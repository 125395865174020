import React from "react";

// Customizable Area Start

import { Typography, Box } from "@material-ui/core";
import { logo } from "../../otp-input-confirmation/src/assets"
import OtpInput from 'react-otp-input';
import { tagCross } from "./assets"
import "../../otp-input-confirmation/src/style.css"
import { NavLink } from "react-router-dom";

// Customizable Area End

import PasscodeLockController, {
    Props,
    configJSON,
} from "./PasscodeLockController";

export default class SetupPin extends PasscodeLockController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <style>
                    {`
                    .verification-code input {
                        width: 65px !important;
                    `}
                </style>
                <div className="main-wrapper" style={{ flexDirection: 'column' , justifyContent: 'center' }}>
                    <div className="verification-wrapper" style={{ marginTop: '30px' }}>
                        <div className="verify-box">
                            <div className="logo-right">
                                <a href="#"><img src={logo} alt="logo" /></a>
                            </div>
                            <div className="verification-block" data-test-id="englishLanguageScreen">
                                <Box>
                                    <div className="verification-code">
                                        <Typography style={{ ...webStyleSP.textLabelsSP }}>Enter PIN</Typography>
                                        <OtpInput
                                            shouldAutoFocus={true}
                                            inputType="number"
                                            data-test-id="OtpField"
                                            onChange={(value) => this.updateStateForEnterPin(value, 'change', "new")}
                                            numInputs={4}
                                            renderSeparator={<span></span>}
                                            renderInput={(props) => <input {...props} />}
                                            value={this.state.enteredPin}
                                            inputStyle={{
                                                width: "75%",
                                                textAlign: 'center',
                                                height: "62px",
                                                marginRight: "30px",
                                                border: "1px solid #2DE2D5",
                                                background: "none",
                                                borderRadius: "12px",
                                                color: "#fff",
                                                opacity: 1,
                                                fontSize: "16px",
                                                margin: "auto"
                                            }}
                                        /></div>
                                    <Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press1" onClick={() => this.updateStateForEnterPin('1', 'click', "new")}>1</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press2" onClick={() => this.updateStateForEnterPin('2', 'click', "new")}>2</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press3" onClick={() => this.updateStateForEnterPin('3', 'click', "new")}>3</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press4" onClick={() => this.updateStateForEnterPin('4', 'click', "new")}>4</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press5" onClick={() => this.updateStateForEnterPin('5', 'click', "new")}>5</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press6" onClick={() => this.updateStateForEnterPin('6', 'click', "new")}>6</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press7" onClick={() => this.updateStateForEnterPin('7', 'click', "new")}>7</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press8" onClick={() => this.updateStateForEnterPin('8', 'click', "new")}>8</Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press9" onClick={() => this.updateStateForEnterPin('9', 'click', "new")}>9</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="backBtn" onClick={() => this.handleBack()}>
                                                <img src={tagCross} style={{ paddingRight: '0px', height: '25px' }} />
                                            </Box>
                                            <Box style={webStyleSP.numberbtnsSP} data-test-id="press0" onClick={() => this.updateStateForEnterPin('0', 'click', "new")}>0</Box>
                                            <Box style={webStyleSP.numberbtns2SP} ><span data-test-id="pressBack" onClick={() => this.handleBack()} >Cancel</span></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '34%', marginTop: '1rem' }} >
                        <div onClick={() => this.confirmEnterBtn()} data-test-id='btnForSetupBtn' id="btnForSetupBtn" style={{
                            color: '#3C3C50', fontWeight: 700, borderRadius: '5px',
                            padding: '5px 50px', cursor: 'pointer', backgroundColor: '#C9F9F6',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '16rem'
                        }}>Confirm</div>
                        <Box style={webStyleSP.forgot}><NavLink style={webStyleSP.anchorTag} to="/ForgotPin">Forgot PIN</NavLink></Box>
                    </div>

                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleSP = {
    forgot: {
        color: '#3C3C50',
        fontWeight: 700,
        borderRadius: '5px',
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '5px 50px',
        cursor: 'pointer',
        backgroundColor: '#C9F9F6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '16rem'
    },
    anchorTag: {
        color: '#3C3C50',
    },
    numberbtns2SP: { cursor: 'pointer', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none' },
    numberbtnsSP: { cursor: 'pointer', background: '#3C3C50', filter: 'drop-shadow(0px 2px 10px rgba(45, 226, 213, 0.20))', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', borderRadius: '30px', alignItems: 'center' },
    textLabelsSP: { color: '#fff', fontWeight: 700, fontSize: '18px', marginLeft: '1rem' },
};
// Customizable Area End
