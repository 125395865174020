// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");


export interface Props {
  [key: string]: any;
}

interface S {
  wealthplannerNamee: string;
  wealthplannerImagee: string;
  currentPlannerId: string | null;
}

interface SS { }

export default class WeathPlannerCallEndController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      wealthplannerImagee: "",
      wealthplannerNamee: "",
      currentPlannerId: null,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
  }

  async receive(from: string, message: Message) {
    this.handleNavigationMessage(message)

    runEngine.debugLog("Message Recived in");

  }

  // web events



  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (data1) {
        this.setState({
          wealthplannerNamee: data1.currentPlannerName,
          wealthplannerImagee: data1.currentPlannerImage,
          currentPlannerId: data1.currentPlannerId,
        }, () => {
        })
      }
    }
  }
  getInitials(name: string) {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.toUpperCase();
  }


  navigateToWealthPlannerHomepage = () => {
    this.props.navigation.history.push('/Homepage');
  }

}

// Customizable Area End
