import React from "react";

// Customizable Area Start
import { styled } from '@material-ui/styles';

import { Typography, Box } from "@material-ui/core";
import {image1, vault} from "./assets"
import { NavLink } from "react-router-dom";

// Customizable Area End

import PasscodeLockController, {
  Props,
  configJSON,
} from "./PasscodeLockController";

export default class PasscodeLock extends PasscodeLockController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <BackgroundBoxSatyle style={{ padding: '4% 3%',  }}>
      <Box>
          <Box style={webStyle.SubscriptionName}>Estate Planning</Box>
      </Box>
      <Box className="main-body-content" style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <img src={image1} style={{height:'65px'}}/>
      <img src={vault} style={{padding:'30px', height:'260px'}}/>
      <Typography style={webStyle.textLabels}>Set up a PIN to secure your access</Typography>
      <Typography style={webStyle.textLabels}>to your document vault</Typography>
      <NavLink to="/SetupPin"><Box style={webStyle.btn}>Set up a PIN</Box></NavLink>
      </Box>
  </BackgroundBoxSatyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BackgroundBoxSatyle = styled(Box)({ width: '100%', background: '#2a2a39', height: '100vh', overflowY: 'scroll' })
const webStyle = {
    textLabels:{color:'#fff', fontWeight:700, fontSize:'18px'},
    SubscriptionName: { color: "#FFF", fontSize: "28px", fontWeight: 600, marginTop: '7px' }, 
    btn: {marginTop:'30px', backgroundColor:'#C9F9F6',color: '#3C3C50', borderRadius:'5px', padding:'5px 35px',cursor:'pointer', fontWeight:600}
};
// Customizable Area End
