import React from "react";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import 'firebase/auth';
import { imgPasswordInVisible, imgPasswordVisible, logoimg, ContinueGoogle, Googleimg, ContinueApple, Appleimg } from "./assets";
import { apiCall } from "../../../components/src/Common";
import { signInWithPopup } from "@firebase/auth";
import { auth, provider } from "./FirebaseConfig";
import {
  Box,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import storage from "../../../framework/src/StorageProvider.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  confirmPasswords: string;
  btnTextSignUp: string;
  enablePasswordField: boolean;
  enablePasswordFieldConfirm: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: string;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  labelOr: string;
  logoimg: string;
  ContinueGoogle: string;
  ContinueApple: string;
  formSubmited: boolean;
  isHovered: boolean;
  isChecked: boolean;
  createAccountButton: string;
  clientId: string,
  emailError: string,
  menual: boolean,
  languages: string,
  logoImg1: string,
  passwordLabel: string,
  passwordlableArabic:string,
  signupEmailError:any;
  Dateofcreate:any,
  selectedFocus: any;
  isDropdownOpen: any;
  countryPage:any;
  locationData:any;
  focusData:any;
  storedEmail:any;
  name:any;
  location:any;
  jurisdiction_advice:any;
  focus:any;
  certificate:any;
  qualification:any;
  employment_history:any;
  firm:any;
  experience:number|null;
  disclosure:any;
  dob:any;
  mobile:any;
  firm_website:any;
  openSuccessDialog: any;
  allCountries1:any;
  allCountriesLocation1:any;
  apiCallingStage:boolean;
  countryDropdownVisible:boolean;
  searchLocation: string;
  loadData:boolean;
  selectedCountry:any;
  selectedCountryCode:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationsControllerweb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationApiCallId: any;
  postUserPDFDataCallId: any;
  getCountryCallId: any;
  getFocusCallId:any;
  updateAccountCallId:any;
  postUserPDFData1CallId: any;
  labelTitle: string = "";
  userRegistrationApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.GoogleLogin),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      logoImg1: localStorage.getItem('language') == "Arabic" ? "ltr" : "rtl",
      languages: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      passwordLabel: configJSON.labelPassword,
      passwordlableArabic: configJSON.lable,
      confirmPasswords: "",
      enablePasswordField: true,
      enablePasswordFieldConfirm: true,
      isHovered: false,
      labelOr: configJSON.labelOr,
      btnTextSignUp: configJSON.btnTextSignUp,
      createAccountButton: configJSON.createAccountButton,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      email: "",
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      password: "",
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      logoimg: logoimg,
      signupEmailError:'',
      ContinueGoogle: localStorage.getItem('language') !== "Arabic" ? ContinueGoogle : Googleimg,
      ContinueApple: localStorage.getItem('language') !== "Arabic" ? ContinueApple : Appleimg,
      formSubmited: false,
      isChecked: false,
      clientId: "954432432614-6r9nb8cjp1gep9631s6akfq61aa2n87f.apps.googleusercontent.com",
      emailError: '',
      menual: false,
      Dateofcreate: null,
      selectedFocus: [],
      isDropdownOpen: false,
      countryPage:0,
      locationData:[],
      focusData:[],
      storedEmail: "",
      name:'',
      location:'',
      jurisdiction_advice:'',
      focus: [] as string[],
      certificate:'',
      qualification:'',
      employment_history:'',
      firm:'',
      experience: null,
      disclosure:'',
      dob:'',
      mobile:'',
      firm_website:'',
      openSuccessDialog: false,
      allCountries1:'',
      allCountriesLocation1:'',
      apiCallingStage:true,
      countryDropdownVisible:false,
      searchLocation:'',
      loadData:false,
      selectedCountry:"",
      selectedCountryCode:'',
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const url= window.location.pathname;
    
    if(url=="/CreateAccount"){
      const email=await storage.get("email");

      this.setState({storedEmail:email})
    }
    this.getCountriesLocation();
    this.getFocusList();
  }

  getString = (key: string) => {
    let language = localStorage.getItem("language") || "English"
    return configJSON.languageList[language][key] 
  }

  navigateToPage(pageName: string){
    this.props.navigation.navigate(pageName)
  }
  performNavigation(){
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
    }, 200)
  }
  navigation = (responseJson: any) => {
    if(this.state.menual && !responseJson.data?.activated){
      this.props.navigation.navigate('OTPInputAuth')
      return
    }
    if(!responseJson.meta?.question_answered){
      this.props.navigation.navigate('Questionbank')
      return
    }
    if (responseJson.meta.subscription?.status !== 'inactive') {
      if (!moment().isAfter(moment.utc(responseJson.meta.subscription?.expiration_date))) {
        if (responseJson.meta.bank_connected) {
          window.localStorage.setItem('authToken', responseJson.meta.token);
          this.performNavigation()
        } else {
          this.navigateToPage('Apiintegrationbankaccounts')
        }
      } else {
        this.navigateToPage('ChangePlan')
      }
    } else {
      this.navigateToPage('Freetrial')
    }
  }

  newChange = (responseJson: any) => {
    if (responseJson?.meta?.token) {
      window.localStorage.setItem('token', responseJson.meta.token);
      window.localStorage.setItem('EngagementRequestId', responseJson?.data?.id);

    }
    if (responseJson?.data?.attributes?.email) {
      window.localStorage.setItem('email', responseJson.data.attributes.email);
    }
    if (responseJson?.errors && this.state.menual) {
      responseJson?.errors[0]?.account && this.setState({ emailError: responseJson?.errors[0]?.account.charAt(0).toUpperCase() + responseJson?.errors[0]?.account.slice(1) })
      responseJson?.errors[0]?.email && this.setState({ emailError: 'Your email is not valid!' })
    }else{
      this.navigation(responseJson)
    }

  }

  errorMsg = (responseJson: any) => {
    if (responseJson?.errors) {
      this.setState({ signupEmailError: responseJson.errors[0].email})
    }

    if (responseJson?.message === "account created") {
      window.localStorage.setItem('profileUpdateToken', responseJson.token);
      this.props.navigation.navigate('CreateAccount');
    }
    if (responseJson?.token) {
      window.localStorage.setItem('profileUpdateToken', responseJson.token);
      window.localStorage.setItem('EngagementRequestId1', responseJson.data.id);
    }
   
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.postUserPDFDataCallId !== null &&
        this.postUserPDFDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) || (getName(MessageEnum.GoogleLogin) === message.id &&
          this.postUserPDFDataCallId !== null &&
          this.postUserPDFDataCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.newChange(responseJson)
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.manageLocationTokenResponce(message,responseJson2,apiRequestCallId)
    this.manageFocusResponce(message,responseJson2,apiRequestCallId)


    if (
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.postUserPDFData1CallId !== null &&
        this.postUserPDFData1CallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))  &&
          this.postUserPDFData1CallId !== null &&
          this.postUserPDFData1CallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.errorMsg(responseJson)
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowPasswords = () => {
    this.setState({
      enablePasswordFieldConfirm: !this.state.enablePasswordFieldConfirm,
    });
  };
  handleCheckboxChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }
  handleMouseEnters = () => {
    this.setState({
      isHovered: !this.state.isHovered,
    });
  };
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };
  dialogCashModel = () => {
    this.setState({ openSuccessDialog: false });
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Loginplanner");
      message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
      );
      this.send(message) 
      };
  dialogCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  HandleSubmit = async (values: any) => {
    this.setState({ formSubmited: true, menual: true });
    const header = { "Content-Type": configJSON.singupApiContentType };
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPasswords,
          terms_and_conditions: true,
          device_id: ""
        },
      }
    };
    let userDetailurl = configJSON.submitPdfFormUrl;
    const postPdfData = apiCall({
      httpBody: httpBody,
      header: header,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  SignUpHandle = async (values: any) => {
    this.setState({ formSubmited: true, menual: true });
    storage.set("email",values?.email)
    const header = { "Content-Type": configJSON.singupApiContentType };
   
    const httpBody = {
      data: {
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPasswords,
          terms_and_conditions: true,
          device_id: ""
      },
    };
    let userDetailurl = configJSON.apiWorkflowSignup;
    const postPdfData1 = apiCall({
      httpBody: httpBody,
      header: header,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFData1CallId = postPdfData1.messageId;
    runEngine.sendMessage(postPdfData1.id, postPdfData1);
  }

  goToPrivacyPolicy = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationLoginWeb));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  goToSignin = () => {
    this.props.navigation.navigate('Loginplanner')
  }
  termaConditions = () => {
    this.props.navigation.navigate('TermsConditions')
  }

  login = () => {
    this.setState({ menual: false });
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithPopup(auth, provider).then((data) => {
      if (data.user) {
        this.responseGoogle(data.user)
      }
    })
  };

  finalErrorMessage(error: any) {
    if (error) {
      return error
    } else {
      return;
    }
  }

  responseGoogle = (props: any) => {
    this.setState({ formSubmited: true });
    const newHeader = { "Content-Type": configJSON.singupApiContentType };
    const httpBody = {
      data: {
        type: "google_account",
        attributes: {
          email: props.email,
          unique_auth_id: props.accessToken,
          activated: true,
          terms_and_conditions: true,
          device_id: ""
        },
      }
    };
    let userDetailurl = configJSON.LoginsubmitPdfFormUrl;
    const postPdfData = apiCall({
      httpBody: httpBody,
      header: newHeader,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  colorCheck = (values: any) => {
    return (
      <Box>
        <Typography
          style={{ fontSize: '0.7rem', display: "flex", alignItems: "center", lineHeight: '2' }}>
          <CheckCircleIcon
            style={{
              fontSize: '0.9rem',
              color: !/^.{8,}$/.test(values.password) ? "#918d98" : "#09e5cf"
            }} /><span style={{ paddingLeft: "3px" }}>
            {this.getString('minimumLength')}</span></Typography>
        <Typography
          style={{ fontSize: '0.7rem', display: "flex", alignItems: "center", lineHeight: '2' }}><CheckCircleIcon
            style={{
              fontSize: '0.9rem',
              color: !/[a-z]/.test(values.password) ? "#918d98" : "#09e5cf"
            }} /><span style={{ paddingLeft: "3px" }}>{this.getString('atLowercase')}</span></Typography>
        <Typography
          style={{ fontSize: '0.7rem', display: "flex", alignItems: "center", lineHeight: '2' }}><CheckCircleIcon
            style={{
              fontSize: '0.9rem',
              color: !/[A-Z]/.test(values.password) ? "#918d98" : "#09e5cf"
            }} /><span style={{ paddingLeft: "3px" }}>{this.getString('oneUppercase')}</span></Typography>
        <Typography
          style={{ fontSize: '0.7rem', display: "flex", alignItems: "center", lineHeight: '2' }}><CheckCircleIcon
            style={{
              fontSize: '0.9rem',
              color: !/\d/.test(values.password) ? "#918d98" : "#09e5cf"
            }} /><span style={{ paddingLeft: "3px" }}>{this.getString('oneNumber')}</span></Typography>
      </Box>
    )
  }

  //code schema
  codeSchema = Yup.object().shape({
    email: Yup.string()
      .email(this.getString("emailIsRequired"))
      .required(this.getString("emailIsRequired")),
    password: Yup.string()
      .required(this.getString("pleaseEnterAValidPassword"))
      .test('password-validation', this.getString('validation'), (value) => {
        const length = /^.{8,}$/.test(value);
        const lowerCase = /[a-z]/.test(value);
        const upperCase = /[A-Z]/.test(value);
        const number = /\d/.test(value);
        return length && lowerCase && upperCase && number;
      }),
    confirmPasswords: Yup.string()
      .required(this.getString('pleaseEnterConfirmPassword'))
      .oneOf([Yup.ref('password'), null], this.getString('pleaseEnterSemPassword'))
  });

  dateOfBirth = (date: Date | null) => {
        this.setState({ Dateofcreate: date });
  };

  getCountriesLocation = async () => {

    const requestMessageForCountries = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCallId = requestMessageForCountries.messageId;

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage+1}`
    );
    this.setState({ countryPage: this.state.countryPage + 1 })

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
  }
  inputLocation = (value: string, allCountriesLocation1: any) => {
    if(this.state.loadData){
      this.setState({  countryDropdownVisible:false})
    }
    const searchData = allCountriesLocation1.filter((val: any) =>
      val.attributes.name.toLowerCase().includes(value.toLowerCase())
    );
    setTimeout(() => {
      this.setState({ countryDropdownVisible:true});
    }, 100);
    this.setState({ searchLocation: value, allCountries1: searchData});
  };


  callCountry = async () => {
    if(this.state.apiCallingStage){
      this.setState({apiCallingStage:false},()=>{
        this.getCountriesLocation();
      })
    }
  }
  manageLocationTokenResponce = async (message:any,responseJson2:any, apiRequestCallId:any)=>{
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.getCountryCallId === dataMessage) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
       
      if (responseJson2 && responseJson2.length > 1) {
        this.setState((prevState) => ({
          allCountries1: [...prevState.allCountries1, ...responseJson2],
          allCountriesLocation1: [...prevState.allCountriesLocation1, ...responseJson2]
        }), () => {
          this.setState({ apiCallingStage: true })
        });
        this.callCountry()
      }
      
    }
  }

  getFocusList = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("profileUpdateToken"),
    };
    const requestMessageForCountries = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFocusCallId = requestMessageForCountries.messageId;

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_formapprovalworkflow/wealth_planners/focus_list`
    );
    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
  }

  manageFocusResponce = async (message:any,responseJson2:any, apiRequestCallId:any)=>{
    if (responseJson2 &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFocusCallId.length > 0 &&
      apiRequestCallId === this.getFocusCallId) {
        this.setState({ focusData: responseJson2.focus.data })
    }
  }

  updateCreateaccount = async (values: any) => {

    const header = {
      "token": localStorage.getItem("profileUpdateToken"),
    };
    const putRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountCallId = putRequestMessage.messageId;

    const putFormData = new FormData();
    const focusArray = this.state.selectedFocus.map((item:any) => parseInt(item.id));

    putFormData.append('data[name]', values.name);
    putFormData.append('data[location]', values.location);
    putFormData.append('data[jurisdiction_advice]', values.jurisdiction_advice);
    putFormData.append('data[focus]',JSON.stringify( focusArray));
    putFormData.append('data[certificate]', values.certificate);
    putFormData.append('data[qualification]', values.qualification);
    putFormData.append('data[employment_history]', values.employment_history);
    putFormData.append('data[firm]', values.firm);
    putFormData.append('data[experience]', values.experience);
    putFormData.append('data[disclosure]', values.disclosure);
    putFormData.append('data[dob]', this.state.Dateofcreate);
    putFormData.append('data[mobile]', values.mobile);
    putFormData.append('data[firm_website]', values.firm_website);

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), putFormData);

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_formapprovalworkflow/wealth_planners/update"
    );

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(putRequestMessage.id, putRequestMessage);
    this.setState({ openSuccessDialog: true });
      this.goToLoginPlannerScreen()
  }

  goToLoginPlannerScreen=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationToPlanner)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);


  }

  // Customizable Area End
}