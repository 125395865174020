import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import toast from "react-hot-toast"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  userEmail: any;
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  errorMsg: string,
  token: any,
  isArabic:boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  resendOtpApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: any;
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      userEmail: "",
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      errorMsg: "",
      token: localStorage.getItem('token'),
      isArabic:false,
    };
    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  const responseJson = successMessage;

  if (this.otpAuthApiCallId === dataMessage) {
    this.setState({ otp: "" });
    this.switchShort(responseJson)
  }
  
  if (this.resendOtpApiCallId === dataMessage) {
    const resendOTPResponse = successMessage;
    this.resendShort(resendOTPResponse)
  }
  
  }


  resendShort(resendOTPResponse:any){
    this.setState({ token: resendOTPResponse?.token })
    if (resendOTPResponse.message) {
      toast[resendOTPResponse.token ? 'success' : 'error'](resendOTPResponse.message);
    }
  }
  switchSortConditions = (responseJson:any)=>{
    if (responseJson.message === 'OTP validation success') {
      this.props.navigation.navigate("Questionbank");
    } else if(responseJson.message) {
      this.setState({ errorMsg: responseJson.message });
    }

    if (responseJson.error) {
      let errMsg = this.state.isArabic ? "كلمة المرور لمرة واحدة غير صالحة" : "OTP invalid";
      this.setState({ errorMsg: errMsg });
    }
  }
  switchShort(responseJson:any){
   this.switchSortConditions(responseJson)

    if (!responseJson.error && responseJson.errors) {
      const errors: any[] = responseJson.errors;
      let allerrors = '';
      errors.forEach((object: string) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
          if (value.length > 0) {
            allerrors = allerrors.length <= 0 ? value : `${allerrors}{\n}${value}`;
          }
        });
      });
      this.setState({ errorMsg: allerrors });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.checkLanguage();
  }
  checkLanguage = () =>{
    let language = localStorage.getItem('language');
    if(language == 'Arabic'){
      this.setState({isArabic:true});
    }
    else{
      this.setState({isArabic:false});
    }
  }
  async submitOtp() {
    if (this.state.otp.length < 4) {
      if (this.state.isArabic) {
        this.setState({ errorMsg: 'كلمة المرور لمرة واحدة غير صالحة' });
      } else {
        this.setState({ errorMsg: 'OTP invalid!' });
      }
      return;
    }

    this.setState({ otpAuthToken: this.state.token })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (!this.state.isFromForgotPassword) {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.token
      };
      this.otpAuthApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/otp_verifications?otp_code=" + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async resendOtp() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOtpApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const attributes = {
      email: window.localStorage.getItem('email')
    }
    const data = {
      attributes: attributes
    };
    const httpBody = {
      data: data
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/resend_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
