import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom'
import '../assets/css/myneSettingsCurrency.css'
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";
import { FormControl } from "@material-ui/core";

export default class MyneCurrency extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  roundedNew = () => {
    return this.state.logoImgsetting === "rtl" ? "cur-close-rounded cur-close-rounded-new" : "cur-close-rounded"
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div className="main-currency-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-currency-container">
          <div className="cur-small-text">
            {this.getStringGoalmanagement("Settings")}
            <Link to={'/Dashboard'} className={this.roundedNew()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="cur-bold-text">{this.getStringGoalmanagement("Currency")}</div>
          <div className="profile-currency-container">
            <span className="currency-title-new">{this.getStringGoalmanagement("SelectedCurrency")}</span>
            <FormControl component="fieldset">

              {
                this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 && this.state.currencyList.list.map((c: any) =>
                  <div key={c.currency_type} className="radio-button-wrapper">
                    <input
                      type="radio"
                      id={c.id}
                      name="currency"
                      value={c.currency_type}
                      checked={c.id === this.state.currencyList.selection}
                      onChange={() => {
                        this.onAddCurrencyToAccount(c.id);
                      }}
                      className="custom-radio"
                      data-test-id={`currency-radio-input${c.id}`}
                    />
                    <span className="currency-title">{c.currency_type}</span>
                  </div>)
              }
            </FormControl>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}