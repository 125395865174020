import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {
  budget7,
  budget8,
  budget9,
  budget10,
  appleLogo,
  googleIcon,
  homeIcon,
  facebook,
  instagram,
  twitter,
  youtube,
  linkedin,
  logo,
  ImgBack,
  bg1
} from "./assets";
import { Box, Typography } from "@material-ui/core";
// Customizable Area End

export default class EstatePlanningWeb extends React.Component {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box style={{ position: 'relative' }}>
          <div style={{ ...webStyle.ImgBackTop, position: 'absolute' }}></div>
          <div style={{ ...webStyle.ImgBackTop1, position: 'absolute' }}></div>
          <Box style={{ maxWidth: "1946", margin: "auto", }}>
            <CnvestmentBox style={{ position: 'relative', zIndex: 20 }}>
              <Box>
                <MasterStyle>
                  Plan for the future <br />{" "}
                  <span style={webStyle.masterSaving}>confdently</span>
                </MasterStyle>
                <MasterFinances >
                  with MYNE’s Estate Planning services. Connect with top estate
                  planners to manage your wills, trusts, and inheritance matters.
                  Myne simplifies the process, ensuring your wealth is preserved
                  and your family's future is secure.
                </MasterFinances>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget7} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin>
                      <MasterCheck>
                        Access the Best-in-Class{" "}
                        <span style={webStyle.masterSpends}>Estate <br /><span>Planners</span></span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Connect with Experts"
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          MYNE grants you access to a network of top-tier estate
                          planners. With a focus on quality and expertise, we
                          connect you with professionals who can craft the perfect
                          estate plan for your needs. Protect your assets with
                          Myne.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>

              <BoxMarginTop>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        Build Your{" "}
                        <span style={webStyle.masterSpends}>
                          Comprehensive Estate <br /><span>Plan</span>
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Tailored Solutions for Your Future"{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          From wills <br /><span>to trusts and beyond, MYNE simplifies the
                            creation of your estate plan. Our platform guides you
                            through the process, ensuring every aspect of your
                            estate is addressed. Build a plan that reflects your
                            goals and provides peace of mind for your loved ones.</span>
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget8} />
                  </Grid>
                </Grid>
              </BoxMarginTop>

              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget9} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin>
                      <MasterCheck >
                        Convenient{" "}
                        <span style={webStyle.masterSpends}>
                          Payment and Status <br /><span>Updates</span>
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Transparent and Hassle-Free Process"{" "}
                        <span style={webStyle.masterCheckMonitor}>Manage
                          <br /><span>the estate planning process directly through MYNE. Pay
                            for services via the app and receive regular status
                            updates. Our streamlined approach ensures a smooth,
                            transparent journey from start to finish, keeping you
                            informed every step of the way.</span>
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>

              <BoxMarginTop>
                <Grid container item spacing={3} >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        Securely Store All Your{" "}
                        <span style={webStyle.masterSpends}>
                          Estate <br /><span>Documents</span>
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        “Your Documents, Safe and Accessible”{" "}
                        <span style={webStyle.masterCheckMonitor}>MYNE
                          <br /><span>offers a secure repository for all your estate
                            planning documents. Store your important files safely
                            and access them anytime, anywhere. With MYNE, you have
                            the assurance that your sensitive documents are
                            protected and readily available whenever you need them.
                          </span>
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget10} />
                  </Grid>
                </Grid>
              </BoxMarginTop>
            </CnvestmentBox>
          </Box>
          <div style={{ ...webStyle.ImgBackBottem, position: 'absolute' }} className="relative"></div>
          <div style={{ ...webStyle.ImgBackBottem1, position: 'absolute' }}></div>
          <Box style={{ ...webStyle.footerwidth, position: 'relative', zIndex: 20, background: "#272733", }}>
            <Box style={{ maxWidth: "1946", margin: "auto", }}>
              <AppStoreGetItOnFooter
                id="footer"
                style={webStyle.footer}
                className="footerInNetworth"
              >
                <AppStoreGetItOnFooterHome className="footerContent" style={webStyle.footerContent}>
                  <Box className="footerImgForHome">
                    <img src={homeIcon} style={webStyle.footerIcons} alt="" />
                  </Box>
                  <Box className="menuItemsForFooter">
                    <span style={webStyle.menuItemsForFooter}>About us</span>
                    <span className="menuItemsForFooter" style={webStyle.menuItemsForFooter}>Contact us</span>
                    <span style={webStyle.menuItemsForFooter}>Resources</span>
                    <span style={webStyle.menuItemsForFooter} className="Roadmap">Roadmap</span>
                    <span style={webStyle.menuItemsForFooter} className="Terms">Terms of use</span>
                    <span className="Cookies" style={webStyle.menuItemsForFooter}>Cookies policy</span>
                    <span className="policy" style={webStyle.menuItemsForFooter}>Privacy policy</span>
                  </Box>
                </AppStoreGetItOnFooterHome>
                <LogoForFooter className="logoForFooter">
                  <img src={logo} style={webStyle.imgLogo} alt="" />
                  <Typography data-test-id="logoForFooter" style={webStyle.logoForFooter}>{" "}Who's managing your wealth?</Typography>
                </LogoForFooter>
                <Box data-test-id="SocialMediaIcons" style={{ ...webStyle.socialMediaIcons, margin: "13px 0px" }}>
                  <img src={facebook} style={webStyle.footerIcons} alt="" />
                  <img src={instagram} data-test-id="instagram" style={webStyle.footerIcons} alt="" />
                  <img src={twitter} style={webStyle.footerIcons} data-test-id="footerIcons" alt="" />
                  <img src={youtube} style={webStyle.footerIcons} alt="" />
                  <img data-test-id="linkedin" src={linkedin} style={webStyle.footerIcons} alt="" />
                </Box>
                <Box data-test-id="GoogleCardFooter" style={{ ...webStyle.appleAndGoogleCards, margin: "13px 0px", flexWrap: 'wrap', justifyContent: 'center' }}>
                  <img width="151px" height="46px" src={googleIcon} alt="" />
                  <img src={appleLogo} alt="" width="151px" height="46px" />
                </Box>
              </AppStoreGetItOnFooter>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  ImgBackTop: { width: '65%', height: '25%', right: '-458px', background: `url(${ImgBack}) no-repeat`, backgroundSize: 'cover', zIndex: 1 },
  footerwidth: { width: "100%", marginTop: "40px" },
  masterSaving: { color: "#2ee1d5" },
  footerContent: { display: "flex", alignItems: "center" },
  ImgBackBottem: { width: '65%', height: '20%', background: `url(${bg1}) no-repeat`, top: '80%', backgroundSize: 'cover', zIndex: 1 },
  menuItemsForFooter: { fontSize: "12px", fontFamily: 'Helvetica !important', color: "#fff", marginLeft: "10px", cursor: "pointer" },
  appStoreFooter: { background: "black", color: "#fff", width: "130px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  googleCardFooter: { background: "black", color: "#fff", width: "150px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  appStoreGetItOnFooter: { fontSize: "clamp(0.469vw,0.469vw, 3vw)", fontFamily: 'Helvetica !important', },
  imgLogo: { width: "170px", borderRadius: '0px' },
  ImgBackBottem1: { width: '65%', height: '20%', zIndex: 2, top: '80%', left: 0, background: 'linear-gradient(227deg, rgb(60 60 81) 66%, rgb(60 60 81 / 0%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
  logoForFooter: { fontSize: "10px", fontFamily: 'Helvetica !important', color: "white", paddingTop: '6.5px' },
  masterCheckMonitor: { color: "white" },
  imgLodoNew: { height: "30px" },
  ImgBackTop1: { width: '65%', right: '-458px', height: '25%', zIndex: 2, background: 'linear-gradient(45deg, rgb(60, 60, 81) 40%, rgba(60, 60, 81, 0.8) 60%, rgba(60, 60, 81, 0) 100%) repeat scroll 0% 0%, transparent none repeat scroll 0% 0%' },
  appleAndGoogleCards: { display: "flex", gap: "15px", marginLeft: "9px" },
  footer: { width: "100%", padding: "50px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" },
  footerIcons: { width: "22px" },
  masterSpends: { color: "#2ee1d5" },
  socialMediaIcons: { display: "flex", gap: "10px", alignItems: "center" },
};
const MasterStyle = styled(Box)({
  fontSize: "clamp(1vw,92.71px,4.829vw)", fontFamily: 'Helvetica !important', color: "white", lineHeight: "100%",
});
const CnvestmentBox = styled(Box)({
  paddingLeft: "110px !important", paddingRight: "110px !important", paddingTop: "40px !important",
  "@media (min-width: 320px)": { paddingRight: "30px !important", paddingLeft: "30px !important" },
  "@media (min-width: 1200px)": { paddingLeft: "110px !important", paddingRight: "110px !important" }
});

const LogoForFooter = styled(Box)({ display: "flex", flexDirection: "column", alignItems: "flex-end" });
const MasterFinances = styled(Box)({
  color: "white", margin: "2% 0px", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', lineHeight: 'initial !important',
});
const BoxMargin = styled(Box)({
  padding: "100px 0px",
  "@media (max-width: 950px)": {
    padding: "0px !important",
  },
})
const MasterCheck = styled(Box)({
  fontSize: "clamp(1vw, 45px,2.344vw)", color: "white", fontFamily: 'Helvetica !important', lineHeight: '100%', paddingBottom: "25px !important",
});
const MasterSavingTime = styled(Box)({
  color: "#2ee1d5", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', paddingTop: "20px", lineHeight: 'normal',
});
const BoxMarginTop = styled(Box)({
  padding: "125px 0px",
  "@media (max-width: 601px)": {
    padding: "0px !important",
  },
})
const AppStoreGetItOnFooter = styled(Box)({
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const AppStoreGetItOnFooterHome = styled(Box)({
  display: 'flex', alignItems: 'center', margin: "13px 0px", textAlign: 'center',
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
// Customizable Area End
