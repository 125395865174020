import React, { useState } from "react";
import "./ToggleSwitch.css"; // Importing external CSS for styling

interface ToggleSwitchProps {
  checked?: boolean; 
  handleFunction: () => void; 
}

const ToggleSwitch = ({
  checked,
  handleFunction
}:ToggleSwitchProps) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={handleFunction} />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleSwitch;
