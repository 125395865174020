import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import storage from "framework/src/StorageProvider";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area End

export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    formValues: {
      name: string,
      amount: number| null,
      currency:{ value: string, label: string },
      date: Date | null,
      note: string,
    },
    selectedCurrency: { value: string, label: string },
    currencyList :ICurrency[],
    currencyListDropDown: { value: string, label: string }[],
    currencyListDropDownForm: { value: string, label: string }[],
    bankId: string,
    openSuccessModal: boolean,
    language: string,
    direction: string,
    purchasePr:string,
    
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ManualFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  createBankFormAPIcallId: string = "";
  updateBankFormAPIcallId: string = ""
 

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formValues: {
        name: "",
        amount: null,
        currency:{ value: 'USD', label: 'USD' },
        date: null,  
        note:""      
      },
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      currencyListDropDown:[],
      bankId:"",
      currencyListDropDownForm:[],
      openSuccessModal: false,
      language: "English",
      direction: "ltr",
    purchasePr:'',


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCurrencyList();
    
    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleNavigationMessageFn(message)
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleCreateBankFormResponse(apiRequestCallId);
    this.handleUpdateBankFormResponse(apiRequestCallId);
    this.handleManageCurrencyResponse(apiRequestCallId)
    // Customizable Area End
  }

  // Customizable Area Start

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  getString = (keyvalue: string) => {
    return configJSON.languageListManualForms[this.state.language][keyvalue]
  }

  handleFormat=(value:any)=>{
    let formattedValue = '';
  if (typeof value === 'number') {
    formattedValue = value.toLocaleString();
  } else if (typeof value === 'string') {
    let numValue = parseFloat(value.replace(/,/g, ''));
    if (!isNaN(numValue)) {
      formattedValue = numValue.toLocaleString();
    }
  }
  return formattedValue;
  }
  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if (apiRequestCallId === this.getCurrencyListAPICallId) {
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
      })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy ? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` } : {
          value: "", label: ""
        },
        currencyListDropDownForm: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.currency_type}` }
        )),

        currencyListDropDown: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
        ))
      })

    }
  }


  handleCreateBankFormResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.createBankFormAPIcallId){
      setTimeout(()=>{
        this.setState({
          openSuccessModal: false,
        } , ()=>{
          this.goToBackPage()
        })
      } , 2000)
    }
  }

  goToBackPage(){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWeb");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  dialogSuccessPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  handleUpdateBankFormResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.updateBankFormAPIcallId){
      this.setState({openSuccessModal : true});
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "BankOverview");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message)
    }
  }

  goToAccountDetail=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "MyneSettingsProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  handleBack=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    if(this.state.bankId){
      message.addData(getName(MessageEnum.NavigationTargetMessage), "BankOverview");
    }else{
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWeb");
    }
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handleSubmit =async (values:{
    name: string,
    amount: string,
    note: string ,
    currency:{ value: string, label: string },
    date: Date | null
  }) => {
  
      const body =
      {
        "name": values.name,
        "amount": values.amount.replace(/,/g, ''),
        "note": values.note,
        "currency_type": values.currency.value,
        "date_of_purchase": values.date
      };
    if(this.state.bankId){
      this.updateBankFormAPIcallId = await this.apiCall({
        contentType:"application/json" + '',
        method: "PUT" + '',
        endPoint: `account_block/bank_forms/${this.state.bankId}` ,
        body: JSON.stringify(body)
      });
    }else{
      this.setState({openSuccessModal : true});
      this.createBankFormAPIcallId = await this.apiCall({
        contentType:"application/json" + '',
        method: "POST" + '',
        endPoint: "account_block/bank_forms" ,
        body: JSON.stringify(body)
      });
    }
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const bankFormMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    bankFormMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    bankFormMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    bankFormMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && bankFormMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(bankFormMessage.id, bankFormMessage);
    return bankFormMessage.messageId;
  };

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }


  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }

  validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
      amount: Yup.string()
      .required("Amount is required")
      .transform(value => value?.replace(/,/g, '')) 
      .max(11, 'Amount should be exactly 11 digits or less'),
      note: Yup.string()
      .required("Note is required"),
    currency: Yup.object().shape({
      value: Yup.string().required("Currency is required"),
    }),
    date: Yup.date().nullable().typeError('Date must be a valid date').required("Date is required"),
  });


  handleNavigationMessageFn(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const bankFormVar = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(bankFormVar){
        this.setState({
          bankId: bankFormVar.id,
          formValues:{
            ...this.state.formValues,
            currency: bankFormVar.currency,
            amount: bankFormVar.attributes.amount,
            note: bankFormVar.attributes.note,
            name: bankFormVar.attributes.name,
            date: moment(bankFormVar.date,"DD/MM/YYYY").toDate()
          }
        })
      }
    }
  }
  // Customizable Area End
}