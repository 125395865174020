import React, { useEffect, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { IconButton } from '@material-ui/core';
import { Mic, MicOff, VolumeUp, VolumeOff, Videocam, VideocamOff } from '@material-ui/icons';

const Controls = () => {
  const { toggleMic, toggleWebcam } = useMeeting();
  const [isMuted, setIsMuted] = useState(false);
  const [isVolumeOff, setIsVolumeOff] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [micStream, setMicStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (!micStream) return;
    micStream.getAudioTracks().forEach(track => {
      track.enabled = !isVolumeOff;
    });
  }, [micStream, isVolumeOff]);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
    toggleMic();
  };

  const handleVolumeToggle = () => {
    setIsVolumeOff(!isVolumeOff);
  };

  const handleVideoToggle = () => {
    setIsVideoOff(!isVideoOff);
    toggleWebcam();
  };

  const buttonStyle = (active: boolean) => ({
    ...styles.button,
    backgroundColor: active ? styles.activeButton.backgroundColor : styles.inactiveButton.backgroundColor,
  });

  return (
    <div style={styles.container}>
      <div style={styles.buttonContainer}>
        <IconButton onClick={handleMuteToggle} style={buttonStyle(isMuted)}>
          {isMuted ? <MicOff style={{ ...styles.icon, color: styles.activeButton.color }} /> : <Mic style={{ ...styles.icon, color: styles.inactiveButton.color }} />}
        </IconButton>
        <IconButton onClick={handleVolumeToggle} style={buttonStyle(isVolumeOff)}>
          {isVolumeOff ? <VolumeOff style={{ ...styles.icon, color: styles.activeButton.color }} /> : <VolumeUp style={{ ...styles.icon, color: styles.inactiveButton.color }} />}
        </IconButton>
        <IconButton onClick={handleVideoToggle} style={buttonStyle(isVideoOff)}>
          {isVideoOff ? <VideocamOff style={{ ...styles.icon, color: styles.activeButton.color }} /> : <Videocam style={{ ...styles.icon, color: styles.inactiveButton.color }} />}
        </IconButton>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  button: {
    borderRadius: '50%',
    margin: '0 17px',
    width: '50px',
    height: '50px',
  },
  icon: {
    fontSize: '25px',
  },
  activeButton: {
    backgroundColor: '#1f1f1f',
    color: '#f4f4f4',
  },
  inactiveButton: {
    backgroundColor: '#f4f4f4',
    color: '#1f1f1f',
  },
};

export default Controls;
