import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";

// Customizable Area Start
import { getStorageData } from 'framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  activeTab: any;
  authToken: any;
  images: string;
  investmentsData: any;
  tabView: string[];
  tabsDetails: string[] | null[];
  logoImgViewInvestments: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueControllerWeb extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  reactInvestmentsApiCallId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start

      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      investmentsData: [],
      token: "",
      activeTab: 1,
      authToken: localStorage.getItem('authToken'),
      images: "",
      tabView: this.getStringViewInvestments("tabViewName"),
      tabsDetails: [],
      logoImgViewInvestments: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImgViewInvestments: logoImg1AddCash });
    this.reactinvestmentsApi()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reactInvestmentsApiCallId !== null &&
      this.reactInvestmentsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let reactInvestmentsApiCallIdJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ investmentsData: reactInvestmentsApiCallIdJson.data.attributes, tabsDetails: [reactInvestmentsApiCallIdJson.data.attributes.overview, reactInvestmentsApiCallIdJson.data.attributes.pros_and_cons, reactInvestmentsApiCallIdJson.data.attributes.opportunity] })
    }
    // Customizable Area End
  }
  // Customizable Area Start
  reactinvestmentsApi = () => {
    const cashData = localStorage.getItem("token")
    const header = { "token": cashData };
    let userDetailurl = `bx_block_content_management/investments/${this.props.location?.state.investMentId}`
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.reactMonthsgetGet,
    });
    this.reactInvestmentsApiCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  getStringViewInvestments = (key: string) => {
    let languageInvestments = localStorage.getItem("language") || "English"
    return configJSON.languageListInvestments[languageInvestments][key]
  }
  clickButton = (value: number) => {
    this.setState({ activeTab: value })
  }

  // Customizable Area End
}

