import React from "react";

// Customizable Area Start
import {
  comingsoon
} from "./assets";
import { Box, Typography } from "@material-ui/core";

import LandingPageController, { Props } from "./LandingPageController";

// Customizable Area End

export default class ComingSoon extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    }
  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{
        position: "relative"
      }} data-test-id="coming-soon">
        <img style={webStyle.imgStyle} src={comingsoon} alt="" />
        <Box style={webStyle.imgContainer}>
          <Typography style={webStyle.heading1}>
            COMING SOON!
          </Typography>
          <Typography style={webStyle.heading2}>
            STAY TUNED!
          </Typography>
        </Box>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle:IStyle = {
  imgContainer: {
    position:"absolute",
    top: "40%",
    right:"40%"
  },
  heading1:{
    fontSize:"32px",
    fontWeight:600,
    color:"#fff",
    fontFamily:"Poppins"
   },
   heading2:{
      fontSize:"26px",
      fontWeight:500,
      color:"#fff",
      textAlign:"center",
      fontFamily:"Poppins"
    },
    imgStyle:{
      height:"100%"
    }
   
};
// Customizable Area End
