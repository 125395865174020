export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_BitmapCopy = require("../assets/image_BitmapCopy.png");
export const image_BitmapCopy1 = require("../assets/image_BitmapCopy1.png");
export const image_BitmapCopy2 = require("../assets/image_BitmapCopy2.png");
export const image_BitmapCopy3 = require("../assets/image_BitmapCopy3.png");
export const image_BitmapCopy4 = require("../assets/image_BitmapCopy4.png");
export const image_BitmapCopy5 = require("../assets/image_BitmapCopy5.png");
export const image_Vuesax = require("../assets/image_Vuesax.png");
export const imagenav_BackIcon = require("../assets/imagenav_BackIcon.png");
export const image_Calendar = require("../assets/image_Calendar.png");
export const image_Upload = require("../assets/image_Upload.png");
export const image_Group4 = require("../assets/image_Group4.png");
export const view_Rectangle = require("../assets/view_Rectangle.png");
export const modelSuccess = require("../assets/cr.png");
export const image_villa = require("../assets/villa.png");
export const image_apartment = require("../assets/apartment.png");
export const image_lend = require("../assets/lend.png");
export const image_store= require("../assets/store.png");
export const iamge_building = require("../assets/building.png");
export const image_others = require("../assets/others.png");
export const imagenav_Bitmap = require("../assets/imagenav_Bitmap.png");