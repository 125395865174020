import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import { Box, Grid, ThemeProvider, Typography as MuiTypography, createTheme, FormControl, Dialog, styled } from "@material-ui/core";
import { backIcon, image_Shape, image_hourglass, logo, logoNew, settingicon } from "./assets";
import { NavLink } from "react-router-dom";
import Select from "react-select";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-input": {
          borderBottom: '0px',
          color: "white",
          width: '100%',
        },
        "& $notchedOutline": {
          borderColor: "#94989c",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
      },
    },
  },
});

// Customizable Area End

import Flanksapi1Controller, {
  Props,
  configJSON,
} from "./Flanksapi1Controller";

export default class Flanksapi1 extends Flanksapi1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="pagedashboard-wrapper">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px',marginBottom:'24px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <NavLink to="/StockSelection"><img src={backIcon} alt="back-icon" /></NavLink>
            <h4  style={{margin:'0',lineHeight:'20px',color:'white',fontSize:'20px',fontWeight:600}}>
              Add Stocks Portfolio
            </h4>
          </div>


          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <FormControl>
              <Select options={this.state.currencyList.map((currency) => ({ label: currency.currency_type, value: currency.currency_type }))}
                value={this.state.selectedCurrency}
                onChange={(value) => { value && this.setCurrency({ label: value.label, value: value.value }) }}
                classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
            </FormControl>
            <NavLink data-test-id="linkForPath" to="/Account-Detail">
              <img src={settingicon} alt="setting" />
            </NavLink>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%" }}>
            <Grid container spacing={4}>
              {
                this.state.portfolioList.length > 0 && this.state.portfolioList.map((portfolio, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>

                      <Box
                        style={this.state.selectedPortfolioId === portfolio.id ? webStyle.stockCardActive : webStyle.stockCard}
                        data-test-id={`portfolio-${index}`}
                        onClick={() => { this.handlePortfolioSelect(portfolio.id) }}
                      >
                        <Box style={{ ...webStyle.stockCard2, flexDirection: 'column' }}>
                          <Box >
                            <img src={portfolio.image_url ? portfolio.image_url : logoNew} style={{ width: '50px', margin: 'auto' }} />
                          </Box>
                          <MuiTypography align="center" style={this.state.selectedPortfolioId === portfolio.id ? webStyle.tickerLabelsActive : webStyle.tickerLabels}>
                            {portfolio.name}
                          </MuiTypography>
                        </Box>
                      </Box>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
          <Box>
            <Box style={{ ...webStyle.btnBox, position: 'absolute', bottom: '20px', right: '30px' }} data-test-id="portfolioSubmit" onClick={this.handlePortfolioConfirm}>
              <MuiTypography style={webStyle.btnLabel}>
                Link Wallet
              </MuiTypography>
            </Box>
          </Box>
          <Dialog
            open={this.state.isCodePresent}
            maxWidth="lg"
            PaperProps={{ style: webStyle.dialogRadius }}
          >
            <MainBankWrapper>
              {this.state.importingData ? (
                <div className="bank-integraion-wrapper">
                  <div className="bank-integration-box hgt-res-500">
                    <div className="logo-right">
                      <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="progress-img-content">
                      <img src={image_hourglass} />
                      <br />
                      <h4>We are importing your data</h4>
                      <p>Please don't close your app. We are<br />
                        currently importing your data to show in<br />
                        the app, it should not take long</p>

                    </div>
                  </div>
                </div>
              ) : (
                <div className="bank-integraion-wrapper">
                  <div className="bank-integration-box d-flex align-center">
                    <div className="successful-img-content">
                      <img src={image_Shape} />
                      <p>Your account linking is successful!</p>
                      <NavLink to="/Dashboard"> <button className="successBtn bankBtns" data-test-id="continueBtn">Continue to dashboard</button> </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </MainBankWrapper>
          </Dialog>
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  stockCard2: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px' },
  stockCard: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
  stockCardActive: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
  tickerLabels: { color: '#fff', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
  tickerLabelsActive: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
  btnBox: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
  btnLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700 },
  dialogRadius: { borderRadius: "35px" }
}

const MainBankWrapper = styled(Box)({
  background: '#3C3C51',
  fontFamily: 'Poppins, sans-serif',
  color: '#fff',
  margin: 0,
  lineHeight: 1.6,
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
  '& .bank-integraion-wrapper': {
    position: 'relative',
    height: "100%",
    minWidth: '800px',
    margin: '0 auto',
    padding: '2px',
    overflow: 'hidden',
    '@media screen and (max-width: 991px)': {
      minWidth: '650px',
    },
    '@media screen and (max-width: 767px)': {
      minWidth: '450px',
      maxWidth: '450px',
    },
    '@media screen and (max-width: 575px)': {
      minWidth: '300px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '32px',
      background: 'linear-gradient(-140deg, rgba(14, 242, 226, 0.19) -22.99%, rgba(0, 0, 0, 0.0001) 64.91%)',
    },
  },
  '& .bank-integration-box': {
    flexWrap: 'wrap',
    height: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(13.5914px)',
    borderRadius: '32px',
    width: '100%',
    position: 'relative',
    background: 'linear-gradient(202.42deg, rgba(53, 81, 79, 0.19) -22.99%, rgba(0, 0, 0, 0.0001) 64.91%), rgba(0, 0, 0, 0.257649)',
    '@media screen and (max-width: 575px)': {
      paddingBottom: '40px',
      height: '570px',
    },
  },
  '& .logo-right': {
    textAlign: 'right',
    padding: '25px 32px 0 0',
  },
  '& .progress-img-content': {
    maxWidth: '360px',
    margin: 'auto',
    textAlign: 'center',
    '& img': {
      objectFit: 'cover',
      maxHeight: '200px',
      '@media screen and (max-width: 991px)': {
        maxWidth: '320px',
      },
      '@media screen and (max-width: 575px)': {
        maxWidth: '280px',
      },
    },
    '& h4': {
      fontSize: '24px',
      fontWeight: '700',
      paddingLeft: '10px',
      display: 'block',
      margin: '20px 0 15px',
    },
    '& p': {
      margin: '15px 0',
      '@media screen and (max-width: 575px)': {
        textAlign: 'center',
      },
    },
  },
});
// Customizable Area End
