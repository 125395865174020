export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const headereBackIcon = require("../assets/backicon.png");
export const vertical3Dots = require("../assets/DotsthreeVertical.png");
export const defaultPortableCardImage = require("../assets/Bitmap.png");
export const settingicon = require("../assets/settingicon.png");
export const subarrowicon = require("../assets/subarrowicon.png");
export const bedCount = require("../assets/bedrooms.png");
export const bathCount = require("../assets/bathrooms.png");
export const areaSizeCount = require("../assets/areasize.png");
export const cashDefault = require("../assets/cash.png");
export const pensionDefault = require("../assets/pension.png");
export const shareDefault = require("../assets/share.png");

