import React from "react";

// Customizable Area Start
import '../assets/pensionAsset.css';
import '../assets/confirmationModal.css';
import { Select, MenuItem, FormControl, Grid,Menu } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { addCommas } from "../../../components/src/commonFn";
// Customizable Area End
import { headereBackIcon, pensionDefault, settingicon, vertical3Dots } from "./assets";
import AssetOverviewController, {
  Props,
} from "./AssetOverviewController";

export default class PensionAssetsOverview extends AssetOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPensionSelectedCurrency(pension_currency_list: any[]) {
    return pension_currency_list.length > 0 && pension_currency_list.find((currency: any) => currency.id === this.state.currencyList.selection) || null;
  }

  getPensionCurrency() {
    return this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 ? this.state.currencyList.list : [];
  }

  getPensionData() {
    return this.state.pensionAssetData || [];
  }

  renderPensionDeleteConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="pension-modal-background">
          <div className="pension-modal-popup-container">
            <div className="pension-modal-title">{this.getString("pensionDeleteConfirm")}</div>
            <div className="pension-modal-button-container">
              <div className="pension-modal-button modal-save" data-test-id='pension-popup-cancel-click'
                onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                {this.getString("pensionCancel")}
              </div>

              <div className="pension-modal-button modal-discard" data-test-id='pension-popup-delete-click'
                onClick={() => {
                  this.setState({ isDeleteConfimationModalOpen: false });
                  this.deletePensionAssetData(this.state.currentCardId)
                }}>
                {this.getString("pensionDelete")}
              </div>
            </div>

          </div>
        </div>
      )
    }
  }

  fetchPensionAsssetKeys(data: any) {
    const attributes = data && data.attributes;
    const obj: any = {
      name: '',
      price: '',
      date: '',
    }
    if (attributes) {
      obj.name = attributes.name;
      obj.price = `${attributes.converted_currency_symbol}${addCommas(attributes.converted_amount)}`;
      obj.date = `${attributes.start_date}`;
      return obj;
    }
    return obj;
  }

  // Customizable Area End

  render() {
    const pension_currency_list = this.getPensionCurrency();
    const selectedPensionCurrency = this.getPensionSelectedCurrency(pension_currency_list);
    const pensionAssetData = this.getPensionData();
    return (
      // Customizable Area Start
      <div className="pension-asset-overview-main-container" dir={this.state.direction}>
        <div className="pension-asset-overview-container">
          <div className="pension-header-wrapper">
            <div className="pension-page-title">
              <h4>
                <Link to={'/Dashboard'} className="pension-navigation-back-icon" style={this.state.direction === 'rtl' ? {marginRight: 'unset', marginLeft: "14px"} : {}}>
                  <img src={headereBackIcon} style={{transform: this.state.direction === 'rtl' ? "rotate(180deg)" : "rotate(0deg)"}}></img>
                </Link>
                {this.getString("pensionPension")}</h4>
            </div>
            <div className="pension-page-info">
              <div className="pension-drop-menu">
                <div>
                  <FormControl className="pension-an-custom-select">
                    <Select
                      displayEmpty
                      value={selectedPensionCurrency ? selectedPensionCurrency.id : ''}
                      data-test-id="select-change"
                      onChange={async (e: any) => {
                        if (e.target.value) {
                          await this.onAddCurrencyToAccount(e.target.value)
                          setTimeout(async () => {
                            await this.loadPensionAssetData();
                          }, 500);
                        }
                      }}
                      className="pension-an-select-dropdown"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {pension_currency_list.length > 0 &&
                        pension_currency_list.map((currency: any) => (
                          <MenuItem key={currency.id} value={currency.id} className="pension-an-custom-menu-item">
                            {`${currency.symbol} ${currency.currency_type}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Link to="/Account-Detail">
                    <img src={settingicon} alt="setting" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="pension-asset-cards">
            <Grid className="pension-card-grid-container" container spacing={2}  style={this.state.direction === "rtl" ? {paddingLeft: "160px", paddingRight: "unset"}: {}}>
            {
              pensionAssetData.length > 0 && pensionAssetData.map((data: any, index: number) => {
                const { name, price, date } = this.fetchPensionAsssetKeys(data);
                return (
                  <Grid className="pension-card-grid-item" item xs={12} sm={6} md={4}>
                    <div className="pensions-card" key={data.id}>
                      <div className="pension-card-top">
                        <div className="pension-card-left-upload">
                          <img src={pensionDefault} alt='pension image' />
                          <div
                            className="white-btn-title">
                            {name}
                          </div>
                        </div>

                        <div className="pension-upper-card-right">
                          <img
                            onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id)}
                            data-test-id={`pension-vertical-icon-click-${index}`}
                            src={vertical3Dots} alt='vertival-dots' />
                          {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                            <Menu
                            id="simple-menu"
                            keepMounted
                            data-test-id="cash-popup-menu"
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleDocumentMenuModalClose}
                            anchorOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                            PaperProps={{
                              style: {width:'55px',background:"#395D6B", padding:"0 5px", borderRadius:'8px'}
                            }}
                            >
                              <Link to={{
                                pathname: '/AddPensionweb',
                                state: { pensionEditData: data }
                              }}
                                className="pension-edit-option"
                                onClick={() => this.setState({ isVerticalPopoverOpen: false })}
                                data-test-id={`pension-edit-${index}`} >
                                {this.getString("pensionEdit")}
                              </Link>
                              <div className="pension-delete-option"
                                onClick={() => {
                                  this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                                }}
                                data-test-id={`pension-delete-${index}`}>
                                {this.getString("pensionDelete")}
                              </div>
                            </Menu>
                          )}
                        </div>
                      </div>
                      <div className="pensions-card-bottom">
                        <div className="pension-card-bottom-content-container">
                          <div className="pension-sub-tile-bold-section" style={this.state.direction === "rtl" ? {marginRight: "unset", marginLeft: "15px"}: {}}>
                            {this.getString("pensionPrice")}
                          </div>
                          <div className="pension-middlesub-tile-normal-section">{price}</div>
                        </div>

                        <div className="pension-card-bottom-content-container">
                          <div className="pension-sub-tile-bold-section" style={this.state.direction === "rtl" ? {marginRight: "unset", marginLeft: "15px"}: {}}>
                            {this.getString("pensionDate")}
                          </div>
                          <div className="pension-middlesub-tile-normal-section">{date}</div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )
              }
              )
            }
            </Grid>
          </div>
        </div>
        {
          this.renderPensionDeleteConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}
