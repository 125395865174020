import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}

export const addCommas = (getText: string) => {
    // Ensure getText is a string
    let text = typeof getText === 'string' ? getText : String(getText);
    let negative = '';
  
    // Handle negative numbers
    if (text.includes('-')) {
      negative = '-';
      text = text.replace('-', '');
    }
  
    let withoutDecimal = text;
    let decimal = '';
  
    // Handle numbers that already include commas
    if (text.includes(',')) {
      if (text.length > 6) {
        let num = text.replace(/,/g, '');
        let value = formatNumber(Number(num));
        return negative + value;
      } else {
        return negative + text;
      }
    }
  
    // Handle decimal points
    if (withoutDecimal.lastIndexOf('.') >= 0) {
      decimal = text.slice(withoutDecimal.lastIndexOf('.'), text.length);
      withoutDecimal = text.slice(0, withoutDecimal.lastIndexOf('.'));
    }
  
    // Handle large numbers without commas
    if (withoutDecimal.length > 6) {
      return negative + formatNumber(Number(text));
    }
  
    // Format numbers with commas
    if (withoutDecimal.length < 4) {
      return negative + withoutDecimal + decimal;
    } else {
      let final = withoutDecimal;
      let count = 0;
      let commasAdded = '';
  
      for (let i = final.length; i > 0; i--) {
        if (count % 3 === 0 && count !== 0) {
          commasAdded = final[i - 1] + ',' + commasAdded;
        } else {
          commasAdded = final[i - 1] + commasAdded;
        }
        count++;
      }
  
      return negative + commasAdded + decimal;
    }
  };
  
  const formatNumber = (number: number) => {
    if (number < 1) {
      return Math.round(number)
    }
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixIndex = Math.floor(Math.log10(Math.abs(number)) / 3);
    const formattedNumber = (number / Math.pow(10, suffixIndex * 3)).toFixed(2) + suffixes[suffixIndex];
    return formattedNumber;
  }

  export const formatNumberWithCommasAndDecimals = (number:string|null) => {
    if (!number) return 0;

    const parts = number.split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] ? '.' + parts[1] : '';

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedInteger  + (decimalPart ? '.' + parseFloat(decimalPart).toFixed(2).slice(2) : '');
};


export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  if (Object.keys(httpBody).length > 0 || httpBody?.append) {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody?.append ? httpBody : JSON.stringify(httpBody)
    );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );
  return requestMessage;
}

export const  getFirstCharacterFromEachWord = (text :string) => {
  if(text){
    let words = text.split(/\s+/);
    let result = "";
    if (words.length > 0) {
      result += words[0][0].toUpperCase(); // First character of the first word
    }
  
    if (words.length > 1) {
      let lastWord = words[words.length - 1];
      if(lastWord)
      result += lastWord[0].toUpperCase(); // First character of the last word
    }
  
    return result;
  }
  return ""
}

export const Regex = {
  urlRegex: /^(https?:\/\/)?(www\.)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
  urlValidateRegex: /^(https?:\/\/)?(www\.)?[\w\-]+(\.[\w\-]+)+[/#?]?.*$/
}

export const convertMinutesToHoursAndMinutes = (minutes: number): string  => {
  const hours: number = Math.floor(minutes / 60);
  const remainingMinutes: number = minutes % 60;
  
  let result: string = '';

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  if (remainingMinutes > 0) {
    if (result) {
      result += ' ';
    }
    result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  }

  return result || '0 minutes';
}

interface DurationResult {
  duration: number;
  durationUnit: string;
}

export const  getDurationWithUnit = (minutes: number): DurationResult => {
  if (minutes % 60 === 0) {
    return {
      duration: minutes / 60,
      durationUnit: 'hours'
    };
  } else {
    return {
      duration: minutes,
      durationUnit: 'minutes'
    };
  }
}

export const  convertISOTo12HourFormat = (isoString:string) => {
  const newDate = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
  };
  return newDate.toLocaleTimeString("en-US", options);
}

export function formatString(inputString:string) {
  return inputString
    .split(/[_-]/)                     
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
    .join(' ');          
}

export const formatDate = (dateString:string) => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) {
    return "Invalid date"
  }
  const options:any = { month: 'short', year: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

export function formatDateWithTime(isoDate:any, options = { includeSeconds: false }) {
  if(!isoDate){
    return '--/--/--'
  }
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  let formattedTime = `${hours}:${minutes} ${ampm}`;

  if (options.includeSeconds) {
    formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;
  }

  return `${year}-${month}-${day} ${formattedTime}`;
}


export function formatNumberWithAbbreviations(value:any) {
  if (value === null || value === undefined || value === '') return '0';
  let numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numericValue)) return '0';

  const absValue = Math.abs(numericValue);

  if (absValue >= 1.0e+12) {
      return (numericValue / 1.0e+12).toFixed(2) + "T";
  } else if (absValue >= 1.0e+9) {
      return (numericValue / 1.0e+9).toFixed(2) + "B";
  } else if (absValue >= 1.0e+6) {
      return (numericValue / 1.0e+6).toFixed(2) + "M"; 
  } else if (absValue >= 1.0e+3) {
      return (numericValue / 1.0e+3).toFixed(2) + "K";
  } else {
      return numericValue.toString();
  }
}


export const formatNumberWithCommas = (inputValue: any): string => {
  if (inputValue === null || inputValue === undefined) return '';

  const numericValue = typeof inputValue === 'number' ? inputValue : parseFloat(inputValue?.replace(/,/g, ''));

  if (isNaN(numericValue)) return '';

  if (Number.isInteger(numericValue)) {
    return numericValue.toLocaleString();
  }

  return numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const processInputValue = (
  inputValue: string,
  maxLength: number,
  formatWithCommas: boolean = true
): { actualValue: string; formattedValue: string } | null => {
  let actualValue = sanitizeAndRemoveCommas(inputValue);
  if (!/^\d*\.?\d*$/.test(actualValue)) {
    return null; 
  }
  if (actualValue.replace('.', '').length > maxLength) {
    return null;
  }
  const formattedValue = formatWithCommas ? formatNumberWithCommas(actualValue) : actualValue;

  return { actualValue, formattedValue };
};



export const sanitizeAndRemoveCommas = (value:any) => {
  if (value == null) {
    return '';
  }
  const stringValue = value.toString();
  return stringValue.replace(/,/g, '');
};