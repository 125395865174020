import React from "react";
// Customizable Area Start
import "./style.css";
import "./homepage.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  Popover
} from "@material-ui/core";
import {
  contactus1,
  star,
  contactus3,
  subarrowicon,
  videoCall,
  picture,
  arrow5,
  globe,
  arroCal
} from "./assets";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ReactECharts from 'echarts-for-react';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DashboardCardOverview from "../../../components/src/DashboardCardOverview"
import ReminderSnackbar from "../../../components/src/Snackbar.web"


// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import ClientList from "../../../components/src/ClientList";
import ClientFilter from "../../../components/src/ClientFilter";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start


  dialogPaperStyle1 = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #2DE2D5',
    padding: '19px 23px',
  };

  handleGraphSelcet = () => {
    return (
      <div style={{zIndex: 999}}>
        <IncomeOverviewSelect data-test-id="graph-overview-dropdown" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleGraphSelectClick(event)}>{this.state.selectedOption === this.selectedOption.Monthly ? 'Monthly' : 'Yearly'}
          <img src={arrow5} alt="morevert" style={{ width: '14px' }} />
        </IncomeOverviewSelect>
      </div>
    )
  }

  handlePendingDataModal = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: '15px 40px 0px 40px', textAlign: 'center', }}>
          {
            this.state.IsPendingDataReject === true ? 
            <>
             <span style={{
            color: '#fff',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 600,
          }}> 
          Are you sure you want to Decline the request?
          </span><br/>
          <span style={{
            color: '#fff',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 600,
          }}> 
          Once declined, cannot be accepted.
          </span>
            </>
            :
            <>
             <span style={{
            color: '#fff',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: 600,
          }}> 
          Are you sure you want to accept the request ?
          </span>
            </>
          }
         
          </DialogTitle>
        <DialogContent style={{
          padding: '0px!important',
          marginBottom: '0px',
          paddingTop: '15px'
        }}>
          <DialogContentText id="alert-dialog-slide-description"
            style={{
              color: 'rgb(255, 255, 255) !important',
              fontSize: '20px !important',
              fontFamily: 'Poppins !important',
              fontWeight: 500,
              marginBottom: '0px',
            }}>
            <div className="modal-button-container">

              <ButtonStyle2 data-test-id="handleCancle" onClick={() => this.dialogModelCl()}>{'Cancel'}</ButtonStyle2>
              {
                this.state.IsPendingDataReject === true ?
                <>
                <ButtonStyleDecline data-test-id="handleDeline" className="nameButton" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'rejected')}>{'Decline'}</ButtonStyleDecline>
                </>
                :
                <>
                <ButtonStyle1 className="nameButton" data-test-id="handleAccespt" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'accepted')}>{'Accept'}</ButtonStyle1>
                </>
              }
              
            </div>
          </DialogContentText>
        </DialogContent>
      </>
    )
  }
  renderProfile = () =>{
    return (
      <div  data-test-id="profileLink" style={styles.container} onClick={()=> this.goToProfilePage()}>
      <div>
        {this.state.plannerInfo.image ? (
          <img
            src={this.state.plannerInfo.image}
            style={styles.image}
            alt="profile"
          />
        ) : (
          <div style={styles.noImg}>
            {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
          </div>
        )}
      </div>
      <div style={styles.textContainers}>
        <span style={styles.nameStyle}>{this.state.plannerInfo.name}</span>
        <span style={styles.email}>{this.state.plannerInfo.email}</span>
      </div>
    </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const barOption = {
      xAxis: {
        type: 'category',
        data: this.state.selectedOption == 'month' ? ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] : this.state.serviceStatistics?.year,
        axisTick: {
          show: false,
          formatter: '{value}'
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#8C8C90',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 20,
            fontFamily: "Asap"
          },

        },

      },
      yAxis: {
        type: 'value',
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#8C8C90',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false,
          formatter: '{value}',
        },
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#1d1d1f'
          }
        },

      },
      series: [
        {
          // data: [30, 40, 45, 50, 49, 60, 70, 91, 12, 15, 20, 100],
          data: this.state.serviceStatistics?.ongoingService,
          name: "Inbound",
          itemStyle: {
            color: '#11eaf0',
            barBorderRadius: [4, 4, 0, 0],
          },
          type: "bar",
          barWidth: '20%',

        },
        {
          // data: [10, 20, 35, 40, 59, 60, 70, 50, 20, 4, 12, 60],
          data: this.state.serviceStatistics?.completedService,
          name: "Outbound",
          itemStyle: {
            barBorderRadius: [4, 4, 0, 0],
            color: '#1a697d',
          },
          type: "bar",
          barWidth: '20%',

        },
      ],
    };

    return (
      <div className="pagedashboard-wrapper2">

        <div className="header-wrapper" style={{ justifyContent: 'end' }}>

          <div className="page-info">
            <ul className="drop-menu">
             
              <li>
               {this.renderProfile()}
              </li>
            </ul>
          </div>
        </div>

        <div className="homepage_container" >

          <div
          className="left-div"
           >
            {
              this.state.plannerServiceDetails &&
              <Grid container spacing={4} 
              style={{
                marginBottom: "18px"
              }}
               data-test-id="planner-details">
                <Grid item md={6} lg={4} sm={12} style={{width:'100%'}}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.total_client} title="Total clients" operand={this.state.plannerServiceDetails.client_operand} percentage={this.state.plannerServiceDetails.client_percent} image={contactus3} />
                </Grid>
                <Grid item md={6}  lg={4}  sm={12} style={{width:'100%'}}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.active_services} title="Active Services" operand={this.state.plannerServiceDetails.service_operand} percentage={this.state.plannerServiceDetails.service_percent} image={contactus1} />
                </Grid>
                <Grid item md={6}  lg={4}  sm={12} style={{width:'100%'}}>
                  <DashboardCardOverview count={this.state.plannerServiceDetails.client_satisfaction} title="Client Satisfaction" operand={this.state.plannerServiceDetails.rating_operand} percentage={this.state.plannerServiceDetails.rating_percent} image={star} />
                </Grid>
              </Grid>
            }
            <div className="faq-wrapper">
              <div
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)', height: '390px'  }}
                data-test-id="bankaccounttab"
                className={this.state.expandBank}
              >
                <div className="mixed-chart" style={{ position: "relative" }}>
                  <div style={{ display: 'flex', alignItems: 'center' , justifyContent: 'space-between', overflowX:'auto' }}>

                    <h4 style={{ color: 'white' }}>Services Statistics</h4>
                    <div style={{ display: 'flex', marginLeft: '95px', gap: 12, alignItems: 'center'}}>
                      <div style={{ width: 22, height: 22, backgroundColor: '#11eaf0' }} />
                      <p style={{ color: '#ffffff7a', fontSize: '14px' }}>Ongoing</p>
                      <div style={{ width: 22, backgroundColor: '#1a697d', height: 22 }} />
                      <p style={{ color: '#ffffff7a', fontSize: '14px' }}>Completed</p>
                    {
                      this.handleGraphSelcet()
                    }
                    <Popover
                      open={Boolean(this.state.incomeAnchorToolTipElone)}
                      onClose={() => this.closeIncomeOverviewPopover()}
                      elevation={0}
                      anchorEl={this.state.incomeAnchorToolTipElone}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}

                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          marginTop: "10px"
                        }
                      }}
                      className="simple-tooltip"
                    >
                      <Box style={styles.actionContainer}>
                        <Typography style={styles.monthlyIncomeTxt} data-test-id="overview-month-duration-graph" onClick={() => this.handleGraphOverviewDropdown(this.selectedOption.Monthly)}>
                          Monthly
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="overview-year-duration-graph" onClick={() => this.handleGraphOverviewDropdown(this.selectedOption.Yearly)}>
                          Yearly
                        </Typography>
                      </Box>
                    </Popover>
                    </div>

                  </div>

                  <ReactECharts
                    option={barOption}
                    className="responsive-chart"
                  />
                </div>
              </div>
            </div>
            <div className="faq-wrapper">
              <div
                className={this.state.expandBank}
                data-test-id="bankaccounttab"
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)'}}
              >

                <div style={styles.tableWrapper}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center"
                  }}>
                    <h3 style={styles.heading}>Clients</h3>
                    <ClientFilter focustList={this.state.focustList} filters={this.state.filter} handleFilter={this.handleFilter} activeTab={this.state.activeTab} />

                  </div>

                  <div style={styles.clientTabContainer}>
                    <div data-test-id="pending-service" style={this.isTabActive('pending') ? styles.activeTabStyle : {}} onClick={() => {
                      this.handleTabChange("pending")
                    }}>
                      <p>Pending</p>
                    </div>
                    <div data-test-id="in-progress-service" style={this.isTabActive("in_progress") ? styles.activeTabStyle : {}} onClick={() => {
                      this.handleTabChange("in_progress")
                    }} >
                      <p>In Progress</p>
                    </div>
                    <div data-test-id="completed-service" style={this.isTabActive('completed') ? styles.activeTabStyle : {}} onClick={() => {
                      this.handleTabChange("completed")
                    }}>
                      <p>Completed</p>
                    </div>
                  </div>
                  <ClientList goToClientPage={this.goToClientPage} clientServices={this.state.clientServices} activeTab={this.state.activeTab} page={this.state.currentPage} totalRecord={this.state.totalRecords} rowPerPage={4} clients={this.state.clients} handlePageChange={this.handlePageChange} />
                </div>

              </div>
            </div>
            <div className="faq-wrapper">
              <div
                className={this.state.expandBank}
                data-test-id="bankaccounttab"
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              >

                <div className="mixed-chart" style={{ position: "relative" }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4 style={{ color: "white" }}>Income Overview</h4>
                    <IncomeOverviewSelect data-test-id="income-overview-dropdown" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleIncomeSelectClick(event)}>{this.state.incomeOverviewDuration === this.IncomeOverviewDuration.Monthly ? "Monthly" : "Yearly"}
                      <img src={arrow5} alt="morevert" style={{ width: '14px' }} />
                    </IncomeOverviewSelect>

                    <Popover
                      open={Boolean(this.state.incomeAnchorToolTipEl)}
                      elevation={0}
                      anchorEl={this.state.incomeAnchorToolTipEl}
                      onClose={() => this.closeIncomeOverviewPopover()}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className="simple-tooltip"
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          marginTop: "10px"
                        }
                      }}
                    >
                      <Box style={styles.actionContainer}>
                        <Typography style={styles.monthlyIncomeTxt} data-test-id="income-overview-month-duration" onClick={() => this.handleIncomeOverviewDropdown(this.IncomeOverviewDuration.Monthly)}>
                          Monthly
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="income-overview-year-duration" onClick={() => this.handleIncomeOverviewDropdown(this.IncomeOverviewDuration.Yearly)}>
                          Yearly
                        </Typography>
                      </Box>
                    </Popover>
                  </div>
                  <Chart
                    data-test-id="income-overview-chart"
                    height={"366px"}
                    options={{
                      chart: {
                        id: "basic-area",
                        toolbar: {
                          show: false,
                        },
                      },
                      xaxis: {
                        categories: this.state.incomeOverviewData.xaxis,
                      },
                      yaxis: {
                        labels: {
                          formatter: this.formatIncomeOverviewValue
                        },
                        tickAmount: 5,
                      },
                      grid: {
                        borderColor: "#1d1d1f",
                        strokeDashArray: 5,
                      },
                      colors: ["#1a697d", "#00E396"],
                      plotOptions: {
                        area: {
                          fillTo: "origin",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      legend: {
                        position: "top",
                      },
                      stroke: {
                        curve: "smooth",
                      },
                    }}
                    series={[{
                      name: "Income",
                      data: this.state.incomeOverviewData.data,
                    }]}
                    type="area"
                  />

                </div>
              </div>
            </div>
          </div>

          <div className="faq-wrapper right-div" 
          >
            <div
              className={this.state.expandBank}
              style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              data-test-id="bankaccounttab"
            >
              <CalendarHeaderWrapper>
                <CalendarMonthDetails>{this.state.weekDetails}</CalendarMonthDetails>
                <div style={{
                  display:"flex",
                  justifyContent:"space-betweem",
                  alignItems:"center"
                }}>
                  <CalendarPrevImage data-test-id="calendarPrevBtn" src={arroCal} alt="arrocal" onClick={() => this.handlePreviousWeek()} />
                  <CalendarNextImage data-test-id="calendarNextBtn" src={arroCal} alt="arrocal" onClick={() => this.handleNextWeek()} />
                </div>
              </CalendarHeaderWrapper>

              <CalendarContainer>
                <div className="single-number day">Mon</div>
                <div className="single-number day">Tue</div>
                <div className="single-number day">Wed</div>
                <div className="single-number day">Thu</div>
                <div className="single-number day">Fri</div>
                <div className="single-number day">Sat</div>
                <div className="single-number day">Sun</div>

                {this.state.weekDays.map((weekDay) => (
                  <CalendarDate
                    data-test-id="calendarDate"
                    key={weekDay.day}
                    style={weekDay.date === this.state.selectedDate ? styles.dateSelected : {}}
                    onClick={() => this.handleDateSelected(weekDay.date)}
                  >
                    {weekDay.day}
                  </CalendarDate>
                ))}
              </CalendarContainer>

              {this.state.scheduledCalls.length > 0 &&
                this.state.scheduledCalls.map((details,index) => (
                  <CallContainer key={index}>
                    <CallDetailsWrapper>
                      <div>
                        <CallImage src={videoCall} alt="contactus3" />
                      </div>
                      <CallClientDetails>
                        <CallClientName>
                          {details.client ? details.client : "Client Name"}
                          <CallClientSlot>{details?.displayTime}</CallClientSlot>
                        </CallClientName>
                      </CallClientDetails>
                    </CallDetailsWrapper>
                    <div>
                      <CallJoinBtn
                        style={this.getButtonStyle(details, this.state.currentAppointment, styles.ActiveCallJoinBtn, styles.defaultButton)}
                        data-test-id={`joinCall-${index}`}
                        disabled={this.isButtonDisabled(details, this.state.currentAppointment)}
                        onClick={()=>{this.handleVideoCall(details)}}
                      >
                        Join call
                      </CallJoinBtn>
                    </div>
                  </CallContainer>
                ))}

              <div style={{ display: 'flex', justifyContent:"space-between", alignItems: 'center' }}>

                <h4 style={{ color: 'white' }}>Activities</h4>
                <p style={{ fontSize: '10px', color: '#ffffff85' }}>Today</p>
              </div>

              <div>
                {this.state.pendingData.map((pendingData: any) => (
                  <div
                    key={pendingData.account_id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: "6%",
                      borderBottom: "1px solid #ffffff85",
                      color: '#8C8C90',
                      fontSize: '11px',
                    }}
                  >
                    <div style={{
                      height: '30px',
                      width: '30px',
                      backgroundColor: '#1a697d',
                      borderRadius: '50%',
                      display: 'flex',
                      color: 'white',
                      marginTop: '16px',
                      justifyContent:'center'
                    }}>
                      {getFirstCharacterFromEachWord(pendingData?.account_name)}
                      </div>
                    <div style={{ color: 'white' }}>
                      <p style={{ display: 'grid' }}>New Request
                        <span style={{ color: '#ffffff78', marginTop: '-4px', fontSize: '10px', textTransform: "capitalize" }}>{pendingData.account_name} </span>
                        <span style={{ marginTop: '-4px', color: '#ffffff78', fontSize: '10px', }}> {pendingData.created_at}</span>
                      </p>
                      <div style={{ display: 'flex', gap: '23%', marginTop: '-14px' }}>
                        {/* <p style={{ color: 'green', cursor: 'pointer' }} data-test-id="userAcceptDecline" onClick={() => this.userAcceptDecline(pendingData.account_id.toString(), 'accepted')}>Accept</p> */}
                        <p style={{ color: 'green', cursor: 'pointer',fontWeight:700, fontSize:'12px' }} data-test-id="userAcceptDecline" onClick={() => this.HendlePendindata(pendingData.account_id.toString())}>Accept</p>

                        <p style={{ color: 'red', cursor: 'pointer',fontWeight:500, fontSize:'12px' }} data-test-id="userAcceptDecline2" onClick={() => this.HendlePendindataReject(pendingData.account_id.toString())}>Decline</p>

                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div>
                {this.state.pendingData1 && (
                  <div
                    style={{
                      gap: "6%",
                      justifyContent: "flex-start",
                      color: '#8C8C90',
                      borderBottom: "1px solid #ffffff85",
                      fontSize: '11px',
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                  
                    <div style={{ color: 'white' }}>
                      <p style={{ display: 'grid' , fontSize: '14px', }}>
                      {this.state.pendingData1}
                        {/* <span style={{ color: 'red', marginTop: '-4px', fontSize: '10px', }}>{this.state.pendingData1} </span> */}
                      </p>

                    </div>
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
        <Dialog
          open={this.state.openModel}
          keepMounted
          data-test-id="dialogModelCl"
          onClose={this.dialogModelCl}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogPaperStyle1 }}
        >
          {
            this.handlePendingDataModal()
          }
        </Dialog>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const CalendarContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  fontSize: '12px',
  gap: '7%',
  color: '#A7AEC1',
  marginTop: '-12px',
  marginBottom: "35px",
  textAlign: "center",
  overflow:"auto",
  padding:"12px",
  overflowY:"hidden"
})

const CalendarDate = styled(Box)({
  color: "#FFFFFF",
  fontSize: "10px",
  lineHeight: "21px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "50%",
  aspectRatio: 1
})

const CallContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1px solid #00000096",
  color: '#8C8C90',
  fontSize: '11px',
  marginTop: "16px",
})

const CallDetailsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  gap: "6%",
  marginBottom: "16px",
})

const CallImage = styled('img')({
  height: "42px",
  width: '42px',
  objectFit: 'cover'
})

const CallClientDetails = styled(Box)({
  color: 'white'
})

const CallClientName = styled(Typography)({
  display: 'grid',
  fontSize: "14px"
})

const CallClientSlot = styled('span')({
  color: '#ffffff78',
  marginTop: "4px",
  fontSize: "12px"
})

const CallJoinBtn = styled('button')({
  backgroundColor: "#A7AEC11A",
  color: "#A7AEC1",
  borderRadius: "5px",
  padding:'6px 12px',
  outline: "none",
  border: "none"
})

const CalendarHeaderWrapper = styled(Box)({
  display: 'flex',
  justifyContent: "space-between",
  alignItems: "center",
  color: 'white',
  marginBottom: '35px'
})

const CalendarMonthDetails = styled('h4')({
  color: 'white',
  marginTop: '0px',
  marginBottom: '0px',
  fontSize: "18px"
})

const CalendarPrevImage = styled('img')({
  height: "36px",
  marginTop: 0,
  width: '36px',
  objectFit: 'cover',
  marginRight: '4px',
  cursor: "pointer"
})

const CalendarNextImage = styled('img')({
  height: "36px",
  marginTop: 0,
  width: '36px',
  objectFit: 'cover',
  transform: 'rotate(180deg)',
  cursor: "pointer",
  marginLeft: "4px"
})
const ButtonStyle1 = styled(Button)({
  borderRadius: "8px", background: "#C8F9F6", color: "#000", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
    backgroundColor: "#395D6B",
  },
})
const ButtonStyleDecline = styled(Button)({
  borderRadius: "8px", textAlign: "center", fontFamily: "Poppins",border: '1px solid #c44d4d', color: "#c44d4d", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
   
  },
})
const ButtonStyle2 = styled(Button)({
  borderRadius: "8px", border: '1px solid #2DE2D5', color: "#fff", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

  },
})
const IncomeOverviewSelect = styled(Box)({
  backgroundColor: 'transparent',
  appearance: 'none',
  color: 'white',
  width: '113px',
  borderRadius: '5px',
  border: '1px solid #f3ecec6e',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingLeft: '9px',
  paddingRight: '9px',
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center',
  gap: '10%',
  cursor: "pointer"
})

const styles = {
  defaultButton:{},
  faqWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  expandBank: {
    border: '2px solid rgba(192, 182, 182, 0.1)',
  },
  tableWrapper: {
    flex: 1,
    marginLeft: '3%',
  },
  heading: {
    color: 'white',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '3%',
    borderBottom: '1px solid #ffffff78',
    padding: '2%',
    fontSize: '13px',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
    color: 'white',
  },
  columnHeader: {
    color: '#ffffff80',
  },
  column: {
    flex: 1,
  },
  actionColumn: {
    textAlign: 'center',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '3%',
    color: '#8C8C90',
    fontSize: '10px',
    alignItems: 'center',
  },
  paginationItem: {
    border: '2px solid rgb(44, 226, 213)',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '2px',
    paddingBottom: '2px',
    borderRadius: '8px',
  },
  dateSelected: {
    backgroundColor: "#1A6882",
  },
  ActiveCallJoinBtn: {
    backgroundColor: "#066DE61A",
    color: "#066DE6"
  },
  actionContainer: {
    backgroundColor: "#395d6b",
    padding: "1rem",
    borderRadius: "14px",
    zIndex: 999
  },
  monthlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer"
  },
  yearlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    marginTop: "1rem",
    cursor: "pointer"
  },
  clientTabContainer: {
    color: '#ffffff80', display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2% 0px 0px',
    gap: '10%',
    borderBottom: '1px solid #ffffff78',
    fontSize: '13px',
    cursor: "pointer"
  } as React.CSSProperties,
  activeTabStyle: { color: 'white', marginBottom: -1, borderBottom: '3px solid rgb(44, 226, 213)' } as React.CSSProperties,
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "2CE2D5",
  }as React.CSSProperties,
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  }as React.CSSProperties,
  image: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  textContainers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  }as React.CSSProperties,
  nameStyle: {
    fontSize: "14px",
    color: "white",
  },
  email: {
    fontSize: "12px",
    marginTop:"-8px",
    color: "#ffffff78",
  },
};
// Customizable Area End
