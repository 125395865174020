import React from "react";

// Customizable Area Start

import { Typography, Box } from "@material-ui/core";
import { logo } from "../../otp-input-confirmation/src/assets"
import OtpInput from 'react-otp-input';
import { tagCross } from "./assets"
import "../../otp-input-confirmation/src/style.css"
import { NavLink } from "react-router-dom";

// Customizable Area End

import PasscodeLockController, {
    Props,
} from "./PasscodeLockController";

export default class SetupNewPin extends PasscodeLockController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <style>
                    {`
                    .verification-code input {
                        width: 65px !important;
                    }
                `}
                </style>
                <div className="main-wrapper" id="setupNewPinMainWrapper" style={{ flexDirection: 'column', justifyContent: 'center' }}>

                    <div className="verification-wrapper" id="setupNewPinVerificationWrapper" style={{ marginTop: '30px' }}>
                        <div className="verify-box" id="setupNewPinVerifyBox" style={{ height: '100%' }}>
                            <div className="logo-right" id="LogoRightSNP">
                                <a href="#"><img id="SNPLogo" src={logo} alt="logo" /></a>
                            </div>
                            <div className="verification-block" id="SNPVerificationBlock" data-test-id="englishLanguageScreen">
                                <Box id="SNPBox">
                                    <div id="VerificationCodeSNP" className="verification-code">
                                        <Typography id="TextLableForSNP" style={{ ...webStyleSNP.textLabels }}>Set up new PIN</Typography>
                                        <OtpInput
                                            value={this.state.newPin}
                                            data-test-id="OtpField"
                                            inputType="number"
                                            numInputs={4}
                                            shouldAutoFocus={true}
                                            renderSeparator={<span></span>}
                                            onChange={(value) => this.updateStateForPin(value, 'change', 'old')}
                                            renderInput={(props) => <input {...props} />}
                                            inputStyle={{
                                                background: "none",
                                                marginRight: "30px",
                                                textAlign: 'center',
                                                margin: 'auto',
                                                height: "62px",
                                                width: "75%",
                                                opacity: 1,
                                                border: "1px solid #2DE2D5",
                                                borderRadius: "12px",
                                                color: "#fff",
                                                fontSize: "16px",
                                            }}
                                        /></div>
                                    <Box className="SNPBtnsBox">
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', marginLeft: '0.4rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress1" data-test-id="press1" onClick={() => this.updateStateForPin('1', 'click', "old")}>1</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress2" data-test-id="press2" onClick={() => this.updateStateForPin('2', 'click', "old")}>2</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress3" data-test-id="press3" onClick={() => this.updateStateForPin('3', 'click', "old")}>3</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', marginLeft: '0.4rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress4" data-test-id="press4" onClick={() => this.updateStateForPin('4', 'click', "old")}>4</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress5" data-test-id="press5" onClick={() => this.updateStateForPin('5', 'click', "old")}>5</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress6" data-test-id="press6" onClick={() => this.updateStateForPin('6', 'click', "old")}>6</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', marginLeft: '0.4rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress7" data-test-id="press7" onClick={() => this.updateStateForPin('7', 'click', "old")}>7</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress8" data-test-id="press8" onClick={() => this.updateStateForPin('8', 'click', "old")}>8</Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress9" data-test-id="press9" onClick={() => this.updateStateForPin('9', 'click', "old")}>9</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', marginLeft: '0.4rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPbackBtn" data-test-id="backBtn" onClick={() => this.backBtn()}>
                                                <img src={tagCross} style={{ paddingRight: '0px', height: '25px' }} />
                                            </Box>
                                            <Box style={webStyleSNP.numberbtnsSNP} id="SNPpress0" data-test-id="press0" onClick={() => this.updateStateForPin('0', 'click', "old")}>0</Box>
                                            <Box style={webStyleSNP.numberbtns2SNP} ><NavLink to="/ForgotPin">Cancel</NavLink></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className="confirmBtnForSNP">
                        <div data-test-id='NextScreenRedirectionSNP' id="NextScreenRedirectionSNP" onClick={() => this.redirectToNextScreen('change')} style={{
                            marginTop: '20px', padding: '5px 50px', cursor: 'pointer', backgroundColor: '#C9F9F6',
                            color: '#3C3C50', fontWeight: 700, borderRadius: '5px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '16rem'
                        }}>Confirm</div></div>
                </div>
            </>


        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleSNP = {
    numberbtns2SNP: { cursor: 'pointer', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    numberbtnsSNP: { cursor: 'pointer', background: '#3C3C50', filter: 'drop-shadow(0px 2px 10px rgba(45, 226, 213, 0.20))', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', borderRadius: '30px', alignItems: 'center' },
    textLabels: { color: '#fff', fontWeight: 700, fontSize: '18px', marginLeft: '1rem' },
};
// Customizable Area End
