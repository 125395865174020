import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import "../assets/border.css";
import './MyneSubscription.css';
import { imgMobileVisible, bankimg, imgAsset, imgAccess, imgInvestment, imgMyneLogo } from "./assets";

// Customizable Area End

import FreetrialController, {
  Props,
  configJSON,
} from "./FreetrialController";

export default class Freetrial extends FreetrialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  freetrialBackground = () => { return this.state.freetrialid ? '#40e0d0' : '#3c3c51' };
  freetrialText = () => { return this.state.freetrialid ? 'black' : 'white' };
  monthlyBackground = () => { return this.state.monthlyid ? '#40e0d0' : '#3c3c51' };
  monthlyText = () => { return this.state.monthlyid ? 'black' : 'white' };
  yearlyBackground = () => { return this.state.yearlyid ? '#40e0d0' : '#3c3c51' };
  yearlyText = () => { return this.state.yearlyid ? 'black' : 'white' };
  handleButtonClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      freetrialid: id,
      monthlyid: null,
      yearlyid: null
    });
  }
  handleButtonMonthlyClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      freetrialid: null,
      yearlyid: null,
      monthlyid: id
    });
  }
  handleButtonYearlyClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      freetrialid: null,
      yearlyid: id,
      monthlyid: null
    });
  }
  handleTermsAndConditionsClick = () => {
    window.location.href = "/TermsConditions";
  }


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ ...styles.mainBankWrapper, overflowY: 'auto' }} dir={this.state.languagesLogin}>
        <div className="borderCss2 bankIntegrationWrapper2">
          <div style={{ position: 'absolute', zIndex: 12, right: '20px', top: '20px', }}>
            <img src={imgMyneLogo} style={{ width: 100 }} />
          </div>
          <div>
            <Grid container>
              <Grid item xs={12} md={5} className="subscription-left">
                <div style={{ textAlign: 'center', marginTop: '36px', width: '100%' }}>
                  <h4 style={styles.textContentH4}><b> {this.getStringLogin('createAccountMyne')}<br />
                     {this.getStringLogin('extraFeature')}</b></h4>
                  <h2> <span style={{ color: '#40e0d0' }}>{this.getStringLogin('premium')}</span> {this.getStringLogin('subscription')}</h2>

                  <div style={{ display: "flex", gap: '20px', justifyContent: "space-evenly", }}>
                    <div> <img src={bankimg} style={{ width: 50 }} />
                      <p style={styles.textContentP}> {this.getStringLogin('unlimitedBank')}<br />
                         {this.getStringLogin('walletSync')}</p>
                    </div>
                    <div><img src={imgAsset} style={{ width: 50 }} />
                      <p style={styles.textContentP}> {this.getStringLogin('accessToAll')}<br />
                        {this.getStringLogin('typesOfAssets')}</p>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: '20px', marginTop: 22, justifyContent: "space-evenly" }}>
                    <div> <img src={imgAccess} style={{ width: 50 }} />
                      <p style={styles.textContentP}> {this.getStringLogin('fullAccessTo')}<br />
                         {this.getStringLogin('estatePlanning')}</p></div>
                    <div> <img src={imgInvestment} style={{ width: 50 }} />
                      <p style={styles.textContentP}> {this.getStringLogin('fullAccess')}<br />
                         {this.getStringLogin('toInvestment')}</p></div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7} className="subscription-right">
                <div style={{ marginTop: '36px', width: '100%', textAlign: 'center' }}>
                  <p style={{ fontSize: 24, textAlign: 'center', marginTop: 0 }}>
                    <span style={{ color: '#40e0d0' }}> {this.getStringLogin('choose')}</span>  {this.getStringLogin('plan')}
                  </p>
                  <div >
                    <button
                      onClick={() => this.handleButtonClick(this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('tria'))?.id)}

                      data-test-id="testFreeTrialId"
                      style={{
                        border: '1px solid gray',
                        background: this.freetrialBackground(),
                        color: this.freetrialText(),
                        padding: '20px 10px',
                        margin: 'auto',
                        borderRadius: 10,
                        cursor: 'pointer',
                        width: '100%', textAlign: 'center'
                      }}
                    >
                      <b> {this.getStringLogin('start')}  {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('tria'))?.attributes.name}  </b> {this.getStringLogin('for30Days')}
                    </button>
                  </div>
                  <div style={{ position: 'relative', marginTop: 10 }}>
                    <hr style={{
                      position: 'absolute',
                      width: '100%',
                      height: '1px',
                      top: '16px',
                    }} />
                    <p style={{ width: 80, background: '#2b3d47', position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>{this.getStringLogin('or')}</p>
                  </div>

                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", backgroundColor: "#5D3FD3", border: "2px solid #5D3FD3", borderRadius: "5px", display: "inline", right: this.state.languagesLogin === 'rtl'? '' : "22px", left:this.state.languagesLogin === 'rtl'?'22px': '', bottom: "60px", width: "140" }}>
                      {this.getStringLogin('recommended')}
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginTop: 60, border: this.state.yearlyid || this.state.monthlyid ? "2px solid #40e0d0" : "unset", borderRadius: "10px" }} >
                      <div style={{
                        width: '100%', textAlign: 'center', border: '1px solid gray', background: this.monthlyBackground(),
                        color: this.monthlyText(), padding: '10px 5px', margin: 'auto', borderRadius: this.state.languagesLogin === 'rtl'? '0px 10px 10px 0px':'10px 0px 0px 10px', cursor: 'pointer'
                      }} onClick={() => this.handleButtonMonthlyClick(this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('month'))?.id)}
                        data-test-id="testMonthlyId"

                      >
                        {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('month'))?.attributes?.name} <br /> <b>{this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('month'))?.attributes?.amount} {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('month'))?.attributes?.currency?.toUpperCase()}/mo</b>
                      </div>
                      <div style={{
                        width: '100%', textAlign: 'center', border: '1px solid gray', background: this.yearlyBackground(),
                        color: this.yearlyText(), padding: '10px 5px', margin: 'auto', borderRadius: this.state.languagesLogin === 'rtl'? '10px 0px 0px 10px':'0px 10px 10px 0px' , borderLeft: '1px solid white', cursor: 'pointer'
                      }} onClick={() => this.handleButtonYearlyClick(this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('year'))?.id)}
                        data-test-id="testYearlyId"

                      >
                        {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('year'))?.attributes?.name} <br /><b> {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('year'))?.attributes?.amount} {this.state.deatailsData.find((item) => item.attributes.frequency.startsWith('year'))?.attributes?.currency?.toUpperCase()}/yr</b>
                      </div>
                    </div>
                  </div>

                  <div style={{ maxWidth: 220, width: '100%', textAlign: 'center', border: '1px solid gray', background: this.state.isButtonClicked ? "#C9F9F6" : '#728781', color: "black", padding: '5px 10px', margin: 'auto', borderRadius: 10, marginTop: 20, cursor: "pointer" }} onClick={() => { this.handleNavigate() }}>
                    {this.getStringLogin('continue')}
                  </div>
                  <p style={{ marginTop: '70px', fontSize: "9px", color: "#B2BEB5" }}>{this.getStringLogin('after30DaysTrialEnds')} </p>
                  <p style={{ fontSize: "9px" }}> {this.getStringLogin('proceedingToPurchase')} <a href="/TermsConditions" onClick={this.handleTermsAndConditionsClick} style={{ textDecoration: "underline white", cursor: "pointer", color: "white" }}>
                    {this.getStringLogin('termsAndConditions')}
                  </a> </p>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  root: {
    width: "100%",
  },
  connectBlock: {
    minWidth: "920px",
  },
  bankIntegrationBox: {
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    backdropFilter: "blur(13.5914px)",
    borderRadius: "32px",
    width: "100%",
    height: "540px",
    position: "relative",
    overflow: 'hidden',
  },
  textContent: {
    textAlign: "center",
    position: 'absolute',
    top: 100,
    width: 320,
    zIndex: 11,
  },
  mainBankWrapper: {
    background: "#3c3c50",
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    margin: 0,
    lineHeight: 1.6,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    height: "100vh",


  },

  bankIntegrationWrapper: {
    position: "relative",
    minWidth: "800px",
    // margin: 0,
    padding: "2px",
    overflow: "hidden",
    margin: 'auto',

  },
  bankIntegrationCustomCSS: {
    content: "",
    position: "absolute",
    top: 0,
    left: 0,
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "32px",
    // backgroundImage: "linear-gradient(to right, rgba(245, 246, 252, 0), rgba(0, 0, 0, 1))",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage: "linear-gradient(to right, rgb(255 255 255 / 0%) 10%, #2b3d47 50%)",
    zIndex: 1,
    borderRadius: "32px",
  },
  dFlex: {
    display: "flex",
  },
  textContentH4: {
    fontSize: "14px",
    fontWeight: 400,
    display: "inline-block",
    margin: 0,
  },
  textContentP: {
    margin: "15px 0",
  },
  imgContentStyle: {
    backgroundSize: "cover",
    borderRadius: "32px 0 0 32px",
    backgroundPosition: "center center",
    width: "40%",
    position: 'relative',
    height: "100%",
  },
  bgImage: {
    position: 'absolute',
    zIndex: -1,
    height: '100%',
    objectFit: 'cover',
  }

};
// Customizable Area End
