import React from "react";
// Customizable Area Start
import "./style.css";
import {
    bankimage3,
    logoNew,
    settingicon,
    subarrowicon,
} from "./assets";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@material-ui/core";

interface PortfolioItem {
    id: number;
    name: string;
    balance: number;
    operand: string;
    capital_gain: number;
    entity: string;
    cost: number;
    portfolio_id: string;
    currency: string;
    price: string;
    symbol: string;
    difference: string;
    percentage: string;
  }

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import { addCommas,addCommas2 } from "../../../components/src/common";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.getStockData()
        // Customizable Area End
    }
    // Customizable Area Start
    renderDir =()=>{
        return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
      }
    stockDataListing = (portfolioData: PortfolioItem[]) => {
        return (
            <div className="faq-content" id="stockListing">
                {portfolioData
                    && portfolioData.map((value) => {
                        return (
                            <>
                                <div className="faq-content-wrapper" id="faqContentWrapper">
                                    <img
                                        data-test-id="iconofstock"
                                        src={logoNew}

                                        alt="bank-account"
                                        width="45"
                                        height="45"
                                    />
                                    <div
                                        data-test-id="faqcontentforstock"
                                        className="faq-inner-content"
                                    >
                                        <p>{value.name}</p>
                                        <span className="stock">
                                            {value
                                                .symbol +
                                                addCommas(value.price)}
                                        </span><br />
                                        <span className={value.operand == '+' ? "plusStock" : "minusStock"}>
                                            {value
                                                .operand +
                                                value.difference + `(${value.percentage}%)`}
                                        </span>
                                    </div>
                                </div>
                            </>
                        );
                    })
                }

            </div>
        )
    }
    imgStyleDirection = () => this.state.direction === 'rtl' ? 'scaleX(-1)' : ''
    loaderForStocks=()=>{
        return this.state.loadingStockData2 &&
        <div style={webStyle.loaderCss}>
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress style={{ color: "#FFF" }} />
                </div>
            </div>
        </div>

    }
    stockDataCheck=()=> this.state.stockData && this.state.stockData.length > 0;
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div id="stockDetails" style={webStyle.scrollBarStyle} dir={this.renderDir()}>
                <div className="header-wrapper" id="stockDetailsWrapper">
                    <div className="page-title" id="pageTitleForStockDetails">
                        <h4 className="stockTitleAndRedirection">
                            <NavLink to="/dashboard"><img src={backIcon} alt="back-icon" style={{transform: this.imgStyleDirection()}} /></NavLink> &nbsp;
                            {this.getStringStocks("Stocks")}</h4>
                    </div>
                    <div data-test-id="pageInfo" className="page-info">
                        <ul className="drop-menu" id="dropdownMenu">
                            <li className="stockDetailCurrencyChange">
                                <div
                                    data-test-id="currencyDropdoen"
                                    id="customDiv"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity}
                                >
                                    <p data-test-id="selectedCuurencyInStockDetails">
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul className="listOfCurrencyInStockDetails">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li id="settingRedirectionFromStockDetails">
                                <NavLink id="navLinkFromStockDetails" to="/Account-Detail">
                                    <img data-test-id="settingLogoInStockDetails" src={settingicon} alt="setting" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                {this.loaderForStocks()}
                {this.stockDataCheck()&&
                     <div className="content-wrapper" id="StockDetailContentWrapper">
                    <InfiniteScroll
                        dataLength={this.state.stockData.length}
                        next={this.getStockData}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="stockDetails"
                    >
                    {this.state.stockData.map((stockValue) => (
                        <div className="assets-block" id="StockDetailassetsBlock">
                         <div className="assets-block-content" id="assetsBlockInStockDetails">
                             <div className="faq-wrapper" data-test-id="faqWrapperInStockDetails">
 
                                 <div
                                     className="faq-block active"
                                     data-test-id="stocksDetails"
                                 >
                                     <div className="faq-heading faq-heading-3 stocksDetails">
                                         <div className="faq-inner-heading fih-StocksDetails">
                                                <img
                                                    src={stockValue?.icon_url || bankimage3}
                                                    alt="bankimage"
                                                    className="circular-image"  style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                             <div className="faq-inner-heading-content" id="innerContentInStocksDetails">
                                                 <p>{stockValue.full_name}</p>
                                                {
                                                    stockValue.port_folio ? <span>{stockValue.port_folio.length} {stockValue.port_folio.length > 1 ? this.getStringStocks("Assets") : this.getStringStocks("Asset")}</span> :
                                                        <span style={webStyle.lineHeight} className={stockValue.operand == '+' ? this.getStringStocks("plusStock") : this.getStringStocks("minusStock")}>
                                                            {stockValue
                                                                .operand +
                                                                stockValue.difference + `(${stockValue.percentage}%)`}
                                                        </span>
                                                }
                                             </div>
                                         </div>
                                         <div data-test-id="stockDetailsBalance" className="faq-heading-content faq-heading-content3">
                                             <div className="inner-info" id="sdInnerInfo">
                                                 <span>{this.getStringStocks("Balance")}</span>
                                                 <p className="overviewStockSymbol">
                                                     {stockValue.symbol} {stockValue.price ? addCommas2(stockValue.price) : ( stockValue.total_balance && addCommas2(stockValue.total_balance.toString()))}
                                                 </p>
                                             </div>
 
                                         </div>
                                     </div>
                                     {stockValue.port_folio && this.stockDataListing(stockValue.port_folio)}
                                 </div>
                             </div>
                         </div>
                     </div>
                    ))}
                    </InfiniteScroll>
                 </div>
                }
               
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle= {
    container :{
        display:"flex",
        marginBottom:"14px",
        alignItems:"center",
    },
    loaderCss: {
        transform: 'translateY(-110px)'
    },
    lineHeight: {
        lineHeight:"24px"
    },
    scrollBarStyle :{ height: "100vh", overflow: "auto", width: "100%",padding:"50px 50px 50px 50px" , background: "rgba(0, 0, 0, 0.3)"}

}

// Customizable Area End
