export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const goal1 = require("../assets/images/goal1.svg")
export const goal2 = require("../assets/images/goal2.png")
export const goal3 = require("../assets/images/goal3.png")
export const goal4 = require("../assets/images/goal4.png")
export const goal5 = require("../assets/images/goal5.png")
export const goal6 = require("../assets/images/goal6.png")
export const goal7 = require("../assets/images/goal7.png")
export const goal8 = require("../assets/images/goal8.png")
export const goal9 = require("../assets/images/goal9.png")
export const goal10 = require("../assets/images/goal10.svg")
export const goal11 = require("../assets/images/goal11.png")
export const goal12 = require("../assets/images/goal12.png")
export const goal1_2 = require("../assets/images/goal1-2.png")
export const goal2_2 = require("../assets/images/goal2-2.png")
export const goal3_2 = require("../assets/images/goal3-2.png")
export const goal4_2 = require("../assets/images/goal4-2.png")
export const goal6_2 = require("../assets/images/goal6-2.png")
export const goal7_2 = require("../assets/images/goal7-2.png")
export const goal8_2 = require("../assets/images/goal8-2.png")
export const goal5_2 = require("../assets/images/goal5-2.png")
export const goal9_2 = require("../assets/images/goal9-2.png")
export const goal11_2 = require("../assets/images/goal11-2.png")
export const goal12_2 = require("../assets/images/goal12-2.png")
export const sidearrow1 = require("../assets/images/sidearrow.png")
export const backButton = require("../assets/images/back-icon.png")
export const settingg = require("../assets/images/settingicon.png")
export const progressImage = require("../assets/images/image_hourglass.png")
export const logoImg = require("../assets/images/logo.png")









