import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    show: string,
    isUp: boolean,
    FindData: any,
    rating_and_reviews_Model: any,
    showName: boolean,
    isPopupOpenNew: boolean,
    EngagementRequest: any,
    EngagmentReject: any
    isPopupOpenModel: boolean
    permission: null | boolean,
    expanded: any,
    data: any,
    selectedServices: any,
    serviceData: any,
    isSeleted: boolean,
    setServices: any,
    cardId: string | number,

    // Customizable Area End
}

interface SS {
    id: any;
}

export default class EngagementServiceProposalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserfinancialPlanner: string = ""
    postUserPDFDataCallId: string = "";
    postUserfinancialPlannerPost: string = "";
    EngagementRequestPost: string = "";
    EngagementRequestDataGet: string = "";
    paymentData: string = "";
    postUserfinancialPermissionId: string = "";
    postUserSetStatus: string = ""
    postUsersubTotal: string = ""

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            EngagmentReject: [],
            isPopupOpenNew: false,
            isPopupOpenModel: false,
            permission: null,
            EngagementRequest: [],
            rating_and_reviews_Model: [],
            FindData: [],
            show: "",
            isUp: true,
            showName: false,
            expanded: null,
            selectedServices: [],
            setServices: [],
            serviceData: null,
            isSeleted: false,
            cardId: '',
            data: {
                service: [
                    {
                        service_id: 7,
                        proposal_template_id: null,
                        name: "Creating a will",
                        image: "https://myneleap2023-321315-ruby.b321315.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5ce505da371c17b0e29e22b6e49c7f25779a0ddc/arrow%20green.png",
                        price: "550",
                        currency: "AED",
                        plan_inclusions: [
                            {
                                id: 1,
                                name: "will write up",
                                date: null,
                                status: null,
                                explanation: "1 Knowing how to write a paragraph is incredibly important. It’s a basic aspect of writing, and it is something that everyone should know how to do. There is a specific structure that you have to follow when you’re writing a paragraph. This structure helps make it easier for the reader to understand what is going on. Through writing good paragraphs, a person can communicate a lot better through their writing.123"
                            },
                            {
                                id: 2,
                                name: "details",
                                date: null,
                                status: null,
                                explanation: "2 Knowing how to write a paragraph is incredibly important. It’s a basic aspect of writing, and it is something that everyone should know how to do. There is a specific structure that you have to follow when you’re writing a paragraph. This structure helps make it easier for the reader to understand what is going on. Through writing good paragraphs, a person can communicate a lot better through their writing.123"
                            }
                        ]
                    },
                    {
                        service_id: 8,
                        proposal_template_id: null,
                        name: "Creating a will data",
                        image: "https://myneleap2023-321315-ruby.b321315.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5ce505da371c17b0e29e22b6e49c7f25779a0ddc/arrow%20green.png",
                        price: "550",
                        currency: "AED",
                        plan_inclusions: [
                            {
                                id: 1,
                                name: "will write up data",
                                date: null,
                                status: null,
                                explanation: "1 Knowing how to write a paragraph is incredibly important. It’s a basic aspect of writing, and it is something that everyone should know how to do. There is a specific structure that you have to follow when you’re writing a paragraph. This structure helps make it easier for the reader to understand what is going on. Through writing good paragraphs, a person can communicate a lot better through their writing.123"
                            },
                            {
                                id: 2,
                                name: "details data",
                                date: null,
                                status: null,
                                explanation: "2 Knowing how to write a paragraph is incredibly important. It’s a basic aspect of writing, and it is something that everyone should know how to do. There is a specific structure that you have to follow when you’re writing a paragraph. This structure helps make it easier for the reader to understand what is going on. Through writing good paragraphs, a person can communicate a lot better through their writing.123"
                            }
                        ]
                    },
                ]
            }
            
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    async componentDidMount() {

        this.getServiceList()
        this.getPaymentData()
    }
    handleChange = (panel: any) => (event: any, isExpanded: any) => {
        this.setState({ expanded: isExpanded ? panel : null });
    };
    
    handleRemoveSelectedItem = (itemToRemove: any) => {
        this.setState(prevState => ({
            selectedServices: prevState.selectedServices.filter((item: any) => !(item.service_id === itemToRemove.service_id && item.proposal_template_id === itemToRemove.proposal_template_id)),
            setServices: prevState.setServices.filter((service: any) => !(service.service_id === itemToRemove.service_id && service.proposal_template_id === itemToRemove.proposal_template_id))
        }));
    
    };
    handleServiceSelectionItem = (item: any) => ()=> {    
        if (!this.state.selectedServices.some((service: {service_id:string}) => service.service_id === item.service_id)) {
            // If the item is checked, add it to the selected services array
            this.setState(prevState => ({
                selectedServices: [...prevState.selectedServices, item],
                setServices: [...prevState.setServices, { service_id: item.service_id, proposal_template_id: item.proposal_template_id}]
            }));
        } else {
            // If the item is unchecked, remove it from the selected services array
            this.setState(prevState => ({
                selectedServices: prevState.selectedServices.filter((service: any) => !(service.service_id === item.service_id && service.proposal_template_id === item.proposal_template_id)),
                setServices: prevState.setServices.filter((service: any) => service.service_id !== item?.service_id || service.proposal_template_id !== item?.proposal_template_id)
            }));
        }
    };
    handleSeleteItem = () => {
        this.setState({
            isSeleted: true
        })
    }
    handleSeleteBack = () => {
        this.setState({
            isSeleted: false
        })
    }
    handleClickBack () {
        this.props.navigation.goBack();
    }
    getServiceList = () => {

        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurls = configJSON.new_added_service;
        const gotDataFromApi = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurls,
            httpMethod: configJSON.send_requests_Get,
        });
        this.EngagementRequestDataGet = gotDataFromApi.messageId;
        runEngine.sendMessage(gotDataFromApi.id, gotDataFromApi);
    }

    getPaymentData = () => {

        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurls = configJSON.index_payment_method;
        const gotDataFromApi = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurls,
            httpMethod: configJSON.send_requests_Get,
        });
        this.paymentData = gotDataFromApi.messageId;
        runEngine.sendMessage(gotDataFromApi.id, gotDataFromApi);
    }


    handleSetService = () => {
        
        const cashData = localStorage.getItem("token")
        const plannerId = localStorage.getItem("plannerId")
       
        const header = {
            "token": cashData,
            "Content-Type": 'application/json',
        };
        let userDetailurl = configJSON.set_service;
        const postPdfData = apiCall({
            header: header,
            httpBody: {
                "planner_id": plannerId,
                "card_id": this.state.cardId,
                "service":this.state.setServices
            },
            url: userDetailurl,
            httpMethod: configJSON.send_allow_access_post,
        });
        this.postUserSetStatus = postPdfData.messageId;
       this.handleSubTotal();
    }

    handleSubTotal = () => {
        const cashData = localStorage.getItem("token")
        const plannerId = localStorage.getItem("plannerId")
       
        const header = {
            "token": cashData,
            "Content-Type": 'application/json',
        };
        let userDetailurl = configJSON.sub_total;
        const postPdfData = apiCall({
            header: header,
            httpBody: {
                "planner_id": plannerId,
                "service":this.state.setServices
            },
            url: userDetailurl,
            httpMethod: configJSON.send_allow_access_post,
        });
        this.postUsersubTotal = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
        
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.EngagementRequestDataGet !== null &&
            this.EngagementRequestDataGet ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {

            let EngaementRequestNewData = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            this.setState({ serviceData: EngaementRequestNewData.service })

        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.paymentData !== null &&
            this.paymentData ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {

            let paymentDataNew = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            this.setState({ cardId: paymentDataNew?.data[0].id })
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUsersubTotal !== null &&
            this.postUsersubTotal ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {

            let postUsersubTotalNew = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
          
            const servicesString = JSON.stringify(this.state.setServices);
            storage.set('sub_total', postUsersubTotalNew?.sub_total);
            storage.set('services', servicesString);
            this.props.navigation.navigate("ServicePayment", { sub_total: postUsersubTotalNew?.sub_total ,  services: this.state.setServices})
        }

        // Customizable Area End
    }


    // Customizable Area End
}
