import React, { useEffect, useMemo, useRef, CSSProperties } from "react";
import { useParticipant, useMeeting } from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { IconButton, Typography } from '@material-ui/core';
import { CallEnd } from '@material-ui/icons';
import { NoUser,shift } from '../../blocks/groupvideocall/src/assets';

const styles = {
  container: (isMain: boolean, webcamOn: boolean): CSSProperties => {
    const isSmallScreen = window.innerWidth <= 600; // Define small screen width
    return {
      position: isMain ? 'relative' : 'absolute',
      bottom: isMain ? 'auto' : (isSmallScreen ? '7%' : '20%'),
      right: isMain ? 'auto' : (isSmallScreen ? '10%' : '23%'),
      width: isMain ? (isSmallScreen ? '90%' : '80%') :(isSmallScreen ? '25%' : '20%') ,
      height: isMain ? (isSmallScreen ? '90%' : '80%') : (isSmallScreen ? '25%' : '20%'),
      overflow: 'visible', // Ensure overflow is visible to allow button to be seen outside the container
      display: 'flex',
      justifyContent: 'center',
      zIndex: isMain ? 0 : 1,
      borderRadius: '20px',
    };
  },
  noVideoContainer: (): CSSProperties => {
    const isSmallScreen = window.innerWidth <= 600; // Define small screen width
    return {
      position: 'absolute',
      width: isSmallScreen ? '90%' : '62%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      borderRadius:'10px',
    };
  },
 
  userName: {
    color: '#000000',
  } as CSSProperties,
  noUserImage: {
    marginTop: '10px',
    width: '50px',
    height: '50px',
  } as CSSProperties,
  videoPlayer: {
    borderRadius: '20px',
    position: 'absolute',
  } as CSSProperties,
  endCallButton: {
    position: 'absolute',
    bottom: '-30px',
    backgroundColor: '#ff003b',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    zIndex: 1000, // Ensure a high z-index
  } as CSSProperties,
  endCallIcon: {
    fontSize: '30px',
    color: '#f4f4f4',
  } as CSSProperties,
  toggleButton: (): CSSProperties => {
    const isSmallScreen = window.innerWidth <= 600; // Define small screen width
    return {
      width: isSmallScreen ? '70%' : '100%',
      height: isSmallScreen ? '70%' : '100%',
      position: 'absolute',
    bottom: '-55px',
    borderRadius: '50%',
    zIndex: 1000,
    };
  },
 
  toggleButtonIcon: {
    fontSize: '50px',
    color: '#fff',
  } as CSSProperties,
};

function ParticipantView({
  participantId,
  isMain,
  onMeetingleave,
  onToggleMain,
}: {
  participantId: string;
  isMain: boolean;
  onMeetingleave:()=>void;
  onToggleMain:()=>void;
}) {
  const micRef = useRef<HTMLAudioElement | null>(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);
  const { leave } = useMeeting();

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
    return "";
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error) => console.error("micRef.current.play() failed", error));
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  const handleEndCall = () => {
    leave();
  };

  return (
    <div style={styles.container(isMain, webcamOn)}>
      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn ? (
        <ReactPlayer
          playsinline
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          height={"100%"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
          style={styles.videoPlayer}
        />
      ) : (
        <div style={styles.noVideoContainer()}>
          <Typography variant="h6" style={styles.userName}>
            {displayName}
          </Typography>
          <img src={NoUser} alt="user" style={styles.noUserImage} />
        </div>
      )}
         {!isMain && (
        <IconButton style={styles.toggleButton()} onClick={onToggleMain}>
          <img src={shift} alt="Toggle" style={styles.toggleButtonIcon} />
        </IconButton>
      )}
      {isMain && (
        <>
          <IconButton onClick={handleEndCall} style={styles.endCallButton}>
            <CallEnd style={styles.endCallIcon} />
          </IconButton>
          
        </>
      )}
    </div>
  );
}

export default ParticipantView;
