Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodtype = "GET";
exports.validationApiPostMethodtype = "POST";
exports.passcodelockAPIEndpoint = "";
exports.passcodelockPlaceholder = "Passcode Lock";
exports.labelTitleText = "Passcode Lock";
exports.lebelBodyText = "Passcode Lock Body";
exports.btnExampleTitle = "Passcode Lock";
// Customizable Area End
