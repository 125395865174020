import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {
  budget4,
  budget5,
  budget6,
  appleLogo,
  googleIcon,
  homeIcon,
  facebook,
  instagram,
  twitter,
  youtube,
  linkedin,
  logo,
  ImgBack,
  bg1
} from "./assets";
import { Box, Typography } from "@material-ui/core";
// Customizable Area End

export default class InvestmentMarketplaceWeb extends React.Component {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box style={{ position: 'relative' }}>
          <div style={{ ...webStyle.ImgBackTop, position: 'absolute' }}></div>
          <div style={{ ...webStyle.ImgBackTop1, position: 'absolute' }}></div>
          <Box style={{ maxWidth: "1946", margin: "auto", }}>
            <CnvestmentBox style={{ position: 'relative', zIndex: 20 }}>
              <Box>
                <MasterStyle >
                  Investment Opportunities at{" "}
                  <span style={webStyle.masterSaving}>Your <span>Fingertips</span></span>
                </MasterStyle>
                <MasterFinances>
                  Explore a world of investment possibilities with MYNE's
                  Investment Marketplace. Access a curated selection of investment
                  opportunities that align with your financial goals. With
                  real-time market data and direct links to investment platforms,
                  making informed investment decisions has never been easier.
                </MasterFinances>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget4} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck >
                        View and Filter{" "}
                        <span style={webStyle.masterSpends}>
                          Investment <br /><span>Options</span>
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "A World of Opportunities at Your Fingertips"
                        <br /> <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Begin your investment journey by exploring a diverse
                          array of alternative investment opportunities on MYNE’s
                          platform. Use our intuitive filters to narrow down
                          options based on your investment preferences, goals, and
                          risk appetite. Find opportunities that resonate your
                          financial strategy, all in one place.
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <BoxMarginTop>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        Learn More About Each <br />
                        <span style={webStyle.masterSpends}>Opportunity</span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Informed Investing Made Simple"
                        {" "}
                        <span style={webStyle.masterCheckMonitor}>
                          For each
                          <br /><span>investment option, click 'Learn More' to dive deeper
                            into the details. Understand the nuances, potential
                            returns, risks, and the unique aspects of each
                            investment. Our comprehensive information layout ensures
                            you’re well-equipped with the knowledge you need to make
                            informed decisions.</span>
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget5} />
                  </Grid>
                </Grid>
              </BoxMarginTop>

              <Box style={{ paddingBottom: '15%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget6} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        Invest with a{" "}
                        <span style={webStyle.masterSpends}>Click</span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Seamless Transition to Investment Success"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          Once you’ve found the right opportunity, investing is
                          just a click away. Select 'Invest' to be taken directly
                          to the investment opportunity, where you can proceed
                          with your transaction. MYNE makes the transition from
                          decision to action smooth and straightforward,
                          empowering you to capitalize on investment opportunities
                          with ease.
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CnvestmentBox>
          </Box>
          <div style={{ ...webStyle.ImgBackBottem, position: 'absolute' }} className="footerwidth"></div>
          <div style={{ ...webStyle.ImgBackBottem1, position: 'absolute' }}></div>
          <Box style={{ ...webStyle.footerwidth, position: 'relative', zIndex: 20, background: "#272733", }}>
            <Box style={{ maxWidth: "1946", margin: "auto", }}>
              <AppStoreGetItOnFooter style={webStyle.footer}>
                <AppStoreGetItOnFooterHome className="footerContent" style={webStyle.footerContent}>
                  <Box className="footerImgForHome"><img style={webStyle.footerIcons} src={homeIcon} alt="" /></Box>
                  <Box className="menuItemsForFooter">
                    <span style={webStyle.menuItemsForFooter}>About us</span>
                    <span data-test-id="menuItemsForFooter" style={webStyle.menuItemsForFooter}>Contact us</span>
                    <span style={webStyle.menuItemsForFooter}>Resources</span>
                    <span style={webStyle.menuItemsForFooter} data-test-id="menuItemsForFooter">Roadmap</span>
                    <span style={webStyle.menuItemsForFooter}>Terms of use</span>
                    <span data-test-id="menuItemsForFooter" style={webStyle.menuItemsForFooter}>Cookies policy</span>
                    <span style={webStyle.menuItemsForFooter}>Privacy policy</span>
                  </Box>
                </AppStoreGetItOnFooterHome>
                <LogoForFooter className="logoForFooter">
                  <img src={logo} style={webStyle.imgLogo} alt="" />
                  <Typography style={webStyle.logoForFooter}>{" "}Who's managing your wealth?</Typography>
                </LogoForFooter>
                <Box style={{ ...webStyle.socialMediaIcons, margin: "13px 0px" }}>
                  <img src={facebook} style={webStyle.footerIcons} alt="" />
                  <img style={webStyle.footerIcons} src={instagram} alt="" />
                  <img style={webStyle.footerIcons} src={twitter} alt="" />
                  <img src={youtube} style={webStyle.footerIcons} alt="" />
                  <img style={webStyle.footerIcons} alt="" src={linkedin} />
                </Box>
                <Box style={{ ...webStyle.appleAndGoogleCards, margin: "13px 0px", flexWrap: 'wrap', justifyContent: 'center' }}>
                  <img width="151px" height="46px" src={googleIcon} alt="" />
                  <img src={appleLogo} alt="" width="151px" height="46px" />
                </Box>
              </AppStoreGetItOnFooter>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  imgLodoNew: { height: "30px" },
  imgLogo: { width: "170px", borderRadius: '0px' },
  logoForFooter: { fontSize: "10px", fontFamily: 'Helvetica !important', color: "white", paddingTop: '6.5px' },
  footerContent: { display: "flex", alignItems: "center" },
  footerwidth: { width: "100%", marginTop: "40px" },
  ImgBackBottem: { width: '65%', height: '20%', background: `url(${bg1}) no-repeat`, top: '80%', backgroundSize: 'cover', zIndex: 1 },
  masterSaving: { color: "#2ee1d5" },
  masterCheckMonitor: { color: "white" },
  masterSpends: { color: "#2ee1d5" },
  ImgBackBottem1: { width: '65%', height: '20%', zIndex: 2, top: '80%', left: 0, background: 'linear-gradient(227deg, rgb(60 60 81) 66%, rgb(60 60 81 / 0%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
  socialMediaIcons: { display: "flex", gap: "10px", alignItems: "center" },
  footer: { width: "100%", padding: "50px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" },
  footerIcons: { width: "22px" },
  ImgBackTop: { width: '65%', height: '25%', right: '-458px', background: `url(${ImgBack}) no-repeat`, backgroundSize: 'cover', zIndex: 1 },
  menuItemsForFooter: { fontSize: "12px", fontFamily: 'Helvetica !important', color: "#fff", marginLeft: "10px", cursor: "pointer" },
  appStoreFooter: { background: "black", color: "#fff", width: "130px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  googleCardFooter: { background: "black", color: "#fff", width: "150px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  appStoreGetItOnFooter: { fontSize: "clamp(0.469vw, 0.469vw, 3vw)", fontFamily: 'Helvetica !important', },
  ImgBackTop1: { width: '65%', right: '-458px', height: '25%', zIndex: 2, background: 'linear-gradient(45deg, rgb(60, 60, 81) 40%, rgba(60, 60, 81, 0.8) 60%, rgba(60, 60, 81, 0) 100%) repeat scroll 0% 0%, transparent none repeat scroll 0% 0%' },
  appleAndGoogleCards: { display: "flex", gap: "15px", marginLeft: "9px" }
};
const CnvestmentBox = styled(Box)({
  paddingLeft: "110px !important", paddingRight: "110px !important", paddingTop: "40px !important",
  "@media (min-width: 320px)": { paddingLeft: "30px !important", paddingRight: "30px !important" },
  "@media (min-width: 1200px)": { paddingLeft: "110px !important", paddingRight: "110px !important" }
});

const LogoForFooter = styled(Box)({ display: "flex", flexDirection: "column", alignItems: "flex-end" });
const MasterSavingTime = styled(Box)({
  color: "#2ee1d5", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', lineHeight: 'initial',
});
const MasterCheck = styled(Box)({
  fontSize: "clamp(1vw, 45px,2.344vw)", color: "white", fontFamily: 'Helvetica !important', lineHeight: '100%', paddingBottom: "25px !important",
});
const MasterStyle = styled(Box)({
  fontSize: "clamp(1vw,92.71px,4.829vw)", fontFamily: 'Helvetica !important', color: "white", lineHeight: "100%",
});
const MasterFinances = styled(Box)({
  color: "white", margin: "2% 0px", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', lineHeight: 'initial !important',
});
const AppStoreGetItOnFooter = styled(Box)({
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const AppStoreGetItOnFooterHome = styled(Box)({
  display: 'flex', alignItems: 'center', margin: "13px 0px", textAlign: 'center',
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const BoxMarginTop = styled(Box)({
  padding: "125px 0px",
  "@media (max-width: 601px)": {
    padding: "0px !important",
  },
})
// Customizable Area End
