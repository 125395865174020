import { Box, Grid, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import React from "react";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins !important"
  }
});

const DashboardCardOverview: React.FC<{
  operand: string;
  percentage: number;
  image: string;
  title: string;
  count: number
}> = ({ percentage, image, title, count, operand }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box style={webstyles.container}>
        <Box
        style={{
          display:'flex',
          gap:'16px',
        }}>
          <img
            src={image}
            alt="image"
            style={{
              maxWidth: "40px",
              height: "40px",
              width: "40px"
            }}
          />
          <Box style={{width:'100%'}}>
            <Typography style={webstyles.mainTitle}>{count}</Typography>
            <Box style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
              <Typography component={"span"} style={webstyles.title}>{title}</Typography>
              <Typography component={"span"} style={operand === "+" ? webstyles.plusPercentage : webstyles.minusPercentage} > {operand}{Math.abs(percentage).toFixed(2)}%</Typography>
            </Box>
          </Box>

        </Box>
      </Box>
    </ThemeProvider>
  );
};

type IStyle = { [key: string]: React.CSSProperties };

const webstyles: IStyle = {
  container: {
    width: '100%',
    backgroundColor: "#3c3c51",
    borderRadius: "10px",
    padding: "36px 25px",
    height: "100%"
  },
  title: {
    fontSize: "13px",
    color: "#c9f9f6"
  },
  mainTitle: {
    fontSize: "20px",
    color: "#fff",
    fontWeight: 600,
    marginBottom: "8px"
  },
  plusPercentage: {
    fontSize: "14px",
    color: "#0aa630"
  },
  minusPercentage: {
    fontSize: "14px",
    color: "#ee5353"
  }
};
export default DashboardCardOverview;
