Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.validationApiContentTypeFrom = "application/json";
exports.validationApiMethodTypePost = "POST";
exports.validationApiMethodTypeFrom = "/bx_block_landingpage2/request_demos";
exports.btnExampleTitle = "CLICK ME";
exports.Loginsubmittoken = "/bx_block_landingpage2/faqs";
exports.exampleAPiMethodtoken = "GET";
exports.Testimonials = "/bx_block_landingpage2/testimonials";
exports.exampleTestimonials = "GET";
exports.NetWorthGet = "/bx_block_landingpage2/why_choose_mynes";
exports.exampleNetWorth = "GET";
exports.ServicesGet = "/bx_block_landingpage2/services";
exports.exampleServices = "GET";
exports.HomePageGet = "/bx_block_landingpage2/home_pages";
exports.exampleHomePage = "GET";
// Customizable Area End