import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  IconButton,
  styled,
  InputAdornment,
  Avatar,
  Chip,
  Grid,
  CircularProgress
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StylesProvider,
} from "@material-ui/core/styles";
import {UserDataListType, ChatDataType} from "./ViewChatController"
import SendIcon from "@material-ui/icons/Send";
import SearchIcon from "@material-ui/icons/Search";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import ChatEmoji from '@material-ui/icons/SentimentSatisfiedOutlined';
import AttachmentIcon from "@material-ui/icons/Attachment";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { playIcon, pauseIcon, globe, subArrowIcon, noUser, download } from "./assets";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import ImageDownloader from "../../../components/src/ImageDownloader/ImageDownloader";
import FileShowingWithName from "../../../components/src/ChatFeature/FileShowingWithName";
// Customizable Area End

import ViewChatController, {
  configJSON,
  IMessage,
  Props,
} from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderImageCell = () =>{
    return(
      <ModalContainer
       open={this.state.isImageModal}
       onClose={this.handleClose}
    >
       <ModalBox>
        <ModalImageBox>
           <ModalImage src={this.state.selectImgUrl}/>
        </ModalImageBox>
        <ImageDownloader image={download} selectImgUrl={this.state.selectImgUrl} fileName={this.state.selectImageName}  />
        </ModalBox>
    </ModalContainer>
    )
  };

  playCircleCell = () => {
    return (
      <img src={playIcon} height="25px" width="30px"/>
    )
  }

  audioPlayCell = (isCurrentAudio : boolean,isPlaying : boolean) => {
    if (isCurrentAudio) {
      if (isPlaying) {
        return (
          <img src={pauseIcon} height="25px" width="30px"/>
        )
      } else {
        return (
        this.playCircleCell()
        )
      }
    } else {
      return (
        <img src={playIcon} height="25px" width="30px"/>
      )
    }
  }

  renderAudioPlayer = (chat: ChatDataType, keyIdx: number, isRight: boolean) => {
    const { isPlaying, audioURL, currentTime, duration, currentAudioIndex } = this.state;
    const isCurrentAudioValue = currentAudioIndex === keyIdx;
    const progress = isCurrentAudioValue ? (currentTime / duration) * 100 : 0;
    return (
      <Box className={isRight ? "audioContainer" : "audioLeftContainer"}>
         <div style={{ display: "flex", height: "40px", backgroundColor: "#3775d8", alignItems: "center", width: "42%", borderRadius: "10px", paddingRight:"10px"}}>
          <div>
            <audio onTimeUpdate={this.handleTimeUpdate} ref={this.audioRef} src={audioURL || ""} onEnded={this.onEndedAudio}></audio>
            <button data-testId={`onViewPlayAudioId,${keyIdx}`} onClick={() => this.onPlayAudio(chat.imgUrl, keyIdx, isCurrentAudioValue)} style={{background: "transparent", border: "0" }}>
            {this.audioPlayCell(isCurrentAudioValue,isPlaying)}
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: '77%'}}>
            <div
              style={{
                width: '100%',
                height: '7px',
                position: 'relative',
                marginLeft: "8px",
                backgroundColor: '#e0e0e0',
                borderRadius: '6px',
                overflow: 'hidden'
              }}
            >
              <div
                style={{
                  width: `${progress}%`,
                  borderRadius: '5px',
                  transition: 'width 0.1s linear',
                  height: '100%',
                  backgroundColor: '#3b5998'
                }}
              ></div>
            </div>
          </div>
          <span className="timeZone" style={{marginRight: "0px", marginLeft:"auto"}}>
            {chat.time}
          </span>
        </div>
      </Box>
    )
  }

  rightSideRenderFirst = (chat: ChatDataType, keyIdx: number) => {
    const isOnlyMessage = chat.message ? chat?.message?.trim() !== '' : false;
    const isOnlyImage = chat.imgUrl !== '';
    const isAudioMessage = chat.type === 'audio';
    if(isAudioMessage) {
      return (
        <>{this.renderAudioPlayer(chat, keyIdx, true)}</>
      )
    }
    if (isOnlyImage) {
      if(isOnlyMessage) {
      return (
        <Box className="outgoingChats">
            <Box data-testId={`openModalCell,${keyIdx}`} onClick={() => this.handleModalOpenBaseOnType(chat.type, chat.imgUrl, chat.fileName)} className="parentdiv" style={{backgroundColor: "#94949c", borderRadius: "10px" , flexDirection: "column"}}>
              <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%",padding:"10px 7px"}}>
              {
                    chat.type === "file" && chat.imgUrl ?  <FileShowingWithName fileName={chat.fileName} url={chat.imgUrl} textColor="white" /> : <>
                  <span style={{display: "flex", alignItems: "center", width: "200px"}}><InsertPhotoIcon style={{fontSize: "30px"}}/><small style={{paddingLeft: "7px", fontSize: "16px", color: "black"}}>{chat.fileName}</small></span>
              <img
                src={chat.imgUrl}
                alt="Received"
                height="70px"
                width="70px"
                style={{borderRadius: "10px"}}
              /> 
                    
                    </> 
                    }
              </div>
             
                <Box className="isMessageTime">
                  <Typography className="message">{chat.message}</Typography>
                  <Box className="timeZone">
                    <Typography className="timeHeading">{chat.time}</Typography>
                  </Box>
                </Box>
            </Box>
        </Box>
      );
    }
    return (
      <Box className="outgoingChats">
        <Box data-testId="openModalCellCheck" onClick={() => this.handleModalOpenBaseOnType(chat.type, chat.imgUrl, chat.fileName)} className="parentdiv">
          {
                    chat.type === "file" && chat.imgUrl ?  <FileShowingWithName fileName={chat.fileName} url={chat.imgUrl}  textColor="white" /> :  <img
            src={chat.imgUrl}
            alt="Received"
            height="200px"
            width="200px"
          />
                  }
        </Box>
      </Box>
    );
  }
    return (
      <>
        <Box className="outgoingChats">
          <div className="parentdiv">
            <div className="subparent">
              <div className="message">
                {chat.message}
              </div>
              <span className="timeZone">
                {chat.time}
              </span>
            </div>
          </div>
        </Box>
      </>
    );
  };

  leftSideRenderFirstMessage = (chat: ChatDataType, keyIdx: number) => {
    const isOnlyMessage = chat.message ? chat?.message?.trim() !== '' : false;
    const isOnlyImage = chat.imgUrl !== '';
    const isAudioMessage = chat.type === 'audio';
    if(isAudioMessage) {
      return (
        <>{this.renderAudioPlayer(chat, keyIdx, false)}</>
      )
    }
    if (isOnlyImage) {
      if(isOnlyMessage) {
      return (
        <Box className="outgoingChats">
          <Box data-testId={`openModalCellId,${keyIdx}`} onClick={() => this.handleModalOpenBaseOnType(chat.type, chat.imgUrl, chat.fileName)}  className="parentLeftdiv" style={{flexDirection:"column" , background: "white" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "10px 7px", background: "grey", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
              {
                    chat.type === "file" && chat.imgUrl ?  <FileShowingWithName fileName={chat.fileName} url={chat.imgUrl} /> : <>
                  <span style={{ display: "flex", alignItems: "center", width: "200px" }}><InsertPhotoIcon style={{ fontSize: "30px" }} /><small style={{ paddingLeft: "7px", fontSize: "16px" }}>{chat.fileName}</small></span>
              <img
                src={chat.imgUrl}
                alt="Received"
                height="70px"
                width="70px"
                style={{ borderRadius: "10px" }}
              />
                    </> 
                    }
            </div>
            
              <div className="parentLeftdiv" style={{padding: "7px 10px"}}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="message">
                    {chat.message}
                  </div>
                  <span className="timeZone">
                    {chat.time}
                  </span>
                </div>
              </div>
            
          </Box>
        </Box>
      );
    }
    return (
      <Box className="outgoingChats">
        <Box  data-testId={`openModalCell,${keyIdx}`}  onClick={() => this.handleModalOpenBaseOnType(chat.type, chat.imgUrl, chat.fileName)} className="parentLeftdiv">
          {
                    chat.type === "file" && chat.imgUrl ?  <FileShowingWithName fileName={chat.fileName} url={chat.imgUrl} /> :  <img
            src={chat.imgUrl}
            alt="Received"
            width="200px"
            style={{borderRadius: "10px"}}
            height="200px"
          />
                  }
        </Box>
      </Box>
    );
  }
    return (
      <>
        <Box className="outgoingChats">
          <div className="parentLeftdiv">
            <div className="subparent">
              <div className="message">
                {chat.message}
              </div>
              <span className="timeZone">
                {chat.time}
              </span>
            </div>
          </div>
        </Box>
      </>
    );
  };

  renderImageModal = () => {
    const { selectedFile } = this.state;

    if (!selectedFile) return null;

    const getDocumentIcon = (fileType: string) => {
      if (fileType === 'application/pdf') {
        return '📄'; 
      } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return '📝';
      } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return '📊'; 
      }
      return '📁';
    };

    return (
      <Chip
        className="imageData"
        avatar={
          selectedFile.fileType.startsWith('image/') ? (
            <Avatar alt="Preview" src={selectedFile.previewUrl} />
          ) : (
            <Avatar alt="Document">{getDocumentIcon(selectedFile.fileType)}</Avatar>
          )
        }
        label={selectedFile.fileName}
      />
    );
  };

  renderRefreshingCell = () =>{
      return(
        <>
          {!this.state.isRefreshing && <Box className="todayBox">
                {this.state.isLoadingPrevMessages && <CircularProgress size={20} style={{color:"aqua"}} />}
                <Typography className="todayHeading">{this.state.dateLabel}</Typography>
              </Box>}
        </>
      )
  }

  renderChatDataListCell = () => {
    return (
      <>
        {!this.state.isRefreshing && this.state.chatDataList.map((chat: ChatDataType, keyIdx) => {
          let userId = this.getLoginId(chat.userId as string)
          return (
            <Box
              key={keyIdx}
              className={`receiveChat ${userId ? 'rightSide' : 'leftSide'}`}
            >
              {!this.state.isRefreshing && this.state.chatDataList[keyIdx - 1] && (keyIdx === 0 || (chat.groupDateLabel !== this.state.chatDataList[keyIdx - 1].groupDateLabel)) && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <div style={{ width: 100, height: 1, backgroundColor: '#c0c0c4' }}/>
                  <span style={{ fontSize: 12, color: '#c0c0c4', paddingLeft: 10, paddingRight: 10 }}>
                    {chat.groupDateLabel}
                  </span>
                  <div style={{ width: 100, height: 1, backgroundColor: '#c0c0c4' }}/>
                </div>
              )}
              {!userId
                ? this.leftSideRenderFirstMessage(chat, keyIdx)
                : this.rightSideRenderFirst(chat, keyIdx)}
            </Box>
          )
        })}
      </>
    )
  }

  renderIsEmojiCell = () => {
    return (

      <Box className="emojiPicker">
        {this.state.isEmoji && (
          <div ref={this.pickerRef}>
            <Picker
              data={data}
              data-testId="emojiPickerId"
              previewPosition="none"
              onEmojiSelect={this.handleEmojiSelect}
            />
          </div>
        )}
      </Box>

    )
  }

  renderserDataListCell = () => {
    return (
      <Box className="userList">
        {this.state.userConversationList.map((chat: UserDataListType, chatIndex: number) => {
          return (
            <Box data-testId={`messageTestId${chatIndex}`} key={chatIndex} onClick={() => this.onSelectUser(chat)} style={{backgroundColor: this.state.conversationSid == chat.data.attributes.conversation_sid ?"#395d6b":""}}>
            <Box className={"userData"}>
              <Box className="userAvatar" >
                <Box className="userDataName">
                    {
                      chat.data.attributes.image ? <Avatar src={chat.data.attributes.image} /> :
                        <NameBox>
                          {getFirstCharacterFromEachWord(chat.data.attributes.name || "U")}
                        </NameBox>
                    }
                  <Typography className="chatName" data-testId={`chatNameTestId${chatIndex}`} >{chat.data.attributes.name ? chat.data.attributes.name : "User"}</Typography>
                </Box>
                <Typography className="chatTime">{this.formatDateLabel(chat.data.attributes.dateUpdated)}</Typography>
              </Box>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {chat.data.type === 'text' && <Typography className="chatPara">{this.sliceTextString(chat.data.attributes.last_message.message)}</Typography>}
                {chat.data.type === 'audio' && <PlayCircleFilledIcon style={{ color: "", fontSize: "32px" }}/>}
                {chat.data.type === 'image' && <InsertPhotoIcon style={{ color: "gray", fontSize: "25px"}}/>}
                {chat.data.unreadCount > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, borderRadius: 15, backgroundColor: 'rgb(55, 117, 216)'}}>
                  <Typography style={{ color: '#fff', fontSize: "13px",  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{chat.data.unreadCount}</Typography>
                </div>}
              </div>
            </Box>
            </Box>
          )
        })}
      </Box>
    )
  }

  userListAndChatRender = () => {
    return (
      <>
        <Grid item sm={3}>
          {this.renderserDataListCell()}
        </Grid>
        <Grid item sm={9}>
          <Box className="chatContainer">
            <Box className="headPosition">
              <Box className="headerStyle">
                <Box className="header">
                  {this.state.userImage ? <Avatar
                    className="avaratImage"
                    alt="Remy Sharp"
                    src={this.state.userImage}
                  /> :
                    <NameBox style={{ marginRight: "12px" }}>
                      {getFirstCharacterFromEachWord(this.state.userName || "U")}
                    </NameBox>
                  }
                  <Typography className="userName"> {this.state.userName ? this.state.userName : "User"}</Typography>
                </Box>
              </Box>
            </Box>
            {this.state.chatDataList.length > 0 && this.renderRefreshingCell()}
            <div className={this.state.imageModal ? "chatSectionStyle2" : "chatSectionStyle"} ref={this.chatContainerRef}>
              {this.renderChatDataListCell()}
            </div>
            {this.state.imageModal ? this.renderImageModal() : null}
            {this.renderIsEmojiCell()}
            <Box>
              <Box className="formStyle">
                <Box className="textButton">
                  <Input
                    name="userMessage"
                    className="inputDateField"
                    placeholder="Enter your text"
                    fullWidth
                    disabled={this.state.isLoading}
                    disableUnderline
                    autoComplete="off"
                    data-testId="inputMessageId"
                    value={this.state.senderChatText}
                    onChange={this.handleSenderChat}
                    onKeyPress={this.handleKeyPress}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton data-testId="emojiSelectId" onClick={this.handleEmojiPicker}>
                          <ChatEmoji className="addIcon" />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <Box className="inputBox">
                            <label htmlFor="fileInput" className="uploads">
                              <AttachmentIcon className="addIcon" />
                            </label>
                            <input
                          id="fileInput"
                          type="file"
                          accept="image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={this.handleFileChange}
                          className="inputImage"
/>
                            <div
                              onMouseDown={this.debounce(this.startRecording, 300)}
                              onMouseUp={this.debounce(this.stopRecording, 300)}
                              onTouchStart={this.debounce(this.startRecording, 300)}
                              onTouchEnd={this.debounce(this.stopRecording, 300)}
                            >
                              <SettingsVoiceIcon className="addIcon" />
                            </div>
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box className="sendIconStyle">
                  <IconButton disabled={this.state.isLoading} data-testId="sendMessageTestId" onClick={this.debounce(this.sendMessage, 300)} type="submit">
                    <SendIcon className="addIcon" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {this.state.isLoading && <LoaderContainer>
              <CircularProgress style={{ color: "#fff" }} size={20} color="inherit" />
            </LoaderContainer>}
          </Box>
        </Grid>
      </>
    )
  };

  renderProfile = () =>{
    return (
      <div  data-test-id="profileLink" className="profileContainer" onClick={()=> this.goToProfilePage()}>
      <div>
        {this.state.plannerInfo.image ? (
          <img
            src={this.state.plannerInfo.image}
            className="plannerImg"
            alt="profile"
          />
        ) : (
          <div className="noImg">
            {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
          </div>
        )}
      </div>
      <div className="textContainer">
        <span className="plannerName">{this.state.plannerInfo.name}</span>
        <span className="plannerEmail">{this.state.plannerInfo.email}</span>
      </div>
    </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <div className="pagedashboard-wrapper2" style={{padding: "40px"}}>
        <MainContainer>
      <div style={{ justifyContent: 'end' }} className="header-wrapper" >
          <div className="page-info">
            <ul className="drop-menu">
              <li>
                <div
                  data-test-id="currencyDropdoen1"
                  className={this.state.dropdownVisiblity}
                  onClick={() => this.handleDropdown()}
                >
                </div>
              </li>
              <li>
              {this.renderProfile()}
              </li>
            </ul>
          </div>
        </div>
          </MainContainer>
        <ChatBotStyle>
          {
            !this.state.isUserCheck ?
             <>
             { this.state.userConversationList.length > 0 ? <Grid container style={{flexWrap: "unset", height: "100%"}}>
              {this.userListAndChatRender()}
              </Grid>
              :
              <LoaderContainer>
                      <CircularProgress style={{color:"#fff"}}  size={20} color="inherit"  />
                    </LoaderContainer>
              }
              </> 

              : 
              <Box style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <img src={noUser} alt="no user found" />
              <Typography className="noUserStyle">Nothing to show yet!</Typography>
              </Box>
          }
        </ChatBotStyle>
        {this.renderImageCell()}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .textContainer":{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  },
  "& .plannerName":{
    fontSize: "14px",
    color: "white",
  },
  "& .plannerEmail":{
    fontSize: "12px",
    marginTop:"-8px",
    color: "#ffffff78",
  },
  "& .noImg":{
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "#2CE2D5",
  },
  "& .plannerImg":{
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  "& .profileContainer":{
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
})
const NameBox = styled(Box)({
  width: "40px",
  display: "flex",
  borderRadius: "50%",
  color: '#acc8d2',
  fontWeight: 500,
  fontSize:"14px",
  alignItems: "center",
  justifyContent: 'center',
  height: "40px",
  textTransform: "uppercase",
  background: "#1A6882"
})


const LoaderContainer = styled(Box)({
   position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
})
const ChatBotStyle = styled(Box)({
  background: "#3C3C50",
  "& .parentdiv ": {
    maxWidth: "500px",
    background: "#2e7eec",
    color: "white",
    height: "max-content",
    borderRadius: "10px",
    display: "flex",
    float: "right",
    "@media(max-width: 940px)": {
      maxWidth: "375px",
    },
    "@media(max-width: 840px)": {
      maxWidth: "320px",
    },
    "@media(max-width: 700px)": {
      maxWidth: "300px",
    },
    "@media(max-width: 630px)": {
      maxWidth: "270px",
    }
  },
  "& .noUserStyle":{
    fontFamily:'Poppins',
    color: "#fff",
    marginTop:'24px',
    fontSize:'20px'
  },
  "& .subparent": {
    display: "flex",
    padding: "10px 10px",
    width: "100%",
    alignItems: 'flex-end'
  },
  "& .userDataList":{
    background: "#3a5d6b"
  },
  "& .message": {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "& .timeZone": {
    fontSize: "10px",
    width: "50px",
    display: "inline-block",
    paddingLeft: "10px",
    color: "#c0c0c4",
    paddingRight: "10px",
    textAlign:'right'
  },
  "& .parentLeftdiv ": {
    maxWidth: "500px",
    background: "#fff",
    height: "max-content",
    borderRadius: "10px",
    display: "flex",
    float: "left",
    "@media(max-width: 940px)": {
      maxWidth: "375px",
    },
    "@media(max-width: 840px)": {
      maxWidth: "320px",
    },
    "@media(max-width: 700px)": {
      maxWidth: "300px",
    },
    "@media(max-width: 630px)": {
      maxWidth: "270px",
    }
  },
  "@media(max-width: 840px)": {
    width: "calc(100% - 0px)",
  },
  "& .noneMessage": {
    display: "none"
  },
  "& .audioContainer": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "16px",
    marginRight: "16px"
  },
  "& .audioLeftContainer": {
    display: "flex",
    marginBottom: "16px",
     marginLeft: "16px"
  },
  "& .isMessageTime" : {
    display: "flex",
    flexDirection: "row",
    padding: "7px",
    background: "#3775d8",
    width: "100%",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    justifyContent: "space-between",
    maxWidth: "500px"
  },
  marginLeft: "auto",
  position: "relative",
  height: "calc(100vh - 165px)",
  "& .userList":{
    overflowY: "scroll",
    height: "100%",
    borderRadius: "10px",
    paddingTop: "30px",
      "@media(max-width: 540px)": {
     width: "200px"
    },
    "@media(max-width: 520px)": {
      width: "190px"
     },
    "@media(max-width: 470px)": {
      width: "150px"
    },
    "@media(max-width: 430px)": {
      width: "130px"
    }
  },
  "& .userData":{
    borderBottom: "1px solid white",
    marginBottom: "15px",
    padding: "10px"
  },
  "& .inputImage":{
    display: "none"
  },
  "& .chatName":{
    textTransform: "capitalize",
    color: "white",
  },
  "& .chatTime":{
    textTransform: "capitalize",
    color: "#61606e",
    fontSize: "10px",
    "@media(max-width: 530px)" : {
      fontSize: "10px"
    }
  },
  "& .chatPara":{
    color: "gray",
    fontSize: "15px",
    marginTop: "10px",
    paddingBottom: "15px"
  },
  "& .userDataName":{
    display: "flex", 
    gap: "10px",
    alignItems: "center",
    "@media(max-width: 530px)" : {
      flexFlow: "wrap"
    }
  },
  "& .userAvatar":{
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center",
    "& .MuiAvatar-colorDefault":{
       background: "gray"
    },
    "@media(max-width: 530px)" : {
     alignItems: "flex-start"
    }
  },
  "& .chatContainer":{
    borderLeft: "1px solid white",
    position:"relative",
    height: "100%"
  },
  "& .formStyle": {
    display: "flex",
    width: "100%",
    padding: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    bottom: "0px",
    boxSizing: "border-box",
    gap:'2%'
  },
  "& .textButton": {
    width: "100%",
  },
  "& .inputBox": {
    display: "flex",
    gap: "10px",
  },
  "& .inputDateField": {
    background: "#373a3d",
    color: "#7C7C7C",
    height: "46px",
    padding: "0px 10px",
    borderRadius: "20px",
    width:"100%",
    "& .MuiInputBase-input":{
      border: "none"
    }, 
    "@media(max-width: 940px)" : {
      width: "97%"
    },
    "@media(max-width: 620px)" : {
      width: "100%"
    },
    "@media(max-width: 590px)" : {
      width: "280px"
    },
    "@media(max-width: 575px)" : {
      width: "255px"
    },
    "@media(max-width: 550px)" : {
      width: "240px"
    },
    "@media(max-width: 520px)" : {
      width: "225px"
    },
    "@media(max-width: 480px)" : {
      width: "180px"
    }
  },
  "& .addIcon": {
    color: "white",
  },
  "& .leftSide, & .rightSide":{
    width: "50%"
  },
  "& .sendIconStyle": {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: "#2e7eec",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 767px)" : {
      marginLeft: "10px"
    }
  },
  "& .headPosition": {
    backgroundColor: "#3C3C50",
    padding: "12px",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    boxSizing: "border-box",
    borderBottom: "1px solid white"
  },
  "& .emojiPicker":{
    position: "fixed",
    bottom: "70px",
    marginLeft: "2%"
  },
  "& .userName": {
    color: "white",
    textTransform: "capitalize",
    fontFamily: "poppins"
  },
  "& .avaratImage": {
    marginRight: "12px",
  },
  "& .todayBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "80px",
    left: "50%",
    transform: "translateX(-50%)",
    boxSizing: "border-box",
    backgroundColor: "grey",
    padding: "10px 20px",
    borderRadius: "10px",
    opacity: "0.8"
  },
  "& .todayHeading": {
   marginLeft: "10px",
   fontWeight:600,
   textAlign:"center",
   paddingLeft:"5px",
   color:"white",
   fontFamily: "poppins"
  },
  "& .headerStyle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .outgoingChats": {
    overflow: "hidden",
    margin: "16px",
  },
  "& .2e7eecs ": {
    backgroundColor: "#3a12ff",
    backgroundImage: "#1577df",
    color: "#fff",
    borderRadius: "10px 10px 0px 10px",
    fontSize: "14px",
    padding: "1rem",
    boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.25)",
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  "& .rightSide": {
    borderRadius: "10px 0px 10px 10px",
  },
  "& .multiMsg":{
     width: "100%",
     wordWrap: "break-word"
  },
  "& .2e7eec": {
    float: "right",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .receiveChat": {
    width: "100%"
  },
  "& .outgoingChatsImg": {
    display: "inline-block",
    width: "50px",
    float: "right",
    marginRight: "1rem",
  },
  "& .time": {
    marginLeft: "15px",
    width: "100px",
  },
  "& .timeHeading": {
    float: "right",
    fontSize: "10px",
  },
  "& .receivedMyCharts": {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "10px 10px 10px 0px",
    width: "40%",
    padding: "1rem",
  },
  "& .receivedSecond": {
    borderRadius: "0px 10px 10px 10px",
  },
  "& .chatSectionStyle": {
    backgroundColor: "#3C3C50",
    height: "calc(100vh - 310px)",
    width: "100%",
    overflowY: "auto",
  },
  "& .chatSectionStyle2": {
    backgroundColor: "#3C3C50",
    height: "calc(100vh - 345px)",
    width: "100%",
    overflowY: "auto",
  },
});

const ModalContainer = styled(Modal)({
  justifyContent:"center" , 
  alignItems:'center',
  display:"flex"
});

const ModalBox = styled("div")({
  position:'relative',
  width: "450px", 
  height: "450px", 
  background: "white", 
  borderRadius: "20px", 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
  "@media(max-width: 620px)" : {
    width: "350px", 
    height: "350px", 
  },
  "@media(max-width: 550px)" : {
    width: "300px", 
    height: "300px", 
  },
});

const ModalImageBox = styled("div")({
  width: "400px", 
  height: "400px",
  "@media(max-width: 620px)" : {
    width: "300px", 
    height: "300px", 
  },
  "@media(max-width: 550px)" : {
    width: "250px", 
    height: "250px", 
  },
});

const ModalImage = styled("img")({
  width: "100%", height: "100%"
});

const ChatSectionStyle = styled(Box)({
  backgroundColor: "#2a2a38",
  height: "100vh",
  width: "100%",
  overflowY: "scroll",
});

// Customizable Area End
