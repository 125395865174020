import React from 'react';

const ProgressBar:React.FC<{
  percentage: number,
  direction:string
}> = ({ percentage,direction }) => {
  const totalBoxes = 11;
  const filledBoxes = Math.floor((percentage / 100) * totalBoxes);
  const partialFill = ((percentage / 100) * totalBoxes) % 1;

  const renderBoxes = () => {
    const boxes = [];

    for (let index = 0; index < totalBoxes; index++) {
      if (index < filledBoxes) {
        boxes.push(<div key={index} style={styles.filledBox}></div>);
      } else if (index === filledBoxes) {
        boxes.push(
          <div key={index} style={{ ...styles.box, position: 'relative' }}>
            <div style={{ ...styles.partialFill, width: `${partialFill * 100}%` ,right: direction === "rtl"? 0 : 'unset'}}></div>
          </div>
        );
      } else {
        boxes.push(<div key={index} style={styles.box}></div>);
      }
    }

    return boxes;
  };

  return <div style={styles.progressBar}>{renderBoxes()}<p style={styles.title}>{percentage} </p></div>;
};
type IStyle = { [key: string]: React.CSSProperties };

const styles:IStyle = {
  progressBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  box: {
    width: '45px',
    height: '45px',
    margin: '2px',
    backgroundColor: '#8f99a3',
  },
  filledBox: {
    width: '45px',
    height: '45px',
    margin: '2px',
    backgroundColor: '#0abe64',
  },
  partialFill: {
    height: '100%',
    backgroundColor: '#0abe64',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  title:{
    fontWeight: 500,
    fontSize:"24px",
    marginLeft:"14px",
    color:"#fff"
  }
};

export default ProgressBar;