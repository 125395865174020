import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import storage from "framework/src/StorageProvider";
import moment from "moment";


export interface IService{
    "id": string,
    "type": string,
    "attributes": {
        "service_name": string,
        "service_id": number,
        "proposal_template_id": number,
        image:string
        "plan_inclusions": {
            "id": number,
            "name": string,
            "expected_date": string,
            "completion_date": string,
            "status": string,
            allowEdit:boolean,
        }[]
    }
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    services : IService[],
    initialServices:  IService[],
    openConfirmationPopup: boolean,
    changeStatusTooltip: HTMLDivElement|null,
    currentPlanInclusionId: number,
    plannerInfo: {
      name:string,
      email:string,
      image:string
    },
    allCompleted: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ClientServiceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getClientServiceAPICallId: string = "";
  setServiceDateAPICallId:string = "";
  getUserProfileAPICallId:string = "";
 


  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        services:[],
        initialServices:[],
        openConfirmationPopup:false,
        changeStatusTooltip: null,
        currentPlanInclusionId: 0,
        plannerInfo:{
          name:"",
          image:"",
          email:""
        },
        allCompleted:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getClientServices();
    this.getUserProfile()
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleClientServiceResponse(apiRequestCallId,responseJson);
    this.handleSetServiceResponse(apiRequestCallId);
    this.handleUserProfileResponse(apiRequestCallId,responseJson);

    // Customizable Area End
  }

  // Customizable Area Start

  goToBackPage() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Clients");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }

  handleGoToVault(){
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "UploadThing");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg)
  }


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const { contentType, method, endPoint, body } = data;
    const proposalRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("auhtToken")
      })
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(proposalRequestMessage.id, proposalRequestMessage);
    return proposalRequestMessage.messageId;
  };


  getClientServices = async () => {
    const accountId = await storage.get("clientId")

    this.getClientServiceAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_proposal_generation/service_proposals/user_service_index?account_id=" + accountId,
      body: null
    });
  }

  handleClientServiceResponse(apiRequestCallId:string,responseJson:{
    services:{
        data :IService[]
    }
  }){

    if(apiRequestCallId === this.getClientServiceAPICallId){
      
        this.setState({
            services: responseJson.services.data,
            allCompleted: this.checkAllServicesCompleted(responseJson.services.data),
            initialServices: JSON.parse(JSON.stringify(responseJson.services.data)),
        })
    }

  }

  handleDateChange = (date:string,currentId:number,type:string) =>{
    let services = this.state.services;
    services = services.map((service)=>{
      let planInclusion = service.attributes.plan_inclusions.map((item)=>{
        if(item.id ===currentId ){
          if(type!== "expected"){
            item.completion_date = date
          }else{
            item.expected_date = date

          }
        }
        return item
      });

      service.attributes.plan_inclusions = planInclusion
      return service;
    })
    this.setState({
      services: services
    })
  }



  handleSaveServices = () =>{
    const services= this.state.services;
    let isValid = true;
    for(let service of services){
      let planInclusions = service.attributes.plan_inclusions;
      for(let planInclusion of planInclusions ){
        if(planInclusion.status === "completed"){
          if(!planInclusion.completion_date || !planInclusion.expected_date ){
            toast.error("You can not complete the service without expected and completion date!");
            isValid = false
          }
        }
      }
    }
    if(isValid){
      this.setState({
        openConfirmationPopup: true
      })
    }
  }

  handleCloseConfirmationPopup = () =>{ 
    this.setState({
      openConfirmationPopup: false
    })
  }



  handleSubmitServices = async () => {
    const serviceDetails = this.state.services.map((service) => {
      return ({
        user_service_selection_id: service.id,
        service_status: service.attributes.plan_inclusions.map((planInclusion) => {
          let expectedDate = null;
          let completionDate = null;
          if (planInclusion.expected_date) {

            expectedDate = moment(planInclusion.expected_date, "Do MMMM 'YY").format("DD-MM-yy");

          

          }
          if (planInclusion.completion_date) {

            completionDate = moment(planInclusion.completion_date, "Do MMMM 'YY").format("DD-MM-yy");
          }
          return {
            "plan_inclusion_id": planInclusion.id,
            "expected_date": expectedDate,
            "completion_date": completionDate,
            "status": planInclusion.status === "completed" ? true : false
          }
        })
      })
    });
    const accountId = await storage.get("clientId")

    const body = {
      data: {
        account_id: accountId,
        service_details: serviceDetails
      }
    }

    this.setServiceDateAPICallId = await this.apiCall({
      contentType: "application/json" + '',
      method: "POST" + '',
      endPoint: "bx_block_proposal_generation/service_proposals/set_service_date" + "",
      body: JSON.stringify(body)
    });
  }

  handleSetStatusTooltip = (tooltip:HTMLDivElement ,currentId:number) => {
    this.setState({
      changeStatusTooltip: tooltip,
      currentPlanInclusionId: currentId
    })
  }

  handleCloseStatusTooltip = () => {
    this.setState({
      changeStatusTooltip: null,
    })
  }
  handleStatusChange = (status: string) => {
    let services = this.state.services;
    services = services.map((service) => {
      let planInclusion = service.attributes.plan_inclusions.map((item) => {
        if (item.id === this.state.currentPlanInclusionId) {
          item.status = status;
          item.allowEdit = true
        }
        return item
      });

      service.attributes.plan_inclusions = planInclusion
      return service;
    })
    this.setState({
      services: services,
      changeStatusTooltip: null,
    })
  }

  isPlanInclusionCompleted = (status:string, allowEdit:boolean )=> {
    if(allowEdit || status === "pending"){
      return false
    }
   return true
  }

  handleSetServiceResponse = (apiCallId:string)=>{
    if(apiCallId === this.setServiceDateAPICallId){
      this.setState({
        openConfirmationPopup: false
      })
      toast.success("Services Updated Successfully!")
      this.getClientServices()
    }
  }

  handleCancelService = () => {
    
    let initialService = JSON.parse(JSON.stringify(this.state.initialServices));
    this.setState({
      services: initialService 
    })

  }


  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }

  checkAllServicesCompleted(services:IService[]) {
    return services.every(service => 
      service.attributes.plan_inclusions.every(inclusion => inclusion.status === "completed")
    );
  }

  navigateRoute = (route: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(msg)
  }

  // Customizable Area End

}
