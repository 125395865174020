import React from "react";
// Customizable Area Start
import "./style.css";
import {

    backButton,
    settingg

} from "./assets";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";


// Customizable Area End
import QuestionbankController, {
    Props,
} from "./QuestionbankController";
export default class Questionbank extends QuestionbankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        
        // Customizable Area End
    }
    // Customizable Area Start
    handleOptionSelect = (
      selectedAnswer: string,
      id: any,
      description?: any,
      type?: string
    ) => {
      this.setState((prevState) => {
        const updatedData = {
          ...prevState.data,
          selected_options: { ...prevState.data.selected_options },
          previous_data: [...(prevState.data.previous_data || [])],
        };
    
        const idData = updatedData.previous_data.find((item:any) => item.id === id) || {
          id,
          question_id: null,
          selected_options: [],
          description: null,
        };
    
        // Update the data based on the type
        if (type === "Radio") {
          idData.selected_options = [selectedAnswer];
        } else if (type === "Checkbox") {
          const index = idData.selected_options.indexOf(selectedAnswer);
          if (index === -1) {
            idData.selected_options.push(selectedAnswer);
          } else {
            idData.selected_options.splice(index, 1);
          }
        }
    
        if (description) {
          idData.description = description.toString();
        }
    
        if (!idData.question_id) {
          idData.question_id = id.toString();
        }
    
        const indexToUpdate = updatedData.previous_data.findIndex((item:any) => item.id === id);
        if (indexToUpdate !== -1) {
          updatedData.previous_data[indexToUpdate] = idData;
        } else {
          updatedData.previous_data.push(idData);
        }
    
        return { data: updatedData };
      });
    };

  
    handleNextQuestion = () => {
      const { questionnnaireData } = this.state;
      const { currentQuestionIndex } = this.state;
      const currentQuestion = questionnnaireData && questionnnaireData[currentQuestionIndex];
      const isLastQuestion = currentQuestionIndex === (questionnnaireData?.length || 0) - 1;
      const lastAnswer = this.state.data?.previous_data?.find((data: {
        question_id: string
      }) => data.question_id === currentQuestion.id);
      if (lastAnswer && lastAnswer.selected_options.length > 0) {
        this.setState((prevState) => ({
          currentQuestionIndex: prevState.currentQuestionIndex + 1,
        }), () => {
          if (isLastQuestion) {
            this.userQuestion()
          }
        });
      }
      else {
        toast.error("Please select answer");
      }
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { questionnnaireData } = this.state;
    const { currentQuestionIndex } = this.state;
    const currentQuestion = questionnnaireData && questionnnaireData[currentQuestionIndex];
    const isLastQuestion = currentQuestionIndex === (questionnnaireData?.length || 0) - 1;
    
        return (
            <div className="questionnaire-wrapper" style={{ height: "100vh" }}>
               <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
               <div style={{display: 'flex', gap: 20, width: '100%', alignItems: 'center'}}>
                <NavLink to="/Quetionnaire">
                <img className="back-icon" data-test-id='bank-icon-click'  src={backButton} alt="back-icon" />
                </NavLink>
                 
                <div className="page1-title" style={{fontSize: 28, color: 'white', fontWeight: 700,fontFamily:"Poppins"}}>
                   Financial Advisor Questionnaire
                </div>
                </div>
                <NavLink to="/Account-Detail">
                  <img src={settingg} alt="setting" />
                </NavLink>
               </div>
                <br />
          
        {currentQuestion && (
          <div style={{
            display:"flex",
            flexDirection:"column",
            height:"90%",
            paddingBottom:"50px"
          }}>
            <div key={currentQuestion.id} style={styles.questionContainer} className="main-radio-box" >
              <div data-test-id={`radioBox${currentQuestion.id}`}>
                <div style={{
                  fontSize:"20px",
                  color:"#fff",
                  fontFamily:"Poppins",
                  fontWeight:500,
                  marginBottom:"12px"
                }}>
                  {currentQuestion.text}
                </div>
                {currentQuestion.options.map((option: any, index: any) => (
                  <div key={index} style={{
                    marginBottom:"12px",
                    fontSize:"20px",
                    height: "34px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                    <input
                      type={currentQuestion.type === "Radio" ? "radio" : "checkbox"}
                      className="radio-button"
                      style={{
                        marginLeft: "0px",
                        cursor:"pointer",
                       
                      }}
                      name={`question${currentQuestion.id}`}
                      value={option}
                      checked={this.state.data.previous_data.find((x: any) => x.id == currentQuestion.id)?.selected_options?.includes(option) || false}
                      id={`question${currentQuestion.id}-${index}`}
                      data-test-id="test"
                      onChange={() => this.handleOptionSelect(option,currentQuestion.id,"",currentQuestion.type)}
                    />
                    <label htmlFor={`question${currentQuestion.id}-${index}`}>{option}</label>
                  </div>
                ))}
                {currentQuestion.user_input && (
                  <div style={{
                    marginTop:"18px"
                  }}>
                    <input
                      type="text"
                      data-test-id="test1"
                      placeholder="Description"
                      value={this.state.data.previous_data.find((x: any) => x.id == currentQuestion.id)?.description}
                      onChange={(e) => this.handleOptionSelect("",currentQuestion.id,e.target.value)}
                    />
                  </div>
                )}
              </div>         
            </div>
            <div className="button">
                {isLastQuestion ? (
            <button style={styles.submitBtn} data-test-id="finalSubmitBtn" onClick={()=>this.handleNextQuestion()}>
              Submit
            </button>
          ) : (
            <button  style={styles.submitBtn} data-test-id="finalSubmitBtn1" onClick={() => this.handleNextQuestion()}>
              Next
            </button>
          )}
                </div>
            </div>
          )}
         </div>        
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const styles = {
  submitBtn: {
    width: "25%",
    minWidth:"120px",
    background: "#C9F9F6",
    border: "1px solid #707070",
    padding: "15px",
    borderRadius: "10px",
    color: "#3C3C50",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily:" 'Poppins', sans-serif;",
    cursor: "pointer",
    marginTop: "10px"
  },
  questionContainer: {
    backgroundColor: 'rgb(42, 42, 57)',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    padding: '30px', height: "100%", color: "white"
  }
}
// Customizable Area End
