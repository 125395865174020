import React from "react";

// Customizable Area Start
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import Applelogin2Controller, {
  Props,

} from "./Applelogin2Controller";

export default class AppleLoginSucess extends Applelogin2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: 'rgb(60, 60, 81)',
        padding: '20px',
      }}>
        {
          this.state.loader &&
          <div style={{ backgroundColor: ' rgb(60, 60, 81)', width: '100vw', height: '100vh', display:'flex',alignItems:'center',justifyContent:'center' }}>
          <CircularProgress
            style={{ color: 'rgb(201, 249, 246)' }} size={60}
          />
  
        </div>
        }
        {this.state.showEmail&&
          <div>
      <div style={{marginTop:'30px',marginBottom:'50px'}}>
      <h2 style={{color:'rgb(201, 249, 246)'}}>Please share your email address</h2>
      <h2 style={{color:'rgb(201, 249, 246)'}}>so we can reach out to you for further details</h2>
      </div>
      <br/>
      <br/>
      <form onSubmit={this.handleSubmit}>
        <input
          type="email"
          style={webStyle.input}
          placeholder="Enter your email"
          value={this.state.email}
          onChange={this.handleChange}
        />
         {this.state.error2 && <p style={webStyle.error}>{this.state.error2}</p>}
        <br/>
        <button type="submit" style={webStyle.button}>
          Submit
        </button>
       
      </form>
      </div>
      }
      
    </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    padding: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    margin: '10px 0',
    width: '300px',
    borderRadius: '5px',
    border: 'rgb(201, 249, 246)',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: 'rgb(201, 249, 246)',
    color: 'rgb(60, 60, 81)',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '5px',
    marginBottom:'0px'
  },
  
};
// Customizable Area End
