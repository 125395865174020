import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    assetsType: string
    investmentsData: string[]
    currency_name: [{ lable: string, value: string }]
    selectedLocations: string[],
    selectedGoals: string[],
    selectedCategories: string[],
    selectLocationsCurrency: { lable?: string, value?: string },
    selectGoalsCurrency: { lable?: string, value?: string },
    selectCategoriesCurrency: { lable?: string, value?: string },
    transform: Object,
    searchInput: string,
    logoImgInvestments: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class MainCatalogueController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: string = '';
    reactMonthsApiCallId: string = '';
    reactInvestmentsApiCallId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            searchInput: '',
            selectLocationsCurrency: { lable: '', value: '' },
            selectGoalsCurrency: { lable: '', value: '' },
            selectCategoriesCurrency: { lable: '', value: '' },
            selectedLocations: [],
            selectedGoals: [],
            selectedCategories: [],
            assetsType: 'Type',
            currency_name: [{ lable: '', value: '' }],
            investmentsData: [],
            transform: { textTransform: 'none' },
            logoImgInvestments: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    async componentDidMount() {
        const language = await getStorageData('language');
        const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoImgInvestments: logoImg1AddCash });
        this.reactLocationsApi()
        this.reactinvestmentsApi()
    }

    getStringInvestments = (key: string) => {
        let languageInvestments = localStorage.getItem("language") || "English"
        return configJSON.languageListInvestments[languageInvestments][key]
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.reactMonthsApiCallId !== null &&
            this.reactMonthsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let reactMonthsApiCallIdJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const currency = reactMonthsApiCallIdJson.locations?.map((value: any) => (
                { value: value, label: value }
            ));
            const currencyGoals = reactMonthsApiCallIdJson.goals?.map((value: any) => (
                { value: value, label: value }
            ));
            const currencyCategories = reactMonthsApiCallIdJson.categories?.map((value: any) => (
                { value: value, label: value }
            ));
            this.setState({ selectedLocations: currency, selectedGoals: currencyGoals, selectedCategories: currencyCategories })
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.reactInvestmentsApiCallId !== null &&
            this.reactInvestmentsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let reactInvestmentsApiCallIdJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ investmentsData: reactInvestmentsApiCallIdJson.data })
        }
        // Customizable Area End
    }



    changeType = (type: string) => {
        this.setState({ assetsType: type })
    }
    selectedStatePath: string[] = []

    getSelectedPath = (name: string, val: { lable?: string, value?: string }) => {
        const data: string | undefined = val?.value?.replace(/\b\w/g, (char: string) => char.toUpperCase());
        if (val?.value) {
            switch (name) {
                case 'Your Goal':
                    return `goal=${val.value}&`
                case 'Location':
                    return `location=${data}&`
                case 'Industry':
                    return `category=${val.value}&`
                default:
            }
        }
    }

    setGoalsPath = () => {
        if (this.state.selectGoalsCurrency.value) {
            const selectedPath: string | undefined = this.getSelectedPath('Your Goal', this.state.selectGoalsCurrency)
            if (selectedPath !== undefined) {
                this.selectedStatePath.push(selectedPath)
            }
        }
    }

    setCategoriesPath = () => {
        if (this.state.selectCategoriesCurrency?.value) {

            const selectedPath: string | undefined = this.getSelectedPath('Industry', this.state.selectCategoriesCurrency)
            if (selectedPath !== undefined) {
                this.selectedStatePath.push(selectedPath)
            }
        }
    }

    setLocationPath = () => {
        if (this.state.selectLocationsCurrency.value) {
            const selectedPath: string | undefined = this.getSelectedPath('Location', this.state.selectLocationsCurrency)
            if (selectedPath !== undefined) {
                this.selectedStatePath.push(selectedPath)
            }
        }
    }

    setFieldValue = (name: string, val: { value: string; label: string }) => {

        this.setState({ selectLocationsCurrency: val })
        const selectedPath: string | undefined = this.getSelectedPath(name, val)

        this.selectedStatePath = []

        if (selectedPath !== undefined) {
            this.selectedStatePath.push(selectedPath)
        }
        this.setCategoriesPath()

        this.setGoalsPath()

        if (val.value) {
            this.reactinvestmentsSelectApi()
        }
    }

    setGoalsValue = (name: string, val: { value: string; label: string }) => {
        this.setState({ selectGoalsCurrency: val })

        const selectedPath: string | undefined = this.getSelectedPath(name, val)

        this.selectedStatePath = []

        if (selectedPath !== undefined) {
            this.selectedStatePath.push(selectedPath)
        }
        this.setCategoriesPath()

        this.setLocationPath()

        if (val.value) {
            this.reactinvestmentsSelectApi()
        }
    }
    setCategoriesValue = (name: string, val: { value: string; label: string }) => {
        this.setState({ selectCategoriesCurrency: val })

        const selectedPath: string | undefined = this.getSelectedPath(name, val)

        this.selectedStatePath = []

        if (selectedPath !== undefined) {
            this.selectedStatePath.push(selectedPath)
        }
        this.setGoalsPath()
        this.setLocationPath()

        if (val.value) {
            this.reactinvestmentsSelectApi()
        }
    }

    reactLocationsApi = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.reactLocations;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.reactMonthsgetGet,
        });
        this.reactMonthsApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    reactinvestmentsApi = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = `bx_block_content_management/investments`
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.reactMonthsgetGet,
        });
        this.reactInvestmentsApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    reactinvestmentsSelectApi = () => {
        const dataPath = this.selectedStatePath.join('');
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = `bx_block_content_management/investments?${dataPath}`
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.reactMonthsgetGet,
        });
        this.reactInvestmentsApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    searchItems = (e: string) => {
        this.setState({ searchInput: e })
    }
    customStylesProperty = {
        control: (defaultStyles: any) => ({
            ...defaultStyles, backgroundColor: "#2a2a38", borderRadius: '50px', padding: "4px", fontSize: '16px',
            fontFamily: 'Poppins',
            textAlign: "left",
            fontWeight: '500',
            border: "1px solid #2DE2D5 !important",
            "&:hover": { border: "1px solid #2DE2D5 !important" }
        }),
        singleValue: (defaultStyles: any) => ({ ...defaultStyles, color: '#2DE2D5 !important' }),
        dropdownIndicator: (defaultStyles: any) => ({ ...defaultStyles, color: '#2DE2D5', "&:hover": { color: '#2DE2D5' } }),
        input: (base: any) => ({ ...base, '[type="text"]': { fontFamily: 'Poppins', fontWeight: 500, color: '#2DE2D5 !important' } })
    };
    // Customizable Area End
}
