import React from "react";

// Customizable Area Start

import { Typography, Box } from "@material-ui/core";
import { logo } from "../../otp-input-confirmation/src/assets"
import OtpInput from 'react-otp-input';
import { tagCross } from "./assets"
import "../../otp-input-confirmation/src/style.css"
import { NavLink } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SuccessCard } from "../../freetrial/src/assets";
// Customizable Area End

import PasscodeLockController, {
    Props,
} from "./PasscodeLockController";

export default class ConfirmNewPin extends PasscodeLockController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <style>
                    {`
                    .verification-code input {
                        width: 65px !important;
                    }                
                `}
                </style>
                <div className="main-wrapper" data-test-id="mainWrapperAtConfirmNewPin" style={{ flexDirection: 'column', justifyContent: 'center' }}>

                    <div className="verification-wrapper" data-test-id="verificationWrapperAtConfirmNewPin" style={{ marginTop: '30px'}}>
                        <div className="verify-box" data-test-id="verifyBoxAtConfirmNewPin" style={{ height: '100%' }}>
                            <div className="logo-right" data-test-id="logoRightAtConfirmNewPin">
                                <a href="#"><img src={logo} alt="logo" data-test-id="imgAtConfirmNewPin" /></a>
                            </div>
                            <div className="verification-block" id="verificationBlocktAtConfirmNewPin" data-test-id="englishLanguageScreen">
                                <Box data-test-id="boxAtConfirmNewPin">
                                    <div data-test-id="verificationCodeAtConfirmNewPin" className="verification-code">
                                        <Typography data-test-id="txtLabelsAtConfirmNewPin" style={{ ...webStyleForConfirmNewPin.textLabelsCNP }}>Confirm new PIN</Typography>
                                        <OtpInput
                                            data-test-id="OtpField"
                                            inputType="number"
                                            value={this.state.confirmPin}
                                            shouldAutoFocus={true}
                                            onChange={(value) => this.updateStateForConfirmPin(value, 'change')}
                                            numInputs={4}
                                            renderInput={(props) => <input {...props} />}
                                            renderSeparator={<span></span>}
                                            inputStyle={{
                                                height: "62px",
                                                marginRight: "30px",
                                                textAlign: "center",
                                                margin: 'auto',
                                                background: "none",
                                                border: "1px solid #2DE2D5",
                                                opacity: 1,
                                                width: "75%",
                                                borderRadius: "12px",
                                                fontSize: "16px",
                                                color: "#fff",
                                            }}
                                        /></div>
                                    {this.state.isMatch ?
                                        <Box data-test-id="matchErrorAtConfirmNewPin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                                            <Typography style={{ ...webStyleForConfirmNewPin.errLineCNP }}>PIN does not match</Typography>
                                        </Box>
                                        :
                                        <Box data-test-id="noMatcherrorAtConfirmNewPin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '25px' }}>
                                        </Box>
                                    }
                                    <Box data-test-id="calcDesign">
                                        <Box data-test-id="confirmNewPinCalcDesigns" style={{ width: '94%', paddingLeft: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin1" data-test-id="press1" onClick={() => this.updateStateForConfirmPin('1', 'click')}>1</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin2" data-test-id="press2" onClick={() => this.updateStateForConfirmPin('2', 'click')}>2</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin3" data-test-id="press3" onClick={() => this.updateStateForConfirmPin('3', 'click')}>3</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin4" data-test-id="press4" onClick={() => this.updateStateForConfirmPin('4', 'click')}>4</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin5" data-test-id="press5" onClick={() => this.updateStateForConfirmPin('5', 'click')}>5</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin6" data-test-id="press6" onClick={() => this.updateStateForConfirmPin('6', 'click')}>6</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin7" data-test-id="press7" onClick={() => this.updateStateForConfirmPin('7', 'click')}>7</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin8" data-test-id="press8" onClick={() => this.updateStateForConfirmPin('8', 'click')}>8</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin9" data-test-id="press9" onClick={() => this.updateStateForConfirmPin('9', 'click')}>9</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="backBtn" data-test-id="backBtn" onClick={() => this.backBtnForConfirmation()}>
                                                <img src={tagCross} style={{ paddingRight: '0px', height: '25px' }} />
                                            </Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtnsCNP} id="confirmNewPin0" data-test-id="press0" onClick={() => this.updateStateForConfirmPin('0', 'click')}>0</Box>
                                            <Box style={webStyleForConfirmNewPin.numberbtns2ForCNP}><NavLink to="/SetupNewPin">Cancel</NavLink></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <Box id="dialogBoxForSuccessConfirmNewPin">
                        <Dialog
                            open={this.state.openPinSuccessPopup}
                            keepMounted
                            data-test-id="dialogModel"
                            onClose={this.pinSuccessModal}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{ style: this.dialogStyle }}
                        >
                            <DialogTitle data-test-id="TitleForDialogInconfirmNewPin" id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                            <DialogContent data-test-id="ContentForDialogInconfirmNewPin" style={{ padding: '8px 20px' }}>
                                <DialogContentText data-test-id="contentTextForDialogInconfirmNewPin" id="alert-dialog-slide-description" className='dialogContent'>
                                    PIN changed successfully!
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>

                    </Box>
                    <div id="confirmBtnForCNP">
                        <div data-test-id='confirmForCNP' id="confirmBtnForCNPForBtn" onClick={() => this.confirmBtn()} style={{
                            marginTop: '22px', padding: '5px 50px', cursor: 'pointer', backgroundColor: '#C9F9F6',
                            color: '#3C3C50', fontWeight: 700, borderRadius: '5px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '16rem'
                        }}>Confirm</div></div>
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleForConfirmNewPin = {
    numberbtns2ForCNP: { cursor: 'pointer', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    numberbtnsCNP: { cursor: 'pointer', background: '#3C3C50', filter: 'drop-shadow(0px 2px 10px rgba(45, 226, 213, 0.20))', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', borderRadius: '30px', alignItems: 'center' },
    textLabelsCNP: { color: '#fff', fontWeight: 700, fontSize: '18px', marginLeft: '1rem' },
    errLineCNP: { color: 'rgb(213 46 46)', fontWeight: 700, fontSize: '14px' },
};
// Customizable Area End
