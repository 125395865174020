import React from "react";
// Customizable Area Start
import {
    appleLogo,
    googleIcon,
    screens,
    verticals,
    networthMobile,
    aggregation,
    mobileInvesting,
    options,
    performance,
    diversification,
    customization,
    resources,
    userFriendly,
    budgetMobile,
    spendVsBudget,
    track_and_view,
    categorySpend,
    patterns,
    spendsLive,
    createGoals,
    mobileEstatePlanning,
    trusts,
    benefciary,
    vault,
    poa,
    digital_wall,
    life_insurance,
    retirement_plan,
    userProfile,
    openQuote,
    closeQuote,
    tickMark,
    faqDown,
    faqUp,
    homeIcon,
    facebook,
    instagram,
    twitter,
    youtube,
    linkedin,
    logo,
    iphone13a,
    image3,
    view1,
    view2,
    ImgBack,
    bg1,
    bottomImg
} from "./assets";
const videoChrome: string = require("../assets/Video_v2.mp4");
const videoFirefox: string = require("../assets/video.webm");
import Grid from "@material-ui/core/Grid";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { OutlinedInput, RadioGroup } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import browserDetect from 'browser-detect';
import { animated } from '@react-spring/web'
import { flipInX } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import { Box, Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
const cashTheme = createTheme({
    overrides: {
        MuiTypography: {
            body1: {
                fontFamily: 'Helvetica !important',
            },
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    borderBottom: '0px',
                    color: "white",
                    width: '100%',
                },
                "& $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
        MuiRadio: {
            root: {
                "& .MuiSvgIcon-root": {
                    fontSize: "36.74px",
                },
            },
        },
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});
const styles = StyleSheet.create({
    flipCard: {
        animationName: flipInX,
        animationDuration: '1.5s',
        animationFillMode: 'forwards',
    },
    cardContainer: {
        perspective: '1000px',
    },
    card: {
        maxWidth: 'auto',
        backgroundColor: '#ffffff00 !important',
        transition: 'transform 2s',
    },
    front: {
        marginTop: '7px',
        width: 'auto',
        height: 'auto',
        backfaceVisibility: 'hidden',
    },
    back: {
        display: 'none',
        width: 'auto',
        height: 'auto',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(250deg)',
    },
});

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    activePackage = () => {
        return this.state.activePackage == "monthly" ? webStyle.activePackageBtn : webStyle.PackageBtn
    }
    activePackageyearly = () => {
        return this.state.activePackage == "yearly" ? webStyle.activePackageBtn : webStyle.PackageBtn
    }
    videoChromePlay = () => {
        return browserDetect().name === "chrome" ? videoChrome : videoFirefox
    }
    videoChromeMp4 = () => {
        return browserDetect().name === "chrome" ? "video/mp4" : "video/webm"
    }
    investingBoxes = (box: string) => {
        return this.state.activeFeature == box ? webStyle.verticalsActive : webStyle.verticals
    }
    animationOne = (index: number) => {
        return index % 2 === 0 ? webStyle.animationOne : webStyle.animationThree
    }
    activeFeaturetitle = (item: any) => {
        return this.investingBoxes(item.title)
    }
    animationCondation = (state: boolean, styles: object) => {
        return state ? styles : {}
    }
    testimonialsAnimationData = (one: any) => {
        return this.state.testimonialsAnimation ? one : {}
    }
    animationOneData = (one: any) => {
        return this.state.animationOneData ? one : {}
    }
    blockStyles = (state: boolean) => {
        return { display: state ? 'block' : 'none' }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const title_oneData = this.state?.HomePageData?.[0]?.title_one.split(" ")
        const timeChange = this.state?.HomePageData?.[0]?.title_two.split(" ")
        return (
            <>

                <ThemeProvider theme={cashTheme}>
                    <div >
                        <div>
                            <Box style={{ ...webStyle.containerNew, position: 'relative', fontFamily: 'Helvetica !important' }}  >
                                <TopImg>
                                    <div style={{ ...webStyle.ImgBackTop, position: 'absolute' }}></div>
                                    <div style={{ ...webStyle.ImgBackTop1, position: 'absolute' }}></div>
                                </TopImg>
                                <Box>
                                    <Box style={{ maxWidth: "1946", margin: 'auto' }}>
                                        <Box style={webStyle.fullPage}>
                                            <Grid container style={{ position: "relative", zIndex: 6 }} id="targetDivClass" >
                                                <Grid item md={5} sm={12} xs={12}>
                                                    <Box style={webStyle.haderName}>
                                                        <MasterStyle style={this.animationCondation(this.state.isTitleDataIntersecting, webStyle.animationOne)} id="isTitleDataIntersecting"><span style={{ lineHeight: 'normal' }}>{title_oneData?.slice(0, 2).join(" ")}</span>{" "}<span style={webStyle.masterSaving}>{title_oneData?.slice(2, 4).join(" ")}</span></MasterStyle>
                                                        <MasterFinances style={this.animationCondation(this.state.isTitleDataIntersecting, webStyle.animationTwo)} id="animationTwo">{this.state?.HomePageData?.[0]?.title_one_description}{" "}</MasterFinances>
                                                        <Typography style={{ ...webStyle.textLabel3, ...this.animationCondation(this.state.isTitleDataIntersecting, webStyle.animationTwo) }} id="animationTwo">Download the App</Typography>
                                                        <Box style={{ flexWrap: "wrap", ...webStyle.appleAndGoogleCards, ...this.animationCondation(this.state.isTitleDataIntersecting, webStyle.animationTwo) }} id="animationTwo">
                                                            <img style={{ height: '70px', objectFit: 'contain', maxWidth: "220px", width: `calc(min(100%, 220px))` }} src={view2} />&nbsp;&nbsp;
                                                            <Box>
                                                                <img style={{ height: '67px', objectFit: 'contain', maxWidth: "220px", width: `calc(min(100%, 220px))` }} src={view1} />
                                                                <Typography style={{ ...webStyle.comingSoon, textAlign: "center" }}>Coming Soon</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={7} sm={12} lg={7}>
                                                    <Box style={{ ...webStyle.screensImg, ...this.animationCondation(this.state.isTitleDataIntersecting, webStyle.animationThree) }} id="animationThree">
                                                        <Box><img style={{ position: "relative", objectFit: 'contain', zIndex: 1, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.65))" }} src={screens} alt="" /></Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box style={webStyle.changeTopBottem}>
                                            <Box style={{ textAlign: "center" }}>
                                                <Typography style={{ ...webStyle.textLabel, ...this.animationCondation(this.state.animationOne1, webStyle.animationOne), position: 'relative', zIndex: 10 }} id="animationOne1">{timeChange?.slice(0, 5).join(" ")}</Typography>
                                                <Typography style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationOne1, webStyle.animationOne), position: 'relative', zIndex: 10 }} id="animationOne1">{timeChange?.slice(5, 9).join(" ")}</Typography>
                                                <VideoBox>
                                                    <div className="videolable" style={{ ...webStyle.video, ...webStyle.animationThree, zIndex: 10, position: 'relative' }}>
                                                        <video width="clamp(400px, 100%, 520px)" height="335px" onClick={this.handleTogglePlay} onEnded={this.handleVideoEnd} controls={false} poster={image3} id='handleVideoClick' data-test-id="handleVideoClick"  >
                                                            <source src={this.videoChromePlay()} type={this.videoChromeMp4()} />
                                                        </video>
                                                    </div>
                                                </VideoBox>                                                
                                                <br />
                                                <span style={webStyle.demoBtn} data-test-id="activeFromNew" onClick={this.activeFromNew}>Request a Demo</span>
                                            </Box>
                                            <Box>
                                                {this.state.activeFrom === true ?
                                                    <Box style={webStyle.firstBox}>
                                                        <Box>
                                                            <Box style={webStyle.NameFont}>Name</Box>
                                                            <OutlinedInput style={webStyle.inputRoot} data-test-id="txtInputAssetname" fullWidth={true} type="text" name="name" value={this.state.name} onChange={(e) => this.setInitialValure('name', e.target.value)} />
                                                            <Box style={webStyle.NameFont}>Email Address</Box>
                                                            <OutlinedInput style={webStyle.inputRoot} data-test-id="txtInputAssetname1" fullWidth={true} type="text" name="email" value={this.state.email} onChange={(e) => this.setInitialValure('email', e.target.value)} />
                                                            <Box style={{ color: 'red' }}>{this.state.emailerror}</Box>
                                                            <Box style={webStyle.NameFont}>You are</Box>
                                                            <RadioGroup aria-label="role" value={this.state.role} data-test-id="txtInput" onChange={this.handleRoleChange}>
                                                                <FormControlLabel style={webStyle.FormControlLabelButton} value={1} control={<Radio color="primary" />} label={<Typography style={{ fontSize: '37.15px' }}>Investment Manager</Typography>} /><br />
                                                                <FormControlLabel style={webStyle.FormControlLabelButton} value={2} control={<Radio />} label={<Typography style={{ fontSize: '37.15px' }}>Portfolio Manager</Typography>} /><br />
                                                                <FormControlLabel style={webStyle.FormControlLabelButton} value={3} control={<Radio />} label={<Typography style={{ fontSize: '37.15px' }}>Other</Typography>} />
                                                            </RadioGroup>
                                                            <Box style={webStyle.NameFont}>How many client portfolios are you looking to manage with Myne?</Box>
                                                            <OutlinedInput style={webStyle.inputRoot} data-test-id="txtInputAssetname2" fullWidth={true} name="client_portfolio_count" value={this.state.client_portfolio_count} onChange={(e) => this.setInitialValure('client_portfolio_count', e.target.value)} />
                                                            <Box style={{ color: 'red' }}>{this.state.client_portfolio_count_error}</Box>
                                                            <Box style={webStyle.siteKey}>
                                                                <ReCAPTCHA
                                                                    sitekey="6LdR02EpAAAAABNYJp3UX6js8bqF6wTyMDERbKKj"
                                                                    data-test-id="captcha"
                                                                    onChange={(value: string | null) => { this.changeCaptcha(value) }}
                                                                    onExpired={() => { this.setState({ captcha: false }) }}
                                                                />
                                                                <Box style={{ color: 'red' }}>{this.state.captchaError}</Box>
                                                            </Box>
                                                            <ButtonSubmit data-test-id='handleSubmit' type="submit" onClick={this.HandleSubmit}>Submit</ButtonSubmit>
                                                        </Box>
                                                    </Box>
                                                    : null}
                                            </Box>
                                        </Box>
                                        {this.state.activeFrom === false ?
                                            <Box>
                                                <Box style={{ position: 'relative', zIndex: 20 }}>
                                                    <Box style={webStyle.MYNETopBottem}>
                                                        <Box style={{ textAlign: "center" }}>
                                                            <Typography style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree1, webStyle.animationThree) }} id="animationThree1">MYNE</Typography>
                                                            <Typography style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree1, webStyle.animationThree) }} id="animationThree1">{this.state?.HomePageData?.[0]?.title_three}</Typography>
                                                            <Typography style={{ ...webStyle.textLabel4, ...this.animationCondation(this.state.animationThree1, webStyle.animationThree) }} id="animationThree1">{this.state?.HomePageData?.[0]?.title_three_description}</Typography>
                                                            <div className="container" style={{ position: 'relative', textAlign: 'center', color: 'white', ...this.animationCondation(this.state.animationOne2, webStyle.animationOne) }} id="animationOne2">
                                                                <img src={verticals} alt="Snow" style={{ ...webStyle.verticalsImg }} />
                                                                <div style={{ ...webStyle.EstatePlanningOnClick, position: 'absolute' }} data-test-id="goToEstatePlanningWeb" onClick={this.goToEstatePlanningWeb}></div>
                                                                <div style={{ position: 'absolute', ...webStyle.BudgetWebOnclick }} data-test-id="goToBudgetWeb" onClick={this.goToBudgetWeb}></div>
                                                                <div style={{ position: 'absolute', ...webStyle.NetworthFeatureOnclick }} data-test-id="goToNetworthFeature" onClick={this.goToNetworthFeature}></div>
                                                                <div style={{ position: 'absolute', ...webStyle.InvestmentMarketplaceOnclick }} data-test-id="goToInvestmentMarketplaceWeb" onClick={this.goToInvestmentMarketplaceWeb}></div>
                                                            </div>
                                                        </Box>
                                                    </Box>
                                                    <Box style={webStyle.titleAll}>
                                                        <Box style={{ textAlign: "center" }}> <HeadTypography style={webStyle.wcm}>Why choose MYNE?</HeadTypography></Box>
                                                    </Box>
                                                    <CustomBox style={webStyle.networthBox}>
                                                        <Grid container spacing={3}>
                                                            {this.state.NetWorthResponse.map((item: any, index: number) => {
                                                                return (
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <Box style={{ ...this.activeFeaturetitle(item), position: 'relative' }}
                                                                            data-test-id={`networthBtn_${index}`}
                                                                            onClick={() => this.changeActiveFeature(item)}
                                                                        >
                                                                            <img className="WcmCardImg" src={this.state.chooseImg[index]} style={{ ...webStyle.wcmCardIcon, objectFit: 'contain' }} alt="" />
                                                                            <Typography className="content1" style={webStyle.wcmCardHeader}>{item.title}</Typography>
                                                                            <Typography className="content2" style={webStyle.wcmCardSubHeader}>
                                                                                {item.description}
                                                                            </Typography>
                                                                            <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                        </Box>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </CustomBox>
                                                    <CustomBox2 style={webStyle.networthMen}>
                                                        {this.state.activeFeature == "Net Worth" && (
                                                            <>
                                                                <Grid container spacing={3} id="netWorthShow">
                                                                    <Box className="innerParentBox" style={{ margin: "0px 131px", marginBottom: '8%' }}>
                                                                        <Grid container spacing={2} style={{ lineHeight: '10px' }}>
                                                                            <Grid xs={12} lg={5} className="imageGridBox"> <Box style={{ ...webStyle.networthAllData, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3"><img className="mobileimg" src={networthMobile} style={webStyle.networkImage} alt="" /></Box></Grid>
                                                                            <Grid xs={12} lg={7}>
                                                                                <Typography className="headingSubText2" style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3">Net Worth</Typography>
                                                                                <Typography className="headingSubText" style={{ ...webStyle.subText, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3">Track and manage your wealth</Typography>
                                                                                <Grid container spacing={2}>
                                                                                    {this.state.ServicesResponse?.map((service: any, index: number) => (
                                                                                        <Grid item xs={12} md={6} key={index} >
                                                                                            <animated.div style={this.blockStyles(this.state.netWorthShow)}>
                                                                                                <div className={css(styles.cardContainer)}>
                                                                                                    <div className={css(styles.card, styles.flipCard)}>
                                                                                                        <div className={css(styles.front)}>
                                                                                                            <Box className="subInnerBox" style={{ ...webStyle.featureDetails, width: "auto", position: 'relative' }}>
                                                                                                                <img className="innericon" width="72.81px" height="72.81px" style={{ aspectRatio: "1 / 1", objectFit: 'contain' }} src={this.state.servicesImg[index]} alt="" />
                                                                                                                <Box style={webStyle.BoxDataAll}>
                                                                                                                    <Typography className="featureDetailHeading content1" style={webStyle.featureDetailHeading}>{service.title}</Typography>
                                                                                                                    <Typography className="content2" style={webStyle.featureDetailDesc}>{service.details}</Typography>
                                                                                                                </Box>
                                                                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                        <div className={css(styles.back)}>Back Content</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </animated.div>
                                                                                        </Grid>
                                                                                    ))}
                                                                                    <Box className='learnupper' style={webStyle.LearnBUtton}><a href="NetworthFeature"><span style={webStyle.demoBtn} className="learn-btn">Learn More</span></a></Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {this.state.activeFeature == "Budgeting" && (
                                                            <>
                                                                <Grid container spacing={3} id='budgetingShow'>
                                                                    <Box className="innerParentBox" style={{ margin: "0px 131px", marginBottom: '8%' }}>
                                                                        <Grid container spacing={2} style={{ lineHeight: '10px' }}>
                                                                            <Grid xs={12} lg={7}>
                                                                                <Typography className="headingSubText2" style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3">Budgeting</Typography>
                                                                                <Typography className="headingSubText" style={{ ...webStyle.subText, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3">Improve your wealth and financial well-being</Typography>
                                                                                <Grid container spacing={2}>
                                                                                    {this.state.ServicesResponse?.map((service: any, index: number) => (
                                                                                        <Grid item xs={12} md={6} key={index} >
                                                                                            <animated.div>
                                                                                                <div className={css(styles.cardContainer)}>
                                                                                                    <div className={css(styles.card, styles.flipCard)}>
                                                                                                        <div className={css(styles.front)}>
                                                                                                            <Box className="subInnerBox" style={{ ...webStyle.featureDetails, width: "auto", position: 'relative' }}>
                                                                                                                <img width="72.81px" height="72.81px" style={{ aspectRatio: "1 / 1", objectFit: 'contain' }} src={this.state.BudgetingImg[index]} alt="" />
                                                                                                                <Box style={webStyle.BoxDataAll}>
                                                                                                                    <Typography style={webStyle.featureDetailHeading} className="service content1">{service.title}</Typography>
                                                                                                                    <Typography className="service content2" style={webStyle.featureDetailDesc}>{service.details}</Typography>
                                                                                                                </Box>
                                                                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                        <div className={css(styles.back)}>Back Content</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </animated.div>
                                                                                        </Grid>
                                                                                    ))}
                                                                                    <Box className='learnupper' style={webStyle.LearnBUtton} id='budgetingShow'><a href="BudgetWeb"><span style={webStyle.demoBtn} className="learn-btn">Learn More</span></a></Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid xs={12} lg={5} className="imageGridBox"><Box style={{ ...webStyle.networthAllData, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3"><img className="mobileimg" src={budgetMobile} style={{ height: "818px" }} alt="" /></Box></Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {this.state.activeFeature == "Investing" && (
                                                            <>
                                                                <Grid container spacing={3} id='investingShow'>
                                                                    <Box className="innerParentBox" style={{ margin: "0px 131px", marginBottom: '8%' }}>
                                                                        <Grid container spacing={2} style={{ lineHeight: '10px' }}>
                                                                            <Grid xs={12} lg={5} className="imageGridBox"> <Box style={{ ...webStyle.networthAllData, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3"><img className="mobileimg pr-20" src={mobileInvesting} style={{}} alt="" /></Box></Grid>
                                                                            <Grid xs={12} lg={7}>
                                                                                <Typography className="headingSubText2" style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3">Investing</Typography>
                                                                                <Typography className="headingSubText" style={{ ...webStyle.subText, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3">Grow your wealth with Myne</Typography>
                                                                                <Grid container spacing={2}>
                                                                                    {this.state.ServicesResponse?.map((service: any, index: number) => (
                                                                                        <Grid item xs={12} md={6} key={index}>
                                                                                            <animated.div>
                                                                                                <div className={css(styles.cardContainer)}>
                                                                                                    <div className={css(styles.card, styles.flipCard)}>
                                                                                                        <div className={css(styles.front)}>
                                                                                                            <Box className="subInnerBox" style={{ ...webStyle.featureDetails, width: "auto", position: 'relative' }}>
                                                                                                                <img width="72.81px" height="72.81px" style={{ aspectRatio: "1 / 1", objectFit: 'contain' }} src={this.state.InvestingImg[index]} alt="" />
                                                                                                                <Box style={webStyle.BoxDataAll}>
                                                                                                                    <Typography style={webStyle.featureDetailHeading} className="details content1">{service.title}</Typography>
                                                                                                                    <Typography style={webStyle.featureDetailDesc} className="details content2">{service.details}</Typography>
                                                                                                                </Box>
                                                                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                        <div className={css(styles.back)}>Back Content</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </animated.div>
                                                                                        </Grid>
                                                                                    ))}
                                                                                    <Box className='learnupper' style={webStyle.LearnBUtton}><a href="InvestmentMarketplaceWeb"><span style={webStyle.demoBtn} className="learn-btn">Learn More</span></a></Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {this.state.activeFeature == "Estate Planning" && (
                                                            <>
                                                                <Grid container spacing={3}>
                                                                    <Box className="innerParentBox" style={{ margin: "0px 131px", marginBottom: '8%' }}>
                                                                        <Grid container spacing={2} style={{ lineHeight: '10px' }}>
                                                                            <Grid xs={12} lg={7}>
                                                                                <span id='estatePlanningShow'></span>
                                                                                <Typography className="headingSubText2" style={{ ...webStyle.textLabel2, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3">Estate Planning</Typography>
                                                                                <Typography className="headingSubText" style={{ ...webStyle.subText, ...this.animationCondation(this.state.animationThree3, webStyle.animationOne) }} id="animationThree3">Protect your wealth and secure your family's future</Typography>
                                                                                <Grid container spacing={2}>
                                                                                    {this.state.ServicesResponse?.map((service: any, index: number) => (
                                                                                        <Grid item xs={12} md={6} key={index}>
                                                                                            <animated.div>
                                                                                                <div className={css(styles.cardContainer)}>
                                                                                                    <div className={css(styles.card, styles.flipCard)}>
                                                                                                        <div className={css(styles.front)}>
                                                                                                            <Box className="subInnerBox" style={{ ...webStyle.featureDetails, width: "auto", position: 'relative' }}>
                                                                                                                <img width="72.81px" height="72.81px" style={{ aspectRatio: "1 / 1", objectFit: 'contain' }} src={this.state.EstatePlanningImg[index]} alt="" />
                                                                                                                <Box style={webStyle.BoxDataAll}>
                                                                                                                    <Typography className="BoxDataAll content1" style={webStyle.featureDetailHeading}>{service.title}</Typography>
                                                                                                                    <Typography className="BoxDataAll content2" style={webStyle.featureDetailDesc}>{service.details}</Typography>
                                                                                                                </Box>
                                                                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                        <div className={css(styles.back)}>Back Content</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </animated.div>
                                                                                        </Grid>
                                                                                    ))}
                                                                                </Grid>
                                                                                <Box className='learnupper' style={webStyle.LearnBUtton}><a href="EstatePlanningWeb"><span style={webStyle.demoBtn} className="learn-btn">Learn More</span></a></Box>
                                                                            </Grid>
                                                                            <Grid xs={12} lg={5} className="imageGridBox"><Box style={{ ...webStyle.networthAllData, ...this.animationCondation(this.state.animationThree3, webStyle.animationThree) }} id="animationThree3"><img className="mobileimg" src={mobileEstatePlanning} style={{ height: "818px" }} alt="" /></Box></Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </CustomBox2>
                                                </Box>
                                                <div style={{ ...webStyle.ImgBackCenter, position: 'absolute' }}></div>
                                                <div style={{ ...webStyle.ImgBackCenter1, position: 'absolute' }}></div>
                                                <Box style={webStyle.titleAll}>
                                                    <Box style={{ textAlign: "center" }}><Typography style={{ ...webStyle.wcm, ...webStyle.animationOne }}>Testimonials</Typography></Box>
                                                </Box>
                                                <Box style={webStyle.TestimonialsData}>
                                                    <Box>
                                                        <Grid container spacing={6} >
                                                            {this.state.Testimonials?.map((value: any, index: number) => {
                                                                const one = this.animationOne(index)
                                                                return (
                                                                    <Grid item xs={12} sm={12} md={6} style={this.testimonialsAnimationData(one)} id="testimonialsAnimation">
                                                                        <Box style={webStyle.networthAllData}>
                                                                            <Box style={{ ...webStyle.testimonialCard, position: 'relative' }} className="testimonial_1_header">
                                                                                <Box style={webStyle.testimonialHeader} id="testimonial_1_headerContent">
                                                                                    <Box className="testimonial_1_profile"> <img src={userProfile} alt="" style={{ width: "50px" }} /></Box>
                                                                                    <Box style={{ marginLeft: "10px" }} id="tesimonials_1_labels">
                                                                                        <TypographyName style={webStyle.testimonialNameLabel} className="nameLabel1">{value.name}</TypographyName>
                                                                                        <TypographyDesignation style={webStyle.testimonialDesignationLabel } className="descLabel1">{value.title}</TypographyDesignation>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box style={webStyle.testimonialMainContent} className="testimonial_1_content">
                                                                                    <TypographyContent style={{ position: "relative", ...webStyle.testimonialContent }} className="testimonial_1_imgContent">
                                                                                        <img src={openQuote} alt="" style={{ position: "absolute", left: "0", top: "-5" }} id="openQuote1" />
                                                                                        <span style={{ display: 'inline-block', maxWidth: '100%', overflow: 'hidden' }}>{value.description}</span>
                                                                                        <img src={closeQuote} alt="" style={{ position: "absolute", right: "0", bottom: "1", padding: "-3em" }} id="closeQuote1" />
                                                                                    </TypographyContent>
                                                                                </Box>
                                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box id="testimonail_3" style={webStyle.testimonail_3Name}>
                                                        <Typography style={{ fontSize: "clamp(1rem, 2.2vw, 4vw)", fontFamily: 'Helvetica !important', color: "#fff", textAlign: 'center' }}>
                                                            Pricing packages{" "}<span style={{ fontSize: "clamp(1rem,2.2vw, 4vw)", fontFamily: 'Helvetica !important', color: "#2DE2D5" }}>tailored just for you</span>
                                                        </Typography>
                                                    </Box>
                                                    <Box style={webStyle.monthlyBox}>
                                                        <MonthlyBox2>
                                                            <Typography onClick={() => this.changeActivePackage("monthly")} data-test-id="monthly" style={this.activePackage()}>Monthly</Typography>
                                                            <Typography data-test-id="yearly" onClick={() => this.changeActivePackage("yearly")} style={this.activePackageyearly()}>Yearly</Typography>
                                                        </MonthlyBox2>
                                                    </Box>

                                                    <Box style={{ ...webStyle.monthlyBox3, ...this.animationCondation(this.state.animationBottom, webStyle.animationBottom) }} id="animationBottom">
                                                        {this.state.activePackage == "monthly" && (
                                                            <PackageCard style={{ position: 'relative' }}>
                                                                <Typography style={{ marginBottom: "-10px", ...webStyle.packageCardText }}>Premium</Typography>
                                                                <Box style={webStyle.PremiumMenu}>
                                                                    <Typography style={webStyle.numberwidth}>$10</Typography>
                                                                    <Typography style={webStyle.numberwidth2}>/month</Typography>
                                                                </Box>
                                                                <Typography className="packageCardText" style={{ marginTop: "-8px", ...webStyle.packageCardText }}>Ideal for individual investors</Typography>
                                                                <Box className="packageContainer" style={{ marginTop: "25px" }}>
                                                                    <Box className="packageDetails" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} className="packageCardText">
                                                                            <img className="tickMarkImg" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Track your investments, Cash and Debts
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className="packageDetails1" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} className="packageCardText1">
                                                                            <img className="tickMarkImg1" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Current flexibility (10+ currencies)
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box id="packageDetails" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} id="packageCardText">
                                                                            <img id="tickMarkImg" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Personal Wealth Dashboard
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box id="packageDetails1" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} id="packageCardText1" >
                                                                            <img id="tickMarkImg1" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Automatic price updates (public instruments, crypto, foreign exchange)
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ ...webStyle.GetStaredButton, textAlign: 'center' }} id="getStartedButtonBox">
                                                                    <Typography className="getStartedLabel" style={webStyle.buttonGetSatred}>Get Stared</Typography>
                                                                </Box>
                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                            </PackageCard>
                                                        )}

                                                        {this.state.activePackage == "yearly" && (
                                                            <PackageCard style={{ position: 'relative' }}>
                                                                <Typography style={{ marginBottom: "-10px", ...webStyle.packageCardText }}>Premium</Typography>
                                                                <Box style={webStyle.PremiumMenu}>
                                                                    <Typography style={webStyle.numberwidth}>$100</Typography>
                                                                    <Typography style={webStyle.numberwidth2}>/year</Typography>
                                                                </Box>
                                                                <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }}>Ideal for individual investors</Typography>
                                                                <Box style={{ marginTop: "25px" }}>
                                                                    <Box className="yearlyPackageDetails" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} className="yearlyPackageCardText">
                                                                            <img className="yearlyOackageTickMark" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Track your investments, Cash and Debts
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className="yearlyPackageDetails1" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} className="yearlyPackageCardText1">
                                                                            <img className="yearlyOackageTickMark1" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Current flexibility (10+ currencies)
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box className="yearlyPackageDetails2" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} className="yearlyPackageCardText2">
                                                                            <img className="yearlyOackageTickMark2" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Personal Wealth Dashboard
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box id="yearlyPackageDetails" style={webStyle.packageDetails}>
                                                                        <Typography style={{ marginTop: "-8px", ...webStyle.packageCardText }} id="yearlyPackageCardText">
                                                                            <img id="yearlyOackageTickMark" src={tickMark} style={webStyle.tickMark} alt="" />&nbsp;
                                                                            Automatic price updates (public instruments, crypto, foreign exchange)
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ ...webStyle.GetStaredButton, textAlign: 'center' }}><Typography style={webStyle.buttonGetSatred}>Get Stared</Typography></Box>
                                                                <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                            </PackageCard>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box style={webStyle.titleAll}>
                                                    <Box style={{ textAlign: "center" }}>
                                                        <HeadTypography style={{ ...webStyle.wcm, ...webStyle.QaTitle }}>Questions about MYNE?</HeadTypography>
                                                    </Box>
                                                </Box>
                                                <Box id="faqText" style={{ ...webStyle.faqTextText, position: 'relative', zIndex: 20 }}>
                                                    <Grid container justifyContent="center" spacing={2} style={{ width: "85%" }}>
                                                        {this.state.faqApi?.map((value: any, index: number) => {
                                                            const one = this.animationOne(index)
                                                            return (
                                                                <CustomAccordianGrid item xs={12} md={6} style={this.animationOneData(one)} id="animationOneData">
                                                                    <Box style={{ ...webStyle.gridCard, position: 'relative', }} data-test-id={"faq"} onClick={() => this.updateActiveFAQ(value.id)} >
                                                                        <Box style={webStyle.questionBox}>
                                                                            <Typography className="accordianBox" style={webStyle.questionBoxColor}>{value.question}</Typography>
                                                                            <img src={this.state.activeFAQ.includes(value.id) ? faqUp : faqDown} alt="" style={webStyle.activeFAQImg} />
                                                                        </Box>
                                                                        {this.state.activeFAQ.includes(value.id) && (
                                                                            <Box style={{ marginTop: "15px" }}><Typography style={webStyle.anserFaq}>{value.answer}</Typography></Box>
                                                                        )}
                                                                        <Box style={{ ...webStyle.borderBefore, position: 'absolute' }} />
                                                                    </Box>
                                                                </CustomAccordianGrid>
                                                            );
                                                        })}
                                                    </Grid>
                                                    <TopImg>
                                                        <div style={{ ...webStyle.ImgBackBottem, position: 'absolute' }}></div>
                                                        <div style={{ ...webStyle.ImgBackBottem1, position: 'absolute' }}></div>
                                                    </TopImg>
                                                </Box>
                                            </Box>
                                            : null}
                                    </Box>
                                    {this.state.activeFrom === true ? <img src={bottomImg} width="100%" /> : null}
                                    <Box style={{ ...webStyle.titleAllNew, position: 'relative', zIndex: 20, background: "#272733" }}>
                                        <Box style={{ maxWidth: "1946", margin: 'auto' }}>
                                            <AppStoreGetItOnFooter className="footer" style={webStyle.footer}>
                                                <AppStoreGetItOnFooterHome>
                                                    <Box> <img src={homeIcon} style={webStyle.footerIcons} alt="" /></Box>
                                                    <Box>
                                                        <span style={webStyle.menuItemsForFooter}>About us</span>
                                                        <span style={webStyle.menuItemsForFooter}>Contact us</span>
                                                        <span style={webStyle.menuItemsForFooter}>Resources</span>
                                                        <span style={webStyle.menuItemsForFooter}>Roadmap</span>
                                                        <span style={webStyle.menuItemsForFooter}>Terms of use</span>
                                                        <span style={webStyle.menuItemsForFooter}>Cookies policy</span>
                                                        <span style={webStyle.menuItemsForFooter}>Privacy policy</span>
                                                    </Box>
                                                </AppStoreGetItOnFooterHome>

                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: "13px 0px" }}>
                                                    <img src={logo} style={{ width: '170px', borderRadius: '0px' }} alt="" />
                                                    <Typography style={{ fontSize: '10px', fontFamily: 'Helvetica !important', color: 'white', paddingTop: '6.5px' }}>Who's managing your wealth?</Typography>
                                                </Box>
                                                <Box style={{ ...webStyle.socialMediaIcons, margin: "13px 0px" }}>
                                                    <img src={facebook} style={webStyle.footerIcons} alt="" />
                                                    <img src={instagram} style={webStyle.footerIcons} alt="" />
                                                    <img src={twitter} style={webStyle.footerIcons} alt="" />
                                                    <img src={youtube} style={webStyle.footerIcons} alt="" />
                                                    <img src={linkedin} style={webStyle.footerIcons} alt="" />
                                                </Box>
                                                <Box style={{ ...webStyle.appleAndGoogleCards, margin: "13px 0px", flexWrap: 'wrap', justifyContent: 'center' }}>
                                                    <img width="151px" height="46px" src={googleIcon} alt="" />
                                                    <img src={appleLogo} alt="" width="151px" height="46px" />
                                                </Box>
                                            </AppStoreGetItOnFooter>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </ThemeProvider>

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyle = {
    imgView: { width: "clamp(150px, 35%, 400px)", height: "70px" },
    ImgBackCenter1: { width: '65%', height: '600px', zIndex: 2, left: 0, marginTop: '-560px', background: 'linear-gradient(227deg, rgb(61 60 80) 66%, rgb(60 60 81 / 41%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
    ImgBackCenter: { width: '65%', height: '600px', background: `url(${bg1}) no-repeat`, backgroundSize: 'cover', zIndex: 1, marginTop: '-560px' },
    phoneBoxes: { backgroundColor: "#45455E", borderRadius: '13.36px', border: '0.64px solid white', display: 'flex', padding: '5px' },
    firstBox: { margin: '30px 0px', padding: '0px 20%' },
    ImgBackBottem1: { width: '65%', height: '700px', zIndex: -1, left: 0, bottom: "-210px", background: 'linear-gradient(227deg, rgb(61 60 80) 66%, rgb(60 60 81 / 41%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
    ImgBackBottem: { width: '65%', height: '700px', background: `url(${bg1}) no-repeat`, backgroundSize: 'cover', bottom: "-210px", zIndex: -1, left: 0 },
    ImgBackTop1: { width: '65%', right: '-458px', height: '65%', zIndex: 2, background: 'linear-gradient(45deg, rgb(60, 60, 81) 40%, rgba(60, 60, 81, 0.8) 60%, rgba(60, 60, 81, 0) 100%) repeat scroll 0% 0%, transparent none repeat scroll 0% 0%' },
    ImgBackTop: { width: '65%', height: '65%', right: '-458px', background: `url(${ImgBack}) no-repeat`, backgroundSize: 'cover', zIndex: 1 },
    InvestmentMarketplaceOnclick: { bottom: '85px', right: '21.1%', backgroundColor: 'rgba(17, 255, 255, 0)', borderRadius: '25px', padding: '30px', cursor: 'pointer' },
    NetworthFeatureOnclick: { top: '80px', right: '33.6%', backgroundColor: 'rgba(17, 255, 255, 0)', borderRadius: '25px', padding: '30px', cursor: 'pointer' },
    BudgetWebOnclick: { top: '80px', left: '33.1%', backgroundColor: 'rgba(17, 255, 255, 0)', borderRadius: '25px', padding: '30px', cursor: 'pointer' },
    EstatePlanningOnClick: { bottom: '85px', right: '75.6%', backgroundColor: 'rgba(17, 255, 255, 0)', borderRadius: '25px', padding: '30px', cursor: 'pointer' },
    anserFaq: { color: "white", fontSize: "clamp(0.467vw, 32px, 1.467vw)", fontFamily: 'Helvetica !important' },
    activeFAQImg: { width: "20px", paddingTop: "7px" },
    questionBoxColor: { color: "white", fontSize: "clamp(1vw, 37.15px, 1.835vw)", fontFamily: 'Helvetica !important', lineHeight: "1" },
    questionBox: { display: "flex", justifyContent: "space-between", alignItems: "self-start" },
    faqTextText: { marginTop: "77px", display: "flex", justifyContent: "center", marginBottom: "200px" },
    buttonGetSatred: { fontSize: "clamp(1.4rem,1.8vw,3vw)", fontFamily: 'Helvetica !important', color: "#3C3C51", fontWeight: 400, borderRadius: "50.72px" },
    GetStaredButton: { marginTop: "70px", marginBottom: "20px", background: "#2DE2D5", width: "100%", borderRadius: "3px", padding: "3px" },
    numberwidth2: { fontSize: "clamp(1vw,2.43vw,3vw)", fontFamily: 'Helvetica !important', fontWeight: 400 },
    numberwidth: { fontSize: "clamp(3vw,4.773vw,6vw)", fontFamily: 'Helvetica !important', fontWeight: 400 },
    PremiumMenu: { display: "flex", color: "#2DE2D5", alignItems: "baseline" },
    monthlyBox3: { marginTop: "20px", display: "flex", justifyContent: "center", marginBottom: '6%' },
    // monthlyBox2: { padding: "25px 17px", border: "1px solid #2DE2D5", display: "flex", gap: "5px", borderRadius: "15.76px" },
    monthlyBox: { justifyContent: "center", display: "flex", marginTop: "30px" },
    testimonail_3Name: { display: "flex", gap: "3px", justifyContent: "center", marginTop: "-50px" },
    TestimonialsData: { margin: '93px 0px', marginLeft: '10%', marginRight: '10%' },
    LearnBUtton: { marginTop: "60px" ,marginLeft:'8px'},
    networthBox: { paddingTop: "80px", margin: '0px 117px' },
    verticalsImg: { margin: "99px 0px" },
    MYNETopBottem: { width: "100%", marginTop: "70px" },
    changeTopBottem: { width: "100%", margin: "100px 0px", marginTop: '190px' },
    screensImg: { paddingTop: "70px", paddingLeft: "30px" },
    haderName: { paddingTop: "50px", paddingLeft: "30px", paddingRight: "30px" },
    fullPage: { width: "100%", display: "flex" },
    video: { display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${iphone13a})`, height: '400.87px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' },
    siteKey: { marginTop: '30px' },
    FormControlLabelButton: { color: "#FFF", fontSize: "37px", fontFamily: 'Helvetica !important', fontStyle: "normal", fontWeight: 400, lineHeight: "normal" },
    NameFont: { color: "#FFF", fontSize: "37.15px", fontWeight: 400, fontFamily: 'Helvetica', margin: "25px 0px", lineHeight: "normal" },
    socialMediaIcons: { display: 'flex', gap: '10px', alignItems: 'center' },
    footer: { width: '100%', padding: '50px 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    footerIcons: { width: '22px', },
    menuItemsForFooter: { fontSize: '12px', fontFamily: 'Helvetica !important', color: '#fff', marginLeft: '10px', cursor: 'pointer' },
    containerNew: { background: "#3C3C51", height: "100%", width: "100%", zInde: -1 },
    gridCard: { margin: "2px", background: "#45455E", borderRadius: "15px", padding: "24px 39px 27px 39px", cursor: "pointer", minHeight: 135 },
    packageDetails: { marginTop: "15px" },
    tickMark: { background: "#2DE2D5", borderRadius: "30px", padding: "6px", width: "26px" },
    packageCardText: { color: "#fff", fontSize: "clamp(1rem,1.2rem,3vw)", fontFamily: "Helvetica, sans-serif", fontWeight: 400, display: "flex",gap:'0.5rem',margin:'1px', alignItems: "flex-start", },
    testimonialNameLabel: { color: "#2DE2D5", fontSize: "clamp(1.2rem, 1.2vw, 2rem)",  lineHeight: '0.8',fontFamily: "Helvetica, sans-serif", },
    activePackageBtn: { background: "#2DE2D5", width: "283.26px", color: "#3C3C51", padding: "12px 15px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "clamp(1.2rem,1.5vw,7vw)", fontFamily: 'Helvetica !important', fontWeight: 400, borderRadius: "8.7px", cursor: "pointer" },
    PackageBtn: { color: "#2DE2D5", width: "283.26px", background: "#3C3C51", padding: "5px 15px", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: 'Helvetica !important', fontSize: "clamp(1.2rem,1vw,7vw)", fontWeight: 400, cursor: "pointer" },
    testimonialCard: { borderRadius: "15px", background: "#45455E", minHeight: "220px", padding: "20px" },
    testimonialHeader: { display: "flex", alignItems: "center", paddingLeft: "30px" },
    testimonialContent: { color: "#fff", fontSize: "clamp(1rem, 1.2vw, 2rem)", fontFamily: "Helvetica, sans-serif", padding: "15px 5px 0px 32px" },
    testimonialMainContent: { paddingLeft: "7px", paddingRight: "7px", paddingTop: "5px", lineHeight: '1.3',fontFamily: "Helvetica, sans-serif" },
    testimonialDesignationLabel: { color: "#fff", fontFamily: "Helvetica, sans-serif", fontSize: "clamp(0.9rem, 0.3vw, 2rem)", fontWeight:400 },
    textLabel: { color: "#fff", fontSize: "clamp(2vw, 92.71px,9.529vw)", fontFamily: 'Helvetica !important', lineHeight: '100%' },
    textLabel2: { color: "#2DE2D5", fontSize: "clamp(2vw, 92.71px, 9.529vw)", fontFamily: 'Helvetica !important', },
    featureDetailHeading: { lineHeight: '100%', color: "#2DE2D5", fontFamily: 'Helvetica !important', fontSize: "clamp(0vw,22px,1.246vw)", paddingBottom: "15px" },
    featureDetailDesc: { lineHeight: '100%', color: "#fff", fontFamily: 'Helvetica !important', fontSize: "clamp(0.146vw,22px,1.146vw)" },
    textLabel4: { color: "#FFF", fontSize: "clamp(1vw, 37.15px, 3.927vw)", fontFamily: 'Helvetica !important', margin: "0px 50px" },
    subText: { color: "#fff", marginBottom: "20px", fontFamily: 'Helvetica !important', fontSize: "clamp(1vw,37.15px,1.935vw)" },
    textLabel3: { color: "#fff", fontSize: "clamp(2vw, 7.917vw, 57.53px)", fontFamily: 'Helvetica !important', marginTop: "17px", marginLeft: "15px", fontWeight: 500, marginBottom: "13px" },
    verticals: { borderRadius: "20px", background: "#45455E", margin: "10px", minHeight: "100%", padding: "25px", cursor: "pointer", display: 'inline-block', fontFamily: 'Helvetica !important', fontSize: 'clamp(1vw,1.563vw, 3vw)' },
    verticalsActive: { boxShadow: '0 3px 10px #30D5C8', borderRadius: "20px", background: "#45455E", margin: "10px", minHeight: "100%", padding: "25px", cursor: "pointer", display: 'inline-block', fontFamily: 'Helvetica !important', fontSize: 'clamp(1vw,1.563vw, 3vw)' },
    featureDetails: { marginRight: "8px", width: "412.42px", display: "flex", alignItems: "center", borderRadius: "15px", background: "#45455E", padding: "20px 15px", marginTop: "4px", height: '100%', minHeight: "142.75px" },
    wcm: { color: "#3C3C51", background: "#2DE2D5", fontSize: "clamp(3vw,108.99px,5.625vw)", fontFamily: 'Helvetica !important', padding: "18px 0px", fontWeight: 400 },
    wcmCardSubHeader: { color: "#fff", fontSize: "19px", fontFamily: 'Helvetica !important', marginTop: "10px", lineHeight: "20px" },
    wcmCardIcon: { width: "70px", height: "70px", marginBottom: "41px" },
    wcmCardHeader: { color: "#2DE2D5", fontFamily: 'Helvetica !important', fontSize: "clamp( 1vw,1.771vw, 3vw)" },
    appStoreFooter: { background: "black", color: "#fff", width: "130px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
    googleCardFooter: { background: "black", color: "#fff", width: "150px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
    appStoreGetItOnFooter: { fontSize: "9px", fontFamily: 'Helvetica !important', },
    appleAndGoogleCards: { display: "flex", gap: "15px", marginLeft: "9px" },
    comingSoon: { color: "#2DE2D5", fontSize: "clamp(1vw, 33.32px, 2.735vw)", fontFamily: 'Helvetica !important', },
    demoBtn: { color: "#2DE2D5", fontWeight: 400, borderRadius: "13.37px", border: "2px solid #2DE2D5", padding: "7px 27px", cursor: "pointer", fontFamily: 'Helvetica !important', fontSize: "clamp(1vw, 41.08px, 1.90vw)" },
    inputRoot: { outline: 'none', borderRadius: '8px' },
    masterSaving: { color: "#2ee1d5" },
    borderBefore: { top: '0', right: '0', bottom: '0', left: '0', borderRadius: '20px', padding: '1px', background: 'rgba(0, 0, 0, 0) linear-gradient(45deg, white, #4f4f4f) repeat scroll 0% 0%', WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', WebkitMaskComposite: 'xor', maskComposite: 'exclude', },
    networthMen: { width: "100%", marginTop: "120px", display: 'flex', justifyContent: 'center' },
    networthAllData: { display: 'flex', justifyContent: 'center', margin: '10px 0px' },
    titleAll: { width: "100%", marginTop: "40px" },
    titleAllNew: { width: "100%" },
    samllBoxAll: { display: "flex", gap: "4px" },
    BoxDataAll: { padding: "0px 0px 0px 10px" },
    animationOne: { animationName: 'slideIn', animationDuration: '700ms', animationTimingFunction: 'ease-in-out', animationDelay: '50ms', animationFillMode: 'forwards', },
    animationTwo: { animationName: 'slideIn', animationDuration: '1200ms', animationTimingFunction: 'ease-in-out', animationDelay: '50ms', animationFillMode: 'forwards', },
    animationThree: { animationName: 'slideInRight', animationDuration: '700ms', animationTimingFunction: 'ease-in-out', animationDelay: '50ms', animationFillMode: 'forwards' },
    animationBottom: { animationName: 'slideInFromBottom', animationDuration: '700ms', animationTimingFunction: 'ease-in-out', animationDelay: '50ms', animationFillMode: 'forwards', },
    flipCard: { width: '200px', height: '200px', perspective: '1000px', },
    flipCardInner: { width: '100%', height: '100%', transition: 'transform 0.6s', },
    flipCardFront: { width: '100%', height: '100%' },
    flipCardBack: {
        backgroundColor: '#2980b9',
        color: 'white',
        transform: 'rotateY(180deg)'
    },
    flipped: { transform: 'rotateY(180deg)' },
    QaTitle: { fontSize: "clamp(3vw,92.71px,5.425vw)", fontFamily: 'Helvetica !important' },
    networkImage: { maxHeight: '90%' }
};
const ButtonSubmit = styled(Button)({ marginTop: "20px", fontWeight: 400, borderRadius: "13.37px", background: "#2DE2D5", width: "300px", color: '#fff', fontFamily: 'Helvetica !important', fontSize: "20px", textTransform: "capitalize", '&:hover': { background: "#2DE2D5" } })
const HeadTypography = styled(Typography)({
    fontSize: '92px !important',
    '@media(max-width: 1280px)': {
        fontSize: '64px !important',
    },
    '@media(max-width: 960px)': {
        fontSize: '48px !important',
    },
    '@media(max-width: 767px)': {
        fontSize: '32px !important',
    },
    '@media(max-width: 400px)': {
        fontSize: '24px !important',
    },
})
const MasterStyle = styled(Box)({
    fontSize: "clamp(2vw,97.3px,12.068vw)", color: "white", lineHeight: "100%", fontFamily: 'Helvetica !important',
});
const CustomAccordianGrid = styled(Grid)({
    '@media (max-width: 1440px)': {
        '& > div': {
            padding: '15px !important',
            minHeight: '95px !important'
        },
    },
    '@media (max-width: 1280px)': {
        '& > div': {
            padding: '15px !important',
            minHeight: '75px !important'
        },
    },
    '@media (max-width: 960px)': {
        '& > div': {
            padding: '15px !important',
            minHeight: '50px !important'
        },
        '& > div > div': {
            '& .MuiTypography-body1': {
                fontSize: '18px !important'
            }
        },
    },
    '@media (max-width: 767px)': {
        '& > div': {
            padding: '15px !important',
        },
        '& > div > div': {
            gap: '10px',
            '& .MuiTypography-body1': {
                fontSize: '14px !important'
            }
        },
        '& .accordianBox': {
            minHeight: 'unset !important',
            fontSize: '16px !important'
        }
    }
})
const FAQBox = styled(Box)({
    '@media (max-width: 1280px)': {
        marginTop: '50px !important',
        marginBottom: '50px !important',
    },
    '@media (max-width: 767px)': {
        marginTop: '30px !important',
        marginBottom: '50px !important',
    },
})
const VideoBox = styled(Box)({
    '@media (max-width: 600px)': {
        '& .videolable': {
            backgroundImage: 'unset !important',
        },
        '& #handleVideoClick': {
            maxWidth: '400px',
        }
    }
})
const CustomBox = styled(Box)({
    '@media (max-width: 1440px)': {
        marginInline: '40px !important',
    },
    '@media (max-width: 1280px)': {
        paddingTop: '40px !important',
        '& .content1': {
            fontSize: '24px !important',
        },
        '& .content2': {
            fontSize: '18px !important'
        },
        '& .WcmCardImg': {
            marginBottom: '20px !important'
        }
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
        '& .cardHeadIcon': {
            width: '55px !important',
            height: '55px !important',
            marginBottom: '20px !important'
        }
    },
    '@media (max-width: 600px)': {
        margin: '20px !important',
        '& .cardHeadIcon': {
            width: '40px !important',
            height: '40px !important',
            marginBottom: '20px !important'
        },
        '& .content1': {
            fontSize: '18px !important',
        },
        '& .content2': {
            fontSize: '14px !important'
        },
    },
})
const TypographyName = styled(Box)({
    '@media (max-width: 768px)': {
        testimonialNameLabel: {
            fontSize:'0.5rem', 
        }, }
})
const TypographyDesignation = styled(Box)({
    '@media (max-width: 768px)': {
        testimonialNameLabel: {
            fontSize:'0.3rem', 
        }, }

})
const TypographyContent = styled(Box)({
    '@media (max-width: 768px)': {
        testimonialNameLabel: {
            fontSize: '0.5rem',
        },
    }
})
const CustomBox2 = styled(Box)({
    '& .content1': {
        fontSize: '22px !important',
    },
    '& .content2': {
        fontSize: '22px !important'
    },
    '& .headingSubText': {
        fontSize: '34px !important'
    },
    '& .headingSubText2': {
        fontSize: '92px !important'
    },
    '& .imageGridBox': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .learn-btn': {
        fontSize: '22px !important',
    },
    '@media (min-width: 1280px)': {
        '& .mobileimg': {
            maxWidth: '95% !important',
        },
        '& .pr-20': {
            paddingRight: '20px'
        }
    },
    '@media (min-width: 1280px) and (max-width: 1560px)': {
        '& .content1': {
            fontSize: '18px !important',
        },
        '& .content2': {
            fontSize: '18px !important'
        },
        '& .headingSubText2': {
            fontSize: '64px !important'
        },
    },
    '@media (max-width: 1440px)': {
        '& .headingSubText2': {
            fontSize: '64px !important'
        },
        '& .innerParentBox': {
            margin: '0px 64px 8% !important'
        },
        '& .innerParentBox > div': {
            alignItems: 'center !important'
        },
    },
    '@media (max-width: 1280px)': {
        '& .content1': {
            fontSize: '18px !important'
        },
        '& .content2': {
            fontSize: '18px !important'
        },
        '& .headingSubText2': {
            fontSize: '48px !important'
        },
        '& .innerParentBox': {
            margin: '0px 64px 50px !important'
        },

    '& .learn-btn': {
        fontSize: '18px !important',
    },
    },
    '@media (min-width: 600px) and (max-width: 1279px)': {
        '& .mobileimg': {
            width: '300px !important',
            height: 'unset !important',
        },
        '& .headingSubText2': {
            fontSize: '48px !important'
        },
    },
    '@media(max-width: 960px)': {
        '& .subInnerBox': {
            minHeight: 'unset !important',
            padding: '10px 15px !important'
        },
    },
    '@media (max-width: 767px)': {
        marginTop: '50px !important',
        '& img': {
            width: '30px !important',
            objectFit: 'contain'
        },
        '& .mobileimg': {
            width: '300px !important',
            height: 'unset !important',
        },
        '& .innerParentBox': {
            margin: '0px 40px 8% !important'
        },
        '& .subInnerBox': {
            height: 'unset !important'
        },
        '& .content1': {
            fontSize: '16px !important',
        },
        '& .content2': {
            fontSize: '14px !important'
        },
        '& .headingSubText2': {
            fontSize: '32px !important'
        },
        '& .headingSubText': {
            fontSize: '14px !important'
        },
        '& .learn-btn': {
            fontSize: '14px !important',
            padding: '5px 20px !important'
        },
        '& .learnupper': {
            marginTop:'35px !important',
            marginBottom: '30px !important'
            
        },
    },
    '@media (max-width: 400px)': {
        '& .headingSubText2': {
            fontSize: '24px !important'
        },
    }
})
const MasterFinances = styled(Box)({
    color: "white", fontSize: "clamp( 1vw, 30px,4.563vw)", lineHeight: 'initial !important', fontFamily: 'Helvetica !important',
    paddingTop: "10px",
});
const MonthlyBox2 = styled(Box)({


    padding: "12px 10px", border: "1px solid #2DE2D5", display: "flex", gap: "5px", borderRadius: "15.76px",
    width: '392.92px',
    '@media (max-width: 468px)': {

        width: '292.92px',
        
    }
})
const PackageCard = styled(Box)({
    borderRadius: "20.19px", background: "#45455E", padding: "30px", width: '400.92px',
    '@media (max-width: 468px)': {

        width: '292.92px',
    }
})
const AppStoreGetItOnFooter = styled(Box)({
    "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const AppStoreGetItOnFooterHome = styled(Box)({
    display: 'flex', alignItems: 'center', margin: "13px 0px", textAlign: 'center',
    "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const TopImg = styled(Box)({
    "@media (min-width: 1946px)": { display: 'none' },
})

// Customizable Area End
