import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom'
import '../assets/css/myneSettingsDeleteProfile.css'
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";
import { deleteLargeIcon } from "./assets";

export default class MyneSettingsDelete extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  roundedDelete = () => {
    return this.state.logoImgsetting === "rtl" ? "dl-close-rounded dl-close-rounded-new" : "dl-close-rounded"
  }
  checkmarkOne = () => {
    return this.state.logoImgsetting === "rtl" ? "dl-checkmarkOne" : "dl-checkmarkOne dl-checkmarkOne-new"
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="myne-settings-delete-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-delete-container">
          <div className="dl-small-text">{this.getStringGoalmanagement("AccountDetails")}
            <Link to={'/Dashboard'} className={this.roundedDelete()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="dl-bold-text">{this.getStringGoalmanagement("DeleteAccount")}</div>
          <div className="profile-delete-container">
            <div className="image-delete-container">
              <div className="profile-delete-image">
                <img src={deleteLargeIcon} alt="delete image" />
              </div>
            </div>
            <div className="dl-detail-container detail-container-delete">
              <div className="dl-detail-section">
                <span className="dl-detail-small-text delete-title-color">{this.getStringGoalmanagement("WarningP")}</span>
              </div>

              <div className="dl-detail-section" style={{ marginTop: 15 }}>
                <label className="dl-container">
                  <input type="checkbox" checked={this.state.isProfileDelete} data-test-id="delete-permission" 
                  onChange={(e) => this.handleInputChange(e.target.checked, 'isProfileDelete')} />
                  <span className={this.checkmarkOne()}></span>
                  {" "} <span className="dl-detail-small-text" style={{ margin:"0px  25px" }}>{this.getStringGoalmanagement("irreversible")}</span>
                </label>

              </div>

              <div data-test-id='profile-delete-btn' className="dl-detail-section" style={{ marginTop: 13 }} onClick={() => this.onDeleteProfile()}>
              <div 
                  className={`delete-button ${!this.state.isProfileDelete ? 'disabled' : ''}`}
                  onClick={this.onDeleteProfile}
                  style={{ cursor: this.state.isProfileDelete ? 'pointer' : 'not-allowed', backgroundColor: this.state.isProfileDelete ? '#ee5353' : '#8C3F46' }}
                >
                  {this.getStringGoalmanagement("ConfirmDeletion")}
                </div>              </div>
            </div>

          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}