Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.subscriptionApi = "bx_block_stripe_integration/subscriptions/get_subscription";
exports.exampleAPiMethodtokenSubscription = "GET";
exports.subscriptionConfirmApi = "bx_block_stripe_integration/subscriptions/cancle_suscription";
exports.subscriptionCancelApi = "bx_block_stripe_integration/subscriptions/cancel_subscription";
exports.exampleAPiMethodtokenConfirmApi = "DELETE";
exports.languageListSubscription = {
  English: {
    ManageSubscription:"Manage Subscription",
    PlanDetails:"Plan Details",
    YourPlan:"Your Plan:",
    NextBillingDate:"Next Billing Date:",
    Settings:'Settings',
    PlanType:"Plan Type:",
    Trial30Day:"30 Day Trial",
    PaymentDetails:"Payment Details",
    Method:"Method:",
    RetryPayment:"Retry Payment",
    RenewSubscription:"Renew Subscription",
    BillingAddress:"Billing Address:",
    OtherPlan:"Explore other plans:",
    SubscriptionCancelled:"Your subscription will be cancelled.",
    GoBack:"Go Back",
    Confirm:"Confirm",
    ValidTill:"Valid till",
    CancelSubscription:"Cancel Subscription",
    EditDetails:"Edit details",
    ReSubscribe:"Re-Subscribe",
    ChangeSubscription:"Change Subscription",
    Year:"Year",
    ActiveMessage:"Congratulations, you’re enjoying the Premium Benefits",
    CanceledMessage:"Re-subscribe now to access extra features",
    ExpiredMessage:"Renew now to access extra features with Premium Subscription",
    PendingMessage:"There was an issue with your payment, add a new payment method or check with your bank",
    TrialMessage:"Congratulations, you're using the 30 Day Trial Plan",
    SuspendedMessage:"Your account has been suspended",
    FailedMessage:"Your subscription was failed",
    DefaultMessage:"Congratulations, you’re enjoying the Premium Benefits",
    Per:"per",
    Month:"Month",
    DaysLeft:"DAYS LEFT",
    Active:'Active',
    TrialPlan:'Trial Plan',
    Premium:'Premium',
    Expired:"Expired",
    Pending:"Pending",
    Canceled:'Canceled',
    Suspended:'Suspended',
    Failed:"Failed",


  },
  Arabic: {
    YourPlan:"خطتك:",
    Failed:'فشل',
    Suspended:"معلق",
    Pending:'قيد الانتظار',
    Canceled:'تم الإلغاء',
    Expired:'منتهي الصلاحية',
    TrialPlan:'الخطة التجريبية',
    Active:'نشيط',
    Premium:'غالي',
    PlanDetails:"تفاصيل الخطة",
    PlanType:"نوع الخطة:",
    NextBillingDate:"تاريخ الفاتورة التالي:",
    PaymentDetails:"تفاصيل الدفع",
    Trial30Day:"تجربة لمدة 30 يومًا",
    OtherPlan:"اكتشف الخطط الأخرى:",
    BillingAddress:"عنوان إرسال الفواتير:",
    GoBack:"عُد",
    SubscriptionCancelled:"سيتم إلغاء اشتراكك.",
    ManageSubscription: "إدارة الاشتراك",
    Confirm:"يتأكد",
    Method:'طريقة:',
    Settings:'إعدادات',
    RetryPayment:"إعادة محاولة الدفع",    
    RenewSubscription:"تجديد الاشتراك",
    CancelSubscription:"إلغاء الاشتراك",
    ValidTill:"صالحة حتى",
    ReSubscribe:"إعادة الاشتراك",
    EditDetails:"تحرير التفاصيل",
    ActiveMessage:"تهانينا، أنت تستمتع بالمزايا المميزة",
    ChangeSubscription:"تغيير الاشتراك",
    PendingMessage:"حدثت مشكلة في دفعتك، أضف طريقة دفع جديدة أو راجع البنك الذي تتعامل معه",
    CanceledMessage:"أعد الاشتراك الآن للوصول إلى الميزات الإضافية",
    Year:"سنة",
    DaysLeft:"أيام متبقية",
    TrialMessage:"تهانينا، أنت تستخدم الخطة التجريبية لمدة 30 يومًا",
    SuspendedMessage:"لقد تم تعليق حسابك",
    FailedMessage:"لقد فشل اشتراكك",
    DefaultMessage:"تهانينا، أنت تستمتع بالمزايا المميزة",
    Per:"لكل",
    Month:"شهر",
    ExpiredMessage:"قم بالتجديد الآن للوصول إلى ميزات إضافية مع الاشتراك المميز",


  }
}
// Customizable Area End
