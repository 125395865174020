import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/Common";
import { networth, budgeting, investing, estatePlanning, aggregation, snap, tracker, insurance, credit, valoraisation, spendsLive, createGoals, categorySpend, patterns, track_and_view, spendVsBudget, options, performance, diversification, customization, resources, userFriendly, life_insurance, retirement_plan, poa, digital_wall, vault, benefciary, trusts } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  animationOneData: boolean;
  activeFeature: any;
  activePackage: any;
  activeFAQ: any;
  faqs: any;
  activeFrom: boolean;
  name: string,
  email: string,
  website: string,
  client_portfolio_count: string,
  role: number
  isVideoPlaying: boolean
  faqApi: any
  Testimonials: any
  playFirstTime: boolean,
  emailerror: string,
  emailerror1: string,
  client_portfolio_count_error: string,
  captcha: boolean,
  captchaError: string,
  isFlipped: boolean,
  NetWorthResponse: Object[],
  chooseImg: any,
  ServicesResponse: [],
  servicesImg: any,
  BudgetingImg: any,
  InvestingImg: any,
  EstatePlanningImg: any,
  HomePageData: any,
  isIntersecting: boolean,
  isTitleDataIntersecting: boolean,
  animationOne1: boolean,
  animationThree1: boolean,
  animationOne2: boolean,
  animationThree3: boolean,
  animationBottom: boolean,
  testimonialsAnimation: boolean,
  netWorthShow: boolean,
  budgetingShow: boolean,
  investingShow: boolean,
  estatePlanningShow: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserPDFDataCallId: string = "";
  postUserFaqDataCallId: string = "";
  postUserTestimonialsDataCallId: string = "";
  postUserMynesCallId: string = "";
  postServicesCallId: string = "";
  postHomePageCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      animationOneData: false,
      animationBottom: false,
      animationOne2: false,
      HomePageData: [],
      EstatePlanningImg: [life_insurance, retirement_plan, poa, digital_wall, vault, benefciary, trusts],
      InvestingImg: [options, performance, diversification, customization, resources, userFriendly],
      BudgetingImg: [spendsLive, createGoals, categorySpend, patterns, track_and_view, spendVsBudget],
      servicesImg: [aggregation, snap, tracker, insurance, credit, valoraisation],
      ServicesResponse: [],
      NetWorthResponse: [],
      chooseImg: [networth, budgeting, investing, estatePlanning],
      activeFrom: false,
      activeFeature: "Net Worth",
      activePackage: "monthly",
      activeFAQ: [],
      isVideoPlaying: false,
      emailerror1: "",
      name: '',
      email: '',
      website: '',
      role: 0,
      client_portfolio_count: '',
      client_portfolio_count_error: "",
      faqs: [
        {
          id: 1,
          title: "What is MYNE and how can it beneft me?",
          desc:
            "MYNE is a comprehensive wealth and asset tracking platform that simplifies and enhances your financial management. From aggregating your assets to offering strategic investment opportunities and estate planning, Myne empowers you with tools for a better financial future."
        },
        {
          id: 2,
          title: "How does MYNE help in asset aggregation?",
          desc:
            "MYNE enables you to sync and visualize all your assets, including bank accounts, stocks, cryptocurrencies, and tangible assets like properties and luxury items, offering a complete picture of your net worth in real-time."
        },
        {
          id: 3,
          title: "Can MYNE assist me with budgeting and expense tracking?",
          desc:
            "Absolutely! MYNE provides real-time insights into your spending habits, helping you monitor expenses, manage budgets effectively, and identify opportunities to increase savings."
        },
        {
          id: 4,
          title: "What investment opportunities does MYNE ofer?",
          desc:
            "Our Investment Marketplace connects you to a diverse range of investment options, carefully curated to align with your fnancial goals, backed by real-time market data for informed decision-making."
        },
        {
          id: 5,
          title: "How does MYNE facilitate estate planning?",
          desc:
            "MYNE simplifies estate planning by connecting you with experienced estate planners. We assist with inheritance matters, wills, trusts, and more, ensuring your wealth is managed according to your wishes."
        },
        {
          id: 6,
          title: "Is my fnancial data secure with MYNE?",
          desc:
            "Yes, your data’s security is our utmost priority. Myne uses advanced encryption, secure authentication methods, and continuous monitoring for anomalies to ensure your fnancial information remains private and protected."
        },
        {
          id: 7,
          title: "How does MYNE use AI and machine learning?",
          desc:
            "MYNE leverages AI and machine learning to analyze financial data, providing personalized insights and recommendations, and enhancing investment strategies to optimize your financial outcomes."
        },
        {
          id: 8,
          title: "Why was MYNE created?",
          desc:
            "MYNE was born out of a vision to make financial management accessible, insightful, and empowering. We recognized the need for a tool that not only aggregates financial data but also helps in growing and protecting wealth, making wealth management a seamless part of everyday life."
        },
        {
          id: 9,
          title: "Can I access MYNE from any device?",
          desc:
            "Yes, MYNE is designed for accessibility. You can manage your finances conveniently using Myne from any web-enabled device, be it a computer, tablet, or smartphone."
        },
        {
          id: 10,
          title: "Are there subscription fees for using MYNE?",
          desc:
            "MYNE offers competitive subscription plans to access our full range of features and services. You can choose a plan that best fits your needs, with options for monthly, quarterly, or annual subscriptions."
        },
        {
          id: 11,
          title: "How do I get started with MYNE?",
          desc:
            "Getting started is easy! Simply sign up on our website, sync your financial accounts, and begin exploring the comprehensive suite of tools and services designed to enhance your financial well-being."
        }
      ],
      faqApi: [],
      Testimonials: [],
      playFirstTime: false,
      emailerror: "",
      captcha: false,
      captchaError: '',
      isFlipped: false,
      isIntersecting: false,
      isTitleDataIntersecting: false,
      animationOne1: false,
      animationThree1: false,
      animationThree3: false,
      testimonialsAnimation: false,
      netWorthShow: false,
      budgetingShow: false,
      investingShow: false,
      estatePlanningShow: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserFaqDataCallId !== null &&
      this.postUserFaqDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let FaqResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ faqApi: FaqResponseJson.faqs })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserTestimonialsDataCallId !== null &&
      this.postUserTestimonialsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let TestimonialsResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ Testimonials: TestimonialsResponseJson.testimonials })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let FormResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.submitFormData(FormResponseJson)
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserMynesCallId !== null &&
      this.postUserMynesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let NetWorthResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getServicesList(NetWorthResponseJson.why_choose_mynes[0].id)
      this.setState({ NetWorthResponse: NetWorthResponseJson.why_choose_mynes })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postServicesCallId !== null &&
      this.postServicesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let ServicesResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ ServicesResponse: ServicesResponseJson.services })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postHomePageCallId !== null &&
      this.postHomePageCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let HomePageResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ HomePageData: HomePageResponseJson.home_pages })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTogglePlay = (event: any) => {
    const video = event.target;
    if (!this.state.isVideoPlaying) {
      video.play();
      this.setState({ playFirstTime: true })
    } else {
      video.pause();
    }
    this.setState({ isVideoPlaying: !this.state.isVideoPlaying })
  };
  async componentDidMount() {
    this.getCurrencyList();
    this.getTestimonialsList();
    this.animationStyle();
    this.getwhy_choose_mynesList();
    this.getHomePageList();
    window.addEventListener("scroll", this.handleScroll, true);
    this.checkIntersection();
  }

  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
  handleScroll = () => {
    this.checkIntersection();
  };
  checkIntersection = () => {
    ['isIntersecting', 'testimonialsAnimation', 'netWorthShow', 'handleVideoClick', 'estatePlanningShow', 'budgetingShow', 'investingShow', 'animationOneData', 'isTitleDataIntersecting', 'animationTwo', 'animationThree', 'animationOne1', 'animationThree1', 'animationOne2', 'animationThree3', 'animationBottom'].forEach((value) => {
      const titleDataElement: HTMLElement | any = document.getElementById(value);
      if (titleDataElement) {
        const { top, bottom } = titleDataElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const isTitleDataIntersecting = top < windowHeight && bottom > 0;
        if (value == 'handleVideoClick') {
          this.videoPlay(titleDataElement, isTitleDataIntersecting)
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [value]: isTitleDataIntersecting,
          }));
        }
      }
    });
  };

  videoPlay = (titleDataElement: HTMLElement | any, isTitleDataIntersecting: boolean) => {
    if (this.state.isVideoPlaying) {
      if (titleDataElement.paused) {
        isTitleDataIntersecting && titleDataElement.play();
      } else {
        !isTitleDataIntersecting && titleDataElement.pause();
      }
    }
  }
  animationStyle = () => {
    const style = document.createElement('style');
    style.innerHTML = `
        @keyframes slideIn {
            from {
                transform: translateX(-100%);
                opacity: 0;
            }
            to {
                transform: translateX(0);
                opacity: 1;
            }
        }
        @keyframes slideInRight {
          from {
              transform: translateX(100%);
              opacity: 0;
          }
          to {
              transform: translateX(0);
              opacity: 1;
          }
      }
      @keyframes slideInFromBottom {
        from {
            transform: translateY(100%);
            opacity:  0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes flipInY {
      from {
          transform: rotateY(180deg);
          opacity: 0;
      }
      to {
          transform: rotateY(0deg);
          opacity: 1;
      }
  }
   @keyframes slideInNew {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
  }
    `;
    document.head.appendChild(style);
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  submitFormData = (FormResponseJson: { errors?: { email?: string; client_portfolio_count?: string }[], message: string }) => {
    this.setState({ emailerror: '', client_portfolio_count_error: '' })
    if (FormResponseJson.errors) {
      FormResponseJson.errors.forEach((element: any) => {
        element.email && this.setState({ emailerror: this.state.emailerror ? this.state.emailerror : element.email })
        element.client_portfolio_count && this.setState({ client_portfolio_count_error: this.state.client_portfolio_count_error ? this.state.client_portfolio_count_error : element.client_portfolio_count })
      });
    }
    if (FormResponseJson.message.includes("successfully")) {
      this.setState({ name: "", email: "", client_portfolio_count: "", role: 0, activeFrom: false });
    }
  }
  changeActiveFeature = (featureName: any) => {
    this.setState({ activeFeature: featureName.title });
    this.getServicesList(featureName.id)
  };

  changeActivePackage = (packageName: any) => {
    this.setState({ activePackage: packageName });
  };
  updateActiveFAQ = (id: any) => {
    if (this.state.activeFAQ.includes(id)) {
      let index = this.state.activeFAQ.indexOf(id);
      const newArray = [
        ...this.state.activeFAQ.slice(0, index),
        ...this.state.activeFAQ.slice(index + 1)
      ];
      this.setState({ activeFAQ: newArray });
    } else {
      const newArray = [...this.state.activeFAQ, id];
      this.setState({ activeFAQ: newArray });
    }
  };

  activeFromNew = () => {
    this.setState({ activeFrom: !this.state.activeFrom });
  }
  changeCaptcha = (value: string | null) => {
    this.setState({ captcha: value ? true : false })
  }
  handleRoleChange = (e: any) => {
    this.setState({ role: Number(e.target.value) });
  };

  setInitialValure = (key: keyof S, value: string) => {
    this.setState({ [key]: value } as unknown as Pick<S, keyof S>);
  }

  HandleSubmit = () => {
    this.setState({ captchaError: '' })
    if (this.state.captcha) {
      const header = { "Content-Type": configJSON.validationApiContentTypeFrom };
      const httpBody = {
        data: {
          name: this.state.name,
          email: this.state.email,
          website: null,
          self_profile: this.state.role,
          client_portfolio_count: this.state.client_portfolio_count
        },
      };
      let userDetailurl = configJSON.validationApiMethodTypeFrom;
      const postPdfData = apiCall({
        httpBody: httpBody,
        header: header,
        url: userDetailurl,
        httpMethod: configJSON.validationApiMethodTypePost,
      });
      this.postUserPDFDataCallId = postPdfData.messageId;
      runEngine.sendMessage(postPdfData.id, postPdfData);
    } else {
      this.setState({ captchaError: 'Please fill in the correct captcha.' })
    }
  }

  getCurrencyList = () => {
    const header = {
      "Content-Type": "application/json",
    };
    let userDetailurl = configJSON.Loginsubmittoken;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethodtoken,
    });
    this.postUserFaqDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  getTestimonialsList = () => {
    const header = {
      "Content-Type": "application/json",
    };
    let userDetailurl = configJSON.Testimonials;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleTestimonials,
    });
    this.postUserTestimonialsDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  goToBudgetWeb = () => {
    const msg: Message = new Message(getName(MessageEnum.BudgetWebAdapter));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToNetworthFeature = () => {
    const msg: Message = new Message(getName(MessageEnum.NetworthFeatureAdapter));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToEstatePlanningWeb = () => {
    const msg: Message = new Message(getName(MessageEnum.EstatePlanningWebAdapter));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToInvestmentMarketplaceWeb = () => {
    const msg: Message = new Message(getName(MessageEnum.InvestmentMarketplaceWebAdapter));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleVideoEnd = () => {
    this.setState({ isVideoPlaying: false })
  };

  getwhy_choose_mynesList = () => {
    const header = {
      "Content-Type": "application/json",
    };
    let userDetailurl = configJSON.NetWorthGet;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleNetWorth,
    });
    this.postUserMynesCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  getServicesList = (servicesId: number) => {
    const header = {
      "Content-Type": "application/json",
    };
    let userDetailurl = `${configJSON.ServicesGet}?why_choose_myne_id=${servicesId}`;
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleServices,
    });
    this.postServicesCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }
  getHomePageList = () => {
    const header = {
      "Content-Type": "application/json",
    };
    let userDetailurl = configJSON.HomePageGet
    const postPdfData = apiCall({
      header: header,
      httpBody: {},
      url: userDetailurl,
      httpMethod: configJSON.exampleHomePage,
    });
    this.postHomePageCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  // Customizable Area End
}
