import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import "../assets/css/popo.css";
import EmailAccountRegistrationsControllerweb, {
  Props,
} from "./EmailAccountRegistrationsController.web";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {warning ,SuccessCard} from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Regex } from "../../../components/src/commonFn";




// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-input": {
          color: "white",
          width: '100%',
          borderBottom: '0px',
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff !important",
        },

      },
    },
  },
});

// Customizable Area End
export class EmailAccountRegistrationBlockweb extends EmailAccountRegistrationsControllerweb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleDropdownToggle = () => {
    this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };



  handleFocusChange = (selectedValues: string[] | any) => {
    if (Array.isArray(selectedValues)) {
      const selectedFocusObjects = this.state.focusData.filter((item: any) => selectedValues.includes(item.attributes.name));
      
      this.setState({ selectedFocus: selectedFocusObjects });
    }
  };


  
  handleRemoveFocus = (removedItem: string) => {
  
    const updatedFocus = this.state.selectedFocus.filter((item: any) => item.attributes.name !== removedItem);
  
    this.setState({ selectedFocus: updatedFocus });
  };

  handleCondition = (condition: boolean, truthyValue: string, falsyValue: string) => {
    return condition ? truthyValue : falsyValue
  }



  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box className='mainWrapper' style={{
        scrollbarWidth: 'thin',
        scrollbarColor: '#00ebd9 transparent',
        height: '100vh',
        overflowY: 'scroll',
      }}>
        <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px', paddingTop: '15px' }}>
          <Box className="borderCss">
            <Box className="verify-box-new">
              <ThemeProvider theme={theme}>
                <Box style={webStyle.logoandimg1} dir={this.state.languages}>
                  <img style={{ width: '200px', height: '100%', top: '42px' }} src={this.state.logoimg} />
                </Box>
                <CssBaseline />
                <Container >
                  <Box className="boxSpace" dir={this.state.languages}>
                    <Box>
                      <Typography style={webStyle.customLabel}>Create Account</Typography>
                      <Typography
                        style={{ color: 'white', fontSize: '16px', fontWeight: 500, marginBottom: '15px', fontFamily: 'Poppins' }}
                        variant="subtitle1" component="div">
                        Fill in your details to continue
                        {/*UI Engine::From Sketch*/}
                      </Typography>
                    </Box>
                    <Formik
                      initialValues={{ 
                        name: this.state.name,
                        location: this.state.location,
                        jurisdiction_advice: this.state.jurisdiction_advice,
                        certificate: this.state.certificate,
                        qualification: this.state.qualification,
                        employment_history: this.state.employment_history,
                        firm: this.state.firm,
                        experience: this.state.experience,
                        disclosure: this.state.disclosure,
                        focus: this.state.selectedFocus,
                        mobile: this.state.mobile,
                        firm_website: this.state.firm_website, } as Record<string, unknown> as Record<string, string>}
                     
                      onSubmit={(values: any) => {
                        this.updateCreateaccount(values);
                        this.setState({ openSuccessDialog: true }); 
                      }}
                      data-test-id="accountLogin"
                      validateOnChange={false}
                      validateOnBlur={false}
                      validate={ (values: { [key: string]: string })=> {
                        const errors: any = {};
                        Object.keys(values).forEach((key) => {
                          if (!values[key]) {
                            errors[key] = 'This field is required';
                          }
                          if(values.focus && values.focus.length === 0) {
                            errors.focus = 'This field is required'
                          }
                          if (values.firm_website && !Regex.urlRegex.test(values.firm_website)) {
                            errors.firm_website = 'Invalid URL format';
                          }
                          
                          if (key === 'mobile') {
                            const mobileRegex = /^\+?\d{6,15}$/; 
                            if (!mobileRegex.test(values.mobile)) {
                              errors.mobile = 'Mobile number must be a valid mobile number';
                            }
                          }
                        });
                       
                       
                        return errors;
                      }}
                    >
                      {({ errors, values, handleSubmit, handleChange, setFieldValue }: any) => (
                        <form onSubmit={handleSubmit} data-test-id='submitForm'>
                        <Box></Box>
                           {Object.keys(errors).length > 0 && (
                        <Box sx={webStyle.fieldErrorMsg}>
                            <img style={{width:"20px"}} src={warning} />
                            
                              All fields must be filled!
                        </Box>
                      )}
                          <Box className="inputFieldCss" >

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Full Name</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                error={ errors.name}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your full name'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='name'
                                  value={values.name}
                                  onChange={handleChange('name')}
                                />
                                 <Typography variant="caption" color="error">{errors.name}</Typography>
                              </Box>
                            </Box>

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                {this.getString('emailAddress')}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='abc@gmail.com'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='email'
                                  value={values.email || this.state.storedEmail}
                                />

                              </Box>
                            </Box>



                            <Box className="inputFieldCss">
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Date of birth</InputLabel>

                              <Box style={{ marginBottom: '16px' }}>
                                <div className={`custom-tooltips ${this.handleCondition(this.state.isHovered,'show','hide')}`}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <CustomDatePicker
                                      openTo="year"
                                      disableToolbar={true}
                                      data-testid='date-txt'
                                      placeholder="Enter your date of birth"
                                      format="MM/dd/yyyy"
                                      value={this.state.Dateofcreate}
                                      onChange={this.dateOfBirth}
                                      maxDate={new Date()}
                                      style={webStyle.inputRoot}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <DateRangeOutlinedIcon style={{ color: '#2CE2D5', fontSize: "30px" }} />
                                          </InputAdornment>
                                        ),
                                      }}
                                      okLabel='Done'
                                      fullWidth
                                    />
                                    <div style={webStyle.redAlert}></div>

                                  </MuiPickersUtilsProvider>
                                  <span className={this.handleCondition(localStorage.getItem('language') == "Arabic", "tooltip-textTransfrom", "tooltip-text")} >
                                    <Box style={{ padding: '3px' }} >
                                      <Typography style={{ fontSize: '0.8rem' }}>{this.getString("languagePasswordRequirements")}</Typography>
                                      {this.colorCheck(values)}
                                    </Box>
                                  </span>

                                </div>
                              </Box>
                            </Box>

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Mobile Number</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={Boolean(errors.mobile)}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your mobile number'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='mobileNumber'
                                  value={values.mobile}
                                  onChange={handleChange('mobile')}
                                />
                                <Typography variant="caption" color="error">{errors.mobile}</Typography>
                              </Box>
                            </Box>

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Location</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <SelectBox
                                  error={errors.location}
                                  data-test-id="locationSelect"
                                  value={values.location}
                                  onChange={handleChange('location')}
                                  displayEmpty
                                  IconComponent={ExpandMoreIcon}
                                  renderValue={(selected: any) => (
                                    <Box>
                                      <span
                                        style={{
                                          color: selected ? 'white' : 'rgba(245, 243, 243, 0.22)', // Adjust colors as needed
                                          fontSize: '16px',
                                        }}
                                      >
                                        {selected || 'Select your location'}
                                      </span>
                                    </Box>
                                  )}
                                >
                                  {this.state.allCountries1 && this.state.allCountries1.map((country: any) => (
                                    <MenuItem key={country.id} value={country.attributes.name}
                                      style={{
                                        color: 'white',
                                        background: '#395D6B',
                                        borderRadius: '4px',
                                        marginTop: -8,
                                        marginBottom: -8
                                      }}>
                                      {country.attributes.emoji_flag}&nbsp;
                                      {country.attributes.name}
                                    </MenuItem>
                                  ))}

                                </SelectBox>
                                <Typography variant="caption" color="error">{errors.location}</Typography>

                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Firm</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.firm}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your firm name'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='firm'
                                  value={values.firm}
                                  onChange={handleChange('firm')}
                                />

                                <Typography variant="caption" color="error">{errors.firm}</Typography>
                              </Box>

                            </Box>

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Firm Website</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.firm_website}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your firm website link'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='firmWebsite'
                                  value={values.firm_website}
                                  onChange={handleChange('firm_website')}
                                />
                                <Typography variant="caption" color="error" data-test-id="error-firm">
                                  {errors.firm_website}
                                </Typography>
                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Experience</InputLabel>
                              <Box style={{ marginBottom: '15px' }}>
                                <OutlinedInput
                             inputProps={{
                              type: 'number',
                              numeric: '[0-9]*'
                            }}
                                error={ errors.experience}
                                  style={webStyle.inputRoot}
                                  data-test-id="experience-input-field"
                                  placeholder='Enter your Experience '
                                  fullWidth={true}
                                  className="inputBox"
                                  name='experience'
                                  value={values.experience}
                                  onChange={(event) => {
                                    const { value } = event.target;
                                    if (value.length <= 2) {
                                      handleChange('experience')(event);
                                    }
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Jurisdiction Advice</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.jurisdiction_advice}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your jurisdiction advice'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='jurisdiction'
                                  value={values.jurisdiction_advice}
                                  onChange={handleChange('jurisdiction_advice')}
                                />

                                <Typography variant="caption" color="error">{errors.jurisdiction_advice}</Typography>
                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Certification</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.certificate}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your certification'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='certification'
                                  value={values.certificate}
                                  onChange={handleChange('certificate')}
                                />

                                <Typography variant="caption" color="error">{errors.certificate}</Typography>
                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Qualification</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.qualification}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your qualification'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='qualification'
                                  value={values.qualification}
                                  onChange={handleChange('qualification')}
                                />
                                <Typography variant="caption" color="error">{errors.qualification} </Typography>
                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Employement History</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.employment_history}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter your last employer name'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='employement'
                                  value={values.employment_history}
                                  onChange={handleChange('employment_history')}
                                />
                                <Typography variant="caption" color="error">{errors.employment_history}</Typography>
                              </Box>
                            </Box>
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >
                                Disclosure</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.disclosure}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder='Enter if any disclosure'
                                  fullWidth={true}
                                  className="inputBox"
                                  name='disclosure'
                                  value={values.disclosure}
                                  onChange={handleChange('disclosure')}
                                />
                                <Typography variant="caption" color="error">{errors.disclosure}</Typography>

                              </Box>
                            </Box>
                            <Box data-test-id="openChip"
                              style={{ marginBottom: '16px' }}>
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password">
                                Focus
                              </InputLabel>
                              <SelectBox
                                error={errors.focus}
                                name="focus"
                                data-test-id="openChip1"
                                multiple
                                value={this.state.selectedFocus.map((item: any) => item.attributes.name)}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                  const selectedValues = Array.isArray(e.target.value) ? e.target.value : [];
                                  this.handleFocusChange(selectedValues);
                                  setFieldValue("focus", selectedValues)
                                }}
                                displayEmpty
                                IconComponent={ExpandMoreIcon}
                                renderValue={(selected: any) => (
                                  <Box>
                                    {Array.isArray(selected) &&
                                      selected.map((item: any) => (
                                        <Chip
                                          data-test-id="txtInputEmail11"
                                          key={item}
                                          label={item}
                                          onMouseDown={(event) => event.stopPropagation()}
                                          onDelete={()=>{
                                            this.handleRemoveFocus(item)
                                            setFieldValue('focus', this.state.selectedFocus.filter((value: { attributes: {name: string}}) => value.attributes.name !== item))
                                          }}
                                          deleteIcon={<CancelOutlinedIcon />}
                                        />
                                      ))}
                                    {(!selected || (Array.isArray(selected) && selected.length === 0)) &&
                                      'Select your focus areas'}
                                  </Box>
                                )}
                              >
                                {this.state.focusData && this.state.focusData.map((item: any) => (
                                  <MenuItem key={item.id} value={item.attributes.name} style={{ color: "white", background: "#395D6B", borderRadius: "4px", marginTop: -8, marginBottom: -8 }}>
                                    {item.attributes.name}
                                  </MenuItem>
                                ))}
                              </SelectBox>
                                <Typography variant="caption" color="error">{errors.focus}</Typography>
                            </Box>
                            <Box sx={webStyle.forget}>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                className="customButton"
                                data-test-id={"btnEmailLogIn"}
                                variant="contained"
                                fullWidth
                                type="submit"
                              >
                                {this.getString('createAccountButton')} {/*UI Engine::From Sketch*/}
                              </Button>
                            </Box>
                          </Box>

                        </form>
                      )}
                    </Formik>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={this.state.openSuccessDialog}
          keepMounted
          onClose={this.dialogCashModel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogCashPaperStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}>
            <img src={SuccessCard} />
          </DialogTitle>
          <DialogContent  >
            <DiologComponent style={webStyle.dialogContent as React.CSSProperties} id="alert-dialog-slide-description" className='dialogContent'>
              Thank you for registering. Your registration will
              be reviewed by Myne and you will be notified once your account is open.
            </DiologComponent>
          </DialogContent>
        </Dialog>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default EmailAccountRegistrationBlockweb
const DiologComponent = styled(DialogContentText)({
  "&.dialogContent": {
    fontSize: '15px !important'
  }
})
const SelectBox = styled(Select)({
  borderRadius: '10px',
  minHeight: '56px',
  outline: 'none',
  fontFamily: 'Poppins',
  border: '2px solid white',
  width: '100%',
  '& .MuiSelect-select': {
    width: '100% !important',
    paddingLeft: 10,
    color: '#ffffff35 !important',
    fontSize: '16px !important',
    height: 'unset !important',
    // whiteSpace: 'break-spaces !important',
    '& > .MuiBox-root': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 5
    }
  },
  '& .MuiChip-deletable': {
    backgroundColor: 'rgb(57, 93, 107);',
    color: "white",
    borderRadius: "10px",
    fontSize: "15px"
  },
  '& .MuiSelect-icon': {
    color: '#2CE2D5'
  },
  '& .MuiList-padding': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiChip-deleteIcon': {
    color: "white",
  },

})

const CustomDatePicker = styled(DatePicker)({
  border: "1px solid white",
  '& input[type="text"]': {
    borderBottom: 'none',
    padding: 25,
    cursor: 'pointer',
    marginLeft: "-10px",
  },
  '& input::placeholder': {
    fontWeight: 500,
    fontSize: '14px',
    color: 'white ',
    opacity: 0.5,
  },
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 10,
  },
  '& img': {
    cursor: 'pointer',
  },



})
const webStyle = {
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "red !important",
    }
  },
  dialogContent: {
    textAlign: 'center',
    fontSize: '15px !important'
  },
  customLabel: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: '32px',
    fontStyle: 'normal',
    color: 'white',
  },
  inputStyle: {
    padding: "15px",
    border: "1px solid white",
  },
  customInputLabel: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    marginBottom: '12px',
    fontSize: '14px',
    color: 'white ',
  },
  inputRoot: {
    fontFamily: 'Poppins',
    outline: 'none',
    borderRadius: '10px',
    height: '56px',
    borderColor: (props: any) => (props.error ? '#f44336 !important' : 'white !important'),
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& input::placeholder': {
      opacity: 0.5,
      color: 'white ',
      fontSize: '14px',
      fontWeight: 500,

    }, '& .MuiInputBase-input': {
      borderColor: 'white !important'
    },
  } as any,
  cekbox: {
    '& .MuiCheckbox-root': {
      color: 'aqua',
    },
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  forget: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
  },
  buttons: {
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 700,
    height: "50px",
    Color: '#3C3C50',
    margin: '0px',
    borderRadius: '10px',
    backgroundColor: "#c7f9f6",
  },
  logoandimg1: {
    paddingLeft: '49px',
    paddingRight: '49px',
    paddingTop: '20px',
    top: '162px',
    alignItems: 'center',
    justifyContent: 'end',
    display: 'flex',
  },
  fieldError1: {
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    color: "#EE5353",
  },
  fieldErrorMsg: {
    position: 'absolute',
    top: 10,
    left: '45%',
    transform: 'translateX(-50%)',
    backgroundColor: "rgb(229, 176, 172)",
    color: '#ff0000b8',
    padding: '10px',
    zIndex: 1000,
    paddingLeft: "40px",
    paddingRight: "130px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  redAlert: {
    fontSize: 12,
    paddingVertical: 4,
    color: 'red'
  },
};
// Customizable Area End
