import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { back_icon, image_hourglass, logo } from "./assets";

interface Props {
    id: string;
    navigation: any;
    instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class InProgressBank extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
    const { instance } = this.props;
        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
               {!this.props.instance.state.isArabic ?
                <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box hgt-res-500">
                    <img
                    className="back-icon"
                    data-test-id='bank-icon-click'
                    style={{
                        position: 'absolute',
                        left: '5',
                        top: '0',
                        padding: '40px',
                        fontSize: '44px',
                        cursor: 'pointer',
                    }}
                    onClick={() =>{ instance.setState({ componentPage: "AddBankAccounts" }) }}
                    src={back_icon}
                    alt="back-icon"
                />
                    <div className="logo-right">
                            <a href="#"><img src={logo} alt="logo" /></a>
                        </div>
                      <div className="progress-img-content">
                        <img src={image_hourglass} /><br />
                        <h4>We are importing your data</h4>
                        <p>Please don't close your app. We are<br />
                        currently importing your data to show in<br />
                        the app, it should not take long</p>

                      </div>
                    </div>
                </div>
                :
                <div className="bank-integraion-wrapper">
                <div className="bank-integration-box hgt-res-500">
                <div className="logo-right">
                        <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                  <div className="progress-img-content">
                    <img src={image_hourglass} /><br />
                    <h4>نحن نقوم باستيراد البيانات الخاصة بك</h4>
                    <p>من فضلك لا تغلق التطبيق الخاص بك. يرتديe<br />
                    جارٍ حاليًا استيراد بياناتك لتظهر فيها<br />
                    التطبيق، فإنه لا ينبغي أن يستغرق وقتا طويلا</p>

                  </div>
                </div>
            </div>
                }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
