import { useMeeting } from "@videosdk.live/react-sdk";

export const useEndCall = () => {
  const { leave } = useMeeting();

  const endCall = () => {
    leave();
  };

  return endCall;
};