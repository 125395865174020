import React from "react";
// Customizable Area Start
import "./style.css"
import ApiIntegrationBankAccountsHome from "./ApiIntegrationBankAccountsHome.web";
import Bankintegrationsuccessful from "./bankintegrationsuccessful.web";
import BankintegrationInProgress from "./bankintegrationInProgress.web";
import BankLists from "./BankLists.web";
import SelectedBank from "./selectedBank.web";
// Customizable Area End

import ApiintegrationbankaccountsController, {
  Props,
} from "./ApiintegrationbankaccountsController";

export default class ApiIntegrationBankAccountsmain extends ApiintegrationbankaccountsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    let component: React.ReactElement = <></>;
    if (this.state.componentPage == 'home') {
      component = <ApiIntegrationBankAccountsHome navigation={this.props.navigation} id={""} instance={{...this}} />;
    }
    if (this.state.componentPage == 'banks') {
      component = <BankLists navigation={this.props.navigation} id={""} instance={this} />;
    }
    if (this.state.componentPage == 'selectedBank') {
      component = <SelectedBank navigation={this.props.navigation} id={""} instance={this} />;
    }
    if (this.state.componentPage == 'inProgress') {
      component = <BankintegrationInProgress navigation={this.props.navigation} id={""} instance={this} />;
    }
    if (this.state.componentPage == 'success') {
      component = <Bankintegrationsuccessful navigation={this.props.navigation} id={""} instance={this} />;
    }
    



    return (
      // Customizable Area Start
      <>{component}</>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
