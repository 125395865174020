export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_Blob = require("../assets/images/image_Blob.png");
export const image_Shape = require("../assets/images/image_Shape.png");
export const image_hourglass = require("../assets/images/image_hourglass.png");
export const logo = require("../assets/images/logo.png");
export const back_icon = require("../assets/images/back-icon.png");
export const country = require("../assets/images/country.png");
export const view_image = require("../assets/images/view-image.png");
export const noBanks = require("../assets/images/no-banks.png");







