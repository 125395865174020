import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imagenav_BackIcon, image_Vuesax,modelSuccess ,imagenav_Bitmap} from "./assets";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import * as Yup from "yup";
import { OutlinedInput } from "@material-ui/core";
import { getStorageData } from 'framework/src/Utilities';
import { FormikValues } from "formik";
import { processInputValue } from "../../../components/src/commonFn";

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    image_Vuesax: string;
    imagenav_BackIcon: string;
    hader_dropdown: any;
    currency_name: any;
    selectedFileError: string;
    companyNameError: string;
    newPriceError: string,
    priceError: string,
    companyName: string,
    numberOfShares: string,
    selectCurrency: { value: string, label: string },
    selectCashCurrency: any,
    currencyList: any,
    editSharesData: any,
    openSharesModel:boolean,
    modelSuccess:string, 
    amountInput: any;
    currencyInput: any;
    currencyData: any;
    newPrice: any;
    newCurrency: any;
    focusedInput: string;
    isCountryMenuOpen: boolean
    newPriceMenuOpen: boolean
    newCurrencyInput: any
    logoImg1AddShares:string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddSharesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: any;
    getCategoriesApiCallId: any;
    deleteCategoriesApiCallId: any;
    deleteSubCategoriesApiCallId: any;
    validationApiCallId: string = "";
    addCategoryApiCallId: any;
    addSubCategoryApiCallId: any;
    postUserSelectDataCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logoImg1AddShares: "",
            focusedInput: "companyName",
            newCurrency: React.createRef(),
            newCurrencyInput: React.createRef(),
            amountInput: React.createRef(),
            currencyInput: React.createRef(),
            currencyData: React.createRef(),
            newPrice: React.createRef(),
            companyNameError: '',
            currency_name: [],
            selectedFileError: '',
            newPriceError: '',
            imagenav_BackIcon: "",
            priceError: '',
            hader_dropdown: [],
            companyName: '',
            image_Vuesax: image_Vuesax,
            selectCurrency: { value: '', label: '' },
            selectCashCurrency: '',
            numberOfShares: '',
            token: "",
            currencyList: [],
            editSharesData: this.props.location?.state?.shareEditData?.attributes,
            openSharesModel:false,
            modelSuccess:modelSuccess, 
            isCountryMenuOpen: false,
            newPriceMenuOpen: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    dialogSharePaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    dialogShareModel = () => {
        this.setState({ openSharesModel: !this.state.openSharesModel })
        this.props.navigation.navigate('CategoriessubcategoriesWeb')
    }

    async componentWillMount() {
        const language = await getStorageData('language');
        const logoImg1AddShares = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoImg1AddShares: logoImg1AddShares });
        const limagenav_BackIcon = language === 'Arabic' ? imagenav_Bitmap : imagenav_BackIcon;
        this.setState({ imagenav_BackIcon: limagenav_BackIcon  });
    }

    getCurrencyList = () => {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = configJSON.Loginsubmittoken;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.exampleAPiMethodtoken,
        });
        this.postUserPDFDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }
    async componentDidMount() {
        this.getCurrencyList();
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserPDFDataCallId !== null &&
            this.postUserPDFDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.list) {
                const userSelectedCurrncy = responseJson.list?.find((value: any) => {
                    return value?.id == responseJson?.selection
                })
                const currency = responseJson.list?.map((value: any) => (
                    { value: value.currency_type, label: value.currency_type }
                ));
                this.setState({
                    currency_name: currency, currencyList: responseJson,
                    selectCurrency: { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
                    selectCashCurrency: { value: userSelectedCurrncy.currency_type, label: userSelectedCurrncy.currency_type }
                });
                this.setState({
                    hader_dropdown: responseJson?.list?.map((value: any) => (
                        { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
                    ))
                });
            }
            if (responseJson?.meta?.message.includes("successfully")) {
                this.setState({ openSharesModel: !this.state.openSharesModel })
            } else if (responseJson?.meta?.message.includes("updated")) {
                this.props.navigation.navigate('ShareAssetsOverview')
            } else {
                this.errorMessage(responseJson)
            }
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId !== null &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (cashResponseJson.meta.currency) {
                this.getCurrencyList()
            }
        }
        // Customizable Area End
    }

    munuSharesSelect = () => {
        return (
            <FormControl>
                <Select options={this.state.hader_dropdown}
                    value={this.state?.selectCurrency}
                    onChange={(val: any) => { this.setSharesCurrency(val) }}
                    classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
            </FormControl>
        )
    }

    setCurrencyPutapi = (currencyId: string) => {
        const sharesDataCash = localStorage.getItem("token")
        const sharesHeader = { "token": sharesDataCash };
        let userDetailurl = configJSON.SetCurrencyPutapi;
        const formData = new FormData();
        formData.append("currency_id", currencyId);
        const sharesRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserSelectDataCallId = sharesRequestMessage.messageId;
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(sharesHeader)
        );
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );
        runEngine.sendMessage(sharesRequestMessage.id, sharesRequestMessage);
    }

    setSharesCurrency = (value: { value: string, label: string }) => {
        const sharesCurrencyId = this.state.currencyList?.list?.find((newValue: any) => {
            return newValue?.currency_type == value?.value
        }).id
        this.setCurrencyPutapi(sharesCurrencyId)
    }

    errorMessage = (SharesResponseJson: any) => {
        if (SharesResponseJson.errors.name) {
            this.setState({ companyNameError: SharesResponseJson.errors.name[0] })
        } else if (SharesResponseJson.errors.new_price) {
            this.setState({ newPriceError: SharesResponseJson.errors.new_price[0] })
        } else if (SharesResponseJson.errors.price) {
            this.setState({ priceError: SharesResponseJson.errors.price[0] })
        }
    }

    portableAsset = () => {
        !this.state.editSharesData ? this.props.navigation.navigate("CategoriessubcategoriesWeb") : this.props.navigation.navigate("ShareAssetsOverview")
    } 

    submitSharesApi = (sharesRequestMessage: any) => {
        if (this.state.editSharesData) {
            sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PUT');
        } else {
            sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
        }
    }

    HandleSubmit = async (values: FormikValues) => {
        const data = localStorage.getItem("token")
        const header = { "token": data };
        let userDetailurl = !this.state.editSharesData ? configJSON.ShareApi : `account_block/shares/${this.props.location?.state?.shareEditData?.id}`;
        const formData = new FormData();
        formData.append("data[attributes][name]", values.companyName);
        formData.append("data[attributes][number_of_shares]", values.numberOfShares);
        formData.append("data[attributes][price]", values.price);
        formData.append("data[attributes][price_currency]", values.priceCurrency.value ? values.priceCurrency.value : this.state.selectCashCurrency.value);
        formData.append("data[attributes][new_price]", values.newPrice ? values.newPrice : values.price);
        formData.append("data[attributes][new_price_currency]", values.priceCurrency.value ? values.priceCurrency.value : this.state.selectCashCurrency.value);
        const sharesRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserPDFDataCallId = sharesRequestMessage.messageId;
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        sharesRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        this.submitSharesApi(sharesRequestMessage)
        runEngine.sendMessage(sharesRequestMessage.id, sharesRequestMessage);
    }
    settingRouteShares = () => this.props.navigation.navigate('Settings2')

    getStringAddShares = (key: string) => {
        let languageAddShares = localStorage.getItem("language") || "English"
        return configJSON.languageListLogin[languageAddShares][key]
      }

    codeSchemaShares = Yup.object().shape({
        numberOfShares: Yup.string()
            .required(this.getStringAddShares('numberOfSharesError')),
        price: Yup.string()
            .required(this.getStringAddShares('priceError')),
        companyName: Yup.string()
            .required(this.getStringAddShares('companyNameError')),
    });
    selectMenu = (name: string, setFieldValue: any, customStyles: any, values: any, fieldValues: any, userField: any, MenuOpen: boolean) => {
        return (
            <Select
                styles={customStyles}
                name={name}
                defaultValue={fieldValues}
                value={fieldValues.value ? fieldValues : this.state.selectCashCurrency}
                onChange={(val: any) => { setFieldValue(name, val) }}
                options={this.state.currency_name}
                classNamePrefix="an-simple-select"
                className="selectMenu"
                data-test-id="selectMenu"
            />
        )
    }
    finalErrorMessage(error: any) {
        if (error) {
            return error
        } else {
            return;
        }
    }

    handleEnterKey = (e: any) => {
        if (e.key === "Enter") {
            if (this.state.focusedInput === "companyName") {
                this.state.currencyInput.current?.focus(); this.setState({ focusedInput: "numberOfShares", newPriceMenuOpen: false });
            } else if (this.state.focusedInput === "numberOfShares") {
                this.state.currencyData.current?.focus(); this.setState({ focusedInput: "price" });
            } else if (this.state.focusedInput === "price") {
                this.setState({ focusedInput: "priceCurrency", isCountryMenuOpen: true });
            } else if (this.state.focusedInput === "priceCurrency") {
                this.state.newPrice.current?.focus(); this.setState({ focusedInput: "newPrice", isCountryMenuOpen: false });
            } else if (this.state.focusedInput === "newPriceCurrency") { this.setState({ focusedInput: "newPriceCurrency", newPriceMenuOpen: false }); }
        }
    };
    onKeyDown = (keyEvent: any) => { if ((keyEvent.charCode || keyEvent.keyCode) === 13) { keyEvent.preventDefault() } }

    handleNameInputAddSharesClick = (name: string) => { this.setState({ focusedInput: name }) };

    OutlinedInputAddShares = (webStyle: any, placeholderName: string, name: string, values: { companyName: '', numberOfShares: '', price: '' }, handleChange: any, amountInputShares: {current: null},type: string,setFieldValue?: any ) => {

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;

            if ((name === "numberOfShares" || name==="price" || name==="newPrice") && setFieldValue) { 
                const result = processInputValue(inputValue, name === "numberOfShares" ? 7 : 11);

                if (result) {
                    const { actualValue, formattedValue } = result;
                    setFieldValue(name, actualValue);
                    handleChange(name)(formattedValue);
                  }
            } else {
            handleChange(name)(inputValue);
            }
        };

        return (
                <OutlinedInput style={webStyle.inputRoot} data-test-id="txtInputAssetname" placeholder={this.getStringAddShares(placeholderName)} fullWidth={true} name={name} value={values} type={type} onChange={handleInputChange} inputRef={amountInputShares} onKeyDown={this.handleEnterKey} onClick={() => this.handleNameInputAddSharesClick(name)} />
        )
    }
    // Customizable Area End
}
