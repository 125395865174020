import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imagenav_BackIcon, plus1, plus, image_Vuesax, modelSuccess } from "./assets";
import { Message } from "../../../framework/src/Message";
import storage from "framework/src/StorageProvider";
import toast from "react-hot-toast";

export const configJSON = require("./config");

interface GetlistId {
    focus: {
        data: Data[]
    }
}

export interface Fileinfolder {
    url: string;
    blob_id: number;
    filename: string;
}

interface Data {
    id: string,
    type: string
    attributes: {
        name: string,
        image: string
    }
}

interface File {
    name: string;
    type: string;
    size: number;
    blob_id: number,
}

interface GetSubcategoryList {
    service_id: string,
    sub_categories: SubcategoryListData[]
}

interface SubcategoryListData {
    id: string,
    description: string
    focus_plan_id: string,
    created_at: string,
    updated_at: string
    arabic_description: string
}

export interface ServiceName {
    label: string,
    value: string
}

type ServiceId = string | null | undefined

export interface ServiceNodes {
    index: number,
    serviceId: ServiceId,
    categoryId: ServiceId,
    service: ServiceName,
    subCategory: ServiceName
    uploadDocumentImage: []
}

export interface DynamicValues {
    selectService: {
        label: string,
        value: string
    } | string | undefined
    selectSubCategory: string,
    serviceNodes: ServiceNodes[]
}

interface ApiCallData {
    contentType?: string,
    method: string,
    endPoint: string,
    body?: Object,
    type?: string
}

interface File {
    name: string;
    type: string;
    size: number;
    // Add more properties if needed
}

interface ServiceDataInput {
    serviceInput: string,
    subCategoryInput: string,
    images: string,
}

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    image_Vuesax: string;
    logoImg1AddCash: string,
    imagenav_BackIcon: string;
    selectService: { value: string, label: string },
    selectSubCategory: { value: string, label: string },
    modelSuccess: string,
    serviceList: {},
    subtotalList: [],
    filesWeb: File[];
    serviceListOptions: ServiceName[]
    addingServicesData: ServiceDataInput[]
    serviceInputRef: React.RefObject<HTMLInputElement>;
    subCategoryInputRef: React.RefObject<HTMLInputElement>;
    plus: string;
    plus1: string;
    subCategoryListOptions: any;
    selectedSeviceId: string | number | undefined;
    uploadIndex: string | number | undefined;
    openSuccessModal: boolean;
    plannerInfo: {
        name:string,
        email:string,
        image:string
      },
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddCashController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    serviceListId: string = '';
    subCategoryListId: string = '';
    uploadDocumentId: string = '';
    getUserProfileAPICallId:string = "";

    maxFileSize = 5e6;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logoImg1AddCash: "",
            imagenav_BackIcon: imagenav_BackIcon,
            image_Vuesax: image_Vuesax,
            plus: plus,
            plus1: plus1,
            selectService: { value: '', label: '' },
            selectSubCategory: { value: '', label: '' },
            token: "",
            modelSuccess: modelSuccess,
            serviceList: {},
            subtotalList: [],
            filesWeb: [],
            serviceListOptions: [],
            addingServicesData: [{
                serviceInput: '',
                subCategoryInput: '',
                images: '',
            }],
            serviceInputRef: React.createRef(),
            subCategoryInputRef: React.createRef(),
            subCategoryListOptions: [],
            selectedSeviceId: "",
            uploadIndex: 0,
            openSuccessModal: false,
            plannerInfo:{
                name:"",
                image:"",
                email:""
              },

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start


    dialogCashPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    handleTabService = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "ClientService");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg)
    }

    handleTabAssets = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientAssets");
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }
    handleUserProfileResponse(apiCallId:string,responseJson: {
        data: {
          attributes:{
            name: string,
            email: string,
            image: string
          }
        }
      }){
        if(apiCallId === this.getUserProfileAPICallId){
          this.setState({
            plannerInfo: responseJson.data.attributes
          })
        }
      }
    getUserProfile = async () => {
        this.getUserProfileAPICallId = await this.apiCall({
          method: "GET" + '',
          endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
          contentType:"application/json"
        });
      }

    handleTabDetails = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientDetails");
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }

    handleTabVault = () => {
    }

    handelSetService = async (id: string) => {
        this.getSubcategoryLisG(id)
    }

    getServiceList = async () => {
        const account_id = await storage.get('clientId');
        let userDetailurl = `/bx_block_proposal_generation/service_proposals/get_user_service?account_id=${account_id}`;
        this.serviceListId = await this.apiCall({
            method: 'GET',
            endPoint: userDetailurl,
            contentType: "application/json",
        });

    }

    getSubcategoryLisG = async (id: string) => {
        let userDetailurl = `/bx_block_proposal_generation/service_proposals/get_subcategory?focus_id=${id}`;
        this.subCategoryListId = await this.apiCall({
            method: 'GET',
            endPoint: userDetailurl,
            contentType: "application/json",
        });
    }

    async componentDidMount() {
        this.getServiceList();
    this.getUserProfile()

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
                );
                let responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                    );
                    if (responseJson && responseJson.message === "record not found") {
                        toast.error('error');
                    }
                    if (responseJson && !responseJson.errors) {
                        this.successCallBack(apiRequestCallId, responseJson);
                    }
                    if (responseJson && responseJson.errors) {
                        toast.error('error');
                    }
                    this.handleUserProfileResponse(apiRequestCallId,responseJson);
                }
        // Customizable Area End
    }

    successCallBack = (apiRequestCallId: string, responseJson: GetlistId & GetSubcategoryList & string) => {
        if (responseJson && apiRequestCallId === this.serviceListId) {
            if (responseJson.focus && responseJson.focus.data.length) {
                const service = responseJson.focus.data.map((value: Data) => (
                    { value: value.id, label: value.attributes.name }
                ));
                this.setState({
                    serviceListOptions: service,
                    serviceList: responseJson.focus.data
                })
            }

        } else if (apiRequestCallId === this.subCategoryListId) {
            const _subCategory = responseJson.sub_categories?.map((value: SubcategoryListData) => (
                { value: value.id, label: value.description }
            ));

            this.setState({ subCategoryListOptions: [...this.state.subCategoryListOptions, _subCategory] })
            this.setState({ subCategoryListOptions: [...this.state.subCategoryListOptions, _subCategory] })
        }

    }

    dialogSuccessModal = () => {
        this.setState({ openSuccessModal: !this.state.openSuccessModal })
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Homepage");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg)
    }

    goToBackPage() {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Clients");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg)
    }

    handleSubmit = async (values: DynamicValues) => {
        if (values.serviceNodes.length) {
            const uploadPromises = [];
            for (let i = 0; i < values.serviceNodes.length; i++) {
                uploadPromises.push(this.uploadDocument(values.serviceNodes[i].serviceId, values.serviceNodes[i].categoryId, values.serviceNodes[i].uploadDocumentImage));
            }

            Promise.all(uploadPromises)
                .then((res) => {
                    this.setState({
                        openSuccessModal: true
                    })
                })
                .catch(() => {
                    toast.error('unable to upload')
                })

        }
    }

    cashCustomStyles = {
        control: (defaultStyles: any) => ({
            ...defaultStyles,
            textAlign: "left",
            fontSize: '16px',
            color: 'rgb(60, 60, 81)',
            backgroundColor: "#2a2a38 !important",
            fontWeight: '500',
            padding: "8px",
            fontFamily: 'Poppins',
            borderRadius: '8px',
        }),
    };

    uploadDocument = async (serviceId: string | undefined | null, categoryId: string | undefined | null, uploadDocument: any) => {
        const clientId = await storage.get('clientId');
        let formData = new FormData();

        formData.append("account_id", clientId);
        formData.append("service_id", serviceId as string);
        formData.append("sub_category_id", categoryId as string);
        for (const file of uploadDocument) {
            formData.append("documents[]", file);
        }

        let userDetailurl = 'bx_block_proposal_generation/service_proposals/add_document';
        this.uploadDocumentId = await this.apiCall({
            method: 'POST',
            endPoint: userDetailurl,
            body: formData,
            type: "formData"
        });

    }

    apiCall = async (data: ApiCallData) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
            token: await storage.get("auhtToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    // Customizable Area End
}

