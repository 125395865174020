import React from "react";

// Customizable Area Start
import { imagenav_BackIcon, image_BackIcon } from "./assets";
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import {
    Box,
    Button,
    Typography,
    Grid
} from "@material-ui/core";

// Customizable Area End

import CatalogueControllerWeb, {
    Props,
} from "./viewInvestmentController.web";

export default class VisualAnalytics extends CatalogueControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    imagenav_Back = () => {
        return this.state.logoImgViewInvestments === "rtl" ? webStyle.imagenav_BackIconImg_New : webStyle.imagenav_BackIconImg
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <BackgroundBoxSatyle dir={this.state.logoImgViewInvestments}>
                    <CnvestmentBox>
                        <Box style={webStyle.displayBox}><Link to="/MainCatalogueWeb"><img src={this.state.logoImgViewInvestments === "rtl" ? image_BackIcon : imagenav_BackIcon} style={this.imagenav_Back()} /></Link><Box style={webStyle.investmentsfont}>{this.getStringViewInvestments("Investments")}</Box></Box>
                        <Grid item xs={12}  >
                            <Box style={webStyle.returnData}>
                                <Box >
                                    <Grid container spacing={0}><Box>{this.state.investmentsData.images !== null && this.state.investmentsData.images ? <div><img style={webStyle.investmentsDataImg} src={this.state.investmentsData.images[0].url} alt="Image" /></div> : null}</Box>
                                        <Box style={webStyle.headingName}><ImgMobile>{this.state.investmentsData.heading}</ImgMobile>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6} sm={6} md={6}> <Box ><a style={webStyle.returnName}>{this.getStringViewInvestments("Return")}&nbsp;&nbsp;&nbsp;</a><a style={webStyle.returnNameColor} dir="ltr">{this.state.investmentsData.return_value}</a></Box></Grid>
                                                <Grid item xs={6} sm={6} md={6}> <Box ><span style={webStyle.dollers}>Min $ 100</span></Box></Grid>
                                                <Grid item xs={12} sm={12} md={12}> <Box><a style={webStyle.returnName}>{this.getStringViewInvestments("MktAvg")}&nbsp;&nbsp;</a><a style={webStyle.returnmktavgColor} dir="ltr">{this.state.investmentsData.market_average}</a></Box></Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <div style={webStyle.one_line_summary} dangerouslySetInnerHTML={{ __html: this.state.investmentsData?.one_line_summary }} />
                                    <Grid container>
                                        <Grid item xs={10} sm={10} md={10} style={webStyle.linkButton}><InvestButton href={this.state.investmentsData.link} >{this.getStringViewInvestments("Invest")}</InvestButton></Grid>
                                    </Grid>
                                </Box>
                                <Box>{this.state.investmentsData.summary_images !== null && this.state.investmentsData.summary_images ? <div><img style={webStyle.showImage} src={this.state.investmentsData.summary_images[0].url} alt="Image" /></div> : null}</Box>
                            </Box>
                        </Grid>
                    </CnvestmentBox>
                    <TabsBox>
                        <Grid >
                            {this.state.tabView.map((value: string, index: number) => {
                                return <span data-test-id={`${value}`} key={index} onClick={() => this.clickButton(index + 1)} style={this.state.activeTab === index + 1 ? webStyle.ActiveTab : webStyle.deactiveTab}>{value}</span>
                            })}
                        </Grid>
                        <Grid style={webStyle.discBox}>
                            <Box>
                                <Typography style={webStyle.paregraf}>{this.state.tabsDetails[this.state.activeTab - 1]}</Typography>
                            </Box>
                        </Grid>
                    </TabsBox>
                </BackgroundBoxSatyle>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    linkButton: { display: 'flex', justifyContent: 'end', paddingTop: "10px" },
    one_line_summary: { color: '#FFF', fontFamily: 'Poppins', fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', maxWidth: '641px' },
    paregraf: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    discBox: { marginTop: '15px' },
    deactiveTab: { color: '#FFF', fontSize: '20px', fontWeight: 600, opacity: 0.4, cursor: "pointer", margin: '0px 22px' },
    ActiveTab: { margin: '0px 22px', color: '#FFF', fontSize: '20px', fontWeight: 600, borderBottom: '4px solid rgb(44, 226, 213)', cursor: "pointer", },
    showImage: {
        maxWidth: '640px',
        maxHeight: '329px',
        borderRadius: '12px 12px 0px 0px',
    },
    dollers: {
        color: "#928F98",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    },
    headingName: {
        margin: '0px 20px',
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "50px",
        fontWeight: 600,
        paddingTop: "3px",
    },
    investmentsfont: {
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "28px",
        fontWeight: 700,
    },
    imagenav_BackIconImg: {
        width: '24px', height: '13px', cursor: "pointer", marginRight: '20px'
    },
    imagenav_BackIconImg_New: {
        width: '24px', height: '13px', cursor: "pointer", marginLeft: '20px'
    },
    displayBox: { display: "flex", alignItems: "center" },
    investmentsDataImg: { width: "130px", height: "130px", borderRadius: "9px" },
    returnData: { display: 'flex', justifyContent: 'space-between', margin: '50px 0px', paddingBottom: '52px', marginBottom: '27px' },
    returnName: { color: "#928F98", fontFamily: "Poppins", fontSize: "20px", fontWeight: 400 },
    returnNameColor: { color: "#FFF", fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 },
    returnmktavgColor: { color: "#2DE2D5", fontFamily: "Poppins", fontSize: "20px", fontWeight: 500 },
}
const BackgroundBoxSatyle = styled(Box)({ width: '100%', background: '#2a2a39', height: '100vh', overflowY: 'scroll' })
const InvestButton = styled(Button)({
    borderRadius: '8px', background: '#C9F9F6', textTransform: 'capitalize', fontFamily: 'Poppins', color: '#3C3C51', fontSize: '16px', fontWeight: 700, padding: '5px 25px',
    '&:hover': {
        borderRadius: '8px', background: '#C9F9F6', textTransform: 'capitalize', fontFamily: 'Poppins', color: '#3C3C51', fontSize: '16px', fontWeight: 700, padding: '5px 25px',
    },
})
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important", boxShadow: "0px 15px 10px -15px #111",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})

const TabsBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important", },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})

const ImgMobile = styled(Box)({
    color: "#FFF", fontFamily: "Poppins", fontSize: "50px", fontWeight: 600, textTransform: "capitalize", marginBottom: '25px',
    '@media (min-width: 320px)': { fontSize: "20px", },
    '@media (min-width: 1200px)': { fontSize: "50px", fontWeight: 600 },
})
// Customizable Area End

