import React from 'react'

function CustomTooltip({ active, payload, label }: any) {
    if (active && payload && payload.length) {
        return (
          <div style={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3C3C50',
            color: 'white',
            padding: '10px',
            border: '1px solid #2CE2D5',
            borderRadius: '5px',
            position: 'relative',
            transform: 'translateX(20px)'
          }}>
            <p style={{ color: '#FFFFFF', fontWeight: 700, fontFamily: "'Poppins', sans-serif" }}>{`${payload[0].name} : ${payload[0].value}%`}</p>
          </div>
        );
      }
  
      return null;
}

export default CustomTooltip