import React, { MouseEvent } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import {
  Box,
} from "@material-ui/core";
import {
  logo,
  downArrow
} from "../../blocks/dashboard/src/assets"
import { Link } from "react-router-dom";
import { styled } from '@material-ui/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

export default class AppHeader extends React.Component {
  state = {
    loginPopup: false,
    featuresPopup: false,
    signupPopup: false,
    iconClick: false,
    right: false,
    openPopup: false,
    featuresPopupNew: false,
    openPopupNew: false
  };

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick as any);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick as any);
  }
  iconClick = () => {
    this.setState({ iconClick: !this.state.iconClick });
  }
  openPopup = (e: any) => {
    e.stopPropagation()
    this.setState({ openPopup: !this.state.openPopup })
  }
  openPopupNew = (e: any) => {
    e.stopPropagation()
    this.setState({ openPopupNew: !this.state.openPopupNew })
  }

  handleOutsideClick = (event: MouseEvent) => {
    const headerClosefeatere = document.getElementById('headerClosefeatere');
    if (headerClosefeatere && !headerClosefeatere.contains(event.target as Node)) {
      this.setState({ featuresPopup: false });
      this.setState({loginPopup: false, signupPopup:false})
    }
  };

  LoginHandle = () => {
    this.setState({ loginPopup: !this.state.loginPopup })
  }
  SignupHandle = () => {
    this.setState({ signupPopup: !this.state.signupPopup })
  }

  featuresPopupHandle = (e: any) => {
    e.stopPropagation()
    this.setState({ featuresPopup: !this.state.featuresPopup })
  }
  featuresPopupHandleNew = (e: any) => {
    e.stopPropagation()
    this.setState({ featuresPopupNew: !this.state.featuresPopupNew })
  }

  featuresPopupClose = (e: any) => {
    e.stopPropagation()
    this.setState({ featuresPopup: false })
  }
  toggleDrawer = (anchor: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    console.log("anchor", anchor, open);

    if (event && 'type' in event && event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift') {
      return;
    }

    this.setState({ [anchor]: open });
    this.state.right && this.setState({ right: false });
  };
  list = (anchor: string) => (
    <div
      className="list"
      role="presentation"
      onClick={this.toggleDrawer(anchor, false)}
      onKeyDown={this.toggleDrawer(anchor, false)}
    >
      <Box style={{ textAlign: 'center' }}>
        <a href="/"><span style={{ color: 'black', fontSize: '20px', padding: "20px 0px", lineHeight: '40px' }}>Home</span></a><br />
        <a><span style={{ color: 'black', fontSize: '20px', padding: "20px 0px", lineHeight: '40px' }} onClick={(e) => this.featuresPopupHandleNew(e)}>Features</span></a><br />
        {this.state.featuresPopupNew &&
          <Box style={{ backgroundColor: "#090c14", padding: "15px 0px" }}>
            <a href='NetworthFeature'><Box style={{ color: '#fff' }}>Net Worth</Box></a>
            <a href='BudgetWeb'><Box style={{ color: '#fff' }}>Budget</Box></a>
            <a href='InvestmentMarketplaceWeb'><Box style={{ color: '#fff' }}>Investment Marketplace</Box></a>
            <a href='EstatePlanningWeb'><Box style={{ color: '#fff' }}>Estate Planning</Box></a>
          </Box>
        }
        <a href="/#testimonail_3"><span style={{ color: 'black', fontSize: '20px', padding: "20px 0px", lineHeight: '40px' }}>Pricing</span></a><br />
        <a href="/"><span style={{ color: 'black', fontSize: '20px', padding: "20px 0px", lineHeight: '40px' }}>About us</span></a><br />
        <a href="/#faqText"><span style={{ color: 'black', fontSize: '20px', padding: "20px 0px", lineHeight: '40px' }}>FAQ</span></a>
        <Box onClick={(e: any) => { this.openPopup(e) }} style={{ color: 'black', fontSize: '20px', padding: "10px 0px", lineHeight: '25px' }}>Login</Box>
        {this.state.openPopup ?
          <Box style={{ backgroundColor: "#090c14", padding: "15px 0px" }}>
            <Link to="/EmailAccountLogin"><span style={{ color: '#fff' }}>Customer</span></Link><br />
            <Link to="/Loginplanner"><span style={{ color: '#fff' }}>Wealth planner</span></Link>
          </Box>
          : null}
        <Box style={{ color: 'black', fontSize: '20px', padding: "10px 0px", lineHeight: '25px' }} onClick={(e: any) => { this.openPopupNew(e) }}>Sign up</Box>
        {this.state.openPopupNew &&
          <Box style={{ backgroundColor: "#090c14", padding: "15px 0px" }}>
            <Link to="/EmailAccountRegistrationBlockweb"><span style={{ color: '#fff' }}>Customer</span></Link><br />
            <Link to="/SignupAccount"><span style={{ color: '#fff' }}>Wealth planner</span></Link>
          </Box>}
      </Box>
    </div>
  );
  render() {
    const path = window.location.pathname
    return (
      <>
        <Box style={webStyle.container} id="headerClosefeatere" onClick={(e) => { this.featuresPopupClose(e) }}>
          <Box style={{ width: '100%' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '1946', margin: 'auto', }}>
              <Box  >
                <LogoStyle className="logo">
                  <a href='/'><img style={{borderRadius: '0px'}} src={logo} alt="" /></a>
                </LogoStyle>
              </Box>
              <CnvestmentBox  >
                <Box>
                  <div className="menu-items" style={{ display: 'flex' }}>
                    <StyledMenuItem ><a href='/' style={path === "/" && !this.state.featuresPopup ? webStyle.colorHome2 : webStyle.colorHome}>Home</a></StyledMenuItem>
                    <StyledMenuItem>
                      <span style={this.state.featuresPopup || path === "/BudgetWeb" || path === "/NetworthFeature" || path === "/InvestmentMarketplaceWeb" || path === "/EstatePlanningWeb" ? webStyle.colorHome2 : webStyle.positionColor}
                        onClick={(e) => this.featuresPopupHandle(e)}>Features</span>
                      {this.state.featuresPopup &&
                        <FeaturesModel>
                          <a href='NetworthFeature'><DropdownTextNew>Net Worth</DropdownTextNew></a>
                          <a href='BudgetWeb'><DropdownTextNew>Budget</DropdownTextNew></a>
                          <a href='InvestmentMarketplaceWeb'><DropdownTextNew>Investment Marketplace</DropdownTextNew></a>
                          <a href='EstatePlanningWeb'><DropdownTextNew>Estate Planning</DropdownTextNew></a>
                        </FeaturesModel>
                      }
                    </StyledMenuItem>
                    <StyledMenuItem ><a href='/#testimonail_3' style={webStyle.colorHome}>Pricing</a></StyledMenuItem>
                    <StyledMenuItem >About us</StyledMenuItem>
                    <StyledMenuItem ><a href='/#faqText' style={webStyle.colorHome}>FAQ</a></StyledMenuItem>
                    <Box style={{ display: "flex", paddingRight: "50px" }}>
                      <span style={{ position: 'relative', display: 'flex' }} onClick={() => this.LoginHandle()}><span style={webStyle.loginBtn}>Login {this.state.loginPopup && <> <img style={{ position: 'absolute', top: '15px' }} src={downArrow} alt="" />&nbsp;</>}</span>
                        {this.state.loginPopup &&
                          <span style={{ padding: '0px 7px', top: "42px", background: '#3C3C51', border: '1px solid #2DE2D5', position: 'absolute', display: 'flex', zIndex: 41, flexDirection: 'column', justifyContent: 'center', textAlign: 'center', height: '89px', borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px" }}>
                            <Link to="/EmailAccountLogin"><span style={webStyle.dropdownText}>Customer</span></Link>
                            <Link to="/Loginplanner"><span style={webStyle.dropdownText}>Wealth planner</span></Link>
                          </span>}
                      </span>
                      <span style={{ position: 'relative', display: 'flex' }} onClick={() => this.SignupHandle()}><span style={webStyle.signupBtn}>Sign up {this.state.signupPopup && <> <img style={{ position: 'absolute', top: '15px' }} src={downArrow} alt="" />&nbsp;</>}</span>
                        {this.state.signupPopup &&
                          <span style={{ padding: '0px 18px', top: "42px", background: '#3C3C51', border: '1px solid #2DE2D5', position: 'absolute', display: 'flex', zIndex: 41, flexDirection: 'column', justifyContent: 'center', textAlign: 'center', height: '89px', borderBottomLeftRadius: '6px', borderBottomRightRadius: "6px" }}>
                            <Link to="/EmailAccountRegistrationBlockweb"><span style={webStyle.dropdownText}>Customer</span></Link>
                            <Link to="/SignupAccount"><span style={webStyle.dropdownText}>Wealth planner</span></Link>
                          </span>}
                      </span>
                    </Box>
                  </div>
                </Box>
              </CnvestmentBox>
              <CnvestmentBoxIcon>
                <Box onClick={this.iconClick}>
                  <MenuRounded><MenuRoundedIcon style={{ fontSize: "40px" }} onClick={this.toggleDrawer('right', true)} /></MenuRounded>
                  <SwipeableDrawer
                    style={{ zIndex: 10, position: 'relative', width: '58%' }}
                    anchor={'right'}
                    open={this.state['right']}
                    onClose={this.toggleDrawer('right', true)}
                    onOpen={this.toggleDrawer('right', true)}
                    PaperProps={{ style: { width: '60%', } }}
                  >
                    {this.list('right')}
                  </SwipeableDrawer>
                </Box>
              </CnvestmentBoxIcon>
            </Box>
          </Box>
        </Box >
      </>)
  }
}

const webStyle = {
  colorHome: {
    color: '#fff'
  },
  colorHome2: {
    color: '#4fcec2',
    borderBottom: '1px solid #91929c'
  } as React.CSSProperties,
  positionColor: {
    position: 'relative',
    color: '#fff'
  } as React.CSSProperties,
  FeaturesModel: { padding: '0px 3px', background: '#182531', position: 'absolute', left: "-15px", display: 'flex', zIndex: 1, flexDirection: 'column', justifyContent: 'center', textAlign: 'center' },
  container: {
    // maxWidth: '1946',
    // margin: 'auto',
    height: "80px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #1B1B2B 0%, #1B1C2C 51%, #1B2332 69.3%, #1C2F3C 82.38%, #1E404A 92.84%, #20565D 101.99%, #227175 111.15%, #259290 117.68%, #29B6AF 124.22%, #2CDFD2 130.76%, #2DE2D5 130.76%)'
  },
  dropdownText: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  dropdownTextNew: {
    color: '#fff',
    fontSize: '11px',
    fontWeight: 500,
    cursor: 'pointer',
    lineHeight: "initial",
    paddingBottom: "10px"
  },
  loginBtn: {
    zIndex: 1,
    backgroundColor: '#2DE2D5',
    color: 'white',
    padding: '5px 20px',
    marginRight: 10,
    borderRadius: '5px',
    border: '1px solid #2DE2D5',
    cursor: 'pointer',
    fontSize: "21px"
  },
  loginBtnNav: {
    alignItems: 'center',
    zIndex: 1,
    color: 'style={{color:"rgb(79, 206, 194)"}} ',
    padding: '5px 20px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: "21px"
  },
  signupBtn: {
    border: '1px solid #2DE2D5',
    color: '#2DE2D5',
    padding: '5px 20px',
    marginRight: 10,
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: "21px"
  },
  borderColor: { color: 'red', borderBottem: "1px solid red" },
  borderColorNew: { color: 'white', borderBottem: "1px solid #1b1d2c" }

}
const FeaturesModel = styled(Box)({
  top: "70px", width: "92px", padding: '0px 3px', background: '#1b1d2c', position: 'absolute', display: 'flex', zIndex: 30, flexDirection: 'column', justifyContent: 'center', textAlign: 'center'
})
const DropdownTextNew = styled(Box)({ color: '#fff', fontSize: '12px', fontWeight: 500, cursor: 'pointer', lineHeight: "initial", paddingBottom: "15px", '&:hover': { color: "#2DE2D5" } })
const StyledMenuItem = styled(Box)({
  color: '#fff', paddingRight: "85px", cursor: 'pointer', fontSize: "21px", fontWeight: 400, display: 'flex', alignItems: 'center',
  "@media (max-width: 601px)": { fontSize: '18px', paddingRight: "30px !important" },
  "@media (max-width: 1610px)": { fontSize: '18px', paddingRight: "30px !important" },
  "@media (max-width: 1372px)": { fontSize: '18px', paddingRight: "30px !important" },
  "@media (max-width: 1280px)": { fontSize: '18px', paddingRight: "30px !important" }
})
const CnvestmentBox = styled(Box)({
  "@media (max-width: 950px)": {
    display: 'none'
  },
});
const CnvestmentBoxIcon = styled(Box)({
  "@media (min-width: 950px)": {
    display: 'none'
  },
});
const LogoStyle = styled(Box)({
  height: 30, paddingLeft: 60,
  "@media (max-width: 950px)": {
    paddingLeft: 30,
  },
});
const MenuRounded = styled(Box)({
  marginRight: '60px', color: '#fff',
  "@media (max-width: 950px)": {
    marginRight: 30,
  },
});