import React from "react";
// Customizable Area Start
import {
    Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../assets/Categoriess.css";
import { NavLink } from "react-router-dom";
import CategoriessubcategoriesControllerWeb, {
    Props
} from "./CategoriessubcategoriesController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class CategoriessubcategoriesWeb extends CategoriessubcategoriesControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <Box className='mainWrapperscas' dir={this.state.logoImg1Login}>
                <Box className="sideBorder">
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Box>
                            <Box className="headerMain">
                                <Box className="headerLeft">
                                    <img
                                        className="headerIcon"
                                        src={this.state.arro}
                                        onClick={this.dashboardNavigation}
                                        alt="arrow"
                                    />
                                    <span className="headerTitle">{this.state.addAssets}</span>
                                </Box>
                                <Box className="headerRight">
                                    {this.addCategorySelect()}
                                    <NavLink to="/Account-Detail">
                                        <img
                                            className="settingsIcon"
                                            src={this.state.setting}
                                            alt="settings"
                                        />
                                    </NavLink>
                                </Box>
                            </Box>
                            <Box className="content">
                                {this.state.assetType.map((value, ind) => (
                                    <Box key={ind} className="menuitem" onClick={() => this.validation(value)}>
                                        <Box className="setBox">
                                            <img className="menuImage" src={value.img} alt="asset" />
                                            <Box className="menuText">
                                                <h3 className="assetTitle">{value.title}</h3>
                                                <p className="assetInfo">{value.Content}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </ThemeProvider>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}