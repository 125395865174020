import React from "react";


// Customizable Area Start

import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area End

interface Props {
  id: string;
  navigation: any;
  instance: any;
}
interface S { }
interface SS { }

export default class Questions extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { instance } = this.props
    return (
      // Customizable Area Start
      <div className="mainquestions-wrapper">
        {!instance.state.isArabic ?
          <div className="questions-wrapper">
            <div className="questions-box" style={{ height: "unset", paddingLeft: "35px", paddingRight: "35px" }}>

              <div className="questions-block questions-block-int">
                <div className="names">
                  <h4>How should we call you ?</h4>
                  <input type="text" placeholder="Enter your first name" data-test-id="firstNameField" onChange={(e) => instance.handleChange('first_name', e.target.value)} />&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className="select-block">
                  <h4>Where are you located ?</h4>
                  <div className="scrollbar-wrapper open-dropdwn" id="englishDropdown" data-test-id="countryDropDown" onClick={() => { instance.setState({ countryDropdownVisible: !instance.state.countryDropdownVisible }) }}>
                    <p className="englishQuestions">
                      <input
                        data-test-id="firstNameField3"
                        style={{ borderBottom: 'none' }}
                        type="text"
                        className="searchCountry english-screen"
                        value={instance.state.searchLocation}
                        onChange={(e) => {instance.inputLocation(e.target.value, instance.state.allCountriesLocation) }}
                        placeholder="Select Location" />
                    </p>
                    {instance.state.countryDropdownVisible ?
                      <div className="scrollbar-block" data-test-id="english-scrollbar-block">
                        <span className="down-arrow" id="down-arrow-english">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" /></svg>
                        </span>

                        <ul onScroll={() => { instance.callCountry() }} data-test-id="scrollSection" className="select-country">
                          {instance.state.allCountries && instance.state.allCountries.map((value: any) => {
                            return <li data-test-id={'countries-data-' + value.id} key={value.id} aria-label="flag" onClick={() => { instance.setState({ selectedCountry: value.attributes.name, selectedCountryCode: value.id, searchLocation: value.attributes.name }) }} >
                              {value.attributes.emoji_flag}&nbsp;
                              {value.attributes.name}
                            </li>;
                          })}

                        </ul>
                      </div> : <div className="space-maintain" data-test-id="spaceMaintainEnglish">
                        <span className="down-arrow">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                        </span>
                      </div>}
                  </div>
                </div>
                <button className="verifyQuestions" onClick={instance.onSubmit} data-test-id="continueBtn">Continue</button>
              </div>
              <ul className="list-dot">
                <li className="current"></li>
                <li ></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
          :
          <div className="questions-wrapper arbic-questions-wrapper">
            <div className="questions-box" style={{ height: "unset", paddingLeft: "35px", paddingRight: "35px" }}>

              <div className="questions-block questions-block-int">
                <div className="names">
                  <h4>كيف يجب أن نتصل بك؟</h4>
                  <input type="text" placeholder="أدخل اسمك الأول" data-test-id="firstNameField" onChange={(e) => instance.handleChange('first_name', e.target.value)} />&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className="select-block">
                  <h4>أين أنت مقيم؟</h4>
                  <div className="scrollbar-wrapper open-dropdwn" data-test-id="countryDropDown" onClick={() => { instance.setState({ countryDropdownVisible: !instance.state.countryDropdownVisible }) }}>
                    <p>
                      <input
                        data-test-id="firstNameField3"
                        type="text"
                        style={{ borderBottom: 'none' }}
                        className="searchCountry"
                        value={instance.state.selectedCountry ? instance.state.selectedCountry : instance.state.searchLocation}
                        onChange={(e) => { instance.inputLocation(e.target.value, instance.state.allCountriesLocation) }}
                        placeholder="اختر موقعا" />
                    </p>
                    {instance.state.countryDropdownVisible ?
                      <div className="scrollbar-block">
                        <span className="down-arrow">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" /></svg>
                        </span>

                        <ul onScroll={() => { instance.callCountry() }} className="select-country">
                          {instance.state.allCountries && instance.state.allCountries.map((value: any) => {
                            return <li data-test-id={'countries-data-' + value.id} key={value.id} aria-label="flag" onClick={() => { instance.setState({ selectedCountry: value.attributes.name, selectedCountryCode: value.id }) }} >
                              {value.attributes.emoji_flag}&nbsp;
                              {value.id}
                            </li>;
                          })}

                        </ul>
                      </div> : <div className="space-maintain">
                        <span className="down-arrow">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                        </span>
                      </div>}
                  </div>
                </div>
                <button className="verifyQuestions" onClick={instance.onSubmit} data-test-id="continueBtn">المتابعة</button>
              </div>
              <ul className="list-dot">
                <li className="current"></li>
                <li ></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        }

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
