import React from "react";
// Customizable Area Start
import "../../apiintegrationbankaccounts/src/style.css"
import { image_Shape } from "../../apiintegrationbankaccounts/src/assets";
import DashboardController, { Props } from "./DashboardController";


// Customizable Area End


export default class StockSuccess extends DashboardController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        setTimeout(() => {
            this.redirectToSomeRoute('Dashboard')
        }, 2000)
        return (
            // Customizable Area Start
            <div style={{ width: '100vw' }} className="mainBank-wrapper">
                <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box d-flex align-center">
                        <div className="successful-img-content">
                            <img src={image_Shape} />
                            <p>Stocks added successfully !
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
