import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}
export function getHeaders(obj: Record<string, string>): Headers {
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  for (let key in obj) {
    headers[key] = obj[key];
  }
  return headers;
}
export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  if (Object.keys(httpBody).length > 0 || httpBody?.append) {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody?.append ? httpBody : JSON.stringify(httpBody)
    );
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );
  return requestMessage;
}