import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export default class FormApprovalWorkFlowWebAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigattionToChatScreen)
    ]);
  }

  convert = (from: Message): Message => {
    const chatNavigate = new Message(getName(MessageEnum.NavigationMessage));

    chatNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Chat"
    );

    chatNavigate.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(
      getName(MessageEnum.NavigationMessageSendData),
      from.getData(getName(MessageEnum.NavigationMessageSendData))
    );

    chatNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return chatNavigate;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}