import React from "react";
// Customizable Area Start
import { NavLink } from "react-router-dom";
import ForgotPasswordController, { Props } from "././ForgotPasswordController";
import "./custom.css";
import OtpInput from 'react-otp-input';
import { logo, backIcon } from "./assets";
// Customizable Area End

export default class ForgotPasswordOTP extends ForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        
        // Customizable Area End
    }

    render() {
        return (
            <div className="main-wrapper">
                {!this.state.isArabic ? 
                <div className="verification-wrapper">
                    <div className="verify-box">
                        <div className="logo-right">
                            <a href="#"><img src={logo} alt="logo" /></a>
                        </div>
                        <div className="verification-block">
                            <NavLink to="/ForgotPassword"><h4><img src={backIcon} alt="back-icon" /> OTP Verification</h4></NavLink>
                            <p>Please enter the 4 digit verification code sent to you at <a href="#">{localStorage.getItem('email_for_otp')}</a></p>
                            <div className="verification-code">
                                <OtpInput
                                    value={this.state.otp}
                                    data-test-id="OtpField"
                                    inputType="number"
                                    onChange={(value) => this.setState({ otp: value, errorMsg: "" })}
                                    shouldAutoFocus={true}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    numInputs={4}
                                    inputStyle={{
                                        opacity: 0.3,
                                        borderRadius: "12px",
                                        color: "#fff",
                                        height: "62px",
                                        background: "none",
                                        marginRight: "30px",
                                        width: "62px",
                                        border: "1px solid #2DE2D5",
                                        fontSize: "16px",

                                    }}
                                />
                                {this.state.errorMsg && <div className="errorMsg">{this.state.errorMsg}</div>
                                }
                            </div> 
                            <span className="recevie-otp">Didn't receive the OTP?&nbsp;<button  data-test-id="resendOtpBtn" onClick={this.resendOtp} className="resend">Resend again</button></span>
                            <button className="verify" onClick={this.onOTPSubmit} data-test-id="verifyBtn">Verify</button>
                        </div>
                    </div>
                </div>
                :
                <div className="verification-wrapper arbic-otp-verification">
                <div className="verify-box">
                    <div className="logo-right">
                        <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="verification-block">
                        <NavLink to="/ForgotPassword"><h4>التحقق من مكتب المدعي العام<img src={backIcon} alt="back-icon" /> </h4></NavLink>
                        <p>الرجاء إدخال رمز التحقق المكون من 4 أرقام المرسل إليك على <a href="#">{localStorage.getItem('email_for_otp')}</a></p>
                        <div className="verification-code" data-test-id="ForgotPasswordOTPArabicScreen">
                            <OtpInput
                                value={this.state.otp}
                                inputType="number"
                                data-test-id="OtpFieldArabicForgotPassword"
                                shouldAutoFocus={true}
                                onChange={(value) => this.setState({ otp: value, errorMsg: "" })}
                                numInputs={4}
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                    width: "62px",
                                    height: "62px",
                                    background: "none",
                                    marginRight: "30px",
                                    border: "1px solid #2DE2D5",
                                    opacity: 0.3,
                                    borderRadius: "12px",
                                    color: "#fff",
                                    fontSize: "16px",

                                }}
                            />
                            {this.state.errorMsg && <div className="errorMsg" data-test-id="errorArabicMsgInForgotPassword">{this.state.errorMsg}</div>
                            }
                        </div> 
                        <span className="recevie-otp"><button  data-test-id="resendOtpBtn" onClick={this.resendOtp} className="resend">إعادة مرة أخرى</button> لم تتلق كلمة المرور لمرة واحدة؟ &nbsp;</span>
                        <button className="verify" onClick={this.onOTPSubmit} data-test-id="verifyBtn">يؤكد</button>
                    </div>
                </div>
            </div>}
            </div>
        );
    }
}

