export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backIcon = require("../assets/backicon.png");
export const settingicon = require("../assets/settingicon.png");
export const subarrowicon = require("../assets/subarrowicon.png");
export const logoNew = require("../assets/logo-new.png");
export const logo = require("../assets/logo.png");
export const image_hourglass = require("../assets/image_hourglass.png");
export const image_Shape = require("../assets/image_Shape.png");
