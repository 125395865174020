export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logoimg = require("../assets/mynelogo.png");
export const ContinueGoogle = require("../assets/ContinueGoogle.png");
export const ContinueApple = require("../assets/ContinueApple.png");
export const VisibilityOutlinedIcons = require("../assets/VisibilityOutlinedIcon.png");
export const Googleimg = require("../assets/imgGoogle.png");
export const Appleimg = require("../assets/apple.png");
export const calendar = require("../assets/image_Calendar.png");
export const warning = require("../assets/warning.png");
export const SuccessCard = require("../assets/cr.png");
