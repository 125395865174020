import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DefaultProfileImage, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";
import React from 'react';
import { getStorageData } from 'framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue?: string;
  txtSavedValue?: string;
  enableField?: boolean;
  // Customizable Area Start
  isEditProfile?: boolean;
  isShowConfirmPopup?: boolean;
  resetCurrentPassword?: string;
  resetNewPassword?: string;
  resetConfirmPassword?: string;
  resetCurrentErrorMessage?: string;
  resetNewErrorMessage?: string;
  resetConfirmPasswordErrorMessage?: string;
  resetSuccessMessage?: string;
  profileData?: any;
  token?: any;
  isOldResetPasswordVisible?: boolean;
  isNewesetPasswordVisible?: boolean;
  isConfirmNewResetPasswordVisible?: boolean;
  currencyList?: any;
  languageList?: any;
  isProfileDelete?: boolean;
  bankWalletData?: any;
  newProfileImage?: any;
  currentRefreshedCard?: any;
  logoImgsetting?:string;
  deleteBankWalletType?: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyneSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileCallId: any;
  getCurrencyCallId: any;
  getLanguageCallId: any;
  getBankWalletCallId: any;
  getRefreshedBankWalletCallId: any;
  BankWalletDeleteCallId: any;
  ProfileDeleteCallId: any;
  ProfileResetPasswordCallId: any;
  ProfileUpdateCallId: any;
  CurrencyAddToCallId: any;
  LanguageAddToCallId: any;
  fileInputRef: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isEditProfile: false,
      isShowConfirmPopup: false,
      resetCurrentPassword: '',
      resetNewPassword: '',
      resetConfirmPassword: '',
      resetCurrentErrorMessage: '',
      resetNewErrorMessage: '',
      resetConfirmPasswordErrorMessage: '',
      resetSuccessMessage: '',
      profileData: {},
      token: localStorage.getItem('token'),
      isOldResetPasswordVisible: false,
      isNewesetPasswordVisible: false,
      isConfirmNewResetPasswordVisible: false,
      currencyList: [],
      languageList: [],
      isProfileDelete: false,
      bankWalletData: [],
      newProfileImage: null,
      currentRefreshedCard: null,
      logoImgsetting:"",
      deleteBankWalletType: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  componentDidMount(): any {
    this.getStorageDataLanguage()
    const windowPath = window.location.pathname
    switch (windowPath) {
      case '/Currency':
        this.loadCurrencyData();
        break;
      case '/Language':
        this.loadLanguageData();
        break;
      case '/Account-Detail':
        this.loadProfileData();
        break;
      case '/BankWallet':
        this.loadBankWalletData('',false, null);
        break;
      default:
        this.loadProfileData();
        break;
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    this.handleProfileReceive(dataMessage, message)

    if (dataMessage === this.getCurrencyCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          currencyList: responseJson
        })
      })
    }

    if (dataMessage === this.CurrencyAddToCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.loadCurrencyData();
      })
    }

    if (dataMessage === this.LanguageAddToCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.loadLanguageData();
      })
    }

    if (dataMessage === this.getLanguageCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          languageList: responseJson
        })
      })
      const data = responseJson.list?.find((item: any) =>  item.id === this.state.languageList.selection)
      localStorage.setItem("language", data.language)
    }

    this.handleBankReceive(dataMessage, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
 async getStorageDataLanguage (){
    const language = await getStorageData('language');
    const logoImg1Setting = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImgsetting: logoImg1Setting });
  }

  getStringGoalmanagement = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListForecasting[languageLogin][key]
  }

  handleProfileReceive(dataMessage: any, message: any) {
    if (dataMessage === this.getProfileCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({ profileData: responseJson })
      })
    }

    if (dataMessage === this.ProfileDeleteCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({ profileData: {} })
        toast.error(this.getStringGoalmanagement("Profiledeleted"))
        localStorage.clear();
        window.location.href = '/';
      })
    }

    if (dataMessage === this.ProfileUpdateCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({ profileData: responseJson })
        if (responseJson && responseJson.meta) {
          toast.success(this.getStringGoalmanagement("toastsuccessfully"))
          this.setState({
            newProfileImage: null
          })
        }
        this.loadProfileData();
      })
    }

    if (dataMessage === this.ProfileResetPasswordCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.checkErrorsSuccessMessage(responseJson)
      })
    }
  }

  getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let day = today.getDate().toString().padStart(2, '0'); 
  
    return `${year}-${month}-${day}`;
  }
  handleBankReceive(dataMessage: any, message: any) {
    if (dataMessage === this.getBankWalletCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        const updatedData = responseJson.data.map((obj: any) => {
          return { ...obj, isSynced: true };
        });
        this.setState({ bankWalletData: updatedData })
      })
    }

    this.handleRefreshBankReceive(dataMessage, message)

    if (dataMessage === this.BankWalletDeleteCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({ bankWalletData: [] })
        this.loadBankWalletData('',false, null);
        this.handleToast(responseJson)
      });

    }
  }

  handleToast(responseJson: any) {
    if (responseJson) {
      if(this.state.deleteBankWalletType?.toLowerCase() == 'bank'){
        toast.error(this.getStringGoalmanagement("successfullyDeleted"))
      }else{
        toast.error(this.getStringGoalmanagement("walletSuccessfullyDeleted"))
      }
    } else {
      toast.error(this.getStringGoalmanagement("Something"))
    }
  }

  handleRefreshBankReceive(dataMessage: any, message: any) {
    if (dataMessage === this.getRefreshedBankWalletCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.updateBankDataonResync(responseJson)
      })
    }
  }

  updateBankDataonResync(responseJson: any) {
    if (responseJson?.message) {
      this.loadBankWalletData('',false, null);

      toast.success(this.getStringGoalmanagement("successfully"));
      
      this.setState({currentRefreshedCard: null})
    } else {
      const index = this.state.bankWalletData.findIndex((obj: any) => obj.id === this.state.currentRefreshedCard);
      if (index !== -1) {
        const updatedData = [...this.state.bankWalletData];
        updatedData[index].isSynced = false;
        toast.error(this.getStringGoalmanagement("Something"));
        this.setState({ bankWalletData: updatedData, currentRefreshedCard: null })
      }
    }
  }

  async receiveHandler(responseJson: any, receiveCallback: any) {
    if (responseJson) {
      receiveCallback && receiveCallback();
    }
  }

  checkErrorsSuccessMessage(responseJson: any) {
    if (responseJson && (responseJson.message || (responseJson.errors && responseJson.errors.length > 0))) {
      let msg = (responseJson.message && responseJson.message.toLowerCase()) || (responseJson.errors && responseJson.errors.length > 0 && typeof responseJson.errors[0] === 'string' && responseJson.errors[0].toLowerCase()) || (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].message && responseJson.errors[0].message.toLowerCase());
      this.onMessageHandling(msg);
    } else if (responseJson && responseJson.meta) {
      this.setState({
        resetSuccessMessage: this.getStringGoalmanagement("Passwordsuccessfully"),
        resetCurrentErrorMessage: '',
        resetNewErrorMessage: '',
        resetConfirmPasswordErrorMessage: '',
        resetCurrentPassword: '',
        resetNewPassword: '',
        resetConfirmPassword: '',
      })
    } else {
      this.setState({
        resetSuccessMessage: '',
        resetCurrentErrorMessage: '',
        resetNewErrorMessage: '',
        resetConfirmPasswordErrorMessage: '',
        resetCurrentPassword: '',
        resetNewPassword: '',
        resetConfirmPassword: '',
      })
    }
  }

  onMessageHandling(msg: string) {
    if (msg) {
      if ((msg.includes('old') || msg.includes('current'))) {
        this.setState({
          resetCurrentErrorMessage: this.setResetValidation(msg.includes('old') || msg.includes('current'), msg),
          resetNewErrorMessage: '',
          resetConfirmPasswordErrorMessage: ''
        })
      } else if ((msg.includes('new') || msg.includes('different'))) {
        this.setState({
          resetCurrentErrorMessage: '',
          resetNewErrorMessage: this.setResetValidation(msg.includes(`new`) || msg.includes(`different`), msg),
          resetConfirmPasswordErrorMessage: ''
        })
      } else if (msg.includes('confirm')) {
        this.setState({
          resetCurrentErrorMessage: '',
          resetNewErrorMessage: '',
          resetConfirmPasswordErrorMessage: this.setResetValidation(msg.includes(`doesn't`) || msg.includes('confirm'), msg),
        })
      } else if (msg.includes(`doesn't`)) {
        this.setState({
          resetCurrentErrorMessage: '',
          resetNewErrorMessage: this.setResetValidation(msg.includes(`doesn't`), msg),
          resetConfirmPasswordErrorMessage: this.setResetValidation(msg.includes(`doesn't`), msg),
        })
      } else {
        this.setState({
          resetSuccessMessage: '',
          resetCurrentErrorMessage: msg.charAt(0).toUpperCase() + msg.slice(1),
          resetNewErrorMessage: msg.charAt(0).toUpperCase() + msg.slice(1),
          resetConfirmPasswordErrorMessage: msg.charAt(0).toUpperCase() + msg.slice(1),
        })
      }
    }

    this.setState({
      resetSuccessMessage: '',
    })
  }

  loadProfileValues(key: any) {
    if (key === 'photo') return this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes[key] || null
    else if (key === 'email') return this.state.profileData && this.state.profileData.meta && this.state.profileData.meta[key]
    else return this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes[key]
  }

  onProfileDataChange(key: any, e: any) {
      const userData = {
        ...this.state.profileData,
        data: {
          ...this.state.profileData.data,
          attributes: {
            ...this.state.profileData.data.attributes,
            [key]: e.target.value
          }
        }
      }
      this.setState({
        profileData: userData
      });
    
  }
 
  handleGotoForgotPassword = () =>{
    localStorage.setItem("userEmail", this.state.profileData?.meta?.email);
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
      message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
      );
      this.send(message) 
  }
  

  handleImageClick = () => {
    this.fileInputRef.current.click();
  };

  async loadProfileData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/show'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadBankWalletData(type:string,isRefreshed = false, bankCardId:number|null = null) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: this.state.token
    };

    if (isRefreshed && bankCardId) {
      this.getRefreshedBankWalletCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/profiles/refresh_api_wallet?id=${bankCardId}&type=${type}`
      );
    } else {
      this.getBankWalletCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/settings/bank_and_wallet"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadCurrencyData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrencyCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/currency_list'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadLanguageData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLanguageCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/language_list'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onDiscardChanges = () => {
    this.setState({ isEditProfile: false, isShowConfirmPopup: false, newProfileImage: null })
    this.loadProfileData();
  }

  onSaveChanges = () => {    
    const {address,full_name,mobile,dob} = this.state.profileData.data.attributes;
    if(!address?.trim().length || !full_name?.trim().length || !mobile?.trim().length || !dob?.trim().length){
      toast.dismiss()
      toast.error('All the fields are required')      
      this.setState({ isShowConfirmPopup: false })
    }else if(!(mobile?.length < 18 && mobile?.length > 5)){
      toast.dismiss()
      toast.error('Mobile number should be greater than 5 and less than 18')      
      this.setState({ isShowConfirmPopup: false })
    }else{      
      this.onUpdateProfile();
      this.setState({ isEditProfile: false, isShowConfirmPopup: false })
    }
  }

  onEditClick = () => {
    if (!this.state.isEditProfile) this.setState({ isEditProfile: true })
    else {
      this.setState({ isShowConfirmPopup: true })
    }
  }

  handleInputChange = (val: any, key: keyof S) => {
    this.setState({
      [key]: val
    });
  }

  onDeleteProfile() {
    if(!this.state.isProfileDelete){
      return 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProfileDeleteCallId = requestMessage.messageId;

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/delete_account?permission=${this.state.isProfileDelete}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onResetPasswordFormSubmit = async () => {
    const isValid = this.onValidateResetForm()
    if (isValid) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileResetPasswordCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        token: this.state.token
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const httpBody = {
        data: {
          old_password: this.state.resetCurrentPassword,
          new_password: this.state.resetNewPassword,
          confirm_password: this.state.resetConfirmPassword,
        }
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/passwords"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  onAddCurrencyToAccount = async (currencyId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CurrencyAddToCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append('currency_id', currencyId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onAddLanguageToAccount = async (languageId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LanguageAddToCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append('language_id', languageId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_language_to_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onUpdateProfile = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProfileUpdateCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append('address', this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes.address.replace(/\s+/g, ' ').trim() );
    formData.append('mobile', this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes.mobile.replace(/\s+/g, ' ').trim());
    formData.append('dob', this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes.dob);
    formData.append('full_name', this.state.profileData && this.state.profileData.data && this.state.profileData.data.attributes && this.state.profileData.data.attributes.full_name.replace(/\s+/g, ' ').trim());
    if (this.state.newProfileImage) {
      formData.append('photo', this.state.newProfileImage);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/update"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onValidateResetForm = () => {
    return this.state.resetConfirmPassword && this.state.resetNewPassword && this.state.resetCurrentPassword;
  }

  setResetValidation = (conditionSatisfy: any, trueVal: any) => {
    if (conditionSatisfy && trueVal) return trueVal.charAt(0).toUpperCase() + trueVal.slice(1);
    return '';
  }

  onDeleteBankWallet(bankCardId: number,type:string) {
    this.setState({
      deleteBankWalletType: type
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.BankWalletDeleteCallId = requestMessage.messageId;

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/${bankCardId}/remove_bank_wallet?type=${type}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
