import React from "react";
import { getFirstCharacterFromEachWord } from "./commonFn";
import { Link } from 'react-router-dom';
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export default class UserProfile extends React.Component<{
  username?: string;
  email?: string;
  profilePicture?: string;
}> {
  render() {
    const {
      username = "Joel samnem",
      email = "joelsamnem@workmail.com ",
      profilePicture = "",
    } = this.props;

    return (
      <>
        <HeaderWrapper
          className="header-wrapper"
          style={{ justifyContent: "end" }}
        >
          <div className="page-info">
            <ul className="drop-menu" style={{
              gridGap:"20px"
            }}>
              <li>
                <Link to={"PlannerProfile"}>
                <div style={styles.container} >
                  <div>
                    {profilePicture ? (
                      <img
                        src={profilePicture}
                        alt="profile"
                        style={styles.image}
                      />
                    ) : (
                      <div style={styles.noImg}>
                        {getFirstCharacterFromEachWord(username)}
                      </div>
                    )}
                  </div>
                  <UserProfileNameBox style={styles.textContainer}>
                    <span style={styles.name}>{username}</span>
                    <span style={styles.email}>{email}</span>
                  </UserProfileNameBox>
                </div>
                </Link>
           
              </li>
            </ul>
          </div>
        </HeaderWrapper>
      </>
    );
  }
}

const UserProfileNameBox = styled(Box)({
  '@media (max-width: 500px)': {
    display: 'none !important',
  },
})


const HeaderWrapper = styled(Box)({
  marginRight:'4rem',
  '@media (max-width: 500px)': {
    marginRight:'0'
  },
})



const styles = {
  container: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    lineHeight:1.43
  }as React.CSSProperties,
  image: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  }as React.CSSProperties,
  name: {
    fontSize: "14px",
    color: "white",
  },
  email: {
    fontSize: "12px",
    color: "#ffffff78",
  },
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    backgroundColor: "2CE2D5",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
  }as React.CSSProperties,
};
