import React,{Fragment} from "react"
// Customizable Area Start
import {
    Box, Button
} from "@material-ui/core";
import { Link, NavLink } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import { imageVuesax } from "./assets"
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ReactApexChart from 'react-apexcharts';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import "./style.css"
import { formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";
// Customizable Area End
import ForecastingandbudgetingTrendsController, { Props } from './ForecastingandbudgetingTrendsController.web';

export default class ForecastingandbudgetingTrends extends ForecastingandbudgetingTrendsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    styleCss = () => {
        return this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const income = this.state.chartData.series[0]
        const expance = this.state.chartData.series[1]
        const saving = this.state.chartData.series[2]
        const getCommonMonth = this.state.selectedMonth.slice(0, 3)

        const incomeData = income.data.filter((value: number, index: number) => {
            return this.state.xaxisName[index] == getCommonMonth && value
        })
        const expanceData = expance.data.filter((value: number, index: number) => {
            return this.state.xaxisName[index] == getCommonMonth && value
        })
        const savingData = saving.data.filter((value: number, index: number) => {
            return this.state.xaxisName[index] == getCommonMonth && value
        })
        const highlightMonth = this.state.selectedMonth.substring(0, 3);
        const highlightIndex = this.state.xaxisName.indexOf(highlightMonth) + 1;
        const dynamicStyle=
        `.apexcharts-xaxis-texts-g text:nth-child(${highlightIndex}){
            fill:white !important;
            font-weight:bold;
        }`

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={inlineStyle.trendsBox}>
                    <Box style={{ overflowY: 'scroll', height: '100%' }} dir={this.state.logoImg1AddCash}>
                        <Box className="custom-box">
                            <Box style={inlineStyle.trendsoverflowY} >
                                <Box style={inlineStyle.trendsfont}>
                                    <a style={inlineStyle.trendsAfont}>
                                        {this.getStringForcastingan("Trends")}</a>

                                </Box>
                                <Box className="addCashrightHeaderDropSetting">
                                    <Box style={inlineStyle.trendsicon}>
                                        <FormControl>
                                            <Select options={this.state.hader_dropdown}
                                                value={this.state.selectCurrency}
                                                onChange={(val: any) => { this.setCurrency(val) }}
                                                classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
                                        </FormControl>
                                        <Box style={{ paddingLeft: '20px' }} data-test-id="addCashDataSetting">
                                            <Link to="/Account-Detail">
                                                <img src={imageVuesax} style={inlineStyle.trendsSettingimg} />
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <div dir="ltr" style={this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew}>
                                    <CustomBoxNext>
                                        <Button style={inlineStyle.calenderButton} data-test-id="updateMonthminusbtn" onClick={() => this.updateMonth('minus')}>
                                            <KeyboardArrowLeftIcon style={this.state.selectedTransactionMonth === this.state.transactionMonth?.length - 1 ? inlineStyle.trendsMinus : inlineStyle.trendsIconMinus} />
                                        </Button>
                                        <Box style={{ color: 'white', fontWeight: 'bold' }}>{this.state.selectedMonth} {this.state.selectedYear}</Box>
                                        <Button style={inlineStyle.calenderButton} data-test-id="updateMonthplusBtn" onClick={() => this.updateMonth('plus')}>
                                            <KeyboardArrowRightIcon style={this.state.selectedTransactionMonth === 0 ? inlineStyle.trendsMinus : inlineStyle.trendsIconMinus} />
                                        </Button>
                                    </CustomBoxNext>
                                </div>
                                <IncomeBox >
                                    <Box style={{ pointerEvents: 'none' }}>
                                        <div dir="ltr" style={this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew}>
                                            <div style={inlineStyle.trendsInfo}>
                                                <div style={inlineStyle.trendsIncomeBox}></div>
                                                <div>{this.getStringForcastingan("Income")}</div>
                                                <div style={inlineStyle.trendsExpenseBox}></div>
                                                <div>{this.getStringForcastingan("Expense")}</div>
                                                <div style={inlineStyle.trendsSavingsBox}></div>
                                                <div>{this.getStringForcastingan("Savings")}</div>
                                            </div>
                                        </div>
                                        <style>{dynamicStyle}</style>

                                    </Box>
                                </IncomeBox>
                            </Box>
                            <Box>
                            <ReactApexChart
                                    options={{
                                        chart: {
                                            id: 'basic-bar',
                                            toolbar: {
                                                show: false,
                                            },
                                        },
                                        legend: {
                                            position: 'top',
                                            show: false,
                                        },
                                        tooltip: {
                                            enabled: true,
                                            theme: 'dark',
                                            style: {
                                                fontSize: '12px',
                                                fontFamily: undefined,
                                            },
                                            x: {
                                                show: true,
                                                format: 'dd MMM',
                                            },
                                            y: {
                                                formatter: (val) => {
                                                    return this.state.selectCurrency2.label + val;
                                                },
                                            },
                                            marker: {
                                                show: true,
                                            },
                                        },
                                        plotOptions: {
                                            bar: {
                                                columnWidth: window.innerWidth > 769 ? '20%' : '30%',
                                                borderRadius: 5,
                                                horizontal: false,
                                            },
                                        },
                                        stroke: {
                                            width: [4, 0, 0],
                                        },
                                        grid: {
                                            show: false,
                                        },
                                        yaxis: {
                                            show: false,
                                        },
                                        xaxis: {
                                            categories: this.state.xaxisName,
                                            axisBorder: {
                                                show: false,
                                            },
                                            labels: {
                                                style: {
                                                    colors: 'white',
                                                    fontWeight: 700,
                                                },
                                            },
                                        },
                                        markers: {
                                            size: [3, 3],
                                            colors: '#2f90fa',
                                            strokeColors: '#2f90fa',
                                            strokeWidth: 1,
                                            strokeOpacity: 0.5,
                                            strokeDashArray: 0,
                                            fillOpacity: 5,
                                            discrete: [],
                                            shape: "circle",
                                            offsetX: 0,
                                            offsetY: 0,
                                            onClick: undefined,
                                            onDblClick: undefined,
                                            showNullDataPoints: true,
                                            hover: {
                                                size: undefined,
                                                sizeOffset: 5,
                                            },
                                        },
                                    }}
                                    series={this.state.chartData.series}
                                    height="300"
                                    style={inlineStyle.chartBox1}
                                />
                            </Box>
                            <Box>
                                <Box style={inlineStyle.IncomeColor} className="textcenter">
                                    <Box >
                                        <Box>{this.getStringForcastingan("Income")}</Box>
                                        <Box style={inlineStyle.fontColorIncome}> {this.state.selectCurrency2.label}{this.convertToThousand(incomeData[0])}<svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
                                            <path d="M6.27364 0.384324C6.07415 0.13958 5.79133 0 5.49474 0C5.19815 0 4.91532 0.13958 4.71583 0.384324L0.273955 5.8478C-0.117274 6.32754 -0.0856998 7.07018 0.344479 7.50651C0.774657 7.94284 1.44054 7.90762 1.83177 7.42785L4.44216 4.19958V15.8261C4.44216 16.4744 4.91341 17 5.49474 17C6.07606 17 6.54731 16.4744 6.54731 15.8261V4.22308L9.16823 7.42785C9.55946 7.90762 10.2253 7.94284 10.6555 7.50651C11.0857 7.07018 11.1173 6.32754 10.726 5.8478L6.27364 0.384324Z" fill="#24D4B0" />
                                        </svg>
                                        </Box>
                                    </Box>
                                    <Box >
                                        <Box>{this.getStringForcastingan("Expense")}</Box>
                                        <Box style={inlineStyle.fontColorExpense}>{this.state.selectCurrency2.label}{this.convertToThousand(expanceData[0])} <svg xmlns="http://www.w3.org/2000/svg" width="13" height="19" viewBox="0 0 13 19" fill="none">
                                            <path d="M7.23632 18.5705C7.0066 18.844 6.68092 19 6.33939 19C5.99786 19 5.67219 18.844 5.44247 18.5705L0.315464 12.4643C-0.135043 11.9281 -0.0986849 11.098 0.396672 10.6104C0.892029 10.1227 1.6588 10.1621 2.10931 10.6983L5.12734 14.3064V1.31203C5.12734 0.587416 5.66999 0 6.33939 0C7.00879 0 7.55145 0.587416 7.55145 1.31203V14.2801L10.5574 10.6983C11.0079 10.1621 11.7746 10.1227 12.27 10.6104C12.7654 11.098 12.8017 11.9281 12.3512 12.4643L7.23632 18.5705Z" fill="#EE5353" />
                                        </svg></Box>
                                    </Box>
                                    <Box >
                                        <Box>{this.getStringForcastingan("Savings")}</Box>
                                        <Box style={inlineStyle.fontColorSavings}>{this.state.selectCurrency2.label}{this.convertToThousand(savingData[0])} <svg xmlns="http://www.w3.org/2000/svg" width="13" height="19" viewBox="0 0 13 19" fill="none">
                                            <path d="M7.42675 18.5705C7.19099 18.844 6.85674 19 6.50622 19C6.1557 19 5.82145 18.844 5.58569 18.5705L0.323766 12.4643C-0.138597 11.9281 -0.101282 11.098 0.407111 10.6104C0.915503 10.1227 1.70246 10.1621 2.16482 10.6983L5.26227 14.3064V1.31203C5.26227 0.587416 5.8192 0 6.50622 0C7.19324 0 7.75017 0.587416 7.75017 1.31203V14.2801L10.8352 10.6983C11.2975 10.1621 12.0845 10.1227 12.5929 10.6104C13.1013 11.098 13.1386 11.9281 12.6762 12.4643L7.42675 18.5705Z" fill="#2F92FC" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="19" viewBox="0 0 13 19" fill="none">
                                                <path d="M7.42675 0.429549C7.19099 0.155972 6.85674 0 6.50622 0C6.1557 0 5.82145 0.155972 5.58569 0.429549L0.323766 6.53573C-0.138597 7.07194 -0.101282 7.90196 0.407111 8.38963C0.915503 8.87729 1.70246 8.83793 2.16482 8.30172L5.26227 4.69364V17.688C5.26227 18.4126 5.8192 19 6.50622 19C7.19324 19 7.75017 18.4126 7.75017 17.688V4.71988L10.8352 8.30172C11.2975 8.83793 12.0845 8.87729 12.5929 8.38963C13.1013 7.90196 13.1386 7.07194 12.6762 6.53573L7.42675 0.429549Z" fill="#2F92FC" />
                                            </svg>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box style={inlineStyle.recentwrapper}></Box>
                            </Box>
                            <Box>
                                <Box style={inlineStyle.recentFlex}>
                                    <h5 style={inlineStyle.recentFlexh5}>{this.getStringForcastingan("SpendingCategory")}</h5>
                                   
                                    <span style={inlineStyle.textSeeAll} onClick={this.navigateToSpendings} data-test-id="seeallspendingsBtn">See all</span>
                                </Box>
                                <Box className="recentwrapper">
                                    {this.state.spendingCategory && this.state.spendingCategory.map((value: any, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                <Box style={inlineStyle.recentblockcontent}>
                                                    <Box style={inlineStyle.recentitem}>
                                                        <img src={value[3].data.attributes.image} width="60px" alt="SpendingImage" />
                                                        <Box >
                                                            <h4 style={inlineStyle.reactitemh4} data-test-id={`subCategory-${index}`} >{value[3].data.attributes.name}</h4>
                                                        </Box>
                                                    </Box>
                                                    <Box style={inlineStyle.recentpricespan}>
                                                        <div dir="ltr" style={this.styleCss()}><span>{"-" +  this.state.selectCurrency2.label+ formatNumberWithCommasAndDecimals(value[1])}</span></div>
                                                    </Box>
                                                </Box>
                                                <Box style={inlineStyle.recentwrapper}></Box>
                                            </Fragment>
                                        )
                                    })}
                                </Box>
                            </Box>

                            <Box>
                                <Box style={inlineStyle.recentFlex}>
                                    <h5 style={inlineStyle.recentFlexh5}>{this.getStringForcastingan("RecentTransactions")}</h5>
                                   
                                        <span style={inlineStyle.textSeeAll} onClick={this.navigateToDetailsPage} data-test-id="seealltransaction">See all</span>
                                   
                                </Box>
                                <Box className="recentwrapper">
                                    {this.state.reactTrans && this.state.reactTrans.map((value: any, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                <Box style={inlineStyle.recentblockcontent}>
                                                    <Box style={inlineStyle.recentitem}>
                                                        <img src={value.attributes.sub_category?.image} width="60px" alt="SpendingImage" />
                                                        <Box >
                                                            <h4 style={inlineStyle.reactitemh4}>{value.attributes.description}</h4>
                                                            <p style={inlineStyle.recentblockcontentp}>{value.attributes.transaction_date}</p>
                                                        </Box>
                                                    </Box>
                                                    <Box style={this.state.lowamount == value.attributes.amount_operand ? inlineStyle.selectCardBoxTitel : inlineStyle.normalCardBoxTitel}>
                                                        <span style={inlineStyle.reactflex}>
                                                            <div dir="ltr" style={this.styleCss()}>{value.attributes.amount_operand}{value.attributes.user_currency}{formatNumberWithCommasAndDecimals(value.attributes.converted_amount)}</div>
                                                            &nbsp;
                                                            {this.state.lowamount == value.attributes.amount_operand ? <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                                                <path d="M1.62276 10.9771C1.82918 10.7741 2.16256 10.7741 2.36898 10.9771L4.79816 13.385L4.79816 1.05734C4.79816 0.76824 5.0351 0.533875 5.32739 0.533875C5.61968 0.533875 5.85662 0.76824 5.85662 1.05734L5.85662 13.3693L8.27522 10.9771C8.48274 10.7703 8.82039 10.7692 9.02937 10.9744C9.23836 11.1797 9.23954 11.5137 9.03202 11.7204L5.6608 15.0496C5.47496 15.2326 5.17452 15.2326 4.98868 15.0496L1.62276 11.7204C1.52257 11.6221 1.46621 11.4883 1.46621 11.3487C1.46621 11.2091 1.52257 11.0753 1.62276 10.9771Z" fill="#EE5353" stroke="#EE5353" />
                                                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                                                <path d="M9.05564 4.77014C8.8478 4.9731 8.51214 4.9731 8.30431 4.77014L5.85849 2.36219L5.85849 14.6898C5.85849 14.979 5.61992 15.2133 5.32563 15.2133C5.03134 15.2133 4.79278 14.979 4.79278 14.6898L4.79278 2.37789L2.35762 4.77014C2.14867 4.97685 1.80871 4.97802 1.59829 4.77276C1.38788 4.56749 1.38668 4.23352 1.59563 4.02682L4.98993 0.697562C5.17705 0.51455 5.47955 0.51455 5.66666 0.697562L9.05564 4.02682C9.15652 4.1251 9.21326 4.2589 9.21326 4.39848C9.21326 4.53805 9.15652 4.67185 9.05564 4.77014Z" fill="#24D4B0" stroke="#2CE2D5" />
                                                            </svg>}</span>
                                                        <p style={{...inlineStyle.recentblockcontentp,textAlign:"right"}}>{value.attributes.sub_category.name}</p>
                                                    </Box>
                                                </Box>
                                                <Box style={inlineStyle.recentwrapper}></Box>
                                            </Fragment>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        )
    }

}
// Customizable Area Start

const IncomeBox = styled(Box)({
    paddingLeft: "80px !important",
    paddingRight: "80px !important",
    '@media (min-width: 320px)': {
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
    },
    '@media (min-width: 1200px)': {
        paddingLeft: "80px !important",
        paddingRight: "80px !important",
    },
})

const CustomBoxNext = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: "24px !important",
    paddingRight: "24px !important",
    paddingTop: "24px !important",
    width: "100%",
    '@media (min-width: 320px)': {
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
    },
    '@media (min-width: 1200px)': {
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
    },
})


const inlineStyle = {
    calenderButton: {
        color: "#ffffff"
    },
    chartBox1: {
        strokeWidth: '0'
    },
    recentFlex: {
        margin: ' 0 0 25px',
        marginTop: "20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    recentFlexh5: {
        color: "#fff",
        fontSize: "20px",
        fontWeight: 500,
        margin: 0,
    },
    recentitem: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
    },
    reactitemh4: {
        color: "#fff",
        margin: '0',
        fontSize: '18px',
        fontWeight: 500,
    },
    pointershow: {
        fontSize: "22px",
    },
    recentpricespan: {
        color: "#EE5353",
    },
    recentblockcontentp: {
        color: "#A6A7B6",
        margin: "5px 0px 0",
    },
    recentblockcontent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    recentwrapper: {
        borderBottom: "1px solid #57575D",
        paddingBottom: "15px",
        marginBottom: "2em",
    },
    reactflex: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
    fontColorIncome: {
        color: '#24D4B0',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,

    },
    fontColorExpense: {
        color: '#EE5353',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,

    },
    fontColorSavings: {
        color: '#2F92FC',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,

    },
    IncomeColor: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        gap:'24px'
    },
    textcenter: {
        textAlign: 'center'
    },
    selectCardBoxTitel: {
        color: '#EE5353',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    normalCardBoxTitel: {
        color: '#24D4B0',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: 'normal',
    },
    trendsBox: { height: '100vh', width: '100%', background: '#2A2A39' },
    trendsoverflowY: { display: 'flex', justifyContent: 'space-between', padding: '20px 0px' },
    trendsfont: { display: 'flex', alignItems: 'center' },
    trendsAfont: { color: 'white', paddingLeft: '20px', fontSize: '28px', fontFamily: 'Poppins', fontWeight: 600 },
    trendsSettingimg: { width: '41px', height: '41px', cursor: "pointer" },
    trendsicon: { display: 'flex', alignItems: 'center', marginRight: "30px" },
    trendsInfo: { display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'white' },
    trendsIncomeBox: { border: '7px solid #24D4B0', height: '0', margin: '0px 10px' },
    trendsExpenseBox: { border: '7px solid #EE5353', height: '0', margin: '0px 10px' },
    trendsSavingsBox: { border: '7px solid #2F92FC', height: '0', margin: '0px 10px' },
    textSeeAll: { color: '#814FF9',cursor:'pointer' },
    trendsMinus: { color: 'rgb(189 174 174)', fontSize: '35px' },
    trendsIconMinus: { color: 'rgb(255, 255, 255)', fontSize: '35px' },
}

// Customizable Area End
