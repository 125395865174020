// Customizable Area Start

import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    Modal,
    TextField,
    Popper,
    MenuItem,
    MenuList,
    Paper,
    Grow,
    ClickAwayListener,
    Menu
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CircularProgress from "@material-ui/core/CircularProgress";
import { document_file, threeDotss } from "./assets";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

import DocumentationController, {
    Props,
    documentMedias
} from "./DocumentationController";

export default class Document extends DocumentationController {
    constructor(props: Props) {
        super(props);

    }

    renameFileModal = () => {
        return (
            <Modal
                open={!!this.state.renameModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                data-test-id="RenameModal"
            >
                <>

                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

                        <Box style={{
                            height: "43%",
                            width: "28%",
                            color: "#FFFFFF",
                            background: "#3D3751",
                            padding: "35px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            borderRadius: "12px"
                        }}>
                            <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                                Rename your folder
                            </Typography>
                            <TextField
                                data-test-id="rename-value"
                                variant="outlined"
                                style={{ width: "80%", borderRadius: "5px" }}
                                value={this.state.renameValue}
                                onChange={this.handleRename}
                                id="outlined-basic"
                                label="Give a title"
                            />
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: '100%',
                                    marginTop: '1.9rem'

                                }}
                            >
                                <Button
                                    data-test-id="rename-cancel-button"
                                    onClick={() => this.handleRenameModal(null)}
                                    style={{
                                        borderRadius: "8px",
                                        color: "red",
                                        border: "1px solid red",
                                        width: "30%",
                                        margin: "5px",
                                        fontWeight: 600,
                                        textTransform: 'unset'
                                    }}
                                    variant="contained" component="span">
                                    Cancel
                                </Button>
                                <Button
                                    data-test-id="rename-button"
                                    onClick={() => this.renameFileAndFolder(this.state.fileMenu)}
                                    style={{
                                        color: "C9F9F6",
                                        border: "1px solid #C9F9F6",
                                        borderRadius: "8px",
                                        width: "30%",
                                        margin: "5px",
                                        fontWeight: 600,
                                        textTransform: 'unset'
                                    }}
                                    variant="contained"
                                    component="span">
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>



                </>



            </Modal>
        )
    }

    deleteModal = () => {
        return (
            <Modal
                open={!!this.state.deleteModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                data-test-id="DeleteModal"
            >
                <>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                        <Box style={{
                            height: "38%",
                            width: "33%",
                            color: "#FFFFFF",
                            background: "#3D3751",
                            padding: "35px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            borderRadius: "12px"

                        }}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>
                                {this.state.fileMenu?.file === "folder" ?
                                    "Are you sure you want to delete?The folder and any documents stored inside the documents stored inside the folder will be permanently deleted"
                                    :
                                    "Do you want to delete this file?"
                                }
                            </Typography>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: '100%',
                                    marginTop: '1.9rem'
                                }}
                            >
                                <Button
                                    data-test-id="delete-button"
                                    onClick={() => this.deleteFolderAndFiles(this.state.fileMenu)}
                                    style={{
                                        borderRadius: "8px",
                                        color: "red",
                                        border: "1px solid red",
                                        width: "30%",
                                        margin: "5px",
                                        textTransform: 'unset'
                                    }}
                                >Delete</Button>
                                <Button
                                    data-test-id="delete-cancel-button"
                                    onClick={() => this.handleDeleteModal(null)}
                                    style={{
                                        borderRadius: "8px",
                                        color: "#C9F9F6",
                                        border: "1px solid #C9F9F6",
                                        width: "30%",
                                        margin: "5px",
                                        textTransform: 'unset'
                                    }}
                                >Cancel</Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Modal>
        )
    }

    removeFolderModal = () => {
        return (
            <Modal
                open={this.state.removeFolderModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                data-test-id="RemoveModal"
            >
                <>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                        <Box style={{
                            height: "30%",
                            width: "30%",
                            color: "#FFFFFF",
                            background: "#3D3751",
                            padding: "35px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            borderRadius: "12px"

                        }}>
                            <Typography variant="h6">
                                Remove from folder
                            </Typography>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: '100%',
                                    marginTop: '1.9rem'
                                }}
                            >
                                <Button
                                    data-test-id="remove-cancel-button"
                                    onClick={() => this.handleRemoveFolderModal(false)}
                                    style={{
                                        borderRadius: "8px",
                                        color: "red",
                                        border: "1px solid red",
                                        width: "45%",
                                        height: '70%',
                                        margin: "5px",
                                        textTransform: 'unset'
                                    }}
                                >Cancel</Button>
                                <Button
                                    data-test-id="remove-button"
                                    onClick={this.removeFolderOpen}
                                    style={{
                                        borderRadius: "8px",
                                        color: "#C9F9F6",
                                        border: "1px solid #C9F9F6",
                                        width: "45%",
                                        margin: "5px",
                                        height: '70%',
                                        textTransform: 'unset'
                                    }}
                                >Remove from folder</Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Modal>
        )
    }

    renderLoaderWeb() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress style={{ color: "#0000FF" }} />
            </div>
        );
    }

    render() {

        return (
            <>
                <ThemeProvider theme={theme}>
                    <style>
                        {`
                    .MuiSelect-select.MuiSelect-select {
                    width: 230px !important;
                    display: flex;
                    align-items: center;
                    outlined: white;
                    color: black;
                    }
                    .MuiOutlinedInput-input {
                    padding: 25.5px 14px !important
                    }
                    input[type="text"] {
                    border-bottom: none
                    }
                    .MuiButton-contained {
                        background-color: transparent;
                        box-shadow: none;
                    }
                    .MuiPaper-root {
                        width: 187px;
                        border-radius: 10px;
                        // left: 1214px !important;
                    }
                    .MuiList-root {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                    }
                    .MuiListItem-root {
                        justify-content: flex-end;
                    }
                    .MuiMenuItem-root {
                        color: #3c3c51;
                        width: 100%
                    }
                    .MuiOutlinedInput-notchedOutline{
                        border-color: #999aa5 !important;
                    }
                    .MuiOutlinedInput-notchedOutline:hover {
                        border-color: #999aa5 !important;
                    }
                    .MuiInputBase-root:hover{
                        border-color: #999aa5 !important;
                    }
                    
                `}
                    </style>
                    <div style={{ overflowX: 'hidden', width: '100%' }}>
                        <Grid container spacing={3} style={{ margin: "15px", padding: "10px", width: '97.9%', position: 'relative', zIndex: 100 }}>
                            <Grid item xs={1}>
                                <IconButton data-test-id="goBackBtn" onClick={() => window.history.back()}>
                                    <ArrowBackIcon style={{ color: "#FFFFFF" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}
                                style={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                            >
                                <Typography style={{
                                    color: '#FFFFFF',
                                }} variant="h6">{this.state.allFolders.find(id=> id.id == this.state.currentFolderId)?.name}</Typography>
                            </Grid>
                            <Grid item xs={3} >
                                <IconButton
                                    data-test-id="menu-options-button-1"
                                    onClick={(event) => this.handleMenuModal({
                                        file_id: null,
                                        folder_id: this.props.navigation.getParam('id'),
                                        file: 'folder',
                                    }, event)}
                                    aria-haspopup="true"
                                    type="button"
                                >
                                    <img src={threeDotss} alt="threeDotsMenu" height={"30px"} />
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleDocumentMenuModalClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem data-test-id="renameDTI" onClick={() =>  {this.handleRenameModal(this.state.fileMenu)}}> Rename </MenuItem>
                            <MenuItem data-test-id="deleteDTI" onClick={() =>  {this.handleDeleteModal(this.state.fileMenu)}}>Delete</MenuItem>
                        </Menu>

                        {this.state.loader ?
                            <div style={webStyle.loaderCss}>
                                {this.renderLoaderWeb()}
                            </div>
                            :
                            <div style={{ height: '84vh', overflowY: 'auto' }}>
                                {this.state.particularDocumentData && this.state.particularDocumentData.length > 0 && this.state.particularDocumentData?.map((element: documentMedias, index: number) => {
                                    return (
                                        <Grid container spacing={3} style={{ margin: '14px', width: "98%", padding: '10px' }}>
                                            <Grid item xs={1}><img style={{ width: '80px', height: "60px" }} src={document_file} alt='doc_file' /></Grid>
                                            <Grid item xs={8} >
                                                
                                                <Typography variant="h6" component="h2" style={{ color: "#FFFFFF", width: 200, wordBreak: "break-all" }}>
                                                    {element.file_name}
                                                </Typography>
                                                <Typography variant="h6" component="h2" style={{ color: '#FFFFFF', paddingTop: '10px', width: 200, wordBreak: "break-all", opacity: '50%' }}>
                                                    {element.updated_by}
                                                </Typography>
                                                <Typography variant="h6" component="h2" style={{ color: '#FFFFFF', paddingTop: '10px', width: 200, wordBreak: "break-all", opacity: '50%' }}>
                                                    {element.date}
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid item xs={3} style={{ position: "relative" }} >
                                                <IconButton
                                                    data-test-id="menu-options-button"
                                                    type="button"
                                                    aria-haspopup="true"
                                                    onClick={(event) => this.handleMenuModal({
                                                        file_id: element.id,
                                                        folder_id: 10,
                                                        file: 'file'
                                                    }, event)}
                                                    
                                                >
                                                    <img src={threeDotss} alt="threeDotsMenu" height={"30px"} />
                                                </IconButton>
                                                {this.state.fileMenu?.file_id === element.id &&
                                                    <Menu
                                                    anchorEl={this.state.anchorEl2}
                                                        id="simple-menu"
                                                        anchorOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'top',
                                                        }}
                                                        keepMounted
                                                        onClose={this.handleDocumentMenuModalClose}
                                                        transformOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'top',
                                                        }}
                                                        open={Boolean(this.state.anchorEl2)}
                                                    >
                                                        <MenuItem data-test-id="view" onClick={() => this.handleDocumentView(element.url)}>View</MenuItem>
                                                        <MenuItem data-test-id="downloadFolder" onClick={() => this.handleDocumentDownload(element.url)}>Download</MenuItem>
                                                        <MenuItem data-test-id="removeFolder" onClick={() => this.handleRemoveFolderModal(true)}>Remove From Folder</MenuItem>
                                                        <MenuItem data-test-id="deleteFolder" onClick={() => this.handleDeleteModal(this.state.fileMenu)} >Delete</MenuItem>
                                                    </Menu>
                                                }

                                            </Grid>

                                        </Grid>
                                    )
                                })}
                            </div>
                        }

                    </div>

                    {this.deleteModal()}
                    {this.removeFolderModal()}
                    {this.renameFileModal()}
                </ThemeProvider>
            </>
        );
    }
}

const webStyle = {

    folderImage: {
        width: '100%'
    },

    addFolder: {
        background: '#fff',
        maxWidth: '400px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        position: 'absolute',
        borderRadius: '15px',
        textAlign: "center",
        paddingBottom: "40px",
        paddingTop: "20px"
    },
    loaderCss: {
        transform: 'translateY(-110px)'
    },
    docMenuOption: {
        background: '#fff',
        width: '200px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        borderRadius: '15px',
    },
};

// Customizable Area End
