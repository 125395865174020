import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import "../assets/css/popo.css";
import EmailAccountRegistrationsControllerweb, {
  Props,
} from "./EmailAccountRegistrationsController.web";
import Applelogin2 from "../../applelogin2/src/Applelogin2.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-input": {
          color: "white",
          width: '100%',
          borderBottom: '0px',
        },
      },
    },
  },
});

// Customizable Area End
export class EmailAccountRegistrationBlockweb extends EmailAccountRegistrationsControllerweb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
<Box className='mainWrapper makeCenter' style={{ overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#00ebd9 transparent' , height: '100vh'}}>  
      <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px', paddingTop: '15px' }}>
          <Box className="borderCss">
            <Box className="verify-box-new">
              <ThemeProvider theme={theme}>
                <Box style={webStyle.logoandimg} dir={this.state.languages}>
                  <img style={{width: '209px', height: '96px', top: '22px' }} src={this.state.logoimg} />
                </Box>
                <CssBaseline />
                <Container >
                  <Box className="boxSpace" dir={this.state.languages}>
                    <Box>
                      <Typography style={webStyle.customLabel}>{this.getString('signUpCustomer')}</Typography>
                      <Typography style={{ color: 'white', fontSize: '17px', fontWeight: 500, marginBottom: '20px', fontFamily: 'Poppins' }} variant="subtitle1" component="div">
                        {this.getString('labelHeader')}
                        {/*UI Engine::From Sketch*/}
                      </Typography>
                    </Box>
                    <Formik
                      initialValues={{ email: "", password: "", confirmPasswords: "" }}
                      validationSchema={this.codeSchema}
                      onSubmit={(values: any) => this.HandleSubmit(values)}
                      data-test-id="accountLogin"
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {({ errors, values, handleSubmit, handleChange }: any) => (
                        <form onSubmit={handleSubmit} data-test-id='submitForm'>
                          <Box className="inputFieldCss" >
                            <Box >
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >{this.getString('emailAddress')}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={this.state.emailError || errors.email}
                                  style={webStyle.inputRoot}
                                  data-test-id="txtInputEmail"
                                  placeholder={this.getString('emailPlaceholder')}
                                  fullWidth={true}
                                  className="inputBox"
                                  name='email'
                                  value={values.email}
                                  onChange={handleChange('email')}
                                />
                                <Box sx={webStyle.fieldError}>
                                  {this.finalErrorMessage(this.state.emailError || errors.email)}
                                </Box>
                              </Box>
                            </Box>
                            <Box className="inputFieldCss">
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >{localStorage.getItem('language') == "Arabic" ? this.state.passwordlableArabic : this.state.passwordLabel}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <div className={`custom-tooltips ${this.state.isHovered ? 'show' : 'hide'}`}>
                                  <OutlinedInput
                                    error={errors.password}
                                    onMouseEnter={this.handleMouseEnters}
                                    onMouseLeave={this.handleMouseEnters}
                                    style={webStyle.inputRoot}
                                    type={this.state.enablePasswordField ? 'password' : 'text'}
                                    name="password"
                                    data-test-id="txtInputpassword"
                                    placeholder={this.getString('languagePasswordPlaceholder')}
                                    fullWidth={true}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          data-test-id="handleClickShowPassword"
                                          onClick={this.handleClickShowPassword}
                                          edge="end"
                                        >
                                          {!this.state.enablePasswordField ? (
                                            <VisibilityOutlinedIcon style={{
                                              cursor: "pointer", color: 'white',
                                              opacity: 0.2
                                            }} />
                                          ) : (
                                            <VisibilityOffOutlinedIcon style={{
                                              cursor: "pointer", color: 'white',
                                              opacity: 0.2
                                            }} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                  <span className={localStorage.getItem('language') == "Arabic" ? "tooltip-textTransfrom" : "tooltip-text"} >
                                    <Box style={{ padding: '3px' }} >
                                      <Typography style={{ fontSize: '0.8rem' }}>{this.getString("languagePasswordRequirements")}</Typography>
                                      {this.colorCheck(values)}
                                    </Box>
                                  </span>
                                  <Box sx={webStyle.fieldError}>
                                    {this.finalErrorMessage(errors.password)}
                                  </Box>
                                </div>
                              </Box>
                            </Box>
                            <Box className="inputFieldCss">
                              <InputLabel style={webStyle.customInputLabel} htmlFor="standard-adornment-password" >{this.getString('confirmPassword')}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.confirmPasswords}
                                  style={webStyle.inputRoot}
                                  className="inputBox"
                                  type={this.state.enablePasswordFieldConfirm ? 'password' : 'text'}
                                  name="confirmPasswords"
                                  data-test-id="txtInputconfirmpassword"
                                  placeholder={this.getString('requirementsPlaceholder')}
                                  fullWidth={true}
                                  value={values.confirmPasswords}
                                  onChange={handleChange('confirmPasswords')}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPasswords}
                                        edge="end"
                                      >
                                        {!this.state.enablePasswordFieldConfirm ? (
                                          <VisibilityOutlinedIcon style={{
                                            cursor: "pointer", color: 'white',
                                            opacity: 0.2
                                          }} />
                                        ) : (
                                          <VisibilityOffOutlinedIcon style={{
                                            cursor: "pointer", color: 'white',
                                            opacity: 0.2
                                          }} />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <Box sx={webStyle.fieldError}>
                                  {this.finalErrorMessage(errors.confirmPasswords)}
                                </Box>
                              </Box>
                            </Box>
                            <Box sx={webStyle.forget}>
                              <Box sx={{ fontSize: "12px", color: "#FFFFFF", fontWeight: "bold", display: "flex" }}>
                                <label className="container" style={{ paddingLeft: '25px' , padding: '10px'}}>
                                  <input type="checkbox" onChange={this.handleCheckboxChange} data-test-id={"btnRememberMe"} />
                                  <span className="checkmarkOne"></span>
                                </label>
                                {" "}<span style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>{this.getString('termCondtions')}&nbsp;</span><a className="termAndConditioin" data-test-id={"btntermCondition"} onClick={this.termaConditions}> {this.getString('termCondtionsLink')}</a>
                              </Box>
                            </Box>
                            <Box>
                              <InputLabel style={{ color: 'white', textAlign: 'center', padding: '1px', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500 }} htmlFor="standard-adornment-password" >{this.getString('alreadyAccount')}  <a style={{ color: "#2F92FC", fontSize: '14px', fontFamily: 'Poppins', fontWeight: 700, cursor: "pointer" }} onClick={this.goToPrivacyPolicy} >{this.getString('singInAccount')}</a></InputLabel>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center", padding: '4px'
                              }}
                            >
                              <Button
                                className={this.state.isChecked ? "customButton" : "disableCustomButton"}
                                data-test-id={"btnEmailLogIn"}
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={!this.state.isChecked}
                              >
                                {this.getString('createAccountButton')} {/*UI Engine::From Sketch*/}
                              </Button>
                            </Box>
                            <Box style={{ top: '32px', bottom: '32px' }}>
                              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <Box style={{ width: '90%', height: '1px', backgroundColor: '#77869C' }}> </Box>
                                <a style={{ alignItems: 'center', margin: '20px', fontSize: '12px', color: '#FFFFFF' }}>{this.getString('or')}</a>
                                <Box style={{ width: '90%', height: '1px', backgroundColor: '#77869C' }}> </Box>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 0px", paddingTop: "0px" }}>
                              <Box data-test-id="btnSocialLogin" >
                                <img className="signupProvider" data-test-id={"btnSocialLogins"} src={this.state.ContinueGoogle} onClick={this.login} />
                                {/*UI Engine::From Sketch*/}
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 0px",paddingTop:"0px" }}>
                               <Applelogin2 navigation={this.props.navigation} id={""}/>
                              </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "10px 0px",
                                paddingBottom: '0px'
                              }}
                            >
                              
                            </Box>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default EmailAccountRegistrationBlockweb
const webStyle = {
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "red !important",
    }
  },
  customLabel: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  inputStyle: {
    border: "1px solid white",
    padding: "15px",
  },
  customInputLabel: {
    color: 'white ',
    fontSize: '14px',
    marginBottom: '12px',
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  inputRoot: {
    borderRadius: '10px',
    height: '56px',
    outline: 'none',
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& input::placeholder': {
      fontWeight: 500,
      fontSize: '14px',
      color: 'white ',
      opacity: 0.5,

    }, '& .MuiInputBase-input': {
      borderColor: 'white !important'
    },
  },
  cekbox: {
    '& .MuiCheckbox-root': {
      color: 'aqua',
    },
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  forget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem'
  },
  buttons: {
    backgroundColor: "#c7f9f6",
    borderRadius: '10px',
    margin: '0px',
    Color: '#3C3C50',
    height: "50px",
    fontWeight: 700,
    fontSize: '20px',
    textTransform: 'none',
  },
  logoandimg: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    top: '142px',
    paddingTop: '12px',
    paddingRight: '49px',
    paddingLeft: '49px'
  },
  fieldError: {
    color: "#EE5353",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  }
};
// Customizable Area End
