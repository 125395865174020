import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const CustomPagination:React.FC<{
  totalRecord: number,
  rowPerPage: number,
  page:number,
  handlePageChange: (page:number)=>void
}> = ({ totalRecord ,rowPerPage,handlePageChange,page}) => {
  const [totalPages,setTotalPages] = useState<number>(0);
  useEffect(()=>{
    setTotalPages(Math.ceil(totalRecord / rowPerPage))
  
  },[totalRecord])
  useEffect(()=>{
    setCurrentPage(page)
  },[page])
  const [currentPage, setCurrentPage] = useState<number>(page);

  const goToPage = (page:number) => {
    if(currentPage !== page){
      setCurrentPage(page);
      handlePageChange(page)
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      handlePageChange(currentPage + 1)
    }
    
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1)

    }
  };

  // Generate array of page numbers
  const pageNumbers:number[] = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Logic for displaying ellipsis and pagination ranges
  let displayPages: (number | string)[]  = pageNumbers;
  if (totalPages > 4) {
    if (currentPage > 3 && currentPage < totalPages - 2) {
      displayPages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    } else if (currentPage <= 3) {
      displayPages = [1, 2, 3, '...', totalPages];
    } else {
      displayPages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
    }
  }

  return (
    <div style={webstyles.pagination}>
      <Typography   onClick={prevPage} style={currentPage === 1? webstyles.disabledBack : webstyles.enabledBack}>
      &lt; Previous
      </Typography>
     
      {displayPages.map((page, index) => (
        <Typography
          key={index}
          style={currentPage === page ? webstyles.activeStyle :webstyles.inActiveStyle}
          component="span"
          onClick={() => typeof page === 'number' ? goToPage(page) : null}
          data-test-id={`page-no-${page}`}
        >
          {page}
        </Typography>
      ))}
       <Typography data-test-id="next"  onClick={nextPage} style={currentPage === totalPages? webstyles.disabledBack : webstyles.enabledBack}>
       Next &gt;
      </Typography>
    </div>
  );
};
type IStyle = { [key: string]: React.CSSProperties };

const webstyles: IStyle= {
  activeStyle: {
    border: "1px solid rgb(44, 226, 213)",
    marginRight: "4px",
    marginLeft:"4px",
    color: "rgb(140, 140, 144)",
    padding: "10px 16px",
    cursor:"pointer",
    fontSize:"12px",
    borderRadius:"8px"
  },
  inActiveStyle: {
    marginRight: "4px",
    marginLeft:"4px",
    color: "rgb(140, 140, 144)",
    padding: "10px 16px",
    cursor:"pointer",
    fontSize:"12px",

  },
  pagination:{
    display:"flex",
    justifyContent:"flex-end",
    flexWrap: "wrap",
  },
  disabledBack:{
    color: "rgb(140, 140, 144)",
    cursor:"unset",
    padding: "10px 16px",
    fontSize:"12px"
  },
  enabledBack:{
    color: "rgb(44, 226, 213)",
    cursor:"pointer",
    padding: "10px 16px",
    fontSize:"12px"
  }
}
export default CustomPagination;