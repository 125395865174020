import { Modal, styled } from '@material-ui/core';
import React from 'react'

function ImageDownloader({
    selectImgUrl,image, fileName='downloaded_file'
}: any) {
    const downloadImage = async () => {
        try {
            const response = await fetch(selectImgUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download =fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download image:', error);
        }
    };
    return (
        <div style={{
            position: 'absolute',
            top: 8,
            right: 16,
            backgroundColor: '#2CE2D5',
            padding: '6px',
            borderRadius: '4px',
            cursor: 'pointer'
        }} onClick={downloadImage}>
            <img width={16} src={image} alt="donwloade image" />
        </div>
    )
}

const ModalContainer = styled(Modal)({
    justifyContent: "center",
    alignItems: 'center',
    display: "flex"
});

const ModalBox = styled("div")({
    position: 'relative',
    width: "450px",
    height: "450px",
    background: "white",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 620px)": {
        width: "350px",
        height: "350px",
    },
    "@media(max-width: 550px)": {
        width: "300px",
        height: "300px",
    },
});

const ModalImageBox = styled("div")({
    width: "400px",
    height: "400px",
    "@media(max-width: 620px)": {
        width: "300px",
        height: "300px",
    },
    "@media(max-width: 550px)": {
        width: "250px",
        height: "250px",
    },
});

const ModalImage = styled("img")({
    width: "100%", height: "100%"
});



export default ImageDownloader
