import React from "react";
// Customizable Area Start
import "../../apiintegrationbankaccounts/src/style.css"
import { image_Shape } from "../../apiintegrationbankaccounts/src/assets";
import {
    Props,
} from "./FormApprovalWorkflowController.web";
import ServicePaymentController from "./ServicePaymentController.web";




// Customizable Area End



export default class SuccessPayment extends ServicePaymentController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
     

        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
                <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box d-flex align-center">

                        <div className="successful-img-content">
                            <img src={image_Shape} />
                            <p>Congratulations,<br />
                                Your purchase is successfull !
                            </p>
                          <button className="successBtn bankBtns"
                            onClick={()=> this.handleNavigateAfterPayment()}
                            data-test-id="continueBtn"

                            >Go back to estate planning</button>

                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
