import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent, createRef } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { Client as ConversationsClient, Conversation, Message as MessageData, Paginator, JSONValue } from "@twilio/conversations";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { fixWebmDuration } from "@fix-webm-duration/fix";
import { validateUploadFile } from "../../../components/src/common";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface SelectedFile {
  fileName: string;
  fileType: string;
  file: File;
  previewUrl?: string;
}
export interface ErrorPayloadTypePayLoad  {
  key: string;
}

export interface InvalidResponseTypePayLoad {
  errors: Array<ErrorPayloadTypePayLoad>;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface Emoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string[];
  custom: boolean;
}

interface UserDataObject {
  id: string;
  type: string;
  attributes: {
    id: number;
    conversation_sid: string;
    account_id: number;
    wealth_planner_id: number;
    created_at: string;
    name: string;
    time: string;
    last_message:{message: string},
    message: string;
    image: string | null;
    image_text: string;
    dateUpdated: Date;
  },
  unreadCount: number;
}

export interface UserDataListType {
  data: UserDataObject
}

export interface ChatDataType {
  sender: string;
  message: string | null;
  time: string;
  imgUrl: string;
  userId: string | null;
  type: string | null;
  dateUpdated: Date;
  groupDateLabel: string;
  fileName:string;
}

export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

interface MessageType {
  state: {
      type: string;
      media: Media;
      body: string;
      attributes?: {
        audio?: boolean;
      },
      dateUpdated: Date
  };
  author: string;
}

interface Media {
  state:{
    filename:string,
    contentType:string,
  };
  getContentTemporaryUrl: Function;
}

interface MessageTwoViewchat {
  state: {
      sid: string;
      type: string;
      media: Media;
      body: string;
      attributes?: {
        audio?: boolean;
      },
      dateUpdated: Date
  };
  author: string;
}

interface MessageAttributes {
  read?: boolean;
  audio?: boolean;
}

interface ConversationInfoType {
  type: string, 
  unreadCount: number, 
  lastMessage: string, 
  lastMessageDateUpdated: Date, 
  conversationSid: string 
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  chatDataList: ChatDataType[];
  senderChatText: string;
  chatToken: string;
  conversationID: string;
  plannerId: string;
  userName: string;
  userImage: string| null;
  userImageText: string | null;
  userId: string;
  loginId : string;
  userDataList: UserDataListType[];
  userConversationList: UserDataListType[];
  conversationSid: string;
  customerUserID:number;
  isEmoji: boolean;
  selectedEmoji: null | Emoji;
  isImgUpload: boolean;
  imageUrlUpload: string;
  imgName: string;
  imageUpload: null | File;
  imageModal: boolean;
  imgUrl: string;
  imageFile: File | null;
  imageName: string;
  isImageUpload: boolean;
  isRecording: boolean;
  isPlaying: boolean;
  audioURL: string | null;
  currentTime: number;
  duration: number;
  currentAudioIndex: number;
  isRefreshing: boolean;
  dateLabel: string;
  isLoadingPrevMessages: boolean;
  isImageModal : boolean;
  selectImageName : string ;
  selectImgUrl : string;
  isLoading: boolean;
  isUserCheck : boolean;
  isSelectedDefaultUser: boolean;
  plannerInfo: {
    name:string,
    email:string,
    image:string
  };
  dropdownVisiblity: string;
  numberOfConversations: number;
  selectedFile?: SelectedFile;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  lastMessageID: string = "";
  chatDataRef: React.RefObject<HTMLDivElement>;
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  getTokenId: string = "";
  sendMessageId : string ="";
  getConversationApiId: string="";
  getUserListApiId: string ="";
  getUserChatApiId: string ="";
  conversationChannel! : Conversation;
  private mediaRecorder: MediaRecorder | null = null;
  audioRef = createRef<HTMLAudioElement>();
  private audioChunks: Blob[] = [];
  private debounceTimeout: NodeJS.Timeout | null = null;
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  chatContainerRef: React.RefObject<HTMLDivElement> = createRef();
  paginator: Paginator<MessageData> | null = null;
  pickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  getUserProfileAPICallId:string = "";
  startTime: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      chatDataList: [],
      senderChatText: "",
      chatToken: "",
      conversationID: "",
      plannerId: "",
      userName: "",
      userImage: "",
      userImageText: "",
      userId: "",
      loginId : "",
      userDataList: [],
      userConversationList: [],
      conversationSid: "",
      customerUserID:0,
      isEmoji: false,
      selectedEmoji: null,
      isImgUpload: false,
      imageUrlUpload: "",
      imageUpload: null,
      imgName: "",
      imageModal: false,
      imgUrl: "",
      imageFile: null,
      imageName: "",
      isImageUpload: false,
      isRecording: false,
      isPlaying: false,
      audioURL: null,
      currentTime: 0,
      duration: 0,
      currentAudioIndex: 0,
      isRefreshing: false,
      dateLabel: 'Today',
      isLoadingPrevMessages: false,
      isImageModal: false,
      selectImgUrl: "",
      isLoading: true,
      isUserCheck : false,
      isSelectedDefaultUser: true,
      plannerInfo: {
        name:"",
        email:"",
        image:""
      },
      dropdownVisiblity:"state-dropdown",
      numberOfConversations: 0,
      selectedFile: undefined,
      selectImageName: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.chatDataRef = createRef();
    this.startTime = 0;
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getChatDataList();
    this.getUserProfile();
    const loginId= await getStorageData("planerId");
    this.setState({ loginId: loginId });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.pickerRef.current && !this.pickerRef.current.contains(event.target as Node)) {
        this.setState({ isEmoji: false });
    }
  };

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  initConversations = async (chatToken: string, refreshLaunch: boolean) => {
    try {
      const conversationsClient = await new ConversationsClient(chatToken);
      if (conversationsClient) {
        try {
            conversationsClient.on('connectionStateChanged', async (state) => {
              if (state === 'connected') {
                this.onClientConnection(conversationsClient, refreshLaunch);
              }
            });
            conversationsClient.on('tokenExpired', async () => {
              this.getChatToken();
            });
            conversationsClient.on('conversationUpdated', async (conversation) => {
              this.setState({ isSelectedDefaultUser: false }, async () => {
                if (this.debounceTimeout) {
                  clearTimeout(this.debounceTimeout);
                }
                this.debounceTimeout = setTimeout(async () => {
                  this.getChatDataList();
                  await this.loadConversations(conversationsClient);
                }, 300);
              });
            });
        } catch (error) {
        }
      }
    } catch (error) {
      console.error('@@@ Error initializing ConversationsClient:', error);
    }
  };

  formatDateLabel = (date: Date): string => {
    const latestDate= new Date(date)
    const now = new Date();
    const isToday = now.toDateString() === latestDate.toDateString();
    const isYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toDateString() === latestDate.toDateString();
    if (isToday) {
      const hours = latestDate.getHours();
      const minutes = latestDate.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      const day = latestDate.getDate().toString().padStart(2, '0');
      const month = (latestDate.getMonth() + 1).toString().padStart(2, '0');
      const year = latestDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };

  loadConversations = async (conversationsClient: ConversationsClient) => {
    try {
      const conversations = await conversationsClient.getSubscribedConversations();
      this.setState({numberOfConversations: conversations.items.length})
      const conversationsInfo = await Promise.all(
        conversations.items.map(async (conversation) => {
          const lastMessage = await conversation.getMessages(1).then(messages => messages.items[0]);
          const attributes = lastMessage?.attributes as MessageAttributes;
          if (conversation.sid === this.state.conversationSid) {
            await this.markMessagesAsRead(conversation);              
            return {
              conversationSid: conversation.sid,
              unreadCount: 0,
              lastMessage: lastMessage?.body ? lastMessage?.body || "" : "",
              type: this.messgaeTypeCheck(lastMessage,attributes as unknown as {audio:string}),
              lastMessageDateUpdated: lastMessage?.dateUpdated || conversation.dateUpdated,
            }
          }
          const unreadCount = await this.getUnreadMessagesCount(conversation);
          return {
            conversationSid: conversation.sid,
            unreadCount,
            lastMessage: lastMessage?.body ? lastMessage?.body || "" : "",
            type: this.messgaeTypeCheck(lastMessage,attributes as unknown as {audio:string}),
            lastMessageDateUpdated: lastMessage?.dateUpdated || conversation.dateUpdated,
          };
        })
      );
       this.userDataListFunction(conversationsInfo as unknown as [ConversationInfoType])
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  userDataListFunction = (conversationsInfo: [ConversationInfoType]) => {
    let userDataList = this.state.userDataList;
    userDataList.forEach((user: UserDataListType) => {
      const userIndex = conversationsInfo.findIndex((conversation) => conversation.conversationSid === user.data.attributes.conversation_sid);
      if (userIndex !== -1) {
        user['data']['type'] = conversationsInfo[userIndex].type;
        user['data']['unreadCount'] = conversationsInfo[userIndex].unreadCount;
        user['data']['attributes']['last_message']['message'] = String(conversationsInfo[userIndex]?.lastMessage) || "";
        user['data']['attributes']['dateUpdated'] = conversationsInfo[userIndex]?.lastMessageDateUpdated || new Date();
      }
      return user;
    });

    userDataList.sort((a, b) => {
      const dateA = new Date(a.data.attributes.dateUpdated);
      const dateB = new Date(b.data.attributes.dateUpdated);
      if (dateB > dateA) {
        return 1;
      } else if (dateB < dateA) {
        return -1;
      } else {
        return 0;
      }
    });
    if (this.state.isSelectedDefaultUser) {
      this.onSelectUser(userDataList[0]);
    }
    this.setState({ userConversationList: userDataList });
  };

  messgaeTypeCheck = ( lastMessage: {type:string} ,attributes:{audio:string}) => {
    let messageType;
    if (lastMessage?.type === 'media') {
      if (attributes?.audio) {
        messageType = 'audio';
      } else {
        messageType = 'image';
      }
    } else {
      messageType = 'text';
    }
    return messageType
  };

  getUnreadMessages = async (conversation: Conversation) => {
    try {
      const messages = await conversation.getMessages(100);
      const unreadMessages = messages.items.filter((message) => {
        const attributes = message.attributes as MessageAttributes;
        return !attributes.read;
      });
      return unreadMessages;
    } catch (error) {
      console.error('Error fetching unread messages:', error);
      return [];
    }
  };

  getUnreadMessagesCount = async (conversation: Conversation) => {
    try {
      const messages = await conversation.getMessages(100);
      const unreadMessages = messages.items.filter((message) => {
        const attributes = message.attributes as MessageAttributes;
        if (Object.keys(attributes).length === 0) {
          return false;
        } else {
          if ('read' in attributes) {
            return !attributes.read;
          }
          return false;
        }
      });
      return unreadMessages.length;
    } catch (error) {
      console.error('Error fetching unread messages:', error);
      return 0;
    }
  };

  markMessagesAsRead = async (conversation: Conversation) => {
    try {
      const unreadMessages = await this.getUnreadMessages(conversation);
      if (unreadMessages.length > 0) {
        await Promise.all(unreadMessages.map(async (message) => {
          const attributes = message.attributes as MessageAttributes;
          try {
            if(Object.keys(attributes).length !== 0 && 'read' in attributes) {
              if(!attributes?.read) {
                const currentAttributes = attributes || {};
                await message.updateAttributes({ ...currentAttributes, read: true } as JSONValue);
              }
            }
          } catch (error) {
            console.error(`Error updating message ${message.sid} as read:`, error);
          }
        }));
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  onClientConnection = async (conversationsClient: ConversationsClient, refreshLaunch: boolean) => {
    try {
      const channel = await conversationsClient.getConversationBySid(this.state.conversationSid);
      await this.loadConversations(conversationsClient);
      if(refreshLaunch) {
      await this.handleMessages(channel);
      }
      this.conversationChannel = channel;
      channel.on('messageAdded', async (getMessage) => {
        if(getMessage.conversation.sid === this.state.conversationSid) {
          await this.handleMessageAdded(getMessage);
        }
      });
      if (this.chatContainerRef.current) {
        this.chatContainerRef.current.addEventListener('scroll', this.handleScroll);
      }
    } catch (error) {
      this.initConversations(this.state.chatToken, false);
    }
  }

  handleScroll = () => {
    if (this.chatContainerRef.current) {
      const { scrollTop } = this.chatContainerRef.current;
      if (scrollTop < 10) {
        this.loadPreviousMessages();
      }
      this.handleDateLabel();
    }
  };

  handleDateLabel = () => {
    if(this.chatContainerRef.current) {
      const children = Array.from(this.chatContainerRef.current.children);
      for (let i = 0; i < children.length; i++) {
        const rect = children[i].getBoundingClientRect();
        if (rect.top >= 0) {
          const message = this.state.chatDataList[i];
          const newDateLabel = this.formatDate(message.dateUpdated);
          if (newDateLabel !== this.state.dateLabel) {
            this.setState({ dateLabel: newDateLabel, isLoadingPrevMessages: false });
          }
          break;
        }
      }
    }
  }

  formatDate = (date: Date = new Date()) => {
    const now = moment();
    const messageDate = moment(date);
    if (now.isSame(messageDate, 'day')) {
      return 'Today';
    } else if (now.subtract(1, 'days').isSame(messageDate, 'day')) {
      return 'Yesterday';
    } else {
      return messageDate.format('MMMM D, YYYY');
    }
  };  

  loadPreviousMessages = async () => {
    this.setState({ isLoadingPrevMessages: true });
    const messagesContainer = this.chatContainerRef.current;
    if (messagesContainer) {
      const currentScrollHeight = messagesContainer.scrollHeight;
      if (!this.paginator || !this.paginator.hasPrevPage) {
        this.setState({ isLoadingPrevMessages: false });
        return;
      }
      try {
        const prevPage = await this.paginator.prevPage();
        const messageDataArray = await Promise.all(
          prevPage.items.map(async (message: MessageData) => {
            return await this.processMessage(message);
          })
        );
        this.paginator = prevPage;
        const uniqueMessages = this.removeDuplicateMessages([...messageDataArray, ...this.state.chatDataList]);
        this.setState({ chatDataList: uniqueMessages, imageModal: false, isLoadingPrevMessages: false }, () => {
          const newScrollHeight = messagesContainer.scrollHeight;
          messagesContainer.scrollTop = newScrollHeight - currentScrollHeight;
        });
      } catch (error) {
      }
    }
  };

  handleMessages = async (channel: Conversation) => {
    try {
      this.paginator = await channel.getMessages(15);
      const messageDataArray = await Promise.all(
        this.paginator.items.map(async (message: MessageData) => {
          return await this.processMessage(message);
        })
      );
      const uniqueMessages = this.removeDuplicateMessages(messageDataArray);
      this.setState({ 
        chatDataList: uniqueMessages, 
        imageModal: false, 
        isRefreshing: false, 
        isLoadingPrevMessages: false,
        isLoading: false
      }, this.scrollToBottom);
    } catch (error) {
      console.error('Error handling messages:', error);
    }
  };

  removeDuplicateMessages = (messages: ChatDataType[]): ChatDataType[] => {
    const messageMap = new Map<string, ChatDataType>();
    messages.forEach((msg) => {
      let messageDate= new Date(msg.dateUpdated)
      if (!messageMap.has(messageDate.toISOString())) {
        messageMap.set(messageDate.toISOString(), msg);
      }
    });
    return Array.from(messageMap.values());
  }

  handleMessageAdded = async (message: MessageData) => {
    try {
      let messagePayload = message as unknown as MessageTwoViewchat;
      if(this.lastMessageID !== messagePayload.state.sid) {
        this.lastMessageID = messagePayload.state.sid;
        const newMessageData = await this.processMessage(message);
        const uniqueMessages = this.removeDuplicateMessages([...this.state.chatDataList, newMessageData]);
        this.setState({
          chatDataList: uniqueMessages,
          imageModal: false,
          isLoading: false
        }, this.scrollToBottom);
      }
    } catch (error) {
      console.error("Error handling added message:", error);
    }
  };

  processMessage = async (message: MessageData) => {
    let messagePayload = message as unknown as MessageType;
    const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp',];
    const dateTxt = new Date(messagePayload.state.dateUpdated);
    const hours = dateTxt.getHours();
    const minutes = dateTxt.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    let mediaUrl = 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg';
    if (messagePayload.state.type === 'media') {
      mediaUrl = await messagePayload.state.media.getContentTemporaryUrl();
    }
    let mediaType = 'text';
    if(messagePayload.state.type === 'media') {
      if(messagePayload.state.attributes?.audio) {
        mediaType = 'audio';
      } else {
        if (imageTypes.includes(messagePayload?.state?.media?.state?.contentType)) {
          mediaType = 'image';
      } else {
        mediaType = 'file';
      }
      }
    }
    return {
      sender: 'receiver',
      message: messagePayload.state.body,
      time: formattedTime,
      imgUrl: messagePayload.state.type === 'media' ? mediaUrl : '',
      userId: messagePayload.author,
      type: mediaType,
      dateUpdated: messagePayload.state.dateUpdated,
      groupDateLabel: this.formatDate(messagePayload.state.dateUpdated),
      fileName: messagePayload?.state?.media?.state?.filename ? messagePayload?.state?.media?.state?.filename :'',
    };
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ accountIdInput: event?.target?.value ?? "2" });
  };

  handleMessageChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ message: event?.target?.value ?? "Hi" });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;

    const file = event.target.files[0];
    const fileType = file.type;

    const validation = validateUploadFile(file);
    /* istanbul ignore next */
    if (!validation.isValid) {
      toast.error(validation.errorMessage);
      return;
    }

    const fileData: SelectedFile = {
      fileName: file.name,
      fileType: file.type,
      file: file
    };

    if (fileType.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          selectedFile: {
            ...fileData,
            previewUrl: reader.result as string
            
          },
          imageModal: true,
          isImageUpload:true
        });
      };
      reader.readAsDataURL(file);
      toast.success("Image uploaded successfully!");
    } else if (
      fileType === 'application/pdf' ||
      fileType === 'application/msword' ||
      fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {

      this.setState({
        selectedFile: fileData,
        imageModal: true,
        isImageUpload:true
      });
      toast.success("Document uploaded successfully!");
    } else {
      toast.error("Unsupported file type");
    }

    event.target.value = ""; 
  };

  handleSendMessage = () => {
    this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
    this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
  };

  handleInsertImage = () => {
    const refrence = this.fileInputRef.current;
    if (refrence) {
      refrence.click();
    }
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = () => {
    this.setState({accountIdInput:this.state.accountIdInput, chatId:this.state.chatId})
    if (!this.state.accountIdInput || this.isStringNullOrBlank(this.state.accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(this.state.accountIdInput)],
        chat_id: Number(this.state.chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: number, message: string, imageUrl?: string) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("message[message]", message);
    if (imageUrl) {
      formData.append("message[attachments][]", (this.state.docRes as Blob));
      this.setState({ docRes: null })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/${chatId}/messages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: this.state.chatId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat,
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.handleUserProfileResponse( apiRequestCallId, responseJson )
   
    this.apiSucessCallBackCell(apiRequestCallId,responseJson)
    this.navigationPayload(message)

    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({
        chatData,
        muted,
      });
      this.updateReadMessages();
    }
    if (
      restApiDataCondition &&
      apiRequestCallId === this.sendMessageApiCallId
    ) {
      this.getChatDetails(this.state.token, this.state.chatId);
    }
  }

  failureCallBackViewChat = async (apiID: string, response: InvalidResponseTypePayLoad) => {
    let errorMessage = this.parseApiMessageErrorResponseViewChat(response);
    if (errorMessage === "Token has Expired") {
      localStorage.removeItem('token');
      localStorage.removeItem('authToken');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('email');
      window.location.href = '/Loginplanner';
    }
  }

  parseApiMessageErrorResponseViewChat = (responseJson: InvalidResponseTypePayLoad) => {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: ErrorPayloadTypePayLoad[] = responseJson.errors;

    let allerrors = '';
    errors.forEach((object: ErrorPayloadTypePayLoad) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
    });
    return allerrors;
  }

  navigationPayload = (message : Message) =>{
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { chatId } = chatData;
      this.setState({ chatId }, () =>
        this.getChatDetails(this.state.token, chatId)
      );
    }
  }

  sessionDataCell = (message : Message) =>{
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      if(messageData && messageData.meta){
        const accountId: number = messageData.meta.id;
        this.setState({ accountId });
      }
      if (token) this.setState({ token });
      this.refreshChatInterval = setInterval(
        () => this.getChatDetails(this.state.token, this.state.chatId),
        30000
      );
    }
  }

  apiSucessCallBackCell = async (apiRequestCallId : string,responseJson :{error : Array<string>,errors: Array<ErrorPayloadTypePayLoad>,local_conversations:Array<UserDataListType>} & {local_conversations:[{data:[{attributes:{account_id:number,name:string,image:string}}]}]}  & {conversation :{conversation_sid:string}}& {token : string}) =>{
    if (this.getUserListApiId === apiRequestCallId && responseJson && !responseJson.errors) {
      this.getUserListSuccessCallBack(responseJson);
    } 
    if (this.getConversationApiId === apiRequestCallId && responseJson) {
      this.setState({
        conversationSid: responseJson.conversation.conversation_sid
      }, () => {
        this.getChatToken();
      });
    }  
    if(this.getTokenId === apiRequestCallId && responseJson && !responseJson.errors){
      this.setState({ chatToken: responseJson.token}, () => {
          this.initConversations(responseJson.token, false);
      });
    } else if (responseJson && responseJson.errors){
        this.failureCallBackViewChat(apiRequestCallId, responseJson)
    }
  }

  getUserListSuccessCallBack = (responseJson:{local_conversations:Array<UserDataListType>}) => {
    if(responseJson.local_conversations.length > 0){
      this.setState({ userDataList: responseJson.local_conversations, isUserCheck : false }, () => {
        if(this.state.isSelectedDefaultUser) {
          this.setState({ 
            customerUserID: responseJson.local_conversations[0].data.attributes.account_id,                
            conversationSid: responseJson.local_conversations[0].data.attributes.conversation_sid,
            isLoading: true
          }, () => {
            this.getChatToken();
          });
        }
      })
    } else {
      this.setState({ isUserCheck: true });
    }
  }

  handleSenderChat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    this.setState({ senderChatText: input, selectedEmoji: this.state.selectedEmoji});
  };

  handleEmojiPicker = () =>{
    this.setState({isEmoji: !this.state.isEmoji});
  }

  handleEmojiSelect = (emoji: Emoji) => {
    this.setState({ senderChatText: this.state.senderChatText + emoji?.native, isEmoji: !this.state.isEmoji });
  }

  apiCallFunction = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "" } = data;
    const header = { 
        "Content-Type": "application/json",
        token: await localStorage.getItem("auhtToken")
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getChatToken = async () => {
    this.getTokenId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: "twilio_chat/get_twilio_token?type=planner&receiver_id=" + this.state.customerUserID
    });
  };

  getConversationChat = async () => {
    this.getConversationApiId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPlannerConversation+ this.state.customerUserID
    });
  };

  getChatDataList = async () => {
    this.getUserListApiId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getUserList
    });
  };

  getUserChatId = async () => {
    this.getUserChatApiId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getUserList
    });
  };

  sendMessage = async () => {
    if (!this.state.isImageUpload) {
      if (this.state.senderChatText.trim() !== "") {
        await this.conversationChannel.sendMessage(this.state.senderChatText.trim(), { read: true });
      }
      this.setState({ senderChatText: "" });
    } else {
      this.handleFileUpload();
    }
  }

  handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.sendMessage();
      }, 300);
    };
  }

  handleFileUpload = async () => {
    let formData: FormData = new FormData()
    formData.append('file', this.state.selectedFile?.file as unknown as Blob);
    const media = this.conversationChannel.prepareMessage();
    if (this.state.senderChatText.trim() !== "") {
      await media.setBody(this.state.senderChatText.trim()).addMedia(formData).setAttributes({ read: true }).build().send();
    } else {
      await media.addMedia(formData).setAttributes({ read: true }).build().send();
    }
    this.setState({ isImgUpload: false, isImageUpload : false, senderChatText: "" });
  };

  getLoginId = (getPlannerId: string) => {
    let loginId = this.state.loginId
    if (getPlannerId.split("_")[1] == loginId) {
      return true;
    } else {
      return false;
    }
  }

  onSelectUser = async (chat: UserDataListType, freshLaunch: boolean = false) => {
    const { name, image, account_id, conversation_sid, image_text } = chat.data.attributes;
    if(account_id !== this.state.customerUserID || freshLaunch || this.state.numberOfConversations) {
      this.setState({
        isSelectedDefaultUser: false,
        isRefreshing: true,
        chatDataList: [],
        userName: name,
        userImage: image,
        userImageText: image_text,
        customerUserID: account_id,
        isLoading: true,
        conversationSid: conversation_sid
      }, () => {
        this.initConversations(this.state.chatToken, true);
      });
    }
  }
  
  scrollToBottom() {
    this.handleDateLabel();
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }
  }

  startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
            this.audioChunks.push(event.data);
        };
        this.mediaRecorder.onstop = async () => {
        const duration = Date.now() - this.startTime;
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/m4a' });
        const fixedBlob = await fixWebmDuration(audioBlob, duration);
        const audioURL = URL.createObjectURL(fixedBlob);
        let formData: FormData = new FormData()
        formData.append('file', audioBlob as unknown as Blob);
        const media = this.conversationChannel.prepareMessage();
        await media.addMedia(formData).setAttributes({ audio: true, read: true }).build().send();
        this.setState({ audioURL });
          this.audioChunks = [];
        stream.getTracks().forEach((track) => track.stop());
      };
        this.mediaRecorder.start();
        this.startTime = Date.now();
        this.setState({ isRecording: true });
    } catch (err) {
      console.error('Error accessing microphone', err);
    }
};

  debounce = (func: () => void, wait: number) => {
    return () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func();
      }, wait);
    };
  };

  stopRecording = () => {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.setState({ isRecording: false });
    }
  };

  onPlayAudio = (audioUrl: string, keyIdx: number, isCurrentAudio: boolean) => {
    this.setState({
      currentAudioIndex: keyIdx,
      audioURL: audioUrl,
      currentTime: isCurrentAudio ? this.state.currentTime : 0
    }, this.handlePlayPause);
  }

  handlePlayPause = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.audioRef.current?.pause();
    } else {
      this.audioRef.current?.play();
    }
    this.setState({ isPlaying: !isPlaying });
  };

  handleTimeUpdate = () => {
    if (this.audioRef.current) {
      this.setState({
        currentTime: this.audioRef.current.currentTime,
        duration: this.audioRef.current.duration,
      });
    }
  };

  onEndedAudio = () => {
    this.setState({ isPlaying: false });
  }

  handleClose = () => {
    this.setState({isImageModal : false,selectImgUrl :"", selectImageName:''})
  };

  openModalCell = (urlData : string) => {
    this.setState({isImageModal : true,selectImgUrl: urlData})
  }

  sliceTextString = (text:string) => {
    if (text.length < 15){
      return text
    }
    else {
      let textSlice = text.slice(0,15)+"..."
      return textSlice
    }
  };

  goToProfilePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannerProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handleDropdown = () => {
    if (this.state.dropdownVisiblity == 'state-dropdown') {
      this.setState({ dropdownVisiblity: 'state-dropdown active' })
    } else {
      this.setState({ dropdownVisiblity: 'state-dropdown' })
    }
  }

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  };

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getProfile
    });
  };

  handleModalOpenBaseOnType = (type:any,urlData : string, fileName:string)=>{
    if(type === '' || type === null){
      return
    }
    type==='file'? null :  this.setState({isImageModal : true,selectImgUrl: urlData, selectImageName:fileName})
  }

  // Customizable Area End
}
