import { Box } from '@material-ui/core'
import React from 'react'

interface FileShowingWithNameProps {
    url: string;
    fileName: string;
    textColor?:string;
}

function FileShowingWithName(
    { url, fileName, textColor= 'black'}:FileShowingWithNameProps
) {
    return (
        <a style={{
            color: textColor,
        }} href={url} target="_blank" rel="noopener noreferrer">
            <Box display="flex" flexDirection="column" alignItems="center" style={{ padding: '4px' }}>
                <p>📁 <span style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}>{fileName}</span></p>
            </Box>
        </a>
    )
}

export default FileShowingWithName
