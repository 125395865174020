import React from "react";
// Customizable Area Start
import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { back_icon } from "./assets";

interface Props {
    id: string;
    navigation: any;
    instance: any;
}
interface S { }
interface SS { }
// Customizable Area End



export default class SelectedBank extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { instance } = this.props
        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
                {!instance.state.isArabic ?
                    <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box d-flex align-center">

                        <div className="bank-account-wrapper">
                            <img className="back-icon" data-test-id="backIcon" onClick={() => { instance.setState({ componentPage: "banks" }) }} src={back_icon} alt="back-icon" />
                            <h4>Connect your Account</h4>
                            <div className="bank-name">
                        <div className="bank-image">
                                <img
                                    className="active-img"
                                    src={instance.state.selectedBankLogo}
                                    alt="bank-account"
                                    width="25"
                                    height="25"
                                />
                                </div>&nbsp;&nbsp;
                                <p>{instance.state.selectedBankName}</p>
                            </div>

                           <div>
                            <p>Myne uses <b>Lean</b> to connect your acount.<br/>
                            Start by selecting the bank accounts you want<br />
                            to connect and go to the bank site to sign in.<br />
                            Then we'll return you here and bring in your<br />
                            transactions.</p>
                            {/* <div className="form-group">
                            <input type="checkbox" /> <label>Account details - Access your account<br />
                            details to analyse your cash flow</label>
<br />
                            <input type="checkbox" /> Transaction data - Retrive transaction data<br />
                            to provide smart insights
                            </div> */}
                            <div>Give us permission to</div>
                            <div>
                            <label className="checkbox">
                                <input type="checkbox" name="color" checked={instance.state.accountsConfirmationForLean} />
                                <span className="checkmark"  data-test-id="accountConfirmationCheckBox" onClick={()=>instance.handleAccountPermission()}></span>
                            </label> Account details - Access your account<br />
                                                details to analyse your cash flow 
                            </div>
                            <br />
                            <div>
                            <label className="checkbox">
                            <input type="checkbox" name="color" checked={instance.state.transactionsConfirmationForLean} />
                            <span className="checkmark" data-test-id="transactionsConfirmationCheckBox" onClick={()=>instance.handleTransactionPermission()}></span>
                            </label>Transacation data - Retrive transaction data <br />to provide
                                            smart insights.
                            </div>
                           </div>
                           <div className="buttons-heard-2">
                            <div className="button bank-button">
                                <button className="bankBtns" data-test-id="leanConnectionBtn" onClick={()=>{instance.leanConnection()}}>Continue</button>
                            </div>
                            <ul className="list-dot">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li className="current"></li>
                            </ul>
                            </div>
                        </div><div id="lean-link"></div>

                    </div>
                </div>
            :
            <div className="bank-integraion-wrapper" dir="rtl">
            <div className="bank-integration-box d-flex align-center">

                <div className="bank-account-wrapper">
                    <div className="back-arrow-div">
                        <img className="back-icon" onClick={() => { instance.setState({ componentPage: "banks" }) }} src={back_icon} alt="back-icon" />
                    <h4>قم بتوصيل حسابك</h4>
                    </div>
                    <div className="bank-name">
                <div className="bank-image">
                        <img
                            className="active-img"
                            src={instance.state.selectedBankLogo}
                            alt="bank-account"
                            width="25"
                            height="25"
                        />
                        </div>&nbsp;&nbsp;
                        <p>{instance.state.selectedBankName}</p>
                    </div>

                   <div className="bank-content ">
                    <p>يستخدم ماين <b>نحيف</b> لربط حسابك.<br/>
                    ابدأ بتحديد الحسابات المصرفية التي تريدها<br />
                    للاتصال والانتقال إلى موقع البنك لتسجيل الدخول.<br />
                    ثم سنعيدك إلى هنا ونحضر لك<br />
                    المعاملات.</p>
                   
                    <div>اسمح لنا بذلك</div>
                    <div className="chekbox-arabic">
                    <label className="checkbox">
                        <input type="checkbox" name="color" />
                        <span className="checkmark" data-test-id="accountConfirmationCheckBox" onClick={()=>instance.setState({accountsConfirmationForLean:"accounts"})}></span>
                    </label>تفاصيل الحساب - الوصول إلى حسابك<br />
                    تفاصيل لتحليل التدفق النقدي الخاص بك
                    </div>
                    <br />
                    <div className="chekbox-arabic1">
                    <label className="checkbox">
                    <input type="checkbox" name="color" />
                    <span className="checkmark" data-test-id="transactionsConfirmationCheckBox" onClick={()=>instance.setState({transactionsConfirmationForLean:"transactions"})}></span>
                    </label>بيانات المعاملات - استرجاع بيانات المعاملات <br />ليزود
                    رؤى ذكية.
                   </div>
                    </div>
                   <div className="buttons-heard-5">
                    <div className="button bank-button">
                        <button className="bankBtns5" data-test-id="leanConnectionBtn" onClick={()=>{instance.leanConnection()}}>يكمل</button>
                    </div>
                    <ul className="list-dot">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="current"></li>
                    </ul>
                    </div>
                </div><div id="lean-link"></div>

            </div>
        </div>    
            }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
