import React from "react";
// Customizable Area Start
import "./style.css";
import {

  progressImage,
  logoImg

} from "./assets";


// Customizable Area End
import QuestionbankController, {
  Props,
} from "./QuestionbankController";
import { Box } from "@material-ui/core";
export default class Questionbank extends QuestionbankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div className="mainBank-wrapper">       
               
        <div className="bank-integraion-wrapper">
            <div className="bank-integration-box hgt-res-500 "  style={{
              display: "flex",
              flexDirection: "column"
            }}>
            <Box style={styles.container}>
              <img style={{ width: '119px', height: '32px',marginRight:"auto" }} src={logoImg} />
            </Box>
            <div className="progress-img-content" style={{
               display: "flex",
               justifyContent: "center",
               marginTop:"22px"
            }} >
              <div>
              <div>
              <img  style={styles.image} src={progressImage} /><br />
              </div>
                <h4 style={styles.text2}>We're curating the </h4>
                <h4  style={styles.text2}>
                best results for you now!
                </h4>
                <p  style={styles.text1}>just one moment.</p>
              </div>
              </div>
            </div>
        </div>
       
   
    </div>


    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '18px',
    paddingRight: '22px',
    marginLeft:"auto"
  },
  text1:{
    fontFamily:"Poppins",
    opacity:"70%",
    fontSize:"20px",
    marginTop:"0px"
  },
  text2: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 700,
    display: "block",
    marginBottom: "0px",
    marginTop: "0px"
  },
  image: {
    maxHeight: "262px",
    width: "170px",
    objectFit: "cover",
    marginBottom: "24px"
  } as React.CSSProperties
}
// Customizable Area End
