Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getClientsEndpoint = "bx_block_proposal_generation/service_proposals/get_clients";
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.LoginsubmitGetReq = "GET";
exports.validationApiMethodPUTType='PUT'
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardGetUrlPath = "/dashboard/dashboards";
exports.truncateDropdown = "dropdown is truncating";
exports.labelBodyTextNew = "neww dashboard Body";
exports.LoginsubmitGet = "GET";
exports.exampleAPiMethodtoken = "bx_block_languagesupport/languages/key_value";
exports.postApiMethod = "POST"
exports.validationApiMethodType='GET'
exports.getTextHere='GET_HERE'
exports.dashboarApiMethodPutType = "PUT";
exports.dashboarDeleteData = "DELETE DATA";
exports.dashboarUpdateData = "UPDATE DATA";
exports.postApiMethodCall = "POST"
exports.deleteApiMethodCall = "DELETE"
exports.languageListDashboard = {
  English: {
    Dashboard: "Dashboard",
    TotalNetworth: "Total Networth",
    Assets: "Assets",
    Debts: "Debts",
    Investable_Assets: "Investable_Assets",
    Cash: "Cash",
    month: "month",
    year: "year",
    AssetsOverview: "Assets Overview",
    DebtsOverview: "Debts Overview",
    InvestableAssetsOverview: "Investable Assets Overview",
    CashOverview: "Cash Overview",
    Balance: "Balance",
    viewdetails: "view details",
    AssetsNew: "Assets",
    BankAccounts: "Bank Accounts",
    CashNew: "Cash",
    Pensions: "Pensions",
    PortableAssets: "Portable Assets",
    Properties: "Properties",
    Shares: "Shares",
    countries: "countries",
    in: "in",
    TypesofFunds: "Types of Funds",
    Type: "Type",
    Country: "Country",
    Property: "Property",
    Portable: "Portable"
  },
  Arabic: {
    Portable: "محمول",
    Property: "ملكية",
    Country: "النوع",
    Type: "البلد",
    TypesofFunds: "Types of Funds",
    in: "in",
    countries: "countries",
    Shares: "تشارك",
    Properties: "ملكيات",
    PortableAssets: "الأصول المحمولة",
    Pensions: "المعاشات التقاعدية",
    CashNew: "نقدي",
    BankAccounts: "حسابات بنكية",
    AssetsNew: "أصول",
    viewdetails: "عرض التفاصيل",
    Balance: "توازن",
    CashOverview: "نظرة عامة على النقد",
    InvestableAssetsOverview: "نظرة عامة على الأصول القابلة للاستثمار",
    DebtsOverview: "نظرة عامة على الديون",
    AssetsOverview: "نظرة عامة على الأصول",
    year: "سنة",
    month: "شهر",
    Cash: "نقدي",
    Investable_Assets: "الأصول القابلة للاستثمار",
    Debts: "الديون",
    Assets: "أصول",
    TotalNetworth: "إجمالي القيمة الصافية",
    Dashboard: "لوحة القيادة",
  }
}
exports.getClientOverviewMethodUrl = "GET"
exports.getClientOverviewUrl = "bx_block_dashboard/planner_dashboard_home/planner_service_details"
exports.leanConnection = "https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js"
exports.leanConnectionAuthToken = "2b90d112-f53f-4e9c-af1d-123a520d31c2";
exports.validationApiMethodPOSTType='POST'
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardGetUrlPath = "/dashboard/dashboards";
exports.truncateDropdown = "dropdown is truncating";
exports.labelBodyTextNew = "neww dashboard Body";
exports.LoginsubmitGet = "GET";
exports.exampleAPiMethodtoken = "bx_block_languagesupport/languages/key_value";
exports.postApiMethod = "POST"
exports.validationApiMethodType='GET'
exports.dashboarUpdateData = "UPDATE DATA";
exports.getTextHere='GET_HERE'
exports.dashboarApiMethodPutType = "PUT";
exports.dashboarDeleteData = "DELETE DATA";
exports.languageListNewDashboard = {
  English: {
    country:"Country",
    currency:"Currency",
    performance: "Performance",
    '6M':"6M",
    '1Y':"1Y",
    YTD: "YTD",
    allocation:"Allocation",
    score:"Diversification Score",
    fundType:"Types of Assets",
    dashboard:"Dashboard",
    countryExposure:"Country Exposure",
    currencyExposure:"Currency Exposure",
    totalInvestment: "Total Investment",
    totalAssets: "Total Assets",
    totalGain: "Total gain",
    monthlyGain: "Monthly gain",
    yearlyGain: "Yearly gain",
    assetCount:"Asset count",
    scoreTooltip: "A rating from 0-100 showing how well diversified you are.",
  },
  Arabic: {
    country: "البلد",
    currency: "العملة",
    performance: "الأداء",
    '6M': "6 أشهر",
    '1Y': "1 سنة",
    YTD: "منذ بداية العام",
    allocation: "التخصيص",
    score: "درجة التنويع",
    fundType: "أنواع الأصول",
    dashboard: "لوحة التحكم",
    countryExposure: "التعرض للدول",
    currencyExposure: "التعرض للعملات",
    totalInvestment: "إجمالي الاستثمار",
    totalGain: "إجمالي الربح",
    monthlyGain: "مكاسب شهرية",
    totalAssets:"إجمالي الأصول",
    yearlyGain: "مكسب سنوي",
    assetCount: "عدد الأصول",
    scoreTooltip: "تقييم من 0 إلى 100 يُظهر مدى تنوعك."
  }
}
exports.languageListDashboard = {
  English: {
    SelectWallet:"Please select a wallet before moving forward",
    AssetsOverview:"Assets Overview",
    Balance: "Balance",
    ViewDetails: "view details",
    StockPositions: "Stock Positions",
    StockWalletExchange: "Stock Wallets & Exchanges",
    AddStockTicker: "Add Stock Ticker",
    UpdatedStockError:"Unable to fetch updated stocks data",
    Networth: "Networth",
    EnterQuantity: "Enter Quantity",
    Confirm: "Confirm",
    ChooseStock: "Choose stock",
    AddCrypto: "Add Crypto",
    PositionSuccess: "Positions added successfully!",
    CryptoPositions: "Crypto Positions",
    CryptoWalletExchange: "Crypto Wallet & Exchanges",
    TotalNetworth: "Total Networth",
    Assets: "Assets",
    AddCryptoTicker: "Add Crypto Ticker",
    AddStock: "Add Stock",
    NoAsset:"No asset available.",
    ChooseCoin:"Choose coin",
    TickerQuentityError:"Ticker quantity should be a number greater than zero",
    NetworthGraphNotLoaded:"Networth graph has not loaded. Please try again later",
    Next: "Next",
    StockError:"Select any one stock",
    ViewMyAssets:'View my assets',

  },
  Arabic: {
    TotalNetworth: "إجمالي صافي الثروة",
    Networth: "صافي الثروة",
    Assets: "الأصول",
    ViewDetails: "عرض التفاصيل",
    ViewMyAssets:'عرض الأصول الخاصة بي',
    Balance: "الرصيد",
    StockWalletExchange: "محافظ الأوراق المالية والبورصات",
    AddStock: "إضافة مخزون",
    Next: "التالي",
    AssetsOverview: "نظرة عامة على الأصول",
    StockPositions: "مواقف الأسهم",
    EnterQuantity: "أدخل الكمية",
    AddStockTicker: "إضافة شريط الأسهم",
    Confirm: "يتأكد",
    CryptoWalletExchange: "محفظة التشفير والبورصات",
    PositionSuccess: "تمت إضافة المناصب بنجاح!",
    ChooseStock: "اختر المخزون",
    ChooseCoin:"اختر العملة",
    CryptoPositions: "مواقف التشفير",
    AddCrypto: "أضف التشفير",
    TickerQuentityError:"يجب أن تكون كمية المؤشر رقمًا أكبر من الصفر",
    NoAsset:"لا يوجد أصول متاحة.",
    AddCryptoTicker:"إضافة شريط التشفير",
    UpdatedStockError:"غير قادر على جلب بيانات الأسهم المحدثة",
    StockError:"حدد أي سهم واحد",
    NetworthGraphNotLoaded:"لم يتم تحميل الرسم البياني لـ Networth. يرجى المحاولة مرة أخرى لاحقا",
    SelectWallet:"يرجى تحديد المحفظة قبل المضي قدما",
  }
}
exports.languageListStocks = {
  English: {
    Balance: "Balance",
    Stocks: "Stocks",
    Assets: "Assets",
    Asset: "Asset",
    plusStock: "plusStock",
    minusStock: "minusStock",
  },
  Arabic: {
    Balance: "الرصيد",
    Stocks: "الأسهم",
    Assets: "الأصول",
    Asset: "الأصل",
    plusStock: "إضافة سهم",
    minusStock: "طرح سهم"
  }
}

exports.languageListCrypto = {
  English: {
    Balance: "Balance",
    Stocks: "Stocks",
    Assets: "Assets",
    Asset: "Asset",
    CryptoCurrency:"Crypto Currency"
  },
  Arabic: {
    Balance: "الرصيد",
    Stocks: "الأسهم",
    Assets: "الأصول",
    Asset: "الأصل",
    CryptoCurrency:"العملة المشفرة"
  }
}

// Customizable Area End