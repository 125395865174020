import {
  Box,
  Button,
  createTheme,
  Popover,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  filter,
  selectedCircle,
  selectedRectangle,
  unselectedCircle,
  unselectedRectangle,
} from "../../blocks/dashboard/src/assets";
import {
  arrowDown,
  arrowUp,
} from "../../blocks/formapprovalworkflow/src/assets";
import { Form, Formik } from "formik";
import { IFilter } from "../../blocks/dashboard/src/DashboardController";

const ClientFilter: React.FC<{
  filters: IFilter;
  handleFilter: (filters: IFilter) => void;
  activeTab: string;
  focustList: {
    id: string;
    attributes: {
      name: string;
    };
  }[];
}> = ({ handleFilter, filters, activeTab, focustList }) => {
  const [
    anchorToolTipEl,
    setAnchorToolTipEl,
  ] = useState<HTMLSpanElement | null>(null);

  const [requestedStatusOpen, setRequestedStatusOpen] = useState<boolean>(
    false
  );
  const [serviceStatusOpen, setServiceStatusOpen] = useState<boolean>(false);
  const [selectedServiceOpen, setSelectedServiceOpen] = useState<boolean>(
    false
  );

  const [dateOpen, setDateOpen] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<IFilter>(filters);

  useEffect(() => {
    setInitialValues(filters);
  }, [filters]);

  const requestedStatusOption = ["Accepted", "Pending", "Rejected"];
  const serviceStatusOption = [
    "Services sent",
    "Call Requested",
    "Pending Services",
    "Awaiting Response",
    "Rejected Request",
  ];
  const dateOptions = ["Last 7 days", "Last 30 days", "Last 90 days"];

  const handleSubmit = (values: IFilter) => {
    setInitialValues(values);
    setAnchorToolTipEl(null);
    handleFilter(values);
  };
  let theme = createTheme({
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
  });
  theme = createTheme(theme, {
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
    overrides: {
      MuiPopover: {
        root: {
          zIndex: 999,
          borderRadius: "14px",
          "&.simple-tooltip .MuiPaper-root": {
            boxShadow: "none",
            fontSize: 12,
            letterSpacing: "0.4px",
            backgroundColor: "#395d6b !important",
            borderRadius: "14px",
            marginTop: "22px",
          },
          "& .MuiPaper-rounded": {
            borderRadius: "0px",
            boxShadow: "none",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={webstyles.filterTitle}
        onClick={(event) => {
          setAnchorToolTipEl(event.currentTarget);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "Pointer",
          }}
        >
          <Typography component="p">Filter</Typography>
          <div>
            <img src={filter} alt="filter" style={{ width: "20px" }} />
          </div>
        </div>
      </div>
      <Popover
        open={Boolean(anchorToolTipEl)}
        elevation={0}
        anchorEl={anchorToolTipEl}
        onClose={() => setAnchorToolTipEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="simple-tooltip"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form translate={"em"}>
              <Box style={webstyles.actionContainer}>
                <Box style={webstyles.filterContainer}>
                  <Typography style={webstyles.filterHeading}>
                    Filters
                  </Typography>
                  <Typography
                    style={webstyles.clearAllHeading}
                    onClick={() => {
                      setFieldValue("requestedStatus", []);
                      setFieldValue("days", "");
                      setFieldValue("selectedServices", []);
                      setFieldValue("serviceStatus", []);
                    }}
                  >
                    Clear all
                  </Typography>
                </Box>
                <Box>
                  {activeTab === "pending" && (
                    <>
                      <Box style={webstyles.filterDataContainer}>
                        <Box style={webstyles.filterHeadingContainer}>
                          <Typography style={webstyles.filterOptionTitle}>
                            Requested Status
                          </Typography>
                          <div
                            onClick={() =>
                              setRequestedStatusOpen(!requestedStatusOpen)
                            }
                            style={webstyles.arrowStyle}
                          >
                            {!requestedStatusOpen ? (
                              <img src={arrowDown} alt="" />
                            ) : (
                              <img src={arrowUp} alt="" />
                            )}
                          </div>
                        </Box>
                        {requestedStatusOpen && (
                          <Box
                            style={{
                              marginTop: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            {requestedStatusOption.map((option) => {
                              return (
                                <Box
                                  key={option}
                                  style={webstyles.optionContainer}
                                >
                                  <img
                                    src={
                                      values.requestedStatus.findIndex(
                                        (value) => value === option
                                      ) !== -1
                                        ? selectedRectangle
                                        : unselectedRectangle
                                    }
                                    style={{
                                      borderRadius: "0px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                    onClick={() => {
                                      let tempStatus = values.requestedStatus;
                                      let index = tempStatus.findIndex(
                                        (value) => value === option
                                      );

                                      if (index === -1) {
                                        tempStatus.push(option);
                                      } else {
                                        tempStatus.splice(index, 1);
                                      }

                                      setFieldValue(
                                        "requestedStatus",
                                        tempStatus
                                      );
                                    }}
                                  />

                                  <Typography style={webstyles.optionText}>
                                    {option}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                      <div style={webstyles.borderStyle} />
                      <Box style={webstyles.filterDataContainer}>
                        <Box style={webstyles.filterHeadingContainer}>
                          <Typography style={webstyles.filterOptionTitle}>
                            Service Status
                          </Typography>
                          <div
                            onClick={() =>
                              setServiceStatusOpen(!serviceStatusOpen)
                            }
                            style={webstyles.arrowStyle}
                          >
                            {!serviceStatusOpen ? (
                              <img src={arrowDown} alt="" />
                            ) : (
                              <img src={arrowUp} alt="" />
                            )}
                          </div>
                        </Box>
                        {serviceStatusOpen && (
                          <Box
                            style={{
                              marginTop: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            {serviceStatusOption.map((option) => {
                              return (
                                <Box
                                  key={option}
                                  style={webstyles.optionContainer}
                                >
                                  <img
                                    src={
                                      values.serviceStatus.findIndex(
                                        (value) => value === option
                                      ) !== -1
                                        ? selectedRectangle
                                        : unselectedRectangle
                                    }
                                    style={{
                                      borderRadius: "0px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                    onClick={() => {
                                      let tempStatus = values.serviceStatus;
                                      let index = tempStatus.findIndex(
                                        (value) => value === option
                                      );

                                      if (index === -1) {
                                        tempStatus.push(option);
                                      } else {
                                        tempStatus.splice(index, 1);
                                      }

                                      setFieldValue(
                                        "serviceStatus",
                                        tempStatus
                                      );
                                    }}
                                  />

                                  <Typography style={webstyles.optionText}>
                                    {option}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                      <div style={webstyles.borderStyle} />
                    </>
                  )}
                  {activeTab !== "pending" && (
                    <>
                      <Box style={webstyles.filterDataContainer}>
                        <Box style={webstyles.filterHeadingContainer}>
                          <Typography style={webstyles.filterOptionTitle}>
                            Services
                          </Typography>
                          <div
                            onClick={() =>
                              setSelectedServiceOpen(!selectedServiceOpen)
                            }
                            style={webstyles.arrowStyle}
                          >
                            {!selectedServiceOpen ? (
                              <img src={arrowDown} alt="" />
                            ) : (
                              <img src={arrowUp} alt="" />
                            )}
                          </div>
                        </Box>
                        {selectedServiceOpen && (
                          <Box
                            style={{
                              marginTop: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            {focustList.map((option) => {
                              return (
                                <Box
                                  key={option.id}
                                  style={webstyles.optionContainer}
                                >
                                  <img
                                    src={
                                      values.selectedServices.findIndex(
                                        (value) => value === option.id
                                      ) !== -1
                                        ? selectedRectangle
                                        : unselectedRectangle
                                    }
                                    style={{
                                      borderRadius: "0px",
                                      height: "16px",
                                      width: "16px",
                                    }}
                                    onClick={() => {
                                      let tempStatus = values.selectedServices;
                                      let index = tempStatus.findIndex(
                                        (value) => value === option.id
                                      );

                                      if (index === -1) {
                                        tempStatus.push(option.id);
                                      } else {
                                        tempStatus.splice(index, 1);
                                      }

                                      setFieldValue(
                                        "selectedServices",
                                        tempStatus
                                      );
                                    }}
                                  />

                                  <Typography style={webstyles.optionText}>
                                    {option.attributes.name}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                      <div style={webstyles.borderStyle} />
                    </>
                  )}

                  <Box style={webstyles.filterDataContainer}>
                    <Box style={webstyles.filterHeadingContainer}>
                      <Typography style={webstyles.filterOptionTitle}>
                        Date
                      </Typography>
                      <div
                        onClick={() => setDateOpen(!dateOpen)}
                        style={webstyles.arrowStyle}
                      >
                        {!dateOpen ? (
                          <img src={arrowDown} alt="" />
                        ) : (
                          <img src={arrowUp} alt="" />
                        )}
                      </div>
                    </Box>
                    {dateOpen && (
                      <Box
                        style={{
                          marginTop: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        {dateOptions.map((option) => {
                          return (
                            <Box key={option} style={webstyles.optionContainer}>
                              <img
                                src={
                                  values.days === option
                                    ? selectedCircle
                                    : unselectedCircle
                                }
                                style={{
                                  borderRadius: "0px",
                                  height: "16px",
                                  width: "16px",
                                }}
                                onClick={() => {
                                  setFieldValue("days", option);
                                }}
                              />

                              <Typography style={webstyles.optionText}>
                                {option}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                  <div style={webstyles.borderStyle} />
                  <Box
                    style={{
                      ...webstyles.filterDataContainer,
                      paddingBottom: "0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                      }}
                    >
                      <Button
                        style={webstyles.cancelBtn}
                        onClick={() => {
                          setAnchorToolTipEl(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button style={webstyles.saveBtn} type="submit">
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Popover>
    </ThemeProvider>
  );
};
type IStyle = { [key: string]: React.CSSProperties };

const webstyles: IStyle = {
  filterTitle: {
    backgroundColor: "#3C3C50",
    appearance: "none",
    color: "white",
    borderRadius: "4px",
    border: "1px solid #f3ecec6e",
    padding: "8px 12px",
    minWidth: "106px",
  },
  actionContainer: {
    backgroundColor: "#395d6b",
    borderRadius: "14px",
    minWidth: "280px",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "24px",
    paddingBottom: "12px",
    paddingLeft: "18px",
    paddingRight: "18px",
  },
  filterHeading: {
    fontSize: "14px",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  clearAllHeading: {
    fontSize: "12px",
    color: "#8f99a3",
    fontFamily: "Poppins",
    cursor: "Pointer",
  },
  filterOptionTitle: {
    fontSize: "14px",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  filterHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterDataContainer: {
    padding: "18px",
  },
  cancelBtn: {
    color: "#c9f9f6",
    borderRadius: "8px",
    border: "1px solid #c9f9f6",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "none",
    padding: "4px 24px",
  },
  saveBtn: {
    color: "#3c3c50",
    borderRadius: "8px",
    border: "1px solid #c9f9f6",
    fontWeight: 600,
    fontSize: "16px",
    backgroundColor: "#c9f9f6",
    textTransform: "none",
    padding: "4px 24px",
  },
  optionText: {
    fontSize: "12px",
    color: "#fff",
    marginLeft: "12px",
    marginTop: "-2px",
  },
  optionContainer: {
    display: "flex",
    marginBottom: "10px",
  },
  borderStyle: {
    borderBottom: "1px solid #d8d8d8",
    opacity: "20%",
  },
  arrowStyle: {
    cursor: "pointer",
  },
};
export default ClientFilter;
