import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import React from "react";
import documentDownload from "../../../components/src/DownloadDocument.web";
import toast from "react-hot-toast";
import { getStorageData } from "framework/src/Utilities";

interface PropertyFilterObject {
  id: string,
  type: string,
  attributes: {
    id: number,
    parent_id: number,
    name: string,
    image: string
  }
}
interface DocumentObject {
  id: number,
  url: string,
  filename: string,
  content_type: string
}
interface PropertyAssetObject {
  id: string,
  type: string,
  attributes: {
    name: string,
    price_currency: string,
    mortgage_currency: string,
    location: string,
    apartment_number: string,
    floor_number: number,
    bed_rooms: number,
    bath_rooms: number,
    account_id: number,
    market_value: null,
    purchase_date: null,
    martagage_loan: null,
    market_date: null,
    country: number,
    price: string,
    mortgage: string,
    area_size: number,
    converted_price: string,
    balance: string,
    converted_currency_symbol: string,
    converted_currency: string,
    converted_mortgage: string,
    mortgage_currency_symbol: string,
    images: DocumentObject[],
    title_deeds: DocumentObject[],
    status: {
      id: number,
      name: string
    },
    sub_category: {
      id: number,
      name: string,
      image: DocumentObject
    },
    property_mortgage: null
  }
}

interface PortableAssetObject {
  id: string;
  type: string;
  attributes: {
    name: string;
    price_currency: string;
    loan_currency: string | null;
    model: string;
    account_id: number;
    country: string;
    market_date: string;
    purchase_date: string;
    market_value: string;
    loan_available: string | null;
    loan_status: boolean;
    price: string;
    converted_price: string;
    balance: string;
    converted_loan: number | string;
    converted_currency_symbol: string;
    converted_currency: string;
    profit: number;
    profit_percentage: number;
    converted_market_value: string;
    operand: string;
    images: string | null;
    receipts: string | null;
    warrants: string | null;
    sub_category: {
      id: number;
      name: string;
      image: {
        url: string;
        filename: string;
        content_type: string;
      };
    };
    loan_detail: {
      id: number;
      amount: number;
      term_years: string;
      term_months: string;
      annual_interest_rate: number;
      loan_monthly_payments: number;
      start_date: string;
      first_loan_payment_date: string;
      portable_asset_id: number;
      created_at: string;
      updated_at: string;
    } | null;
    liability: {
      id: number;
      name: string;
      amount: number;
      term: string;
      months: number;
      annual_interest_rate: number;
      monthly_payments: number;
      start_date: string;
      first_payments: number;
      account_id: number;
      form_type: string;
      property_id: string | null;
      currency_type: string;
      converted_currency_type: string;
      converted_amount: string;
      converted_monthly_payments: number;
      portable_asset_id: number;
      created_at: string;
      updated_at: string;
    } | null;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: any;
  currencyList: any;
  portableAssetData: PortableAssetObject[];
  isUploadDocumentOpen: boolean;
  isGalleryModalOpen: boolean;
  selectedGallery: any;
  receipt: any;
  certificate: any;
  currentCardId: any;
  isDocOpen: boolean;
  docUrl: any;
  propertyAssetData: PropertyAssetObject[];
  filteredPropertyAssetData: PropertyAssetObject[];
  AllPortableAssetData: PortableAssetObject[];
  isVerticalPopoverOpen: boolean;
  isDeleteConfimationModalOpen: boolean;
  cashAssetData: any;
  shareAssetData: any;
  pensionAssetData: any;
  assetSatyle:Object;
  propertyFiltersData: PropertyFilterObject[];
  portableFiltersData: PropertyFilterObject[];
  selectedPropertyFilterId: number;
  selectedPortableFilterId: number;
  language: string,
  direction: string,
  anchorEl: HTMLAnchorElement | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssetOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrencyListCalId: any;
  ChangeCurrencyCallId: any;
  getPortableAssetCallId: any;
  updatePortableAssetCallId: any;
  deletePortableAssetCallId: any;
  getPropertyAssetCallId: any;
  deletePropertyAssetCallId: any;
  getCashAssetCallId: any;
  getPensionAssetCallId: any;
  getShareAssetCallId: any;
  deleteCashAssetCallId: any;
  deleteShareAssetCallId: any;
  deletePensionAssetCallId: any;
  receiptBtnRef: any;
  certificateBtnRef: any;
  docModalRef: any;
  galleryModalRef: any;
  uploadDocModalRef: any;
  getPropertyAssetFilterCallId:string="";
  getPortableAssetFilterCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      assetSatyle:{
        width: "60px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: localStorage.getItem('token'),
      currencyList: [],
      portableAssetData: [],
      isUploadDocumentOpen: false,
      isGalleryModalOpen: false,
      selectedGallery: [],
      receipt: null,
      certificate: null,
      currentCardId: '',
      isDocOpen: false,
      docUrl: '',
      propertyAssetData: [],
      filteredPropertyAssetData: [],
      AllPortableAssetData: [],
      isVerticalPopoverOpen: false,
      isDeleteConfimationModalOpen: false,
      cashAssetData: [],
      pensionAssetData: [],
      shareAssetData: [],
      propertyFiltersData: [],
      portableFiltersData: [],
      selectedPropertyFilterId: 0,
      selectedPortableFilterId: 0,
      language: "English",
      direction: "ltr",
      anchorEl: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receiptBtnRef = React.createRef();
    this.certificateBtnRef = React.createRef();
    this.docModalRef = React.createRef();
    this.galleryModalRef = React.createRef();
    this.uploadDocModalRef = React.createRef();
    // Customizable Area End
  }

  async componentDidMount() {
    this.refreshData();
    this.loadCurrencyData();

    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});

    if (this.state.isDocOpen || this.state.isGalleryModalOpen || this.state.isUploadDocumentOpen) {
      document.addEventListener('click', this.handleOutsideClick);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.isDocOpen !== this.state.isDocOpen || prevState.isGalleryModalOpen !== this.state.isGalleryModalOpen || prevState.isUploadDocumentOpen !== this.state.isUploadDocumentOpen) {
      if (this.state.isDocOpen || this.state.isGalleryModalOpen || this.state.isUploadDocumentOpen) {
        document.addEventListener('click', this.handleOutsideClick);
      } else {
        document.removeEventListener('click', this.handleOutsideClick);
      }
    }
  }

  componentWillUnmount(): any {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    this.handleCurrencyData(dataMessage, message);
    this.handlePortableAssetData(dataMessage, message);
    this.handlePropertyAssetData(dataMessage, message);
    this.handleCashAssetData(dataMessage, message);
    this.handleShareAssetData(dataMessage, message);
    this.handlePensionAssetData(dataMessage, message);
    this.handlePropertyFiltersData(dataMessage, message);
    this.handlePortableFiltersData(dataMessage, message);
    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async receiveHandler(responseJson: any, receiveCallback: any) {
    if (responseJson) {
      receiveCallback && receiveCallback();
    }
  }

  getString = (keyvalue: string) => {
    return configJSON.languageList[this.state.language][keyvalue]
  }

  refreshData = () => {
    const windowPath = window.location.pathname;
    switch (windowPath) {
      case '/PortableAssetsDetail':
        this.getPortableFilterDetails();
        this.loadPortableAssetData();
        break;
      case '/PropertyAssetsDetail':
        this.getPropertyFilterDetails();
        this.loadPropertyAssetData();
        break;
      case '/CashAssetsDetail':
        this.loadCashAssetData();
        break;
      case '/ShareAssetsDetail':
        this.loadShareAssetData();
        break;
      case '/PensionAssetsDetail':
        this.loadPensionAssetData();
        break;
      default:
        this.loadPortableAssetData();
        break;
    }
  }

  handleCurrencyData(dataMessage: any, message: any) {
    if (dataMessage === this.getCurrencyListCalId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          currencyList: responseJson
        })
      })
    }

    if (dataMessage === this.ChangeCurrencyCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.loadCurrencyData();
      })
      this.refreshData();
    }
  }

  handlePortableAssetData(dataMessage: string, message: Message) {
    if (dataMessage === this.getPortableAssetCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          portableAssetData: responseJson?.data || [],
          AllPortableAssetData: responseJson?.data || []
        })
        if( this.deletePortableAssetCallId !=="" && responseJson.message==="List is empty"){
          this.props.navigation.navigate("Dashboard");          
        } 
      })
    }
    if (dataMessage === this.updatePortableAssetCallId || dataMessage === this.deletePortableAssetCallId) {
      const Response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (Response?.message === "Please remove the associated liability first.") {
        toast.error(Response?.message)
      }
      this.loadPortableAssetData();
    }
  }

  handlePropertyAssetData(dataMessage: string, message: Message) {
    if (dataMessage === this.getPropertyAssetCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          propertyAssetData: responseJson?.data || [],
          filteredPropertyAssetData: responseJson?.data || []
        })
        if( this.deletePropertyAssetCallId !=="" && responseJson.message==="List is empty"){
          this.props.navigation.navigate("Dashboard");          
        } 
      })
    }
    if (dataMessage === this.deletePropertyAssetCallId) {
      const Response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (Response?.message === "Please remove the associated liability first.") {
        toast.error(Response?.message)
      }
      this.loadPropertyAssetData();
    }
  }

  handleCashAssetData(dataMessage: any, message: any) {
    if (dataMessage === this.getCashAssetCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          cashAssetData: responseJson.data
        })
        if(this.deleteCashAssetCallId !="" && responseJson.message==="List is empty"){
          this.props.navigation.navigate("Dashboard"); 
        }       
      })
    }
    if (dataMessage === this.deleteCashAssetCallId) {
      this.loadCashAssetData();
    }
  }

  handleShareAssetData(dataMessage: any, message: any) {
    if (dataMessage === this.getShareAssetCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          shareAssetData: responseJson.data
        })
        if(this.deleteShareAssetCallId !==""&& responseJson.message==="List is empty"){
          this.props.navigation.navigate("Dashboard");          
        } 
      })
    }
    if (dataMessage === this.deleteShareAssetCallId) {
      this.loadShareAssetData();
    }
  }

  handlePensionAssetData(dataMessage: any, message: any) {
    if (dataMessage === this.getPensionAssetCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          pensionAssetData: responseJson.data
        })
        if( this.deletePensionAssetCallId !=="" && responseJson.message==="Pension not added"){
          this.props.navigation.navigate("Dashboard");          
        }
      })
    }
    if (dataMessage === this.deletePensionAssetCallId) {
      this.loadPensionAssetData();
    }
  }

  getFileExtension = (url: string) => {
    const parts = url.split('.');
    return  parts[parts.length - 1].toLowerCase();
  };

  async loadCurrencyData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyListCalId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/currency_list'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadPortableAssetData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPortableAssetCallId = requestMessage.messageId;

    const headers = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/portable_assets`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadPropertyAssetData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPropertyAssetCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/properties`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadCashAssetData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCashAssetCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/cashes`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadShareAssetData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareAssetCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/shares`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async loadPensionAssetData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPensionAssetCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/pensions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updatePortableAssetData(portableCardId: any, key: string, val: any) {
    if (portableCardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePortableAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      const formData = new FormData();
      formData.append(key, val);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), formData);

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/portable_assets/${portableCardId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async deletePortableAssetData(portableCardId: any) {
    if (portableCardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deletePortableAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/portable_assets/${portableCardId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async deletePropertyAssetData(portableCardId: any) {
    if (portableCardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deletePropertyAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/properties/${portableCardId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async deleteCashAssetData(cardId: any) {
    if (cardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteCashAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/cashes/${cardId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async deleteShareAssetData(cardId: any) {
    if (cardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteShareAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/shares/${cardId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async deletePensionAssetData(cardId: any) {
    if (cardId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deletePensionAssetCallId = requestMessage.messageId;
      const headers = {
        token: this.state.token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/pensions/${cardId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleOutsideClick = (e: any) => {
    if (this.state.isDocOpen && this.docModalRef.current && !this.docModalRef.current.contains(e.target)) {
      this.setState({ isDocOpen: false });
    } else if (this.state.isGalleryModalOpen && this.galleryModalRef.current && !this.galleryModalRef.current.contains(e.target)) {
      this.setState({ isGalleryModalOpen: false });
    } else if (this.state.isUploadDocumentOpen && this.uploadDocModalRef.current && !this.uploadDocModalRef.current.contains(e.target)) {
      this.setState({ isUploadDocumentOpen: false });
    }
  };

  onAddCurrencyToAccount = async (currencyId: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ChangeCurrencyCallId = requestMessage.messageId;
    const header = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const formData = new FormData();
    formData.append('currency_id', currencyId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleDownloadDoc(downloadUrl: string) {
    documentDownload(downloadUrl);
  }

  async getPropertyFilterDetails() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPropertyAssetFilterCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories/?name=property`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPortableFilterDetails() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPortableAssetFilterCallId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories/?name=portable`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePropertyFiltersData(dataMessage: string, message: Message) {
    if (dataMessage === this.getPropertyAssetFilterCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          propertyFiltersData: responseJson.data,
        })
      })
    }
  }

  handlePortableFiltersData(dataMessage: string, message: Message) {
    if (dataMessage === this.getPortableAssetFilterCallId) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.receiveHandler(responseJson, () => {
        this.setState({
          portableFiltersData: responseJson.data,
        })
      })
    }
  }

  handleFilterSelect(filterId: number) {
    this.setState({selectedPropertyFilterId: filterId}, () => {
      if (filterId !== 0) {
        this.setState({
          filteredPropertyAssetData: this.state.propertyAssetData.filter(propertyData => propertyData.attributes.sub_category.id === filterId)
        })
      } else {
        this.setState({
          filteredPropertyAssetData: this.state.propertyAssetData   
        })
      }
    })
  }

  handlePortableFilterSelect(filterId: number) {
    this.setState({selectedPortableFilterId: filterId}, () => {
      if (filterId !== 0) {
        this.setState({
          portableAssetData: this.state.AllPortableAssetData.filter(portableData => portableData.attributes.sub_category.id === filterId)
        })
      } else {
        this.setState({
          portableAssetData: this.state.AllPortableAssetData
        })
      }
    })
  }
  
  handleEditProperty = (data: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AddPropertyweb");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data)
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  }

  handleMenuModal = (event: React.MouseEvent<HTMLElement>, currentCardId: string) => {    
    this.setState({ isVerticalPopoverOpen: true, currentCardId: currentCardId, anchorEl: event?.currentTarget as HTMLAnchorElement })
  }
  handleDocumentMenuModalClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  // Customizable Area End
}
