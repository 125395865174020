import React from "react";

// Customizable Area Start
import {
  appleLogo,
  googleIcon,
  homeIcon,
  facebook,
  instagram,
  twitter,
  youtube,
  linkedin,
  logo,
  budget13,
  networth27,
  networth28,
  networth29,
  ImgBack,
  bg1
} from "./assets";
import { styled } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from "@material-ui/core";
// Customizable Area End
import LandingPageController from "./LandingPageController";
export default class NetworthFeature extends LandingPageController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box style={{ position: 'relative' }}>
          <div style={{ ...webStyle.ImgBackTop, position: 'absolute' }}></div>
          <div style={{ ...webStyle.ImgBackTop1, position: 'absolute' }}></div>
          <Box style={{ maxWidth: "1946", margin: "auto" }}>
            <CnvestmentBox style={{ position: 'relative', zIndex: 20 }}>
              <Box>
                <MasterStyle>
                  All Your Assets,{" "}
                  <span style={webStyle.masterSaving}>One platform</span>
                </MasterStyle>
                <MasterFinances >
                  Experience the ease of managing all your assets in one place.
                  Myne's Net Worth and Asset Aggregation feature syncs your bank
                  accounts, stocks, cryptocurrencies, properties, and even luxury
                  items like art and jewellery. Get a clear, real-time view of
                  your net worth and make smarter financial decisions.
                </MasterFinances>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img src={networth28} width="100%" height="100%" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin>
                      <MasterCheck>
                        Connect with Over{" "}
                        <span style={webStyle.masterSpends}>10,000 Bank <span>Accounts Securely</span></span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Seamless Connectivity, Unmatched Security"
                        <br /><span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Connect effortlessly with more than 10,000 bank accounts
                          worldwide using MYNE's direct, secure API. Experience the
                          confidence of robust security protocols while managing your
                          financial data seamlessly.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>
              <BoxMarginTop>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        Track and Sync Your Stocks and {" "}
                        <br /><span style={webStyle.masterSpends}>
                          Crypto Portfolios
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Your Investments, Perfectly Aligned"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Track and synchronize your stocks and crypto portfolios in
                          real-time with MYNE. Get aggregated view from your wallets
                          and exchanges live and track performance effortlessly.
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img src={networth29} width="100%" height="100%" />
                  </Grid>
                </Grid>
              </BoxMarginTop>

              <Box>
                <Grid container item spacing={4}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={networth27} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin >
                      <MasterCheck >
                        Track All Types of Assets in{" "}
                        <br /> <span style={webStyle.masterSpends}>
                          Real-Time
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Total Asset Clarity at Your Fingertips"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          With MYNE, track all your assets in real-time, from banking
                          assets, real estate, to luxury items. Gain complete,
                          up-to-date visibility of your entire asset portfolio.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>
              <BoxMarginTop>
                <Grid container item spacing={4} >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin>
                      <MasterCheck >
                        Net Worth Live View and{" "}
                        <span style={webStyle.masterSpends}> Smart <br /><span>Dashboard</span></span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Your Financial Snapshot, Enhanced and <br /> <span>intelligent"</span>
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Discover your financial health with MYNE's Net Worth Live
                          View and Smart Dashboard. Check your asset allocation by
                          type, country and currency instantly to make better and more
                          informed financial decisions.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img src={budget13} width="100%" />
                  </Grid>
                </Grid>
              </BoxMarginTop>
            </CnvestmentBox>
          </Box>
          <Box>
            <div style={{ ...webStyle.ImgBackBottem, position: 'absolute' }} className="ImgBackBottem"></div>
            <div style={{ ...webStyle.ImgBackBottem1, position: 'absolute' }}></div>
            <Box style={{ ...webStyle.footerwidth, position: 'relative', zIndex: 20, background: "#272733" }}>
              <Box style={{ maxWidth: "1946", margin: "auto", }}>
                <AppStoreGetItOnFooter id="footer" style={webStyle.footer}>
                  <AppStoreGetItOnFooterHome data-test-id="footerContent" style={{ ...webStyle.footerContent, margin: "13px 0px" }}>
                    <Box >
                      <img src={homeIcon} style={webStyle.footerIcons} alt="" />
                    </Box>
                    <Box >
                      <span style={webStyle.menuItemsForFooter} className="About">About us</span>
                      <span className="Roadmap" style={webStyle.menuItemsForFooter} data-test-id="Contact">Contact us</span>
                      <span style={webStyle.menuItemsForFooter}>Resources</span>
                      <span style={webStyle.menuItemsForFooter} className="Roadmap" >Roadmap</span>
                      <span className="TermsOf" style={webStyle.menuItemsForFooter}>Terms of use</span>
                      <span style={webStyle.menuItemsForFooter} className="policy">Cookies policy</span>
                      <span style={webStyle.menuItemsForFooter} data-test-id="menuItemsForFooter">Privacy policy</span>
                    </Box>
                  </AppStoreGetItOnFooterHome>
                  <LogoForFooter style={{ margin: "13px 0px" }}>
                    <img src={logo} style={webStyle.imgLogo} alt="" />
                    <Typography style={webStyle.logoForFooter}>{" "}Who's managing your wealth?</Typography>
                  </LogoForFooter>
                  <Box style={{ ...webStyle.socialMediaIcons, margin: "13px 0px" }}>
                    <img style={webStyle.footerIcons} src={facebook} alt="" />
                    <img src={instagram} data-test-id="footerIcons" style={webStyle.footerIcons} alt="" />
                    <img style={webStyle.footerIcons} src={twitter} alt="" />
                    <img src={youtube} data-test-id="youtube" style={webStyle.footerIcons} alt="" />
                    <img style={webStyle.footerIcons} src={linkedin} alt="" />
                  </Box>
                  <Box style={{ ...webStyle.appleAndGoogleCards, margin: "13px 0px", flexWrap: 'wrap', justifyContent: 'center' }} className="appleAndGoogleCards">
                    <img height="46px" src={googleIcon} alt="" width="151px" />
                    <img alt="" width="151px" src={appleLogo} height="46px" />
                  </Box>
                </AppStoreGetItOnFooter>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  ImgBackBottem: { width: '65%', height: '20%', background: `url(${bg1}) no-repeat`, top: '80%', backgroundSize: 'cover', zIndex: 1 },
  masterSpends: { color: "#2ee1d5" },
  ImgBackBottem1: { width: '65%', height: '20%', zIndex: 2, top: '80%', left: 0, background: 'linear-gradient(227deg, rgb(60 60 81) 66%, rgb(60 60 81 / 0%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
  imgLogo: { width: "170px", borderRadius: '0px' },
  logoForFooter: { fontSize: "10px", fontFamily: 'Helvetica !important', color: "white", paddingTop: '6.5px' },
  socialMediaIcons: { display: "flex", gap: "10px", alignItems: "center" },
  footerContent: { display: "flex", alignItems: "center" },
  appleAndGoogleCards: { display: "flex", gap: "15px", marginLeft: "9px" },
  masterSaving: { color: "#2ee1d5" },
  imgLodoNew: { height: "30px" },
  ImgBackTop1: { width: '65%', right: '-458px', height: '25%', zIndex: 2, background: 'linear-gradient(45deg, rgb(60, 60, 81) 40%, rgba(60, 60, 81, 0.8) 60%, rgba(60, 60, 81, 0) 100%) repeat scroll 0% 0%, transparent none repeat scroll 0% 0%' },
  footerwidth: { width: "100%", marginTop: "40px" },
  googleCardFooter: { background: "black", color: "#fff", width: "150px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  footer: { width: "100%", padding: "50px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" },
  googleimg: { height: "30px" },
  ImgBackTop: { width: '65%', height: '25%', right: '-458px', background: `url(${ImgBack}) no-repeat`, backgroundSize: 'cover', zIndex: 1 },
  GooglePlayFont: { fontWeight: 600 },
  appStoreFooter: { background: "black", color: "#fff", width: "130px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  appStoreGetItOnFooter: { fontSize: "clamp(1vw,0.469vw,3vw)", fontFamily: 'Helvetica !important', },
  footerIcons: { width: "22px" },
  menuItemsForFooter: { fontSize: "12px", fontFamily: 'Helvetica !important', color: "#fff", marginLeft: "10px", cursor: "pointer" },
  masterCheckMonitor: { color: "white" },
  container: { background: "#3C3C51", height: "100%" },
};
const MasterFinances = styled(Box)({
  color: "white", marginBottom: "8%", fontSize: `clamp(1vw, 33px,1.578vw)`, fontFamily: 'Helvetica !important', lineHeight: 'initial !important',
});
const CnvestmentBox = styled(Box)({
  paddingLeft: "110px !important", paddingRight: "110px !important", paddingTop: "40px !important",
  "@media (max-width: 950px)": {
    paddingRight: "30px !important",
    paddingLeft: "30px !important",
  },
  "@media (min-width: 601px)": {
    paddingRight: "30px !important",
    paddingLeft: "30px !important",
  },
  "@media (min-width: 1280px)": {
    paddingRight: "110px !important",
    paddingLeft: "110px !important",
  }
});
const MasterSavingTime = styled(Box)({
  color: "#2ee1d5", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', lineHeight: 'initial'
});
const MasterCheck = styled(Box)({
  fontSize: "clamp(1vw, 45px,2.344vw)", fontFamily: 'Helvetica !important', color: "white", lineHeight: '100% !important', paddingBottom: "25px !important"
});
const AppStoreGetItOnFooter = styled(Box)({
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const AppStoreGetItOnFooterHome = styled(Box)({
  display: 'flex', alignItems: 'center', margin: "13px 0px", textAlign: 'center',
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const LogoForFooter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end"
});
const MasterStyle = styled(Box)({
  fontSize: `clamp(1vw,92.71px,4.829vw)`, fontFamily: 'Helvetica !important', color: "white", lineHeight: "100%", paddingBottom: "25px !important",
});
const BoxMargin = styled(Box)({
  margin: "60px 0px",
  "@media (max-width: 601px)": {
    margin: "22px 0px !important",
  },
  "@media (max-width: 1280px)": {
    margin: "22px 0px !important",
  },
})
const BoxMarginTop = styled(Box)({
  padding: "125px 0px",
  "@media (max-width: 601px)": {
    padding: "0px !important",
  },
})

// Customizable Area End
