// Customizable Area Start
import React from "react";
import { Calendar } from "react-modern-calendar-datepicker";
import { image_Imagenav_backicon } from "./assets"
import FormApprovalWorkflowController, {
    Props,
    MockSlotsResponse
} from "./FormApprovalWorkflowController.web";
import { Box, Typography, styled } from '@material-ui/core';
import { convertISOTo12HourFormat } from "../../../components/src/commonFn";

const today = new Date();
const minDate = {
    year: today.getFullYear(),
    month: today.getMonth() + 1, // Note: Month is zero-indexed
    day: today.getDate(),
};


export default class RescheduleCalender extends FormApprovalWorkflowController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { selectedDay } = this.state;

        return (
            <div className="main-dashboard-wrapper" style={{ background: '#2b2a38', scrollbarWidth: 'thin', width: '100%', scrollbarColor: '#00ebd9 transparent', height: '100vh', overflowY: 'scroll' }}>
                <StyleBox>
                    <Box style={webStyle.profileNameNew}>
                        <Box onClick={() => this.redirectToSomeRoute('ModifyBooking')} data-test-id="backButton" ><img src={image_Imagenav_backicon} style={webStyle.marginImg2} alt="back" /></Box>
                        <Box style={webStyle.planning}> Schedule a consultation call</Box>
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "4rem", flexWrap: "wrap", gap: "20px" }}>

                        <CalendarWrapper className="calendar___wrapper">
                            <Calendar
                                value={this.state.calenderSelectedDay}
                                onChange={this.handleSelectedDay}
                                minimumDate={minDate}
                                colorPrimary="#2DE6D5"
                                calendarClassName="custom-calendar"
                                calendarTodayClassName="custom-today-day"
                                data-test-id="calendarTestId"
                            />
                            <p className="calendar___day newClassHere">{this.state.calenderSelectedDay?.day}</p>
                        </CalendarWrapper>

                        {this.state.slots && this.state.slots.length > 0 ? (
                            <Box style={{ flex: 1, flexDirection: "column",display: "flex" ,  backgroundColor: "#3b3c50",boxShadow: "1px 1px 1px 1px #868383", maxWidth: "550px", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
                                <Typography variant="h6" style={{
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontFamily: "Poppins",
                                    color: "#fff",
                                    marginBottom: "1rem",
                                    fontSize: "24px",
                                      marginTop:'1rem'
                                }}> Schedule Time</Typography>
                                <div style={{ display: "flex",justifyContent:'center', flexWrap: "wrap", gap: "2rem", overflowY:"auto", padding:'0 3rem 3rem 3rem', maxHeight:'260px' }}>

                                    
                                    {this.state.availableSlot?.map((dataN:{
                                        start_time:string,
                                        availablity: boolean
                                    }) => (
                                        <div style={{ width:'195px' }} key={dataN.start_time}>
                                            <ButtonWrapper
                                                data-test-id="btnAttribute"
                                                onClick={() => this.handleTimeSlots(dataN)}
                                                style={{cursor: 'pointer'}}
                                                className={this.state.timeSlotDate.time === dataN.start_time ? 'active' : ''}
                                            >
                                             {convertISOTo12HourFormat(dataN.start_time)}

                                            </ButtonWrapper>
                                        </div>
                                    ))}

                                    {this.state.unAvailableSlot?.map((dataN: {
                                        start_time:string,
                                        availablity: boolean
                                    }) => (
                                        <div style={{ width:'195px' }} key={dataN.start_time}>
                                            <ButtonWrapper
                                                data-test-id="btnAttribute"
                                                style={webStyle.buttonGrey}
                                                onClick={() => this.handleTimeSlots(dataN)}
                                                disabled>
                                             {convertISOTo12HourFormat(dataN.start_time)}
                                            </ButtonWrapper>
                                        </div>
                                    ))}
                                </div>
                            </Box>
                        ) : (
                            <Box style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: " center", gap: 3 }}>
                                <Typography variant="h6" style={{
                                    fontWeight: 500,
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                    fontStyle: 'normal',
                                    fontSize: "19px"
                                }}> No slots available</Typography>
                                <div style={{ marginTop: "20px" }}>
                                    <button onClick={this.gotoNextSlot} style={webStyle.buttonContinue as React.CSSProperties}>Next availability is on {this.state.next_date}</button>
                                </div>
                            </Box>
                        )}
                    </Box>
                </StyleBox>
                <Box>


                    <div style={{ display: "flex", justifyContent: "center", padding: "4rem" }}>
                        <button style={webStyle.buttonConsult as React.CSSProperties} data-test-id="appointBtn" onClick={() => this.handleRescheduleBooking()}> Book Consultation </button>
                    </div>
                </Box>
            </div>
        );
    }
}
const CalendarWrapper = styled(Box)({
    "& .Calendar__sectionWrapper": {
        minHeight: "27em !important",
      },
})
const StyleBox = styled(Box)({
    padding: '80px 40px 50px 110px', 
    backgroundColor: '#2b2a38',
    "@media (max-width: 500px)": {
        padding: '80px 30px 50px 30px',
    }
})
const ButtonWrapper = styled('button')({
    maxWidth: '456px',
    width: '100%',
    background: 'transparent',
    border: '1px solid #979797',

    padding: '10px 3em',
    borderRadius: '8px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    justifyContent: 'center',
    '&:hover': {
        color: '#3C3C51',
        backgroundColor: "#C9F9F6",
        border: '1px solid #C9F9F6',
    },
    '&.active': {
        border: '1px solid #C9F9F6',
        color: '#3C3C51',
        backgroundColor: "#C9F9F6",
    },
})

const webStyle = {
    pageHeader: {
        lineHeight: 'normal',
        marginTop: '17px',
        color: '#FFF',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        marginLeft: '42px'
    },
    pageHeader1: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '28px',
        lineHeight: 'normal',
        fontWeight: 700
    },
    buttonGrey: {
        maxWidth: '456px',
        width: '100%',
        background: 'rgba(197,196,202,255)',
        border: '1px solid #979797',
        padding: '10px 3em',
        borderRadius: '8px',
        color: '#061506',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        justifyContent: 'center',
    },
    buttonContinue: {
        maxWidth: '456px',
        width: '100%',
        background: '#2de1d5',
        border: '1px solid #979797',
        padding: '10px 3em',
        borderRadius: '8px',
        color: '#3C3C50',
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
    },

    buttonConsult: {
        maxWidth: '356px',
        background: '#C9F9F6',
        border: '1px solid #979797',
        width: '100%',
        padding: '15px 3em',
        borderRadius: '8px',
        color: '#3C3C50',
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" },
    marginImg2: { cursor: "pointer", margin: '0px 20px 0 0' },
    planning1: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
    marginImg1: { marginRight: '9px', cursor: "pointer" },
    normalCardBox: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #2675ff',
        padding: '24px 27px',
        position: 'relative',
    }
};
