import React from "react";


// Customizable Area Start

import "./style.css"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Box, Button, styled } from "@material-ui/core";

// Customizable Area End

interface Props {
  id: string;
  navigation: any;
  instance: any;
}
interface S { }
interface SS { }

export default class Questions extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { instance } = this.props
    return (
      // Customizable Area Start
      <div className="mainquestions-wrapper">
        {!instance.state.isArabic ?
          <div className="questions-wrapper">
            <div className="questions-box">

              <div className="questions-block">

                <div className="heard" style={{padding: '1rem'}}>
                  <h4>How did you hear about us?</h4>
                  <div className="main-radio-box" style={{marginBottom: '1rem'}}>
                    <div className="radio-box" data-test-id="radioBox1" onClick={() => instance.checkboxHandle('search_engine')}><input type="radio" className="radio-button" name="heard" value="search_engine" checked={instance.state.heard === "search_engine"} /><label>Search Engine</label></div>
                    <div className="radio-box" data-test-id="radioBox2" onClick={() => instance.checkboxHandle('advertisement')}><input type="radio" className="radio-button" name="heard" value="advertisement" checked={instance.state.heard === "advertisement"} /><label>Advertisement</label></div>
                    <div className="radio-box" data-test-id="radioBox3" onClick={() => instance.checkboxHandle('blog_or_publications')}><input type="radio" className="radio-button" name="heard" value="blog_or_publications" checked={instance.state.heard === "blog_or_publications"} /><label>Blog or Publications</label></div>
                    <div className="radio-box" data-test-id="radioBox4" onClick={() => instance.checkboxHandle('social_media')}><input type="radio" className="radio-button" name="heard" value="social_media" checked={instance.state.heard === "social_media"} /><label>Social media</label></div>
                    <div className="radio-box" data-test-id="radioBox5" onClick={() => instance.checkboxHandle('word_of_mouth')}><input type="radio" className="radio-button" name="heard" value="word_of_mouth" checked={instance.state.heard === "word_of_mouth"} /><label>Word of mouth</label></div>
                    <div className="radio-box" data-test-id="radioBox6" onClick={() => instance.checkboxHandle('recommended_by_a_friend')}><input type="radio" className="radio-button" name="heard" value="recommended_by_a_friend" checked={instance.state.heard === "recommended_by_a_friend"} /><label>Recommended by a friend</label></div>
                  </div>
                </div>
                <ContinueBtn className="buttons-heard">
                  <Button className="verifyQuestions" onClick={() => instance.setState({ componentPage: "goals" })} data-test-id="continueBtn" disabled={instance.state.heard === ''} style={{margin: '15px 0 0'
}}>Continue</Button>

                  <ul className="list-dot">
                    <li ></li>
                    <li className="current"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </ContinueBtn>
              </div>
            </div>
          </div>
          :
          <div className="questions-wrapper arbic-questions-wrapper">
            <div className="questions-box">

              <div className="questions-block">

                <div className="heard">
                  <h4>كيف سمعت عنّا؟</h4>
                  <div className="main-radio-box">
                    <div className="radio-box" data-test-id="radioBox1" onClick={() => instance.checkboxHandle('search_engine')}><input type="radio" className="radio-button" name="heard" value="search_engine" checked={instance.state.heard === "search_engine"} /><label>محرك البحث</label></div>
                    <div className="radio-box" data-test-id="radioBox2" onClick={() => instance.checkboxHandle('advertisement')}><input type="radio" className="radio-button" name="heard" value="advertisement" checked={instance.state.heard === "advertisement"} /><label>الإعلان</label></div>
                    <div className="radio-box" data-test-id="radioBox3" onClick={() => instance.checkboxHandle('blog_or_publications')}><input type="radio" className="radio-button" name="heard" value="blog_or_publications" checked={instance.state.heard === "blog_or_publications"} /><label>المدونة أو المنشورات</label></div>
                    <div className="radio-box" data-test-id="radioBox4" onClick={() => instance.checkboxHandle('social_media')}><input type="radio" className="radio-button" name="heard" value="social_media" checked={instance.state.heard === "social_media"} /><label>مواقع التواصل الاجتماعي</label></div>
                    <div className="radio-box" data-test-id="radioBox5" onClick={() => instance.checkboxHandle('word_of_mouth')}><input type="radio" className="radio-button" name="heard" value="word_of_mouth" checked={instance.state.heard === "word_of_mouth"} /><label>كلمة شفهية</label></div>
                    <div className="radio-box" data-test-id="radioBox6" onClick={() => instance.checkboxHandle('recommended_by_a_friend')}><input type="radio" className="radio-button" name="heard" value="recommended_by_a_friend" checked={instance.state.heard === "recommended_by_a_friend"} /><label>تمت التوصية به من صديق</label></div>
                  </div>
                </div>
                <ContinueBtn className="buttons-heard">
                  <button className="verifyQuestions" onClick={() => instance.setState({ componentPage: "goals" })} data-test-id="continueBtn">المتابعة</button>

                  <ul className="list-dot">
                    <li ></li>
                    <li className="current"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </ContinueBtn>
              </div>
            </div>
          </div>
        }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContinueBtn = styled(Box)({
  "@media (max-width: 575px)": {
      bottom: "5px",
      width: "100%",
      paddingLeft: "15px",
      paddingRight: "15px"
  }
})
// Customizable Area End
