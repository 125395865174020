// Customizable Area Start
import React from "react";
import { Calendar } from "react-modern-calendar-datepicker";
import { image_Imagenav_backicon } from "./assets"
import FormApprovalWorkflowController, {
    Props,
} from "./FormApprovalWorkflowController.web";
import { Box, Typography, styled } from '@material-ui/core'
import { convertISOTo12HourFormat } from "../../../components/src/commonFn";

const today = new Date();
const minDate = {
    year: today.getFullYear(),
    month: today.getMonth() + 1, 
    day: today.getDate(),
};


export default class Calender extends FormApprovalWorkflowController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { selectedDay} = this.state;


        return (
            <div className="main-dashboard-wrapper" style={{ scrollbarWidth: 'thin', width: '100%', scrollbarColor: '#00ebd9 transparent', height: '100vh', overflowY: 'scroll' , backgroundColor:'#2b2a38'}}>
                <StyleWrapper>
                    <Box style={webStyle.profileNameNew}>
                        <Box onClick={() => this.goToAppointment()} data-test-id="backButton" ><img src={image_Imagenav_backicon} style={webStyle.marginImg2} alt="back" /></Box>
                        <Box style={webStyle.planning}> Schedule a Consultation Call</Box>
                    </Box>

                    <Box style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginTop: "4rem", flexWrap: "wrap", gap: "10px" }}>

                        <CalendarWrapper className="calendar___wrapper">
                            <Calendar
                                onChange={this.handleSelectedDay}
                                data-test-id="calendarTestId"
                                colorPrimary="#2DE6D5"
                                minimumDate={minDate}
                                calendarTodayClassName="custom-today-day"
                                value={this.state.calenderSelectedDay}
                                calendarClassName="custom-calendar"
                            />
                               <p className="calendar___day">{this.state.calenderSelectedDay?.day}</p>
                        </CalendarWrapper>

                        {this.state.slots  && this.state.slots.length > 0 ? (
                            <Box style={{ flex: 1, display: "flex", flexDirection: "column", backgroundColor: "#3b3c50", maxWidth: "550px", justifyContent: "center", alignItems: " center", borderRadius: "10px" }}>
                                <Typography variant="h6" style={{
                                    fontStyle: 'normal',
                                    fontFamily: "Poppins",
                                    color: "#fff",
                                    fontSize: "25px", marginBottom: "2rem",
                                    fontWeight: 500
                                }}>Schedule Time</Typography>
                               
                      <div style={{ display: "flex",justifyContent:'center', flexWrap: "wrap", gap: "2rem", padding:'0 3rem 3rem 3rem', overflowY:"auto", maxHeight:'260px'}}>
                {this.state.availableSlot?.map((d:any) => (
                    <div style={{width:'195px'  }} key={d.id}>
                        <ButtonWrapper
                            data-test-id="btnAttribute"
                            onClick={() => this.handleTimeSlots(d)}
                            className={this.state.timeSlotDate?.time === d.start_time ? 'active' : ''}
                        >
                            {convertISOTo12HourFormat(d.start_time)}
                        </ButtonWrapper>
                    </div>
                ))}

                {this.state.unAvailableSlot?.map((d:any) => (
                    <div style={{ width:'195px' }} key={d.start_time}>
                        <ButtonWrapper
                            data-test-id="btnAttribute"
                            style={webStyle.buttonGrey}
                            onClick={() => this.handleTimeSlots(d)}
                            disabled>
                            {convertISOTo12HourFormat(d.start_time)}
                        </ButtonWrapper>
                    </div>
                ))}
            </div>
                            </Box>
                        ) : (
                            <Box style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: " center", gap: 3 }}>
                                <Typography variant="h6" style={{
                                    fontWeight: 500,
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                    fontStyle: 'normal',
                                    fontSize: "19px"
                                }}> No slots available</Typography>
                                <div style={{ marginTop: "20px" }}>
                                    <button data-test-id="gotoNextSlot" onClick={this.gotoNextSlot} style={webStyle.buttonContinue1 as React.CSSProperties}>Next availability is on {this.state.next_date}</button>
                                </div>
                            </Box>
                        )}
                    </Box>
                </StyleWrapper>
                <Box>
               {this.state.availableSlot?.length == 0 ? null : this.state.slots &&
                <div style={{ display: "flex", justifyContent: "center" , padding: "4rem"}}>
                    <button style={webStyle.buttonConsult as React.CSSProperties} data-test-id="appointBtn" onClick={()=> this.goToAppointPayment()}>Book Consultation </button>
                </div>
    }
                </Box>
            </div>
        );
    }
}

const StyleWrapper = styled(Box)({
    padding: '80px 40px 50px 110px', 
    backgroundColor: '#2b2a38',
    "@media (max-width: 767px)": {
        padding: '80px 40px 50px 20px', 
    }
})

const CalendarWrapper = styled(Box)({
    "& .Calendar__sectionWrapper": {
        minHeight: "27em !important",
      },
})
const ButtonWrapper= styled('button')({
    maxWidth: '456px',
    width: '100%',
    background: 'transparent',
    border: '1px solid #979797',
    
    padding: '10px 3em',
    borderRadius: '8px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    cursor: 'pointer',
    justifyContent: 'center',
    boxSizing:'border-box',
    '&:hover':{
        border: '1px solid #C9F9F6',
        color: '#3C3C51',
        backgroundColor: "#C9F9F6",
    },
    '&.active':{
        border: '1px solid #C9F9F6',
        backgroundColor: "#C9F9F6",
        color: '#3C3C51',
    },

   
})

const webStyle = {
    pageHeader: {
        marginTop: '17px',
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: 'normal',
        fontWeight: 700,
        fontStyle: 'normal',
        marginLeft: '42px'
    },
    pageHeader1: {
        fontSize: '28px',
        fontFamily: 'Poppins',
        color: '#FFF',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: 'normal'
    },
    buttonContinue: {
        maxWidth: '456px',
        width: '100%',
        background: 'transparent',
        border: '1px solid #979797',
        
        padding: '15px 3em',
        borderRadius: '8px',
        color: 'white',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
        '&:hover':{
            backgroundColor: "#C9F9F6",
            border: '1px solid #C9F9F6',
            color: '#3C3C51',
        }
        
       
    },

    buttonContinue1: {
        maxWidth: '456px',
        width: '100%',
        background: '#C9F9F6',
        border: '1px solid #979797',
        
        padding: '15px 3em',
        borderRadius: '8px',
        color: '#3C3C50',
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    buttonGrey: {
        maxWidth: '456px',
        width: '100%',
        background: 'rgba(197,196,202,255)',
        border: '1px solid #979797',
        padding: '10px 3em',
        borderRadius: '8px',
        color: '#061506',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
    },

    buttonConsult: {
        maxWidth: '356px',
        background: '#C9F9F6',
        border: '1px solid #979797',
        width: '100%',
        padding: '15px 3em',
        borderRadius: '8px',
        color: '#3C3C50',
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" , margin: '2px'},
    marginImg2: {  cursor: "pointer" ,margin: '0 20px 0 0'},
    planning1: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
    marginImg1: { marginRight: '9px', cursor: "pointer" },
    normalCardBox: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #2675ff',
        padding: '24px 27px',
        position: 'relative',
    }
};
