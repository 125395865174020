import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area End

export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}
export interface ICommodity{
  "id": string,
  "type": "commodity",
  "attributes": {
      "commodity_type": string,
      "name": string,
      "quantity": string,
      "purchase_price": number,
      "currency": string,
      "transaction_date": string,
      converted_amount: number,
      converted_currency_type: string,
  }
}
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    selectedCurrency: { value: string, label: string },
    currencyList :ICurrency[],
    currencyListDropDown: { value: string, label: string }[],
    commodityData: ICommodity[],
    commodityTooltip: HTMLSpanElement | null,
    currentCommodity: ICommodity | null,
    openDeleteModel: boolean;
    language:string,
    direction: string,
    
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommodityOverviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  deleteCommodityAPICallId:string = "";
  getCommodityListAPICallId:string = "";
 

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      currencyListDropDown:[],
      commodityData: [],
      commodityTooltip:null,
      currentCommodity: null,
      language: "English",
      direction: "ltr",
      openDeleteModel: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCurrencyList();
    let language = await getStorageData('language');
    const currentLanguage2 = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage2 ,language: language});
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleDeleteCommodityResponse(apiRequestCallId);
    this.handleCommodityListResponse(apiRequestCallId,responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId);

    // Customizable Area End
  }

  // Customizable Area Start

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  getStringCommodityOverview = (keyvalue1: string) => {
    return configJSON.languageListCommodityOverview[this.state.language][keyvalue1]
  }

  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if(apiRequestCallId === this.getCurrencyListAPICallId){
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
    })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` }:{
          value:"",label:""
        },
        currencyListDropDown: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
      ))
      })

      this.getCommodityList()
    }
  }
  goToAccountDetail=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "MyneSettingsProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  handleBack=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }


  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const commodityMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    commodityMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(commodityMessage.id, commodityMessage);
    return commodityMessage.messageId;
  };

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }

  getCommodityList = async() => {
    this.getCommodityListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_proposal_generation/commodities" ,
      body: null
    });
  }


  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }

  setCommodityTooltip = (event:React.MouseEvent<HTMLSpanElement, MouseEvent>,commodity:ICommodity) => {
    this.setState({
      commodityTooltip: event.currentTarget,
      currentCommodity: commodity
    })
  }

  handleCloseTooltip = () => {
    this.setState({
      commodityTooltip: null
    })
  }

  handleEditCommodity = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AddCommodity");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if(this.state.currentCommodity){
      const userSelectedCurrncy = this.state.currencyListDropDown.find((value) => {
        return value.value === this.state.currentCommodity?.attributes.currency
      })
      
      let editData = {
        ...this.state.currentCommodity,
        currency: {
          label: this.state.currentCommodity?.attributes.currency,
          value:userSelectedCurrncy?.value
        },
        transaction_date: moment(this.state.currentCommodity.attributes.transaction_date,"DD/MM/YYYY").toDate()
      };
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), editData)
    }
  
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  }

  closeDeleteModal = () =>{
    this.setState({
      openDeleteModel: false
    })
  }

  handleDeleteCommodity = () => {
    this.setState({
      openDeleteModel: true,
      commodityTooltip:null
    })
  }
  
  handleDeleteCommodityAsset = async() => {
    this.deleteCommodityAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "DELETE" + '',
      endPoint: `bx_block_proposal_generation/commodities/${this.state.currentCommodity?.id}` ,
      body: null
    });
  }

  handleDeleteCommodityResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.deleteCommodityAPICallId){

     this.getCommodityList()
    }
  }

  handleCommodityListResponse(apiRequestCallId:string,responseJson: {
    data: ICommodity[]
  }){
    if(apiRequestCallId === this.getCommodityListAPICallId){
      this.setState({
        commodityData:responseJson.data,
        openDeleteModel: false,
        commodityTooltip: null
      })
      if(this.deleteCommodityAPICallId !=="" && responseJson.data.length === 0){
        this.props.navigation.navigate("Dashboard");          
      } 
    }
  }

  dialogPaperStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '0rem 2rem',
  };

  // Customizable Area End
}
