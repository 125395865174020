import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { subarrowicon } from "./assets";
import { Grid, Box, Typography, ButtonGroup, Button, Select, MenuItem } from "@material-ui/core";
import { ResponsivePie } from '@nivo/pie';
import "./style.css";
import "../asset/main-dashboard.css"
import { getStorageData } from 'framework/src/Utilities';
import { apiCall } from "../../../components/src/commonFn";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue?: string;
  txtSavedValue?: string;
  enableField?: boolean;
  // Customizable Area Start
  token: any;
  currencyList: any;
  mainDashboardData: any;
  currentCardId: any;
  currentCard: any;
  assetsType: string;
  pieChartColor: any;
  expandProperty: any;
  PropertyOverview: any;
  newWebStyle: any;
  cytotalNetworth: any;
  pieChartData: any;
  sectApiCard: any;
  assetsCountryDetails: any;
  debtsDetails: any;
  investDetails: any;
  cashDetails: any;
  balance: string;
  assetCountry: any;
  showCountryValue: boolean;
  assetsTypeDetails: any;
  assetsTypeDetailsBalance: string;
  assetsDetailsTitle: string;
  viewDetail: any;
  dataValue: Object
  dataValueNew: Object
  logoImgDashboard: string;
  logoImgDashData: string;
  dashboradArabic:any;
  languages:string
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MainDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCytotalNetworth: any;
  getPieChartData: any;
  getNewPieChartData: any;
  getCurrencyListCalId: any;
  ChangeCurrencyCallId: any;
  getMainDashboardListCallId: any
  ChangeCurrencyCallOnClickId: any
  getTypeCountryCallOnClickId: any
  BankAccountDetailCallId: any
  cashDetailDataCallId: any
  SharesDetailDataCallId: any
  propertyDetailsCallId: any
  portableAssetDetailsDataCallId: any
  pensionDetailDataCallId: any
  postUserPDFDataCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dashboradArabic:[],
      cytotalNetworth: [],
      sectApiCard: "",
      PropertyOverview: '',
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: localStorage.getItem('token'),
      mainDashboardData: [],
      currencyList: [],
      currentCardId: null,
      expandProperty: 'faq-block',
      currentCard: 'Assets',
      assetsType: 'Type',
      pieChartColor: ["hsl(257, 97%, 59%)", "hsl(258, 97%, 55%)", "hsl(258, 90%, 67%)", "hsl(257, 93%, 64%)", "hsl(258, 96%, 73%)", "hsl(257, 87%, 79%)", "hsl(261, 80%, 86%)", "hsl(257, 84%, 93%)", "hsl(259, 99%, 62%)"],
      newWebStyle: {},
      pieChartData: [],
      assetsCountryDetails: '',
      debtsDetails: [],
      investDetails: [],
      cashDetails: [],
      balance: '',
      assetCountry: '',
      showCountryValue: false,
      assetsTypeDetails: [],
      assetsTypeDetailsBalance: '',
      assetsDetailsTitle: '',
      logoImgDashboard: "",
      logoImgDashData: "",
      languages:'',
      viewDetail: {
        color: '#814FF9',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        cursor: "pointer"
      },
      dataValue: {
        display: "flex", flexDirection: 'row-reverse'
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row'
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  componentDidMount(): any {
    this.getPensionList()
    this.loadCurrencyData();
    this.currenCytotalNetworth();
    const chartColor = ["hsl(257, 97%, 59%)", "hsl(258, 97%, 55%)", "hsl(258, 90%, 67%)", "hsl(257, 93%, 64%)", "hsl(258, 96%, 73%)", "hsl(257, 87%, 79%)", "hsl(261, 80%, 86%)", "hsl(257, 84%, 93%)", "hsl(259, 99%, 62%)"]
    const repeatedColors = [];
    for (let i = 0; i < 33; i++) {
      repeatedColors.push(...chartColor);
    }
    this.setState({ pieChartColor: repeatedColors });
  }

  async componentWillMount() {
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImgDashboard: logoImg1AddCash,languages:language?language:"English" });
  }
  pieChartTitle = () => {
    if (this.state.currentCard == 'Assets') return this.getStringDashbord('AssetsOverview')
    else if (this.state.currentCard == 'Debts') return this.getStringDashbord('DebtsOverview')
    else if (this.state.currentCard == 'Investable_Assets') return this.getStringDashbord('InvestableAssetsOverview')
    else if (this.state.currentCard == 'Cash') return this.getStringDashbord('CashOverview')
  }

  getStringDashbord = (key: string) => {
    let languageDashbord = localStorage.getItem("language") || "English"
    return configJSON.languageListDashboard[languageDashbord][key]
  }
  pieChartHeader = (webStyle: any) => {
    return (<Grid style={{ paddingTop: '50px', display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Typography style={webStyle.pageHeader}>{this.pieChartTitle()}</Typography>
      </Box>
      {this.state.currentCard == 'Assets' && <Box dir="ltr" style={this.state.logoImgDashboard === "rtl" ? this.state.dataValue : this.state.dataValueNew}>
        <ButtonGroup disableElevation variant="contained" style={{ borderRadius: "12px", height: '35px' }}>
          <Button data-test-id="openPictureUpload" onClick={() => { this.changeType('Type') }}
            style={{
              textTransform: 'none', borderRadius: "12px 0px 0px 12px", backgroundColor: this.state.assetsType == 'Type' ? "#2DE2D5" : '#D8D8D8',
              ...webStyle.pieChartHeaderButton,
            }}>{this.getStringDashbord('Type')}</Button>
          <Button data-test-id='openPictureUpload1' onClick={() => { this.changeType('Country') }}
            style={{
              textTransform: 'none', borderRadius: "0px 12px 12px 0px", backgroundColor: this.state.assetsType == 'Country' ? "#2DE2D5" : '#D8D8D8'
              , ...webStyle.pieChartHeaderButton,
            }}>{this.getStringDashbord('Country')}</Button>
        </ButtonGroup>
      </Box>}
    </Grid>)
  }

  changeType = (type: string) => {
    this.setState({ assetsType: type })
    this.currenCytotalNetworth(type)
  }

  getAssetCountryData = (event: any) => {
    const requestGetPieChartDataOnClick = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTypeCountryCallOnClickId = requestGetPieChartDataOnClick.messageId;
    const headers = {
      token: this.state.token
    };

    requestGetPieChartDataOnClick.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_budgetingforecasting/graph_charts/selected_country_records?country=${event.code}`
    );

    requestGetPieChartDataOnClick.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestGetPieChartDataOnClick.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestGetPieChartDataOnClick.id, requestGetPieChartDataOnClick);
  }

  pieChartTitleValue = () => {
    if (this.state.currentCard == 'Assets') return { value: this.state.cytotalNetworth.Assets?.total, operand: this.state.cytotalNetworth.Assets?.total_operand }
    else if (this.state.currentCard == 'Debts') return { value: this.state.cytotalNetworth.Debts?.all_debts, operand: this.state.cytotalNetworth.Debts?.all_debts_operand }
    else if (this.state.currentCard == 'Investable_Assets') return {
      value: this.state.cytotalNetworth.Investable_Assets?.all_investable_assets,
      operand: this.state.cytotalNetworth.Investable_Assets?.all_investable_assets_operand
    }
    else if (this.state.currentCard == 'Cash') return {
      value: this.state.cytotalNetworth.Cash?.all_cash,
      operand: this.state.cytotalNetworth.Cash?.all_cash_operand
    }
  }

  pieChartValue = () => {
    if (this.state.currentCard == 'Assets') {
      const newValue = this.state.pieChartData?.map((dataValue: any, index: number) => {
        return {
          id: dataValue.id || index, label: dataValue.name, value: parseFloat(dataValue.balance).toLocaleString('en-US', { useGrouping: false }), percentage: dataValue.percentage, symbol: this.state.cytotalNetworth.currency, color: this.state.pieChartColor[index], code: dataValue.code, dataValueBalance: dataValue.balance
        }
      })
      return newValue
    }
    else if (this.state.currentCard == 'Debts' || this.state.currentCard == 'Investable_Assets' || this.state.currentCard == 'Cash') {
      return this.state.pieChartData?.map((dataValue: any, index: number) => {
        return {
          id: dataValue.id || index, label: dataValue.name, value: dataValue.balance, percentage: dataValue.percentage, symbol: this.state.cytotalNetworth.currency, color: this.state.pieChartColor[index], dataValueBalance: dataValue.balance
        }
      })
    }
  }
  convertToK = (amount: any) => {
    let convertedAmount = parseFloat(amount)
    let newAmount;
    if (convertedAmount >= 1000000) {
      newAmount = (convertedAmount / 1000000).toFixed(1) + 'M';
    } else if (convertedAmount >= 1000) {
      newAmount = (convertedAmount / 1000).toFixed(1) + 'K';
    } else {
      newAmount = convertedAmount;
    }
    return newAmount;
  }
  commonPieChart = () => {
    const chartValue = this.pieChartValue()
    const titleValue: {
      value: string;
      operand: string;
    } | undefined = this.pieChartTitleValue()

    return (
      <Box>
        <div style={{ height: "450px", position: "relative", textAlign: "center" }}>
          <div className="circle_title">
            <div className={'circle_one_New'} style={{ color: titleValue?.operand === '+' ? '#FFF' : 'rgb(238, 83, 83)' }}>{this.state.assetsType === "Type" ? this.state.cytotalNetworth.currency : null}{this.convertToK(titleValue?.value?.replace(/,/g, ''))}{this.state.assetsType === "Country" ? this.state.cytotalNetworth.currency : null}</div>
            {this.state.assetsType === "Country" ? <div className={'circle_two_New'}>{this.getStringDashbord('in')} {chartValue?.length || 0} {this.getStringDashbord('countries')}</div> : null}
            <div className={'circle_two_New'}>{chartValue?.length || 0} {this.getStringDashbord('TypesofFunds')}</div>
          </div>
          {chartValue && <ResponsivePie
            data={chartValue}
            margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
            innerRadius={0.65}
            padAngle={0.1}
            cornerRadius={0}
            colors={chartValue.map((ind: any) => ind.color)}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.0]] }}
            radialLabelsSkipAngle={10}
            radialLabelsLinkOffset={10}
            radialLabelsTextXOffset={20}
            radialLabel='label'
            radialLabelsLinkDiagonalLength={0}
            radialLabelsLinkHorizontalLength={0}
            radialLabelsLinkStrokeWidth={15}
            slicesLabelsSkipAngle={10}
            radialLabelsTextColor="white"
            slicesLabelsTextColor="#ffffff00"
            enableRadialLabels={false}
            data-test-id="clickedSlice"
            onClick={(event) => this.clickedSlice(event)}
            tooltip={(data: any) => {
              return (<>
                <span className="tooltip-content">
                  <div
                    style={{
                      color: '#000',
                      padding: '5px 20px',
                      textAlign: 'center',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '10px',
                    }}
                  > {`${data.percentage}${typeof data.percentage === 'number' ? '%' : ''}`}
                  </div>
                  <div
                    style={{
                      color: '#000',
                      padding: '5px 20px',
                      textAlign: 'center',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '10px',
                    }}
                  >{this.formatDataValueBalance(data.dataValueBalance, data.symbol)}
                  </div>
                  <div
                    style={{
                      color: '#000',
                      padding: '5px 20px',
                      textAlign: 'center',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '10px',
                    }}
                  >{this.state.logoImgDashboard === "rtl" ? this.getStringDashbordArabic(data.label) : data.label}
                  </div>
                  <div className="tooltip-arrow" />
                </span>
              </>)
            }}
          />}
        </div>
      </Box>
    )
  }
  formatDataValueBalance = (value: number | string, symbol: string) => {
    if (typeof value === 'number' && Number.isInteger(value)) {
      return `${symbol}${value.toLocaleString()}`;
    }
    return `${symbol}${value.toString()}`;
  }
  getStringDashbordArabic =  (key: string) => {
    const languageArabic = this.state.dashboradArabic[this.state.languages.toLowerCase()][key]
    return  languageArabic?languageArabic:key
  }

  getBankAccountData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('BankAccounts') })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.BankAccountDetailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/dashboards/bank_accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCashDetailData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('Cash') })

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cashDetailDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/cashes?page=1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSharesData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('Shares')})

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SharesDetailDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/shares?token=${this.state.token}&page=1`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPropertyData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('Property') })

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.propertyDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/properties?page=1`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPortableDetailsAssetData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('Portable') })

    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.portableAssetDetailsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/portable_assets?token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPensionDetailData = async () => {
    this.setState({ assetsDetailsTitle: this.getStringDashbord('Pensions') })

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pensionDetailDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/pensions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAssetsTypeDetails = (event: any) => {
    event.label == "Bank Accounts" && this.getBankAccountData()
    event.label == "Property" && this.getPropertyData()
    event.label == "Shares" && this.getSharesData()
    event.label == "Cash" && this.getCashDetailData()
    event.label == "Portable" && this.getPortableDetailsAssetData()
    event.label == "Pension" && this.getPensionDetailData()
  }

  clickedSlice = (event: any) => {
    this.setState({ assetsCountryDetails: '', showCountryValue: false, PropertyOverview: event.label })
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Type') {
      this.getAssetsTypeDetails(event)
    } else if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country') {
      this.getAssetCountryData(event)
      this.setState({ assetsCountryDetails: this.state.assetCountry, showCountryValue: true, PropertyOverview: event.label })

    } else if (this.state.currentCard !== 'Cash') {
      const requestGetPieChartDataOnClick = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.ChangeCurrencyCallOnClickId = requestGetPieChartDataOnClick.messageId;
      const headers = {
        token: this.state.token
      };
      if (this.state.currentCard == 'Investable_Assets') {
        requestGetPieChartDataOnClick.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${this.state.sectApiCard}${event.label.replace(" ", "_").toLowerCase()}`
        );
      } else {
        requestGetPieChartDataOnClick.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${this.state.sectApiCard}${event.label}`
        );
      }


      requestGetPieChartDataOnClick.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestGetPieChartDataOnClick.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );

      runEngine.sendMessage(requestGetPieChartDataOnClick.id, requestGetPieChartDataOnClick);
    }
  }

  setCard = (value: string) => {
    this.setState({ currentCard: value, debtsDetails: [], balance: '' })
    if (value == 'Assets') {
      this.setState({ sectApiCard: "" })
      this.currenCytotalNetworth()
    } else if (value == 'Debts') {
      this.setState({ sectApiCard: "/bx_block_dashboard/dashboards/debts_pie?debts=" })
      this.pieChartBoxData('debts_pie?debts=bank')
    } else if (value == 'Investable_Assets') {
      this.setState({ sectApiCard: "bx_block_budgetingforecasting/graph_charts/fetch_investable_records?type=" })
      this.pieChartInvestableAssets('investable_pie_chart')
    } else if (value == 'Cash') {
      this.setState({ sectApiCard: "" })
      this.pieChartInvestableAssets('cash_pie_chart')
    }

  }
  styleArabicRtlNew = () => {
    return this.state.logoImgDashboard === "rtl" ? "faq-content-wrapperes" : "faq-content-wrapper"
  }
  styleArabicCss = () => {
    return this.state.logoImgDashboard === "rtl" ? "faq-contents-new" : "faq-contents"
  }
  styleArabicRtl = () => {
    return this.state.logoImgDashboard === "rtl" ? this.styleArabicRtlNew() : "faq-content-wrapper-new"
  }
  showNormalDetails = () => {
    if (this.state.debtsDetails.length > 0 && this.state.currentCard !== 'Assets') {
      return (<div className="assets-block-content">
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p style={{ textTransform: 'capitalize' }}>{this.dashbordArabic()} </p>
                  <span>{this.state.debtsDetails.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.balance || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()} >
              {this.state.debtsDetails
                && this.state.debtsDetails?.map((value: any) => {
                  return (
                    <>
                      <div className={this.styleArabicRtl()}>
                        <div className="faq-inner-content inlineOne">
                          <p id="a">{value.name}</p>
                          <span className="portable testValueDash">
                            {value.value}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div data-test-id="viewDetail5" className="view-detail-btn">
              {" "}
              <span data-test-id="viewDetail51" style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    } else if (this.state.assetsTypeDetails.length > 0 && this.state.currentCard == 'Assets' && this.state.assetsType == 'Type') {
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.state.assetsDetailsTitle}</p>
                  <span>{this.state.assetsTypeDetails.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.assetsTypeDetailsBalance || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()} >
              {this.state.assetsTypeDetails
                && this.state.assetsTypeDetails?.map((value: any) => {
                  return (
                    <>
                      <div className={this.styleArabicRtl()}>
                        <div className="faq-inner-content mainBoxFilder">
                          <p id="a">{value.name}</p>
                          <span className="portable nameFilder">
                            {value.value}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div data-test-id="viewDetail6" className="view-detail-btn">
              {" "}
              <span data-test-id="viewDetail61" style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
dashbordArabic = () => {
 return this.state.logoImgDashboard === "rtl" ? this.getStringDashbordArabic(this.state.PropertyOverview?.replace(/_/g, ' ')) : this.state.PropertyOverview?.replace(/_/g, ' ')
}
  showBankAccounts = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue && this.state.assetsCountryDetails.result) {
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.getStringDashbord('BankAccounts')}</p>
                  <span>{this.state.assetsCountryDetails.result["bank accounts"].length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.cytotalNetworth.currency}{this.state.assetsCountryDetails.result["bank accounts"][0]?.total || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()} >
              {this.state.assetsCountryDetails.result["bank accounts"].map((value: any) => {
                return (
                  <>
                    <div className={this.styleArabicRtl()}>
                      <div className="faq-inner-content maonBoxOneLinearDash">
                        <p id="a">{value.name}</p>
                        <span className="portable maonLinearBalaneOne">
                          {this.state.cytotalNetworth.currency}{value.balance}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div data-test-id="viewDetail7" className="view-detail-btn">
              {" "}
              <span data-test-id="viewDetail71" style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
  showCashes = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue) {
      return (<div className="faq-wrapper" dir={this.state.logoImgDashboard}>
        <div
          className={this.state.expandProperty}
          data-test-id="propertytab"
        >
          <div className="faq-heading faq-heading-7">
            <div className="faq-inner-heading fih-6">
              <div className="faq-inner-heading-content faq-inner-heading-content3">
                <p>{this.getStringDashbord('CashNew')}</p>
                <span>{this.state.assetsCountryDetails.result?.cashes.length || 0} {this.getStringDashbord('AssetsNew')}</span>
              </div>
            </div>
            <div className="faq-heading-content faq-heading-content7">
              <div className="inner-info">
                <span>{this.getStringDashbord('Balance')}</span>
                <p>
                  {this.state.cytotalNetworth.currency}{this.state.assetsCountryDetails.result?.cashes[0]?.total || 0}
                </p>
              </div>
              <div className="down-arrow">
                <img src={subarrowicon}
                />
              </div>
            </div>
          </div>
          <div className={this.styleArabicCss()}>
            {this.state.assetsCountryDetails.result?.cashes?.map((value: any) => {
              return (
                <>
                  <div className={this.styleArabicRtl()}>
                    <div className="faq-inner-content manGypeBox">
                      <p id="a">{value.name}</p>
                      <span className="portable normalOne">
                        {this.state.cytotalNetworth.currency}{value.balance}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div data-test-id="viewDetail14" className="view-detail-btn">
            {" "}
            <span data-test-id="viewDetail85" style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
          </div>
        </div>
      </div>)
    }
  }
  showPension = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue) {
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.getStringDashbord('Pensions')}</p>
                  <span>{this.state.assetsCountryDetails.result?.pensions.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.cytotalNetworth.currency}{this.state.assetsCountryDetails.result?.pensions[0]?.total || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()}>
              {this.state.assetsCountryDetails.result?.pensions?.map((value: any) => {
                return (
                  <>
                    <div className={this.styleArabicRtl()} >
                      <div className="faq-inner-content" data-test-id="viewDetail58">
                        <p id="a">{value.name}</p>
                        <span className="portable" data-test-id="viewDetail59">
                          {this.state.cytotalNetworth.currency}{value.balance}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div data-test-id="viewDetail14" className="view-detail-btn">
              {" "}
              <span data-test-id="viewDetail85" style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
  showPortableAssets = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue && this.state.assetsCountryDetails.result) {
      const data = this.state.assetsCountryDetails.result["portable assets"]
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.getStringDashbord('PortableAssets')}</p>
                  <span>{data.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.cytotalNetworth.currency}{data[0]?.total || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()}>
              {data.map((value: any) => {
                return (
                  <>
                    <div className={this.styleArabicRtl()}>
                      <div className="faq-inner-content newMainOneCountryIn">
                        <p id="a">{value.name}</p>
                        <span className="portable newMainOneCountryOut">
                          {this.state.cytotalNetworth.currency}{value.balance}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div data-test-id="viewDetail4" className="view-detail-btn newMainOneCountryMax">
              {" "}
              <span style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
  showProperty = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue) {
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.getStringDashbord('Properties')}</p>
                  <span>{this.state.assetsCountryDetails.result?.properties.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.cytotalNetworth.currency}{this.state.assetsCountryDetails.result?.properties[0]?.total || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()}>
              {this.state.assetsCountryDetails.result?.properties?.map((value: any) => {
                return (
                  <>
                    <div className={this.styleArabicRtl()} >
                      <div className="faq-inner-content DashNewOneVixIn">
                        <p id="a">{value.name}</p>
                        <span className="portable DashNewOneVixOut">
                          {this.state.cytotalNetworth.currency}{value.balance}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div data-test-id="viewDetail45" className="view-detail-btn">
              {" "}
              <span style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
  showShares = () => {
    if (this.state.currentCard == 'Assets' && this.state.assetsType == 'Country' && this.state.showCountryValue) {
      return (<div className="assets-block-content" dir={this.state.logoImgDashboard}>
        <div className="faq-wrapper">
          <div
            className={this.state.expandProperty}
            data-test-id="propertytab"
          >
            <div className="faq-heading faq-heading-7">
              <div className="faq-inner-heading fih-6">
                <div className="faq-inner-heading-content faq-inner-heading-content3">
                  <p>{this.getStringDashbord('Shares')}</p>
                  <span>{this.state.assetsCountryDetails.result?.shares.length || 0} {this.getStringDashbord('AssetsNew')}</span>
                </div>
              </div>
              <div className="faq-heading-content faq-heading-content7">
                <div className="inner-info">
                  <span>{this.getStringDashbord('Balance')}</span>
                  <p>
                    {this.state.cytotalNetworth.currency}{this.state.assetsCountryDetails.result?.shares[0]?.total || 0}
                  </p>
                </div>
                <div className="down-arrow">
                  <img src={subarrowicon}
                  />
                </div>
              </div>
            </div>
            <div className={this.styleArabicCss()}>
              {this.state.assetsCountryDetails.result?.shares?.map((value: any) => {
                return (
                  <>
                    <div className={this.styleArabicRtl()}>
                      <div className="faq-inner-content DahboardDetailsMainBoxOneOut">
                        <p id="a">{value.name}</p>
                        <span className="portable DahboardDetailsMainBoxOneIn">
                          {this.state.cytotalNetworth.currency}{value.balance}
                        </span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div data-test-id="viewDetail4" className="view-detail-btn DahboardDetailsMainBoxOneOutin">
              {" "}
              <span style={this.state.viewDetail}>{this.getStringDashbord('viewdetails')}</span>
            </div>
          </div>
        </div>
      </div>)
    }
  }
  async pieChartInvestableAssets(endPoint: string | undefined) {
    const requestGetPieChartData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewPieChartData = requestGetPieChartData.messageId;
    const headers = {
      token: this.state.token
    };

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_budgetingforecasting/graph_charts/${endPoint}`
    );

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestGetPieChartData.id, requestGetPieChartData);
  }

  async pieChartBoxData(endPoint: string | undefined) {
    const requestGetPieChartData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPieChartData = requestGetPieChartData.messageId;
    const headers = {
      token: this.state.token
    };

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/dashboards/${endPoint}`
    );

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestGetPieChartData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestGetPieChartData.id, requestGetPieChartData);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCurrencyListCalId !== null &&
      this.getCurrencyListCalId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortable = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.receiveHandler(responseJsonPortable, () => {

        this.setState({
          currencyList: responseJsonPortable
        })
      })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ChangeCurrencyCallId !== null &&
      this.ChangeCurrencyCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortable = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.loadCurrencyData();
      this.currenCytotalNetworth();
      this.receiveHandler(responseJsonPortable, () => {
        this.loadCurrencyData();
      })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCytotalNetworth !== null &&
      this.getCytotalNetworth ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCytotalNetworth = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        cytotalNetworth: responseJsonCytotalNetworth, pieChartData: responseJsonCytotalNetworth.piechart
      })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPieChartData !== null &&
      this.getPieChartData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCytotalNetworth = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        pieChartData: responseJsonCytotalNetworth.pie
      })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getNewPieChartData !== null &&
      this.getNewPieChartData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPieChart = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.changeResponse(responseJsonPieChart)
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ChangeCurrencyCallOnClickId !== null &&
      this.ChangeCurrencyCallOnClickId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClick = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getChartResponse(responseJsonPortableClick)
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTypeCountryCallOnClickId !== null &&
      this.getTypeCountryCallOnClickId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickOne = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ assetCountry: responseJsonPortableClickOne, assetsCountryDetails: responseJsonPortableClickOne })
    }
    this.getArabicData(message)
    this.getReciveData(message)
    // Customizable Area End
  }

  getArabicData = (message: any) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickOne = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ dashboradArabic: responseJsonPortableClickOne})
    }
  }

  getReciveData = (message: any) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.BankAccountDetailCallId !== null &&
      this.BankAccountDetailCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickTwo = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let symbol = ''
      let mainBalance = 0
      const bankData = responseJsonPortableClickTwo?.data?.map((value: any) => {
        mainBalance = mainBalance + parseInt(value.attributes?.balance)
        symbol = value.attributes?.symbol
        return { name: value.attributes?.account_number, value: `${value.attributes?.symbol}${value.attributes?.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbol}${mainBalance}`, assetsTypeDetails: bankData })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.cashDetailDataCallId !== null &&
      this.cashDetailDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickOne = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let symbolOne = ''
      let mainBalanceOne = 0
      const bankData = responseJsonPortableClickOne?.data?.map((value: any) => {
        mainBalanceOne = mainBalanceOne + parseInt(value.attributes?.balance)
        symbolOne = value.attributes?.converted_currency_symbol
        return { name: value.attributes?.name, value: `${value.attributes?.converted_currency_symbol}${value.attributes?.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbolOne}${mainBalanceOne}`, assetsTypeDetails: bankData })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.SharesDetailDataCallId !== null &&
      this.SharesDetailDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickTwo = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let symbolTwo = ''
      let mainBalanceTwo = 0
      const bankData = responseJsonPortableClickTwo?.data?.map((value: any) => {
        mainBalanceTwo = mainBalanceTwo + parseInt(value.attributes?.balance)
        symbolTwo = value.attributes?.converted_currency_symbol
        return { name: value.attributes?.name, value: `${value.attributes?.converted_currency_symbol}${value.attributes?.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbolTwo}${mainBalanceTwo}`, assetsTypeDetails: bankData })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.propertyDetailsCallId !== null &&
      this.propertyDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickThree = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let symbolThree = ''
      let mainBalanceThree = 0
      const bankData = responseJsonPortableClickThree.data.map((value: any) => {
        mainBalanceThree = mainBalanceThree + parseInt(value.attributes.balance)
        symbolThree = value.attributes.converted_currency_symbol
        return { name: value.attributes.name, value: `${value.attributes.converted_currency_symbol}${value.attributes.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbolThree}${mainBalanceThree}`, assetsTypeDetails: bankData })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.portableAssetDetailsDataCallId !== null &&
      this.portableAssetDetailsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickThree = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let symbolaOne = ''
      let mainBalanceaOne = 0
      const bankData = responseJsonPortableClickThree.data.map((value: any) => {
        mainBalanceaOne = mainBalanceaOne + parseInt(value.attributes.balance)
        symbolaOne = value.attributes.converted_currency_symbol
        return { name: value.attributes.name, value: `${value.attributes.converted_currency_symbol}${value.attributes.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbolaOne}${mainBalanceaOne}`, assetsTypeDetails: bankData })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pensionDetailDataCallId !== null &&
      this.pensionDetailDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonPortableClickFour = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let symbolbOne = ''
      let mainBalancebFour = 0
      const bankData = responseJsonPortableClickFour.data.map((value: any) => {
        mainBalancebFour = mainBalancebFour + parseInt(value.attributes.balance)
        symbolbOne = value.attributes.converted_currency_symbol
        return { name: value.attributes.name, value: `${value.attributes.converted_currency_symbol}${value.attributes.balance}` }
      })
      this.setState({ assetsTypeDetailsBalance: `${symbolbOne}${mainBalancebFour}`, assetsTypeDetails: bankData })
    }
  }

  getChartResponse = (responseJsonPieChart: any) => {
    if (responseJsonPieChart.all_debts) {
      let balance = 0
      const getMainVlaue = responseJsonPieChart.details.data.map((value: any) => {
        balance = balance + parseInt(value.attributes.balance)
        return { name: value.attributes.name, value: value.attributes.converted_currency_symbol + value.attributes.balance }
      })
      this.setState({ debtsDetails: getMainVlaue, balance: responseJsonPieChart.currency + balance })
    }
    if (responseJsonPieChart.overview) {
      const getMainVlaue = responseJsonPieChart.overview.map((value: any) => {
        return { name: value.name, value: responseJsonPieChart.currency + value.balance }
      })
      this.setState({ debtsDetails: getMainVlaue, balance: responseJsonPieChart.currency + responseJsonPieChart.total })
    }
  }

  changeResponse = (responseJsonPieChart: any) => {
    if (responseJsonPieChart.investable_overview) {
      this.setState({
        pieChartData: responseJsonPieChart.investable_overview.pie_chart
      })
    } else if (responseJsonPieChart.cash_overview) {
      const getMainVlaue = responseJsonPieChart.cash_overview.pie_chart.map((value: any) => {
        return { name: value.name, value: `${responseJsonPieChart.cash_overview.currency}${value.balance}` }
      })
      this.setState({
        pieChartData: responseJsonPieChart.cash_overview.pie_chart,
        debtsDetails: getMainVlaue, balance: responseJsonPieChart.cash_overview.currency + responseJsonPieChart.cash_overview.total
      })
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async receiveHandler(responseJson: any, receiveCallback: any) {
    if (responseJson) {
      receiveCallback && receiveCallback();
    }
  }

  async loadCurrencyData() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrencyListCalId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/currency_list'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async currenCytotalNetworth(Type: string = 'Type') {
    const requestMessagessCytotalNetworth = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCytotalNetworth = requestMessagessCytotalNetworth.messageId;
    const headers = {
      token: this.state.token
    };
    requestMessagessCytotalNetworth.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/dashboards/default_dashboard_home?pie_chart_type=${Type == 'Type' ? "assets" : "country"}`
    );

    requestMessagessCytotalNetworth.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessagessCytotalNetworth.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessagessCytotalNetworth.id, requestMessagessCytotalNetworth);
  }

  onAddCurrencyToAccountDashboard = async (currencyId: any) => {
    const putRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ChangeCurrencyCallId = putRequestMessage.messageId;
    const putheader = {
      token: this.state.token
    };

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(putheader)
    );

    const putFormData = new FormData();
    putFormData.append('currency_id', currencyId);

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), putFormData);

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );

    putRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(putRequestMessage.id, putRequestMessage);
  }
  firstStyleOne = (name: any, webStyle: any) => {
    return this.state.currentCard == name ? webStyle.selectCardBoxTotalInc :
      webStyle.normalCardBoxTotalDic
  }
  firstStyle = (allCashOperand: any, name: any, webStyle: any) => {
    return allCashOperand == '+' ? this.firstStyleOne(name, webStyle) : webStyle.selectCardBoxTotalDic
  }

  secondStyleOne = (name: any, webStyle: any) => {
    return this.state.currentCard == name ? webStyle.selectManageValueDic : webStyle.normalManageValueInc
  }
  secondStyle = (monthCashOperand: any, name: any, webStyle: any) => {
    return monthCashOperand == '+' ? this.secondStyleOne(name, webStyle) : webStyle.normalManageValueDic
  }

  thirdStyleOne = (name: any, webStyle: any) => {
    return this.state.currentCard == name ? webStyle.selectManageValueDic : webStyle.normalManageValueInc
  }

  thirdStyle = (yearCashOperand: any, name: any, webStyle: any) => {
    return yearCashOperand == '+' ? this.thirdStyleOne(name, webStyle) : webStyle.normalManageValueDic
  }

  selectDropdowon = (selectedCashCurrency: any, cash_currency_list: any) => {
    return <Select
      displayEmpty
      value={selectedCashCurrency ? selectedCashCurrency.id : ''}
      data-test-id="select-change"
      onChange={async (e: any) => {
        if (e.target.value) {
          await this.onAddCurrencyToAccountDashboard(e.target.value)
        }
      }}
      className="main-dashboard-an-select-dropdown"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      {cash_currency_list.length > 0 &&
        cash_currency_list.map((currency: any) => (
          <MenuItem key={currency.id} value={currency.id} className="main-dashboard-an-custom-menu-item">
            {`${currency.symbol} ${currency.currency_type}`}
          </MenuItem>
        ))}
    </Select>
  }

  getPensionList = () => {
    const addPensionData = localStorage.getItem("token")
    const header = { "token": addPensionData };
    let userDetailurlPension = configJSON.exampleAPiMethodtoken;
    const postPdfData = apiCall({
        header: header,
        httpBody: {},
        url: userDetailurlPension,
        httpMethod: configJSON.LoginsubmitGet,
    });
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
}

  // Customizable Area End
}
