import React from "react";

// Customizable Area Start
import '../assets/liabilityAsset.css';
import '../assets/confirmationModal.css';
import { FormControl, Box, styled, Button, Grid, Menu } from '@material-ui/core'
import { addCommas, formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";
import Select from "react-select";
import { headereBackIcon, settingicon, vertical3Dots, card, mortgageListImg, personalLoanListImg, otherLoanListImg } from "./../src/assets";
// Customizable Area End

import LiabilityOverviewController, {
  Props,
} from "./LiabilityOverviewController";

export default class LiabilityAssets extends LiabilityOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getCardImage = () => {
    switch (this.state.showLiability) {
      case this.formListTypes.PersonalLoan:
        return personalLoanListImg;
      case this.formListTypes.OtherLoan:
        return otherLoanListImg;
      case this.formListTypes.Mortgage:
      default:
        return mortgageListImg;
    }
  }

  renderPensionDeleteConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="pension-modal-background">
          <div className="pension-modal-popup-container">
            <div className="pension-modal-title">{this.getString("deleteConfirm")}</div>
            <div className="pension-modal-button-container">
              <div className="pension-modal-button modal-save" data-test-id='pension-popup-cancel-click'
                onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                {this.getString("Cancel")}
              </div>

              <div className="pension-modal-button modal-discard" data-test-id='pension-popup-delete-click'
                onClick={() => {
                  this.setState({ isDeleteConfimationModalOpen: false });
                  this.handleDeleteLiabilityData(this.state.currentCardId);
                }}>
                {this.getString("Delete")}
              </div>
            </div>

          </div>
        </div>
      )
    }
  }

  renderCreditCardDetails() {
    return (
      this.state.creditCardDetails.transformed_response.length > 0
        && this.state.creditCardDetails.transformed_response.map((value) => {
          return (
            <>
              <div className="faq-content-wrapper" id="faqContentWrapper">
                <img
                  data-test-id="iconofstock"
                  src={value.attributes.bank_logo || card}
                  alt="bank-account"
                  width="45"
                  height="45"
                />
                <div
                  data-test-id="faqcontentforstock"
                  className="faq-inner-content"
                >
                  <p>{value.attributes.bank_account_number || this.getString("CreditCard")}</p>
                  <span style={value.attributes.converted_amount ? {color: '#E25153' }: {}} className="stock">
                    {value.attributes.converted_amount ? "-" : ""}{`${value.attributes
                      .currency_symbol ?? "$"}`}{`${formatNumberWithCommasAndDecimals(value.attributes.converted_amount?.toString() ?? '0')}`}
                  </span><br />

                </div>
              </div>
            </>
          );
        })
    )
  }

  handleCardData = () => {
    if (this.state.showLiability === this.formListTypes.CreditCard) {
      return (
        <>
          <div className="assets-block" id="StockDetailassetsBlock">
            <div className="assets-block-content" id="assetsBlockInStockDetails">
              <div className="faq-wrapper" data-test-id="faqWrapperInStockDetails">

                <div
                  className="faq-block active"
                  data-test-id="stocksDetails"
                >
                  <div className="faq-heading faq-heading-3 stocksDetails">
                    <div className="faq-inner-heading fih-StocksDetails">
                      <img src={card} alt="bankimage" height={'45px'} />
                      <div className="faq-inner-heading-content" id="innerContentInStocksDetails">
                        <p>{this.getString("CreditCards")}</p>
                      </div>
                    </div>
                    <div data-test-id="stockDetailsBalance" className="faq-heading-content faq-heading-content3">
                      <div className="inner-info" id="sdInnerInfo">
                        <span>{this.getString("Balance")}</span>
                        <p className="overviewStockSymbol" style={this.state.creditCardDetails.total_balance ? { color: '#E25153' }: {}}>
                          {this.state.creditCardDetails.total_balance ? "-" : ""}{this.state.creditCardDetails.currency_symbol ?? "$"}{formatNumberWithCommasAndDecimals(this.state.creditCardDetails.total_balance.toString())}
                        </p>
                      </div>

                    </div>
                  </div>
                  <div className="faq-content" id="stockListing">
                      {this.renderCreditCardDetails()}                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    if (this.state.showLiability !== this.formListTypes.CreditCard) {
      return (
        <>
          <div className="liability-asset-cards">
            <Grid container spacing={2} className="liability-card-grid-container" style={this.state.direction === "rtl" ? {paddingLeft: "160px", paddingRight: "unset"}: {}}>
              {
                this.state.dataList?.length > 0 && this.state.dataList.map((data, index) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4}>
                      <div className="lb-card" key={data.id}>
                        <div className="lb-card-top">
                          <div className="pension-card-left-upload">
                            <img src={this.getCardImage()} alt='image' />
                            <div
                              className="white-lb-title">
                              {data.attributes.name}
                            </div>
                          </div>

                          <div className="pension-upper-card-right">
                            <img
                              data-test-id={`pension-vertical-icon-click-${index}`}
                              onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id)}
                              src={vertical3Dots}
                              alt='vertival-dots' />
                            {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                              <Menu
                              id="simple-menu"
                          data-test-id="cash-popup-menu"
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleDocumentMenuModalClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: {width:'55px',background:"#395D6B", padding:"0 5px", borderRadius:'8px'}
                          }}
                              >

                                <Box
                                  className="pension-edit-option"
                                  onClick={() => this.handleEditLiability(data)}
                                  data-test-id={`pension-edit-${index}`} >
                                  {this.getString("Edit")}
                                </Box>
                                <div className="pension-delete-option"
                                  onClick={() => {
                                    this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                                  }}
                                  data-test-id={`pension-delete-${index}`}>
                                  {this.getString("Delete")}
                                </div>
                              </Menu>
                            )}
                          </div>
                        </div>
                        <div className="pension-card-bottom">
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("Loanamount")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section-top-three">{addCommas(data.attributes.converted_amount.toString())}</div>
                          </div>
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("Terms")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section-top-three">{data.attributes.total_months} {this.getString("Months")}</div>
                          </div>
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("AIR")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section-top-three">{data.attributes.annual_interest_rate}%</div>
                          </div>
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("Installmentamount")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section">{data.attributes.converted_currency_symbol} {addCommas((Math.round(data.attributes.converted_monthly_payments * 100) / 100).toString())}</div>
                          </div>
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("Monthsremaining")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section">{data.attributes.remaining_months} {this.getString("months")}</div>
                          </div>
                          <div className="lb-card-bottom-content-container">
                            <div className="lb-sub-tile-bold-section">
                              {this.getString("Amountremaining")}
                            </div>
                            <div className="lb-middlesub-tile-normal-section">{data.attributes.converted_currency_symbol} {addCommas(Math.round(Number(data.attributes.remaining_payment)).toString())}</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )
                }
                )
              }
            </Grid>
          </div>
        </>
      )
    }
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div className="pension-asset-overview-main-container" dir={this.state.direction}>
        <div className="pension-asset-overview-container">
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "40px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                src={headereBackIcon}
                style={{transform: this.state.direction === "rtl" ? "rotate(180deg)" : "0deg", ...webStyle.backbtnStyle}}
                data-test-id="handle-back"
                onClick={() => this.navigateRoute("Dashboard")}
              />
              <div
                style={webStyle.titleTxt}
              >
                {this.getString("Liability")}
              </div>
            </Box>
            <Box className="addCashrightHeaderDropSetting">
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "30px",
                }}
              >
                <FormControl>
                  <Select
                    options={this.state.currencyListDropDown}
                    value={this.state.selectedCurrency}
                    onChange={(value) => {
                      value?.value && this.setCurrency(value);
                    }}
                    classNamePrefix="an-simple-selectUSD"
                    className="selectUSD"
                    data-test-id="selectMenu"
                  />
                </FormControl>
                <Box
                  onClick={() => this.navigateRoute("MyneSettingsProfile")}
                  style={{ padding: '0px 20px' }} data-test-id="accountDetail"                    >
                  <img
                    src={settingicon}
                    style={{
                      width: "41px",
                      height: "41px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={3} style={{ marginBottom: "20px" }}>
            <Grid item>
              <ButtonStyle style={{ background: this.state.showLiability == this.formListTypes.Mortgage ? "#2CE2D5" : "#C9F8F6" }} data-test-id="handleSeleteItems1" onClick={() => { this.handleSelectBtn(this.formListTypes.Mortgage) }}>{this.getString("Mortgages")}</ButtonStyle>
            </Grid>
            <Grid item>
              <ButtonStyle style={{ background: this.state.showLiability == this.formListTypes.CreditCard ? "#2CE2D5" : "#C9F8F6" }} data-test-id="handleSeleteItems2" onClick={() => { this.handleSelectBtn(this.formListTypes.CreditCard) }}>{this.getString("Creditcards")}</ButtonStyle>
            </Grid>
            <Grid item>
              <ButtonStyle style={{ background: this.state.showLiability == this.formListTypes.PersonalLoan ? "#2CE2D5" : "#C9F8F6" }} data-test-id="handleSeleteItems3" onClick={() => { this.handleSelectBtn(this.formListTypes.PersonalLoan) }}>{this.getString("PersonalLoans")}</ButtonStyle>
            </Grid>
            <Grid item>
              <ButtonStyle style={{ background: this.state.showLiability == this.formListTypes.OtherLoan ? "#2CE2D5" : "#C9F8F6" }} data-test-id="handleSeleteItems4" onClick={() => { this.handleSelectBtn(this.formListTypes.OtherLoan) }}>{this.getString("Otherloans")}</ButtonStyle>
            </Grid>
          </Grid>
          <div>
            {
              this.handleCardData()
            }
          </div>

        </div>
        {
          this.renderPensionDeleteConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonStyle = styled(Button)({
  borderRadius: "4px", background: "#C8F9F6", color: "#000", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, padding: "5px 20px", textTransform: "capitalize", '&:hover': {
    backgroundColor: "#395D6B",
  },
})
type IStyle = { [key: string]: React.CSSProperties };
const webStyle: IStyle = {
  backbtnStyle:{
    width: "24px",
    height: "13px",
    cursor: "pointer",
  }, 
  titleTxt:{
    color: "white",
    padding: "0px 20px",
    fontSize: "21px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  
};
// Customizable Area End