import React from "react";
// Customizable Area Start
import "../../apiintegrationbankaccounts/src/style.css"
import { image_Shape } from "../../apiintegrationbankaccounts/src/assets";
import EngagementRequestController, {
    Props,
} from "./EngagementRequestController.web";
import FormApprovalWorkflowController from "./FormApprovalWorkflowController.web";


// Customizable Area End


export default class RescheduledSuccess extends FormApprovalWorkflowController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        setTimeout(() => {
            if (this.state.isServiceSelected) {
                this.redirectToSomeRoute('EstatePlanning')
            }
            else {
                this.props.navigation.navigate("EngagementRequest", { id: "id" })
            }
        },1000)
        return (
            // Customizable Area Start
            <div style={{width: '100vw'}} className="mainBank-wrapper">
                <div className="bank-integraion-wrapper">
                    <div style={{maxHeight: "500px"}} className="bank-integration-box d-flex align-center">
                        <div className="successful-img-content">
                            <img src={image_Shape} />
                            <p>Your call has rescheduled successfully !
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
