import React from "react";

// Customizable Area Start

import { logo, closeBtn } from "./assets";
import "./style.css";
// Customizable Area End

import TermsConditionsController, {
    Props,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (

            <div className="main-wrapper">
                {!this.state.isArabic ?
                    <div className="terms-condi-wrapper">
                        <div className="terms-condi-box">
                            <div className="terms-condi-block">
                                <img src={logo} alt="logo" />
                                <h4>Terms & Conditions</h4>
                                <span className="close-btn-tnc" onClick={() => this.handleGoBack()}><img src={closeBtn} alt="close" /></span>
                            </div>
                            <div className="terms-condi-content">
                                <div className="inner-content">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionsForUser }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="terms-condi-wrapper arabic-style">
                        <div className="terms-condi-box">
                            <div className="terms-condi-block">
                                <div className="terms-img-with-text">
                                    <img src={logo} alt="logo" />
                                    <h4>Terms & Conditions</h4>
                                </div>
                                <div className="close-btn-direction">
                                    <span className="close-btn-tnc" onClick={() => this.handleGoBack()}><img src={closeBtn} alt="close" /></span>
                                </div>
                            </div>
                            <div className="terms-condi-content">
                                <div className="inner-content">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionsForUser }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End
