import React from "react";

// Customizable Area Start
import {
  Box, Grid, Switch, ThemeProvider, Typography, createTheme,
} from "@material-ui/core";
import '../assets/css/myneSettingsCurrency.css'
import { Link } from 'react-router-dom'
import { styled } from "@material-ui/styles";
// Customizable Area End

import EstatePlanningPreferencesController, {
  Props,
  configJSON,
} from "./EstatePlanningPreferencesController";

export default class EstatePlanningPreferences extends EstatePlanningPreferencesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
    theme = createTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    color: "green",
                    '&.Mui-checked': {
                        color: "green",
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: "green",
                    },
                },
            },
        },
    });

    roundedNew = () => {
        return this.state.logoImgsetting === "rtl" ? "cur-close-rounded cur-close-rounded-new" : "cur-close-rounded"
    }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <ThemeProvider theme={this.theme}>
            {configJSON.name}
            <div className="main-currency-container" style={{ maxHeight: "100vh" }} dir={this.state.logoImgsetting}>
                <div className="setting-profile-currency-container" style={{ overflowY: "auto" }}>
                    <div className="cur-small-text" style={{ width: "calc(100% - 30px)" }}>
                        {`Settings`}
                        <Link to={'/Dashboard'} className={this.roundedNew()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                        </Link>
                    </div>
                    <div className="cur-bold-text">Estate Planning Preferences</div>
                    <ThemeWrapper>
                        <Box className="content-container">
                            <Typography>Allow data sharing to wealth planners you have currently worked with and working with. This allow the platform to share information about your networth and asset distribution to your matched wealth planners for your estate planning services.</Typography>
                            <Grid container spacing={2}>
                                {this.state.plannerList.map(planner => 
                                    <Grid item xs={12} key={planner.id}>
                                        <Box className="item-container">
                                            <Box className="item-content">Allow Myne to share networth data to {planner.attributes.name}</Box>
                                            <Switch data-test-id="toggle-switch" onChange={(event, checked) => this.handlePlannerPermission(checked, planner.attributes.planner_id)} color="default" checked={planner.attributes.allow_access} />
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Box className="edit-questionnaire-container" data-test-id="redirect-questionnaire" onClick={() => this.navigateRoute("FinancialQuestion", JSON.stringify({edit: true}))}>
                                        <Box className="item-text">Edit Questionnaire</Box>
                                        <Box className="item-subtext">Edit estate planning questionnaire answers</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </ThemeWrapper>
                </div>
            </div>
        </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ThemeWrapper = styled(Box)({
  "& .content-container": {
    marginTop: "15px",
},
"& .content-container p": {
    color: "white",
    opacity: "70%",
    fontSize: "16px",
    marginBottom: "15px",
  },
  "& .item-container": {
    background: "#3C3C51",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "15px",
    borderRadius: "15px",
    maxWidth: "750px",
    border: "1px solid #4f4f4f",
  },
  "& .item-content": {
    color: "white",
    fontSize: "14px",
  },
  "& .edit-questionnaire-container": {
    background: "#3C3C51",
    padding: "15px",
    borderRadius: "15px",
    maxWidth: "750px",
    border: "1px solid #4f4f4f",
    cursor: "pointer",
  },
  "& .item-text": {
    color: "white",
    fontSize: "14px",
  },
  "& .item-subtext": {
    color: "white",
    opacity : "70%",
    fontSize: "12px",
  },
});
// Customizable Area End