import React from "react";

// Customizable Area Start

import { SuccessCard, image_Calendar, imgArrow, imgSetting, } from "./assets";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button as MuiButton, Typography } from "@material-ui/core"
import { Box, CssBaseline, FormControl, Grid, OutlinedInput, ThemeProvider, createTheme, styled } from "@material-ui/core";
import Select from 'react-select';
import { ErrorMessage, Formik } from "formik";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

let theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
});

theme = createTheme(theme, {
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                }
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersCalendarHeader: {
            dayheader: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",
            },
            switchHeader: {
                color: "rgb(181, 176, 176)",
                backgroundColor: "rgb(57, 93, 107)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#33abb6",
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
        },
    }
})

// Customizable Area End

import LiabilityController, {
    Props,
    configJSON,
} from "./LiabilityController";

export default class LiabilityForm extends LiabilityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainWrapper dir={this.state.direction}>
                <Box className="sideBorder wrapper">
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Box className="innerWrapper">
                            <Box className="headerMain">
                                <Box className="headerTitle">
                                    <Box><img className={`backImage ${this.state.direction === 'rtl' ? 'rtlRotate' : ''}`} src={imgArrow} data-test-id="backButton" onClick={() => this.handleBack()} /></Box>
                                    <Box style={{ fontSize: '20px', color: 'white', padding: '0px 20px', fontFamily: 'Poppins', fontWeight: 600 }}>{this.getString("title")}</Box>
                                </Box>
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl>
                                            <Select
                                                value={this.state.selectedCurrency}
                                                onChange={(value) => this.setCurrency({label: value!.label, value: value!.value})}
                                                options={this.state.currencyList.map(currency => (
                                                    {
                                                        label: currency.currency_type,
                                                        value: currency.currency_type
                                                    }))
                                                }
                                                classNamePrefix="an-simple-selectUSDNew"
                                                data-test-id="selectCurrencyMenu"
                                                className="selectUSDNew"
                                            />
                                        </FormControl>
                                        <Box>
                                            <Box onClick={() => this.navigateRoute("Account-Detail")}>
                                                <img style={{ width: '41px', height: '40px', cursor: "pointer" }} src={imgSetting} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ paddingTop: '15px' }}>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        liabilityId: null,
                                        name: this.InitData(this.state.editLiabilityForm, this.state.editLiabilityFormData?.attributes.name, ""),
                                        amount: this.InitData(this.state.editLiabilityForm,Math.round(Number(this.state.editLiabilityFormData?.attributes.converted_amount) * 100) / 100,""),
                                        term: this.InitData(this.state.editLiabilityForm,this.state.editLiabilityFormData?.attributes.term,""),
                                        months: this.InitData(this.state.editLiabilityForm,this.state.editLiabilityFormData?.attributes.months,""),
                                        annualInterestRate: this.InitData(this.state.editLiabilityForm,this.state.editLiabilityFormData?.attributes.annual_interest_rate,""),
                                        monthlyPayments: this.InitData(this.state.editLiabilityForm,Math.round(Number(this.state.editLiabilityFormData?.attributes.converted_monthly_payments) * 100) / 100,""),
                                        startDate: this.InitData(this.state.editLiabilityForm,this.state.editLiabilityFormData?.attributes.start_date,null),
                                        firstPayment: this.InitData(this.state.editLiabilityForm,this.state.editLiabilityFormData?.attributes.first_payments,null),
                                        currency_type: this.InitCurrency(),
                                        associatedInvestment: this.InitAccociatedInvestment()
                                    }}
                                    onSubmit={this.handleSubmit}
                                    validationSchema={this.LiabilityFormSchema}
                                    data-test-id="LiabilityForm"
                                >
                                    {({ errors, values, handleSubmit, handleChange, setFieldValue, setErrors, resetForm }) => (
                                        <form onSubmit={handleSubmit} data-test-id='shareForm'>
                                            <Box>
                                                <Box>
                                                    <Box>
                                                        {this.getFormType().value !== this.LiabilityType.Other && <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                            <Box>
                                                                <Typography>{this.getString("associatedInvestment")}</Typography>
                                                                <Box className="cash_uploadBox" data-test-id="addCashCurrenNewcyDropdown">
                                                                    <Select
                                                                        value={values.associatedInvestment}
                                                                        options={this.getInvestmentOptions()}
                                                                        onChange={(value) => {
                                                                            resetForm()
                                                                            value && this.handleInvestmentChange({label: value.label, value: value.value}, setFieldValue)
                                                                        }}
                                                                        placeholder={this.getString("select")}
                                                                        name="associatedInvestment"
                                                                        classNamePrefix="an-simple-select"
                                                                        className="selectMenu"
                                                                        data-test-id="test-input-associatedInvestment"
                                                                        styles={{
                                                                            control: (defaultStyles) => ({
                                                                                ...defaultStyles,
                                                                                textAlign: "left",
                                                                                fontSize: '16px',
                                                                                color: 'rgba(255, 255, 255, 0.60)',
                                                                                backgroundColor: "#2a2a38",
                                                                                fontWeight: 500,
                                                                                padding: "8px",
                                                                                fontFamily: 'Poppins',
                                                                                borderRadius: '8px',
                                                                                outline: "none"
                                                                            })
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                    <ErrorMessage name="associatedInvestment"/>
                                                                </Box>
                                                            </Box>
                                                        </Box>}
                                                        <Box className="inputWrapper">
                                                            <Typography className="">{this.getString("name")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput
                                                                    className="inputRoot"
                                                                    placeholder={this.getString("placeholderName")}
                                                                    data-test-id="test-input-name"
                                                                    fullWidth={true}
                                                                    name="name"
                                                                    type="text"
                                                                    value={values.name}
                                                                    onChange={(event) => {
                                                                        handleChange("name")(event)
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="name"/>
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("amount")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput
                                                                    className="inputRoot"
                                                                    placeholder={this.getString("placeholderAmount")}
                                                                    data-test-id="test-input-amount"
                                                                    fullWidth={true}
                                                                    name="amount"
                                                                    type="text"
                                                                    value={this.handleFormat(values.amount)}
                                                                    style={addLiabilitywebStyle.ColorWhite}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;

                                                                        const rawValue = typeof value === 'string' ? value.replace(/,/g, '') : '';

                                                                        if (rawValue && !isNaN(parseFloat(rawValue))) {
                                                                            this.calculateMortgageMonthlyPayment({ ...values, amount: parseFloat(rawValue) }, setFieldValue);
                                                                            handleChange("amount")({ ...event, target: { ...event.target, value: rawValue } });
                                                                        } else {
                                                                            handleChange("amount")({ ...event, target: { ...event.target, value: '' } });
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="amount" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("currency_type")}</Typography>
                                                            <Box className="cash_uploadBox" data-test-id="addCashCurrenNewcyDropdown">
                                                                <Select
                                                                    value={values.currency_type}
                                                                    options={this.state.currencyList?.map(value => ({ label: value.currency_type, value: value.currency_type }))}
                                                                    onChange={(value) => { setFieldValue("currency_type", value) }}
                                                                    name="currency_type"
                                                                    classNamePrefix="an-simple-select"
                                                                    className="selectMenu"
                                                                    data-test-id="test-input-currency_type"
                                                                    styles={{
                                                                        control: (defaultStyles) => ({
                                                                            ...defaultStyles,
                                                                            textAlign: "left",
                                                                            fontSize: '16px',
                                                                            color: 'rgba(255, 255, 255, 0.60)',
                                                                            backgroundColor: "#2a2a38",
                                                                            fontWeight: 500,
                                                                            padding: "8px",
                                                                            fontFamily: 'Poppins',
                                                                            borderRadius: '8px',
                                                                            outline: "none"
                                                                        })
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="currency_type" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("term")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput
                                                                    className="inputRoot"
                                                                    placeholder={this.getString("placeholderTerm")}
                                                                    data-test-id="test-input-term"
                                                                    fullWidth={true}
                                                                    name="term"
                                                                    type="number"
                                                                    value={values.term}
                                                                    onChange={(event) => {
                                                                        this.calculateMortgageMonthlyPayment({ ...values, term: parseInt(event.target.value) }, setFieldValue)
                                                                        handleChange("term")(event)
                                                                    }}
                                                                    style={addLiabilitywebStyle.ColorWhite}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="term" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("months")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput
                                                                    className="inputRoot"
                                                                    placeholder={this.getString("placeholderMonths")}
                                                                    data-test-id="test-input-months"
                                                                    fullWidth={true}
                                                                    name="months"
                                                                    type="number"
                                                                    value={values.months}
                                                                    onChange={(event) => {
                                                                        this.calculateMortgageMonthlyPayment({ ...values, months: parseInt(event.target.value) }, setFieldValue)
                                                                        handleChange("months")(event)
                                                                    }}
                                                                    style={addLiabilitywebStyle.ColorWhite}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="months" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("annualInterestRate")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput
                                                                    className="inputRoot"
                                                                    placeholder={this.getString("placeholderAnnualInterestRate")}
                                                                    data-test-id="test-input-annualInterestRate"
                                                                    fullWidth={true}
                                                                    name="annualInterestRate"
                                                                    type="number"
                                                                    value={values.annualInterestRate}
                                                                    onChange={(event) => {
                                                                        this.calculateMortgageMonthlyPayment({ ...values, annualInterestRate: parseInt(event.target.value) }, setFieldValue)
                                                                        handleChange("annualInterestRate")(event)
                                                                    }}
                                                                    style={addLiabilitywebStyle.ColorWhite}
                                                                />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="annualInterestRate" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("monthlyPayments")}</Typography>
                                                            <Box className="cash_uploadBox">
                                                                <OutlinedInput className="inputRoot" placeholder={this.getString("placeholderMonthlyPayments")} data-test-id="test-input-monthlyPayments" fullWidth={true} name="monthlyPayments" type="text" value={values.monthlyPayments} readOnly />
                                                            </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="monthlyPayments" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                            <Box>
                                                                <Typography>{this.getString("startDate")}</Typography>
                                                                <Box className="cash_uploadBox" data-test-id="addCashCurrenNewcyDropdown">
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            autoOk
                                                                            variant="inline"
                                                                            keyboardIcon={<img src={image_Calendar} alt="" style={{
                                                                                marginRight: "12px",
                                                                                borderRadius: "0",
                                                                                height:"18px" ,
                                                                                marginTop:"-4px", 
                                                                                width:"18px",
                                                                            }} />}
                                                                            inputVariant="outlined"
                                                                            format="dd/MM/yy"
                                                                            data-test-id="test-input-startDate"
                                                                            placeholder={"DD/MM/YY"}
                                                                            name="startDate"
                                                                            value={values.startDate}
                                                                            onChange={(value) => { setFieldValue("startDate", value) }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    padding: '10px 0px',
                                                                                    borderRadius: '8px',
                                                                                    color: 'white',
                                                                                }
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                style: {
                                                                                    color: 'white'
                                                                                }
                                                                            }}
                                                                            style={addLiabilitywebStyle.fullWidth}
                                                                            className="uploadBox newBoxContant12Pension"
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Box>
                                                                <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                    <ErrorMessage name="startDate" />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="inputWrapper">
                                                            <Typography>{this.getString("firstPayment")}</Typography>
                                                            <Box className="cash_uploadBox" data-test-id="addCashCurrenNewcyDropdown">
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            variant="inline"
                                                                            autoOk
                                                                            keyboardIcon={<img src={image_Calendar} alt="" style={{
                                                                                marginRight: "12px",
                                                                                height:"18px" ,
                                                                                borderRadius: "0", 
                                                                                marginTop:"-4px", 
                                                                                width:"18px",
                                                                            }} />}
                                                                            format="dd/MM/yy"
                                                                            inputVariant="outlined"
                                                                            data-test-id="test-input-firstPayment"
                                                                            placeholder={"DD/MM/YY"}
                                                                            name="firstPayment"
                                                                            value={values.firstPayment}
                                                                            onChange={(value) => { setFieldValue("firstPayment", value) }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    padding: '10px 0px',
                                                                                    borderRadius: '8px',
                                                                                    color: 'white',
                                                                                }
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                style: {
                                                                                    color: 'white'
                                                                                }
                                                                            }}
                                                                            style={addLiabilitywebStyle.fullWidth}
                                                                            className="uploadBox newBoxContant12Pension"
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </Box>
                                                            <Box sx={addLiabilitywebStyle.fieldError} data-test-id="error">
                                                                <ErrorMessage name="firstPayment"/>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box style={{ textAlign: "end", paddingTop: '48px' }} data-test-id="addCashWebFinalButton">
                                                    <MuiButton
                                                        data-test-id="submitButton"
                                                        style={{
                                                            textAlign: 'center',
                                                            lineHeight: '24px',
                                                            fontWeight: 700,
                                                            fontFamily: 'Poppins',
                                                            textTransform: 'none',
                                                            color: '#3C3C50',
                                                            fontSize: '17px',
                                                            width: "auto",
                                                        }}
                                                        variant="contained"
                                                        className="share_nextButton addCashNewFinalButton"
                                                        type="submit"
                                                    >
                                                        {this.getString("save")}
                                                    </MuiButton>
                                                </Box>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </ThemeProvider>
                    <Dialog
                        open={this.state.openSuccessModal}
                        keepMounted
                        data-test-id="dialogModel"
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogSuccessPaperStyle }}
                        onClose={() => this.navigateRoute("CategoriessubcategoriesWeb")}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                                textAlign: "center"
                            }}>
                                {this.getString("LiabilitySuccessMessage")}
                            </DialogContentText>

                        </DialogContent>
                    </Dialog>
                </Box>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    width: "100%",
    height: "100vh",
    background: "#2A2A39",
    "& .innerWrapper": {
        margin: "0px 30px"
    },
    "& .wrapper": {
        height: "100%",
        overflowY: "scroll"
    },
    "& .headerMain": {
        justifyContent: 'space-between',
        display: 'flex',
    },
    "& .headerTitle": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center"
    },
    "& .backImage": {
        width: '24px',
        cursor: "pointer",
        height: '13px',
        marginTop: "3px",
    },
    "& .inputWrapper": {
        marginBottom: "10px"
    },
    "& .backImage.rtlRotate": {
        transform: "rotate(180deg)"
    },
    "& .MuiTypography-root": {
        color: "#FFFFFF",
        marginBottom: "5px"
    },
    "& .inputRoot": {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        "&. MuiOutlinedInput-input": {
            color: "#FFFFFF !important",
        },
        height: '56px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        fontFamily: 'Poppins',
        outline: 'none',
        borderBottom: "none",
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        "& .MuiInputBase-input": {
            borderBottom: "none"
        },
        borderRadius: '8px',
    },
    "& .custom-datepicker": {
        height: "56px",
        fontSize: "14px",
        borderColor: '#94989c !important',
        padding: "0 15px",
        fontWeight: "normal"
    }

})

const addLiabilitywebStyle = {
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    ColorWhite: {
        color: "white"
    },
    fullWidth: {
        width: "100%"
    }
}


// Customizable Area End
