import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import storage from "framework/src/StorageProvider";
import moment from "moment";
import { formatNumberWithCommas } from "../../../components/src/commonFn";
import toast from "react-hot-toast";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area End

export interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    formValues: {
      name: string,
      quantity: string,
      purchasePrise: number | null | string,
      currency:{ value: string, label: string },
      transactionDate: Date | null
    },
    selectedCurrency: { value: string, label: string },
    currencyList :ICurrency[],
    currencyListDropDown: { value: string, label: string }[],
    currencyListDropDownForm: { value: string, label: string }[],
    commodityType: string,
    commodityId: string,
    direction: string,
    language:string,
    openSuccessModal: boolean
    purchasePr: string
    
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class AddCommodityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  getCurrencyListAPICallId: string = "";
  putCurrencyAPICallId:string = "";
  createCommodityAPIcallId: string = "";
  updateCommodityAPIcallId: string = ""
 

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formValues: {
        name: "",
        quantity: "",
        purchasePrise: null,
        currency:{ value: 'USD', label: 'USD' },
        transactionDate: null,        
      },
      purchasePr:'',
      selectedCurrency: { value: '', label: '' },
      currencyList:[],
      currencyListDropDown:[],
      commodityType:"metal",
      commodityId:"",
      currencyListDropDownForm:[],
      direction:'ltr',
      language:"English",
      openSuccessModal: false

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCurrencyList();
    let language1 = await getStorageData('language');
    const currentLanguage = language1 === 'Arabic' ? 'rtl' : 'ltr';
    if(!language1){
      language1 = "English"
    }
    this.setState({ direction: currentLanguage ,language: language1});
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleNavigationMessage(message)
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
    this.handleCreateCommodityResponse(apiRequestCallId,responseJson);
    this.handleUpdateCommodityResponse(apiRequestCallId,responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId)
    // Customizable Area End
  }

  // Customizable Area Start

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  getStringCommodity = (keyvalue: string) => {
    return configJSON.languageListCommodity[this.state.language][keyvalue]
  }

  handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
    list:ICurrency[],
    selection: number
  }){

    if (apiRequestCallId === this.getCurrencyListAPICallId) {
      const userSelectedCurrncy = responseJson.list.find((value) => {
        return value?.id == responseJson.selection
      })
      this.setState({
        currencyList: responseJson.list,
        selectedCurrency: userSelectedCurrncy ? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` } : {
          value: "", label: ""
        },
        currencyListDropDownForm: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.currency_type}` }
        )),

        currencyListDropDown: responseJson.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
        ))
      })

    }
  }


  handleCreateCommodityResponse(apiRequestCallId:string,responseJson:any){
    if(apiRequestCallId === this.createCommodityAPIcallId){
      responseJson.data ? this.setState({ openSuccessModal: true}): toast.error('Failed to create commodity. Please check your input and try again.')
    }
  }

  goToBackPage(){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWeb");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  dialogSuccessPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };


  handleUpdateCommodityResponse(apiRequestCallId:string,responseJson:any){
    if(apiRequestCallId === this.updateCommodityAPIcallId){
      if (responseJson.data ) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "CommodityOverview");
        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(message)
      }else toast.error('Failed to update commodity. Please check your input and try again.')
      
    }
  }

  goToAccountDetail=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "MyneSettingsProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  handleBack=() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    if(this.state.commodityId){
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CommodityOverview");
    }else{
      message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWeb");
    }
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handleSubmit =async (values:{
    name: string,
    quantity: string,
    purchasePrise: number | null,
    currency:{ value: string, label: string },
    transactionDate: Date | null
  }) => {
    const body = 
    {
      "commodity": {
          "commodity_type": this.state.commodityType === "metal" ? 0 : 1,
          "name": values.name,
          "quantity":  values.quantity,
          "purchase_price": values.purchasePrise,
          "currency": values.currency.value,
          "transaction_date": moment(values.transactionDate).format("DD/MM/YYYY")
      }
    };
    if(this.state.commodityId){
      this.updateCommodityAPIcallId = await this.apiCall({
        contentType:"application/json" + '',
        method: "PUT" + '',
        endPoint: `bx_block_proposal_generation/commodities/${this.state.commodityId}` ,
        body: JSON.stringify(body)
      });
    }else{
      this.createCommodityAPIcallId = await this.apiCall({
        contentType:"application/json" + '',
        method: "POST" + '',
        endPoint: "bx_block_proposal_generation/commodities" ,
        body: JSON.stringify(body)
      });
    } 
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const commodityMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    commodityMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && commodityMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(commodityMessage.id, commodityMessage);
    return commodityMessage.messageId;
  };

  getCurrencyList = async() => {
    this.getCurrencyListAPICallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }


  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }

  validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    quantity: Yup.number()
        .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return parseFloat(originalValue.replace(/,/g, ''));
            }
            return value;
        })
        .typeError('Quantity must be a number')
        .positive('Quantity must be greater than zero')
        .integer('Quantity must be an integer')
        .required('Quantity is required'),
    purchasePrise: Yup.number()
        .transform((value, originalValue) => {
            if (typeof originalValue === 'string') {
                return parseFloat(originalValue.replace(/,/g, ''));
            }
            return value;
        })
        .typeError('Purchase Price must be a number')
        .positive('Purchase Price must be greater than zero')
        .required('Purchase Price is required'),
        transactionDate: Yup.date().nullable().typeError('Transaction Date must be a valid date').required("Transaction Date is required"),
});




  handleChangeCommodityType = (commodityType:string) =>{
    this.setState({
      commodityType: commodityType
    })
  }


  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const commodity = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(commodity){
        this.setState({purchasePr:formatNumberWithCommas(commodity.attributes.purchase_price)})
        this.setState({
          commodityType: commodity.attributes.commodity_type !== "other" ? "metal" :"other",
          commodityId: commodity.id,
          formValues:{
            ...this.state.formValues,
            currency: commodity.currency,
            quantity: formatNumberWithCommas(commodity.attributes.quantity),
            purchasePrise: formatNumberWithCommas(commodity.attributes.purchase_price),
            name: commodity.attributes.name,
            transactionDate: commodity.transaction_date
          }
        })
      }
    }
  }

  // Customizable Area End
}
