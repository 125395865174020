export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const calendar = require("../assets/image_Calendar.png");
export const arrowImage = require("../assets/arrow.png");
export const deleteIcon = require("../assets/delete.png");
export const arrowDown = require("../assets/arrow_down.png");
export const SuccessCard = require("../assets/cr.png");
export const CheckboxRight = require("../assets/chekbox-right.png");
export const CheckboxUnselect = require("../assets/check-box-unselect.png");
export const Checkbox = require("../assets/rectange.png");
export const AlertImage = require("../assets/alert.png");
