Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "proposalgeneration2";
exports.labelBodyText = "proposalgeneration2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.proposalEndpoint = "bx_block_proposal_generation/proposal_generations";
exports.deleteMethod = "DELETE";
exports.updateMethod = "PUT";
exports.formDataContent = "application/json";
exports.postMethod= "POST";
exports.shareProposalEndpoint = "bx_block_proposal_generation/proposal_generations/share_proposal";
exports.serviceProposalEndpoint = "bx_block_proposal_generation/service_proposals";
exports.serviceProposalEditEndpoint = "bx_block_proposal_generation/service_proposals/set_service_details";
exports.templateDeleteEndpoint = "bx_block_proposal_generation/service_proposals/delete_template?id=";
exports.percentageEndpoint = "bx_block_proposal_generation/service_proposals/get_percentage";
exports.sendTemplateEndpoint = "bx_block_proposal_generation/service_proposals/send_template";
exports.sendServiceEndpoint = "bx_block_proposal_generation/service_proposals/send_service";
exports.getClientsEndpoint = "bx_block_proposal_generation/service_proposals/get_clients";
exports.getTemplateEndpoint = "bx_block_proposal_generation/service_proposals/template_list"


// Customizable Area End