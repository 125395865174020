export const infoImage = require("../asset/sidebar/info-image.png");
export const infoImage2 = require("../asset/sidebar/info-image2.png");
export const logo = require("../asset/sidebar/sidebar-logo.png");
export const logoMyne = require("../asset/sidebar/mynelogo.png");
export const downArrow = require("../asset/dashboard/down.png");
export const home = require("../asset/sidebar/home.png");
export const homeActive = require("../asset/sidebar/home-active.png");
export const budget = require("../asset/sidebar/budget.png");
export const budgetActive = require("../asset/sidebar/budget-active.png");
export const estatePlanning = require("../asset/sidebar/estate-planning.png");
export const estatePlanningActive = require("../asset/sidebar/estate-planning-active.png");
export const investment = require("../asset/sidebar/investment.png");
export const investmentActive = require("../asset/sidebar/Investment-active.png");
export const logout = require("../asset/sidebar/logout.png");
export const contact4 = require("../asset/sidebar/contactus2.png");
export const message = require("../asset/sidebar/messages.png");
export const schedule = require("../asset/sidebar/schedule.png");
export const services = require("../asset/sidebar/services.png");
export const clients = require("../asset/sidebar/clients.png");
export const SuccessCard = require("../asset/dashboard/cr.png");
export const debtsIcon= require("../asset/sidebar/debts.png")

export const image_Vuesax = require("../../multipageforms2/assets/image_Vuesax.png");
export const imagenav_BackIcon = require("../../multipageforms2/assets/imagenav_BackIcon.png");
export const imagenav_Bitmap = require("../../multipageforms2/assets/imagenav_Bitmap.png");
export const modelSuccess = require("../../multipageforms2/assets/cr.png");
export const plus1= require('../../formapprovalworkflow/assets/plus1.png');
export const plus= require('../../formapprovalworkflow/assets/Plus.png');

export const addFile = require('../asset/add_file.svg');
export const fileCopy = require('../asset/file_copy.svg');
export const check = require('../asset/check.png');
export const uncheck = require('../asset/uncheck.png');

// dashboard assets
export const bankicon = require("../asset/dashboard/bankicon.png");
export const bankimage = require("../asset/dashboard/bankimage.png");
export const bankimage2 = require("../asset/dashboard/bankimage2.png");
export const bankimage3 = require("../asset/dashboard/bankimage3.png");
export const bankimage4 = require("../asset/dashboard/bankimage4.png");
export const bankimage5 = require("../asset/dashboard/bankimage5.png");
export const bankimage6 = require("../asset/dashboard/bankimage6.png");
export const bankimage7 = require("../asset/dashboard/bankimage7.png");
export const bankimage8 = require("../asset/dashboard/bankimage8.png");
export const bankimage9 = require("../asset/dashboard/bankimage9.png");
export const buttonimage = require("../asset/dashboard/buttonimage.png");
export const refreshicon = require("../asset/dashboard/refreshicon.png");
export const settingicon = require("../asset/dashboard/settingicon.png");
export const subarrowicon = require("../asset/dashboard/subarrowicon.png");
export const sidearrow = require("../asset/dashboard/sidearrow.png");
export const cash = require("../asset/dashboard/cash.png");
export const pension = require("../asset/dashboard/pension.png");
export const portable = require("../asset/dashboard/portable.png");
export const property = require("../asset/dashboard/property.png");
export const shares = require("../asset/dashboard/shares.png");

export const one = require("../asset/sidebar/1.png");
export const two = require("../asset/sidebar/2.png");
export const three = require("../asset/sidebar/3.png");
export const four = require("../asset/sidebar/4.png");
export const five = require("../asset/sidebar/5.png");
export const six = require("../asset/sidebar/6.png");
export const seven = require("../asset/sidebar/7.png");
export const eight = require("../asset/sidebar/8.png");
export const nine = require("../asset/sidebar/9.png");
export const ten = require("../asset/sidebar/10.png");
export const eleven = require("../asset/sidebar/11.png");
export const down = require("../asset/dashboard/down.svg");
export const up = require("../asset/dashboard/up.svg");

export const headereBackIcon = require("../asset/backicon.png");
export const stockPortfolio = require("../asset/stock_portfolio.png");
export const stockTicker = require("../asset/stock_ticker.png");
export const portfolioIcon = require("../asset/portfolioIcon.png");
export const cryptoPortfolio = require("../asset/cryptoport.png");
export const cryptoTicker = require("../asset/cryptoticker.png");
export const cryptowallet1 = require("../asset/cryptowallet1.png");
export const contactus3 = require("../asset/contact3.png");
export const contactus1 = require("../asset/contact1.png");
export const star = require("../asset/star.png");
export const morevert = require("../asset/Morevert.png");
export const videoCall = require("../asset/videocall.png");
export const picture = require("../asset/picture.png");
export const filter = require("../asset/filter.png");
export const arrow5 = require("../asset/arrow.png");
export const bell = require("../asset/bell.png");
export const crosss = require("../asset/crosss.png");
export const right = require("../asset/right.png");
export const globe = require("../asset/globe.png");
export const arroCal = require("../asset/arrowCal.png");

export const deleteIcon = require("../asset/delete.png");
export const Trash = require("../asset/trash.svg");
export const AddCircle = require("../asset/add-circle.svg");

export const unselectedRectangle = require("../asset/dashboard/unselected_rectangle.png");
export const selectedRectangle = require("../asset/dashboard/selected_rectangle.png");

export const unselectedCircle = require("../asset/dashboard/circle_unselect.png");
export const selectedCircle = require("../asset/dashboard/circle_select.png");
export const unselectedStar = require("../asset/dashboard/star_unselect.png");
export const selectedStar = require("../asset/dashboard/star_select.png");
export const logoNewDefault = require("../asset/dashboard/logo-new.png");
export const logoNew = require("../asset/dashboard/logo-new.png");
export const arrowDown = require("../asset/dashboard/arrow_down.png")
export const calendarImg = require("../asset/dashboard/calendar.png")
export const image_Imagenav_backicon = require("../asset/dashboard/image_Imagenav_backicon.png");
export const commodity1 = require("../asset/dashboard/commodity1.png");
export const commodity2 = require("../asset/dashboard/commodity2.png");
export const commodity = require("../asset/dashboard/commodity.png");
export const bankIcon = require("../asset/dashboard/bank.png");
export const liablityImg = require("../asset/dashboard/liability.png");
export const MortgageImgBlack = require("../asset/dashboard/mortgage-black.png");
export const CreditCardBlack = require("../asset/dashboard/credit-card-black.png");
export const PersonalLoanBlack = require("../asset/dashboard/personal-loan-black.png");
export const OtherBlack = require("../asset/dashboard/other-loan-black.png");
export const editSlot = require("../asset/edit_slot.png");
export const deleteSlot = require("../asset/delete-slot.png");
export const ArrowRightIcon = require("../asset/arrow-right.png");
export const EnableSwitchIcon = require("../asset/dashboard/enable-switch.png");
export const EditIcon = require("../asset/dashboard/edit-icon.png");
export const DisableSwitchIcon = require("../asset/dashboard/disable-switch.png");
export const AddIcon = require("../asset/dashboard/addPlan.png");
export const radioChecked = require("../asset/radio-checked.png");
export const radioUncheck = require("../asset/radio-uncheck.png");
export const closeDialogBtn = require("../asset/close-dialog.png");
export const AlertImage = require("../asset/alert.png");
