import React, { Component } from 'react';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Box, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';

// Load your publishable key
const stripePromise = loadStripe('pk_live_51MoKUqLyQFPDlOQ1n2N7lgC91rI8p7cSzwnPrYwCOvsGLqCNXQXH2ZhcAfWfJCvtNVFPiYv1G2Ko3zZhP0mfqWSS00DNw1uJYH');

interface PaymentFormProps {
    stripe: Stripe | null;
    elements: StripeElements | null;
    onTokenGenerated: (token: any) => void;
    buttonName?: string;
}

interface PaymentFormState {
    cardName: string;
    errorMessage: string | null;
    buttonDisable: boolean;
    cardNameError: boolean;

}

class PaymentForm extends Component<PaymentFormProps, PaymentFormState> {
    constructor(props: PaymentFormProps) {
        super(props);
        this.state = {
            cardName: '',
            errorMessage: null,
            buttonDisable: true,
            cardNameError: false
        };

        this.handlePayment = this.handlePayment.bind(this);
        this.setCardName = this.setCardName.bind(this);
    }

    async handlePayment() {
        if (this.state.cardName === '') {
            this.setState({
                cardNameError: true
            })
            return
        }
        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            toast.error('Please add card info')
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {
            const { token, error } = await stripe.createToken(cardNumberElement as unknown as StripeCardElement, {
                name: this.state.cardName, // Optionally include the cardholder's name
            });
            if (error) {
                toast.error(error.message || 'Your card is not supported.')
            } else {
                this.props.onTokenGenerated(token)
            }
        } else {
            toast.error('Card number element is not available.')
        }
    }

    setCardName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ cardName: e.target.value, cardNameError: false });
    } 

    render() {
        const cardElementOptions = {
            style: {
                base: {
                    fontSize: '16px',
                    color: 'white',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding: '10px',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
         
        return (
            <>
                <Box>
                    <Box mb={2}>
                        <Typography style={{
                            color: 'white',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: 300,
                            fontFamily: 'Poppins',
                        }}>Card holder name</Typography>
                        <input
                            type="text"
                            placeholder="Enter cardholder name"
                            onChange={this.setCardName}
                            style={{
                                width: '100%',
                                height: '56px',
                                padding: '14.5px 14px',
                                borderRadius: '10px',
                                border: '1px solid #2DF1E3',
                                fontFamily: 'Poppins',
                            }}
                        />
                        {
                            this.state.cardNameError && <Typography style={{
                                color: '#ff2525',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>Please enter card holder name</Typography>
                        }
                    </Box>

                    <Box>
                        <Typography style={{
                            color: 'white',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: 300,
                            fontFamily: 'Poppins',
                        }}>Card number</Typography>
                        <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px">
                            <CardNumberElement options={cardElementOptions} />
                        </Box>
                    </Box>
                    <Box style={
                        {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%'
                        }
                    }>
                        <Box width={'100%'}>
                            <Typography style={{
                                color: 'white',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>Expiry date</Typography>
                            <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px">
                                <CardExpiryElement options={cardElementOptions} />
                            </Box>
                        </Box>

                        <Box width={'100%'}>
                            <Typography style={{
                                color: 'white',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>CVC</Typography>
                            <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px" >
                                <CardCvcElement options={cardElementOptions} />
                            </Box>
                        </Box>
                    </Box>

                    <button
                        type="button"
                        onClick={this.handlePayment}
                        style={{
                            backgroundColor: '#C9F9F6',
                            display: 'flex',
                            height: '56px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            marginTop: '16px',
                            cursor: 'pointer',
                            width: '100%',
                            color: '#3C3C50',
                            fontStyle: 'normal',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: 'Poppins',
                            border: 'none'
                        }}
                    >
                        {this.props.buttonName || 'Complete purchase'}
                    </button>
                </Box>
            </>
        );
    }
}


interface StripePaymentProps {
    handleToken: (token: any) => void;
    buttonName?: string
}
// Wrap your PaymentForm component with Elements to inject stripe and elements context
class StripePayment extends Component<StripePaymentProps> {
    render() {
        return (
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <PaymentForm stripe={stripe} elements={elements} onTokenGenerated={this.props.handleToken} buttonName={this.props.buttonName} />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }
}

export default StripePayment;
