import React from "react";


// Customizable Area Start

import "./custom.css"
import { goal1, goal2, goal4, goal5, goal6, goal7, goal9, goal1_2, goal2_2, goal4_2, goal6_2, goal7_2, goal5_2, goal9_2, goal12_2, goal12, goal11_2, goal11, } from "./assets";


// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Button } from "@material-ui/core";

interface Props {
    id: string;
    navigation: any;
    instance: any;
}
interface S { }
interface SS { }

export default class Goals extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    investmentLogo(goals: any) {
        return (
            <img src={goals.includes("Track Assets") ? goal1 : goal1_2} className="img" alt="goal" />
        )
    }
    trackeMySpendingLogo(goals: any) {
        return (
            <img src={goals.includes("Wealth Planning") ? goal2_2 : goal2} className="img" alt="goal" />
        )
    }

    reduceDebtLogo(goals: any) {
        return (
            <img src={goals.includes("Wealth Protection") ? goal12_2 : goal12} className="img" alt="goal" />
        )
    }

    payBillOnTimeLogo(goals: any) {
        return (
            <img src={goals.includes("Start Investing") ? goal4_2 : goal4} className="img" alt="goal" />
        )
    }

    createABudgetLogo(goals: any) {
        return (
            <img src={goals.includes("Monitor Spending") ? goal5 : goal5_2} className="img" alt="goal" />
        )
    }


    lowerBillsLogo(goals: any) {
        return (
            <img src={goals.includes("Create Budget Goals") ? goal6_2 : goal6} className="img" alt="goal" />
        )
    }


    growSavingsLogo(goals: any) {
        return (
            <img src={goals.includes("Grow Saving") ? goal7 : goal7_2} className="img" alt="goal" />

        )
    }

    startInvestingLogo(goals: any) {
        return (
            <img src={goals.includes("Asset Insurance") ? goal11_2 : goal11} className="img" alt="goal" />

        )
    }


    planRetirementLogo(goals: any) {
        return (
            <img src={goals.includes("Plan Retirement") ? goal9_2 : goal9} className="img" alt="goal" />

        )
    }
    // Customizable Area End

    render() {
        const { instance } = this.props
        const { goals } = instance.state;
        return (
            // Customizable Area Start
            <div className="main-wrapper">
                {!instance.state.isArabic ?
                    <div className="goal-wrapper">
                        <div className="goal-box">
                            <div className="goal-block">
                                <h4>What are your goals?</h4>
                                <div className="goalsDetails" style={{ maxHeight: "unset", overflow: "auto", height: "91%"}}>
                                    <ul className="goal-list">
                                        <li className={instance.checkActiveClass("Track Assets")}>
                                            <div className="goal-item" data-test-id="goalsDiv2" onClick={() => instance.goalsHandle('Track Assets')}>
                                                {this.investmentLogo(goals)}
                                                <p>Track Assets</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Wealth Planning")}>
                                            <div className="goal-item" data-test-id="goalsDiv" onClick={() => instance.goalsHandle('Wealth Planning')}>
                                                {this.trackeMySpendingLogo(goals)}
                                                <p>Wealth Planning</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Start Investing")}>
                                            <div className="goal-item" data-test-id="goalsDiv3" onClick={() => instance.goalsHandle('Start Investing')}>
                                            {this.payBillOnTimeLogo(goals)}
                                                <p>Start Investing</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Monitor Spending")}>
                                            <div className="goal-item" data-test-id="goalsDiv4" onClick={() => instance.goalsHandle('Monitor Spending')}>
                                            {this.createABudgetLogo(goals)}

                                                <p>Monitor Spending</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Grow Saving")}>
                                            <div className="goal-item" data-test-id="goalsDiv5" onClick={() => instance.goalsHandle('Grow Saving')}>
                                            {this.growSavingsLogo(goals)}

                                                <p>Grow Saving</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Asset Insurance")}>
                                            <div className="goal-item" data-test-id="goalsDiv6" onClick={() => instance.goalsHandle('Asset Insurance')}>
                                            {this.startInvestingLogo(goals)}

                                                <p>Asset Insurance</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Wealth Protection")}>
                                            <div className="goal-item" data-test-id="goalsDiv7" onClick={() => instance.goalsHandle('Wealth Protection')}>
                                                {this.reduceDebtLogo(goals)}
                                                <p>Wealth Protection</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Create Budget Goals")}>
                                            <div className="goal-item" data-test-id="goalsDiv8" onClick={() => instance.goalsHandle('Create Budget Goals')}>
                                                {this.lowerBillsLogo(goals)}
                                                <p>Create Budget Goals</p>
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="button">
                                        <Button className="verifyQuestions" data-test-id="finalSubmitBtn" style={{background: '#C9F9F6',width:'50%',    padding: '18px',
fontWeight: 700,
borderRadius: '7px'}}
                                         onClick={instance.sendQuestionBank} disabled={instance.state.goals.length === 0}>Continue</Button>
                                    </div>
                                </div>

                                <ul className="list-dot">
                                    <li></li>
                                    <li ></li>
                                    <li className="current" ></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="goal-wrapper arbic-goal-wrapper ">
                        <div className="goal-box">
                            <div className="goal-block">
                                <h4>ما هي أهدافك؟</h4>
                                <div className="goalsDetails" style={{ maxHeight: "unset", overflow: "auto", height: "91%"}}>
                                    <ul className="goal-list">
                                    <li className={instance.checkActiveClass("Reduce debt")}>
                                            <div className="goal-item" data-test-id="goalsDiv3" onClick={() => instance.goalsHandle('Reduce debt')}>
                                                {this.reduceDebtLogo(goals)}
                                                <p>تخفيض الديون</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Track my spending")}>
                                            <div className="goal-item" data-test-id="goalsDiv" onClick={() => instance.goalsHandle('Track my spending')}>
                                                {this.trackeMySpendingLogo(goals)}
                                                <p>تتبّع إنفاقي</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Grow Investment")}>
                                            <div className="goal-item" data-test-id="goalsDiv2" onClick={() => instance.goalsHandle('Grow Investment')}>
                                                {this.investmentLogo(goals)}
                                                <p>تنمية الاستثمار</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Lower bills")}>
                                            <div className="goal-item" data-test-id="goalsDiv6" onClick={() => instance.goalsHandle('Lower bills')}>
                                                {this.lowerBillsLogo(goals)}
                                                <p>فواتير بقيمة أقل </p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Create a budget")}>
                                            <div className="goal-item" data-test-id="goalsDiv5" onClick={() => instance.goalsHandle('Create a budget')}>
                                                {this.createABudgetLogo(goals)}
                                                <p>إعداد موازنة</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Pay bills on time")}>
                                            <div className="goal-item" data-test-id="goalsDiv4" onClick={() => instance.goalsHandle('Pay bills on time')}>
                                                {this.payBillOnTimeLogo(goals)}
                                                <p>دفع الفواتير في موعد الاستحقاق</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Plan Retirement")}>
                                            <div className="goal-item" data-test-id="goalsDiv9" onClick={() => instance.goalsHandle('Plan Retirement')}>
                                                {this.planRetirementLogo(goals)}
                                                <p>خطة التقاعد</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Start investing")}>
                                            <div className="goal-item" data-test-id="goalsDiv8" onClick={() => instance.goalsHandle('Start investing')}>
                                                {this.startInvestingLogo(goals)}
                                                <p>ابدأ الاستثمار</p>
                                            </div>
                                        </li>
                                        <li className={instance.checkActiveClass("Grow savings")}>
                                            <div className="goal-item" data-test-id="goalsDiv7" onClick={() => instance.goalsHandle('Grow savings')}>
                                                {this.growSavingsLogo(goals)}
                                                <p>زيادة المدخرات</p>
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="button">
                                        <button className="goalsSubmit" data-test-id="finalSubmitBtn" onClick={instance.sendQuestionBank}>المتابعة</button>
                                    </div>
                                </div>

                                <ul className="list-dot">
                                    <li></li>
                                    <li ></li>
                                    <li className="current" ></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
