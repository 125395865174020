import { Box, OutlinedInput, makeStyles, styled } from "@material-ui/core";
import { useField, ErrorMessage } from "formik";
import React, { ReactNode } from "react";

interface Props {
  name: string;
  label: string;
  type?:string;
  handleChange?: (val: string) => void;
  endAdornment?: ReactNode;
  isNumberField?: boolean;
  multiline?: boolean;
  rows?: number;
  className?: string;
  isDisabled?: boolean;
  maxLength?: number;
  isRequired?: boolean;
  placeHolder?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "white",
        borderBottom: "0px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      position: "unset",
    },
  },
}));

export const CustomTextFieldControl = ({
  handleChange,
  name,
  placeHolder = "",
  type="text"
}: Props) => {
  const [field] = useField(name);
  const classses = useStyles();

  return (
    <>
      <OutlinedInput
        style={webStyle.inputRoot}
        classes={{
          root: classses.root,
        }}
        data-test-id={name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        placeholder={placeHolder}
        fullWidth={true}
        name={field.name}
        type={type}
      />
      <Box sx={webStyle.fieldError}>
        <ErrorMessage name={field.name} />
      </Box>
    </>
  );
};

type IStyle = { [key: string]: React.CSSProperties };

const webStyle: IStyle = {
  inputRoot: {
    borderRadius: "8px",
    height: "56px",
    outline: "none",
    fontFamily: "Poppins",
  },
  fieldError: {
    color: "#EE5353",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
  fieldStyle: {
    marginTop: "15px",
  },
};
