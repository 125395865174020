import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start


// Customizable Area End

import QuestionbankController, {
  Props,
  configJSON,
} from "./QuestionbankController";
import Questions from "./Questions.web";
import Goals from "./Goals.web";
import Heard from "./heard.web"

export default class Questionbank extends QuestionbankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <div style={{height:'100vh'}}>

        {this.state.componentPage == "questions" &&
          <Questions navigation={this.props.navigation} id={""} instance={this} />}
           {this.state.componentPage == "heard" &&
          <Heard navigation={this.props.navigation} id={""} instance={this} />}
        {this.state.componentPage == "goals" && <Goals navigation={undefined} id={""} instance={this}/>}
          </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
