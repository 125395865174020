import React from "react";
// Customizable Area Start
import "./style.css";
import {
    SuccessCard,
    settingicon,
    subarrowicon,
} from "./assets";

import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import InfiniteScroll from "react-infinite-scroll-component";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    selectWallet(walletId: any) {
        this.setState({ selectedWallet: walletId }, () => {
            console.log("Selected Wallet ID:", this.state.selectedWallet);
        });
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div id="portfolioScreen" className="pagedashboard-wrapper">
                <div id="portfolioWrapper" className="header-wrapper" style={{ gap: '5px' }}>
                    <div id="pageTitleForPortfolio" className="page-title" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <NavLink to="/CryptoSelection"><img src={backIcon} alt="back-icon" /></NavLink>
                        <h4 className="portfolioRedirectionLink">
                            Add Crypto wallet
                        </h4>
                    </div>
                    <div className="stock-portfolio page-info">
                        <div data-test-id="portfolioFile" className="drop-menu dropDoenMenu" style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <div
                                className={this.state.dropdownVisiblity}
                                onClick={() => this.handleDropdown()}
                                id="portfolioListing"
                                data-test-id="currencyDropdoen"
                            >


                                <p id="selectedPortfolioCurrencyLabel">
                                    {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                </p>
                                <ul className="walletList2">
                                    {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                        return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                    })}
                                </ul>

                            </div>
                            <NavLink data-test-id="linkForPath" to="/Account-Detail">
                                <img src={settingicon} alt="setting" />
                            </NavLink>
                        </div>

                    </div>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={7}>
                    <InfiniteScroll
                        dataLength={this.state.walletList.length}
                        next={this.walletListData}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="portfolioScreen"
                    >
                        <Grid container spacing={0} >
                            {this.state.walletList && this.state.walletList.map((values, index: number) => (
                                <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: '10px' }} >
                                    <Box
                                        data-test-id="selectBtn"
                                        style={this.state.selectedWallet != values ? webStyleForPortfolio.stockCardportfolio1 : webStyleForPortfolio.stockCardActivepotfolio1}
                                        onClick={() => this.selectWallet(values)}
                                    >
                                        <Box style={{ ...webStyleForPortfolio.stockCard2Portfolio1, flexDirection: 'column' }}>
                                            <Box >
                                                <img src={values.logo} style={{ width: '50px', margin: 'auto' }} />
                                            </Box>
                                            <Typography style={this.state.selectedWallet != values ? webStyleForPortfolio.tickerLabelsPortfolio1 : webStyleForPortfolio.tickerLabelsActivePortfolio1}>
                                                {values.display_name.length > 16 ? `${values.display_name.slice(0, 10)}...` : values.display_name}
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </InfiniteScroll>
                    </Grid>
                    <Grid item xs={12} sm={5}></Grid>

                </Grid>


                <Box className="linkBtn">
                    <Box data-test-id="linkBtnDesign" style={{ ...webStyleForPortfolio.btnBoxPortfolio1, position: 'absolute', bottom: '20px', right: '30px' }} onClick={() => this.addCryptoWallet()}>
                        <Typography className="btnTxt" style={webStyleForPortfolio.btnLabelportfolio1}>
                            Link to Wallet
                        </Typography>
                    </Box>
                </Box>
                <Dialog
                    open={this.state.openSuccessModal}
                    keepMounted
                    data-test-id="dialogModel"
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.dialogSuccessPaperStyle }}
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                    <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
                        <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                            textAlign: "center",
                            fontFamily: "Poppins"
                        }}>
                            Your wallet linking is successful!
                        </DialogContentText>
                        <Button data-test-id="continue-to-dashboard" onClick={() => {
                            this.goToDashboard()
                        }} style={webStyleForPortfolio.goToDashboardBtn}>
                            Continue to Dashboard
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleForPortfolio = {
    stockCard2Portfolio1: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px' },
    btnLabelportfolio1: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700 },
    stockCardportfolio1: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    btnBoxPortfolio1: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
    stockCardActivepotfolio1: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    tickerLabelsActivePortfolio1: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    tickerLabelsPortfolio1: { color: '#fff', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    goToDashboardBtn: { cursor: "pointer", background: "#C9F9F6", color: "#3c3c50", minWidth: "150px", fontSize: "16px", fontWeight: 600, marginTop: "2rem", marginBottom: "2rem", textTransform: "none" } as React.CSSProperties,

}
// Customizable Area End
