// This is the Auth token, you will use it to generate a meeting and connect to it
export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIzNTAyMDRlOC0yYTcyLTRhYzYtYjk0Yy1lYzlhNGU1NWMwZjUiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcxNjk0ODA1NSwiZXhwIjoxODc0NzM2MDU1fQ.2aZ89nbkhF39SyLjate06MKZIzPW76ZG1NBMUKF2T7A";

interface CreateMeetingParams {
  token: string;
}

// API call to create a meeting
export const createMeeting = async ({ token }: CreateMeetingParams): Promise<string> => {
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: token,
      // "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });

  if (!res.ok) {
    throw new Error('Failed to create meeting');
  }

  // Destructuring the roomId from the response
  const { roomId } = await res.json();
  return roomId;
};
