import React from "react";
import { Box, Drawer, List, ListItem, ListItemIcon, styled } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../../blocks/dashboard/src/sidebar.css"
import { Link } from "react-router-dom";
import {
    infoImage, infoImage2, logo, home, homeActive, budget, budgetActive, logout, investment, investmentActive, estatePlanning, estatePlanningActive,debtsIcon,
    one, two, three, four, five, six, eleven, seven, eight, nine, ten, logoMyne
} from "../../blocks/dashboard/src/assets"


import SideBarController,{Props} from "./SideBarController.web"
import ReminderSnackbar from "./Snackbar.web";
export default class Sidebar extends SideBarController{

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    formatCurrency = (number: any) => {
        if (number >= 1000) {
            const formattedNumber = (number / 1000).toFixed(1);
            return `$${formattedNumber}k`;
        } else {
            return `$${number.toFixed(2)}`;
        }
    }
    setSidebarValues = (data:any) => {
        let networthString = data.networth;
        let assetString = data.assets;
        let debtString = data.debts;
        // let networth = networthString ? this.formatCurrency(parseFloat(networthString.replace(/[$,]/g, ''))) : 0;
        // let asset = assetString ? this.formatCurrency(parseFloat(assetString.replace(/[$,]/g, ''))) : 0;
        // let debts = debtString ? this.formatCurrency(parseFloat(debtString.replace(/[$,]/g, ''))) : 0;
        // if (this.state.networth == 0 ) {
        //     this.setState({ networth: networthString });
        // }
        // if (this.state.assets == 0) {
        //     this.setState({ assets: assetString });
        // }
        // if (this.state.debts == 0) {
        //     this.setState({ debts: debtString });
        // }
    }
    handleMenu = (name: any) => {
        this.setState({ activeMenu: name })
        if (name == "home") {
            this.setState({ activeSubMenu: "networth" })
        }
        if (name == "budget") {
            this.setState({ activeSubMenu: "spending" })
        }
    }
    networthData = (data:any)=>{
        return data.networth;
    }

    assetsData = (data:any)=>{
        return data.assets;
    }

    debtsData = (data:any)=>{
        return data.debts;
    }

    logout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userEmail');
        window.localStorage.removeItem('authToken');
        window.localStorage.removeItem('rememberMe');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('loggedin');
        window.localStorage.removeItem('auhtToken');
        window.localStorage.clear()
        window.location.href = '/EmailAccountLogin';
    }

    handleSubMenu = (name: any) => {
        this.setState({ activeSubMenu: name })
    }

    dialogModel = () => {
        this.setState({ openModel: !this.state.openModel })
    }

    onCancel = () => {
        this.setState({ openModel: !this.state.openModel })
    }

    DrawerOnClose = () => {
        this.setState({ isDrawerOpen: false })
    }

    openDrawer = () => {
        this.setState({ isDrawerOpen: true })
    }

    dialogPaperStyle = {
        width: 'max-content',
        backgroundColor: '#3c3c51',
        borderRadius: '12px',
        border: '1px solid #57575d',
        padding: '19px 23px',
    };

    sidebarContent = () => {
        return (
            <div dir={this.state.direction} className={`page-sidebar ${this.state.direction}`} style={this.state.isDrawerOpen ? {display: "block"} : {}}>
                    <div className="site-logo">
                        <Link to="/Dashboard" onClick={this.DrawerOnClose}>
                            <img className="desktop-logo" src={logoMyne} alt='logo' />
                        </Link>
                    </div>
                    {window.location.pathname != '/Account-Detail' && !window.location.pathname.includes('EditPayment') && window.location.pathname != '/Language' && window.location.pathname != '/EstatePlanningPreferences' && window.location.pathname != '/Currency' && window.location.pathname != '/ResetPassword' && window.location.pathname != '/DeleteAccount' && window.location.pathname != '/BankWallet' && window.location.pathname != '/ManageSubscriptionController' ?
                        <div className="info-block">
                            <div className="inner-info-block">
                                <div className="inner-info-image">
                                    <img src={infoImage} alt="info" />
                                </div>
                                <div className="inner-info-content">
                                    <p>{this.getStringSidebar("Networth")}</p>
                                    <span>{this.networthData(this.props)}</span>
                                </div>
                            </div>
                            <div className="inner-info-block">
                                <div className="inner-info-image">
                                    <img src={infoImage2} alt="info" />
                                </div>
                                <div className="inner-info-content">
                                    <p>{this.getStringSidebar("Assets")}</p>
                                    <span>{this.assetsData(this.props)}</span>
                                </div>
                            </div>
                            <div className="inner-info-block">
                                <div className="inner-info-image">
                                    <img src={debtsIcon} alt="info" />
                                </div>
                                <div className="inner-info-content">
                                    <p>{this.getStringSidebar("Debts")}</p>
                                    <span>{this.debtsData(this.props)}</span>
                                </div>
                            </div>
                        </div> : ""}
                    <div className="navigation">
                        <nav className="main-nav">
                            {window.location.pathname != '/Account-Detail' && !window.location.pathname.includes('EditPayment')&& window.location.pathname != '/Language' && window.location.pathname != '/EstatePlanningPreferences' && window.location.pathname != '/Currency' && window.location.pathname != '/ResetPassword' && window.location.pathname != '/DeleteAccount' && window.location.pathname != '/BankWallet' && window.location.pathname != '/ManageSubscriptionController' ?
                                <ul className="menu">
                                    <li className={window.location.pathname == '/Dashboard' || window.location.pathname == '/MainDashboard' ? 'active' : ''}>
                                        <Link to="/Dashboard" onClick={this.DrawerOnClose}>
                                            <span onClick={() => this.handleMenu('home')} className="nav-span">
                                                <img src={window.location.pathname == '/Dashboard' || window.location.pathname == '/MainDashboard' ? homeActive : home} style={{ height: "20px" }} />
                                                {this.getStringSidebar("Wealth")}
                                            </span></Link>
                                        <ul className="sub-nav">
                                            <li className={window.location.pathname == '/Dashboard'  ? 'current' : 'inactive'}><Link to="/Dashboard"  onClick={this.DrawerOnClose}><span onClick={() => this.handleSubMenu('networth')}>{this.getStringSidebar("Networth")}</span></Link></li>
                                            <li className={window.location.pathname == '/MainDashboard' ? 'current' : 'inactive'}><Link to="/MainDashboard" onClick={this.DrawerOnClose}><span onClick={() => this.handleSubMenu('dashboard')}>{this.getStringSidebar("Dashboard")}</span></Link></li>
                                           </ul>
                                    </li>
                                    <li className={window.location.pathname == '/ForecastingandbudgetingTrends' || window.location.pathname == '/TransactionAndSpendingDetailsPage' || window.location.pathname == '/VisualAnalytics' || window.location.pathname == '/Goalmanagement' || window.location.pathname == '/Filters' || window.location.pathname == '/Transactions' || window.location.pathname == '/spendingCategory' ? 'active' : ''}>
                                        <Link to="/VisualAnalytics" onClick={this.DrawerOnClose}>
                                            <span onClick={() => this.handleMenu('budget')}>
                                                <img src={window.location.pathname == '/VisualAnalytics' || window.location.pathname == '/Filters'
                                                    || window.location.pathname == '/Goalmanagement' || window.location.pathname == '/Transactions' || window.location.pathname == '/TransactionAndSpendingDetailsPage' || window.location.pathname == '/spendingCategory' ? budgetActive : budget} style={{ height: "20px" }} />
                                                {this.getStringSidebar("Budget")}
                                            </span></Link>
                                        <ul className="sub-nav">
                                            <li className={window.location.pathname == '/VisualAnalytics' || window.location.pathname == '/Filters' || window.location.pathname == '/Transactions' || window.location.pathname == '/spendingCategory' ? 'current' : 'inactive'}><Link to="/VisualAnalytics" onClick={this.DrawerOnClose}><span onClick={() => this.handleSubMenu('spending')}>{this.getStringSidebar("Spending")}</span></Link></li>
                                            <li className={window.location.pathname == '/Goalmanagement' ? 'current' : 'inactive'}><Link to="/Goalmanagement" onClick={this.DrawerOnClose}><span onClick={() => this.handleSubMenu('goals')}>{this.getStringSidebar("Goals")}</span></Link></li>
                                            <li className={window.location.pathname == '/ForecastingandbudgetingTrends' || window.location.pathname == '/TransactionAndSpendingDetailsPage' ? 'current' : 'inactive'}><Link to="/ForecastingandbudgetingTrends" onClick={this.DrawerOnClose}><span onClick={() => this.handleSubMenu('trends')}>{this.getStringSidebar("Trends")}</span></Link></li>
                                        </ul>
                                    </li>
                                    {/* <li className={this.state.activeMenu == 'investment' ? 'active' : ''}>
                                        <Link to="/MainCatalogueWeb" onClick={this.DrawerOnClose}>
                                            <span onClick={() => this.handleMenu('investment')}>
                                                <img src={this.state.activeMenu == 'investment' ? investmentActive : investment} style={{ height: "20px" }} />

                                                Investment
                                            </span>
                                        </Link>
                                    </li> */}
                                    <li className={(this.state.activeMenu == 'planning' || window.location.pathname == '/FinancialPlanner' || window.location.pathname == '/EstatePlannerDashboard' ||window.location.pathname == '/EstatePlanning')  ? 'active' : ''}>
                                        <Link to={this.getEstatePlanningLink()} onClick={this.DrawerOnClose}>
                                            <span onClick={() => this.handleMenu('planning')}>
                                                <img src={window.location.pathname == '/EstatePlanning' ? estatePlanningActive : estatePlanning}  style={{ height: "20px" }} />
                                                
                                                {this.getStringSidebar("EstatePlanning")}
                                            </span>
                                        </Link>
                                        {
                                            this.state.serviceSelected && 
                                            
                                            <ul className="sub-nav">
                                            <li className={window.location.pathname == '/EstatePlannerDashboard' ? 'current' : 'inactive'}>
                                                <Link to={"/EstatePlannerDashboard"} onClick={this.DrawerOnClose}>
                                                    <span onClick={() => this.handleSubMenu('dashboard')}>{this.getStringSidebar("Dashboard")}</span>
                                                </Link>
                                            </li>
                                            <li className={window.location.pathname == '/FinancialPlanner' || window.location.pathname == '/passcodeLock' ? 'current' : 'inactive'}>
                                                <Link to={this.state.isPinPresent ? '/EnterPin' : '/passcodeLock'} onClick={this.DrawerOnClose}>
                                                    <span onClick={() => this.handleSubMenu('networth')}>{this.getStringSidebar("Vault")}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                        }
                                    </li>
                                    
                                 
                                </ul>
                                :
                                <ul className="menu">
                                    <li className={(window.location.pathname == '/Account-Detail' || window.location.pathname == '/ResetPassword') ? "active" : ""}>
                                        <Link to="/Account-Detail" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={(window.location.pathname == '/Account-Detail' || window.location.pathname == '/ResetPassword') ? eleven : one} style={{ height: "20px" }} />
                                                {this.getStringSidebar("AccountDetails")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={(window.location.pathname == '/BankWallet') ? "active" : ""}>
                                        <Link to="/BankWallet" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={two} style={{ height: "20px" }} />
                                                {this.getStringSidebar("BankWalletLogins")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li >
                                        <Link to="/TermsConditions" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={three} style={{ height: "20px" }} />
                                                {this.getStringSidebar("TermsConditions")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={window.location.pathname == '/Language' ? "active" : ""}>
                                        <Link to="/Language" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={window.location.pathname == '/Language' ? eight : four} style={{ height: "20px" }} />
                                                {this.getStringSidebar("Language")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={window.location.pathname == '/Currency' ? "active" : ""}>
                                        <Link to="/Currency" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={window.location.pathname == '/Currency' ? five : ten} style={{ height: "20px" }} />
                                                {this.getStringSidebar("Currency")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={window.location.pathname == '/EstatePlanningPreferences' ? "active" : ""}>
                                        <Link to="/EstatePlanningPreferences" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={home} style={{ height: "20px" }} />
                                                {this.getStringSidebar("EstatePlanningPreferences")}
                                            </span>
                                        </Link>
                                    </li>

                                    <li className={window.location.pathname == '/ManageSubscriptionController' || window.location.pathname.includes('EditPayment') ? "active" : ""}>
                                        <Link to="/ManageSubscriptionController" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={six} style={{ height: "20px" }} />
                                                {this.getStringSidebar("ManageSubscription")}
                                            </span>
                                        </Link>
                                    </li>

                                    
                                </ul>
                            }
                        </nav>
                    </div>
                    <div className="log-out-block" style={{padding:'7px 0'}}>
                        <button onClick={() => { this.onCancel() }} className="log-out-button">
                            <img src={logout} style={{ height: "20px" }} />
                            {this.getStringSidebar("Logout")}
                        </button>
                    </div>
                    <Dialog
                        open={this.state.openModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0px 65px',textAlign: 'center', }}>
                            <span style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                            }}>{this.getStringSidebar("ConfirmLogout")}</span></DialogTitle>
                        <DialogContent style={{
                            padding: '0px!important',
                            marginBottom: '0px',
                            paddingTop: '15px'
                        }}>
                            <DialogContentText id="alert-dialog-slide-description"
                                style={{
                                    color: 'rgb(255, 255, 255) !important',
                                    fontSize: '20px !important',
                                    fontFamily: 'Poppins !important',
                                    fontWeight: 500,
                                    marginBottom: '0px',
                                }}>
                                <div className="modal-button-container">
                                    <div className="modal-button modal-save" data-test-id='popup-save-click' onClick={() => this.onCancel()}>
                                        {this.getStringSidebar("Cancel")}
                                    </div>
                                    <div className="modal-button modal-discard" data-test-id='popup-discard-click' onClick={() => this.logout()}>
                                        {this.getStringSidebar("LogOut2")}
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
        )
    }

    render() {
        return (
            <StyleWrapper className={this.state.direction}>
                <ReminderSnackbar open={this.state.toastOpenn} handleClose={this.handleSidebarToastClose} message="You have an upcoming meeting!" />
                <HamBurgerButton data-test-id="hamburger" className={this.state.direction} onClick={this.openDrawer}>
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </HamBurgerButton>
               {this.state.isDrawerOpen ? <DrawerWrapper className={this.state.direction} PaperProps={{style: { background: "transparent" }, className: this.state.direction}} anchor={this.state.direction === "rtl" ? 'right' : "left"} open={this.state.isDrawerOpen} onClose={this.DrawerOnClose}>
                    {this.sidebarContent()}
               </DrawerWrapper> : this.sidebarContent()}
            </StyleWrapper>
        );
    }
}

const DrawerWrapper = styled(Drawer)({
    "& .rtl.page-sidebar": {
        borderRadius: "25px 0 0 25px !important"
    },
    "&.rtl ul.menu li img": {
        paddingLeft: "15px !important",
        paddingRight: "0px !important",
    },
    "&.rtl ul.sub-nav": {
        marginRight: "43px !important",
        marginLeft: "0px !important",
    },
    "&.rtl button.log-out-button img": {
        paddingRight: "0px !important",
        paddingLeft: "15px !important",
    },
    "&.rtl .inner-info-block": {
        flexWrap: "nowrap"
    },
    "&.rtl .inner-info-content": {
        paddingRight: "15px !important",
        paddingLeft: "0px !important",
    },
})

const StyleWrapper = styled(Box)({
    minHeight: "100vh",
    "&.rtl": {
        order: 2,
    },
    "&.rtl ul.sub-nav": {
        marginRight: "43px !important",
        marginLeft: "0px !important",
    },
    "&.rtl button.log-out-button img": {
        paddingRight: "0px !important",
        paddingLeft: "15px !important",
    },
    "& .rtl.page-sidebar": {
        borderRadius: "25px 0 0 25px !important"
    },
    "&.rtl ul.menu li img": {
        paddingLeft: "15px !important",
        paddingRight: "0px !important",
    },
    "&.rtl .inner-info-block": {
        flexWrap: "nowrap"
    },
    "&.rtl .inner-info-content": {
        paddingRight: "15px !important",
        paddingLeft: "0px !important",
    },
    "@media (max-width: 767px)": {
        "& .page-sidebar": {
           display: "none" 
        }
    }
})

const HamBurgerButton = styled(Box)({
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    background: "rgb(60, 60, 80)",
    position: "fixed",
    top: "50px", 
    left: 0, 
    cursor: "pointer",
    width: "50px",
    height: "50px",
    zIndex: 99999999,
    borderRadius: "0 10px 10px 0",
    "&.rtl": {
        right: 0,
        left: 'unset',
        borderRadius: "10px 0 0 10px",
    },
    "& div div": {
        width: "35px",
        backgroundColor: "#2ce2d5",
        height: "5px",
        margin: "6px 0",
    },
    "@media (max-width: 767px)": {
        display: "flex"
    }
})