export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const arro = require("../assets/arro.png");
export const setting = require("../assets/setting1.png");
export const bank = require("../assets/bank.png");
export const money = require("../assets/money.png");
export const netverk = require("../assets/netverk.png");
export const img1 = require("../assets/img1.png");
export const img2 = require("../assets/img2.png");
export const img3 = require("../assets/img3.png");
export const img4 = require("../assets/img4.png");
export const img5 = require("../assets/img5.png");
export const img6 = require("../assets/img6.png");
export const imagenavBitmap = require("../assets/imagenav_Bitmap.png");
export const commodityImg = require("../assets/commodity.png");
export const liablityImg = require("../assets/liability.png");
