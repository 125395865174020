// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");


export interface Props {
  [key: string]: any;
}

interface S {
  startLocation: { lat: number; lng: number };
  endLocation: { lat: number; lng: number };
  routeCoordinates: { lat: number; lng: number }[];
  directionsService: {};
  wealthplannerNamee:string;
    wealthplannerImagee:string;
    currentPlannerId:string|null;
}

interface SS {}

export default class GeoLocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      startLocation: { lat: 37.7749, lng: -122.4194 },
      endLocation: { lat: 37.7749, lng: -122.4313 },
      routeCoordinates: [],
      directionsService: {},
      wealthplannerImagee:"",
      wealthplannerNamee:"",
      currentPlannerId:null,
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.handleGetDirections();
  }

  async receive(from: string, message: Message) {
    this.handleNavigationMessage(message)

    runEngine.debugLog("Message Recived in");

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      /*istanbul ignore next*/
      if (apiRequestCallId === this.getApicallData) {
        this.handleGoBack();
      }
    } else {
    }
  }

  // web events

  handleSetCoordinates = (direction: string[]) => {
    this.setStartCoordinates(direction);
    this.setEndCoordinates(direction);
  };

  setStartCoordinates = (direction: string[]) => {
    this.setState({
      startLocation: { lat: Number(direction[1]), lng: Number(direction[3]) },
    });
  };

  setEndCoordinates = (direction: string[]) => {
    this.setState({
      endLocation: { lat: Number(direction[5]), lng: Number(direction[7]) },
    });
  };

  handleGetDirections = (googleData?: {
    route(
      request: {},
      callback: (
        result: {
          routes: {
            overview_path: { lat: () => number; lng: () => number }[];
          }[];
        },
        status: string
      ) => void
    ): void;
  }) => {
   return null
  };

  calculateRoute = (
    directionsService:
      | {
          route(
            request: {},
            callback: (
              result: {
                routes: {
                  overview_path: { lat: () => number; lng: () => number }[];
                }[];
              },
              status: string
            ) => void
          ): void;
        }
      | { route: Function }
  ) => {
    const { startLocation, endLocation } = this.state;
    const request = {
      origin: startLocation,
      destination: endLocation,
      travelMode: "DRIVING",
    };
    directionsService.route(
      request,
      (
        result: {
          routes: {
            overview_path: { lat: () => number; lng: () => number }[];
          }[];
        },
        status: string
      ) => {
        /*istanbul ignore next*/
        if (status === "OK") {
          const routeCoordinates = result.routes[0].overview_path.map(
            (location: { lat: () => number; lng: () => number }) => {
              return { lat: location.lat(), lng: location.lng() };
            }
          );
          this.setState({ routeCoordinates });
        }
      }
    );
  };

  handleGoBack = (mockWindowObj?: { location: { replace: Function } }) => {
    const redirectWindow = mockWindowObj || window;
    redirectWindow.location.replace("/FormApproval");
  };
  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if (data1) {
          this.setState({
            wealthplannerNamee: data1.currentPlannerName,
            wealthplannerImagee: data1.currentPlannerImage,
            currentPlannerId:data1.currentPlannerId,
          }, () =>  {  
        })
      }
    }
}
getInitials(name:string) {
  if (!name) return '';
  const initials = name
    .split(' ')
    .map(word => word[0])
    .join('');
  return initials.toUpperCase();
}
navigateToRatingScreen=()=>{

    const message9 = new Message(getName(MessageEnum.NavigationMessage));
    message9.addData(getName(MessageEnum.NavigationTargetMessage), "EstatePlannerReview");
    message9.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        currentPlannerName:this.state.wealthplannerNamee,
        currentPlannerImage: this.state.wealthplannerImagee,
       fromjoinCall:true,
       currentPlannerId:this.state.currentPlannerId
    })
    message9.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message9)  
 
}
navigateToBackScreen=()=>{
   
  this.props.navigation.history.goBack();
  setTimeout(() => {
    this.props.navigation.history.goBack();
  }, 100);
}

  handleCancel = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTcxOCwiZXhwIjoxNjgyMTY0NzgyLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.N0vknQq98iNbzOWN5RhEr0cBOEVIedCvBorY5ui0BLdjIFf6tTYNecWRuxyN7xypY6a2c0mm9prwNPbzIKen9g",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApicallData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelAPI
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
}

// Customizable Area End
