import React from "react";

// Customizable Area Start

import { SuccessCard, calendarImg, image_Vuesax, imagenav_BackIcon } from "./assets";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button , Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core"
import { Box, CssBaseline, FormControl, Grid, OutlinedInput, ThemeProvider, createTheme, styled } from "@material-ui/core";
import Select from 'react-select';
import { ErrorMessage, Form, Formik } from "formik";

let theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
});

theme = createTheme(theme, {
    overrides: {
        MuiSvgIcon: {
            root: {
                color: '#94989c',
            },
        },
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                }
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                backgroundColor: '#395D6B',
                borderRadius: '8px',
                width: 'max-content',
                "@media (max-width: 600px)": {
                    width: '100%',
                    margin: '0 10px',
                },
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayheader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#33abb6",
                backgroundColor: "#3C3C51",
            },
        },
    },
});

// Customizable Area End

import ManualFormController, {
    Props,
} from "./ManualFormController.web";

export default class ManualForm extends ManualFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainWrapper dir={this.state.direction}>
                <Box className="sideBorder wrapper">
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Box className="innerWrapper">
                            <Box className="menualHeaderTitle">
                                <Box className="headerLeft">
                                    <img
                                        data-test-id="handle-back"
                                        src={imagenav_BackIcon}
                                        className="backImage"
                                        onClick={this.handleBack}
                                    />
                                    <p className="headerTitle">
                                        {this.state.bankId ? this.getString("EditBankAccount") : this.getString("AddBankAccount")}
                                    </p>
                                </Box>
                                <Box className="headerRight">
                                    <FormControl>
                                        <Select
                                            options={this.state.currencyListDropDown}
                                            value={this.state.selectedCurrency}
                                            onChange={(value) => { value?.value && this.setCurrency(value) }}
                                            classNamePrefix="an-simple-selectUSD"
                                            className="selectUSD"
                                            data-test-id="selectMenu"
                                        />
                                    </FormControl>
                                    <Box onClick={this.goToAccountDetail} className="accountDetail" data-test-id="accountDetail">
                                        <img src={image_Vuesax} className="accountDetailImage" />
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Formik
                                    enableReinitialize
                                    initialValues={this.state.formValues}
                                    onSubmit={(values:any) => this.handleSubmit(values)}
                                    data-test-id="bankForm"
                                    validationSchema={this.validationSchema}
                                >
                                    {({ handleBlur, values, handleSubmit, handleChange, setFieldValue }) => (
                                        <Form noValidate onSubmit={handleSubmit} data-test-id='submitForm' translate={"en"}>
                                            <Grid container spacing={0}>
                                                <Grid item md={8} xs={12}>
                                                    <Typography className="title">{this.getString("Name")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <OutlinedInput
                                                        className="inputRoot"
                                                        data-test-id="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        placeholder={this.getString("placeholderName")}
                                                        fullWidth={true}
                                                        name="name"
                                                        type="text"
                                                    />
                                                    <Box className="fieldError">
                                                        <ErrorMessage name="name" />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={8} xs={12} className="fieldStyle">
                                                    <Typography className="title">{this.getString("Amount")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <OutlinedInput
                                                        className="inputRoot"
                                                        data-test-id="amount"
                                                        placeholder={this.getString("placeholderAmount")}
                                                        fullWidth={true}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={this.handleFormat(values.amount)}
                                                        name="amount"
                                                        type="text"
                                                    />
                                                    <Box className="fieldError">
                                                        <ErrorMessage name="amount" />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={8} xs={12} className="fieldStyle">
                                                    <Typography className="title">{this.getString("Currency")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <Select
                                                        styles={{
                                                            control: (defaultStyles) => ({
                                                                ...defaultStyles,
                                                                backgroundColor: "#2a2a38",
                                                                borderRadius: '8px',
                                                                padding: "8px",
                                                                color: 'rgba(255, 255, 255, 0.60)',
                                                                fontSize: '16px',
                                                                fontFamily: 'Poppins',
                                                                textAlign: "left",
                                                                fontWeight: 500,
                                                            }),
                                                        }}
                                                        classNamePrefix="an-simple-select"
                                                        options={this.state.currencyListDropDownForm}
                                                        onChange={(event) => event && setFieldValue("currency", event)}
                                                        className="selectMenu selectedMenuDropdown"
                                                        data-test-id="selectCurrencyMenu"
                                                        value={values.currency}
                                                        placeholder={this.getString("placeholderCurrency")}
                                                    />
                                                    <Box className="fieldError">
                                                        <ErrorMessage name="currency.value" />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={8} xs={12} className="fieldStyle">
                                                    <Typography className="title">{this.getString("Date")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <Box data-test-id="datePicker">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                error={false}
                                                                placeholder="DD/MM/YYYY"
                                                                variant="inline"
                                                                fullWidth
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                name="date"
                                                                value={values.date}
                                                                onChange={(values) => setFieldValue("date", values, true)}
                                                                autoOk
                                                                InputProps={{
                                                                    style: {
                                                                        padding: '10px 0px',
                                                                        borderRadius: '8px',
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    style: {
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                                data-test-id="date"
                                                                helperText=""
                                                                className="uploadBox newBoxContant12Pension"
                                                                keyboardIcon={<img src={calendarImg} style={{
                                                                    borderRadius: "0",
                                                                    height: "18px",
                                                                    width: "18px",
                                                                    marginTop: "-4px"
                                                                }} />}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                    <Box className="fieldError">
                                                        <ErrorMessage name="date" />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={8} xs={12} className="fieldStyle">
                                                    <Typography className="title">{this.getString("Note")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={12}>
                                                    <OutlinedInput
                                                        className="inputRoot"
                                                        data-test-id="note"
                                                        placeholder={this.getString("placeholderNote")}
                                                        fullWidth={true}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.note}
                                                        name="note"
                                                        type="text"
                                                    />
                                                    <Box className="fieldError">
                                                        <ErrorMessage name="note" />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box className="formSubmit" data-test-id="formSubmit">
                                                <Button
                                                    className="nextButton"
                                                    data-test-id="submitButton"
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    {this.getString("Save")}
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </ThemeProvider>
                </Box>
                <Dialog
                    open={this.state.openSuccessModal}
                    keepMounted
                    data-test-id="dialogModel"
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.dialogSuccessPaperStyle }}
                    onClose={this.goToBackPage}
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                    <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
                        <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{ textAlign: "center" }}>
                            {this.getString("addAccountSuccess")}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </MainWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    width: "100%",
    height: "100vh",
    background: "#2A2A39",
    "& .wrapper": {
        height: "100%",
        overflowY: "scroll",
    },
    "& .innerWrapper": {
        margin: "0px 30px",
    },
    "& .headerMain": {
        display: 'flex',
        justifyContent: 'space-between',
    },
    "& .menualHeaderTitle": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    "& .headerLeft": {
        display: 'flex',
        alignItems: 'center',
    },
    "& .headerRight": {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    "& .backImage": {
        width: '24px',
        height: '13px',
        cursor: "pointer",
        marginTop: "3px",
    },
    "& .headerTitle": {
        color: 'white',
        padding: '0px 20px',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
    },
    "& .accountDetail": {
        paddingLeft: '20px',
        cursor: "pointer",
    },
    "& .accountDetailImage": {
        width: '41px',
        height: '41px',
    },
    "& .inputRoot": {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
    },
    "& .fieldError": {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    "& .nextButton": {
        color: '#3C3C50',
        textAlign: 'center',
        fontSize: '17px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        width: "auto",
    },
    "& .fieldStyle": {
        marginTop: "15px",
    },
    "& .formSubmit": {
        textAlign: "end",
        marginTop: '20px',
    },
    "@media (max-width: 600px)": {
        "& .headerTitle": {
            fontSize: '16px',
        },
        "& .inputRoot": {
            height: '48px',
        },
        "& .fieldError": {
            fontSize: '10px',
            lineHeight: '16px',
        },
        "& .nextButton": {
            fontSize: '15px',
            lineHeight: '22px',
        },
    },
});

type IStyle = { [key: string]: React.CSSProperties };

const webStyle: IStyle = {
    inputRoot: {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    nextButton: {
        color: '#3C3C50',
        textAlign: 'center',
        fontSize: '17px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        width: "auto",
    },
    fieldStyle: {
        marginTop: "15px",
    }
};

// Customizable Area End
