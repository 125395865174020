export const appleLogo = require("../assets/apple-logo.png");
export const googleIcon = require("../assets/google-icon.png");
export const screens = require("../assets/screen.svg");
export const mobile = require("../assets/mobile.png");
export const verticals = require("../assets/verticals.png");
export const budgeting = require("../assets/budgeting.svg");
export const estatePlanning = require("../assets/estate_planning.svg");
export const networth = require("../assets/networth.svg");
export const investing = require("../assets/investing.svg");
export const networthMobile = require("../assets/networthMobiles.svg");
export const aggregation = require("../assets/aggregation.svg");
export const credit = require("../assets/credit.svg");
export const insurance = require("../assets/insurance.svg");
export const snap = require("../assets/snap.svg");
export const tracker = require("../assets/tracker.svg");
export const valoraisation = require("../assets/valoraisation.svg");
export const userProfile = require("../assets/user_profile.png");
export const openQuote = require("../assets/openQuote.png");
export const closeQuote = require("../assets/closeQuote.png");
export const view1 = require("../assets/view1.png");
export const view2 = require("../assets/view2.png");

export const mobileInvesting = require("../assets/investing/mobile_investing.svg");
export const customization = require("../assets/investing/customization.svg");
export const diversification = require("../assets/investing/diversification.svg");
export const options = require("../assets/investing/options.svg");
export const performance = require("../assets/investing/performance.svg");
export const resources = require("../assets/investing/resources.svg");
export const userFriendly = require("../assets/investing/user_friendly.svg");

export const categorySpend = require("../assets/budgeting/categorySpend.svg");
export const createGoals = require("../assets/budgeting/createGoals.svg");
export const patterns = require("../assets/budgeting/patterns.svg");
export const spendVsBudget = require("../assets/budgeting/spendVsBudget.svg");
export const track_and_view = require("../assets/budgeting/track_and_view.svg");
export const spendsLive = require("../assets/budgeting/spendsLive.svg");
export const budgetMobile = require("../assets/budgeting/budgetMobile.svg");

export const benefciary = require("../assets/estatePlanning/benefciary.svg");
export const digital_wall = require("../assets/estatePlanning/digital_will.svg");
export const life_insurance = require("../assets/estatePlanning/life_insurance.svg");
export const poa = require("../assets/estatePlanning/poa.svg");
export const retirement_plan = require("../assets/estatePlanning/retirement_plan.svg");
export const trusts = require("../assets/estatePlanning/trusts.svg");
export const vault = require("../assets/estatePlanning/vault.svg");
export const mobileEstatePlanning = require("../assets/estatePlanning/mobileEstatePlanning.svg");

export const tickMark = require("../assets/tickMark.png");
export const faqUp = require("../assets/faqUp.png");
export const faqDown = require("../assets/faqDown.png");
export const ImgBack = require("../assets/imgBack.png");
export const facebook = require("../assets/footer/facebook.png");
export const googlePlayStoreIcon = require("../assets/footer/googlePlay.png");
export const homeIcon = require("../assets/footer/homeIcon.png");
export const instagram = require("../assets/footer/instagram.png");
export const linkedin = require("../assets/footer/linkedin.png");
export const logo = require("../assets/footer/logo.png");
export const twitter = require("../assets/footer/twitter.png");
export const youtube = require("../assets/footer/youtube.png");
export const bg1 = require("../assets/bg1.png")
export const networth1 = require("../assets/networthFeature/1.png");
export const networth2 = require("../assets/networthFeature/2.png");
export const networth3 = require("../assets/networthFeature/3.png");
export const networth4 = require("../assets/networthFeature/4.png");
export const networth5 = require("../assets/networthFeature/5.png");
export const networth6 = require("../assets/networthFeature/6.png");
export const networth7 = require("../assets/networthFeature/7.png");
export const networth8 = require("../assets/networthFeature/8.png");
export const networth9 = require("../assets/networthFeature/9.png");
export const networth10 = require("../assets/networthFeature/10.png");
export const networth11 = require("../assets/networthFeature/11.png");
export const networth12 = require("../assets/networthFeature/12.png");
export const networth13 = require("../assets/networthFeature/13.png");
export const networth14 = require("../assets/networthFeature/14.png");
export const networth15 = require("../assets/networthFeature/15.png");
export const networth16 = require("../assets/networthFeature/16.png");
export const networth17 = require("../assets/networthFeature/17.png");
export const networth18 = require("../assets/networthFeature/18.png");
export const networth19 = require("../assets/networthFeature/19.png");
export const networth20 = require("../assets/networthFeature/20.png");
export const networth21 = require("../assets/networthFeature/21.png");
export const networth22 = require("../assets/networthFeature/22.png");
export const networth23 = require("../assets/networthFeature/23.png");
export const networth24 = require("../assets/networthFeature/24.png");
export const networth25 = require("../assets/networthFeature/25.png");
export const networth26 = require("../assets/networthFeature/26.png");
export const networth27 = require("../assets/networthFeature/Group5.jpg");
export const networth28 = require("../assets/networthFeature/Group6.jpg");
export const networth29 = require("../assets/networthFeature/Group7.jpg");
export const budget = require("../assets/budgeting/image.png");
export const budget1 = require("../assets/budgeting/image-1.png");
export const budget2 = require("../assets/budgeting/image-2.png");
export const budget3 = require("../assets/budgeting/image-3.png");
export const budget4 = require("../assets/budgeting/image-4.png");
export const budget5 = require("../assets/budgeting/image-5.png");
export const budget6 = require("../assets/budgeting/image-6.png");
export const budget7 = require("../assets/budgeting/image-7.png");
export const budget8 = require("../assets/budgeting/image-8.png");
export const budget9 = require("../assets/budgeting/image-9.png");
export const budget10 = require("../assets/budgeting/image-10.png");
export const budget13 = require("../assets/budgeting/image-13.png");
export const iphone13a = require("../assets/iphone13a.png")
export const image3 = require("../assets/image3.png")
export const bottomImg = require("../assets/bottomImg.png")
export const comingsoon = require("../assets/coming-soon.png")