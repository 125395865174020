Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.reactLocations = "bx_block_content_management/investments/filter_items";
exports.reactMonthsgetGet = "GET";
exports.reactinvestments = "bx_block_content_management/investments";
exports.languageListInvestments = {
  English: {
    Investments: "Investments",
    MktAvg: "Mkt Avg:",
    Return: "Return:",
    LearnMore: "Learn More",
    Invest: "Invest",
    YourGoal: "Your Goal",
    Location: "Location",
    Industry: "Industry",
    tabViewName: ["Overview", "Pros & Cons", "Opportunity"]
  },
  Arabic: {
    tabViewName: ["نظرة عامة", "الفقرات والسلبيات", "Opportunity"],
    Industry: "المجال/ القطاع",
    Location: "موقع",
    YourGoal: "هدفك",
    Invest: "استثمر الآن",
    LearnMore: "التعرف على المزيد",
    Return: "العائد:",
    MktAvg: "متوسط السوق:",
    Investments: "الاستثمارات",
  }
}

// Customizable Area End