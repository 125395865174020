import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import "../assets/PortableAsset.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import AddCashController, {
    Props
} from "./AddCashController.web";
import { formatNumberWithCommas, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";
// Customizable Area End

// Customizable Area Start
const cashTheme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    borderBottom: '0px',
                    color: "white",
                    width: '100%',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: '#395D6B',
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                width: 'max-content',
                borderRadius: '8px',
            },
        },
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});
const cashCustomStyles = {
    control: (defaultStyles: any) => ({
        ...defaultStyles,
        textAlign: "left",
        fontSize: '16px',
        color: 'rgba(255, 255, 255, 0.60)',
        backgroundColor: "#2a2a38",
        fontWeight: '500',
        padding: "8px",
        fontFamily: 'Poppins',
        borderRadius: '8px',
    }),
};
// Customizable Area End

export default class AddCashWeb extends AddCashController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    logoImg1AddCashAdd = () => {
        return this.state.logoImg1AddCash === "rtl" ? "يضيف" : "Add"
    }
    logoImg1AddCashEdit = () => {
        return this.state.logoImg1AddCash === "rtl" ? "يحرر" : "Edit"
    }

    render() {
        return (
            // Customizable Area Start
            <Box style={{ width: '100%', }} className='cash_sideBorder box_bg_color' dir={this.state.logoImg1AddCash}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }} >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={this.state.imagenav_BackIcon} style={{ width: '24px', height: '13px', cursor: "pointer" }} onClick={this.portableAsset} />
                        <a style={{ color: 'white', padding: '0px 20px', fontSize: '24px', fontFamily: 'Poppins', fontWeight: 600 }}>
                            {!this.state.editCashData ? this.logoImg1AddCashAdd() : this.logoImg1AddCashEdit()} {this.getStringAddCash('Cash')}</a>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', }}>
                        {(this.selectCurrencyapi())}
                        <Box data-test-id="addCashDataSetting">
                            <Link to="/Account-Detail">
                                <img src={this.state.image_Vuesax} style={{ width: '41px', height: '41px', cursor: "pointer" }} />
                            </Link>
                        </Box>
                    </Box>
                </Box>
                <ThemeProvider theme={cashTheme}>
                    <CssBaseline />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10} md={6}>
                            <Formik
                                initialValues={{
                                    name: !this.state.editCashData ? "" : this.state.editCashData?.name,
                                    amount: !this.state.editCashData ? "" :formatNumberWithCommas(this.state.editCashData?.amount),
                                    currency: !this.state.editCashData ? this.state.selectCashCurrency : { value: this.state.editCashData.currency, label: this.state.editCashData.currency }
                                }}
                                onSubmit={(values: any) => {
                                    const cleanValues = {
                                        ...values,
                                        amount: sanitizeAndRemoveCommas(values?.amount || ''),
                                    };
                                    this.HandleSubmit(cleanValues);
                                }}
                                validationSchema={this.cashCodeSchema}
                                data-test-id="shareLogin"
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({ errors, values, handleSubmit, handleChange, setFieldValue }: any) => (
                                    <form onKeyDown={this.onKeyDown} onSubmit={handleSubmit} data-test-id='shareForm'>
                                        <Box>
                                            <Box>
                                                <Box>
                                                    <Typography className="cash_title">{this.getStringAddCash('Name')}</Typography>
                                                    <Box>
                                                        {this.OutlinedInputNew(webStyle, "placeholderName", values.name, handleChange, "name", "text", this.state.nameInput)}
                                                    </Box>
                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                        {this.finalErrorMessage(errors?.name || this.state.companyNameError)}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography className="cash_title">{this.getStringAddCash('Amount')}</Typography>
                                                    <Box>
                                                        {this.OutlinedInputNew(webStyle, "placeholderAmount", values.amount, handleChange, "amount", "text", this.state.amountInput,setFieldValue)}
                                                    </Box>
                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                        {this.finalErrorMessage(errors?.amount || this.state.amountError)}
                                                    </Box>
                                                </Box>

                                                <Box className="cash_assetsCurrency addNewAddCashMainCurrency">
                                                    <Box>
                                                        <Typography className="cash_title">{this.getStringAddCash('Currency')}</Typography>
                                                        <Box data-test-id="addCashCurrenNewcyDropdown">
                                                            {(this.selectMenu("currency", setFieldValue, cashCustomStyles, values))}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ textAlign: "end", paddingTop: '48px' }} data-test-id="addCashWebFinalButton">
                                                <Button
                                                    data-test-id="submitButton"
                                                    style={{
                                                        textAlign: 'center',
                                                        lineHeight: '24px',
                                                        fontWeight: 700,
                                                        fontFamily: 'Poppins',
                                                        textTransform: 'none',
                                                        color: '#3C3C50',
                                                        fontSize: '17px',
                                                        width: "auto",
                                                    }}
                                                    variant="contained"
                                                    className="share_nextButton addCashNewFinalButton"
                                                    type={this.state.focusedInput === "Amount" ? "button" : "submit"}
                                                >
                                                    {this.getStringAddCash('SaveButton')}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} sm={2} md={6}></Grid>
                    </Grid>
                </ThemeProvider>

                <Box>
                    <Dialog
                        open={this.state.openCashModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogCashModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={this.state.modelSuccess} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringAddCash("successfullyAddData")}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    inputRoot: {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        height: '56px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        fontFamily: 'Poppins',
        outline: 'none',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        borderRadius: '8px',
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    inputStyle: {
        border: "1px solid white",
        padding: "15px",
    },
    datePicker: {
        border: '1px solid black',
        borderRadius: '4px',
        padding: '5px',
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        top: '162px',
        paddingTop: '20px',
        paddingRight: '49px'
    },
};
// Customizable Area End
