// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.send_requests_Post_Data = "POST";
exports.users_slots="/bx_block_appointment_management/appointments/user_slot";
exports.send_requests_Get = "GET";
exports.send_requestsPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request?wealth_planner_id";
exports.send_requests_Post = "POST";
exports.send_allow_access = '/account_block/accounts/allow_access_permission';
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots';
exports.rescheduleBookingEndPoint = 'bx_block_appointment_management/appointments/reschedule_booking';
exports.cancelSlot = 'bx_block_appointment_management/appointments/cancel_slot'
exports.send_allow_access_post = "POST";
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots'
exports.getBookSlotEndPoint='bx_block_appointment_management/appointments/user_slot'
exports.postSetPlanEndPoint='bx_block_appointment_management/service_planners/set_plan'
exports.getPlanEndPoint='bx_block_appointment_management/service_planners/get_plan'
exports.postApiMethod= 'POST'
exports.getApiMethod= "GET"
exports.getConversationApi= "twilio_chat/create_conversation?type=user&receiver_id=";

exports.exampleAPiMethodPostType = "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.labelTitleText = "FormApprovalWorkflow";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip";
exports.FinancialPlannerApiMethodType = "GET";
exports.FinancialPlannerGet = "/bx_block_formapprovalworkflow/send_requests/wealth_planner_index";
exports.FinancialPlannerPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request";
exports.FinancialPlannerApiMethodTypePost = "POST";
exports.send_requests = "/bx_block_formapprovalworkflow/send_requests/get_status";
exports.service_status = "/bx_block_proposal_generation/user_service_selections/services_status";
exports.new_added_service = "/bx_block_proposal_generation/user_service_selections/newly_added_services";
exports.index_payment_method = "/bx_block_stripe_integration/payment_methods";
exports.sub_total = "/bx_block_proposal_generation/user_service_selections/subtotal";
exports.set_service = "/bx_block_proposal_generation/user_service_selections";

exports.languageListFinancialPlanner = {
  English: {
    successMessage: "Your request is sent successfully.",
    Next: "Next",
    notifyMessage:"You'll be notified once its accepted!",
    selectPlannerDesc: "List of wealth planners and advisors recommended for you. Select one to continue.",
    EstatePlanning: "Estate Planning",
    NameofCorporation: "Name of Corporation",
    Location: "Location",
    JurisdictionAdvise: "Jurisdiction advise",
    Yrs: "Yrs",
    Experience: "Experience",
    Yr: "Yr",
    Focus: "Focus",
    Email: "Email",
    Qualifications: "Qualifications",
    Certifications: "Certifications",
    Firms: "Firms",
    EmployementHistory: "Employement History",
    Disclosure: "Disclosure",
    AllReviews: "All Reviews",
    RatingsandReviews: "Ratings and reviews",
    SendEngagementRequest: "Send Engagement Request",
    basedoutof: "based out of",
    reviews: "reviews",
    Less: "Less",
    More: "More",
    noReviews: "No reviews yet to show!",
  },
  Arabic: {
    Next: "التالي",
    successMessage: "تم إرسال طلبك بنجاح.",
    notifyMessage: "ستتم إشعارك بمجرد قبوله!",
    EstatePlanning: "التخطيط العقاري",
    selectPlannerDesc: "قائمة بمخططي ومستشاري الثروات الموصى بهم لك. اختر واحدًا للمتابعة.",
    NameofCorporation: "اسم الشركة",
    Location: "الموقع",
    JurisdictionAdvise: "استشارة الاختصاص",
    Experience: "الخبرة",
    Yrs: "سنوات",
    Yr: "سنة",
    Focus: "التركيز",
    Email: "البريد الإلكتروني",
    Certifications: "الشهادات",
    Qualifications: "المؤهلات",
    Firms: "الشركات",
    EmployementHistory: "تاريخ التوظيف",
    Disclosure: "الإفصاح",
    RatingsandReviews: "التقييمات والمراجعات",
    AllReviews: "جميع المراجعات",
    SendEngagementRequest: "إرسال طلب الاشتراك",
    basedoutof: "مقره في",
    reviews: "المراجعات",
    More: "المزيد",
    Less: "أقل",
    noReviews: "لا توجد مراجعات لعرضها حتى الآن!"
  }
}

exports.languageListEngagementRequest = {
  English: {
    EstatePlanning: "Estate Planning",
    Location: "Location",
    NameofCorporation: "Name of Corporation",
    JurisdictionAdvise: "Jurisdiction advise",
    Experience: "Experience",
    Yrs: "Yrs",
    Focus: "Focus",
    Yr: "Yr",
    Email: "Email",
    Certifications: "Certifications",
    Qualifications: "Qualifications",
    Firms: "Firms",
    EmployementHistory: "Employement History",
    Disclosure: "Disclosure",
    AllReviews: "All Reviews",
    RatingsandReviews: "Ratings and reviews",
    basedoutof: "based out of",
    reviews: "reviews",
    Less: "Less",
    More: "More",
    noReviews: "No reviews yet to show!",
    allowAccessDesc: "Your request has been accepted! Please allow Myne to share your access to your net worth data and asset distribution to your financial planner in order to continue with Estate Planning services. This can be updated from phone settings.",
    rejectRequestDesc: "Your request has been rejected!",
    addServiceSuccess: "Your service has been added!",
    requestSentDesc: "Your request has been sent. You will be notified shortly!",
    ViewServices: "View Services",
    notificationMessage: "You have an upcoming meeting!",
    Chat: "Chat",
    ModifyBooking: "Modify Booking",
    ScheduleCall: "Schedule Call",
    callScheduleAt: "Your Call is Scheduled at",
    RequestSent: "Request Sent",
    chooseDifferentPlanner: "Choose a different wealth planner",
    myneSuccessDesc2: "This allows the platform to share information about you to wealth planners for your estate planning services.",
    myneSuccessDesc1: '"Myne" wants to access your net worth data and asset distribution',
    Allow: "Allow",
    dontAllow: "Don't Allow",
  },
  Arabic: {
    EstatePlanning: "التخطيط العقاري",
    NameofCorporation: "اسم الشركة",
    Location: "الموقع",
    JurisdictionAdvise: "استشارة الاختصاص",
    Experience: "الخبرة",
    Yrs: "سنوات",
    Yr: "سنة",
    Focus: "التركيز",
    Email: "البريد الإلكتروني",
    Certifications: "الشهادات",
    Qualifications: "المؤهلات",
    Firms: "الشركات",
    EmployementHistory: "تاريخ التوظيف",
    Disclosure: "الإفصاح",
    RatingsandReviews: "التقييمات والمراجعات",
    AllReviews: "جميع المراجعات",
    basedoutof: "مقره في",
    reviews: "المراجعات",
    More: "المزيد",
    Less: "أقل",
    noReviews: "لا توجد مراجعات لعرضها حتى الآن!",
    allowAccessDesc: "تم قبول طلبك! يرجى السماح لـ Myne بمشاركة الوصول إلى بيانات صافي الثروة وتوزيع الأصول الخاصة بك مع مخططك المالي من أجل الاستمرار في خدمات التخطيط العقاري. يمكن تحديث ذلك من إعدادات الهاتف.",
    rejectRequestDesc: "تم رفض طلبك!",
    addServiceSuccess: "تمت إضافة خدمتك!",
    ViewServices: "عرض الخدمات",
    requestSentDesc: "تم إرسال طلبك. سيتم إشعارك قريبًا!",
    notificationMessage: "لديك اجتماع قادم!",
    Chat: "الدردشة",
    ModifyBooking: "تعديل الحجز",
    callScheduleAt: "تم جدولة مكالمتك في",
    ScheduleCall: "جدولة مكالمة",
    RequestSent: "تم إرسال الطلب",
    chooseDifferentPlanner: "اختر مخطط ثروة مختلف",
    myneSuccessDesc1: "يريد 'Myne' الوصول إلى بيانات صافي الثروة الخاصة بك وتوزيع الأصول",
    myneSuccessDesc2: "يسمح هذا للنظام بمشاركة معلومات عنك مع مخططي الثروة لخدمات التخطيط العقاري.",
    dontAllow: "عدم السماح",
    Allow: "السماح",
  }
}

// Customizable Area End
