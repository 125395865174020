Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automaticformcreation";
exports.labelBodyText = "automaticformcreation Body";

exports.btnExampleTitle = "CLICK ME";

exports.languageList = {
  English: {
    cashCancel: "Cancel",
    cashDeleteConfirm: "Are you sure you want to delete ?",
    cashDelete: "Delete",
    cashEdit: "Edit",
    cashCash: "Cash",
    cashPrice: "Price",
    pensionCancel: "Cancel",
    pensionDeleteConfirm: "Are you sure you want to delete ?",
    pensionDelete: "Delete",
    pensionEdit: "Edit",
    pensionPension: "Pension",
    pensionDate: "Date",
    pensionPrice: "Price",
    shareCancel: "Cancel",
    shareDeleteConfirm: "Are you sure you want to delete ?",
    shareShare: "Share",
    shareDelete: "Delete",
    shareEdit: "Edit",
    shareNewPrice: "New Price",
    sharePrice: "Price",
    shareProfit: "Profit",
    shareShares: "Shares",
    propertyDeleteConfirm: "Are you sure you want to delete ?",
    propertyCancel: "Cancel",
    propertyShare: "Share",
    propertyDelete: "Delete",
    propertyAll: "All",
    propertyProperty: "Property",
    propertyShareDeed: "Share Deed",
    propertyInsure: "Insure",
    propertyUploadDocument: "Upload Document",
    propertyEdit: "Edit",
    propertyMarketPrice: "Market Price",
    propertyPurchasePrice: "Purchase Price",
    propertyProfit: "Profit",
    propertyApartmentNo: "Apartment No.",
    propertyNoassetsavailable: "No assets available in this category!",
    propertyFloorNo: "Floor No.",
    portableShare: "Share",
    portableUploadCertificate: "Upload Certificate",
    portableUploadReceipt: "Upload Receipt",
    portableDeleteConfirm: "Are you sure you want to delete ?",
    portableCancel: "Cancel",
    portableLuxuryAssets: "Luxury Assets",
    portableDelete: "Delete",
    portableAll: "All",
    portableReceipt: "Receipt",
    portableCertificate: "Certificate",
    portableInsure: "Insure",
    portableUploadDocument: "Upload Document",
    portablePurchasePrice: "Purchase Price",
    portableDate: "Date",
    portableModel: "Model",
    portableProfit: "Profit",
    portableMarketValue: "Market Value",
    portableNoassetsavailable: "No assets available in this category!",
    portableEdit: "Edit",
  },
  Arabic: {
    cashDeleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    cashCancel: "إلغاء",
    cashDelete: "حذف",
    cashCash: "نقد",
    cashEdit: "تعديل",
    cashPrice: "السعر",
    pensionDeleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    pensionCancel: "إلغاء",
    pensionDelete: "حذف",
    pensionPension: "معاش",
    pensionEdit: "تعديل",
    pensionPrice: "السعر",
    pensionDate: "التاريخ",
    shareDeleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    shareCancel: "إلغاء",
    shareDelete: "حذف",
    shareShare: "سهم",
    shareEdit: "تعديل",
    sharePrice: "السعر",
    shareNewPrice: "السعر الجديد",
    shareShares: "الأسهم",
    shareProfit: "الأرباح",
    propertyShare: "مشاركة",
    propertyDeleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    propertyCancel: "إلغاء",
    propertyDelete: "حذف",
    propertyProperty: "العقار",
    propertyAll: "الكل",
    propertyShareDeed: "مستند الملكية",
    propertyUploadDocument: "تحميل المستند",
    propertyInsure: "التأمين",
    propertyEdit: "تعديل",
    propertyPurchasePrice: "سعر الشراء",
    propertyMarketPrice: "السعر السوقي",
    propertyProfit: "الأرباح",
    propertyApartmentNo: "رقم الشقة",
    propertyFloorNo: "رقم الطابق",
    propertyNoassetsavailable: "لا توجد أصول متاحة في هذه الفئة!",
    portableShare: "مشاركة",
    portableUploadReceipt: "تحميل الإيصال",
    portableUploadCertificate: "تحميل الشهادة",
    portableDeleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    portableCancel: "إلغاء",
    portableDelete: "حذف",
    portableLuxuryAssets: "الأصول الفاخرة",
    portableAll: "الكل",
    portableReceipt: "إيصال",
    portableCertificate: "شهادة",
    portableUploadDocument: "تحميل المستند",
    portableInsure: "التأمين",
    portablePurchasePrice: "سعر الشراء",
    portableDate: "التاريخ",
    portableModel: "الطراز",
    portableMarketValue: "القيمة السوقية",
    portableProfit: "الأرباح",
    portableEdit: "تعديل",
    portableNoassetsavailable: "لا توجد أصول متاحة في هذه الفئة!",
  }
}
// Customizable Area End