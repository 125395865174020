import React from "react";

// Customizable Area Start
import '../assets/css/myneSettingsLanguage.css'
import { Link } from 'react-router-dom'
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";
import { FormControl } from "@material-ui/core";

export default class MyneLanguage extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  roundedNewArabic = () => {
    return this.state.logoImgsetting === "rtl" ? "lng-close-rounded lng-close-rounded-new" : "lng-close-rounded"
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-language-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-language-container">
          <div className="lng-small-text">{this.getStringGoalmanagement("Settings")}
            <Link to={'/Dashboard'} className={this.roundedNewArabic()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="lng-bold-text">{this.getStringGoalmanagement("Language")}</div>
          <div className="profile-language-container">
            <span className="language-title">{this.getStringGoalmanagement("SelectedCurrency")}</span>
            <FormControl component="fieldset">
              {
                this.state.languageList && this.state.languageList.list && this.state.languageList.list.length > 0 && this.state.languageList.list.map((c: any) =>
                  <div key={c.id} className="radio-button-wrapper">
                    <input
                      type="radio"
                      id={c.id}
                      name="language"
                      value={c.language}
                      checked={c.id === this.state.languageList.selection}
                      className="custom-radio"
                      onChange={() => {
                        this.onAddLanguageToAccount(c.id)
                      }}
                      data-test-id={`language-radio-input${c.id}`}
                    />
                    <span className="language-title-new">{this.getStringGoalmanagement(c.language)}</span>
                  </div>
                )
              }
            </FormControl>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}