import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {
  budget,
  budget1,
  budget2,
  budget3,
  appleLogo,
  googleIcon,
  homeIcon,
  facebook,
  instagram,
  twitter,
  youtube,
  linkedin,
  logo,
  ImgBack,
  bg1
} from "./assets";
import { Box, Typography } from "@material-ui/core";
// Customizable Area End

export default class BudgetWeb extends React.Component {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box style={{ position: 'relative' }}>
          <div style={{ ...webStyle.ImgBackTop, position: 'absolute' }}></div>
          <div style={{ ...webStyle.ImgBackTop1, position: 'absolute' }}></div>
          <Box style={{ maxWidth: "1946", margin: "auto" }}>
            <CnvestmentBox style={{ position: 'relative', zIndex: 20 }}>
              <Box>
                <MasterStyle>
                  Master Your Spending{" "}
                  <span style={webStyle.masterSaving}>Enhance <br /><span>Your Savings</span></span>
                </MasterStyle>
                <MasterFinances >
                  Stay ahead of your finances with MYNE's real-time Budgeting and
                  Spending Monitoring. Track your expenses, identify saving
                  opportunities, and manage your budget efficiently. MYNE helps
                  you understand your financial habits, paving the way for
                  improved fnancial well-being.
                </MasterFinances>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6} >
                    <img width="100%" height="100%" src={budget} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin>
                      <MasterCheck>
                        Check your{" "}
                        <span style={webStyle.masterSpends}>spends live</span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Real-Time Spending Insights at Your Fingertips"
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          With MYNE, experience the power of live tracking.
                          Monitor your expenses as they happen and stay on top of
                          your finances. It's real-time financial awareness,
                          simplifed and personalized for you.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>

              <BoxMarginTop>
                <Grid container item spacing={3} >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck>
                        View Transactions Live — with <br />
                        <span style={webStyle.masterSpends}>
                          auto categorization
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Simplifed Transaction Management"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          See your spending clearly with MYNE's intelligent
                          categorization. Our matching algorithm organizes your
                          transactions live, offering an easy-to-understand view
                          of your financial activity. Managing your money has
                          never been this effortless.
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget1} />
                  </Grid>
                </Grid>
              </BoxMarginTop>

              <Box>
                <Grid container item spacing={3}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget2} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <BoxMargin >
                      <MasterCheck >
                        Create Monthly Budget Goals <br /><span>and</span>{" "}
                        <span style={webStyle.masterSpends}>
                          Track Performance
                        </span>
                      </MasterCheck>
                      <MasterSavingTime>
                        "Your Financial Goals, Our Commitment"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Set, track, and achieve your financial goals with MYNE.
                          Whether it's monthly budget targets or custom financial
                          objectives, our platform helps you monitor your progress
                          and stay on course.
                        </span>
                      </MasterSavingTime>
                    </BoxMargin>
                  </Grid>
                </Grid>
              </Box>

              <BoxMarginTop>
                <Grid container item spacing={3} >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box>
                      <MasterCheck >
                        See Patterns and{" "}
                        <span style={webStyle.masterSpends}>Improve</span>
                      </MasterCheck>
                      <MasterSavingTime >
                        "Discover Your Financial Habits, Enhance Your Decisions"
                        <br />{" "}
                        <span style={webStyle.masterCheckMonitor}>
                          {" "}
                          Unlock the key to smarter spending with Myne Our tool helps you identify patterns in your. financial behaviour, providing valuable insights for improvement. Make informed decisions and optimize your financial health with MYNE.
                        </span>
                      </MasterSavingTime>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <img width="100%" height="100%" src={budget3} />
                  </Grid>
                </Grid>
              </BoxMarginTop>
            </CnvestmentBox>
          </Box>
          <div style={{ ...webStyle.ImgBackBottem, position: 'absolute' }}></div>
          <div style={{ ...webStyle.ImgBackBottem1, position: 'absolute' }} className="absolute"></div>
          <Box style={{ ...webStyle.footerwidth, position: 'relative', zIndex: 20, background: "#272733" }}>
            <Box style={{ maxWidth: "1946", margin: "auto", }}>
              <AppStoreGetItOnFooter id="footer" style={webStyle.footer}>
                <AppStoreGetItOnFooterHome style={webStyle.footerContent}>
                  <Box >
                    <img src={homeIcon} style={webStyle.footerIcons} alt="" />
                  </Box>
                  <Box >
                    <span style={webStyle.menuItemsForFooter} className="About">About us</span>
                    <span style={webStyle.menuItemsForFooter} data-test-id="Contact">Contact us</span>
                    <span style={webStyle.menuItemsForFooter}>Resources</span>
                    <span className="Roadmap" style={webStyle.menuItemsForFooter}>Roadmap</span>
                    <span className="TermsOf" style={webStyle.menuItemsForFooter}>Terms of use</span>
                    <span style={webStyle.menuItemsForFooter} className="policy">Cookies policy</span>
                    <span style={webStyle.menuItemsForFooter}>Privacy policy</span>
                  </Box>
                </AppStoreGetItOnFooterHome>
                <LogoForFooter >
                  <img src={logo} style={webStyle.imgLogo} alt="" />
                  <Typography style={webStyle.logoForFooter}>{" "}Who's managing your wealth?</Typography>
                </LogoForFooter>
                <Box style={{ ...webStyle.socialMediaIcons, margin: "13px 0px" }}>
                  <img style={webStyle.footerIcons} src={facebook} alt="" />
                  <img src={instagram} style={webStyle.footerIcons} alt="" />
                  <img style={webStyle.footerIcons} src={twitter} alt="" />
                  <img src={youtube} style={webStyle.footerIcons} alt="" />
                  <img style={webStyle.footerIcons} src={linkedin} alt="" />
                </Box>
                <Box className="appleAndGoogleCards" style={{ ...webStyle.appleAndGoogleCards, margin: "13px 0px", flexWrap: 'wrap', justifyContent: 'center' }}>
                  <img src={googleIcon} alt="" width="151px" height="46px" />
                  <img width="151px" height="46px" src={appleLogo} alt="" />
                </Box>
              </AppStoreGetItOnFooter>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  ImgBackTop: { width: '65%', height: '25%', right: '-458px', background: `url(${ImgBack}) no-repeat`, backgroundSize: 'cover', zIndex: 1 },
  googleimg: { height: "30px" },
  GooglePlayFont: { fontWeight: 600 },
  appStoreFooter: { background: "black", color: "#fff", width: "130px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  appStoreGetItOnFooter: { fontSize: "clamp(0.469vw, 0.469vw, 3vw)", fontFamily: 'Helvetica !important' },
  appleAndGoogleCards: { display: "flex", gap: "15px", marginLeft: "9px" },
  masterSaving: { color: "#2ee1d5" },
  imgLodoNew: { height: "30px" },
  ImgBackBottem1: { width: '65%', height: '20%', zIndex: 2, top: '80%', left: 0, background: 'linear-gradient(227deg, rgb(60 60 81) 66%, rgb(60 60 81 / 0%) 100%, rgba(60, 60, 81, 0) 100%) 0% 0% repeat scroll, none 0% 0% repeat scroll transparent' },
  imgLogo: { width: "170px", borderRadius: '0px' },
  logoForFooter: { fontSize: "10px", fontFamily: 'Helvetica !important', color: "white", paddingTop: '6.5px' },
  footerContent: { display: "flex", alignItems: "center" },
  footerwidth: { width: "100%", marginTop: "40px" },
  socialMediaIcons: { display: "flex", gap: "10px", alignItems: "center" },
  googleCardFooter: { background: "black", color: "#fff", width: "150px", borderRadius: "5px", display: "flex", justifyContent: "center", padding: "5px", alignItems: "center" },
  footer: { width: "100%", padding: "50px 10px", display: "flex", alignItems: "center", justifyContent: "space-between" },
  footerIcons: { width: "22px" },
  ImgBackBottem: { width: '65%', height: '20%', background: `url(${bg1}) no-repeat`, top: '80%', backgroundSize: 'cover', zIndex: 1 },
  ImgBackTop1: { width: '65%', right: '-458px', height: '25%', zIndex: 2, background: 'linear-gradient(45deg, rgb(60, 60, 81) 40%, rgba(60, 60, 81, 0.8) 60%, rgba(60, 60, 81, 0) 100%) repeat scroll 0% 0%, transparent none repeat scroll 0% 0%' },
  menuItemsForFooter: { fontSize: "12px", fontFamily: 'Helvetica !important', color: "#fff", marginLeft: "10px", cursor: "pointer" },
  masterCheckMonitor: { color: "white" },
  masterSpends: { color: "#2ee1d5" },
};
const MasterCheck = styled(Box)({
  fontSize: "clamp(1vw, 45px,2.344vw)", color: "white", fontFamily: 'Helvetica !important', lineHeight: '100%',
});
const MasterSavingTime = styled(Box)({
  color: "#2ee1d5", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', paddingTop: "6%", lineHeight: 'normal',
});
const LogoForFooter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end"
});
const CnvestmentBox = styled(Box)({
  paddingLeft: "110px !important", paddingRight: "110px !important", paddingTop: "40px !important",
  "@media (max-width: 950px)": {
    paddingRight: "30px !important",
    paddingLeft: "30px !important",
  },
  "@media (min-width: 601px)": {
    paddingRight: "30px !important",
    paddingLeft: "30px !important",
  },
  "@media (min-width: 1280px)": {
    paddingRight: "110px !important",
    paddingLeft: "110px !important",
  }
});
const MasterFinances = styled(Box)({
  color: "white", margin: "2% 0px", fontSize: "clamp(1vw,34px,1.771vw)", fontFamily: 'Helvetica !important', lineHeight: 'initial !important',
});
const BoxMargin = styled(Box)({
  padding: "100px 0px",
  "@media (max-width: 950px)": {
    padding: "0px !important",
  },
})
const MasterStyle = styled(Box)({
  fontSize: "clamp(1vw,92.71px,4.829vw)", fontFamily: 'Helvetica !important', color: "white", lineHeight: "100%",
});
const BoxMarginTop = styled(Box)({
  padding: "125px 0px",
  "@media (max-width: 601px)": {
    padding: "0px !important",
  },
})
const AppStoreGetItOnFooter = styled(Box)({
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
const AppStoreGetItOnFooterHome = styled(Box)({
  display: 'flex', alignItems: 'center', margin: "13px 0px", textAlign: 'center',
  "@media (max-width: 950px)": { display: 'flex', flexDirection: 'column' },
})
// Customizable Area End
