Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.leanConnection = "https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js"
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "apiintegrationbankaccounts";
exports.labelBodyText = "apiintegrationbankaccounts Body";
exports.getCountriesApiEndPoints = "account_block/accounts/get_countries";
exports.getBanksApiEndPoints = "account_block/accounts/banks";
exports.getCustomerIDApi = "account_block/accounts/get_customer_id";
exports.exampleAPiGetMethod = "GET";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End