Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.exampleAPiMethodtokenNew = "GET";

exports.SetCategoriessPutapi = "bx_block_profile/profiles/add_currency_to_account";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.Loginsubmittokennew = "bx_block_profile/profiles/currency_list";



// Customizable Area End