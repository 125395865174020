import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import "./style.css";
import OtpInput from 'react-otp-input';
import { logo, backIcon } from "./assets";
// Customizable Area End

export default class OTPInputAuth extends OTPInputAuthController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <div className="main-wrapper">
                {!this.state.isArabic ? 
                <div className="verification-wrapper">
                    <div className="verify-box">
                        <div className="logo-right">
                            <a href="#"><img src={logo} alt="logo" /></a>
                        </div>
                        <div className="verification-block" data-test-id="englishLanguageScreen">
                            <NavLink to="/EmailAccountRegistrationBlockweb"><h4><img src={backIcon} alt="back-icon" /> OTP Verification</h4></NavLink>
                            <p>Please enter the 4 digit verification code sent to you at <a href="#">{localStorage.getItem('email')}</a></p>
                            <div className="verification-code" id="englishScreenOTPInput">
                                <OtpInput
                                    value={this.state.otp}
                                    inputType="number"
                                    data-test-id="OtpField"
                                    shouldAutoFocus={true}
                                    onChange={(value) => this.setState({ otp: value, errorMsg: "" })}
                                    numInputs={4}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        width: "56px",
                                        height: "78px",
                                        background: "none",
                                        marginRight: "30px",
                                        border: "1px solid #2DE2D5",
                                        opacity: 1,
                                        borderRadius: "12px",
                                        color: "#fff",
                                        fontSize: "16px",
                                    }}
                                />
                                <div style={{padding: '1rem'}}>

                                {this.state.errorMsg && <div className="errorMsg" id="errorMsgInEnglish">{this.state.errorMsg} </div>
                                }
                                </div>
                            </div>
                            <span className="recevie-otp" data-test-id="resendOTPMsg">Didn't receive the OTP? <button data-test-id="resendOtpBtn" onClick={() => this.resendOtp()} className="resend">Resend again</button></span>
                            <button className="verify" data-test-id="verifyBtn" onClick={() => this.submitOtp()}>Verify</button>
                        </div>
                    </div>
                </div>
                :
                <div className="verification-wrapper arbic-otp-verification">
                <div className="verify-box">
                    <div className="logo-right"> 
                        <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="verification-block" id="arabicScreen">
                        <NavLink to="/EmailAccountRegistrationBlockweb"><h4>التحقق من مكتب المدعي العام <img src={backIcon} alt="back-icon" /> </h4></NavLink>
                        <p>الرجاء إدخال رمز التحقق المكون من 4 أرقام المرسل إليك على <a href="#">{localStorage.getItem('email')}</a></p>
                        <div className="verification-code" data-test-id="arabicOTPInput">
                            <OtpInput
                                value={this.state.otp}
                                inputType="number"
                                data-test-id="OtpFieldInArabic"
                                shouldAutoFocus={true}
                                numInputs={4}
                                onChange={(value) => this.setState({ otp: value, errorMsg: "" })}
                                renderInput={(props) => <input {...props} />}
                                renderSeparator={<span></span>}
                                inputStyle={{
                                    height: "62px",
                                    borderRadius: "12px",
                                    background: "none",
                                    marginRight: "30px",
                                    width: "62px",
                                    border: "1px solid #2DE2D5",
                                    opacity: 1,
                                    fontSize: "16px",
                                    color: "#fff",
                                }}
                            />
                            {this.state.errorMsg && <div className="errorMsg errorMsg-arabic">{this.state.errorMsg}</div>
                            }
                        </div>
                        <span className="recevie-otp"><button data-test-id="resendOtpBtnArabic" onClick={() => this.resendOtp()} className="resend">إعادة مرة أخرى</button> لم تتلق كلمة المرور لمرة واحدة؟ </span>
                        <button className="verify" data-test-id="verifyBtnArabic" onClick={() => this.submitOtp()}>يؤكد</button>
                    </div>
                </div>
            </div>
                }
            </div>
        );
    }
}