import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../assets/PortableAsset.css";
import AddSharesController, {
    Props
} from "./AddSharesController.web";
import { formatNumberWithCommas, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";
// Customizable Area End

// Customizable Area Start
const themes = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-input": {
                    width: '100%',
                    color: "white",
                    borderBottom: '0px',
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                borderRadius: '8px',
                backgroundColor: '#395D6B',
                width: 'max-content',
            },
        },
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});
const sharesCustomStyles = {
    control: (defaultStyles: any) => ({
        ...defaultStyles,
        textAlign: "left",
        padding: "8px",
        color: 'rgba(255, 255, 255, 0.60)',
        fontSize: '16px',
        fontWeight: '500',
        backgroundColor: "#2a2a38",
        borderRadius: '8px',
        fontFamily: 'Poppins',
    }),
};
// Customizable Area End

export default class AddSharesWeb extends AddSharesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    logoImg1AddSharesAdd= () =>{
        return this.state.logoImg1AddShares === "rtl" ? "يضيف" : "Add"
    }
    logoImg1AddSharesEdit= () =>{
        return this.state.logoImg1AddShares === "rtl" ? "يحرر" : "Edit"
    }

    render() {
        return (
            // Customizable Area Start
            <Box style={{ width: '100%', height:'100vh',overflowY:'auto' }} className='cash_sideBorder box_bg_color' dir={this.state.logoImg1AddShares}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', }} className="share_headerMain">
                    <Box style={{ display: 'flex', alignItems: 'center' }} className="new1232Add">
                        <img src={this.state.imagenav_BackIcon} style={{ width: '24px', height: '13px', cursor: "pointer" }} onClick={this.portableAsset} />
                        <a style={{ color: 'white', padding: '0px 20px', fontSize: '24px', fontFamily: 'Poppins', fontWeight: 600 }} className="new1232AddLabel">
                            {!this.state.editSharesData ? this.logoImg1AddSharesAdd() : this.logoImg1AddSharesEdit()} {this.getStringAddShares('Shares')}</a>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }} data-test-id="errorShareNew">
                        {(this.munuSharesSelect())}
                        <Box data-test-id="errorShareNewImage">
                            <Link to="/Account-Detail">
                                <img src={this.state.image_Vuesax} style={{ width: '41px', height: '41px', cursor: "pointer" }} />
                            </Link>
                        </Box>
                    </Box>

                </Box>
                <ThemeProvider theme={themes}>
                            <CssBaseline />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} md={6}>
                        <Formik
                            initialValues={{
                                companyName: !this.state.editSharesData ? "" : this.state.editSharesData.name,
                                numberOfShares: !this.state.editSharesData ? "" : this.state.editSharesData.number_of_shares,
                                price: !this.state.editSharesData ? "" : formatNumberWithCommas(this.state.editSharesData.price),
                                priceCurrency: !this.state.editSharesData ? this.state?.selectCashCurrency : { value: this.state.editSharesData.price_currency, label: this.state.editSharesData.price_currency },
                                newPrice: !this.state.editSharesData ? "" : formatNumberWithCommas(this.state.editSharesData.new_price),
                                newPriceCurrency: !this.state.editSharesData ? this.state?.selectCashCurrency : { value: this.state.editSharesData.new_price_currency, label: this.state.editSharesData.new_price_currency }
                            }}
                            onSubmit={(values: any) => {
                                const cleanValues = {
                                    ...values,
                                    numberOfShares: sanitizeAndRemoveCommas(values?.numberOfShares),
                                    price: sanitizeAndRemoveCommas(values?.price),
                                    newPrice:sanitizeAndRemoveCommas(values?.newPrice),
                                };
                                this.HandleSubmit(cleanValues);
                            }}
                            validationSchema={this.codeSchemaShares}
                            data-test-id="shareLogin"
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({ errors, values, handleSubmit, handleChange, setFieldValue, handleBlur }: any) => (
                                <form onSubmit={handleSubmit} onKeyDown={this.onKeyDown} data-test-id='shareForm'>
                                    <Box>
                                        <Typography>
                                            <Box>
                                                <Box>
                                                    <Typography className="share_title">{this.getStringAddShares('Companyname')}</Typography>
                                                    <Box>
                                                        {this.OutlinedInputAddShares(webStyle, 'placeholderCompanyname', 'companyName', values.companyName, handleChange, this.state.amountInput, "text")}
                                                    </Box>
                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                        {this.finalErrorMessage(errors.companyName || this.state.companyNameError)}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography className="share_title">{this.getStringAddShares('Numberofshares')}</Typography>
                                                    <Box>
                                                        {this.OutlinedInputAddShares(webStyle, 'placeholderNumberofshares', 'numberOfShares', values.numberOfShares, handleChange, this.state.currencyInput, "text",setFieldValue)}
                                                    </Box>
                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                        {this.finalErrorMessage(errors.numberOfShares)}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box>
                                                        <Box>
                                                            <Typography className="share_title">{this.getStringAddShares('Price')}</Typography>
                                                            {this.OutlinedInputAddShares(webStyle, 'placeholderPrice', 'price', values.price, handleChange, this.state.currencyData, "text",setFieldValue)}
                                                        </Box>
                                                        <Box sx={webStyle.fieldError} data-test-id="error">
                                                            {this.finalErrorMessage(errors.price || this.state.priceError)}
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography className="share_title">{this.getStringAddShares('CurrencyNew')}</Typography>
                                                        {(this.selectMenu("priceCurrency", setFieldValue, sharesCustomStyles, values, values.priceCurrency, "isCountryMenuOpen", this.state.isCountryMenuOpen))}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography className="share_title">{this.getStringAddShares('NewPrice')}</Typography>
                                                    <Box>
                                                        {this.OutlinedInputAddShares(webStyle, 'placeholderNewPrice', 'newPrice', values.newPrice, handleChange, this.state.newPrice, "text",setFieldValue)}
                                                    </Box>
                                                    <Box sx={webStyle.fieldError} data-test-id="error">
                                                        {this.finalErrorMessage(errors.newPrice || this.state.newPriceError)}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Typography>
                                        <Box style={{ textAlign: "end", paddingTop: '48px' }}>
                                            <Button data-test-id="submitButton"
                                                className="share_nextButton newButtonBy1mode"
                                                style={{
                                                    color: '#3C3C50',
                                                    textAlign: 'center',
                                                    fontSize: '17px',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 700,
                                                    lineHeight: '24px',
                                                    textTransform: 'none',
                                                    width: "auto",
                                                }}
                                                variant="contained"
                                                type="submit"
                                            >
                                                {this.getStringAddShares('SaveAddShares')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} sm={2} md={6}></Grid>
                </Grid>
                </ThemeProvider>
                <Box>
                    <Dialog
                        open={this.state.openSharesModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogShareModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogSharePaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={this.state.modelSuccess} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringAddShares("successfullyAddData")}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    customInputLabel: {
        color: 'white ',
        fontSize: '14px',
        marginBottom: '12px',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        top: '162px',
        paddingTop: '20px',
        paddingRight: '49px'
    },
    inputStyle: {
        border: "1px solid white",
        padding: "15px",
    },
    datePicker: {
        border: '1px solid black',
        borderRadius: '4px',
        padding: '5px',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    inputRoot: {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
        borderRadius: '8px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        outline: 'none',
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        fontFamily: 'Poppins',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,

        },

        height: '56px',
    },
    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },

};
// Customizable Area End
