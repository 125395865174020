export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const imgArrow = require("../assets/arrow.png");
export const imgSetting = require("../assets/setting.png");
export const imgAutoConnectBlack = require("../assets/autoconnect-black.png");
export const imgAutoConnectWhite = require("../assets/autoconnect-white.png");
export const imgCryptoWalletBlack = require("../assets/cryptowallet-black.png");
export const imgCryptoWalletWhite = require("../assets/cryptowallet-white.png");
export const headereBackIcon = require("../assets/backicon.png");
export const vertical3Dots = require("../assets/DotsthreeVertical.png");
export const pensionDefault = require("../assets/pension.png");
export const settingicon = require("../assets/settingicon.png");
export const card = require("../assets/card.png");
export const commodity1 = require("../assets/commodity1.png");
export const commodity2 = require("../assets/commodity2.png");
export const image_Vuesax = require("../assets/image_Vuesax.png");
export const imagenav_BackIcon = require("../assets/imagenav_BackIcon.png");
export const threeDot = require("../assets/DotsthreeVertical.png");
export const SuccessCard = require("../assets/cr.png");
export const MortgageImgWhite = require("../assets/mortgage-white.png");
export const MortgageImgBlack = require("../assets/mortgage-black.png");
export const CreditCardWhite = require("../assets/credit-card-white.png");
export const CreditCardBlack = require("../assets/credit-card-black.png");
export const PersonalLoanWhite = require("../assets/personal-loan-white.png");
export const PersonalLoanBlack = require("../assets/personal-loan-black.png");
export const OtherBlack = require("../assets/other-loan-black.png");
export const OtherWhite = require("../assets/other-loan-white.png");
export const image_Calendar = require("../assets/image_Calendar.png");
export const calendarImg = require("../assets/calendar.png");
export const bankImg = require("../assets/bank.png");
export const mortgageListImg = require("../assets/list_mortgage.png");
export const personalLoanListImg = require("../assets/list_personal_loan.png");
export const otherLoanListImg = require("../assets/list_other_loan.png");
