// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert, Platform } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from 'react-hot-toast';
import documentDownload from '../../../components/src/DownloadDocument.web';
const navigation = require("react-navigation");

export interface documentMedias {
  url?: any,
  id?: number,
  date?: string,
  updated_by?: string,
  file_name?: string,
}


export interface documentFileMenu {
  file_id?: number,
  folder_id?: number,
  file?: string
  fileAndFolderName?: string
}
// }

export interface GalleryMedias {
  url?: string,
  blob_id?: number,
  filename?: string,
  gallery_id?: string | number,
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface FolderItemsData {
  id?: number,
  type?: string,
  attributes?: { folder_name: string }
  account_id?: string,
  name?: string,
  created_at?: string,
  updated_at?: string,
  files?:
  | {
    id: number;
    file_name: string;
    file_url: string;
  }[]
  | null;
}

export interface DataItems {
  id: string,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder: {
      data: Array<FolderItemsData>
    }
  }
  files?:
  | {
    id: number;
    file_name: string;
    file_url: string;
  }[]
  | null;
}

interface FileMenu {
  id?: number | string,
  file?: string,
  fileAndFolderName?: string,
  folder_id?: number
  file_id?: any
}

export interface AllDocumentsResponse {
  data: Array<DataItems>;
  files?:
  | {
      id: number;
      file_name: string;
      file_url: string;
    }[]
  | null;
}

export interface AllDocumentData {
  gallery_id?: string,
  filename?: string,
  blob_id?: string
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface MapData {
  id: number
  url: string,
  blob_id: number,
  filename: string,
  gallery_id: number,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder_name: string,
    gallery: {
      id: number
    }
  }
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}

interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}

interface TokenAlert {
  token: string
}

// For Mobile
export interface Galleryobjectuniqueid {
  gallery_type: string;
  account_id: number;
}
export interface Innerobjectoffolder {
  folder_name: string;
  folder_type: string;
  gallery: Galleryobjectuniqueid;
  folder_medias: Array<GalleryMedia>
}
export interface Foldermedia {
  type: string;
  attributes: Innerobjectoffolder
}
export interface FolderInterface {
  data: Array<Foldermedia>
}
export interface GalleryMedia {
  url: string;
  blob_id: number;
  filename: string;
}
export interface Innerobject {
  gallery_type: string;
  gallery_medias: Array<GalleryMedia>
  folder: FolderInterface;
}
export interface Versionitems {
  data: Array<ObjectMedia>;
}
export interface ObjectMedia {
  type: string;
  attributes: Innerobject;
}

export interface Itemmedia {
  concat(data: Itemmedia[]): Itemmedia[];
  url: string,
  blob_id: number,
  filename: string
}

export interface DocumentlistItemapi {
  data: Array<GetAllDocumentlistapi>;
}

export interface GetAllDocumentlistapi {
  type: string;
  attributes: Objectattributes;
}

export interface Objectattributes {
  folder: ObjectOffolder;
  gallery_medias: Itemmedia;
}

export interface ObjectOffolder {
  data: Itemmedia[];
}

interface GetAllDocumentResponseJson {
  data: Array<Versionitems>;
  message: string
}

interface Errorobject {
  errors: string;
}

interface Tokenexpire {
  errors: Array<Tokenalert>
}
export interface Tokenalert {
  token: string
}

export interface Itemmedia {
  url: string;
  blob_id: number;
  filename: string;
  id: string,
  type: string,
  attributes: Folderobject
}
export interface Folderobject {
  folder_name: string;
  folder_type: string;
  gallery: Uniqeidnumberobject;
  folder_medias: Array<Fileinfolder>;
  cover_photo: null;
}
export interface Fileinfolder {
  url: string;
  blob_id: number;
  filename: string;
}

export interface Uniqeidnumberobject {
  gallery_type: string,
  account_id: number
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  loader: boolean;
  fileMenu: documentFileMenu | null;
  addFolderModal: boolean;
  renameModal: documentFileMenu | boolean | null;
  deleteModal: documentFileMenu | boolean | null;
  uploadedFiles: [];
  folderName: string;
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  allDocuments: Array<FolderItemsData | GalleryMedias>,
  allFolders: Array<FolderItemsData>;
  folderId: number;
  galleryId: number | string;
  blobId: number;
  anchorEl: HTMLAnchorElement | null;
  anchorEl2: HTMLAnchorElement | null;
  particularDocumentData: any;
  removeFolderModal: boolean;
  selectFolderName: string;
  selectFolderModal: boolean;
  currentFolderId: number;

  // For Mobile

  modalDots: boolean;
  folderModal: boolean;
  folderNameNative: string;
  cancel: boolean;
  create: boolean;
  rename: boolean;
  delete: boolean;
  modalRename: boolean;
  fileRenameText: string;
  deleteModalnative: boolean;
  fileDeleteText: string;
  documentList: Array<Itemmedia>;
  blobIdNumber: number;
  floderId: string;
  documentId: string;
  isFolder: boolean;
  isDelete: boolean;
  isloading: boolean;
  yesNoModal: boolean;
  yesNoModalFile: boolean;
  responseFailureData: boolean;
}

interface SS {
  id: string;
}

export default class DocumentationController extends BlockComponent<
  Props,
  S,
  SS
> {
  addFolderApiCallId: string = "";
  renameFileApiCallId: string = "";
  deleteFileApiCallId: string = "";
  allDocumentsApiCallId: string = "";
  renameFolderApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesAndFoldersApiCallId: string = "";
  getAllFoldersApiCallId: string = "";
  getDocumentDataWithIdApiCallId: string = "";
  moveFileApiCallId: string = "";

  // For Mobile

  getAllDocumentsApiCallId: string = "";
  createDocumentApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";
  getAddFolderApiCallId: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  removeFolderApiCallId: string = "";
  willFocusScreen!: string;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteModal: null,
      allDocuments: [],
      allFolders: [],
      addFolderModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      renameModal: null,
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      fileMenu: null,
      blobId: 0,
      particularDocumentData: [],
      removeFolderModal: false,
      selectFolderModal: false,
      selectFolderName: '',
      currentFolderId:0,

      // For Mobile

      modalDots: false,
      folderModal: false,
      cancel: false,
      create: false,
      rename: false,
      delete: false,
      folderNameNative: '',
      modalRename: false,
      documentList: [],
      documentId: '512',
      blobIdNumber: 0,
      fileRenameText: '',
      deleteModalnative: false,
      fileDeleteText: '',
      floderId: '',
      isFolder: false,
      isDelete: false,
      isloading: false,
      yesNoModal: false,
      yesNoModalFile: false,
      responseFailureData: false,
      anchorEl: null,
      anchorEl2: null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // For Mobile
      if (responseJson.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson && !responseJson.errors) {
        this.getAllSuccessFunctionCallBack(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.getAllErrorFunctionCallBack(apiRequestCallId, responseJson);
      }

      // For Web
        
      if (responseJson && !responseJson.errors) {
        this.successCallBack(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.failureCallBack(apiRequestCallId, responseJson);
      }
    }
  }



  async componentDidMount(): Promise<void> {
    let DocId = this.props.navigation.getParam('id')
    this.setState({currentFolderId:DocId})
    if (Platform.OS !== 'web') {
      this.willFocusScreen = this.props.navigation.addListener(
        'willFocus',
        () => {
          this.getAllDocumentList();
          this.getAllFolders();
          this.functionExpireCheck()
        }
      );
    }
    else {
      this.getAllDocuments();
      this.getAllFolders();
      if (DocId) {
        this.getDocumentDataWithId(DocId)
      }
    }
  }

  handleMenuModal = (value: FileMenu, event: React.MouseEvent<HTMLElement>) => {
    if (value.file === 'file') {
      this.setState({ fileMenu: value, anchorEl2: event.currentTarget as HTMLAnchorElement })
    }
    else if (value.file === 'folder') {
      this.setState({ fileMenu: value, anchorEl: event.currentTarget as HTMLAnchorElement })
    }
  }

  handleDocumentMenuModalClose = () => {
    this.setState({
      anchorEl: null,
      anchorEl2: null
    })
  }

  handleAddFolderModal = (value: boolean) => {
    this.setState({ addFolderModal: value, folderName: '' })
  }
  handleRemoveFolderModal = (value: boolean) => {
    this.setState({ removeFolderModal: value })
    this.handleDocumentMenuModalClose()
  }
  handleSelectFolderModal = (value: boolean) => {
    this.setState({ selectFolderModal: value })
    this.handleDocumentMenuModalClose()
  }

  handleRenameModal = (value: documentFileMenu | null) => {
    this.setState({ renameModal: value, renameValue: "" })
    this.handleDocumentMenuModalClose()

  }

  handleDeleteModal  = (value: documentFileMenu | null) => {
    this.handleDocumentMenuModalClose()
    this.setState({ anchorEl: null });
    this.setState({ deleteModal: value })
  }

  handleFolderName = (event: React.ChangeEvent<HTMLInputElement>) => {

    const value = event.target.value;

    if (value) {
      this.setState({ folderName: value.trim() });
    } else {
      this.setState({ folderName: value });
    }
  }

  handleSelectFolder = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectFolderName: event.target.value as string }); // Assuming folderName is a string
  }

  handleRename = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (value.target?.value.trim() === "") {
      this.setState({ renameValue: value.target?.value.trim() });
    } else {
      this.setState({ renameValue: value.target?.value });
    }
  }

  handleFolderNavigate = (gallery_id?: number | string, folderId?: number) => {
    if (gallery_id && folderId) {
      this.setState({ folderId: folderId, galleryId: gallery_id })
      this.props.navigation.navigate("DocumentationFolder", { galleryId: gallery_id, folderId: folderId })
    }
  }

  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(event.target.files!)
    let formData = new FormData();
    for (const file of files) {
      formData.append("name", file)
    }
    this.setState({ loader: true })
    this.uploadFilesAndFoldersApiCallId = await this.apiCall({
      method: 'POST',
      endPoint: `/bx_block_documentstorage2/document_folders`,
      body: formData,
      type: "formData"
    })
  }

  getAllDocuments = async () => {
    this.setState({ loader: true })
    this.allDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentstorage2/document_folders/files_index`,
    })
  }

  getAllFolders = async () => {
    this.setState({ loader: true })
    this.getAllFoldersApiCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: 'bx_block_documentstorage2/document_folders',
    })
  }

  moveFile = async () => {
    this.setState({ loader: true })
    this.moveFileApiCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: 'bx_block_documentstorage2/document_folders/move_file',
      body: {
        file_id: this.state.fileMenu?.file_id,
        folder_id: this.state.selectFolderName
      }
    })
    this.setState({ selectFolderName: "" })
    this.handleSelectFolderModal(false)
  }

  getDocumentDataWithId = async (mainId: string) => {
    this.setState({ loader: true })
    this.getDocumentDataWithIdApiCallId = await this.apiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_documentstorage2/document_folders/${mainId}`,
    })
  }

  renameFileAndFolder = async (value: documentFileMenu | null) => {
    if (value) {
      this.setState({ loader: true })
      this.renameFileApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'PUT',
        endPoint: `bx_block_documentstorage2/document_folders/${value.folder_id}`,
        body: {
          name: this.state.renameValue
        }
      })
      this.setState({ renameModal: false, fileMenu: null })
      // this.getDocumentDataWithId(this.props.navigation.getParam('id'))
      this.getAllDocuments()
      this.getAllFolders();
    }
  }

  addFolder = async () => {
    if (!this.state.folderName.trim()) {
      this.showAlert('Alert', 'Please Enter Folder Name');
      return
    }
    this.setState({ loader: true });
    this.addFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentstorage2/document_folders`,
      body: {
        name: this.state.folderName
      },
    })
    // this.getDocumentDataWithId(this.props.navigation.getParam('id'))
  }

  removeFolder = async () => {
    this.setState({ loader: true });
    this.removeFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PUT',
      endPoint: `/bx_block_documentstorage2/document_folders/remove_file`,
      body: {
        file_id: this.state.fileMenu?.file_id
      },
    })
  }

  deleteFolderAndFiles = async (value: documentFileMenu | null) => {
    if (value) {
      this.setState({ loader: true })
      if (value.file === "folder") {
        this.deleteFolderApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'DELETE',
          endPoint: `bx_block_documentstorage2/document_folders/${value.folder_id}`,
        })
        if (this.props.navigation.getParam('id')) {
          this.props.navigation.goBack();
        }
        this.setState({ deleteModal: null, fileMenu: null });
        this.getAllFolders();
      }
      else {
        this.deleteFileApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'DELETE',
          endPoint: `bx_block_documentstorage2/document_folders/delete_file`,
          body: {
            file_id: value.file_id
          }
        })
        this.setState({ deleteModal: null, fileMenu: null });
        this.getAllFolders();
      }
    }
  }

  folderNavigate = (element: GalleryMedias) => {
    if (element.attributes?.folder_name) {
      this.handleFolderNavigate(element.attributes.gallery?.id, element.id)
    }
  }

  //  For Mobile
  getAllSuccessFunctionCallBack = (apiRequestCallId: string, responseJson: GetAllDocumentResponseJson & DocumentlistItemapi) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.moveFileApiCallId) {
      this.moveFileSuccesCallBack(responseJson)
    }
    else if (apiRequestCallId === this.removeFolderApiCallId) {
      this.removeFileFromFolderSuccessCallback(responseJson)
    }
  }

  getAllErrorFunctionCallBack = (apiRequestCallId: string, responseJson: Tokenexpire & string & Errorobject) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.moveFileApiCallId) {
      this.moveFileFailureCallBack(responseJson);
    }
  }

  //  For Web

  successCallBack = (apiRequestCallId: string, responseJson: string & AllDocumentsResponse & SuccessResponse) => {
    if (responseJson && apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.getAllFoldersApiCallId) {
      this.getAllFoldersSuccessCallback(responseJson)
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.getDocumentDataWithIdApiCallId) {
      this.getDocumentDataWithIdSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.removeFolderApiCallId) {
      this.removeFileFromFolderSuccessCallback(responseJson)
    }

  }

  failureCallBack = (apiRequestCallId: string, responseJson: string & TokenExpire & ErrorResponse) => {
    if (apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsFailureCallback(responseJson);
    } else if (apiRequestCallId === this.getAllFoldersApiCallId) {
      this.getAllFoldersFailureCallback(responseJson)
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.getDocumentDataWithIdApiCallId) {
      this.getDocumentDataWithIdFailureCallback(responseJson);
    }

  }

  // API Success and Failure Callbacks for Web

  getAllDocumentsSuccesCallback = (response: string & AllDocumentsResponse & SuccessResponse) => {
    this.setState({ loader: false })
    if (response.files)
      this.setState({ allDocuments: response.files })
  }

  getAllFoldersSuccessCallback = (response: any) => {
    this.setState({ loader: false });
    this.setState({ allFolders: response?.folders })
  }

  getAllDocumentsFailureCallback = (response: TokenExpire) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors[0].token)
  }

  getAllFoldersFailureCallback = (response: TokenExpire) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors[0].token)
  }

  uploadFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'Files uploaded successfully')
    this.getAllDocuments();
  }

  uploadFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  addFolderSuccessCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'Folder added successfully')
    this.getAllDocuments();
    this.getAllFolders();
  }

  addFolderFailureCallback = (response: string) => {
    toast.error('This folder name has already been taken');
    this.setState({ loader: false })
    // this.getAllDocuments();
  }

  renameFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
    this.getAllFolders();
  }

  moveFileSuccesCallBack = (response: SuccessResponse) => {
    this.setState({ loader: false })
    // this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  renameFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  renameFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  removeFileFromFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    let DocId = this.props.navigation.getParam('id')
    this.getDocumentDataWithId(DocId)
  }

  renameFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  deleteFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
    let DocId = this.props.navigation.getParam('id')
    if (DocId) {
      this.getDocumentDataWithId(DocId)
    }
  }

  deleteFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }
  deleteFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
    this.getAllFolders();
    let DocId = this.props.navigation.getParam('id')
    if (DocId) {
      this.getDocumentDataWithId(DocId)
    }
  }

  deleteFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  moveFileFailureCallBack = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  getDocumentDataWithIdFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
  }

  // API Success and Failure Callbacks for Mobile

  getAllDocListSuccesCallBack = (responseJson: DocumentlistItemapi) => {
    this.setState({ isloading: false }
      , () => {
        this.setState({ documentList: responseJson.data[0].attributes.gallery_medias.concat(responseJson.data[0].attributes.folder.data) });
      }
    )
  };

  getAllDocListFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== errorResponseJson", responseJson);
  };

  createDocumentSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false })
    if (responseJson.data) {
      this.getAllDocumentList()
    }
  };

  createDocumentFailureCallBack = (responseJson: Errorobject) => {
    this.setState({ isloading: false })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  deleteFileSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModalFile: false, deleteModalnative: false, blobIdNumber: 0, fileDeleteText: '', isloading: false })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  deleteFileFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
  };

  renameNewFileSuccesCallBack = async (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, blobIdNumber: 0, fileRenameText: '' })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  renameNewFileFailureCallBack = (responseJson: Errorobject) => {
    this.setState({ isloading: false, modalRename: false, })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  addNewFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ folderModal: false }, () => {
      this.getAllDocumentList()
    })
    this.setState({ isloading: false })
  };

  addNewFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
  };

  deleteFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModal: false, isloading: false, deleteModalnative: false, floderId: '', fileDeleteText: '' })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  deleteFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
  };

  renameFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, floderId: '', folderNameNative: '', })
    this.showAlert('Alert', responseJson?.message)
    this.getAllDocumentList()
  };

  getDocumentDataWithIdSuccessCallback = (responseJson: any) => {
    this.setState({ isloading: false, particularDocumentData: responseJson.files, loader: false })
  };

  renameFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false, modalRename: false, })
  };

  createFolderOpen = () => { this.setState({ addFolderModal: false, }, () => this.addFolder()) }
  removeFolderOpen = () => { this.setState({ removeFolderModal: false, }, () => this.removeFolder()) }

  getAllDocumentList = async () => {
    this.setState({ isloading: true })
    this.getAllDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentstorage2/document_folders/files_index`,
      body: "",
      type: ""
    });
  }

  createDocumentApiCell = async (urlresponse: string, fileName: string) => {
    this.setState({ isloading: true })
    let formData = new FormData();
    formData.append("gallery_medias[]",
      JSON.parse(JSON.stringify({
        uri: urlresponse,
        type: "sample/pdf",
        name: fileName
      }))
    )

    this.createDocumentApiCallId = await this.apiCall({
      contentType: "multipart/form-data",
      method: 'PATCH',
      endPoint: `/bx_block_documentation/gallery_documents/${this.state.documentId}`,
      body: formData,
      type: 'formData'
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `/bx_block_documentation/gallery_documents/${this.state.documentId}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    const apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber,
          filename: this.state.fileRenameText
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `bx_block_documentation/gallery_documents/${this.state.documentId}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  addFolderApiCell = async () => {
    if (this.state.folderNameNative.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.folderNameNative
        }
      }
    }

    this.getAddFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentation/folder_documents`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.fileRenameText,
        }
      }
    }
    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `bx_block_documentation/folder_documents/${this.state.floderId}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `bx_block_documentation/folder_documents/${this.state.floderId}`,
      body: apidata,
      type: ""
    });
  }

  threeDotsTouchable = (item: Itemmedia) => item?.type !== 'folder' ? this.onRequestClose(item?.blob_id, item?.filename) : this.onFolderRenameandDelete(item.id, item.attributes.folder_name)

  onRequestClose = (Item_id: number, fileName: string) => this.setState({ blobIdNumber: Item_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: false, isDelete: false }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  onFolderRenameandDelete = (Items_id: string, fileName: string) => this.setState({ floderId: Items_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: true, isDelete: true }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  folderClickButton = () => this.setState({ folderModal: true, create: false, cancel: false, folderNameNative: '' })

  modalDotsTrue = () => this.setState({ modalDots: false })

  modalOverMOdal = () => this.setState({ modalDots: false, modalRename: true, })

  modalDeleteOverMOdal = () => this.setState({ modalDots: false, deleteModalnative: true, })

  navigateScreen = (item: Itemmedia) => { this.props.navigation.navigate('DocumentationFolder', { item: item, documentId: this.state.documentId }) }

  changeTextFolderModal = (text: string) => { this.setState({ folderNameNative: text }) }

  RenameChangeFileModal = (text: string) => { this.setState({ fileRenameText: text }) }

  changeDeleteFileModal = (text: string) => { this.setState({ fileDeleteText: text }) }

  cancelFolderModal = () => this.setState({ folderModal: false, cancel: true, create: false })

  cancelDeleteFile = () => this.setState({ deleteModalnative: false, cancel: true, delete: false })

  cancelFolderRename = () => this.setState({ modalRename: false, cancel: true, rename: false })

  createFolderModal = () => { this.setState({ create: true, cancel: false, }, () => this.addFolderApiCell()) }

  responseRenameFile = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFileApiCell()) }

  responseRenameFolder = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFolderApiCell()) }

  responseDeleteFile = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModalFile: true }) }

  responseDeleteFolder = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModal: true }) }

  responseDeleteFolderYesNo = () => { this.DeleteFolderApiCell() }

  responseDeleteFolderYesNoFile = () => { this.DeleteFileApiCell() }

  uplaodCsvFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
      if (isResponse) {
        this.createDocumentApiCell(isResponse[0].uri, isResponse[0].name)
      }
    }
    catch (error) {
    }
  };

  yesNoModalFalse = () => this.setState({ yesNoModal: false, yesNoModalFile: false })

  handleDocumentView = (redirectUrl: string) => {
    window.open(redirectUrl, "_blank")
  }

  handleDocumentDownload = async(url: string) => {
    documentDownload(url);
  }
  
  handleNavigation = (mainId: number) => {
    this.props.navigation.navigate(`Document`, { id: mainId })

  }

  shortText = (text: string) => {
    if (text.length > 15) {
      return text.substring(0, 15) + '...';
    }
    return text;
  }
  functionExpireCheck=async ()=>{
  const authToken = localStorage.getItem("token")
if (!authToken ) {
  this.NavigateToLogout() 
}
}
NavigateToLogout=()=>{
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('userEmail');
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('rememberMe');
  window.localStorage.removeItem('email');
this.navigateRoute()
}
navigateRoute = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
  msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(msg)
}
}



// Customizable Area End
