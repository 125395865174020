import React from "react";
// Customizable Area Start
import "./style.css";


import {
    Button
} from "@material-ui/core";
import ReminderSnackbar from "../../../components/src/Snackbar.web"
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class PlannerVideoCall extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start



    // Customizable Area End

    render() {
        // Customizable Area Start
        return (

            <div style={{ backgroundColor: ' rgb(43, 43, 60)', width: '100%', height: '100vh', flex: 1 }}>
                <Button style={{ marginLeft: '15px', color: '#3C3C51', border: '1px solid #C9F9F6', background: '#C9F9F6', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' }}  data-test-id = "VideoCall"> {this.state.currentAppointment ? 'Join Call' : 'Video Call'}</Button>
                <ReminderSnackbar open={this.state.showToast} handleClose={this.handleToastClose} message="You have an upcoming meeting!" />

            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleForPortfolio = {



}
// Customizable Area End
