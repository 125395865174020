import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Select from 'react-select';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imagenav_BackIcon, image_Vuesax, modelSuccess, imagenav_Bitmap } from "./assets";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import FormControl from '@material-ui/core/FormControl';
import * as Yup from "yup";
import { OutlinedInput } from "@material-ui/core";
import { getStorageData } from 'framework/src/Utilities';
import { processInputValue } from "../../../components/src/commonFn";

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    image_Vuesax: string;
    imagenav_BackIcon: string;
    hader_dropdown: any;
    currency_name: any;
    selectedFileError: string;
    companyNameError: string;
    amountError: string,
    priceError: string,
    companyName: string,
    numberOfShares: string,
    selectCurrency: { value: string, label: string },
    selectCashCurrency: any,
    currencyList: any,
    editCashData: any;
    openCashModel: boolean,
    modelSuccess: string,
    nameInput: any;
    amountInput: any;
    currencyInput: any;
    focusedInput: string;
    logoImg1AddCash: string,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AddCashController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserPDFDataCallId: any;
    addSubCategoryApiCallId: any;
    deleteCategoriesApiCallId: any;
    deleteSubCategoriesApiCallId: any;
    validationApiCallId: string = "";
    getCategoriesApiCallId: any;
    addCategoryApiCallId: any;
    postUserSelectDataCallId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            logoImg1AddCash: "",
            focusedInput: "name",
            nameInput: React.createRef(),
            amountInput: React.createRef(),
            currencyInput: React.createRef(),
            currency_name: [],
            selectedFileError: '',
            companyNameError: '',
            amountError: '',
            imagenav_BackIcon: "",
            priceError: '',
            image_Vuesax: image_Vuesax,
            companyName: '',
            hader_dropdown: [],
            selectCurrency: { value: '', label: '' },
            selectCashCurrency: '',
            numberOfShares: '',
            token: "",
            currencyList: [],
            editCashData: this.props.location?.state?.cashEditData?.attributes,
            openCashModel: false,
            modelSuccess: modelSuccess,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    errorMessage = (cashResponseJson: any) => {
        if (cashResponseJson.errors.name) {
            this.setState({ companyNameError: cashResponseJson.errors.name[0] })
        } else if (cashResponseJson.errors.amount) {
            this.setState({ amountError: cashResponseJson.errors.amount[0] })
        }
    }
    finalErrorMessage(error: any) {
        if (error) {
            return error
        } else {
            return;
        }
    }

    dialogCashPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    dialogCashModel = () => {
        this.setState({ openCashModel: !this.state.openCashModel })
        this.props.navigation.navigate('CategoriessubcategoriesWeb')
    }

    getCurrencyList = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.Loginsubmittoken;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.exampleAPiMethodtoken,
        });
        this.postUserPDFDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }
    async componentDidMount() {
        this.getCurrencyList();
        this.state.nameInput.current.focus()
    }

    async componentWillMount() {
        const language = await getStorageData('language');
        const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
        this.setState({ logoImg1AddCash: logoImg1AddCash });
        const limagenav_BackIcon = language === 'Arabic' ? imagenav_Bitmap : imagenav_BackIcon;
        this.setState({ imagenav_BackIcon: limagenav_BackIcon  });
    }
    portableAsset = () => {
      !this.state.editCashData?  this.props.navigation.navigate("CategoriessubcategoriesWeb"): this.props.navigation.navigate('CashAssetsOverview')
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserPDFDataCallId !== null &&
            this.postUserPDFDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (cashResponseJson.list) {
                const userSelectedCurrncy = cashResponseJson.list?.find((value: any) => {
                    return value?.id == cashResponseJson?.selection
                })

                const currency = cashResponseJson.list?.map((value: any) => (
                    { value: value.currency_type, label: value.currency_type }
                ));
                this.setState({
                    currency_name: currency, currencyList: cashResponseJson,
                    selectCurrency: { value: userSelectedCurrncy.currency_type, label: this.state.logoImg1AddCash === "rtl" ? `${userSelectedCurrncy.currency_type}${userSelectedCurrncy.symbol}` : `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` },
                    selectCashCurrency: { value: userSelectedCurrncy.currency_type, label: userSelectedCurrncy.currency_type }
                });
                this.setState({
                    hader_dropdown: cashResponseJson?.list?.map((value: any) => (
                        { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
                    ))
                });
            }
            if (cashResponseJson?.meta?.message.includes("successfully")) {
                this.setState({ openCashModel: !this.state.openCashModel })
            } else if(cashResponseJson?.meta?.message.includes("updated")){
                this.props.navigation.navigate('CashAssetsOverview')
            }else {
                this.errorMessage(cashResponseJson)
            }
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId !== null &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let cashResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (cashResponseJson.meta.currency) {
                this.getCurrencyList()
            }
        }
        // Customizable Area End
    }

    selectCurrencyapi = () => {
        return <FormControl>
            <Select options={this.state.hader_dropdown}
                value={this.state.selectCurrency}
                onChange={(val: any) => { this.setCurrency(val) }}
                classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="selectMenu" />
        </FormControl>
    }

    submitCashApi = (newCashRequestMessage: any) => {
        if (this.state.editCashData) {
            newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'PUT'
            );
        } else {
            newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                'POST'
            );
        }
    }

    HandleSubmit = async (values: any) => {
        const dataCash = localStorage.getItem("token")
        const header = { "token": dataCash };
        let userDetailurl = !this.state.editCashData ?configJSON.CashApi :`account_block/cashes/${this.props.location?.state.cashEditData.id}`;
        const formData = new FormData();
        formData.append("data[attributes][name]", values.name);
        formData.append("data[attributes][amount]", values.amount);
        formData.append("data[attributes][currency]", values.currency.value ? values.currency.value : this.state.selectCashCurrency.value);
        const newCashRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserPDFDataCallId = newCashRequestMessage.messageId;
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        this.submitCashApi(newCashRequestMessage)
        runEngine.sendMessage(newCashRequestMessage.id, newCashRequestMessage);
    }

    setCurrency = (value: { value: string, label: string }) => {
        const currencyId = this.state.currencyList?.list?.find((newValue: any) => {
            return newValue?.currency_type == value?.value
        }).id
        this.setCurrencyPutapi(currencyId)
    }

    setCurrencyPutapi = (currencyId: string) => {
        const dataCash = localStorage.getItem("token")
        const header = { "token": dataCash };
        let userDetailurl = configJSON.SetCurrencyPutapi 
        const formData = new FormData();
        formData.append("currency_id", currencyId);
        const newCashRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postUserSelectDataCallId = newCashRequestMessage.messageId;
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            userDetailurl
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        newCashRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );
        runEngine.sendMessage(newCashRequestMessage.id, newCashRequestMessage);
    }

    getStringAddCash = (key: string) => {
        let languageLogin = localStorage.getItem("language") || "English"
        return configJSON.languageListLogin[languageLogin][key]
    }
    cashCodeSchema = Yup.object().shape({
        amount: Yup.string()
            .required(this.getStringAddCash('amountError')),
        name: Yup.string()
            .required(this.getStringAddCash('nameError')),
    });
    settingRouteCash = () => {
        this.props.navigation.navigate('Settings2')
    }

    selectMenu(name: string, setFieldValue: any, customStyles: any, values: any) {
        
        return (
            <Select
                defaultValue={this.state.selectCashCurrency}
                value={values?.currency?.value ? values?.currency : this.state.selectCashCurrency}
                options={this.state.currency_name}
                onChange={(val: any) => { setFieldValue(name, val) }} name={name} classNamePrefix="an-simple-select" className="selectMenu" data-test-id="selectMenu" styles={customStyles} />
        )
    }

    handleEnterKey = (e: any) => {
        if (e.key === "Enter") { if (this.state.focusedInput === "name") { this.state.amountInput.current?.focus(); this.setState({ focusedInput: "amount" }) } }
    };
    onKeyDown = (keyEvent: any) => { if ((keyEvent.charCode || keyEvent.keyCode) === 13) { keyEvent.preventDefault() } }

    handleNameInputClick = (name: string) => { this.setState({ focusedInput: name }) };

      OutlinedInputNew = (
        webStyle: any,
        placeholderData: string,
        value: string,
        handleChange: any,
        name: string,
        type: string,
        nameInputField: { current: null },
        setFieldValue?: any 
      ) => {
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;

            if (name === "amount" && setFieldValue) { 
                    const result = processInputValue(inputValue, 11);
                    if (result) {
                        const { actualValue, formattedValue } = result;
                        setFieldValue(name, actualValue);
                        handleChange(name)(formattedValue);
                      }
                } else {
            handleChange(name)(inputValue);
            }
        };
      
        return (
          <OutlinedInput
            style={webStyle?.inputRoot}
            data-test-id="txtInputAssetname"
            placeholder={this.getStringAddCash(placeholderData)}
            fullWidth={true}
            name={name}
            type={type}
            value={value}
            onChange={handleInputChange}
            inputRef={nameInputField}
            onKeyDown={this.handleEnterKey}
            onClick={() => this.handleNameInputClick(name)}
          />
        );
      };
    // Customizable Area End
}
