import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import "../assets/css/popo.css";
import EmailAccountRegistrationsControllerweb, {
  Props,
} from "./EmailAccountRegistrationsController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-input": {
          borderBottom: '0px',
          width: '100%',
          color: "white",
        },
      },
    },
  },
});

// Customizable Area End
export class EmailAccountRegistrationBlockweb extends EmailAccountRegistrationsControllerweb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box className='mainWrapper makeCenter' style={{ scrollbarWidth: 'thin',
      scrollbarColor: '#00ebd9 transparent',
      height: '100vh', overflow: 'auto' }}>
        <Box style={{   paddingTop: '25px',justifyContent: 'center',display: 'flex',paddingBottom: '15px', }}>
          <Box className="borderCss">
            <Box className="verify-box-new">
              <ThemeProvider theme={theme}>
                <Box style={webStyle.logoandimg1} dir={this.state.languages}>
                  <img style={{ width: '200px', height: '92px', top: '42px' }} src={this.state.logoimg} />
                </Box>
                <CssBaseline />
                <Container >
                  <Box className="boxSpace" dir={this.state.languages}>
                    <Box>
                      <Typography style={webStyle.customLabel1}>{this.getString('signUp')}</Typography>
                      <Typography style={{ color: 'white', fontSize: '16px', fontWeight: 500, marginBottom: '15px', fontFamily: 'Poppins' }} variant="subtitle1" component="div">
                        {this.getString('labelHeader')}
                        {/*UI Engine::From Sketch*/}
                      </Typography>
                    </Box>
                    <Formik
                      initialValues={{ email: "", password: "", confirmPasswords: "" }}
                      onSubmit={(values: any) => this.SignUpHandle(values)}
                      validationSchema={this.codeSchema}
                      validateOnBlur={false}
                      validateOnChange={false}
                      data-test-id="accountLogin"
                    >
                      {({ errors, values, handleSubmit, handleChange }: any) => (
                        <form onSubmit={handleSubmit} data-test-id='submitForm'>
                          <Box className="inputFieldCss" >
                            <Box >
                              <InputLabel style={webStyle.customInputLabel1} htmlFor="standard-adornment-password" >{this.getString('emailAddress')}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={this.state.signupEmailError || errors.email}
                                  value={values.email}
                                  fullWidth={true}
                                  name='email'
                                  className="inputBox"
                                  onChange={handleChange('email')}
                                  placeholder={this.getString('emailPlaceholder')}
                                  data-test-id="txtInputEmail"
                                  style={webStyle.inputRoot1}
                                />
                                <Box sx={webStyle.fieldError1}>
                                  {this.finalErrorMessage(this.state.signupEmailError || errors.email)}
                                </Box>
                              </Box>
                            </Box>
                            <Box className="inputFieldCss">
                              <InputLabel style={webStyle.customInputLabel1} htmlFor="standard-adornment-password" >{localStorage.getItem('language') == "Arabic" ? this.state.passwordlableArabic : this.state.passwordLabel}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <div className={`custom-tooltips ${this.state.isHovered ? 'show' : 'hide'}`}>
                                  <OutlinedInput
                                    error={errors.password}
                                    onMouseEnter={this.handleMouseEnters}
                                    onMouseLeave={this.handleMouseEnters}
                                    type={this.state.enablePasswordField ? 'password' : 'text'}
                                    placeholder={this.getString('languagePasswordPlaceholder')}
                                    name="password"
                                    value={values.password}
                                    fullWidth={true}
                                    onChange={handleChange('password')}
                                    style={webStyle.inputRoot1}
                                    data-test-id="txtInputpassword"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={this.handleClickShowPassword}
                                          data-test-id="handleClickShowPassword"
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          {!this.state.enablePasswordField ? (
                                           <VisibilityOutlinedIcon style={{
                                            color: 'white',cursor: "pointer",
                                           opacity: 0.2
                                         }} />
                                          ) : (
                                            <VisibilityOffOutlinedIcon style={{
                                              color: 'white',cursor: "pointer",
                                             opacity: 0.2
                                           }} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                  <span className={localStorage.getItem('language') == "Arabic" ? "tooltip-textTransfrom" : "tooltip-text"} >
                                    <Box style={{ padding: '3px' }} >
                                      <Typography style={{ fontSize: '0.8rem' }}>{this.getString("languagePasswordRequirements")}</Typography>
                                      {this.colorCheck(values)}
                                    </Box>
                                  </span>
                                  <Box sx={webStyle.fieldError1}>
                                    {this.finalErrorMessage(errors.password)}
                                  </Box>
                                </div>
                              </Box>
                            </Box>
                            <Box className="inputFieldCss">
                              <InputLabel style={webStyle.customInputLabel1} htmlFor="standard-adornment-password" >{this.getString('confirmPassword')}</InputLabel>
                              <Box style={{ marginBottom: '16px' }}>
                                <OutlinedInput
                                  error={errors.confirmPasswords}
                                  type={this.state.enablePasswordFieldConfirm ? 'password' : 'text'}
                                  name="confirmPasswords"
                                  placeholder={this.getString('requirementsPlaceholder')}
                                  fullWidth={true}
                                  value={values.confirmPasswords}
                                  data-test-id="txtInputconfirmpassword"
                                  onChange={handleChange('confirmPasswords')}
                                  className="inputBox"
                                  style={webStyle.inputRoot1}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={this.handleClickShowPasswords}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                      >
                                        {!this.state.enablePasswordFieldConfirm ? (
                                         <VisibilityOutlinedIcon style={{
                                          color: 'white',cursor: "pointer",
                                         opacity: 0.2
                                       }} />
                                        ) : (
                                          <VisibilityOffOutlinedIcon style={{
                                            color: 'white',cursor: "pointer",
                                           opacity: 0.2
                                         }} />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <Box sx={webStyle.fieldError1}>
                                  {this.finalErrorMessage(errors.confirmPasswords)}
                                </Box>
                              </Box>
                            </Box>
                            <Box sx={webStyle.forget1}>
                              <Box sx={{ fontSize: "12px", color: "#FFFFFF",fontWeight: "bold", display: "flex",}}>
                                <label className="container" style={{ paddingLeft: '25px' }}>
                                  <input type="checkbox" onChange={this.handleCheckboxChange} data-test-id={"btnRememberMe"} />
                                  <span className="checkmarkOne"></span>
                                </label>
                                {" "}<span style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>{this.getString('termCondtions')}&nbsp;</span><a className="termAndConditioin" data-test-id={"btntermCondition"} onClick={this.termaConditions}> {this.getString('termCondtionsLink')}</a>
                              </Box>
                            </Box>
                            <Box>
                              <InputLabel style={{ textAlign: 'center', padding: '10px', fontSize: '14px', fontFamily: 'Poppins', fontWeight: 500, color: 'white',marginBottom:'15px' }} htmlFor="standard-adornment-password" >{this.getString('alreadyAccount')}  <a style={{ color: "#2F92FC", fontSize: '14px', fontFamily: 'Poppins', fontWeight: 700, cursor: "pointer" }} onClick={this.goToSignin} >{this.getString('singInAccount')}</a></InputLabel>
                            </Box>
                            <Box
                              style={{
                                alignItems: "center",
                                flexDirection: "column",
                                display: "flex",
                              }}
                            >
                              <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={!this.state.isChecked}
                                className={this.state.isChecked ? "customButton" : "disableCustomButton"}
                                data-test-id={"btnEmailLogIn"}
                              >
                                {this.getString('signUpBtn')} {/*UI Engine::From Sketch*/}
                              </Button>
                            </Box>
                        
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default EmailAccountRegistrationBlockweb
const webStyle = {
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "red !important",
    }
  },
  customLabel1: {
    fontWeight: 600,
    fontSize: '32px',
    fontFamily: "Poppins",
    fontStyle: 'normal',
    color: 'white',
  },
  customInputLabel1: {
    fontWeight: 500,
    marginBottom: '5px',
    fontSize: '14px',
    color: 'white ',
    fontFamily: 'Poppins'
  },
  inputRoot1: {
    outline: 'none',
    height: '50px',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& input::placeholder': {
      opacity: 0.5,
      color: 'white ',
      fontSize: '14px',
      fontWeight: 500,

    }, '& .MuiInputBase-input': {
      borderColor: 'white !important'
    },
  },
  cekbox: {
    '& .MuiCheckbox-root': {
      color: 'aqua',
    },
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  forget1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom:'30px',
  },
  logoandimg1: {
    paddingRight: '49px',
    paddingTop: '2px',
    top: '162px',
    alignItems: 'center',
    paddingLeft: '49px',
    justifyContent: 'end',
    display: 'flex',
  },
  fieldError1: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    color: "#EE5353",
  }
};
// Customizable Area End
