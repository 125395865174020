import React from "react";
// Customizable Area Start
import "../../apiintegrationbankaccounts/src/style.css"
import { image_Shape } from "../../apiintegrationbankaccounts/src/assets";
import { NavLink } from "react-router-dom";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";




// Customizable Area End



export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div className="mainBank-wrapper">
                <div className="bank-integraion-wrapper">
                    <div className="bank-integration-box d-flex align-center">

                      <div className="successful-img-content">
                        <img src={image_Shape} />
                        <p>{ !this.state.isArabic ? "Your password is reset successfully!" : "تم إعادة تعيين كلمة المرور بنجاح"}</p>
                        <NavLink to={this.getLoginLink()}> <button className="successBtn bankBtns" data-test-id="continueBtn">{ !this.state.isArabic ? "Back to Sign in":"العودة إلى تسجيل الدخول"}</button></NavLink>

                      </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
