Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.placeHolderNewPassword = "NewPassword";
exports.placeLable = "كلمه المرور"
exports.labelHeader = "Welcome Back!";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Sign in as customer";
exports.emailIsRequired = "Email id is a required field";
exports.pleaseEnterAValidPassword = "Enter your password.";
exports.pleaseEnterAValidEmailNew="Email must be a valid email"

exports.singupApiContentType = "application/json"
exports.submitPdfFormUrl = "login/login"
exports.exampleAPiMethod = "POST"
exports.LoginsubmitPdfFormUrl = "account_block/accounts"

exports.plannerLoginUrl = "bx_block_formapprovalworkflow/wealth_planners/planner_login"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.languageListLogin = {
  English: {
    signIn: 'Sign in as wealth planner',
    signInCustomer: 'Sign in as customer',
    labelHeader: 'Welcome Back!',
    emailAddressLogin: 'Email Address',
    emailAddressLoginPlaceholder: 'Enter your email address',
    enterPasswordLogin: 'Enter password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password?',
    anAccount: "Don't have an account?",
    signUpLogin: 'Sign up',
    signInButton: 'Sign in',
    orLogin: 'or',
    emailIsRequired: "Please do not leave the field blank",
    pleaseEnterAValidEmailNew: "Email must be a valid email",
    pleaseEnterAValidPassword: "Enter your password.",
    areYouWealthPlanner: "If you are a Wealth Planner, you can",
    areYouCustomer: "If you are a Customer, you can",
    loginHere:'Login here'
  },
  Arabic: {
    signIn: 'تسجيل الدخول',
    labelHeader: 'مرحباً بك مجددًا!',
    emailAddressLogin: 'عنوان البريد الإلكتروني',
    emailAddressLoginPlaceholder: 'أدخل عنوان بريدك الإلكتروني',
    enterPasswordLogin: 'أدخل كلمة المرور',
    rememberMe: 'تذكّرني',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    anAccount: 'ليس لديك حساب؟',
    signUpLogin: 'التسجيل',
    signInButton: 'تسجيل الدخول',
    orLogin: 'أو',
    emailIsRequired: 'إن البريد الإلكترونى حقل مطلوب',
    pleaseEnterAValidEmailNew: 'بريدك الإلكتروني غير صالح',
    pleaseEnterAValidPassword: "كلمة المرور الخاصة بك غير متطابقة",
    areYouWealthPlanner: "إذا كنت مخططًا للثروة، فيمكنك",
    areYouCustomer: "إذا كنت عميلاً، يمكنك",
    loginHere:'تسجيل الدخول هنا'
  }
}
// exports.logoimg = "assets/logo.png"
// Customizable Area End