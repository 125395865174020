// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import "../assets/border.css";
import EmailAccountLoginControllers, {
  Props,
} from "./EmailAccountLoginController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-input": {
          borderBottom:'0px',
          width: '100%',
          color: "white",
        }
      },
    },
  },
});
// Customizable Area End

export default class EmailAccountLogin extends EmailAccountLoginControllers {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box className='mainWrapper makeCenter' style={{ height: '100vh', scrollbarWidth: 'thin', scrollbarColor: '#00ebd9 transparent', overflowY: 'auto' }}>
      <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: '35px', paddingTop: '30px' }}>
       <Box className="borderCss">
            <Box className="verify-box-one">
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box style={webStyle.logoandimg1} dir={this.state.logoImg1Login}>
                  <img style={{  height: '92px', top: '162px',width: '200px' }}  src={this.state.logoimg} />
                </Box>
                <Container>
                  <Box className="boxSpace"  dir={this.state.languagesLogin}>
                    <Box>
                      <Box>
                        <Typography style={webStyle.customLabel1}>{this.getStringLogin('signIn')}</Typography>
                        <Typography style={{  fontWeight: 500, color: 'white',fontSize: '16px' ,fontFamily: 'Poppins', marginBottom: '25px'}} variant="subtitle1" component="div">
                          {this.getStringLogin('labelHeader')}
                          {/*UI Engine::From Sketch*/}
                        </Typography>
                      </Box>
                      <Formik
                        data-test-id="accountLogin"
                        initialValues={{ email: "", password: "" }}
                        validationSchema={this.codeSchema}
                        onSubmit={(values: any) => this.HandleLogin(values)}
                        validateOnChange={false}
                        validateOnBlur={false} 
                      >
                        {({ errors, values, handleSubmit, handleChange }: any) => (
                          <form onSubmit={handleSubmit} data-test-id='submitForm'>
                            <Box >
                              <Box >
                                <InputLabel style={webStyle.customInputLabel1} htmlFor="standard-adornment-password" >{this.getStringLogin('emailAddressLogin')}</InputLabel>
                                <Box style={{ marginBottom: '16px' }}>
                                  <OutlinedInput
                                    className="inputBox"
                                    style={webStyle.inputRoot}
                                    data-test-id="txtInputEmail"
                                    error={this.state.errorMsg.toLocaleLowerCase().includes('email')||errors.email}
                                    placeholder={this.getStringLogin('emailAddressLoginPlaceholder')}
                                    fullWidth={true}
                                    value={values.email}
                                    onChange={handleChange('email')}
                                  />
                                  <Box sx={webStyle.fieldError1}>
                                    {errors.email?errors.email.charAt(0).toUpperCase() + errors.email.slice(1) :null}{this.state.errorMsgValidate}
                                  </Box>
                                </Box>
                              </Box>
                              <Box >
                                <InputLabel style={webStyle.customInputLabel1} htmlFor="standard-adornment-password" >{this.state.placeLableLogin}</InputLabel>
                                <Box style={{ marginBottom: '16px' }}>
                                  <OutlinedInput
                                    className="inputBox"
                                    style={webStyle.inputRoot}
                                    data-test-id="txtInputEmail"
                                    error={this.state.errorMsg.toLocaleLowerCase().includes('password')||errors.password}
                                    type={this.state.enablePasswordField ? 'password' : 'text'}
                                    placeholder={this.getStringLogin('enterPasswordLogin')}
                                    onChange={handleChange('password')}
                                    value={values.password}
                                    fullWidth={true}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={this.handleClickShowPassword}
                                          edge="end"
                                        >
                                          {!this.state.enablePasswordField ? (
                                            <VisibilityOutlinedIcon style={{
                                              cursor: "pointer", color: 'white',
                                              opacity: 0.2
                                            }} />
                                          ) : (
                                            <VisibilityOffOutlinedIcon style={{
                                              cursor: "pointer", color: 'white',
                                              opacity: 0.2
                                            }} />
                                          )}

                                        </IconButton>
                                        
                                      </InputAdornment>
                                    }
                                  />
                                  <Box sx={webStyle.fieldError1}>
                                  {errors.password?errors.password.charAt(0).toUpperCase() + errors.password.slice(1):null}{ this.state.errorPassValidate}
                                  </Box>
                                </Box>
                              </Box>
                              <Box sx={webStyle.forget1}>
                                <Box sx={{ color: "#FFFFFF",fontSize: "14px", display:"flex" ,fontFamily: 'Poppins',fontWeight:500}}>
                                <label className="container" style={{ paddingLeft: '25px'}}>
                                  <input type="checkbox" data-test-id={"btnRememberMe"} onChange={this.rememberMe}/>
                                     <span className="checkmarkOne"></span>
                                      </label>
                                    {" "}<span style={{ fontFamily: 'Poppins',fontWeight:500,marginTop:"-3px"}}>{this.getStringLogin('rememberMe')}</span>
                                </Box>
                                <Box>
                                  <a style={{ fontSize: '12px', color: '#2F92FC',fontFamily: 'Poppins', cursor: "pointer",fontWeight:"bold" }} className="termAndConditioin"data-test-id="forgotePasswordPage" onClick={()=> this.forgotePasswordPage("planner")}>{this.getStringLogin('forgotPassword')}</a>
                                </Box>
                              </Box>

                              <Box>
                                <InputLabel style={{ color: 'white', textAlign: 'center', padding: '10px', fontSize: '14px' ,fontFamily: 'Poppins',fontWeight:500}}
                                 htmlFor="standard-adornment-password" >{this.getStringLogin('anAccount')}
                                  <a style={{ cursor: "pointer",color: "#2F92FC", fontSize: '14px',fontFamily: 'Poppins',fontWeight:700 , padding: '8px'}} 
                                onClick={this.gotToSignup} >{this.getStringLogin('signUpLogin')}</a></InputLabel>
                              </Box>
                              <Box>
                                <InputLabel style={{ color: 'white', textAlign: 'center', padding: '10px', fontSize: '14px' ,fontFamily: 'Poppins',fontWeight:500,marginBottom:'15px'}}
                                 htmlFor="standard-adornment-password" >{this.getStringLogin('areYouCustomer')}
                                  <span data-test-id="customer-login" style={{ cursor: "pointer",color: "#2F92FC", fontSize: '14px',fontFamily: 'Poppins',fontWeight:700 , padding: '8px'}} 
                                onClick={this.gotToCustomerLogin} >{this.getStringLogin('loginHere')}</span></InputLabel>
                              </Box>
                              
                              <Box>
                                <Button className="customButton" variant="contained" fullWidth type="submit">
                                  {this.getStringLogin('signInButton')} {/*UI Engine::From Sketch*/}
                                </Button>
                              </Box>
                           
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: "red !important",
    }
  },
  customLabel1: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },

  customInputLabel1: {
    color: 'white ',
    fontSize: '14px',
    marginBottom: '12px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  inputRoot: {
    borderRadius: '10px',
    height: '56px',
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
    },
    '& input::placeholder': {
      color: 'white ',
      fontSize: '14px',
      opacity: 0.5,
      fontWeight: 500,

    }, '& .MuiInputBase-input': {
      borderColor: 'white !important'
    },
  },
  cekbox: {
    '& .MuiCheckbox-root': {
      color: 'aqua',
    },
    '& .MuiIconButton-root': {
      padding: '0px'
    }
  },
  forget1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom:'30px',
  },
  buttons: {
    fontSize: '20px',
    textTransform: 'none',
    fontWeight: 700,
    height: "50px",
    Color: '#3C3C50',
    margin: '0px',
    borderRadius: '10px',
    backgroundColor: "#c7f9f6",
  },
  logoandimg1: {
    paddingRight: '49px',
    paddingTop: '26px',
    alignItems: 'center',
    justifyContent: 'end',
    paddingLeft:'49px',
    display: 'flex',
  },
  fieldError1: {
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    fontFamily: 'Poppins',
    color: "#EE5353",
  }
};
// Customizable Area End
// Customizable Area End
