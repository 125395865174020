import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Divider,
  Dialog,
  OutlinedInput,
  Button,
  DialogContent,
  InputAdornment,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import UserProfile from "../../../components/src/UserProfile.web";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import Select from "react-select";
import { convertMinutesToHoursAndMinutes } from "../../../components/src/commonFn";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    },
  },
});

theme = createTheme(theme, {
  overrides: {
    MuiSvgIcon: {
      root: {
        color: '#94989c',
      },
    },
    MuiOutlinedInput: {
      root: {
        "& .MuiOutlinedInput-input": {
          color: "white",
          borderBottom: '0px',
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
        },
        "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "& $notchedOutline": {
          borderColor: "#94989c",
        },
        "& .MuiAutocomplete-endAdornment": {
          position: 'unset'
        }
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        width: 'max-content',
      },
    },
    MuiTypography: {
      root: {
        "&.duration": {
          fontWeight: 600,
          fontSize: "16px",
          color: "#fff",
          fontFamily: "Poppins",
        },
        "&.currency": {
          fontSize: "24px",
          color: "#2de2d5",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        "&.benifit": {
          fontSize: "16px",
          color: "#fff",
          fontFamily: "Poppins",
          width: "calc(100% - 28px)",
          wordWrap: "break-word",
        },
        "&.addBenifit": {
          fontSize: "20px",
          color: "#fff",
          fontWeight: 600,
          marginLeft:"12px",
          fontFamily: "Poppins",
        },

        
      }
    },
  },
});
import { AddIcon, ArrowRightIcon, deleteSlot, DisableSwitchIcon, EditIcon, EnableSwitchIcon, image_Imagenav_backicon, SuccessCard } from "./assets";

// Customizable Area End

import EditPlanController, {
  Props,
  configJSON,
} from "./EditPlanController";

export default class EditPlan extends EditPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderPlanInfo = (plan: {
    id: string
    type: string
    attributes:  {
      id: number
      name: string
      duration: number
      currency: string
      amount: number
      enabled: boolean
      benefits: string[]
      tier: number
    }
  },index:number) => {
    return (
      <Box style={webStyle.planContainer} data-test-id="plan">
        <span title={plan.attributes.name ? plan.attributes.name :"Enter plan name"} style={{...webStyle.nameContainer,background: this.getBackground(index)}}>
          {plan.attributes.name ? plan.attributes.name :"Enter plan name"}

        </span>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Typography className="duration">
            {plan.attributes.duration ? convertMinutesToHoursAndMinutes(plan.attributes.duration) :"Enter time limit" }    
            </Typography>
            <Typography className="currency">
            {this.getCurrencySymbol(plan.attributes.currency) } {plan.attributes.amount} 
            </Typography>
          </Grid>
          <Grid item style={{   marginLeft:"auto" }}>
            <Box style={{  display:"flex",alignItems:"center",paddingTop:plan.attributes.enabled ? "8px":"0px"   }}>
              <img data-test-id="edit-plan-button" style={webStyle.editPlan} src={EditIcon} onClick={()=> { this.handleOpenEditDialog(plan) }}/>
              <img data-test-id="disable-plan-button" style={plan.attributes.enabled ? webStyle.togglePlan :{...webStyle.togglePlan,marginTop:"8px"} }  src={plan.attributes.enabled ? EnableSwitchIcon :DisableSwitchIcon}   onClick={()=> { this.handleConfirmChangePlan(plan.attributes.enabled,plan.attributes.id) }}  />
            </Box>
          </Grid>
        </Grid>
        <Box style={webStyle.benifitTitleContainer}>
          <Divider style={webStyle.divider} />
          <Typography className="benifit" >  Benefits of Plan </Typography>
          <Divider style={webStyle.divider} />
        </Box>
        {
          plan.attributes.benefits.map((benifit)=>{
            return (
             <Box style={{...webStyle.benifitContainer, width:'100%' }}>
              <img src={ArrowRightIcon} style={webStyle.arrowRight} />
             
              {benifit?  
                    <Typography className="benifit" style={{ whiteSpace: 'break-spaces', wordWrap:"break-word" }}> {benifit} </Typography>
              : <div style={webStyle.noExplanationTextContainer}>
                    <Typography style={webStyle.noExplanationText}> Write a description about your plan   </Typography>
                  </div>}
             
              </Box>
            )
          })
        }
      </Box>
    )
  }

  renderEditPlan = () => {
    return (
      <Dialog
        open={this.state.openPlanModal}
        keepMounted
        data-test-id="edit-plan-id"
        aria-labelledby="plan-edit"
        aria-describedby="plan-edit"
        PaperProps={{ style: webStyle.editDialogStyle }}
      > <Formik
        initialValues={this.state.initialValues}
        enableReinitialize
        validationSchema={this.validationSchema}
        onSubmit={(values) => {
          this.handleSubmit(values)
        }}
        data-test-id="editPlanForm"
      >
          {({ values, handleChange, setFieldValue, }) => (
            <Form translate={"en"}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <OutlinedInput
                      data-test-id="txtInputEmail"
                      className="inputBox"
                      fullWidth={true}
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Plan Name"
                      name="name"
                      style={webStyle.inputRoot}
                    />
                    <Box sx={webStyle.fieldError}>
                      <ErrorMessage name="name" />
                    </Box>
                  </Grid>
                  <Grid item md={2} />
                  <Grid item md={3} style={{
                    marginRight: "22px"
                  }} >
                    <OutlinedInput
                      data-test-id="textInputduration"
                      className="inputBox"
                      fullWidth={true}
                      value={values.duration}
                      onChange={handleChange}
                      name="duration"
                      style={webStyle.inputRoot}
                      type="number"
                      endAdornment={<InputAdornment position="end"  >
                        <Select options={this.durationOptions}
                          value={{ value: values.durationUnit, label: values.durationUnit }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              width: "100px",
                            }),
                          }}
                          data-test-id="durationUnit"
                          isSearchable={false}
                          components={this.arrowComponent}
                          onChange={(value) => { value?.value && setFieldValue("durationUnit", value.value) }}
                          classNamePrefix="an-simple-selectUSD"  />
                      </InputAdornment>}
                    />
                    <Box sx={webStyle.fieldError}>
                      <ErrorMessage name="duration" />
                    </Box>
                  </Grid>
                  <Grid item md={3} style={{
                    marginRight: "22px"
                  }}>
                    <OutlinedInput
                      data-test-id="currencyMenu"
                      className="currencyMenu"
                      fullWidth={true}
                      value={values.price}
                      onChange={handleChange}
                      name="price"
                      style={webStyle.inputRoot}
                      type="number"
                      endAdornment={<InputAdornment position="end"  >
                        <Select options={this.currencyOptions}
                          value={{ value: "AED", label: "AED" }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              width: "50px",
                            }),
                          }}
                          isSearchable={false}
                          data-test-id="selectMenu"
                          isDisabled
                          components={this.arrowComponent}
                          classNamePrefix="an-simple-selectUSD"  />
                      </InputAdornment>}
                    />
                    <Box sx={webStyle.fieldError}>
                      <ErrorMessage name="price" />
                    </Box>
                  </Grid>

                </Grid>
                <Box style={webStyle.benifitTitleContainerEdit}>
                  <Divider style={webStyle.divider} />
                  <Typography className="benifit">Benefits of Plan</Typography>
                  <Divider style={webStyle.divider} />
                </Box>
                <FieldArray name="benefits" data-test-id="fieldArray">
                  {({ push, remove }) => (
                    <div>
                      {values.benefits.map((_benefit, index) => (
                        <Box>
                          <Grid key={index} container style={{
                            marginBottom: "14px"
                          }}>
                            <Grid item md={1}>
                              <img src={ArrowRightIcon} style={webStyle.arrowRightEdit} alt="Arrow Right" />

                            </Grid>
                            <Grid item md={10}>
                              <OutlinedInput
                                data-test-id="txtInputEmail"
                                className="inputBox"
                                fullWidth={true}
                                name={`benefits[${index}]`}
                                value={`${values.benefits[index]}`}
                                onChange={handleChange}
                                maxRows={4}
                                rows={4}
                                multiline
                                style={webStyle.inputRoot1}
                              />
                              <Box sx={webStyle.fieldError}>
                                <ErrorMessage name={`benefits[${index}]`} />
                              </Box>
                            </Grid>
                            <Grid item md={1}>
                              {
                                index > 0 && <div data-test-id="removeBtn" style={{
                                  marginLeft: "12px",
                                  cursor:"pointer"
                                }} onClick={() => remove(index)}>
                                  <img src={deleteSlot} style={webStyle.deleteIcon} />
                                </div>
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                      <Grid container style={{
                        marginBottom: "14px"
                      }}>
                        <Grid item md={1}></Grid>
                        <Grid item md={10}>
                          <Box style={{display:"flex",alignItems:"center"}}>
                            <img src={AddIcon} style={{
                            cursor:"pointer"
                            }} onClick={() => push('')} />
                            <Typography className="addBenifit">
                            Add Benefit
                            </Typography>
                            
                          </Box>
                        </Grid>
                      </Grid>
                    
                    </div>
                  )}
                </FieldArray>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2rem", gridGap: "20px" }}>
                  <Button type="button" style={{ ...webStyle.cancelBtn, width: "270px" }} data-test-id="close-editplan" onClick={() => {
                    this.cancelSubmit()
                  }} >Cancel</Button>
                  <Button style={{ ...webStyle.saveBtn, width: "270px" }} data-test-id="formSubmit" type="submit" >Save</Button>
                </Box>
              </DialogContent>

            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }

  renderSuccessDialog = () => {
    return (
      <Dialog
        open={this.state.openSuccessModal}
        keepMounted
        data-test-id="dialogModel"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: this.dialogSuccessPaperStyle }}
        onClose={this.closeModal}
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
        <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
          <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
            textAlign: "center"
          }}>
            Your plan is successfully saved!
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }

  renderPlanUnavailableDialog = () =>{
    return (
      <Dialog
      open={this.state.openDisablePlanModel}
      keepMounted
      data-test-id="dialogModel"
      onClose={this.closeDisablePlanModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: this.dialogPaperStyle }}
    >
      <DialogTitle id="alert-dialog-slide-title" style={webStyle.dialogTitle}>
        <span style={webStyle.dialogTitleHeader}>Are you sure you want to make this plan {this.state.currentStatus? "unavailable" : "available"}?</span></DialogTitle>
      <DialogContent style={webStyle.dialogContent}>
        <DialogContentText id="alert-dialog-slide-description"
          style={webStyle.dialogContainer}>
          <div className="modal-button-container">
            <div className="modal-button modal-discard" style={{
              borderRadius:"7px",
              height:"40px",
              marginRight:"3rem"
            }} data-test-id='cancelDisable' onClick={() => this.closeDisablePlanModal()}>
              Cancel
            </div>
            <div style={{
              borderRadius:"7px",
              height:"40px",
              color:"#c9f9f6",
              border:"1px solid #c9f9f6"
            }}   className="modal-button modal-save" data-test-id='yesDisable' onClick={()=>{this.handleChangePlanStatus()}} >
              Yes
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
    )
  }


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
         <div className="pagedashboard-wrapper2" style={{paddingTop: "10px"}}>
        <Grid container style={{
          flexWrap: "wrap",
          background: "#2a2a39"
        }} >
          <Grid item md={12} style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
            <Box style={webStyle.profileNameNew}>
              <Box onClick={() => this.goToBackPage()} data-test-id="backButton" ><img src={image_Imagenav_backicon} style={webStyle.marginImg1} alt="back" /></Box>
              <Box style={webStyle.planning}> Edit Consultation Plans</Box>
            </Box>
            <UserProfile username={this.state.plannerInfo.name} email={this.state.plannerInfo.email} profilePicture={this.state.plannerInfo.image} />
          </Grid>
          <Grid item md={12} style={{ width: "100%" }}>
            <Grid container spacing={8} style={webStyle.mainContainer}>
              {
                this.state.customPlans.map((customPlan,index) => {
                  return (
                  <Grid item sm={12} md={4}  style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                    {this.renderPlanInfo(customPlan,index)}
                  </Grid>
                  )
                })
              }
            </Grid>
          </Grid>
        </Grid>
        </div>
        {this.renderEditPlan()}
        {this.renderSuccessDialog()}
        {this.renderPlanUnavailableDialog()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle= {
    boxContainer:{ backgroundColor: "#3c3c50", marginBottom:"50px",  padding:"32px",marginTop:"18px", paddingTop:"4px" },
    noServiceTxt: { fontSize: "14px", color: "#c4c4c4", fontFamily: 'Poppins', whiteSpace: "break-spaces" },
    titleText: {fontSize: "24px",color: "#fff",fontFamily: "Poppins",marginTop:"18px"},
    heading:{fontSize: "18px",color: "#fff",fontFamily: "Poppins",fontWeight:500,marginTop:"12px"},
    successMessage: {fontSize: "20px",color: "#fff",fontFamily: "Poppins"},
    dialogStyle: {width: 'max-content',backgroundColor: '#395D6B',borderRadius: '8px',boxShadow: 'none',paddingTop:"16px",paddingBottom:"16px"},
    saveBtn: { marginLeft:"24px",color: "#3c3c50", background: "#c9f9f6", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" },
    btnContainer: {display:"flex",justifyContent:"end", textAlign: "center", marginTop: "18px",fontFamily: "Poppins" },
    cancelBtnRed: { color: "#ee5353", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #ee5353", borderRadius: "8px",padding:"10px" } ,
    profileNameNew: { display: 'flex', alignItems: 'center' },
    marginImg1: { margin: '0px', cursor: "pointer", zIndex: 10 },
    planning: { fontSize: "22px", color: "fff", fontFamily: 'Poppins', fontWeight: 500 ,marginLeft:"18px"},
    planContainer: {background: "#395d6b",borderRadius: "14px", padding:"20px", maxWidth:"420px",   paddingTop:"26px",minHeight:600,flex:1,position:"relative"},
    cancelBtn: { color: "#c9f9f6", width:"180px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" } ,
    benifitTitleContainer: { display: "flex", alignItems: "center", marginBottom: "16px",marginTop:"16px" },
    benifitTitleContainerEdit: { display: "flex", alignItems: "center", marginBottom: "16px",marginTop:"22px",opacity:0.5 },
    divider: { flexGrow: 1, height: "1px", backgroundColor: "#e1e6ec", margin: "0px 10px" },
    arrowRight:{ borderRadius:"0",marginRight:"12px",height:"16px",width:"16px",marginTop:"8px"},
    arrowRightEdit:{ borderRadius:"0",marginRight:"12px",height:"20px",width:"20px"},
    deleteIcon:{ borderRadius:"0",marginRight:"12px",height:"20px",width:"20px"},
    benifitContainer:{ display: 'flex', alignItems: 'flex-start',marginBottom:"14px" },
    inputRoot1: { borderRadius: '10px', minHeight: '120px', fontFamily: 'Poppins'},
    inputRoot: {borderRadius: '8px', height: '48px',outline: 'none', fontFamily: 'Poppins', width:"160px", fontSize:"20px",fontWeight: 500},
    editDialogStyle: {width: "650px",maxWidth: "650px", backgroundColor: '#3c3c50',borderRadius: '8px',boxShadow: 'none',paddingTop:"16px",paddingBottom:"16px"},
    dialogTitle: { padding: '0px 65px', marginTop: "3rem" },
    dialogTitleHeader: { color: '#fff', textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins', fontWeight: 600 },
    dialogContent: { padding: '0px!important', marginBottom: '0px', paddingTop: '15px' },
    dialogContainer: { color: 'rgb(255, 255, 255) !important', fontSize: '20px !important', fontFamily: 'Poppins !important', fontWeight: 500, marginTop: '18px', marginBottom: "2rem" },
    noExplanationText: { fontSize: "16px", fontFamily: 'Poppins', color: "#808d9e" },
    noExplanationTextContainer: {
      border: "1px solid", width:"100%",borderColor: "#808d9e", borderRadius: "4px", paddingTop: "18px", paddingLeft: "12px", minHeight: "120px", marginBottom: "12px"
    },
    fieldError:{ color: "#EE5353",  fontFamily: 'Poppins', fontStyle: 'normal',   fontWeight: 400, fontSize: '12px',lineHeight: '18px',},
    nameContainer:{ position:"absolute",  left:"50%",transform: "translate(-50%, 0)", width:"160px", borderRadius:"15px", textAlign:"center",top:"-16px", color:"#fff",
    fontWeight:600,fontSize:"16px",  fontFamily:"Poppins",    padding:"1px 12px",height:"32px",whiteSpace:"nowrap",overflow:"hidden", textOverflow:"ellipsis"
    },
    mainContainer:{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',marginTop:"2rem" },
    editPlan:{cursor:"pointer"},
    togglePlan:{cursor:"pointer",marginLeft:"12px"}
    
}
// Customizable Area End