// Customizable Area Start
import React from "react";

import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core";
import { SuccessCard ,notAvailable} from "./assets"
import FormApprovalWorkflowController, {
  Props
} from "./FormApprovalWorkflowController.web";
import ServiceDetail from "../../../components/src/ServiceDetail"



export default class EstatePlanningDashboardWeb extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }





  render() {
    return (
      <div className="pagedashboard-wrapper">
        <div>
          <Grid container spacing={4} >
            <Grid item >
              <Typography style={this.getTabDesign("ongoing")} data-test-id="ongoing-tab" onClick={() => this.handleTabChange("ongoing")}> Ongoing </Typography>
            </Grid>
            <Grid item >
              <Typography style={this.getTabDesign("completed")} data-test-id="completed-tab" onClick={() => this.handleTabChange("completed")}> Completed </Typography>
            </Grid>
          </Grid>
          {
            this.state.completedServices.length > 0 && this.state.activeTab === "completed" &&
            <Grid container spacing={4} style={{ marginTop: "2rem",marginLeft:"1rem" }}>
              {
                this.state.completedServices.map((service) => {
                  return (
                    <>
                      <Grid item md={4} sm={12} style={styles.serviceContainer}>
                      <ServiceDetail  service={service}/>
                      </Grid>
                      <Grid item md={2} sm={12} key={service.service_id}></Grid>
                    </>
                   
                  )
                })
              }
            </Grid>
          }
           {
            this.state.onGoingServices.length > 0 && this.state.activeTab === "ongoing" &&
            <Grid container spacing={4} style={{ marginTop: "2rem",marginLeft:"1rem"}}>
              {
                this.state.onGoingServices.map((service) => {
                  return (
                   <>
                  <Grid item md={4} sm={12} style={styles.serviceContainer}>
                      <ServiceDetail  service={service}/>
                      </Grid>
                    <Grid item md={2} sm={12} key={service.service_id}></Grid>
                   </>
                  )
                })
              }
            </Grid>
          }
          {
            this.state.completedServices.length === 0 && this.state.activeTab === "completed" &&
            <Box style={{
              display:"flex",
              alignSelf:"center",
              justifyContent:"center",
              height:"80%",
              alignItems:"center"
            }}>
              <Box>
                <img src={notAvailable} alt="">
                </img>
                  <Typography style={{
                  color:"#fff",
                  textAlign:"center",
                  marginTop:"2rem",
                  fontSize:"18px",
                  fontWeight:600,
                  fontFamily:"Poppins"
                }}>
                     No completed services to show!
                  </Typography>
              </Box>
            </Box>
          }

          {
            this.state.onGoingServices.length === 0 && this.state.activeTab === "ongoing" &&
            <Box style={{
              display:"flex",
              alignSelf:"center",
              justifyContent:"center",
              height:"80%",
              alignItems:"center"
            }}>
              <Box>
              <img src={notAvailable} alt="" height={240} width={240}>
                </img>
                <Typography style={{
                  color:"#fff",
                  textAlign:"center",
                  marginTop:"2rem",
                  fontSize:"18px",
                  fontWeight:600,
                  fontFamily:"Poppins"
                }}>
                No ongoing services to show!
                </Typography>
              </Box>
            </Box>
          }
           
        </div>
        {
          this.state.activeTab === "completed" && 
          <>
          <Dialog
          open={this.state.openAccessDialog}
          keepMounted
          data-test-id="dialogModel"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: styles.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
          <DialogContent style={{ padding: '8px 20px' }}>
            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
              textAlign: "center"
            }}>
              <Typography style={styles.successMessage}>
                Your service has ended!
              </Typography >
              <Typography style={styles.successMessage}>
                Would you like to keep access to planner for your assets or close it?
              </Typography>
              <Grid container style={styles.btnContainer}>
                <Grid item md={12} >
                  <Button style={styles.cancelBtn} data-test-id="keep-access" onClick={()=>this.handleAccess("true")}>
                    Keep Access
                  </Button>
                </Grid>
                <Grid item md={12} style={{ marginTop: "14px" }}  data-test-id="dont-keep-access" onClick={()=>this.handleAccess("false")}>
                  <Button style={styles.cancelBtn} >
                    Close Access
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
          <Dialog
          open={this.state.openReviewDialog}
          keepMounted
          data-test-id="dialogModel"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: styles.dialogStyle }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
          <DialogContent style={{ padding: '8px 20px' }}>
            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
              textAlign: "center"
            }}>
              <Typography style={styles.successMessage}>
                Your response has been recorded.
              </Typography >
              <Typography style={styles.successMessage}>
                Would you like to give a review?
              </Typography>
              <Grid container style={styles.btnContainer}>
                <Grid item md={12} >
                  <Button style={styles.cancelBtn} data-test-id="submit-review" onClick={()=> this.handleWriteAReview({
                     currentPlannerId: this.state.currentPlannerId,
                     currentPlannerImage: this.state.currentPlannerImage,
                     currentPlannerName: this.state.currentPlannerName
                  })} >
                    Write a review
                  </Button>
                </Grid>
                <Grid item md={12} style={{ marginTop: "14px" }} >
                  <Button style={styles.saveBtn} data-test-id="cancel-review" onClick={()=> this.handleCancelReview()}>
                    Maybe Later
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        </>
        }
      </div>
    );
  }
}
type IStyle = { [key: string]: React.CSSProperties }


const styles: IStyle = {
  dialogStyle: {width: 'max-content',backgroundColor: '#395D6B',borderRadius: '8px',boxShadow: 'none',paddingTop:"16px",paddingBottom:"16px"},
  successMessage: {fontSize: "20px",color: "#fff",fontFamily: "Poppins"},
  saveBtn: { color: "#3c3c50", background: "#c9f9f6", width: "100%", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" },
  btnContainer: { justifyContent: "center", textAlign: "center", marginTop: "18px",fontFamily: "Poppins" },
  cancelBtn: { color: "#c9f9f6", width: "100%", fontFamily: "Poppins", fontSize: "20px", fontWeight: 600, textTransform: "none", border: "1px solid #c9f9f6", borderRadius: "8px",padding:"10px" },
  serviceContainer: {
    border: "1px solid #8f99a3",
    padding: "16px",
    fontFamily: "Poppins",
    borderRadius: "12px", marginTop: "2rem"
  }
};

// Customizable Area End
