import React from "react";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, logoimg, ContinueGoogle, ContinueApple, AppleimgLogin, GoogleimgLogin } from "./assets";
import * as Yup from "yup";
import 'firebase/auth';
import { apiCall } from "../../../components/src/Common";
import { signInWithPopup } from "@firebase/auth";
import { auth, provider } from "./FirebaseConfig"
import { setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  labelOr: string;
  logoimg: string;
  ContinueGoogle: string;
  ContinueApple: string;
  formSubmited: boolean,
  loginformSubmited: boolean,
  clientId: string,
  errorMsg: string,
  menual: boolean,
  loginMenual: boolean,
  rememberMe: boolean,
  languagesLogin: string,
  logoImg1Login: string,
  placeLable:string,
  placeLableLogin:string,
  emailErrorMsg:string,
  errorMsgValidate:string,
  errorPassValidate: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserPDFDataCallId: any;
  postUserPDFData1CallId: any;
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  userRegistrationApiCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GoogleLogin),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      logoImg1Login: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      languagesLogin: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      placeLableLogin: localStorage.getItem('language') !== "Arabic" ? configJSON.placeHolderPassword :configJSON.placeLable,
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: "",
      placeLable:configJSON.placeLble,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      labelOr: configJSON.labelOr,
      logoimg: logoimg,
      ContinueGoogle: localStorage.getItem('language') !== "Arabic" ? ContinueGoogle : GoogleimgLogin,
      ContinueApple: localStorage.getItem('language') !== "Arabic" ? ContinueApple : AppleimgLogin,
      formSubmited: false,
      loginformSubmited: false,
      clientId: "954432432614-6r9nb8cjp1gep9631s6akfq61aa2n87f.apps.googleusercontent.com",
      errorMsg: '',
      menual: false,
      loginMenual: false,
      rememberMe: false,
      emailErrorMsg:'',
      errorMsgValidate:'',
      errorPassValidate:''

    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const rememberMe = localStorage.getItem("rememberMe")
    const token = localStorage.getItem("token")
    if (rememberMe == "true" && token) {
      this.props.navigation.navigate('Dashboard')
    }
    // Customizable Area End
  }
  getStringLogin = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListLogin[languageLogin][key]
  }
  // Customizable Area Start
  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  HandleSubmit = async (values: any) => {
    this.setState({ formSubmited: true, menual: true });
    const header = {
      "Content-Type": configJSON.singupApiContentType
    };
    const httpBody = {
      data: {
        attributes: {
          email: values.email,
          password: values.password,
          device_id: ""
        },
        type: "email_account"
      }
    };
    let userDetailurl = configJSON.submitPdfFormUrl;
    const postPdfData = apiCall({
      httpBody: httpBody,
      header: header,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  HandleLogin = async (values: any) => {
    this.setState({ loginformSubmited: true, loginMenual: true });
    const header = {
      "Content-Type": configJSON.singupApiContentType
    };
    const httpBody = {
      data: {
          email: values.email,
          password: values.password,
          device_id: ""
      }
    };
    let userDetailurl = configJSON.plannerLoginUrl;
    const postPdfData1 = apiCall({
      httpBody: httpBody,
      header: header,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFData1CallId = postPdfData1.messageId;
    runEngine.sendMessage(postPdfData1.id, postPdfData1);
  }
  goToPrivacyPolicy = () => {
    this.props.navigation.navigate('EmailAccountRegistrationBlock')
  }

  gotToSignup = () => {
    this.props.navigation.navigate('SignupAccount')
  }

  gotToAdminLogin = () => {
    this.props.navigation.navigate('Loginplanner')
  }
  gotToCustomerLogin = () => {
    this.props.navigation.navigate('EmailAccountLogin')
  }

  forgotePasswordPage = (role:string) => {
    setStorageData("role",role);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  login = () => {
    this.setState({ menual: false });
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithPopup(auth, provider).then((data) => {
      if (data.user) {
        this.responseGoogle(data.user)
      }
    })
  };

  responseGoogle = (props: any) => {
    this.setState({ formSubmited: true });
    const header = { "Content-Type": configJSON.singupApiContentType };
    const httpBody = {
      data: {
        type: "google_account",
        attributes: {
          email: props.email,
          unique_auth_id: props.accessToken,
          activated: true,
          terms_and_conditions: true,
          device_id: ""
        },
      }
    };
    let userDetailurl = configJSON.LoginsubmitPdfFormUrl;
    const postPdfData = apiCall({
      httpBody: httpBody,
      header: header,
      url: userDetailurl,
      httpMethod: configJSON.exampleAPiMethod,
    });
    this.postUserPDFDataCallId = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
  }

  rememberMe = () => {
    this.setState({ rememberMe: !this.state.rememberMe })
  }
  // Customizable Area End

  navigation = (responseJson: any) => {
    const { meta } = responseJson;
    if (meta.token) {
      if (!meta.question_answered) {
        this.props.navigation.navigate('Questionbank')
        return
      }
      if (meta.subscription?.status !== 'inactive') {
        if (!moment().isAfter(moment.utc(meta.subscription?.expiration_date))) {
          if (meta.bank_connected) {
                  window.localStorage.setItem('authToken', meta.token);
            setTimeout(() => {
                 document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
            }, 200)
          } else {
                  this.props.navigation.navigate('Apiintegrationbankaccounts')
            }
        } else {
             this.props.navigation.navigate('ChangePlan')
           }
      } else {
            this.props.navigation.navigate('Freetrial')
          }
    }
  }


  newChange = (responseJson: any) => {
    if (responseJson?.meta?.token) {
      window.localStorage.setItem('token', responseJson.meta.token);
      window.localStorage.setItem('EngagementRequestId', responseJson.meta.id);
    }
    if (this.state.rememberMe) {
      window.localStorage.setItem('rememberMe', 'true');
    }
    if (responseJson?.errors) {
      this.setState({ errorMsg: '' })
      if(responseJson.errors[0].failed_login.includes('email')){
        this.setState({ emailErrorMsg: this.state.logoImg1Login == "rtl" ? "عنوان بريدك الإلكتروني غير صالح!":  responseJson.errors[0].failed_login  })
      }else if(responseJson.errors[0].failed_login.includes('password')){
        this.setState({ errorMsg: this.state.logoImg1Login == "rtl" ? "كلمة المرور الخاصة بك غير متطابقة! ":  responseJson.errors[0].failed_login  })
      }
    } else {
      this.navigation(responseJson)
    }
  }

  loginMsg = (responseJson: any) => {

    if (responseJson?.message === "logged in") {
      window.localStorage.setItem('auhtToken', responseJson.token);
       setStorageData("planerId",responseJson.id)
      this.props.navigation.navigate('Homepage');
    }
    if (responseJson?.token) {
      window.localStorage.setItem('auhtToken', responseJson.token);
    }
    if (this.state.rememberMe) {
      window.localStorage.setItem('rememberMe', 'true');
    }
    if (responseJson?.errors) {
      this.setState({ emailErrorMsg:'',errorMsg:''})
    } else {
      this.navigation(responseJson)
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFDataCallId !== null &&
      this.postUserPDFDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) || (getName(MessageEnum.GoogleLogin) === message.id &&
        this.postUserPDFDataCallId !== null &&
        this.postUserPDFDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.newChange(responseJson)
      setStorageData('subscription', JSON.stringify(responseJson.meta.subscription));
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUserPDFData1CallId !== null &&
      this.postUserPDFData1CallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
        this.postUserPDFData1CallId !== null &&
        this.postUserPDFData1CallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson2?.errors?.email) {
        if (responseJson2?.errors?.email === 'You are not authorized by admin') {
          this.setState({ errorMsgValidate: 'You are not authorized by admin' })
        } else {
          this.setState({ errorMsgValidate: 'Your email address is not valid' })
        }
        return
      }
      if (responseJson2?.errors?.password === "Your password doesn't match") {
        this.setState({ errorPassValidate
          : "Your password doesn't match"})
        return
      }
      this.loginMsg(responseJson2)
    }
    // Customizable Area End
  }

  //code schema
  // Customizable Area Star
  codeSchema = Yup.object().shape({
    email: Yup.string()
      .email(this.getStringLogin('pleaseEnterAValidEmailNew'))
      .required(this.getStringLogin('emailIsRequired')),
    password: Yup.string()
      .required(this.getStringLogin('pleaseEnterAValidPassword')),
  });
  // Customizable Area End
}