import React from "react";

//Customizable Area Start

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { logo, backIcon } from "./assets";
import "./style.css";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {

    return (
      <div className="main-wrapper">
        {!this.state.isArabic ?
          <div className="forgotPassword-wrapper">
            <div className="forgotPassword-box">
              <div className="logo-right">
                <a href="#"><img src={logo} alt="logo" /></a>
              </div>
              <div className="forgotPassword-block">
                <span onClick={() => this.handleGoBack()}><h4><img src={backIcon} alt="back-icon" />Forgot Password</h4></span>
                <p>Enter the email address associated with your<br />account and we will send an OTP for verification</p>
                <div className="forgotPassword-code">
                  Email Address
                  <br />
                  <input type="email" className="forgotPassword-input" disabled={this.state.isLoggedIn} data-test-id="emailField" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                  <button className="verifyForgotPassword" onClick={this.onEmailSubmit} data-test-id="verifyBtn">Continue</button>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="forgotPassword-wrapper arbic-wrapper">
            <div className="forgotPassword-box">
              <div className="logo-right">
                <a href="#"><img src={logo} alt="logo" /></a>
              </div>
              <div className="forgotPassword-block">
                <span onClick={() => this.handleGoBack()}><h4>هل نسيت كلمة السر<img src={backIcon} alt="back-icon" /></h4></span>
                <p>أدخل عنوان البريد الإلكتروني المرتبط بحسابك<br />الحساب وسوف نرسل OTP للتحقق</p>
                <div className="forgotPassword-code">
                  عنوان البريد الإلكتروني
                  <br />
                  <input type="email" className="forgotPassword-input" disabled={this.state.isLoggedIn} data-test-id="emailFieldArabic" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                  <button className="verifyForgotPassword" onClick={this.onEmailSubmit} data-test-id="verifyBtnArabic">يكمل</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

// Customizable Area Start

