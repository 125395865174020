import React from "react";

// Customizable Area Start
import { styled } from '@material-ui/styles';
import {
    Box,
    Button,
    Grid,
    Divider,
    Typography
} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { group_Exit } from "../src/assets"
import ClearIcon from '@material-ui/icons/Clear';
import SyncIcon from '@material-ui/icons/Sync';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './SubscriptionCard.css';
// Customizable Area End

import ManageSubscriptionController, {
    Props,
} from "./ManageSubscriptionController";

export default class ManageSubscription extends ManageSubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    dataBotton = () => {
        if (this.state.subscription == "canceled") return
        return this.state.subscriptionData?.cancle_status === "true" ? null : <ButtonClear data-test-id="closeButton" onClick={this.closeButton}><ClearIcon style={webStyle.cancelButton} /> {this.getStringSubscription("CancelSubscription")}</ButtonClear>
    }
    InvestButtonTrial = () => {
        return this.state.subscription === "trial" ? null : <InvestButton data-test-id="changeSubscription" onClick={this.changeSubscription}>{this.state.subscription == "canceled" ? this.getStringSubscription("ReSubscribe"): this.getStringSubscription("ChangeSubscription")}</InvestButton>
    }
    trindButton = () => {
        return <AedName >{this.state.planData.currency ? this.state.planData.currency.toUpperCase() : null} {this.state.planData.amount}/{this.state.planData.frequency.startsWith("year") ? "yr" : "mo"}</AedName>
    }
    getAddress = () => {
        return <AedName title={`${this.state.billingDetails?.attributes.address || ''}${this.state.billingDetails?.attributes.address ? "," : null} ${this.state.billingDetails?.attributes.city || ''}${this.state.billingDetails?.attributes.address ? "," : null} ${this.state.billingDetails?.attributes.country || ''}`}>{this.state.billingDetails?.attributes.address || ''}{this.state.billingDetails?.attributes.address ? "," : null} {this.state.billingDetails?.attributes.city || ''}{this.state.billingDetails?.attributes.address ? "," : null} {this.state.billingDetails?.attributes.country || ''}</AedName>
    }
    getReNewPlan = () => {
        return this.state.planList.find((item: {
            type: string,
            attributes: {
                name: string,
                currency: string,
                duration: string | null,
                amount: number,
                frequency: string
            }
        }) => {
            if (item.attributes.frequency.startsWith('trial') && this.state.subscriptionData?.status === "expired") {
                return
            } else {
                return this.state.planData.frequency !== item.attributes.frequency
            }
        }
        )
    }
    renderDir =()=>{
        return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
      }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <BackgroundBoxSatyle dir={this.renderDir()}>
                    <CnvestmentBox>
                        <Box style={webStyle.boxSettings}>
                            <a style={webStyle.SettingsName}>{this.getStringSubscription("Settings")}</a>
                            <Link to={'/Dashboard'} className="lng-close-rounded">
                                <img style={webStyle.group_Exit} src={group_Exit} />
                            </Link>
                        </Box>
                        <Box style={webStyle.SubscriptionName}> {this.getStringSubscription("ManageSubscription")}</Box>
                        <Cnvestment>
                            <div style={this.colorCss(webStyle)}>
                                <div className="subscription-header">
                                    <div className="header-left">
                                        {(this.nameInfo(EnjoyingName, ExpiredName, PendingName, EnjoyingNameDay))}
                                    </div>
                                    <div className="header-right">
                                        {this.getImage(TrialText, DaysLeft, webStyle)}
                                    </div>
                                </div>
                                <div className="subscription-content">
                                    <div className="content-left">
                                        <div className="plan-info">
                                            <Typography style={webStyle.PlanDetailsName}> {this.getStringSubscription("PlanDetails")}</Typography>
                                            <Typography style={webStyle.YourPlanName}>{this.getStringSubscription("YourPlan")}</Typography>
                                            <Box style={webStyle.towFlex}>
                                                <Typography style={webStyle.PremiumName}>{this.state.subscription === "trial" ? this.getStringSubscription("TrialPlan") : this.getStringSubscription("Premium")}</Typography>&nbsp;&nbsp;&nbsp;
                                                <Box style={this.pendingButton(webStyle)}>
                                                    {this.allButtonName()}
                                                </Box>
                                            </Box>
                                        </div>
                                        <div className="billing-info">
                                            <PaddingBox >
                                                <Grid item xs={6}>
                                                    <Typography style={webStyle.PlanDetailsName}> {this.getStringSubscription("PlanType")}</Typography>
                                                    {this.state.subscription === "trial" ?
                                                        <AedName > {this.getStringSubscription("Trial30Day")}</AedName> :
                                                        this.trindButton()
                                                    }
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={webStyle.PlanDetailsName}>{this.getStringSubscription("NextBillingDate")}</Typography>
                                                    <AedName>{this.state.subscriptionData?.expiration_date ? new Date(this.state.subscriptionData.expiration_date).toLocaleDateString("en-GB") : null}</AedName>
                                                </Grid>
                                            </PaddingBox>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="content-right">
                                        <div className="payment-details">
                                            <div style={{
                                                display: 'flex', alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Typography style={webStyle.PlanDetailsName}> {this.getStringSubscription("PaymentDetails")}</Typography>
                                                {this.state.subscription !== "canceled" && <Typography style={webStyle.Editdetails} data-test-id="editDetails" onClick={this.editDetails}>{this.getStringSubscription("EditDetails")}</Typography>}
                                            </div>
                                            <Box style={webStyle.boxPaddinTop}>
                                                <Grid item xs={10}>
                                                    <Typography style={webStyle.PlanDetailsName}> {this.getStringSubscription("Method")}</Typography>
                                                    <AedName style={{ textTransform: 'uppercase' }}>{this.state.cardDetails.card_number || String(this.state.subscriptionData?.purchase_type).split("_").join(" ")}</AedName>
                                                </Grid>
                                                <Grid item xs={10} style={webStyle.boxPaddinTop}>
                                                    <Typography style={webStyle.PlanDetailsName}>{this.getStringSubscription("BillingAddress")}</Typography>
                                                    {this.getAddress()}
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                <div className="subscription-footer">
                                    <Box style={webStyle.buttonCss}>
                                        {this.state.subscription === "expired" ? <ExpiredClear data-test-id="renewSubscription" onClick={this.renewSubscription}><SyncIcon style={webStyle.ExpiredButtonColor} />&nbsp;{this.getStringSubscription("RenewSubscription")}</ExpiredClear> : this.dataBotton()}
                                    </Box>
                                    <Box style={webStyle.PandingCss} data-test-id="retryPayment" onClick={this.retryPayment}>{this.state.subscription === "pending" ? this.getStringSubscription("RetryPayment") : null}</Box>
                                </div>
                            </div>
                        </Cnvestment>

                        <Box style={webStyle.comenButton}>
                            {this.state.subscription === "expired" ?
                                <Box>
                                    <ExploreOther >{this.getStringSubscription("OtherPlan")}</ExploreOther>
                                    <ExpirdButtonCss data-test-id="aedMonth" onClick={() => this.aedMonth(this.getReNewPlan()?.id as string)}>
                                        AED {this.getReNewPlan()?.attributes.amount} {this.getStringSubscription("Per")} {this.getReNewPlan()?.attributes.frequency.startsWith("year") ? this.getStringSubscription("Year") : this.getStringSubscription("Month")}
                                    </ExpirdButtonCss>
                                </Box> :
                                this.InvestButtonTrial()
                            }
                        </Box>
                    </CnvestmentBox>
                    <Box>
                        <Dialog
                            open={this.state.openCashModel}
                            keepMounted
                            data-test-id="dialogModel"
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{ style: this.dialogCashPaperStyle }}
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description" style={webStyle.subscriptionBe}>
                                    {this.getStringSubscription("OtherPlan")}
                                </DialogContentText>
                                <Box style={webStyle.Validtill}> {this.getStringSubscription("ValidTill")} {this.state.subscriptionData?.expiration_date ? new Date(this.state.subscriptionData.expiration_date).toLocaleDateString("en-GB") : null}</Box>
                                <Box style={webStyle.comenButton}>
                                    <GoBack data-test-id="dialogCashModel" onClick={this.dialogCashModel}>{this.getStringSubscription("GoBack")}</GoBack>
                                    <Confirm data-test-id="getsubscriptionConfirm" onClick={this.getsubscriptionConfirm}>{this.getStringSubscription("Confirm")}</Confirm>
                                </Box>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </BackgroundBoxSatyle>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start 
const webStyle = {
    boxwidth: { width: '50%' },
    Validtill: { color: "#928F98", fontSize: "20px", fontWeight: 300, display: 'flex', justifyContent: 'center', margin: "25px 0px" },
    subscriptionBe: { color: "#FFF", fontSize: "20px", fontWeight: 600 },
    imgCss: { width: '60%' },
    cardPending: { borderRadius: "28px", border: "1px solid #FFF159", background: "linear-gradient(180deg, rgba(255, 241, 89, 0.45) -52.71%, rgba(255, 241, 89, 0.00) 50%)", padding: '10px 24px' },
    cardExpired: { borderRadius: "28px", border: "1px solid #a54e5d", background: "linear-gradient(rgb(226 45 45 / 38%) -52.71%, rgba(61, 61, 79, 0) 50%)", padding: '10px 24px' },
    cardColor: { borderRadius: "28px", border: "1px solid #57F3EC", background: "linear-gradient(180deg, #2DE2D5 -52.71%, rgba(61, 61, 79, 0.00) 50%)", padding: '10px 24px' },
    cancelButton: { color: '#EE5353', marginTop: '-2px' },
    ExpiredButtonColor: { color: '#39e0d5' },
    Divider: { backgroundColor: '#57575D' },
    PandingCss: { color: '#31e0de', display: 'flex', justifyContent: 'center', fontSize: "20px", fontWeight: 600, cursor: "pointer" },
    buttonCss: { margin: '30px 0px', display: 'flex', justifyContent: 'center' },
    boxPaddinTop: { paddingTop: '17px' },
    boxWhith: { width: '50%', paddingLeft: '10%' },
    paddingBox: { display: 'flex', flexWrap: 'wrap', paddingTop: '17px' },
    towFlex: { display: 'flex' },
    menBox: { padding: '0px 80px', width: '100%', marginTop: '10px' },
    comenButton: { display: 'flex', justifyContent: 'center' },
    group_Exit: { width: '36px', height: '36px' },
    boxSettings: { display: 'flex', justifyContent: 'space-between' },
    SettingsName: { color: "#FFF", fontSize: "14px", fontWeight: 600 },
    SubscriptionName: { color: "#FFF", fontSize: "24px", fontWeight: 600, marginTop: '7px' },
    enjoyingName: { display: 'flex', justifyContent: 'center' },
    PlanDetailsName: { color: "#928F98", fontSize: "14px", fontWeight: 300 },
    YourPlanName: { color: "#FFF", fontSize: "16px", fontWeight: 300, marginTop: '17px' },
    PremiumName: { color: "#FFF", fontSize: "18px", fontWeight: 700 },
    PremiumNameButton: { color: "#FFF", fontSize: "14px", fontWeight: 400, background: '#24D4B0', borderRadius: "8px", padding: '0px 9px', display: 'flex', alignItems: 'center' },
    ExpiredButton: { color: "#FFF", fontSize: "14px", fontWeight: 400, background: '#ed5353', borderRadius: "8px", padding: '0px 9px', display: 'flex', alignItems: 'center' },
    PendingButton: { color: "#FFF", fontSize: "14px", fontWeight: 400, background: '#E4D539', borderRadius: "8px", padding: '0px 9px', display: 'flex', alignItems: 'center' },
    Editdetails: { color: "#814FF9", fontSize: "14px", fontWeight: 500, cursor: "pointer" },
}
const Confirm = styled(Box)({ cursor: "pointer", color: "#f94f49", textAlign: 'center', border: "1px solid #f94f49", padding: '0px 15px 0px 15px', borderRadius: "8px", fontWeight: 500, marginLeft: '17px' })
const GoBack = styled(Box)({ cursor: "pointer", color: "#d1fffd", textAlign: 'center', border: "1px solid #d1fffd", padding: '0px 15px 0px 15px', borderRadius: "8px", fontWeight: 500 })
const ExploreOther = styled(Box)({ color: "#FFF", fontSize: "20px", fontWeight: 500, padding: '10px 0px', textAlign: 'center', cursor: "pointer" })
const BackgroundBoxSatyle = styled(Box)({ width: '100%', background: '#2a2a39', height: '100vh', overflowY: 'scroll' })
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})
const ExpiredClear = styled(Button)({ borderRadius: "12px", border: "1px solid #31e1cf", textTransform: 'capitalize', padding: '10px 20px', boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", color: '#31e1cf', fontSize: '16px', fontWeight: 600, fontFamily: "Poppins" })
const ButtonClear = styled(Button)({ borderRadius: "12px", border: "1px solid #EE5353", textTransform: 'capitalize', padding: '10px 20px', boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", color: '#EE5353', fontSize: '16px', fontWeight: 600 })
const BoxCardData = styled(Box)({ alignItems: 'center', display: 'flex' })
const Cnvestment = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "0px !important", paddingRight: "0px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})
const PendingName = styled(Box)({ color: "#FFF159", fontSize: "20px", lineHeight: '28px', fontWeight: 600, marginTop: '7px', textAlign: 'center', display: 'flex', justifyContent: 'center' })
const ExpiredName = styled(Box)({ color: "#de5c51", fontSize: "20px", lineHeight: '28px', fontWeight: 600, marginTop: '7px', textAlign: 'center', display: 'flex', justifyContent: 'center', })
const EnjoyingNameDay = styled(Box)({
    color: "#2CE2D5", fontSize: "20px", lineHeight: '28px', fontWeight: 600, marginTop: '7px', textAlign: 'center', display: 'flex', justifyContent: 'center', width: '45%',
    '@media (max-width: 1536px)': { width: "65% !important" },
})
const EnjoyingName = styled(Box)({ color: "#2CE2D5", fontSize: "20px", lineHeight: '28px', fontWeight: 600, marginTop: '7px', textAlign: 'center', display: 'flex', justifyContent: 'center', })
const InvestButton = styled(Button)({
    borderRadius: '8px', background: '#C9F9F6', textTransform: 'capitalize', fontFamily: 'Poppins', color: '#3C3C51', fontSize: '16px', fontWeight: 700, padding: '10px 10px', margin: '30px 0',
    '&:hover': { borderRadius: '8px', background: '#C9F9F6', textTransform: 'capitalize', fontFamily: 'Poppins', color: '#3C3C51', fontSize: '16px', fontWeight: 700, padding: '10px 10px', margin: '30px 0' },
})
const ExpirdButtonCss = styled(Button)({
    borderRadius: '8px', textTransform: 'capitalize', border: '1px solid #31e6d0', fontFamily: 'Poppins', color: '#31e6d0', fontSize: '16px', fontWeight: 700, padding: '10px 10px', cursor: "pointer",
    '&:hover': { borderRadius: '8px', textTransform: 'capitalize', border: '1px solid #31e6d0', fontFamily: 'Poppins', color: '#31e6d0', fontSize: '16px', fontWeight: 700, padding: '10px 10px', cursor: "pointer" },
})
const PaddingBox = styled(Box)({ display: 'flex', flexWrap: 'wrap', paddingTop: '17px' })
const DaysLeft = styled(Box)({ fontSize: "33px", color: "#2daba7", marginTop: "20%", textAlign: 'center', fontWeight: 600 })
const TrialText = styled(Box)({ fontSize: "150px", color: "#296f73", textAlign: 'center', textShadow: "-3px -3px 0 #26e0d4, 3px -3px 0 #26e0d4, -3px 3px 0 #26e0d4, 3px 3px 0 #26e0d4", margin: '25% 0px' })
const AedName = styled(Box)({ color: "#928F98", fontSize: "20px", fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' })
// Customizable Area End

