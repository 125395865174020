import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  correctPin: string;
  showRemoveButton: boolean;
  enteredPin: string;
  showCompletedButton: boolean;
  newPin: any;
  confirmPin: any;
  confirmNewPin: any,
  isMatch: boolean;
  token: any;
  openPinSuccessPopup: boolean;
  email: any;
  otp: any;
  errorMsg: any;
  newSetPin: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PasscodeLockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pinviewRef = React.createRef();
  pin: any;
  pinConfirmPinCallId: any;
  otpRequestInMailCallId: any;
  otpSendRequestCallId: any;
  enteredPinId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      correctPin: "56771",
      showRemoveButton: false,
      showCompletedButton: false,
      enteredPin: "",
      newPin: '',
      confirmPin: '',
      confirmNewPin: '',
      isMatch: false,
      token: localStorage.getItem('authToken'),
      openPinSuccessPopup: false,
      email: '',
      otp: '',
      errorMsg: '',
      newSetPin: '',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (responseJson && apiRequestCallId === this.enteredPinId) {
      this.redirectToDocumentation(responseJson)
    }

    if (responseJson && this.pinConfirmPinCallId == apiRequestCallId) {
      this.pinSuccessModal()
    }

    if (responseJson && this.otpRequestInMailCallId === apiRequestCallId) {
      this.manageOtpInMailResponse(responseJson)
    }

    if (responseJson && this.otpSendRequestCallId == apiRequestCallId) {
      this.manageOTPSubmitResponse(responseJson);

    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleButtonPress(key: any) {
    if (key === "custom_left") {
      //@ts-ignore
      this.pinviewRef.current.clear();
    }
    if (key === "custom_right") {
      if (this.state.correctPin === this.state.enteredPin) {
        this.showAlert("", "Correct Pin Entry Success");
      } else {
        this.showAlert("", "Incorrect Pin. Try Again.");
        //@ts-ignore
        this.pinviewRef.current.clearAll();
      }
    }
  }

  setEnteredPin(value: any) {
    console.log("entered pin value::" + value);
  }

  updateStateForPin = (value: any, method: any, state: any) => {
    let newPin;
    if (method == 'click') {
      newPin = this.state.newPin + value
    } else {
      newPin = value
    }
    if (newPin.length <= 4) {
      this.setState({ newPin: newPin }, () => {
        console.log(this.state.newPin)
      });
    }
  }

  updateStateForEnterPin = (value: any, method: any, state: any) => {
    let enteredPin;
    if (method == 'click') {
      enteredPin = this.state.enteredPin + value;
    }else{
      enteredPin = value
    }
    if(enteredPin.length <= 4){

      this.setState({
        enteredPin: enteredPin
      })
    }
  }


  updateStateForConfirmPin = (value: any, method: any) => {
    let pin = localStorage.getItem('newPin')
    this.setState({ newPin: pin })
    let newPin;
    if (method == 'click') {
      newPin = this.state.confirmPin + value
    } else {
      newPin = value
    }
    if (newPin.length <= 4) {
      this.setState({ confirmPin: newPin }, () => {

        if (this.state.confirmPin.length == 4) {

          if (this.state.newPin != this.state.confirmPin) {
            this.setState({ isMatch: true })
          } else {
            this.setState({ isMatch: false })

          }
        } else {
          this.setState({ isMatch: false })

        }

      })
    }
  }

  confirmBtn = () => {
    if (this.state.confirmPin.length == 4) {

      if (this.state.newPin != this.state.confirmPin) {
        this.setState({ isMatch: true })
      } else {
        this.setState({ isMatch: false })
        this.pinMatchSuccessful()

      }
    } else {
      this.setState({ isMatch: false })

    }

  }

  confirmEnterBtn = () => {
    if (this.state.enteredPin.length === 4) {
      this.enteredPinSuccessful()
    }
    else {
      toast.dismiss()
      toast.error('PIN invalid')
      this.setState({ isMatch: false })
    }
  }

  enteredPinSuccessful = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.enteredPinId = requestMessage.messageId;
    const header = {
      "token": this.state.token,
      'Content-Type': 'application/json'
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/settings/validate_pin?pin=${this.state.enteredPin}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  pinMatchSuccessful = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pinConfirmPinCallId = requestMessage.messageId;
    const header = {
      "token": this.state.token,
      'Content-Type': 'application/json'
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const attributes = {
      "pin": this.state.newPin,
      "confirm_pin": this.state.confirmPin
    }
    const data = {
      attributes: attributes
    };
    const httpBody = {
      data: data
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/set_n_confirm_pin"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  backBtn = () => {
    let currentPin = this.state.newPin;
    if (currentPin.length > 0) {
      currentPin = currentPin.slice(0, -1);
    }
    this.setState({ newPin: currentPin });
  }

  backBtnForConfirmation = () => {
    let currentPin = this.state.confirmPin;
    if (currentPin.length > 0) {
      currentPin = currentPin.slice(0, -1);
    }
    this.setState({ confirmPin: currentPin });
  }

  pinSuccessModal = () => {
    this.setState({ openPinSuccessPopup: !this.state.openPinSuccessPopup })
    setTimeout(() => {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    },2000)
  }

  dialogStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  isValidEmail = (email: any) => {
    if (!email || typeof email !== 'string') {
      return false; // Invalid if email is empty or not a string
    }

    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');

    if (atIndex <= 0 || dotIndex <= atIndex || dotIndex === email.length - 1) {
      return false; // Invalid if @ and . are in incorrect positions
    }

    return true; // Email passes basic validation
  };
  onEmailSubmit = async () => {
    if (this.state.email.lenth < 1 || this.state.email === "") {
      toast.error('Email is required')
    }
    else {
      if (!this.isValidEmail(this.state.email)) {
        toast.error('Email is not valid')

      } else {
        const requestMessageForRequestMail = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.otpRequestInMailCallId = requestMessageForRequestMail.messageId;
        const header = {
          "token": this.state.token,
          "Content-Type": "application/json"
        };
        requestMessageForRequestMail.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        const attributes = {
          email: this.state.email
        }
        const data = {
          attributes: attributes
        };
        const httpBody = {
          data: data
        };

        requestMessageForRequestMail.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessageForRequestMail.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_settings/settings/forgot_password"
        );

        requestMessageForRequestMail.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessageForRequestMail.id, requestMessageForRequestMail);
        window.localStorage.setItem("email_for_otp", this.state.email);
      }
    }
  }

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  redirectToNextScreen = (state: any) => {
    if (this.state.newPin.length == 4) {
      localStorage.setItem('newPin', this.state.newPin)
      if (state == 'new') {
        this.props.navigation.navigate('ConfirmPin')
      }
      else {
        this.props.navigation.navigate('ConfirmNewPin')
      }
    }
  }
  redirectToDocumentation = (responseJson: any) => {
    if (responseJson.message !== 'Pin does not match') {
      this.props.navigation.navigate('Documentation')
    }
    else {
      this.setState({ isMatch: false })
      toast.dismiss()
      toast.error('PIN invalid')
    }
  }

  manageOtpInMailResponse = async (responseJson: any) => {
      if (responseJson.meta) {
        toast.success('OTP is send to your email successfully')
        this.props.navigation.navigate('ForgotPinOtp')
      } else {
        toast.error(responseJson.message)
      }

  }

  resendOtp = async () => {

    const requestMessageForResendOtp = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpRequestInMailCallId = requestMessageForResendOtp.messageId;
    const header = {
      "token": this.state.token,
      "Content-Type": "application/json"
    };
    requestMessageForResendOtp.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const attributes = {
      email: window.localStorage.getItem("email_for_otp")
    }
    const data = {
      attributes: attributes
    };
    const httpBody = {
      data: data
    };

    requestMessageForResendOtp.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessageForResendOtp.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/forgot_password"
    );

    requestMessageForResendOtp.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessageForResendOtp.id, requestMessageForResendOtp);

  }

  onOTPSubmit = async () => {
    if (this.state.otp.length < 4) {
      this.setState({ errorMsg: 'OTP invalid!' });
    } else {
      this.setState({ "otp": "" })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpSendRequestCallId = requestMessage.messageId;
      const header = {
        "Content-Type": "application/json",
        "token": this.state.token

      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        "otp_code": this.state.otp,
      };
      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/settings/otp_confirmation"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  manageOTPSubmitResponse = (responseJson: any) => {
      if (responseJson.message == 'Validation success') {
        toast.success('Validation success')
        this.props.navigation.navigate('SetupNewPin')
      } else {
        toast.error(responseJson.message)
      }
  }

  handleBack = () => {
    this.props.navigation.goBack();
  }

  // Customizable Area End
}
