import React from "react";

//Customizable Area Start

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { NavLink } from "react-router-dom";
import { logo, backIcon } from "./assets";
import "./style.css";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    passwordRequirements() {
        return (
            <>
                {this.state.isPasswordFocused && (
                    <div className="required-block">
                        <h5>Password requirements:</h5>
                        <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordLength ? "confirmed" : ""}>
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                                Minimum 8 characters in length
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordLowercase ? "confirmed" : ""}>
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                                At least one lowercase letter (a-z)</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordUppercase ? "confirmed" : ""}>
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                                At least one uppercase letter (A-Z)</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordNumber ? "confirmed" : ""}>
                                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                                At lease one number (0-9)</li>
                        </ul>
                    </div>
                )}
            </>
        )
    }

    passwordRequirementsArabic() {
        return (
            <>
                {this.state.isPasswordFocused && (
                    <div className="required-block">
                        <h5>متطلبات كلمة المرور</h5>
                        <ul>
                            <li>الحد الأدنى 8 أحرف في الطول
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordLength ? "confirmed" : ""}>
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </li>
                            <li>
                                (a-z) حرف صغير واحد على الأقل
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordLowercase ? "confirmed" : ""}>
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg></li>
                            <li>
                                (A-Z) حرف كبير واحد على الأقل
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordUppercase ? "confirmed" : ""}>
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </li>
                            <li>
                                رقم واحد على الأقل (0-9)
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className={this.state.passwordNumber ? "confirmed" : ""}>
                                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                                </svg>
                            </li>
                        </ul>
                    </div>
                )}
            </>
        )
    }

    render() {

        return (
            <div className="main-wrapper">
                {!this.state.isArabic ?
                    <div className="forgotPassword-wrapper">
                        <div className="forgotPassword-box">
                            <div className="logo-right">
                                <a href="#"><img src={logo} alt="logo" /></a>
                            </div>
                            <div className="forgotPassword-block">
                                <NavLink to="/ForgotPasswordOTP"><h4><img src={backIcon} alt="back-icon" />Reset Password</h4></NavLink>
                                <p>Your new password should not match with your old password</p>
                                <div className="forgotPassword-code">
                                    New Password
                                    <br />
                                    <div className="input-controls not-arabic">
                                        <input type={this.inputType(this.state.passwordVisible)} className="forgotPassword-input" data-test-id="newPasswordField" placeholder="Enter password"
                                            onChange={(e) => {
                                                this.validatePassword(e.target.value);
                                            }} onFocus={this.handlePasswordFocus}
                                            onBlur={this.handlePasswordBlur} />
                                        {this.state.passwordVisible ? (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" data-test-id="visible-eye-icon" onClick={this.passwordVisiblityChange} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                            </svg>
                                        ) : (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" data-test-id="Invisible-eye-icon" onClick={this.passwordVisiblityChange} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        )}</div>
                                    <br />
                                    {this.state.errorMsgs && <div className="errorMsgs">{this.state.errorMsgs}</div>}
                                    {this.passwordRequirements()}
                                    <br />
                                    Confirm Password
                                    <br />
                                    <div className="input-controls">
                                        <input type={this.inputType(this.state.confirmPasswordVisible)} className="forgotPassword-input" data-test-id="confirmPasswordField" placeholder="Confirm your password" onChange={(e) => this.setState({ confirmPassword: e.target.value, errorMsgs: "", samePasswordError: "" })} />
                                        {this.state.confirmPasswordVisible ? (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" onClick={this.confirmPasswordVisiblityChange} data-test-id="confirmPasswordVisibleEye" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                            </svg>
                                        ) : (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" onClick={this.confirmPasswordVisiblityChange} data-test-id="confirmPasswordInVisibleEye" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        )}
                                    </div>
                                    {this.state.samePasswordError && <div className="errorMsgs">{this.state.samePasswordError}</div>}
                                    <button className="verifyForgotPassword" onClick={this.onResetPassword} data-test-id="verifyBtn">Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="forgotPassword-wrapper arbic-password-index">
                        <div className="forgotPassword-box">
                            <div className="logo-right">
                                <a href="#"><img src={logo} alt="logo" /></a>
                            </div>
                            <div className="forgotPassword-block">
                                <NavLink to="/ForgotPasswordOTP"><h4>إعادة تعيين كلمة المرور<img src={backIcon} alt="back-icon" /></h4></NavLink>
                                <p>يجب ألا تتطابق كلمة المرور الجديدة مع كلمة المرور القديمة</p>
                                <div className="forgotPassword-code">
                                    كلمة المرور الجديدة
                                    <br />
                                    <div className="input-controls">
                                        <input type={this.inputType(this.state.passwordVisible)} className="forgotPassword-input" data-test-id="newPasswordField1" placeholder="كلمة المرور"
                                            onChange={(e) => {
                                                this.validatePassword(e.target.value);
                                            }} onFocus={this.handlePasswordFocus}
                                            onBlur={this.handlePasswordBlur} />
                                        {this.state.passwordVisible ? (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" data-test-id="visible-eye-icon1" onClick={this.passwordVisiblityChange} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                            </svg>
                                        ) : (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" data-test-id="Invisible-eye-icon1" onClick={this.passwordVisiblityChange} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        )}</div>
                                    <br />
                                    {this.state.errorMsgs && <div className="errorMsgs">{this.state.errorMsgs}</div>}
                                    {this.passwordRequirementsArabic()}
                                    <br />
                                    تأكيد كلمة المرور
                                    <br />
                                    <div className="input-controls">
                                        <input type={this.inputType(this.state.confirmPasswordVisible)} className="forgotPassword-input" data-test-id="confirmPasswordField1" placeholder="أكد رقمك السري" onChange={(e) => this.setState({ confirmPassword: e.target.value, errorMsgs: "", samePasswordError: "" })} />
                                        {this.state.confirmPasswordVisible ? (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" onClick={this.confirmPasswordVisiblityChange} data-test-id="confirmPasswordVisibleEye1" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                            </svg>
                                        ) : (
                                            <svg className="passwordVisible1" xmlns="http://www.w3.org/2000/svg" onClick={this.confirmPasswordVisiblityChange} data-test-id="confirmPasswordInVisibleEye1" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                            </svg>
                                        )}
                                    </div>
                                    {this.state.samePasswordError && <div className="errorMsgs">{this.state.samePasswordError}</div>}
                                    <button className="verifyForgotPassword" onClick={this.onResetPassword} data-test-id="verifyBtn">إعادة تعيين كلمة المرور</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// Customizable Area Start

