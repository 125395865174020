import React from "react";

export default async function documentDownload(documentUrl:string) {
    try {
      const response = await fetch(documentUrl);
      if (!response?.ok) throw new Error('Network response was not ok');
  
      const blob = await response.blob();
      const newurl = window.URL.createObjectURL(blob);
  
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = newurl;
      anchor.download = documentUrl.split('/').pop() as string;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(newurl);
    } catch (error) {
      // console.error('Download failed:', error);
    }
  }