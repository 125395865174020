import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Client as ConversationsClient, Conversation, Message as MessageData, Paginator } from "@twilio/conversations";
import { getStorageData } from "../../../framework/src/Utilities";
import React,{ createRef } from "react";
import StorageProvider from "framework/src/StorageProvider";
import toast from "react-hot-toast";
import moment from "moment";
import { fixWebmDuration } from "@fix-webm-duration/fix";
import { validateUploadFile } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface SelectedFile {
  fileName: string;
  fileType: string;
  file: File;
  previewUrl?: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface EmojiType {
  id: string;
  name: string;
  unified: string;
  native: string;
  keywords: string[];
  custom: boolean;
  shortcodes: string[];
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

export interface ChatDataType {
  sender: string;
  message: string | null;
  time: string;
  imgUrl: string;
  userId: string | null;
  type: string | null;
  fileName:string;
  dateUpdated: Date;
  errors?: string;
  error?: string;
  groupDateLabel: string;
}

interface MessageTwo {
  state: {
      sid: string;
      type: string;
      media: Media;
      body: string;
      attributes?: {
        audio?: boolean;
      },
      dateUpdated: Date
  };
  author: string;
}

interface Media {
  state:{
    filename:string,
    contentType:string,
  };
  getContentTemporaryUrl: Function;
}

export interface ErrorPayloadType {
  key: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
  error?: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  chatData: ChatDataType[];
  senderChatText: string;
  chatToken: string;
  conversationID: string;
  plannerId: string;
  userName: string;
  userImage: string;
  userId: string;
  isParticipantId: boolean;
  loginId : string;
  isEmoji: boolean;
  currrentEmojiPick: null | EmojiType;
  imgUrl: string;
  imgModal: boolean;
  imageName: string;
  isImageUpload: boolean;
  imageFile: File | null;
  isRecording: boolean;
  isPlaying: boolean;
  audioURL: string | null;
  currentTime: number;
  duration: number;
  currentAudioIndex: number;
  isUpwardDirection: boolean;
  dateLabel: string;
  isRefreshing: boolean;
  isImageModal : boolean;
  selectImgUrl : string;
  selectImageName : string ;
  isLoader: boolean;
  selectedFile?: SelectedFile;
  language:string,
  direction: string,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  lastMessageID: string = "";
  chatContainerRef: React.RefObject<HTMLDivElement>;
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getTokenId: string = "";
  sendMessageId : string ="";
  conversationChannel! : Conversation;
  private mediaRecorder: MediaRecorder | null = null;
  audioRef = createRef<HTMLAudioElement>();
  private audioChunks: Blob[] = [];
  private debounceTimeout: NodeJS.Timeout | null = null;
  paginator: Paginator<MessageData> | null = null;
  pickerRef: React.RefObject<HTMLDivElement> = React.createRef();
  startTime: number;
  postUserfinancialPlanner: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      chatData: [],
      senderChatText: "",
      chatToken: "",
      conversationID: "",
      plannerId: "",
      userName: "",
      userImage: "",
      userId: "",
      isParticipantId: false,
      loginId : "",
      isEmoji: false,
      currrentEmojiPick: null,
      imgUrl: "",
      imgModal: false,
      imageName: "",
      isImageUpload: false,
      imageFile: null,
      isRecording: false,
      isPlaying: false,
      audioURL: null,
      currentTime: 0,
      duration: 0,
      currentAudioIndex: 0,
      isUpwardDirection: false,
      dateLabel: 'Today',
      isRefreshing: false,
      isImageModal: false,
      selectImgUrl: "",
      selectImageName: "",
      isLoader: false,
      selectedFile: undefined,
      direction: 'ltr',
      language: 'English',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.chatContainerRef = createRef();
    this.startTime = 0;
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getChatData();
    this.loadPlannedEventList()
    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if (!language) {
      language = "English"
    }
    this.setState({ direction: currentLanguage, language: language });
    const loginId= await getStorageData("EngagementRequestId")
    this.setState({loginId : loginId},()=>{
      this.getChatToken();
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.pickerRef.current && !this.pickerRef.current.contains(event.target as Node)) {
        this.setState({ isEmoji: false });
    }
  };

  async componentWillUnmount(): Promise<void> {
    this.conversationChannel.delete();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getChatData = async () => {
    let chatStoredData = await StorageProvider.get('CHAT_DATA');
    let conversationDetails = JSON.parse(chatStoredData);
    this.setState({
      conversationID: conversationDetails?.conversation?.conversation_sid,
      plannerId: conversationDetails?.plannerID,
      userName: conversationDetails?.userName,
      userImage: conversationDetails?.userImage,
      isLoader: true
    }, () => {
      this.getChatToken();
      this.loadPlannedEventList()
    });
  }

async loadPlannedEventList() {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postUserfinancialPlanner = requestMessage.messageId;

  const headers = {
    token: localStorage.getItem("token")
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_formapprovalworkflow/wealth_planners/wealth_planner_data?id=${this.state.plannerId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  
  initConversations = async (chatToken: string) => {
    try {
      const conversationsClient = await new ConversationsClient(chatToken);
      if(conversationsClient) {
          conversationsClient.on('connectionStateChanged', async (state) => {
            if (state === 'connected') {
              this.onClientConnectionCell(conversationsClient);
            }
          });
          conversationsClient.on('tokenExpired', async () => {
            this.getChatToken();
          });
      }
      } catch (error) {
        console.error('Error initializing ConversationsClient:', error);
      }
  };

  onClientConnectionCell = async (conversationsClient: ConversationsClient) => {
    try {
      const channel = await conversationsClient.getConversationBySid(this.state.conversationID);
      await this.handleMessages(channel);
      this.conversationChannel = channel;
      channel.on('messageAdded', async (getMessage) => {
        await this.handleMessageAdded(getMessage);
      });
      if (this.chatContainerRef.current) {
        this.chatContainerRef.current.addEventListener('scroll', this.handleScrollCell);
      }
    } catch (error) {
      console.error('Error getting conversation:', error);
      this.initConversations(this.state.chatToken);
    }
  }

  handleMessages = async (channel: Conversation) => {
    try {
      this.paginator = await channel.getMessages(15);
      const messageDataArray = await Promise.all(
        this.paginator.items.map(async (message: MessageData) => {
          return await this.processMessage(message);
        })
      );
      const uniqueMessages = this.removeDuplicateMessages(messageDataArray);      
      this.setState({ 
        chatData: uniqueMessages,
        imgModal: false,
        isLoader: false
      }, this.scrollToBottom);
    } catch (error) {
      console.error('Error handling messages:', error);
    }
  };

  loadPreviousMessages = async () => {
    this.setState({ isRefreshing: true });
    const messagesContainer = this.chatContainerRef.current;
    if (messagesContainer) {
      const currentScrollHeight = messagesContainer.scrollHeight;
      if (!this.paginator || !this.paginator.hasPrevPage) {
        this.setState({ isRefreshing: false });
        return;
      }
      try {
        const prevPage = await this.paginator.prevPage();
        const messageDataArray = await Promise.all(
          prevPage.items.map(async (message: MessageData) => {
            return await this.processMessage(message);
          })
        );
        this.paginator = prevPage;
        const uniqueMessages = this.removeDuplicateMessages([...messageDataArray, ...this.state.chatData]);
        this.setState({ chatData: uniqueMessages, imgModal: false, isRefreshing: false }, () => {
          const newScrollHeight = messagesContainer.scrollHeight - 50;
          messagesContainer.scrollTop = newScrollHeight - currentScrollHeight;
        });
      } catch (error) {
      }
    }
  };

  removeDuplicateMessages = (messages: ChatDataType[]): ChatDataType[] => {
    const messageMap = new Map<string, ChatDataType>();
    messages.forEach((msg) => {
      let messageDateFormate = new Date(msg.dateUpdated)
      if (!messageMap.has(messageDateFormate.toISOString())) {
        messageMap.set(messageDateFormate.toISOString(), msg);
      }
    });
    return Array.from(messageMap.values());
  }

  handleMessageAdded = async (message: MessageData) => {
    try {
      let messagePayload = message as unknown as MessageTwo;
      if(this.lastMessageID !== messagePayload.state.sid) {
        this.lastMessageID = messagePayload.state.sid;
        const newMessageData = await this.processMessage(message);
        const uniqueMessages = this.removeDuplicateMessages([...this.state.chatData, newMessageData]);
        this.setState({
          chatData: uniqueMessages,
          imgModal: false,
          isLoader: false
        }, this.scrollToBottom);
      }
    } catch (error) {
      console.error("Error handling added message:", error);
    }
  };

  processMessage = async (message: MessageData) => {
    let messagePayload = message as unknown as MessageTwo;
    const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp',];
    
    const dateTxtValue = new Date(messagePayload.state.dateUpdated);
    const hours = dateTxtValue.getHours();
    const minutes = dateTxtValue.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    let mediaUrlValue = 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg';
    if (messagePayload.state.type === 'media') {
      mediaUrlValue = await messagePayload.state.media.getContentTemporaryUrl();
    }
    let mediaType = 'text';
    if(messagePayload.state.type === 'media') {
      if(messagePayload.state.attributes?.audio) {
        mediaType = 'audio';
      } else {
        if (imageTypes.includes(messagePayload?.state?.media?.state?.contentType)) {
          mediaType = 'image';
      } else {
        mediaType = 'file';
      }
      }
    }
    return {
      sender: 'receiver',
      message: messagePayload.state.body,
      time: formattedTime,
      imgUrl: messagePayload.state.type === 'media' ? mediaUrlValue : '',
      userId: messagePayload.author,
      type: mediaType,
      fileName: messagePayload?.state?.media?.state?.filename ? messagePayload?.state?.media?.state?.filename :'',
      dateUpdated: messagePayload.state.dateUpdated,
      groupDateLabel: this.formatDate(messagePayload.state.dateUpdated)
    };
  };

  handleFileUpload = async () => {
    this.setState({ isLoader: true });
    let formData: FormData = new FormData()
    formData.append('file', this.state.selectedFile?.file as unknown as Blob);
    const media = this.conversationChannel.prepareMessage();
    if (this.state.senderChatText && this.state.senderChatText.trim() !== "") {
      await media.setBody(this.state.senderChatText.trim()).addMedia(formData).setAttributes({ read: false }).build().send();
    } else {
      await media.addMedia(formData).setAttributes({ read: false }).build().send();
    }
    this.setState({ isImageUpload : false, senderChatText: "" });
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = () => {
    this.setState({ chatName: this.state.chatName })
    if (this.isStringNullOrBlank(this.state.chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: this.state.chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: this.createChatRoom,
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ chatName: event?.target?.value ?? 'Chat Room' });
  };

  handleNavigation = (message : Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let conversationDetails = message.getData(getName(MessageEnum.NavigationMessageSendData));
      if (conversationDetails) {     
        this.setState({
          conversationID: conversationDetails?.conversation?.conversation_sid,
          plannerId: conversationDetails?.plannerID,
          userName: conversationDetails?.userName,
          userImage: conversationDetails?.userImage,
        }, ()=> {
          this.getChatToken()     
        })
      }    
     }
  }

  handleSessionCell = (message : Message) =>{
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    this.handleNavigation(message)
    this.handleSessionCell(message)
    if(apiRequestCallId === this.postUserfinancialPlanner){
      this.setState({
        userImage: responseJson.data.attributes.image,
        userName: responseJson.data.attributes.name
      })
    }
    if(responseJson){
      if(this.isApiSuccess(responseJson)){
        this.successCallBack(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.failureCallBack(apiRequestCallId, responseJson);
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
  }

  handleSenderChat = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ senderChatText: event.target.value, currrentEmojiPick: this.state.currrentEmojiPick });
  };

  apiCallFunction = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "" } = data;
    const header = { 
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
    };
    let apiRequestMessageValue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessageValue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageValue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessageValue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessageValue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? apiRequestMessageValue.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : apiRequestMessageValue.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(apiRequestMessageValue.id, apiRequestMessageValue);
    return apiRequestMessageValue.messageId;
  };

  getChatToken = async () => {
    this.getTokenId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getTokenApi+this.state.plannerId
    });
  };

  isApiSuccess = (response:ChatDataType) => {
    return response && !response.errors && !response.error ? true : false
  }

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors || responseJson.error;
  };

  successCallBack = (apiID: string, response: {token:string}) => {
    if(apiID === this.getTokenId) {
      this.setState({ chatToken: response.token, chatList: [] }, () => {
        this.initConversations(response.token);
      });
    }
  }

  failureCallBack = async (apiID: string, response: InvalidResponseType) => {
    let errorMessage = this.parseApiMessageErrorResponse(response);
    if(apiID === this.getTokenId) {
      if(errorMessage === "Token has Expired") {
        localStorage.removeItem('token');
        localStorage.removeItem('authToken');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('email');
        window.location.href = '/EmailAccountLogin';
      }
    }
  }

  parseApiMessageErrorResponse = (responseJson: InvalidResponseType) => {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: ErrorPayloadType[] = responseJson.errors;

    let allerrors = '';
    errors.forEach((object: ErrorPayloadType) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
    });
    return allerrors;
  }
 
  sendMessage = async () => { 
    if (!this.state.isImageUpload) {
      if (this.state.senderChatText.trim() !== "") {
        await this.conversationChannel.sendMessage(this.state.senderChatText.trim(), { read: false });
      }
      this.setState({ senderChatText: "" });
    } else {
      this.handleFileUpload();
    }
  }

  handleKeyPressCell = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.sendMessage();
      }, 300);
    };
  }

  goToEngagementRequestScreen = () => {
    if(!this.state.isLoader) {
      this.props.navigation.goBack();
    }
  }

  getLoginId =(getUserId : string)=> {
    let loginId = this.state.loginId
     if(loginId === getUserId.split("_")[1]){
      return true
     }else{
      return false
     }
  }

  handleEmojiPicker = () =>{
    this.setState({isEmoji: !this.state.isEmoji});
  }

  handleEmojiSelect = (emoji: EmojiType) => {
    this.setState({ senderChatText: this.state.senderChatText + emoji?.native, isEmoji: !this.state.isEmoji , imgUrl: this.state.imgUrl});
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    
    const file = event.target.files[0];
    const fileType = file.type;
    const validation = validateUploadFile(file);
    if (!validation.isValid) {
      toast.error(validation.errorMessage);
      return;
    }

    const fileData: SelectedFile = {
      fileName: file.name,
      fileType: file.type,
      file: file
    };

    if (fileType.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          selectedFile: {
            ...fileData,
            previewUrl: reader.result as string
            
          },
          imgModal:true,
          isImageUpload:true
        });
      };
      reader.readAsDataURL(file);
      toast.success(this.getStringChat("ImageUploadSuccess"));
    } else if (
      fileType === 'application/pdf' ||
      fileType === 'application/msword' ||
      fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.setState({
        selectedFile: fileData,
        imgModal:true,
        isImageUpload:true
      });
      toast.success(this.getStringChat("DocumentUploadSuccess"));
    } else {
      toast.error(this.getStringChat("UnsupportedFileType"));
    }

    event.target.value = "";
  };
  

  scrollToBottom() {
    this.handleDateLabelValue();
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }
  }

  handleScrollCell = () => {
    if (this.chatContainerRef.current) {
      const { scrollTop } = this.chatContainerRef.current;
      if (scrollTop < 10) {
        this.loadPreviousMessages();
      }
      this.handleDateLabelValue();
    }
  };

  handleDateLabelValue = () => {
    if(this.chatContainerRef.current) {
      const children = Array.from(this.chatContainerRef.current.children);
      for (let value = 0; value < children.length; value++) {
        const rect = children[value].getBoundingClientRect();
        if (rect.top >= 0) {
          const message = this.state.chatData[value];
          const newDateLabel = this.formatDate(message.dateUpdated);
          if (newDateLabel !== this.state.dateLabel) {
            this.setState({ dateLabel: newDateLabel });
          }
          break;
        }
      }
    }
  }

  formatDate = (date: Date = new Date()) => {
    const now = moment();
    const messageDate = moment(date);
    if (now.isSame(messageDate, 'day')) {
      return this.getStringChat("Today");
    } else if (now.subtract(1, 'days').isSame(messageDate, 'day')) {
      return this.getStringChat("Yesterday");
    } else {
      return messageDate.format('MMMM D, YYYY');
    }
  };  

  startRecordingValue = async () => {
    try {
      const streamValue = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(streamValue);
      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = async () => {
        const timeDuration = Date.now() - this.startTime;
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/m4a' });
        const fixedBlob = await fixWebmDuration(audioBlob, timeDuration);
        const audioURL = URL.createObjectURL(fixedBlob);
        let formData: FormData = new FormData()
        formData.append('file', audioBlob as unknown as Blob);
        const mediaValue = this.conversationChannel.prepareMessage();
        await mediaValue.addMedia(formData).setAttributes({ audio: true , read: false}).build().send();
        this.setState({ audioURL });
        this.audioChunks = [];
        streamValue.getTracks().forEach((track) => track.stop());
      };
      this.mediaRecorder.start();
      this.startTime = Date.now();
      this.setState({ isRecording: true });
    } catch (error) {
      console.error('Error accessing microphone', error);
    }
  };

  debounceValue = (func: () => void, wait: number) => {
    return () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func();
      }, wait);
    };
  };

  stopRecordingValue = () => {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.setState({ isRecording: false });
    }
  };

  onPlayAudioValue = (audioUrl: string, keyIdx: number, isCurrentAudio: boolean) => {
    this.setState({
      currentAudioIndex: keyIdx,
      audioURL: audioUrl,
      currentTime: isCurrentAudio ? this.state.currentTime : 0
    }, this.handlePlayPauseValue);
  }

  handlePlayPauseValue = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.audioRef.current?.pause();
    } else {
      this.audioRef.current?.play();
    }
    this.setState({ isPlaying: !isPlaying });
  };

  handleTimeUpdateValue = () => {
    if (this.audioRef.current) {
      this.setState({
        currentTime: this.audioRef.current.currentTime,
        duration: this.audioRef.current.duration,
      });
    }
  };

  onEndedAudioValue = () => {
    this.setState({ isPlaying: false });
  }
  handleCloseValue = () => {
    this.setState({isImageModal : false,selectImgUrl :"", selectImageName:''})
  };


  handleModalOpenBaseOnType = (type: string | null, urlData: string, fileName: string) => {
    if (type === '' || type === null) {
      return;
    }
    if (type !== 'file') {
      this.setState({
        isImageModal: true,
        selectImgUrl: urlData,
        selectImageName: fileName,
      });
    }
  };
  getStringChat = (keyvalue: string) => {
    return configJSON.languageListChat[this.state.language][keyvalue]
  }
  // Customizable Area End
}
