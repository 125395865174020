import React from "react";
// Customizable Area Start
import "./style.css";
import {
    Box,
    createTheme,
    ThemeProvider,
    Typography,
    FormGroup,
    styled,
    Popover,
    Grid,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Modal,
    TextField,
    Switch,
    Radio,
    Snackbar,
    GridSize
} from "@material-ui/core";
import {
    subarrowicon,
    picture,
    addFile,
    fileCopy,
    check,
    uncheck,
    editSlot,
    deleteSlot,
    arrowDown,
    SuccessCard,
    radioChecked,
    radioUncheck,
    closeDialogBtn,
    AlertImage
} from "./assets";
import ScheduleSectionController, { Props } from "./ScheduleSectionController.web";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ErrorMessage, FieldArray, Formik } from "formik";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Calendar } from "react-modern-calendar-datepicker";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
import Select, { StylesConfig } from "react-select"

// Customizable Area End

export default class ScheduleSection extends ScheduleSectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    renderProfile = () =>{
        return (
          <div  data-test-id="profileLink" style={styles.container} onClick={()=> this.goToProfilePage()}>
          <div>
            {this.state.plannerInfo.image ? (
              <img
                src={this.state.plannerInfo.image}
                style={styles.image}
                alt="profile"
              />
            ) : (
              <div style={styles.noImg}>
                {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
              </div>
            )}
          </div>
          <div style={styles.textContainers}>
            <span style={styles.nameStyle}>{this.state.plannerInfo.name}</span>
            <span style={styles.email}>{this.state.plannerInfo.email}</span>
          </div>
        </div>
        )
      }
    
    customTheme = createTheme({
        palette: {
            primary: {
                main: "#2CE2D5",
                contrastText: "#fff",
            },
        },
    });

    theme = createTheme(this.customTheme, {
        palette: {
            primary: {
                main: "#2CE2D5",
                contrastText: "#fff",
            },
        },
        overrides: {
            MuiTableContainer: {
                root: {
                    boxShadow: "none",
                    borderRadius: 0,
                    "&.normal-grid": {
                        "& .MuiTable-root": {
                            "& .MuiTableHead-root": {
                                "& .MuiTableCell-root": {
                                    padding: "22px 22px",
                                    fontWeight: 500,
                                    fontSize: 12,
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    color: "#8f99a3",
                                    borderColor: "#808d9e",
                                    whiteSpace: "nowrap",
                                },
                            },
                            "& .MuiTableBody-root": {
                                "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                                    backgroundColor: "rgba(9,72,73,0.05)",
                                    cursor: "pointer",
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                },
                                "& .MuiTableCell-root": {
                                    padding: "22px 22px",
                                    fontFamily: "'MaderaRegular', sans-serif",
                                    fontWeight: 400,
                                    fontSize: 17,
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    color: "#fff",
                                    borderColor: "#808d9e",
                                },
                            },
                        },
                    },
                },
            },
            MuiPickersModal: {
                dialogAction: {
                    backgroundColor: "#3C3C51",
                    color: "#33abb6",
                },
            },
            MuiPickersBasePicker: {
                pickerView: {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)"
    
                },
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                    "& button": {
                        backgroundColor: "rgb(57, 93, 107)",
                        color: "rgb(181, 176, 176)",
                    }
                },
                dayLabel: {
                    color: "rgb(181, 176, 176)"
                },
                dayheader: {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)"
                },
            },
            MuiPickersDay: {
                day: {
                    color: "rgb(181, 176, 176)",
                },
                current: {
                    color: "rgb(181, 176, 176)"
                },
                dayLabel: {
                    color: "rgb(181, 176, 176)"
                },
                daySelected: {
                    backgroundColor: "#2CE2D5",
                },
                dayDisabled: {
                    color: "#ccc",
                },
            },
            MuiPopover: {
                root: {
                    zIndex: 999,
                    borderRadius: "14px",
                    "& .MuiPaper-root": {
                        padding: 14,
                        boxShadow: "none",
                        fontSize: 12,
                        letterSpacing: "0.4px",
                        backgroundColor: "#395d6b !important",
                        borderRadius: "14px",
                    },
                    "& .MuiPaper-rounded": {
                        borderRadius: "5px",
                        boxShadow: "none",
                    },
                },
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: "#3C3C51",
                },
            },
        },
    })
    renderSnackBar = () =>{
        return(
          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} anchorOrigin={{
            vertical: 'top', horizontal: 'center'
          }} onClose={this.handleCloseSnackbar}>
            <Box style={styles.snakbar}>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item>
                <img src={AlertImage} alt="" style={{
                    paddingRight: "12px",
                    paddingTop: "4px",
                }}/>
                </Grid>
                <Grid item>
                {this.state.errorMsg}
                </Grid>
    
              </Grid>
            </Box>
          </Snackbar>
        )
      }

    renderOverrideDialog = () => {
        return (
            <Dialog
                open={this.state.addDateOverridesModalOpen}
                onClose={this.handleAddOverridesModalClose}
                scroll="body"
                className="date-override-dialog"
                maxWidth="xs"
            >
                <DialogContent dividers={false} style={styles.dateOverridesContent}>
                    <Box>
                        <img src={closeDialogBtn} style={{ float: "right", cursor: "pointer" }} onClick={this.handleAddOverridesModalClose} alt="" />
                    </Box>
                    <CalendarWrapper>
                        <Calendar
                            value={this.state.selectedOverrideDayDialog}
                            onChange={this.handleSelectDateOverrideDialog}
                            calendarClassName="date-overrides-calendar"
                            colorPrimary="#2DE6D5"
                            minimumDate={this.formateDate(new Date().toISOString())}
                            customDaysClassName={this.formatEventScheduledDates()}
                        />
                    </CalendarWrapper>
                    <OverrideFormWrapper>
                        <Formik
                            enableReinitialize
                            initialValues={this.state.DOInitialValue}
                            validationSchema={this.OverrideValidationSchema}
                            onSubmit={this.handleDOSubmit}
                            data-test-id="override-form"
                        >
                            {({ values, handleSubmit, setFieldValue, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container className="dateOverridesFormSection">
                                        <Grid item xs={12} className="form-grid-item">
                                            <TextField className="form-title" name="title" value={values.title} onChange={handleChange} placeholder="Add Title" />
                                            <Box style={styles.fieldError}>
                                                <ErrorMessage name="title" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} className="form-grid-item">
                                            <div className="switch-container">
                                                <div className="do-unavailable">Mark me as unavailable this day</div>
                                                <Switch name="unavailable" checked={values.unavailable} onChange={handleChange} />
                                            </div>
                                        </Grid>
                                        {!values.unavailable && (
                                            <>
                                                <Grid item xs={12} className="form-grid-item">
                                                    <div className="do-time-wrapper">
                                                        <div className="time-title">Start Time</div>
                                                        <div>
                                                            <div className="do-time">
                                                                <div>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardTimePicker
                                                                            id="time-picker"
                                                                            margin="normal"
                                                                            data-test-id="do-start-date-hours-id"
                                                                            value={values.startTimeHours}
                                                                            className="start-date-picker"
                                                                            format="hh"
                                                                            onChange={(value) =>
                                                                                setFieldValue(
                                                                                    `startTimeHours`,
                                                                                    value
                                                                                )
                                                                            }
                                                                            autoOk
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change time",
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box style={styles.fieldError}>
                                                                        <ErrorMessage name="startTimeHours" />
                                                                    </Box>
                                                                </div>
                                                                <div>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardTimePicker
                                                                            margin="normal"
                                                                            id="time-picker"
                                                                            data-test-id="do-start-date-minutes-id"
                                                                            className="start-date-picker"
                                                                            format="mm"
                                                                            value={values.startTimeMinutes}
                                                                            onChange={(value) =>
                                                                                setFieldValue(
                                                                                    `startTimeMinutes`,
                                                                                    value
                                                                                )
                                                                            }
                                                                            autoOk
                                                                            InputLabelProps={{ shrink: true }}
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change time",
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box style={styles.fieldError}>
                                                                        <ErrorMessage name="startTimeMinutes" />
                                                                    </Box>
                                                                </div>
                                                                <div className="do-am-dropdown">
                                                                    <Select
                                                                        value={values.startTimeMeridian}
                                                                        data-test-id="start-time-meridian"
                                                                        options={[{ label: "a.m.", value: "a.m." }, { label: "p.m.", value: "p.m." }]}
                                                                        onChange={(value) => setFieldValue('startTimeMeridian', value && { label: value.label, value: value.value })}
                                                                        classNamePrefix="an-simple-selectUSD" className="selectUSD"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="form-grid-item">
                                                    <div className="do-time-wrapper">
                                                        <div className="time-title">End Time</div>
                                                        <div>
                                                            <div className="do-time">
                                                                <div>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardTimePicker
                                                                            margin="normal"
                                                                            id="time-picker"
                                                                            data-test-id="do-end-date-hours-id"
                                                                            className="start-date-picker"
                                                                            value={values.endTimeHours}
                                                                            onChange={(value) =>
                                                                                setFieldValue(
                                                                                    `endTimeHours`,
                                                                                    value
                                                                                )
                                                                            }
                                                                            autoOk
                                                                            format="hh"
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change time",
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box style={styles.fieldError}>
                                                                        <ErrorMessage name="endTimeHours" />
                                                                    </Box>
                                                                </div>
                                                                <div>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardTimePicker
                                                                            margin="normal"
                                                                            data-test-id="do-end-date-minutes-id"
                                                                            className="start-date-picker"
                                                                            format="mm"
                                                                            id="time-picker"
                                                                            value={values.endTimeMinutes}
                                                                            onChange={(value) =>
                                                                                setFieldValue(
                                                                                    `endTimeMinutes`,
                                                                                    value
                                                                                )
                                                                            }
                                                                            autoOk
                                                                            KeyboardButtonProps={{
                                                                                "aria-label": "change time",
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box style={styles.fieldError}>
                                                                        <ErrorMessage name="endTimeMinutes" />
                                                                    </Box>
                                                                </div>
                                                                <div className="do-am-dropdown">
                                                                    <Select
                                                                        options={[{ label: "a.m.", value: "a.m." }, { label: "p.m.", value: "p.m." }]}
                                                                        value={values.endTimeMeridian}
                                                                        onChange={(value) => setFieldValue('endTimeMeridian', value && { label: value.label, value: value.value })}
                                                                        classNamePrefix="an-simple-selectUSD" className="selectUSD" data-test-id="end-time-meridian"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <Box style={styles.fieldError}>
                                                                <ErrorMessage name="endTimeValidation" />
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} className="form-grid-item">
                                            <Button disableRipple className="do-options" data-test-id="do-options" onClick={(event) => this.handleOptionPopoverOpen(event)}>{values.options.label}</Button>
                                            <Popover
                                                id="mouse-over-popover"
                                                keepMounted
                                                anchorEl={this.state.anchorElOnDO}
                                                open={Boolean(this.state.anchorElOnDO)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                onClose={this.handleOptionPopoverClose}
                                                data-test-id="copy-days-dropdown"
                                            >
                                                <Box>
                                                    <FormGroup>
                                                        {this.DateOverridesOptions.map(options => (
                                                            <FormControlLabel
                                                                control={<Radio
                                                                    disableRipple
                                                                    disableTouchRipple
                                                                    onChange={(event, checked) => this.handleOptionsChange(checked, options, values, setFieldValue)}
                                                                    disableFocusRipple
                                                                    name="options"
                                                                    data-test-id="options"
                                                                    checked={values.options.value === options.value}
                                                                    checkedIcon={<img src={radioChecked} style={{ borderRadius: 0 }} />}
                                                                    icon={<img src={radioUncheck} style={{ borderRadius: 0 }} />}
                                                                />}
                                                                style={styles.heading}
                                                                data-test-id="copy-form-group"
                                                                label={options.label}
                                                            />
                                                        ))}

                                                    </FormGroup>
                                                </Box>
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                    <Button type="submit" className="add-date-overrides" fullWidth>
                                        Add
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </OverrideFormWrapper>
                </DialogContent>
            </Dialog>
        )
    }
    renderCustomDialog = () => {
        return (
            <Dialog
                open={this.state.addCustomOptionsDOModelOpen}
                scroll="body"
                className="date-override-dialog"
                maxWidth="xs"
            >
                <DialogContent dividers={false} style={styles.dateOverridesContentCustom}>
                    <Typography variant="h5" style={styles.dialogHeading}>Custom Recurrence</Typography>
                    <OverrideFormWrapperCustom>
                        <Formik
                            initialValues={this.state.CustomDOInitialValue}
                            validationSchema={this.CustomOverridesValidationSchema}
                            onSubmit={this.handleCustomFormDatSubmit}
                            data-test-id="custom-override-form"
                        >
                            {({ values, handleSubmit, setFieldValue, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container className="dateOverridesFormSection">
                                        <Grid item xs={12} className="form-grid-item">
                                            <Typography className="title-field">Repeat Every</Typography>
                                            <Select
                                                value={values.repeatEvery}
                                                options={this.DateOverridesCustomOptions}
                                                name="repeatOn"
                                                classNamePrefix="an-simple-select"
                                                onChange={(value) => { setFieldValue("repeatEvery", value && { label: value.label, value: value.value }) }}
                                                className="selectMenu selectedMenuDropdown"
                                                styles={customStylesProperty}
                                                data-test-id="loanSelectMenu"
                                            />
                                        </Grid>
                                        {values.repeatEvery.value === 0 && (
                                            <Grid item xs={12} className="form-grid-item repeat-on">
                                                <Typography className="title-field">Repeat On</Typography>
                                                <Box className="day-wrapper">
                                                    {this.state.slotsDataResponse?.length > 0 && this.state.slotsDataResponse.map(slots => slots.availability !== "Unavailable" && (
                                                        <Box
                                                            className={`day ${this.state.selectedRepeatOn.includes(slots.day) && 'selected'}`}
                                                            data-test-id="day-selected"
                                                            title={slots.day} onClick={() => this.handleRepeatOn(slots.day)}>
                                                            {
                                                                slots.day.split('')[0]
                                                            }
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} className="form-grid-item">
                                            <Typography className="title-field">Ends</Typography>
                                            <Box>
                                                <FormControlLabel
                                                    control={<Radio
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        name="options"
                                                        data-test-id="never-option"
                                                        checked={values.options === "never"}
                                                        onChange={() => setFieldValue('options', 'never')}
                                                        checkedIcon={<img src={radioChecked} style={{ borderRadius: 0 }} />}
                                                        icon={<img src={radioUncheck} style={{ borderRadius: 0 }} />}
                                                    />}
                                                    style={styles.heading}
                                                    data-test-id="never-form-group"
                                                    label="Never"
                                                />
                                            </Box>
                                            <Box className="endDateContainer">
                                                <FormControlLabel
                                                    control={<Radio
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        name="options"
                                                        checked={values.options === "endDate"}
                                                        onChange={() => setFieldValue("options", 'endDate')}
                                                        checkedIcon={<img src={radioChecked} style={{ borderRadius: 0 }} />}
                                                        data-test-id="endDate-option"
                                                        icon={<img src={radioUncheck} style={{ borderRadius: 0 }} />}
                                                    />}
                                                    style={styles.heading}
                                                    data-test-id="endDate-form-group"
                                                    label={
                                                        <>
                                                            <div className="do-end-date-wrapper">
                                                                <span>On</span>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DatePicker
                                                                        onChange={(newDate) => setFieldValue("endDate", newDate)}
                                                                        value={values.endDate}
                                                                        className="do-end-date"
                                                                        format="MMM dd, yyyy"
                                                                        autoOk
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </>
                                                    }
                                                />
                                                <Box style={styles.fieldError}>
                                                    <ErrorMessage name="endDate" />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={<Radio
                                                        disableRipple
                                                        data-test-id="occurence-option"
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        name="options"
                                                        checked={values.options === "occurence"}
                                                        onChange={() => setFieldValue('options', 'occurence')}
                                                        checkedIcon={<img src={radioChecked} style={{ borderRadius: 0 }} />}
                                                        icon={<img src={radioUncheck} style={{ borderRadius: 0 }} />}
                                                    />}
                                                    style={styles.heading}
                                                    data-test-id="occurence-form-group"
                                                    label={
                                                        <>
                                                            <div className="occurence">
                                                                <span>After</span>
                                                                <span><TextField name="occurence" value={values.occurence} onChange={handleChange} type="number" /></span>
                                                                <span>occurence</span>
                                                            </div>
                                                        </>
                                                    }
                                                />
                                                <Box style={styles.fieldError}>
                                                    <ErrorMessage name="occurence" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box className="action-container">
                                        <Button type="button" className="cancel-btn" data-test-id="cancel-custom-form" onClick={this.handleAddOverridesCustomOptionModalClose}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" color="primary">
                                            Done
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </OverrideFormWrapperCustom>
                </DialogContent>
            </Dialog>
        )
    }
    conditionalGridRender = (condition: boolean, truthyValue: GridSize, falsyValue: GridSize) => {
        return condition ? truthyValue : falsyValue
    }
    conditionalImgRender = (condition: boolean, truthyValue: string, falsyValue: string) => {
        return condition ? truthyValue : falsyValue
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainWrapper>
                <ThemeProvider theme={this.theme}>
                {this.renderSnackBar()}
                <div className="pagedashboard-wrapper2">
                        <div style={{ justifyContent: 'end' }} className="header-wrapper" >
                            {this.renderProfile()}
                        </div>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={8} className="slots-wrapper">
                            <div className="copyAndAddImageParent" style={styles.copyAndAddImageParent}>
                                <span
                                    style={{ display: 'flex', alignItems: 'center' }}>
                                    <h2
                                        style={styles.heading}>Set your weekly consultation calls availability hours</h2>
                                    {!this.state.isEditable && <img data-test-id="handle-edit-on-image"
                                        onClick={() => this.handleEdit()} style={styles.tableCellImage} src={editSlot} />}
                                </span>
                                <Button data-test-id='edit-plans' className="editPlansBtn" onClick={() => this.navigateRoute("EditPlan")} >Edit Plans</Button>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={this.getInitialValues()}
                                validationSchema={this.validationSchema}
                                onSubmit={this.handleSlotsSubmit}
                                data-test-id="schedule-form"
                            >
                                {({
                                    values,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        {this.weekDays.map((weekName) => (
                                            <Grid className="dayGridContainer" container spacing={2}>
                                                {this.state.isEditable && (
                                                    <Grid item xs={3} sm={1}>
                                                        <div data-test-id="form-checkbox" onClick={() => setFieldValue(`${weekName}.selected`, !values[weekName].selected, true)}>
                                                            <img style={{ borderRadius: 0 }} src={this.conditionalImgRender(values[weekName].selected, check, uncheck)} />
                                                        </div>
                                                    </Grid>
                                                )}
                                                <Grid item xs={6} sm={3}>
                                                    <div className="weekName">{weekName}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={this.conditionalGridRender(this.state.isEditable, 6, 7)} className="slot-section">
                                                    <FieldArray
                                                        name={`${weekName}.timeSlot`}
                                                        data-test-id="field-array"
                                                        render={(arrayHelpers) => {
                                                            this.addBtnRef[weekName].current = arrayHelpers;
                                                            return (
                                                                <div>
                                                                    {values[weekName] &&
                                                                        values[weekName].timeSlot.length > 0 ? (
                                                                        values[weekName].timeSlot.map((weekData, index) => (
                                                                            <div key={index}>
                                                                                <Grid container spacing={2} className="time-grid-container">
                                                                                    <Grid item xs={this.conditionalGridRender(this.state.isEditable,4, 5)}>
                                                                                        <MuiPickersUtilsProvider
                                                                                            utils={DateFnsUtils}
                                                                                        >
                                                                                            <KeyboardTimePicker
                                                                                                margin="normal"
                                                                                                id="time-picker"
                                                                                                data-test-id="start-date-id"
                                                                                                className="start-date-picker"
                                                                                                disabled={!this.state.isEditable}
                                                                                                value={
                                                                                                    values[weekName].timeSlot[index]
                                                                                                        .startTime
                                                                                                }
                                                                                                onChange={(value) =>
                                                                                                    setFieldValue(
                                                                                                        `${weekName}.timeSlot.[${index}].startTime`,
                                                                                                        value
                                                                                                    )
                                                                                                }
                                                                                                autoOk
                                                                                                KeyboardButtonProps={{
                                                                                                    "aria-label": "change time",
                                                                                                }}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                        <Box style={styles.fieldError}>
                                                                                            <ErrorMessage
                                                                                                name={`${weekName}.timeSlot.[${index}].startTime`}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={this.conditionalGridRender(this.state.isEditable,1, 2)} className="dash">-</Grid>
                                                                                    <Grid item xs={this.conditionalGridRender(this.state.isEditable,4, 5)}>
                                                                                        <MuiPickersUtilsProvider
                                                                                            utils={DateFnsUtils}
                                                                                        >
                                                                                            <KeyboardTimePicker
                                                                                                margin="normal"
                                                                                                id="time-picker"
                                                                                                data-test-id="end-date-id"
                                                                                                className="end-date-picker"
                                                                                                disabled={!this.state.isEditable}
                                                                                                value={
                                                                                                    values[weekName].timeSlot[index]
                                                                                                        .endTime
                                                                                                }
                                                                                                autoOk
                                                                                                onChange={(value) =>
                                                                                                    setFieldValue(
                                                                                                        `${weekName}.timeSlot.[${index}].endTime`,
                                                                                                        value
                                                                                                    )
                                                                                                }
                                                                                                KeyboardButtonProps={{
                                                                                                    "aria-label": "change time",
                                                                                                }}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                        <Box style={styles.fieldError}>
                                                                                            <ErrorMessage
                                                                                                name={`${weekName}.timeSlot.[${index}].endTime`}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    {this.state.isEditable && (
                                                                                        <Grid item xs={3}>
                                                                                            <button
                                                                                                type="button"
                                                                                                data-test-id="delete-slot-btn"
                                                                                                className="deleteSlotBtn"
                                                                                                onClick={() =>
                                                                                                    arrayHelpers.remove(index)
                                                                                                }
                                                                                            >
                                                                                                <img src={deleteSlot} alt="delete" />
                                                                                            </button>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <Box className="unavailable">unavailable</Box>
                                                                            <Box style={styles.fieldError}>
                                                                                <ErrorMessage name={`${weekName}.timeSlot`} />
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2} className="action-grid">
                                                    <Box>
                                                        <Button
                                                            type="button"
                                                            className="addSlotBtn"
                                                            data-test-id="add-slot-btn"
                                                            onClick={() => {
                                                                this.addSlots(setFieldValue,weekName, values)
                                                            }}
                                                        >
                                                            <img data-test-id="add-new-slot" src={addFile} alt="globe" />
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            className="addSlotBtn"
                                                            data-test-id="copy-btn"
                                                            onClick={(event) => this.handleCopyPopoverOpen(event, weekName)}
                                                        >
                                                            <img data-test-id="add-new-slot" src={fileCopy} alt="globe" />
                                                        </Button>
                                                        <Popover
                                                            id="mouse-over-popover"
                                                            keepMounted
                                                            open={Boolean(this.state.anchorElOnCustom)}
                                                            anchorEl={this.state.anchorElOnCustom}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            data-test-id="copy-days-dropdown"
                                                            onClose={this.handleCopyPopoverClose}
                                                        >
                                                            <Box style={{ padding: '1rem' }}>
                                                                <Typography style={{ ...styles.heading, marginLeft: '0.5rem' }}>Copy times to</Typography>
                                                                <FormGroup style={{ marginTop: '0.5rem' }}>
                                                                    {this.weekDays.map(daysCopyDay => (
                                                                        <FormControlLabel
                                                                            control={<Checkbox
                                                                                disableRipple
                                                                                disableTouchRipple
                                                                                disableFocusRipple
                                                                                data-test-id={`copy-check`}
                                                                                name={daysCopyDay}
                                                                                checked={this.state.copyTimesTo.includes(daysCopyDay)}
                                                                                onChange={(event, checked) => this.handleCopySlot(checked, daysCopyDay, values)}
                                                                                checkedIcon={<img src={check} style={{ borderRadius: 0 }} />}
                                                                                icon={<img src={uncheck} style={{ borderRadius: 0 }} />}
                                                                            />}
                                                                            style={styles.heading}
                                                                            data-test-id="copy-form-group"
                                                                            label={daysCopyDay}
                                                                        />
                                                                    ))}

                                                                </FormGroup>
                                                            </Box>
                                                        </Popover>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))}
                                            {this.state.isEditable && <Box style={styles.wrapperContainer}>
                                                <Box style={{ ...styles.buttonWrapper, marginTop: '1rem' }}>
                                                    <Button data-test-id="form-cancel" className="cancelEditSlotsBtn" type="button" onClick={this.cancelEditSlotsForm}>Cancel</Button>
                                                    <Button className="saveSlotsBtn" type="submit" data-test-id="save-slots">Save</Button>
                                                </Box>
                                            </Box>}

                                            <Modal
                                                open={this.state.slotsCancelModal}
                                                keepMounted
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                            >
                                                <>
                                                    <Box style={{ ...styles.boxWrapper, justifyContent: 'center', height: "100vh", width: "100vw" }}>
                                                        <Box style={{ ...styles.cancelModal, flexDirection: "column" }}>
                                                            <Typography variant="h6" style={{ width: '100%', textAlign: 'center' }}>
                                                                Are you sure you want to cancel?
                                                            </Typography>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-around",
                                                                    width: '90%',
                                                                    marginTop: '1.9rem',
                                                                    gap: "30px",
                                                                    margin: "20px 40px 0 40px"
                                                                }}
                                                            >
                                                                <Button style={{ ...styles.confirmCancelBtn, fontWeight: 800 }} data-test-id="cancel-slots-cancel" onClick={() => this.cancelEditSlots(resetForm)}>Yes</Button>
                                                                <Button style={{ ...styles.cancelCancelBtn, fontWeight: 800 }} data-test-id="cancel-slots-confirm" onClick={this.cancelCancelEditSlots}>No</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            </Modal>
                                    </form>
                                )}
                            </Formik>                            
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="calendar-container">
                                <Typography variant="h5" style={styles.heading}>Add date overrides</Typography>
                                {this.formatEventScheduledDates().length > 0 ? (
                                    <CalendarWrapper style={{ marginTop: '1rem' }}>
                                        <Calendar
                                            value={this.state.selectedOverrideDay}
                                            data-test-id="side-calendar"
                                            onChange={(value) => this.handleSelectedOverrideDayChange(value)}
                                            calendarClassName="date-overrides-calendar"
                                            colorPrimary="#2DE6D5"
                                            customDaysClassName={this.formatEventScheduledDates()}
                                        />
                                    </CalendarWrapper>
                                ) : (
                                    <Typography variant="h5" style={styles.description}>Add dates when your availibility changes from your weekly hours</Typography>
                                )}
                                <Button className="add-override-btn" data-test-id="add_a_data" onClick={this.handleAddOverridesModalOpen}>Add a date override</Button>
                                <Box className="event-list-container">
                                    {this.state.SingleDayEventData?.length > 0 && this.state.SingleDayEventData.map((value) => (
                                        <>
                                            <Box className="event-wrapper">
                                                <Box className="event-details">
                                                    <Box className="event-title">{this.formateDateForEventList(value.attributes.event_date)}</Box>
                                                    <Box className="description">{value.attributes.title}</Box>
                                                    {value.attributes.unavailable ? (
                                                        <Box className="time">All Day</Box>
                                                    ) : (
                                                        <Box className="time">{this.displayTimeConverter(value.attributes.start_time, value.attributes.end_time)}</Box>
                                                    )}
                                                </Box>
                                                <Box className="event-edit">
                                                    <img src={editSlot} alt="edit" data-test-id="edit-slot-btn" onClick={() => this.handleEditDOForm(value)} />
                                                    <img style={{marginLeft: "10px"}} data-test-id="delete-slot-btn" src={deleteSlot} alt="edit" onClick={() => this.handleDeleteEventConfirm()} />
                                                </Box>
                                            </Box>
                                            <Modal
                                                open={this.state.deleteConfirm}
                                                keepMounted
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                            >
                                                <>
                                                    <Box style={{ ...styles.boxWrapper,  height: "100vh", justifyContent: 'center', width: "100vw" }}>
                                                        <Box style={{ ...styles.cancelModal, flexDirection: "column" }}>
                                                            <Typography variant="h6" style={{ width: '100%', textAlign: 'center' }}>
                                                                Are you sure you want to Delete?
                                                            </Typography>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    margin: "20px 40px 0 40px",
                                                                    width: '90%',
                                                                    marginTop: '1.9rem',
                                                                    justifyContent: "space-around",
                                                                    gap: "30px",
                                                                }}
                                                            >
                                                                <Button style={{ ...styles.confirmCancelBtn, fontWeight: 800 }} data-test-id="delete-event-cancel" onClick={() => this.DeleteEvent(value.id)}>Yes</Button>
                                                                <Button style={{ ...styles.cancelCancelBtn, fontWeight: 800 }} data-test-id="delete-event-confirm" onClick={this.cancelDelete}>No</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            </Modal>
                                        </>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <Box>
                    <Dialog
                        open={this.state.slotsSuccessModal}
                        data-test-id="save-form-success-modal"
                        onClose={this.slotsSuccessModalClose}
                        className="schedule-section-slots-success-dialog"
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: styles.dialogSuccessPaperStyle }}
                        >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" style={{...styles.dialogSuccessContent, fontWeight: 500}}>
                                Your availability hours are successfully saved!
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
                {this.renderOverrideDialog()}
                {this.renderCustomDialog()}
                </ThemeProvider>
            </MainWrapper>
        );
        // Customizable Area End
    }

}

// Customizable Area Start

const MainWrapper = styled(Box)({
    width: "100%",
    "& .weekName": {
        fontSize: "20px",
        color: "white"
    },
    "& .unavailable": {
        paddingLeft: "30px",
        color: "#ffffffb3"
    },
    "& .dayGridContainer": {
        borderBottom: "1px solid white",
        padding: "15px 0",
        alignItems: "center"
    },
    "& .dayGridContainer:last-child": {
        borderBottom: "none",
    },
    "& .slot-section": {
        flexGrow: 1
    },
    "& .addSlotBtn": {
        border: "none",
        background: "transparent"
    },
    "& .deleteSlotBtn": {
        border: "none",
        background: "transparent"
    },
    "& .deleteSlotBtn>img": {
        cursor: "pointer"
    },
    "& .addSlotBtn>img": {
        width: "14px",
        cursor: "pointer"
    },
    "& .action-grid > div": {
        display: "flex"
    },
    "& .slots-wrapper": {
        borderRight: '2px solid rgba(192, 182, 182, 0.1)',
        paddingRight: "40px"
    },
    "& .calendar-wrapper": {
        paddingRight: "10px"
    },
    "& .saveSlotsBtn": {
        backgroundColor: "#C9F9F6",
        height: "52px",
        width: "186px",
        color: "#3C3C50",
        borderRadius: "10px",
        fontSize: "18px",
        fontWeight: "bolder",
        marginLeft: "10px",
    },
    "& .cancelEditSlotsBtn": {
        color: "#C9F9F6",
        height: "52px",
        marginRight: "10px",
        width: "186px",
        borderRadius: "10px",
        background: "transparent",
        fontWeight: "400",
        fontSize: "18px",
        border: "1px solid #C9F9F6"
    },
    "& .editPlansBtn": {
        width: "130px",
        backgroundColor: "#C9F9F6",
        height: "42px",
        color: "#3C3C50",
        borderRadius: "10px",
        fontWeight: "bolder",
        fontSize: "12px",
        marginLeft: "15px"
    },
    "& .start-date-picker .MuiInputAdornment-root": {
        display: "none"
    },
    "& .end-date-picker .MuiInputAdornment-root": {
        display: "none"
    },
    "& #time-picker": {
        height: "40px",
        borderRadius: "12px",
        border: "1px solid white",
        padding: "15px",
    },
    "& #time-picker-helper-text": {
        display: "none"
    },
    "& .time-grid-container": {
        alignItems: "center"
    },
    "& .time-grid-container .dash": {
        textAlign: "center",
        color: "white"
    },
    "& .calendar-container": {
        display: "flex",
        flexDirection: "column",
        marginLeft: "10px",
    },
    "& .add-override-btn": {
        width: "max-content",
        border: "1px solid #CAF5F4",
        borderRadius: "5px",
        color: "#CAF5F4",
        padding: "10px 25px",
        marginTop: '1rem', 
        fontFamily: "Poppins", 
        textTransform: 'none'
    },
    "& .event-list-container": {
        marginTop: "20px",
        height: "400px",
        overflow: "auto",
        overflowX: "hidden"
    },
    "& .event-wrapper": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        lineHeight: "25px",
    },
    "& .event-details": {
        margin: "8px 0",
    },
    "& .event-wrapper:not(:last-child)": {
        borderBottom: "1px solid #c4c4cab3"
    },
    "& .event-wrapper .event-title": {
        color: "#C4C4CA",
        fontSize: "16px",
        marginBottom: "10px",
    },
    "& .event-wrapper .description": {
        color: "#c4c4cab3",
        fontSize: "16px",
    },
    "& .event-wrapper .time": {
        color: "#c4c4cab3",
        fontSize: "16px",
    },
    "& .event-wrapper .event-edit img": {
        borderRadius: 0,
        cursor: "pointer"
    },
    "& .date-override-dialog .MuiDialog-paperWidthXs": {
        background: "#3B3C50 !important",
        borderRadius: "15px"
    },
    "& .date-overrides-calendar": {
        background: "#3B3C50 !important"
    },
    "@media(max-width: 960px)": {
        "& .slots-wrapper": {
            borderBottom: '2px solid rgba(192, 182, 182, 0.1)',
            borderRight: 'none',
            paddingBottom: "40px"
        },
        "& .calendar-container": {
            alignItems: "center",
        },
        "& .cancelEditSlotsBtn": {
            fontSize: "14px",
        },
    },
    "@media(max-width: 500px)": {
        "& .copyAndAddImageParent": {
            flexWrap: "wrap",
            gap: "20px",
        }
    }
})

const CalendarWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    "& .event-day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)": {
      position: "relative",
    },
    "& .event-day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      height: "4px",
      aspectRatio: "1",
      backgroundColor: "#2DE6D5",
      borderRadius: "50%",
    },
    "& .Calendar__monthSelectorAnimationWrapper": {
        borderTop: "none !important",
    },
    "& .date-overrides-calendar": {
      backgroundColor: "#2a2a3b !important",
    },
    "& .Calendar__weekDays": {
      color: "#C4C4CA !important",
    },
    "& .Calendar__day, & .Calendar__monthText, & .Calendar__yearText": {
      color: "#fff !important",
    },
    "& .Calendar__day.-disabled": {
        opacity: "30% !important",
    },
    "& .Calendar__monthYear.-shown>*:hover, & .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown>*:focus, & .Calendar__monthYear>*.-activeBackground": {
      backgroundColor: "#2DE6D5 !important",
      color: "#000 !important",
    },
    "& .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, & .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText": {
      color: "#000 !important",
    },
    "& .Calendar__yearSelectorWrapper::after": {
      backgroundImage: "linear-gradient(to bottom, #3c3c50, #3c3c50 10%, rgba(245, 245, 245, 0)) !important",
    },
    "& .Calendar__yearSelectorWrapper::before": {
      backgroundImage: "linear-gradient(to top, #3c3c50, #3c3c50 10%, rgba(245, 245, 245, 0)) !important",
    },
    "& .Calendar__monthArrow": {
      fontSize: "5px !important",
      backgroundImage: `url(${arrowDown})`,
    },
    "& .Calendar__monthArrowWrapper.-left": {
      transform: "rotate(270deg)",
    },
    "& .Calendar__monthArrowWrapper.-right": {
      transform: "rotate(90deg)",
    },
    "& .Calendar__header": {
      width: "100% !important",
      justifyContent: "center !important",
    },
    "& .Calendar__monthYearContainer": {
      width: "150px !important",
      flex: "none !important",
    },
    "& .Calendar__monthSelector, & .Calendar__yearSelector": {
      backgroundColor: "#3c3c50 !important",
    },
    "& .Calendar__yearSelectorText, & .Calendar__monthSelectorItemText": {
      color: "white !important",
    },
    "& .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd": {
      color: "#3c3c50 !important",
    },
    "& .Calendar__sectionWrapper": {
      minHeight: "27em !important",
    },
  });

const OverrideFormWrapper = styled(Box)({
    "& .dateOverridesFormSection": {
        marginTop: "15px",
        width: "100%",
    },
    "& .do-unavailable": {
        fontSize: "14px",
        color: "#FFFFFF"
    },
    "& .switch-container .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "green"
    },
    "& .do-time-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .do-time": {
        display: "flex"
    },
    "& #time-picker": {
        height: "40px",
        width: "70px",
        borderRadius: "12px",
        border: "1px solid white",
        padding: "15px",
        marginRight: "15px"
    },
    "& .start-date-picker .MuiInputAdornment-root": {
        display: "none"
    },
    "& .switch-container": {
        display: "flex", 
        justifyContent: "space-between"
    },
    "& .form-grid-item": {
        margin: "10px 0"
    },
    "& .time-title": {
        color: "white",
        fontSize: "16px"
    },
    "& .do-options": {
        color: "#814FF9",
        fontSize: "14px"
    },
    "& .form-title": {
        width: "100%",
    },
    "& .form-title .MuiInputBase-input": {
        color: "white"
    },
    "& #time-picker-helper-text": {
        display: "none"
    },
    "& .do-am-dropdown": {
        display: "flex",
        alignItems: "center"
    },
    "& .add-date-overrides": {
        color: "#3C3C50",
        backgroundColor: "#C9F9F6",
        height: "52px",
        fontSize: "20px",
        fontWeight: "bold",
    }
})
  
const OverrideFormWrapperCustom = styled(Box)({
    "& .title-field": {
        color: "white",
        opacity: "70%",
        fontSize: "14px !important",
        paddingBottom: "10px !important",
        marginTop: "25px"
    },
    "& .action-container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        marginTop: "30px"
    },
    "& .action-container .cancel-btn": {
        color: "white",
        marginRight: "15px"
    },
    "& .repeat-on .day-wrapper": {
        display: "flex",
        gap: "10px"
    },
    "& .repeat-on .day": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        borderRadius: "40%",
        backgroundColor: "#FFF",
        color: "#00000080",
        opacity: "50%",
        fontSize: "20px",
        cursor: "pointer"
    },
    "& .repeat-on .day.selected": {
        color: "black",
        backgroundColor:"#2DE2D5",
        opacity: "100%"
    },
    "& .occurence": {
        display: "flex",
        alignItems: "center",
        gap: "15px"
    }, 
    "& .occurence .MuiInputBase-input": {
        width: "50px",
        border: "1px solid white",
        borderRadius: "10px",
        height: "35px",
        color: "white",
        padding: "10px"
    },
    "& .do-end-date-wrapper": {
        display: "flex",
        alignItems: "center"
    },
    "& .do-end-date input": {
        width: "150px",
        marginLeft: "20px",
        borderRadius: "10px",
        border: "1px solid white",
        height: "35px",
        padding: "10px",
    },
    "& .endDateContainer": {
        margin: "15px 0"
    }
})
type IStyle = { [key: string]: React.CSSProperties };
const styles :IStyle = {
    copyAndAddImageParent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
      }as React.CSSProperties,
      image: {
        height: "35px",
        width: "35px",
        borderRadius: "50%",
      },
      textContainers: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      }as React.CSSProperties,
      nameStyle: {
        fontSize: "14px",
        color: "white",
      },
      email: {
        fontSize: "12px",
        marginTop:"-8px",
        color: "#ffffff78",
      },
      noImg: {
        height: "35px",
        width: "35px",
        borderRadius: "50%",
        color: "#fff",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        backgroundColor: "2CE2D5",
      }as React.CSSProperties,
    availabilityParent: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'nowrap' as const,
        flexDirection: 'column' as const
    },
    mainBox: {
        top: '5%',
        left: '45%',
        backgroundColor: '#2b2a38',
        padding: '2rem 2rem',
        width: '23%',
        position: 'absolute'
    },
    cancelModal: {
        color: "#FFFFFF",
        background: "#3D3751",
        padding: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        borderRadius: "12px"
    },
    faqWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    expandBank: {
        border: '2px solid rgba(192, 182, 182, 0.1)',
    },


    tableWrapper: {
        flex: 1,
        marginLeft: '3%',
    },
    heading: {
        color: 'white',
        fontWeight: 600,
        fontFamily: 'poppins',
        opacity: '0.7',
        marginRight: 0
    },
    description: {
        color: 'white',
        fontWeight: 500,
        fontFamily: 'poppins',
        opacity: '0.5',
        marginRight: 0,
        fontSize: "14px",
        marginTop: "20px"
    },
    dialogHeading: {
        color: 'white',
        fontWeight: 500,
        fontFamily: 'poppins',
        marginRight: 0,
        fontSize: "16px",
        marginBottom: "15px",
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '3%',
        borderBottom: '1px solid #ffffff78',
        padding: '2%',
        fontSize: '13px',
        boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
        color: 'white',
    },
    columnHeader: {
        color: '#ffffff80',
    },
    column: {
        flex: 1,
    },
    addTitleInput: {
        marginTop: '1rem',
        padding: '1rem 0',
        fontSize: '1rem'
    },
    tableCellImage: {
        height: '100%',
        width: '25px' as const,
        objectFit: 'cover' as const,
        marginLeft: '1.3rem' as const,
        cursor: 'pointer'
    },
    switchInput: {
        margin: '0',
        marginTop: '0.5rem',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between'
    },
    startEndTimeParent: {
        display: 'flex',
    },
    startEndTime: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '0.5rem'
    },
    StartNdEndSpan: {
        borderRadius: "14px",
        color: "white",
        opacity: '0.7',
        border: "1px solid grey",
        padding: '1rem 2.9rem',
        fontWeight: 600,
        fontFamily: 'poppins',
        background: 'transparent',
        textAlign: 'center' as const
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '34%'
    },
    boxWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '63%'
    },
    daysRound: {
        padding: '0.5rem',
        borderRadius: '37%',
        width: '50px',
        height: '50px'
    },
    columnCellMiddle: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 1rem' as const
    },
    wrapperContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: "15px"
    },
    imageStyle: {
        width: '50px',
        height: '50px',
        objectFit: 'cover'
    },

    fieldError:{
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '18px',
    },
    dialogSuccessPaperStyle: {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    },
    dialogSuccessContent: {
        color: "rgb(255, 255, 255)",
        fontSize: "20px ",
        fontFamily: "Poppins",
        paddingRight: "40px",
        paddingLeft: "40px",
    },
    confirmCancelBtn: {
        border: "1px solid #2DE2D5",
        color: "#CAEEF2",
        height: "52px",
        width: "150px",
    },
    cancelCancelBtn: {
        background: "#CAEEF2",
        color: "#323B4C",
        height: "52px",
        width: "150px",
    },
    dateOverridesContent: {
        background: "#2a2a3b"
    },
    dateOverridesContentCustom: {
        background: "#2a2a3b",
        padding: "40px"
    },
    snakbar: {
        display:"flex", 
        background: "#f7c1ba", 
        color: "#ee5353",
        fontSize: "14px",
        fontFamily: "Poppins",
        paddingRight: "4rem",
        paddingLeft: "4rem",
        minWidth: "440px",
        textAlign: "center",
        borderRadius: "8px",
        height:"45px"
    },
    actionColumn: {
        textAlign: 'center',
    } as React.CSSProperties,
    activeTabStyle: { color: 'white', marginBottom: -1, borderBottom: '3px solid rgb(44, 226, 213)' } as React.CSSProperties,
};

const customStylesProperty: StylesConfig<{label: string, value: number}, false>  = {
    control: (defaultStyles: React.CSSProperties) => ({
        ...defaultStyles,
        backgroundColor: "#2a2a38",
        borderRadius: '8px',
        padding: "8px",
        color: 'rgba(255, 255, 255, 0.60)',
        fontSize: '16px',
        fontFamily: 'Poppins',
        textAlign: "left",
        fontWeight: 500,
    }),
    singleValue: (defaultStyles) => ({
        ...defaultStyles,
        color: 'white'
    })
};

// Customizable Area End
