import React from "react";

// Customizable Area Start

import { Typography, Box } from "@material-ui/core";
import { logo } from "../../otp-input-confirmation/src/assets"
import OtpInput from 'react-otp-input';
import { tagCross } from "./assets"
import "../../otp-input-confirmation/src/style.css"
import { NavLink } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SuccessCard } from "../../freetrial/src/assets";
// Customizable Area End

import PasscodeLockController, {
    Props,
    configJSON,
} from "./PasscodeLockController";

export default class ConfirmPin extends PasscodeLockController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <style>
                    {`
                    .verification-code input {
                        width: 65px !important;
                    }
                `}
                </style>
                <div className="main-wrapper" id="mainWrapperForConfirmPin" style={{ flexDirection: 'column', justifyContent: 'center' }}>

                    <div className="verification-wrapper" id="verificationWrapperForConfirmPin" style={{ marginTop: '30px' }}>
                        <div id="verifyBoxForVerifyBox" className="verify-box" style={{ height: '100%' }}>
                            <div id="rightLogoForConfirmPin" className="logo-right">
                                <a href="#"><img src={logo} alt="logo" className="logoForConfirmPinScreen" /></a>
                            </div>
                            <div className="verification-block" id="verificationBlockForConfirmPin" data-test-id="englishLanguageScreen">
                                <Box>
                                    <div id="mainWrapperForConfirmPin" className="verification-code">
                                        <Typography id="confirmPinLabel" style={{ ...webStyleConfirmPIN.textLabelsCP }}>Confirm PIN</Typography>
                                        <OtpInput
                                            inputType="number"
                                            data-test-id="OtpField"
                                            value={this.state.confirmPin}
                                            numInputs={4}
                                            shouldAutoFocus={true}
                                            onChange={(value) => this.updateStateForConfirmPin(value, 'change')}
                                            renderInput={(props) => <input {...props} />}
                                            renderSeparator={<span></span>}
                                            inputStyle={{
                                                textAlign: 'center',
                                                height: "62px",
                                                marginRight: "30px",
                                                margin: 'auto',
                                                background: "none",
                                                border: "1px solid #2DE2D5",
                                                opacity: 1,
                                                width: "75%",
                                                borderRadius: "12px",
                                                fontSize: "16px",
                                                color: "#fff",
                                            }}
                                        /></div>
                                    {this.state.isMatch ?

                                        <Box id="errorMsgForConfirmPIN" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                                            <Typography style={{ ...webStyleConfirmPIN.errLineCP }}>PIN does not match</Typography>
                                        </Box>
                                        :
                                        <Box id="ForConfirmPin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '25px' }}>
                                        </Box>
                                    }
                                    <Box>
                                        <Box id="buttonsOfNumbers" style={{ width: '94%', paddingLeft: '1.6rem', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press1" data-test-id="press1" onClick={() => this.updateStateForConfirmPin('1', 'click')}>1</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press2" data-test-id="press2" onClick={() => this.updateStateForConfirmPin('2', 'click')}>2</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press3" data-test-id="press3" onClick={() => this.updateStateForConfirmPin('3', 'click')}>3</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press4" data-test-id="press4" onClick={() => this.updateStateForConfirmPin('4', 'click')}>4</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press5" data-test-id="press5" onClick={() => this.updateStateForConfirmPin('5', 'click')}>5</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press6" data-test-id="press6" onClick={() => this.updateStateForConfirmPin('6', 'click')}>6</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press7" data-test-id="press7" onClick={() => this.updateStateForConfirmPin('7', 'click')}>7</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press8" data-test-id="press8" onClick={() => this.updateStateForConfirmPin('8', 'click')}>8</Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press9" data-test-id="press9" onClick={() => this.updateStateForConfirmPin('9', 'click')}>9</Box>
                                        </Box>
                                        <Box style={{ width: '94%', paddingLeft: '1.6rem', paddingTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="backBtn" data-test-id="backBtn" onClick={() => this.backBtnForConfirmation()}>
                                                <img src={tagCross} style={{ paddingRight: '0px', height: '25px' }} />
                                            </Box>
                                            <Box style={webStyleConfirmPIN.numberbtnsCP} id="press0" data-test-id="press0" onClick={() => this.updateStateForConfirmPin('0', 'click')}>0</Box>
                                            <Box style={webStyleConfirmPIN.numberbtns2CP}><NavLink to="/SetupPin">Cancel</NavLink></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <Box id="SuccessPopupForConfirmPin">
                        <Dialog
                            open={this.state.openPinSuccessPopup}
                            keepMounted
                            data-test-id="dialogModel"
                            onClose={this.pinSuccessModal}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{ style: this.dialogStyle }}
                        >
                            <DialogTitle data-test-id="SuccessPopupForConfirmPinTitle" id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                            <DialogContent style={{ padding: '8px 20px' }} id="SuccessPopupForConfirmPinContent">
                                <DialogContentText id="alert-dialog-slide-description" className='dialogContent' data-test-id="SuccessPopupForConfirmPinText">
                                    PIN set up successfully!
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>

                    </Box>
                    <div >
                        <div data-test-id="confirmBtnConfirmPIN" onClick={() => this.confirmBtn()} style={{
                            marginTop: '22px', padding: '5px 50px', cursor: 'pointer', backgroundColor: '#C9F9F6',
                            color: '#3C3C50', fontWeight: 700, borderRadius: '5px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '16rem'
                        }}>Confirm</div></div>
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyleConfirmPIN = {
    numberbtns2CP: { cursor: 'pointer', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    numberbtnsCP: { cursor: 'pointer', background: '#3C3C50', filter: 'drop-shadow(0px 2px 10px rgba(45, 226, 213, 0.20))', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', borderRadius: '30px', alignItems: 'center' },
    textLabelsCP: { color: '#fff', fontWeight: 700, fontSize: '18px', marginLeft: '1rem' },
    errLineCP: { color: 'rgb(213 46 46)', fontWeight: 700, fontSize: '14px' },
};
// Customizable Area End
