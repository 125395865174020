Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE';

exports.showChatApiEndPoint = 'chat/chats';
exports.getChatListApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';
exports.updateNEWChatApiEndPoint = 'chat/chats/leave';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.getTokenApi = "twilio_chat/get_twilio_token?type=user&receiver_id=";
exports.sendMessage = "twilio_chat/send_message?type=user&receiver_id=";
exports.getPlannerConversation = "twilio_chat/create_conversation?type=planner&receiver_id=";
exports.getPlannerMessage = "twilio_chat/send_message?type=planner&receiver_id=";
exports.getPlannerToken = "twilio_chat/get_twilio_token?type=planner";
exports.todayStatus = "Today";
exports.getUserList = "twilio_chat/get_conversation_list";
exports.getUserId = "twilio_chat/get_conversation_client?conversation_id=";
exports.getProfile = "bx_block_formapprovalworkflow/wealth_planners/show_profile"
exports.languageListChat = {
  English: {
    EnterText: "Enter your text",
    Today: "Today",
    Yesterday: "Yesterday",
    ImageUploadSuccess:'Image uploaded successfully!',
    DocumentUploadSuccess:"Document uploaded successfully!",
    UnsupportedFileType:"Unsupported file type",
  },
  Arabic: {
    EnterText: "أدخل النص الخاص بك",
    Today: "اليوم",
    Yesterday: "أمس",
    ImageUploadSuccess:"تم تحميل الصورة بنجاح!",
    DocumentUploadSuccess:"تم تحميل المستند بنجاح!",
    UnsupportedFileType:"نوع الملف غير معتمد",
  }
}
  // Customizable Area End