import React, { useState } from 'react';

const CounterButton = ({
  value,
  buttonHandler,
}: any) => {
  return (
    <div style={styles.container}>
      <button type='button' style={{ ...styles.button, ...styles.minusButton, opacity: value < 1 ? '0.5' : '1', }} onClick={() => { value > 0 && buttonHandler() }}>
        -
      </button>
      <div style={styles.count}>{value}</div>
      <button type='button' style={{ ...styles.button, ...styles.plusButton }} onClick={() => buttonHandler('increment')}>
        +
      </button>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50px',
    backgroundColor: '#2B2D42',
    borderRadius: '8px',
    border: '1px solid #2CE2D5', 
    overflow: 'hidden',
  },
  button: {
    flex: '1',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2CE2D5', 
    fontSize: '20px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  minusButton: {
    borderRight: '1px solid #2CE2D5', 
  },
  plusButton: {
    borderLeft: '1px solid #2CE2D5',
  },
  count: {
    flex: '2',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '20px',
    fontFamily: 'Poppins',
  },
};

export default CounterButton;
