// Customizable Area Start
import {getAuth, GoogleAuthProvider} from "@firebase/auth";
import {initializeApp} from "@firebase/app";
// Customizable Area End

// Customizable Area Start
const firebaseConfig = {
  apiKey: "AIzaSyC8bWjtfiHf7thXZVUWBLr2mmTq0uhtAwM",
  authDomain: "myne-1806c.firebaseapp.com",
  databaseURL: "https://myne-1806c-default-rtdb.firebaseio.com/",
  projectId: "myne-1806c",
  storageBucket: "myne-1806c.appspot.com",
  messagingSenderId: "852028628891",
  appId: "1:852028628891:web:c910cab92ab4921bc7cb3e",
  measurementId: "G-YLPRCKPHXZ"
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  export{auth,provider}
  // Customizable Area End