import React from "react";
// Customizable Area Start
import { NavLink } from "react-router-dom";
import PasscodeLockController, {
    Props,
  } from "./PasscodeLockController";
import "../../forgot-password/src/custom.css";
import OtpInput from 'react-otp-input';
import { logo, backIcon } from "../../forgot-password/src/assets";

// Customizable Area End

export default class ForgotPinOTP extends PasscodeLockController {


    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <div className="main-wrapper">
                <div className="verification-wrapper">
                    <div className="verify-box">
                        <div className="logo-right">
                            <a href="#"><img src={logo} alt="logo" /></a>
                        </div>
                        <div className="verification-block">
                            <NavLink to="/ForgotPin"><h4><img src={backIcon} alt="back-icon" /> OTP Verification</h4></NavLink>
                            <p>Please enter the 4 digit verification code sent to you at <a href="#">{localStorage.getItem('email_for_otp')}</a></p>
                            <div className="verification-code" id="verificationCodeForgotPinOtp">
                                <OtpInput
                                    value={this.state.otp}
                                    data-test-id="OtpField"
                                    inputType="number"
                                    onChange={(value) => this.setState({ otp: value, errorMsg: "" })}
                                    shouldAutoFocus={true}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    numInputs={4}
                                    inputStyle={{
                                        opacity: 0.3,
                                        borderRadius: "12px",
                                        color: "#fff",
                                        height: "62px",
                                        fontSize: "16px",
                                        marginRight: "30px",
                                        background: "none",
                                        width: "62px",
                                        border: "1px solid #2DE2D5",

                                    }}
                                />
                                {this.state.errorMsg && <div id="errorMsgForForgotPinOTP" className="errorMsg">{this.state.errorMsg}</div>
                                }
                            </div> 
                            <span className="recevie-otp" id="receiveOtpForgotPinOtp">Didn't receive the OTP?&nbsp;<button id="resendAgainForBtn" data-test-id="resendOtpBtn" onClick={this.resendOtp} className="resend">Resend again</button></span>
                            <button id="VerifyBtnInForgotPinOTP" className="verify" onClick={this.onOTPSubmit} data-test-id="verifyBtn">Verify</button>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

