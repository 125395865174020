export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const group_Exit = require("../assets/group_Exit.png")
export const group_ActiveSubscriptionIcon = require("../assets/group_ActiveSubscriptionIcon.png")
export const Expired = require("../assets/Expired.png")
export const Pending = require("../assets/Pending.png")
export const Actve = require("../assets/Actve.png")


