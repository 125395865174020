import React from "react";

// Customizable Area Start
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import '../assets/css/myneSettingsBankWallet.css';
import { Link } from 'react-router-dom';
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";
import { CopyIcon, EsyncWebIcon, SyncIcon, YellowcyncIcon, Trash } from "./assets";

export default class MyneBankWalletLoginScreen extends MyneSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fetchKeys(data: any) {
    let obj = {
      bank_name: '',
      bank_logo_url: '',
      bank_account_number: '',
      sort_code: ''
    }
    if (data.attributes) {
      const d = data.attributes;
      obj.bank_name = d.bank_name || d.walletName;
      obj.bank_logo_url = d.bank_logo_url;
      obj.bank_account_number = d.bank_account_number;
      obj.sort_code = d.sort_code;
    }
    return obj
  }
  roundedNewBank = () => {
    return this.state.logoImgsetting === "rtl" ? "bnk-close-rounded bnk-close-rounded-new" : "bnk-close-rounded"
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-bank-wallet-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-bank-wallet-container">
          <div className="bnk-small-text">
          {this.getStringGoalmanagement("Settings")}
            <Link to={'/Dashboard'} className={this.roundedNewBank()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="bnk-bold-text">{this.getStringGoalmanagement("bankLogins")}</div>
          <div className="profile-bank-wallet-container">
            <span className="wallet-count-small-text">{`${this.getStringGoalmanagement("AccountsLinked")}  ${this.state.bankWalletData && this.state.bankWalletData.length > 0 ? this.state.bankWalletData.length : 0}`}</span>
            <div className="bank-card-container">
              {
                this.state.bankWalletData && this.state.bankWalletData.length > 0 &&
                this.state.bankWalletData.map((bankData: {
                 
                    "sequence_nu": number,
                    "id": number,
                    "bank_logo_url":string,
                    "bank_name": string,
                    "bank_account_number": string,
                    "sort_code": string,
                    "connected_on": string,
                    "last_synced": string,
                    "type":string,
                    isSynced: boolean,
                    publicAddress:string
                
                }, index: number) => {
                  const { bank_name, bank_logo_url, bank_account_number, sort_code } =  bankData
                  return (
                    <div className="card" key={bankData.id}>
                      <div className="card-row-1">
                        <div className="title-container">
                          <span className="title-style">{this.getStringGoalmanagement("BankName")}</span>
                          <span className="title-value">{bank_name}</span>
                        </div>
                        <div className="banks-logo-container">
                          <img src={bank_logo_url} alt="bank logo" />
                        </div>
                      </div>

                      {
                        bankData.publicAddress && <div className="card-row-2">
                          <div className="title-container">
                            <span className="title-style">{this.getStringGoalmanagement("PublicAddress")}</span>
                            <span className="title-value">{bankData.publicAddress}</span>
                          </div>
                          <div className="copy-logo">
                            <img src={CopyIcon} alt="copy img" />
                          </div>
                        </div>
                      }

                      {
                        bank_account_number && <div className="card-row-3 bank-account-row">
                          <div className="title-container">
                            <span className="title-style">{this.getStringGoalmanagement("AccountNumber")}</span>
                            <span className="title-value">{bank_account_number}</span>
                          </div>
                          <div className="title-container" dir="ltr">
                            <span className="title-style">Sort Code:</span>
                            <span className="title-value">{sort_code}</span>
                          </div>
                          <div>
                          </div>
                        </div>
                      }

                      <div className="card-row-4">
                        <div className="title-container">
                          <span className="title-style">{this.getStringGoalmanagement("Connectedon")}</span>
                          <span className="title-value">{bankData.connected_on}</span>
                        </div>
                        <div className="title-container">
                          <span className="title-style">{this.getStringGoalmanagement("LastSynced")}</span>
                          <span className="title-value">{bankData.last_synced}</span>
                        </div>

                        <div data-test-id={`sync-icon-click-${index}`} className="copy-logo"
                          onClick={() => {
                            this.setState({
                              currentRefreshedCard: bankData.id,
                            }, () => {
                              if (bankData.isSynced && bankData.id === this.state.currentRefreshedCard) {
                                this.loadBankWalletData(bankData.type,true, bankData.id)
                              }
                            })
                          }}>
                          <img src={bankData.isSynced ? SyncIcon : EsyncWebIcon} alt="sync img" />
                        </div>
                      </div>

                      <div className="card-row-5">
                        {
                          !bankData.isSynced && <div data-test-id={`bank-wallet-resync-btn-${index}`}
                            onClick={() => this.loadBankWalletData(bankData.type,true, bankData.id)} className="resync-btn">
                            <img src={YellowcyncIcon} className="btn-bank-icon" />
                             {this.getStringGoalmanagement("Resync")}
                          </div>
                        }
                        <div data-test-id={`bank-wallet-remove-btn-${index}`} className="resync-btn remove-btn"
                          onClick={() => this.onDeleteBankWallet(bankData.id,bankData.type)}>
                          <img src={Trash} className="btn-bank-icon" />
                           {this.getStringGoalmanagement("Remove")}
                        </div>
                      </div>
                    </div>)
                })
              }
              <div className="card dashed-card">
                <Link to='/AddBankAccounts'>
                  <AddRoundedIcon className="add-card-logo" />
                </Link>
                <span className="add-new-text">{this.getStringGoalmanagement("AddNewBank")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}