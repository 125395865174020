import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface ReminderSnackbarProps {
  open: boolean;
  handleClose: () => void;
  message: string;
}

const ReminderSnackbar: React.FC<ReminderSnackbarProps> = ({ open, handleClose, message }) => {
  // Define inline styles for the SnackbarContent
  const reminderStyle: React.CSSProperties = {
    backgroundColor: 'rgb(201, 249, 246)', // Custom color (orange)
    color:'rgb(60, 60, 81)',
    fontWeight:500,
  };

  const handleSnackbarClose = (_event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleSnackbarClose}
    >
      <SnackbarContent
        style={reminderStyle}
        message={message}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" style={{color:'rgb(60, 60, 81)'}} />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default ReminderSnackbar;