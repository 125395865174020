import React from "react";

// Customizable Area Start
import { Link } from 'react-router-dom'
import '../assets/css/myneSettings.css'
import '../assets/css/MyneSettingsModal.css'
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
// Customizable Area End

import MyneSettingsController, {
  Props,
} from "./MyneSettingsController.web";
import { resetIcon, varrow, deleteIcon } from "./assets";
import { validateImage } from "../../../components/src/common";
import toast from "react-hot-toast";

export default class MyneSettings extends MyneSettingsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  roundedNewSettings = () => {
    return this.state.logoImgsetting === "rtl" ? "close-rounded close-rounded-new" : "close-rounded"
  }
  transformImg = () => {
    return this.state.logoImgsetting === "ltr" ? "" : "transformImg"
  }
  getDOB = () => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(this.loadProfileValues('dob'))) {
      return this.loadProfileValues('dob');
    }
    const [day, month, year] = this.loadProfileValues('dob')?.split("/");
    return `${year}-${month}-${day}`;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="myne-settings-main-container" dir={this.state.logoImgsetting}>
        <div className="setting-profile-container">
          <div className="small-text">
            {this.getStringGoalmanagement("Settings")}
            <Link to={'/Dashboard'} className={this.roundedNewSettings()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" fill="#ffffff" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
            </Link>
          </div>
          <div className="bold-text">{this.getStringGoalmanagement("AccountDetails")}</div>
          <div className="profile-container">
            <div className="image-container">
              <div className="profile-image" >
                {
                  this.state.isEditProfile ?
                    <div style={{ height: 100, width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={this.state.newProfileImage && URL.createObjectURL(this.state.newProfileImage) || this.loadProfileValues('photo')}
                        alt="" data-test-id="handleImageClick" onClick={this.handleImageClick} />
                      <input
                        type="file"
                        accept="image/*"
                        ref={this.fileInputRef}
                        style={{ display: 'none' }}
                        data-test-id="profile-picture-input"
                        onChange={(e: any) => {
                          const profileImageValidation = validateImage(e.target.files[0]);
                          if (!profileImageValidation.isValid) {
                            toast.error(profileImageValidation.errorMessage)
                            return;
                          }
                          if (e.target.files) {
                            this.setState({ newProfileImage: e.target.files[0] })
                          }
                        }}
                      />
                    </div>
                    :
                    <div>
                    {
                      this.loadProfileValues("photo")? 
                        <img src={this.loadProfileValues('photo')} alt="profile image" />
                        :
                          <>
                            <div className="profile-initials" style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "50%",
                              backgroundColor: "2CE2D5",
                              color: "#fff",
                              fontWeight: 600,
                              fontSize: "22px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: 'center',
                              textTransform: "uppercase"
                            }}>
                              {getFirstCharacterFromEachWord(this.state.profileData?.data?.attributes?.full_name?this.state.profileData?.data?.attributes?.full_name.trim():'User')}
                            </div>
                          </> 
                    }
                  </div>
  } 
                  
                  </div>
                  </div>
            <div className="detail-container">
              <div className="detail-section">
                <span className="detail-small-text">{this.getStringGoalmanagement("FullName")}*</span>
                {
                  this.state.isEditProfile ? <span>
                    <input
                      type="text"
                      className="detail-value-text input-box"
                      placeholder={this.getStringGoalmanagement("FullNameplaceholder")}
                      value={this.loadProfileValues('full_name')}
                      data-test-id='profile-name-input'
                      onChange={(e) => this.onProfileDataChange('full_name', e)}
                    />
                  </span>
                    : <span className="detail-value-text">{this.loadProfileValues('full_name')?this.loadProfileValues('full_name'):'N/A'}</span>
                }
              </div>

              <div className="detail-section margin-detail">
                <span className="detail-small-text email-text">{this.getStringGoalmanagement("Email")}</span>
                <span className="detail-value-text email-value">{this.loadProfileValues('email')|| 'N/A'}</span>
              </div>

              <div className="detail-row-content margin-detail">
                <div className="detail-section custom-width">
                  <span className="detail-small-text">{this.getStringGoalmanagement("Mobile")}*</span>
                  {
                    this.state.isEditProfile ? <span>
                      <input
                        type="number"
                        className="detail-value-text input-box mobile-input-box"
                        placeholder={this.getStringGoalmanagement("Contact")}
                        value={this.loadProfileValues('mobile')}
                        data-test-id='profile-mobile-input'
                        onChange={(e) => this.onProfileDataChange('mobile', e)}
                      />
                    </span>
                      : <span className="detail-value-text">{this.loadProfileValues('mobile')?this.loadProfileValues('mobile'):'N/A'}</span>
                  }
                </div>

                <div className="detail-section custom-width">
                  <span className="detail-small-text">{this.getStringGoalmanagement("DateofBirth")}*</span>
                  {
                    this.state.isEditProfile ? <span>
                      <input
                        type="date"
                        className="detail-value-text input-box date-input-box"
                        placeholder={this.getStringGoalmanagement("DateofBirthplaceholder")}
                        value={this.getDOB()}
                        data-test-id='profile-dob-input'
                        min={'1924-01-01'}
                        max={this.getCurrentDate()} 
                        onChange={(e) => this.onProfileDataChange('dob', e)}
                      />
                    </span>
                      : <span className="detail-value-text">{this.loadProfileValues('dob')?this.loadProfileValues('dob'):'N/A'}</span>
                  }
                </div>

              </div>

              <div className="detail-section margin-detail">
                <span className="detail-small-text">{this.getStringGoalmanagement("Address")}*</span>
                {
                  this.state.isEditProfile ? <span>
                    <input
                      type="text"
                      className="detail-value-text input-box address-input-box"
                      placeholder={this.getStringGoalmanagement("Addressplaceholder")}
                      value={this.loadProfileValues('address')}
                      data-test-id='profile-address-input'
                      onChange={(e) => this.onProfileDataChange('address', e)}
                    />
                  </span>
                    : <span className="detail-value-text">{this.loadProfileValues('address')?this.loadProfileValues('address'):'N/A'}</span>
                }

              </div>

            </div>
            <div className="link-container">
              <span data-test-id='edit-text-click' onClick={() => this.onEditClick()} className={`link-text ${this.state.isEditProfile ? 'confirm-link-text' : ''}`}>{`${this.state.isEditProfile ? this.getStringGoalmanagement("Confirmdetails") : this.getStringGoalmanagement("Editdetails")}`}</span>
            </div>

            <Link to={'/ResetPassword'} className="reset-password-container margin-delete-button-new" >
              <div className="reset-password-container-new">
                <span>
                  <img src={resetIcon} height={19} width={19} alt="reset password" />
                </span>
                <div>
                  <span className="bolder-text">{this.getStringGoalmanagement("ResetPassword")}</span>
                </div>
              </div>
              <div>
                <span>
                  <img src={varrow} className={this.transformImg()} height={10} width={5} alt="arrow" />
                </span>
              </div>
            </Link>
            <Link to={'/DeleteAccount'} className="reset-password-container margin-delete-button margin-delete-button-new">
              <div className="reset-password-container-new">
                <span className="delete-icon-span">
                  <img src={deleteIcon} height={19} width={'auto'} alt="reset password" />
                </span>
                <div>
                  <span className="bolder-text">{this.getStringGoalmanagement("DeleteAccount")}</span>
                </div>
              </div>
              <div>
                <span>
                  <img src={varrow} className={this.transformImg()} height={10} width={5} alt="arrow" />
                </span>
              </div>
            </Link>
          </div>
          {
            this.state.isShowConfirmPopup && this.state.isEditProfile &&
            <div className="modal-background" dir="ltr">
              <div className="modal-popup-container">
                <div className="modal-title">{this.getStringGoalmanagement("changesMesseg")}</div>
                <div className="modal-button-container">
                  <div className="modal-button modal-discard" data-test-id='popup-discard-click' onClick={() => this.onDiscardChanges()}>
                    {this.getStringGoalmanagement("Discard")}
                  </div>

                  <div className="modal-button modal-save" data-test-id='popup-save-click' onClick={() => this.onSaveChanges()}>
                    {this.getStringGoalmanagement("Save")}
                  </div>
                </div>

              </div>
            </div>
          }
        </div>
      </div>
      // Customizable Area End
    );
  }
}