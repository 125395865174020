import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import { getStorageData } from "framework/src/Utilities";

interface ILiabilityObject {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    amount: number,
    currency_type: null,
    term: string,
    months: number,
    annual_interest_rate: number,
    monthly_payments: number,
    start_date: string,
    first_payments: number,
    form_type: string,
    property_id: number,
    converted_amount: string,
    converted_currency_type: string,
    converted_monthly_payments: number,
    converted_currency_symbol: string,
    remaining_payment: string,
    remaining_months: number,
    total_months: number
  }
}
interface ICurrency{
  "id": number,
  "currency_name": string,
  "value_respect_to_usd": number,
  "currency_type": string,
  "symbol": string,
}

interface CreditCardResponse {
  transformed_response: Array<{
    id: string;
    type: string;
    attributes: {
      bank_id: number | string;
      account_id: number | string;
      available_balance: number | string;
      converted_amount: number | null;
      card_type: string;
      name: string | null;
      currency_code: string | null;
      bank_logo: string;
      bank_account_number: string | null;
      currency_symbol: string;
    };
  }>;
  currency_symbol: string;
  total_balance: number;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  assetSatyle: {
    width: string,
    whiteSpace: string,
    overflow: string,
    textOverflow: string,
  }
  token: string | null;
  currencyList :ICurrency[],
  isVerticalPopoverOpen: boolean;
  isDeleteConfimationModalOpen: boolean;
  showLiability: string;
  dataList: ILiabilityObject[];
  currentCardId: string;
  selectedCurrency: { value: string, label: string },
  currencyListDropDown: { value: string, label: string }[],
  anchorEl: HTMLAnchorElement | null;
  liabilitiesList:any[]
  language:string,
  direction: string,
  creditCardDetails: CreditCardResponse
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiabilityOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrencyListAPICallId: string = "";
  getLiabilityFormCallId: string = "";
  getAllLiabilityDataCallId: string = "";
  getCreditCardDetailsCallId: string = "";
  putCurrencyAPICallId: string = "";
  deleteLiabilityAssetCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      assetSatyle:{
        width: "50px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: localStorage.getItem('token'),
      currencyList: [],
      isVerticalPopoverOpen: false,
      isDeleteConfimationModalOpen: false,
      showLiability: this.formListTypes.Mortgage,
      dataList: [],
      currentCardId: '',
      selectedCurrency: { value: '', label: '' },
      currencyListDropDown: [],
      anchorEl: null,
      liabilitiesList:[],
      language: "English",
      direction: "ltr",
      creditCardDetails: { transformed_response: [], total_balance: 0, currency_symbol: "$" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getCurrencyList();
    this.getLiabilityListData(this.formListTypes.Mortgage);
    this.getAllLiabilityData();

    let language = await getStorageData('language');
    const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
    if(!language){
      language = "English"
    }
    this.setState({ direction: currentLanguage ,language: language});
  }





  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleGetCurrencyListAPIResponse(apiRequestCallId, responseJson);
    this.handleLiabilityFormData(apiRequestCallId, responseJson);
    this.handleCreditCardDetailsResponse(apiRequestCallId, responseJson);
    this.handleManageCurrencyResponse(apiRequestCallId);

    // Customizable Area End
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  formListTypes = {
    Mortgage: "mortgage",
    PersonalLoan: "personal_loans",
    OtherLoan: "other",
    CreditCard: "creditcard"
  }

  getString = (keyvalue: string) => {
    return configJSON.languageListLiabilityAssets[this.state.language][keyvalue]
  }

  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }

  receiveHandler(responseJson: any, receiveCallback: any) {
    if (responseJson) {
      receiveCallback && receiveCallback();
    }
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const token = await storage.get("authToken")
    const { contentType, method, endPoint, body } = data;
    const liabilityOverviewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    liabilityOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token
      })
    );

    liabilityOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    liabilityOverviewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && liabilityOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(liabilityOverviewMessage.id, liabilityOverviewMessage);
    return liabilityOverviewMessage.messageId;
  };

  navigateRoute=(route: string) =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message) 
  }

  setCurrency = async (value: { value: string, label: string }) => {
    const currency = this.state.currencyList.find((newValue) => {
      return newValue.currency_type === value.value
    })
    if (currency) {
      this.putCurrencyAPICallId = await this.apiCall({
        contentType: "application/json" + '',
        method: "PUT" + '',
        endPoint: "bx_block_profile/profiles/add_currency_to_account",
        body: JSON.stringify({
          currency_id: currency.id
        })
      });
    }
  }

  handleGetCurrencyListAPIResponse(apiRequestCallId:string,responseJson2: {
    list:ICurrency[],
    selection: number
  }){

    if(apiRequestCallId === this.getCurrencyListAPICallId){
      const userSelectedCurrncy = responseJson2.list.find((value) => {
        return value?.id == responseJson2.selection
    })
      this.setState({
        currencyList: responseJson2.list,
        selectedCurrency: userSelectedCurrncy? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` }:{
          value:"",label:""
        },
        currencyListDropDown: responseJson2.list.map((value) => (
          { value: value.currency_type, label: `${value.symbol}${value.currency_type}` }
      ))
      })

      this.getLiabilityListData(this.state.showLiability);
    }
  }

  handleSelectBtn = (val: string) =>{
    if (val !== this.formListTypes.CreditCard) {
      this.setState({ dataList: []}, () => this.getLiabilityListData(val))
    } else {
      this.setState({ dataList: []}, () => this.getCreditCardDetailsData())
    }
    this.setState({showLiability : val})
  }

  handleManageCurrencyResponse(apiRequestCallId:string){
    if(apiRequestCallId === this.putCurrencyAPICallId){
      this.getCurrencyList()
    }
  }

  handleEditLiability = (formData: ILiabilityObject) =>{
    let route = "MortgageForm";
    switch (this.state.showLiability) {
      case this.formListTypes.OtherLoan:
          route = "OtherLoanForm"
          break;
      case this.formListTypes.PersonalLoan:
        route = "PersonalLoanForm"
        break;
      case this.formListTypes.Mortgage:
      default:
        route = "MortgageForm"
        break;
    }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), formData)
  
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message)
  }

  async getCreditCardDetailsData() {
    this.getCreditCardDetailsCallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: `account_block/liabilities_forms/get_card_details` ,
      body: null
    });
  }

  async getLiabilityListData(formType: string) {
    this.getLiabilityFormCallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: `account_block/liabilities_forms?[data][form_type]=${formType}` ,
      body: null
    });
  }
  async getAllLiabilityData() {
    this.getAllLiabilityDataCallId = await this.apiCall({
      contentType:"application/json" + '',
      method: "GET" + '',
      endPoint: `account_block/liabilities_forms` ,
      body: null
    });
  }

  handleLiabilityFormData(apiRequestCallId: string, responseJson: {
    message: string; data : ILiabilityObject[]|any, meta: { message: string },
}) {
  
    if (apiRequestCallId === this.getLiabilityFormCallId) {
      this.receiveHandler(responseJson, () => {
        this.setState({
          dataList: responseJson.data
        })
      })
    }
    if(apiRequestCallId === this.getAllLiabilityDataCallId){
      this.receiveHandler(responseJson, () => {
        this.setState({
          liabilitiesList: responseJson.data
        })
        if(this.deleteLiabilityAssetCallId!=="" && responseJson.data.attributes?.length===0){
          this.props.navigation.navigate("Dashboard");          
        }
      })
    }
    if (apiRequestCallId === this.deleteLiabilityAssetCallId) {
      this.getLiabilityListData(this.state.showLiability);
      this.getAllLiabilityData();
    }
  }

  handleCreditCardDetailsResponse(apiRequestCallId: string, responseJson: CreditCardResponse){
    if (apiRequestCallId === this.getCreditCardDetailsCallId && responseJson && responseJson?.transformed_response.length > 0) {
      this.setState({ creditCardDetails: responseJson})
    }
  }

  handleDeleteLiabilityData = async (liabilityId: string) => {
    if (liabilityId) {
      this.deleteLiabilityAssetCallId = await this.apiCall({
        contentType:"application/json" + '',
        endPoint: `account_block/liabilities_forms/${liabilityId}`,
        method: "DELETE" + '',
        body: null
      })
    }
  }

  getCurrencyList = async () => {
    this.getCurrencyListAPICallId = await this.apiCall({
      method: "GET" + '',
      contentType:"application/json" + '',
      endPoint: "bx_block_profile/profiles/currency_list" ,
      body: null
    });
  }

  handleMenuModal = (event: React.MouseEvent<HTMLElement>, currentCardId: string) => {    
    this.setState({ isVerticalPopoverOpen: true, currentCardId: currentCardId, anchorEl: event?.currentTarget as HTMLAnchorElement })
  }
  handleDocumentMenuModalClose = () => { 
    
    this.setState({
      anchorEl: null,
    })
  }

  // Customizable Area End
}
