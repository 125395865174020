import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/Common";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  code: string | null;
  idToken: string | null;
  error:string | null;
  email:string;
  error2:string;
  envUrl:string;
  // Customizable Area Start
  showEmail:boolean;
  loader:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Applelogin2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  AppleLoginapiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      code: null,
      idToken: null,
      error:null,
      email: '',
      error2: '',
      envUrl:'',
      showEmail:false,
      loader:true,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.AppleLoginapiCallID) {
        
          if (responseJson?.meta?.token) {
            window.localStorage.setItem('token', responseJson.meta.token);
            window.localStorage.setItem('EngagementRequestId', responseJson.data.id);
            this.checkAccountStatus(responseJson)
        }
        if (responseJson?.errors[0].email==='Account not found') {
          this.setState({showEmail:true,loader:false})
      }
        
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start



  async componentDidMount() {
   this.getToken()
   this.setEnvironmentUrl();
  }
  setEnvironmentUrl() {
    // Get the current URL
    const currentUrl = window.location.href;

    // Define the environment URLs
    const environments = {
        uat: "https://myneleap2023-321315-react.b321315.uat.eastus.az.svc.builder.ai/AppleLoginSucess",
        stage: "https://myneleap2023-321315-react.b321315.stage.eastus.az.svc.builder.ai/AppleLoginSucess",
        dev: "https://myneleap2023-321315-react.b321315.dev.eastus.az.svc.builder.cafe/AppleLoginSucess",
        prod: "https://myneleap2023-321315-react.b321315.prod.us-east-1.aws.svc.builder.ai/AppleLoginSucess",
        main: "https://platform.joinmyne.com/AppleLoginSucess"
    };

    // Determine the environment based on the current URL
    let envUrl = '';
    if (currentUrl.includes('uat')) {
        envUrl = environments.uat;
    } else if (currentUrl.includes('stage')) {
        envUrl = environments.stage;
    } else if (currentUrl.includes('dev')) {
        envUrl = environments.dev;
    } else if (currentUrl.includes('prod')) {
        envUrl = environments.prod;
    } else if (currentUrl.includes('joinmyne')) {
      envUrl = environments.main;
    } else {
      envUrl = "Environment not recognized";
    }
    // Set the environment URL in the state
    this.setState({ envUrl });
}

getToken :any=()=>{

  const url =new URL(window.location?.href);
    const hashParams = new URLSearchParams(url.hash.substring(1)); // Remove the '#' at the beginning

    const code = hashParams.get('code');

    const idToken = hashParams.get('id_token');
    const error = hashParams.get('error');

    if (error === 'user_cancelled_authorize') {
      this.setState({ error: error });
    }
    if (code) {
      this.setState({ code: code });
    }

    if (idToken) {

      this.setState({ idToken: idToken }, () => {
        // Your function call here
        this.sendIdTokenToBackend2();
      });

    }
}

sendIdTokenToBackend2() {
 

    const header = {
    
      "Content-Type": 'application/json',
  };
    const postPdfData = apiCall({
      header: header,
      httpBody: {
        "data": {
        "type": "apple_account",
        "attributes": {
          "id_token": this.state.idToken
        }
      }
      },
      url: "/account_block/accounts/web_apple_login",
      httpMethod: "POST",
    });
  this.AppleLoginapiCallID = postPdfData.messageId;
  runEngine.sendMessage(postPdfData.id, postPdfData);
   
 
}
  
componentDidUpdate(prevProps: {}, prevState:S) {
    const { error } = this.state;
   
    if ((error && error !== prevState.error)) {
      this.navigateToOtherScreen2();
    }
  }

  sendIdTokenToBackend(idToken: string | null,email:string) {
    if (idToken && email) {
      const header = {
      
        "Content-Type": 'application/json',
    };
      const postPdfData = apiCall({
        header: header,
        httpBody: {
          "data": {
          "type": "apple_account",
          "attributes": {
            "email": email,
            "id_token": this.state.idToken
          }
        }
        },
        url: "/account_block/accounts/web_apple_login",
        httpMethod: "POST",
      });
    this.AppleLoginapiCallID = postPdfData.messageId;
    runEngine.sendMessage(postPdfData.id, postPdfData);
     
    }
  }

  navigateToOtherScreen=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Apiintegrationbankaccounts");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
 
checkAccountStatus = (responseJson: any) => {
  const { meta } = responseJson;
  if (meta.token) {
    if (!meta.question_answered) {
      this.navigateToQuestion()
      return
    }
    if (meta.subscription?.status !== 'inactive') {
      if (!moment().isAfter(moment.utc(meta.subscription?.expiration_date))) {
        if (meta.bank_connected) {
                window.localStorage.setItem('authToken', meta.token);
          setTimeout(() => {
               document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: true }));
          }, 200)
        } else {
          this.navigateToApiintegration()
          }
      } else {
        this.navigateToChangePlan();
         }
    } else {
      this.navigateToFreetrial();
        }
  }
}
  navigateToOtherScreen2=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  navigateToQuestion=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Questionbank");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  navigateToFreetrial=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Freetrial");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  navigateToChangePlan=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ChangePlan");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
 
  navigateToApiintegration=()=>{
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Apiintegrationbankaccounts");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  

  ChangeCode=()=>{
   this.setState({code:"code"})
 this.setState({idToken:"idToken"})
  }
  ChangeToken=()=>{
    this.setState({code:"code"})
    this.setState({idToken:"idToken"})
  }
  ChangeToken2=()=>{
    this.setState({code:"code"})
    this.setState({idToken:"idToken"})
  }
  validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email } = this.state;
    if (!email) {
      this.setState({ error2: 'Email is required.' });

    } else if (!this.validateEmail(email)) {
      this.setState({ error2: 'Please enter a valid email address.' });

    } else {
      this.setState({ error2: '' });

      // Handle form submission, e.g., send email to the server
      this.sendIdTokenToBackend(this.state.idToken,email)
    }
  };
  // Customizable Area End
}
