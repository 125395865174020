import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,  } from "@material-ui/core/styles";

import AppleLogin from 'react-apple-login';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import {ContinueApple} from './assets';
import "../../email-account-login/assets/border.css"
// Customizable Area End

import Applelogin2Controller, {
  Props,
  configJSON,
} from "./Applelogin2Controller";

export default class Applelogin2 extends Applelogin2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     
      <> 
        <AppleLogin
   data-test-id="appleSignin"
      clientId="com.myneLeap.app.auth.client"
      redirectURI={this.state.envUrl}
      responseType="code id_token"
      responseMode="fragment"
      usePopup={false}
      render={(props: any) => <Box {...props} data-test-id="applelogin" style={{padding:'0',borderRadius:'2px'}}> <img className="signupProvider" data-test-id="btnSocialLogins" src={ContinueApple} style={{width:'100%'}}/></Box>}

    />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
