import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    ButtonGroup,
    Input,
    Divider
} from "@material-ui/core";
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import { group_Sorting } from "./assets"
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { styled } from '@material-ui/styles';
import MainCatalogueController, {
    Props
} from "./MainCatalogueController.web";
import { Link } from 'react-router-dom';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class MainCatalogueWeb extends MainCatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    twoButton = (data: string, link: string) => {
        return (
            <TwoButtonBox >
                <Link to={{
                    pathname: '/ViewInvestments',
                    state: { investMentId: data }
                }}>
                    <LearnMoreButton><Learn >{this.getStringInvestments("LearnMore")}</Learn> </LearnMoreButton>
                </Link>
                <LearnMoreButtons href={link}><InvestNow >{this.getStringInvestments("Invest")}</InvestNow> </LearnMoreButtons>
            </TwoButtonBox>
        )
    }
    selectDropdowon = (name: string, select: object, setFieldValue: (name: string, val: { value: string; label: string }) => void, selectCountry: { lable?: string, value?: string }) => {
        return (
            <CustomSelect defaultValue={selectCountry} placeholder={name} styles={this.customStylesProperty} classNamePrefix="an-simple-select" onChange={(val: any) => { setFieldValue(name, val) }} options={select} className="selectMenu selectedMenuDropdown" value={!selectCountry?.value ? { label: name, value: name } : selectCountry} data-test-id="selectMenu" />
        )
    }
    render() {
        return (
            // Customizable Area Start
            <BackgroundBoxSatyle dir={this.state.logoImgInvestments}>
                <CnvestmentBox>
                    <Box style={webStyle.investmentsfont}>{this.getStringInvestments("Investments")}</Box>
                    <Box style={webStyle.twoButtonFlex}>
                        <ButtonGroup disableElevation variant="contained" style={webStyle.twoButton} dir="ltr">
                            <Button data-test-id="changeType" onClick={() => { this.changeType('Type') }}
                                style={{ ...this.state.transform, backgroundColor: this.state.assetsType == 'Type' ? "#2a4550" : '#2a2a39', ...webStyle.pieChartHeaderButton, ...webStyle.leftTag }}><img src={group_Sorting} /></Button>
                            <Button data-test-id='changeCountry' onClick={() => { this.changeType('Country') }}
                                style={{ ...this.state.transform, backgroundColor: this.state.assetsType == 'Country' ? "#2a4550" : '#2a2a39', ...webStyle.pieChartHeaderButton, ...webStyle.rightTag }}><MenuRoundedIcon style={webStyle.rightTagIcon} /></Button>
                        </ButtonGroup>
                    </Box>
                    <Box style={webStyle.paddingTopBox}>
                        <Box style={webStyle.menBox}>
                            <Box>
                                <Input placeholder="Search" data-test-id="searchInput" value={this.state.searchInput} onChange={(e) => this.searchItems(e.target.value)} />
                                <Box style={webStyle.searchBox}>
                                    <Box style={webStyle.yourGoal}>
                                        {(this.selectDropdowon(this.getStringInvestments("YourGoal"), this.state.selectedGoals, this.setGoalsValue, this.state.selectGoalsCurrency))}
                                    </Box>
                                    <Box style={webStyle.yourGoal}>
                                        {(this.selectDropdowon(this.getStringInvestments("Location"), this.state.selectedLocations, this.setFieldValue, this.state.selectLocationsCurrency))}
                                    </Box>
                                    <Box>
                                        {(this.selectDropdowon(this.getStringInvestments("Industry"), this.state.selectedCategories, this.setCategoriesValue, this.state.selectCategoriesCurrency))}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" flexItem style={webStyle.divider} />
                            <OverBoxSatyle>
                                {this.state.assetsType == "Type" ?
                                    <AssetsTypeBoxSatyle >
                                        <Grid container spacing={3}>
                                            {this.state.investmentsData?.filter((item: any) => item.attributes.heading.toLowerCase().includes(this.state.searchInput.toLowerCase())).map((value: any, index: number) => {
                                                return (
                                                    <>
                                                        <Grid key={index} item xs={12} sm={12} md={6}>
                                                            <Box style={webStyle.backgroundBox}>
                                                                <ImgFullBox >
                                                                    <Box>{value?.attributes?.images !== null ? <img style={webStyle.boximg} src={value.attributes.images[0].url} alt="Image" /> : null}</Box>
                                                                    <ImgNameMobile >
                                                                        <NameTempBox >{value.attributes.heading}</NameTempBox>
                                                                        <ImgFullBox >
                                                                            <Box><a style={webStyle.returnName}>{this.getStringInvestments("Return")}&nbsp;&nbsp;&nbsp;</a><a style={webStyle.returnNameColor} dir="ltr">{value.attributes.return_value}</a></Box>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <Box><a style={webStyle.returnName}>{this.getStringInvestments("MktAvg")}&nbsp;&nbsp;&nbsp;</a><a style={webStyle.returnmktavgColor} dir="ltr">{value.attributes.market_average}</a></Box>
                                                                        </ImgFullBox>

                                                                    </ImgNameMobile>
                                                                </ImgFullBox>
                                                                <Box>
                                                                    <TextField style={webStyle.information}><div dangerouslySetInnerHTML={{ __html: value.attributes.one_line_summary }} /> </TextField >
                                                                </Box>
                                                                {(this.twoButton(value.attributes.id, value.attributes.link))}
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </AssetsTypeBoxSatyle>
                                    :
                                    <AssetsTypeBoxSatyle >
                                        <Grid container spacing={3}>
                                            {this.state.investmentsData?.filter((item: any) => item.attributes.heading.toLowerCase().includes(this.state.searchInput.toLowerCase())).map((value: any, index: number) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} >
                                                            <Box key={index} style={webStyle.backgroundBox1}>
                                                                <InvestmentMobile>
                                                                    <Grid item xs={12} sm={9}>
                                                                        <InvestmentMobile1 >
                                                                            <Box>{value?.attributes?.images !== null ? <img style={webStyle.boximg} src={value.attributes.images[0].url} alt="Image" /> : null}</Box>
                                                                            <Box style={webStyle.nameHeading}>
                                                                                <ImgMobile >{value.attributes.heading}</ImgMobile>
                                                                            </Box>
                                                                        </InvestmentMobile1>
                                                                        <TextField style={webStyle.information}><div dangerouslySetInnerHTML={{ __html: value.attributes.one_line_summary }} /></TextField >
                                                                    </Grid>
                                                                    <Box>
                                                                        <Box style={webStyle.returnData}>
                                                                            <Box ><a style={webStyle.returnName}>{this.getStringInvestments("Return")}&nbsp;&nbsp;&nbsp;</a><br /><a style={webStyle.returnNameColor} dir="ltr">{value.attributes.return_value}</a></Box>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <Box><a style={webStyle.returnName}>{this.getStringInvestments("MktAvg")}&nbsp;&nbsp;</a><br /><a style={webStyle.returnmktavgColor} dir="ltr">{value.attributes.market_average}</a></Box>
                                                                        </Box>
                                                                        <Box>
                                                                            {(this.twoButton(value.attributes.id, value.attributes.link))}
                                                                        </Box>
                                                                    </Box>
                                                                </InvestmentMobile>
                                                            </Box>
                                                        </Grid>
                                                    </>)
                                            })}
                                        </Grid>
                                    </AssetsTypeBoxSatyle>
                                }
                            </OverBoxSatyle>
                        </Box>
                    </Box>
                </CnvestmentBox>
            </BackgroundBoxSatyle>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const webStyle = {
    nameHeading: { padding: '0px 20px', display: 'flex', alignItems: 'center' },
    returnData: { display: 'flex', justifyContent: 'space-evenly', },
    rightTagIcon: { fontSize: '40px' },
    leftTag: {
        padding: '0px 10px', borderRadius: "17px 0px 0px 17px"
    },
    rightTag: {
        padding: '0px 7px', borderRadius: "0px 17px 17px 0px",
    },
    yourGoal: {
        marginBottom: "20px",
    },
    imgFullBox: {
        display: 'flex', flexWrap: 'wrap'
    },
    selcetcolor: {
        width: "100%",
        height: "40px",
        marginTop: "20px",
        borderColor: "#2DE2D5",
        cursor: "pointer",
        borderRadius: "20px",
        background: '#2a2a39',
        color: "#2DE2D5 ",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Poppins",
    },
    searchBox: {
        marginTop: '89px'
    },
    divider: {
        margin: '0px 40px',
        background: '#395D6B'
    },
    boximg: {
        width: '64px', height: '64px', borderRadius: "9px",
    },
    paddingTopBox: {
        paddingTop: '50px'
    },
    menBox: {
        display: "flex", height: "100vh", width: "100%"
    },
    investmentsfont: {
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "28px",
        fontWeight: 700,
    },
    pieChartHeaderButton: {
        color: '#2ce2d5',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderRight: "2px solid",
    },
    twoButton: {
        border: '2px solid #2ce2d5', borderRadius: "20px"
    },
    twoButtonFlex: {
        display: 'flex',
        justifyContent: 'end'
    },
    backgroundBox: {
        borderRadius: "12px",
        border: "1px solid #57575D",
        background: "#3C3C51",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
        width: '100%',
        padding: '20px ',
        flex: '1',
        height: '100%'
    },
    backgroundBox1: {
        borderRadius: "12px",
        border: "1px solid #57575D",
        background: "#3C3C51",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
        padding: '20px ',
        flex: '1',
        height: '100%',
    },

    returnName: {
        color: "#928F98",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
    },
    returnNameColor: {
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
    },
    returnmktavgColor: {
        color: "#2DE2D5",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
    },
    information: {
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 600,
        width:"95%"
    },
}

const CustomSelect = styled(Select)({
    borderRadius: '50px',
    selectMenu: {
        width: "8px",
    },
    '&::-webkit-scrollbar': {
        backgroundColor: "#C9F9F6",
        border: '1px solid #C9F9F6',
        color: '#3C3C51',
    },
})

const AssetsTypeBoxSatyle = styled(Box)({
    width: '96%',
})
const BackgroundBoxSatyle = styled(Box)({
    width: '100%', background: '#2a2a39'
})

const OverBoxSatyle = styled(Box)({
    width: "100%",
    overflowY: 'scroll',
    height: '80%',
})

const LearnMoreButton = styled(Button)({
    borderRadius: '6px',
    border: "1px solid #C9F9F6",
    background: "#3C3C51",
    textTransform: "capitalize",
    width: "150px",
})

const LearnMoreButtons = styled(Button)({
    borderRadius: '6px',
    border: "1px solid #C9F9F6",
    backgroundColor: "#C9F9F6",
    textTransform: "capitalize",
    color: "#C9F9F6",
    margin: '0px 20px',
    width: "150px",
    flexWrap: 'wrap',
    '&:hover': {
        backgroundColor: "#C9F9F6",
        border: '1px solid #C9F9F6',
        color: '#3C3C51',
    },
    '@media (max-width: 1024px)': {
        margin: '0px',
        marginTop: '10px'
    },
})

const TextField = styled(Typography)({
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    paddingTop: '10px'
})

const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important",
    paddingRight: "80px !important",
    paddingTop: "40px !important",
    '@media (min-width: 320px)': {
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
    },
    '@media (min-width: 1200px)': {
        paddingLeft: "80px !important",
        paddingRight: "80px !important",
    },
})

const InvestmentMobile = styled(Box)({
    display: 'flex',
    '@media (max-width: 768px)': {
        flexWrap: 'wrap',
    },
    '@media (min-width: 1200px)': {
        display: 'flex',
    },
})
const InvestmentMobile1 = styled(Box)({
    display: 'flex',
})

const ImgMobile = styled(Box)({
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    textTransform: "capitalize",
    '@media (min-width: 320px)': {
        fontSize: "20px",
    },
    '@media (min-width: 1200px)': {
        fontSize: "20px",
        fontWeight: 600,
    },
})

const ImgNameMobile = styled(Box)({
    padding: '0px 20px',
    '@media (min-width: 320px)': {
        padding: '0px'
    },
    '@media (min-width: 1200px)': {
        padding: '0px 10px'
    },
})

const ImgFullBox = styled(Box)({
    display: 'flex', flexWrap: 'wrap',
})

const NameTempBox = styled(Box)({
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    textTransform: "capitalize"
})
const Learn = styled(Box)({
    whiteSpace: 'nowrap',
    color: "#C9F9F6",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
})

const InvestNow = styled(Box)({
    color: "#3C3C51",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
})
const TwoButtonBox = styled(Box)({
    display: 'flex', justifyContent: 'center', paddingTop: '30px',
    '@media (max-width: 1024px)': {
        flexWrap: 'wrap'
    },
})
// Customizable Area End