// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/folderThreeDots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const addFolder = require('../assets/add_folder.png');
export const willDocuments = require('../assets/will_documents.png');
export const document_file = require('../assets/document_file.png')
export const ThreeDots = require('../assets/folderThreeDots.png')
export const threeDotss = require('../assets/threedots.svg')

// Customizable Area End
