import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DateObject } from "react-native-calendars";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import * as Yup from "yup";

interface Proposal {
  "id": string,
  "type": string,
  "attributes": {
    "title": string,
    "description": string,
    "price": number,
    "date_of_proposal": string,
    "customer_emails": string[],
    "name_of_proposal_creator": string,
    "customer_name": string

  }
}
interface IService {
  "id": string,
  "type": string,
  "attributes": {
    "service_id": number,
    "name": string,
    "image": string,
    "price": string,
    "currency": string,
    "plan_inclusions": IPlanInclusion[]
  }
}

interface IPlanInclusion {
  "id": number,
  "name": string,
  "explanation": string
}
export interface ProposalType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    price: number;
    date_of_proposal: string;
    customer_emails: string[];
    name_of_proposal_creator: string;
    customer_name: string;
  };
}

export interface ITemplate {
  "id": number,
  "wealth_planner_id": number,
  "name": string,
  "created_at": string,
  "updated_at": string
}

export interface IClient{
  "id": string,
  "type": string,
  "attributes": {
      "client_id": number,
      "name": string,
      "date": string,
      "request_status": string,
      "review": number,
      "status": string,
      "proposal_sent_status": boolean
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openCreateForm: boolean,
  title: string,
  description: string,
  price: string,
  dateOfProposal: string,
  customerName: string,
  customerEmail: string,
  creator: string,
  listOfProposals: Proposal[],
  deleteID: string,
  proposalIndex: number,
  deleteConfirmationPopup: boolean,
  openCalendarPicker: boolean,
  shareActivated: boolean,
  numberOfCustomers: number,
  customersArray: string[],
  showValidateMessage: boolean,
  mode: string,
  shareProposalID: string,
  selectedDate: Date | null,
  proposalDate: null | MaterialUiPickersDate,
  viewproposal: null | Proposal,
  services: IService[],
  activeTab: string;
  expandedServiceId:string;
  templates: ITemplate[];
  openDeleteModel: boolean;
  currentTemplateId: number;
  openEditServiceModal:boolean;
  commisionFee: number;
  cancelServiceModal: boolean;
  openSuccessDialog: boolean;
  clients:IClient[];
  currentPage: number;
  selectedClients: number[],
  anchorToolTipEl: HTMLSpanElement| null,
  currentClientId: number,
  selectTemplateDialog: boolean,
  selectedTemplateId: number | null,
  isServiceSendDialogOpen: boolean,
  rowPerPage:number,
  openSendServiceModal:boolean,
  openSnackbar:boolean,
  successMsg: string;
  isMultipleServices: boolean;
  errorMsg:string,
  plannerInfo: {
    name:string,
    email:string,
    image:string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Proposalgeneration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProposalsAPICallID: string = "";
  createProposalAPICallID: string = "";
  deleteProposalAPICallID: string = "";
  shareProposalAPICallID: string = "";
  getServiceAPICallID: string = "";
  getTemplatesAPIcallID: string = "";
  deleteTemplateAPICallID: string = "";
  editServiceAPICallID: string = "";
  getCommisionFeeAPICallID: string = "";
  getClientsAPICallID: string  = "";
  sendTemplateAPICallID:string = "";
  sendServiceToUserAPICallId:string = "";
  getUserProfileAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openCreateForm: false,
      title: '',
      description: '',
      price: '',
      dateOfProposal: '',
      customerName: '',
      customerEmail: '',
      creator: '',
      listOfProposals: [],
      deleteID: '',
      proposalIndex: -1,
      deleteConfirmationPopup: false,
      openCalendarPicker: false,
      shareActivated: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      showValidateMessage: false,
      mode: 'add',
      shareProposalID: "",
      selectedDate: new Date(),
      proposalDate: null,
      viewproposal: null,
      services: [],
      activeTab: "services",
      expandedServiceId:"",
      templates:[],
      openDeleteModel:false,
      currentTemplateId: 0,
      openEditServiceModal:false,
      commisionFee:20,
      cancelServiceModal: false,
      openSuccessDialog: false,
      clients:[],
      currentPage: 1,
      selectedClients: [],
      anchorToolTipEl: null,
      currentClientId: 0,
      selectTemplateDialog:false,
      selectedTemplateId: null,
      isServiceSendDialogOpen: false,
      rowPerPage: 5,
      openSendServiceModal: false,
      openSnackbar:false,
      successMsg:"",
      isMultipleServices: false,
      errorMsg: "",
      plannerInfo:{
        name:"",
        image:"",
        email:""
      }
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
      return false
    }

    if (response.errors) {
      this.parseApiCatchErrorResponse(response)
      return false
    }
    if (requestCallId === this.getProposalsAPICallID) {
      this.setState({ listOfProposals: response.data ? response.data : [] })
    } else if (requestCallId === this.createProposalAPICallID) {
      this.showAlert("", "Proposal Saved Successfully");
      this.handleSuccessResponse()
    } else if (requestCallId === this.deleteProposalAPICallID) {
      this.handleSuccessResponse()
    } else if (requestCallId === this.shareProposalAPICallID) {


      alert(response.message)
      this.setState({ viewproposal: null });

      this.closePopUp()
    }
    
    this.handleEditServiceResponse(requestCallId)
    this.handlesendTemplateResponse(requestCallId);
    this.handlesendTemplateToUserResponse(requestCallId)
    this.handlesendServiceToUserResponse(requestCallId)
    this.handleCommisionPercentageResponse(response,requestCallId)
    this.handleGetClientResponse(response,requestCallId);
    this.handleDeleteTemplateResponse(requestCallId);
    this.handleGetServiceResponse(response,requestCallId);
    this.handleGetTemplateResponse(response,requestCallId);
    this.handleUserProfileResponse(requestCallId,response)
    // Customizable Area End
  }

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handlesendServiceToUserResponse(requestCallId: string){
    if(requestCallId === this.sendServiceToUserAPICallId){
      this.setState({
        openEditServiceModal:false,
        isServiceSendDialogOpen: false,
        successMsg:"Service proposal have been successfully sent!",
        openSuccessDialog: true,
        selectedClients: [],
        isMultipleServices: false
      });
      this.getClients();
      this.getTemplates()
    }
  }

  handlesendTemplateToUserResponse(requestCallId: string){
    if(requestCallId === this.sendTemplateAPICallID){
      this.setState({
        selectTemplateDialog:false,
        openSuccessDialog: true,
        selectedClients: [],
        isMultipleServices: false,
        selectedTemplateId:0,
        successMsg:"Service proposal have been successfully sent!"
      })
      this.getClients();
    }
  }

  handlesendTemplateResponse(requestCallId: string){
    if(requestCallId === this.sendTemplateAPICallID){
      this.setState({
        selectTemplateDialog:false,
        openSuccessDialog: true,
        selectedClients: [],
        isMultipleServices: false,
        selectedTemplateId:0,
        successMsg:"Service proposal have been successfully sent!"
      })
      this.getClients();
    }
  }

  handleCommisionPercentageResponse(response: {percentage:number},requestCallId:string){
    if(requestCallId === this.getCommisionFeeAPICallID){
      this.setState({
        commisionFee: response.percentage
      })
    }
  }

  handleGetClientResponse(response: {clients:{
    data: IClient[]
  }},requestCallId:string){
    if(requestCallId === this.getClientsAPICallID){
      this.setState({
        clients:  response.clients.data
      })
    }
  }

  handleEditServiceResponse(requestCallId:string){
    if (requestCallId === this.editServiceAPICallID) {
      this.setState({
        openEditServiceModal: false,
        successMsg:"Services are successfully saved!"
      });
      this.dialogSuccessModal();
      this.getServices()
    }
  }

  handleDeleteTemplateResponse(requestCallId:string){
    if (requestCallId === this.deleteTemplateAPICallID) {
      this.getTemplates();
      this.closeDeleteModal();
      this.setState({
        selectedTemplateId: 0,
      })
    }
  }

  handleGetServiceResponse(response: {service: {data:IService[]}},requestCallId:string){
    if(requestCallId === this.getServiceAPICallID){
      this.setState({
        services: response.service.data
      })
    }
  }
  
  handleGetTemplateResponse(response:{templates:ITemplate[]},requestCallId:string){
    if (requestCallId === this.getTemplatesAPIcallID) {
      this.setState({ templates: response.templates })
    }
  }

  setTitle = (text: string) => {
    this.setState({
      title: text
    })
  }

  setDescription = (text: string) => {
    this.setState({
      description: text
    })
  }

  setPrice = (text: string) => {
    this.setState({
      price: text
    })
  }

  setDateOfProposal = (dayObject: DateObject) => {
    this.setState({
      dateOfProposal: dayObject.day + "/" + dayObject.month + "/" + dayObject.year,
      openCalendarPicker: false
    })
  }
  
  setCustomerName = (text: string) => {
    this.setState({
      customerName: text
    })
  }
  
  
  setCustomerMails = (text: string, index: number) => {
    let mailArray = this.state.customerEmail.split(',');
    mailArray[index] = text
    this.setState({ customerEmail: mailArray.toString() })
  }

  showAlertMessage = (type: string) => {

    if (this.state.showValidateMessage) {
      if (type === 'title' && this.isStringNullOrBlank(this.state.title)) {
        return "Please enter title of the proposal";
      } else if (type === 'price' && this.isStringNullOrBlank(this.state.price)) {
        return "Please enter price of the proposal";
      }
      else if (type === 'price' && !(/^[0-9]+$/.test(this.state.price))) {
        return "Price should be number";
      }
      else if (type === 'date' && this.isStringNullOrBlank(this.state.dateOfProposal) || type === 'date' && !!this.state.proposalDate) {
        return "Please enter date of the proposal";
      } else if (type === 'desc' && this.isStringNullOrBlank(this.state.description)) {
        return "Please enter description of the proposal";
      } else if (type === 'creator' && this.isStringNullOrBlank(this.state.creator)) {
        return "Please enter creator of the proposal";
      } else {
        return ""
      }

    } else {
      return ""
    }
  }



  plus = () => {
    let array = [...this.state.customersArray]
    array.push(this.state.numberOfCustomers + "");
    this.setState({
      customersArray: array,
      numberOfCustomers: this.state.numberOfCustomers + 1,
      customerEmail: this.state.customerEmail + ","
    })
  }

  isStringNullOrBlank(text: string) {
    return text === null || text.trim().length === 0;
  }


  handleSaveClick = async () => {
    this.setState({ showValidateMessage: false })
    if (this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.dateOfProposal) ||
      this.isStringNullOrBlank(this.state.price) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.creator)) {
      this.setState({ showValidateMessage: true })
      return
    }

    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("price", this.state.price)
    formData.append("name_of_proposal_creator", this.state.creator)
    formData.append("date_of_proposal", this.state.dateOfProposal)
    formData.append("description", this.state.description)
    this.state.customerName != '' && formData.append("customer_name", this.state.customerName)
    formData.append("customer_emails", '[]')
    this.createProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: this.state.mode === 'add' ? configJSON.postMethod : configJSON.updateMethod,
      endPoint: this.state.mode === 'add' ? configJSON.proposalEndpoint : configJSON.proposalEndpoint + '/' + this.state.mode,
      body: JSON.stringify(formData)
    });

  }

  getMailValue = (index: number) => {
    return this.state.customerEmail.split(',')[index]
  }

  setCreator = (text: string) => {
    this.setState({
      creator: text
    })
  }

  toggleCreateForm = () => {
    this.setState({
      mode: 'add',
      openCreateForm: true
    })

  }

  shareProposal = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal, "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name,
      shareProposalID: proposal.id,
      shareActivated: true
    })
  }

  shareProposalToMail = async () => {
    if (this.isStringNullOrBlank(this.state.customerEmail.split(',').join(''))) {
      this.showAlert("Alert", "Please enter customer mail to share")
      return false
    }

    let mailArray = this.state.customerEmail.split(',').filter(item => item !== "");;
    let shareArray: string = '[';
    let validate = true;
    mailArray.forEach(element => {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(element) === false) {
        validate = false
      } else {
        shareArray = shareArray + '"' + element + '",'
      }
    });

    if (!validate) {
      this.showAlert("Alert", "Please enter valid mail ID to share")
      return false
    }

    const formData = new FormData();
    formData.append("proposal_id", this.state.viewproposal ? this.state.viewproposal?.id : "")
    formData.append('customer_emails', shareArray + ']');


    this.shareProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: configJSON.postMethod,
      endPoint: configJSON.shareProposalEndpoint,
      body: JSON.stringify(formData)
    });
  }

  editProposals = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal.toString(), "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name || '',
      mode: proposal.id,
      openCreateForm: true
    })
  }

  openDeletePopup = (proposalID: string) => {
    this.setState({
      deleteID: proposalID + '',
      deleteConfirmationPopup: true
    })
  }

  deleteHandler = async () => {
    this.deleteProposalAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.proposalEndpoint + "/" + this.state.deleteID,
      body: null
    });
  }


  closePopUp = () => {
    this.setState({
      deleteConfirmationPopup: false,
      openCreateForm: false,
      title: "",
      price: "",
      description: "",
      creator: "",
      dateOfProposal: "",
      proposalDate: null,
      proposalIndex: -1,
      shareActivated: false,
      openCalendarPicker: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      customerEmail: '',
      showValidateMessage: false,
      shareProposalID: ""
    })
  }

  toggleCalendar = () => {
    this.hideKeyboard();
    this.setState({
      openCalendarPicker: !this.state.openCalendarPicker
    })
  }



  getProposals = async () => {
    this.getProposalsAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: configJSON.validationApiMethodType + '',
      endPoint: configJSON.proposalEndpoint + '',
      body: null
    });
  }

  getServices = async () => {
    this.getServiceAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: configJSON.validationApiMethodType + '',
      endPoint: configJSON.serviceProposalEndpoint + '',
      body: null
    });
  }

  getTemplates = async () => {
    this.getTemplatesAPIcallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: "GET" + '',
      endPoint: configJSON.getTemplateEndpoint + '',
      body: null
    });
  }

  getCommisionFee = async () => {
    this.getCommisionFeeAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: "GET" + '',
      endPoint: configJSON.percentageEndpoint + '',
      body: null
    });
  }

  getClients = async () => {
    this.getClientsAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: "GET" + '',
      endPoint: configJSON.getClientsEndpoint + '',
      body: null
    });
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null 
  }) => {
    const { contentType, method, endPoint, body } = data;
    const proposalRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
        token: localStorage.getItem("auhtToken")
      })
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(proposalRequestMessage.id, proposalRequestMessage);
    return proposalRequestMessage.messageId;
  };

  handleSuccessResponse = () => {
    this.closePopUp();
    this.getProposals()
    return true
  }

  async componentDidMount() {
    this.getProposals();
    this.getServices();
    this.getTemplates();
    this.getCommisionFee();
    this.getClients();
    this.getUserProfile()
  }

  handleTabChange(tabName: string) {
    this.setState({
      activeTab: tabName
    })
  }

  getTabDesign = (tabName: string) => {

    if (tabName === this.state.activeTab) {
      return { fontSize: "18px", fontWeight: 600, color: "2de2d5", cursor: "pointer", borderBottom: "1px solid" }
    }
    return { fontSize: "18px", fontWeight: 400, color: "c4c4c4", cursor: "pointer" }
  }

  serviceProposalSchema = Yup.object().shape({
    services: Yup.array()
      .of(
        Yup.object().shape({
          service_id: Yup.string().nullable(),
          currency: Yup.string().nullable(),
          is_selected: Yup.boolean().nullable(),
          price: Yup.number().nullable().transform((value, originalValue) => (originalValue === '' ? null : value))
          .when('is_selected', {
            is: true,
            then: Yup.number().required("Please enter price").min(0.1,"Please enter price"),
            otherwise: Yup.number().nullable()
          }),
          plan_inclusion: Yup.array()
            .of(
              Yup.object().shape({
                service_detail_id: Yup.number().nullable(),
                description: Yup.string().nullable(),
              }))
        }),
      )
      .test('at-least-one-selected', 'At least one service must be selected', function (value) {
        const atLeastOneSelected = value.some((service: { is_selected: boolean; }) => service.is_selected === true);
        return atLeastOneSelected;
      })
      .required(),
      save_as_template: Yup.boolean().nullable(),
      name: Yup.string()
      .ensure()
      .when("save_as_template", {
        is: true,
        then:Yup.string().nullable().required()
      }),
  });

  handleSubmit = async (values: {
    services: {
      service_id: number;
      price: string;
      currency: string;
      is_selected:boolean,
      plan_inclusion: {
        service_detail_id: number;
        description: string;
      }[],
  }[];
  "save_as_template": boolean,
  "name":string
  }) => {
    if(this.state.isServiceSendDialogOpen){
      if(this.state.isMultipleServices){
        let clientIds = this.state.selectedClients;
        for(let index = 0;index< clientIds.length;index++ ){
          let body = 
          {
            data: values.services.filter((service)=>service.is_selected).map((item)=>{
              return {
                ...item,
                price: parseFloat(item.price)+ (parseFloat(item.price)* this.state.commisionFee/100)
              }
            }),
            "account_id":clientIds[index],
             "save_as_template": false,
             "name":"" ,
          }
          if(index  === clientIds.length -1){
            body.save_as_template =values.save_as_template;
            body.name = values.name
          }
          this.sendServiceToUserAPICallId = await this.apiCall({
            contentType: configJSON.validationApiContentType + '',
            method: "POST" + '',
            endPoint: configJSON.sendServiceEndpoint + '',
            body: JSON.stringify({...body,currency: "AED"})
          });
        }
      }else{
        this.sendServiceToUserAPICallId = await this.apiCall({
          contentType: configJSON.validationApiContentType + '',
          method: "POST" + '',
          endPoint: configJSON.sendServiceEndpoint + '',
          body: JSON.stringify({
            data: values.services.filter((service)=>service.is_selected).map((item)=>{
              return {
                ...item,
                price: parseFloat(item.price)+ (parseFloat(item.price)* this.state.commisionFee/100)
              }
            }),
            "account_id": this.state.currentClientId,
             "save_as_template": values.save_as_template,
             "name": values.name,
             currency: "AED"
          })
        });
      }
     
    }else{
      this.editServiceAPICallID = await this.apiCall({
        contentType: configJSON.validationApiContentType + '',
        method: "PUT" + '',
        endPoint: configJSON.serviceProposalEditEndpoint + '',
        body: JSON.stringify({
          data: values.services.map((item)=>{
            return {
              ...item,
              price: parseFloat(item.price)+ (parseFloat(item.price)* this.state.commisionFee/100),
              currency: "AED"
            }
          })
        })
      });
    }
  }

  getInitialValue = () => {
    return this.state.services.map((service)=>{
      let feeA = (parseFloat(service.attributes.price)*100/this.state.commisionFee);
      let  feeDivison = (100/this.state.commisionFee)+1
      return {
        "service_id": service.attributes.service_id,
        "price": (feeA/ feeDivison).toFixed(2),
        "currency": service.attributes.currency,
        "plan_inclusion": service.attributes.plan_inclusions.map((planInclusion)=>{
          return {
            "service_detail_id": planInclusion.id,
            "description": planInclusion.explanation ?? ""
          }
        }),
        "is_selected": this.state.isServiceSendDialogOpen ? false: true,
      }
    })
  }

  handleAccordionChange = (serviceId:string) =>{
    this.setState({
      expandedServiceId: this.state.expandedServiceId === serviceId ? "" :serviceId 
    })
  }

  openDeleteTemplateModal = (templateId: number) =>{
    this.setState({
      currentTemplateId: templateId,
      openDeleteModel: true
    })
  }

  handleEditServiceModal = () => {
    this.setState({
      openEditServiceModal: !this.state.openEditServiceModal
    })
  }

  closeDeleteModal = () =>{
    this.setState({
      openDeleteModel: false
    })
  }
  dialogPaperStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '0rem 2rem',
  };

  deleteTemplate = async () =>{
    this.deleteTemplateAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: "DELETE" + '',
      endPoint: configJSON.templateDeleteEndpoint + this.state.currentTemplateId +'',
      body: null
    });
  }

  handleCloseCancelServiceModal = () =>{
    this.setState({
      cancelServiceModal: false
    })
  }

  handleOpenCancelServiceModal = () =>{
    this.setState({
      cancelServiceModal: true
    })
  }

  handleCloseServiceModal = () => {
    this.setState({
      cancelServiceModal: false,
      openEditServiceModal: false,
      isServiceSendDialogOpen: false
    })
  }

  dialogSuccessModal = () =>{
    this.setState({
      openSuccessDialog: !this.state.openSuccessDialog
    })
  }
  
  dialogSuccessPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };

  handlePageChange(page : number){
    this.setState({
      currentPage: page,
      selectedClients: []
    })
  }

  handleSelectClient(clientId: number){
    let selectedClients = this.state.selectedClients
    let index = selectedClients.indexOf(clientId)
    if(index === -1){
      selectedClients.push(clientId);
    }else{
      selectedClients.splice(index, 1);
    }
    this.setState({
      selectedClients
    })
  }

  isSelected(clientId: number){
    return this.state.selectedClients.indexOf(clientId) === -1 ? false: true
  }
  closePopover(){
    this.setState({
      anchorToolTipEl: null
    })
  }
   handleServiceClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>,clientId:number) => {
    this.setState({
      anchorToolTipEl: event.currentTarget,
      currentClientId: clientId
    })
  };

  handleTemplateSelectDialog = (isMultipleServices: boolean = false) =>{
    if(this.state.templates.length> 0){
      this.setState({
        selectTemplateDialog: true,
        anchorToolTipEl: null,
        openSendServiceModal:false,
        isMultipleServices: isMultipleServices
      })
    }else{
      this.setState({
        errorMsg: "No saved templates available. Try saving template first.",
        openSnackbar: true
      })
    }
  }

  handleSelectTemplate(templateId:number){
    this.setState({
      selectedTemplateId: templateId
    })
  }

  dialogTemplateSelectionStyle = {
    width: '52%',
    backgroundColor: '#3B3C50',
    borderRadius: '8px',
    boxShadow: 'none',
  };


  sendTemplate = async () => {
    if (this.state.selectedTemplateId) {
      if (this.state.isMultipleServices) {
        for (let client of this.state.selectedClients) {
          let body = {
            account_id: client,
            template_id: this.state.selectedTemplateId
          };
          this.sendTemplateAPICallID = await this.apiCall({
            contentType: configJSON.validationApiContentType + '',
            method: "POST" + '',
            endPoint: configJSON.sendTemplateEndpoint + '',
            body: JSON.stringify(body)
          });
        }
      } else {
        let body = {
          account_id: this.state.currentClientId,
          template_id: this.state.selectedTemplateId
        };
        this.sendTemplateAPICallID = await this.apiCall({
          contentType: configJSON.validationApiContentType + '',
          method: "POST" + '',
          endPoint: configJSON.sendTemplateEndpoint + '',
          body: JSON.stringify(body)
        })
      }
    }
  }

  handleServiceSelectDialog(isMultipleServices:boolean = false){
    if(this.state.services.filter((service)=>service.attributes.price).length> 0){
      this.setState({
        isServiceSendDialogOpen:true,
        anchorToolTipEl: null,
        openSendServiceModal:false,
        openEditServiceModal:true,
        isMultipleServices: isMultipleServices
      })
    }else{
      this.setState({
        errorMsg: "No selection made",
        openSnackbar: true
      })
    }
  };


  handleSelectAll = () =>{
    if(this.state.selectedClients.length === this.state.rowPerPage){
      this.setState({
        selectedClients:[]
      })
    }else{
      let clients = this.state.clients.slice((this.state.currentPage - 1) * this.state.rowPerPage, ((this.state.currentPage - 1) * this.state.rowPerPage) + this.state.rowPerPage);
      this.setState({
        selectedClients:clients.map((client)=>client.attributes.client_id)
      })
    }
  }


  handleSendServiceModal(){
    if(this.state.selectedClients.length> 0){
      this.setState({
        openSendServiceModal: true,
        isMultipleServices: true
      })
    }else{
      this.setState({
        errorMsg: "No selection made",
        openSnackbar: true
      })
    }

  }

  closeSendServiceModal = () =>{
    this.setState({
      openSendServiceModal: false
    })
  }
  
  handleCloseSnackbar = () => {
    this.setState({
      openSnackbar: false
    })
  }
  closeSelectTemplateDialog(){
    this.setState({
      selectTemplateDialog:false
    })
  }
  dialogStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    paddingTop: '2rem',
    paddingBottom:'2rem',
    boxShadow:"none"
  };
  handleKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    const charCode = typeof event.which === 'undefined' ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (charCode === 13) return true;
    if (!charStr.match(/^[0-9.]+$/)) return event.preventDefault();
    return true;
  }
  getWidthOfDialog = () =>   this.state.isServiceSendDialogOpen ? 11 : 12

  handleUserProfileResponse(apiCallId:string,responseJson: {
    data: {
      attributes:{
        name: string,
        email: string,
        image: string
      }
    }
  }){
    if(apiCallId === this.getUserProfileAPICallId){
      console.log("hereakshay")
      this.setState({
        plannerInfo: responseJson.data.attributes
      })
    }
  }

  getUserProfile = async () => {
    this.getUserProfileAPICallId = await this.apiCall({
      method: "GET" + '',
      endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
      body: null,
      contentType:"application/json"
    });
  }
  goToProfilePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannerProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }
  // Customizable Area End
}
